import { Component, OnInit } from '@angular/core';


declare var $: any;
import { ApiService } from '../../../services/apiService';
import swal from 'sweetalert2'
import { Router, NavigationEnd, Event } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChatService } from '../../../services/chat/chat.service';
import { Action } from '../../../services/chat/models/action';
import { User } from '../../../services/chat/models/user';
import { Message } from '../../../services/chat/models/message';
import { FooterComponent } from '../../../components/footer/footer.component';

@Component({
  selector: 'app-acceptedQuotations',
  templateUrl: './acceptedQuotations.component.html',
  styleUrls: ['./acceptedQuotations.component.css'],
  providers: [ChatService, ApiService]
})
export class AcceptedQuotations implements OnInit {
  public completeQuotations: Array<any> = [];
  public soldQuotations: Array<any> = [];
  public forSendQuotations: Array<any> = [];
  public cardView: Boolean;
  public listView: Boolean;

  public selectedQuotation :any;
  // Rating
  max: number = 5;
  rate: number = 4;
  isReadonly: boolean = false;
  public chatComponent = false;
  paraenviada2 = false;
  paraenviada1 = false;
  porcotizar = false;
  bloque = true;
  paraaceptadas = true;
  paranueva = false;
  categoria = String;
  public ventaconcretada = [];
  public rechazada = [];
// Rating

maxRatings: number = 5;
rateRatings: number = 0;
public ratingComment = '';
public rating = {

  companyId: "",
  quotationId: {},
  requestId: {}

};
quotationId = ''
requestId = '';
  /*chat cosas*/
  action = Action;
  user: User;
  messages: Message[] = [];
  messageContent: string;
  ioConnection: any;
  /*chat cosas*/
  constructor(private chatService: ChatService, private _apiService: ApiService, private spinner: NgxSpinnerService, private _router: Router) {
    this.cardView = true;
    this.listView = false;



  }
  ngOnInit() {
    this._router.events.subscribe(
      (value: Event) => {
        if (value instanceof NavigationEnd) {
          this.getVentasAceptadas('Requests/acceptedQuotations');

        }
      });
    this.getVentasAceptadas('Requests/acceptedQuotations');

  }x
  cardViewClick() {
    this.listView = false;
    document.getElementById("card").style["display"] = "block";
    document.getElementById("list").style["display"] = "none";
    setTimeout(() => { this.cardView = true; });


  };
  surveyIxcco() {

    //IXCCO SURVEY listed in admin ixcco
    this.spinner.show();


    setTimeout(() => {
      swal({
        title: 'Gracias!',
        text: 'Encuesta contestada satisfactoriamente.',
        type: 'success',
        confirmButtonClass: 'btn ',
        confirmButtonColor: '#000'
      });
      this.spinner.hide()
      $('#ixccoEncuesta').modal('hide');
      this._router.navigate(['/aceptada/']);


    }, 350);
 
    


  }
  listViewClick() {
    document.getElementById("list").style["display"] = "block";
    document.getElementById("card").style["display"] = "none";
    setTimeout(() => { this.listView = true; });
    this.cardView = false;
  };

  getVentasAceptadas(type, id?) {
    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');

    let branchId = localStorage.getItem('userBranchId');
    let userId = localStorage.getItem('$LoopBack$currentUserId');

    let params = {

      userId: userId,
      access_token: tokenId
    };
    this._apiService.getDataObjects(type, branchId, params).subscribe(
      (result: any)=> {

        this.soldQuotations = result.data.sold;
        this.forSendQuotations = result.data.send;

        this.completeQuotations = result.data.complete;

        console.log(result)



      },
      error => {
        let errorMessage = <any>error;
        if (errorMessage != null) {
          console.log(errorMessage);

        }
      }

    );
  }

  verSended(req) {
    this.selectedQuotation = req;

    this.categoria = req;
    console.log( req);
    $('#verSended').show(400);
    $('#regresa').hide(10);

  }
  
   verHistory(req) {
    this.selectedQuotation = req;

    this.categoria = req;
    console.log( req);
    $('#verHistory').show(400);
    $('#regresa').hide(10);

  }
  ver(cat) {
    this.selectedQuotation = cat;

    this.categoria = cat;
    console.log("la categoria", cat);
    $('#verSended').show(400);
    $('#regresa').hide(10);

  }
  verRegresa() {

    $('#verSended').hide(400);
    this.selectedQuotation = null;

    $('#regresa').show(400);
    //$('#verchat').hide(10);
  }
  Regresa() {
    $('#regresa').show(400);
    $('#ver').hide(10);
  }
  openChat(quotation) {
    this.selectedQuotation = quotation;
    console.log(quotation)
    this.chatComponent = true;
    $('#verchat').show(400);
    $('#regresa').hide(10);
  }

}
