import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
declare var $: any;
import { ApiService } from '../../../../services/apiService';
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2'
import { Router } from '@angular/router';
@Component({
	selector: 'app-viewQuotation',
	templateUrl: './viewQuotation.component.html',
	styleUrls: ['./viewQuotation.component.css'],
	providers: [ApiService]
})
export class ViewQuotationComponent implements OnInit {
	@Input() concretada: any;
	
	public detallecompra: Array<any>; public chatComponent = false;
	// Rating
	max: number = 5;
	rate: number = 0;
	isReadonly: boolean = true;
	maxRatings: number = 5;
	rateRatings: number = 0;
	public ratingComment = '';
	@Output() orderId: EventEmitter<any> = new EventEmitter();


	public rating = {

		companyId: "",
		quotationId: {},
		requestId: {}

	};

	public amount = 0;

	constructor(private _apiService: ApiService, private spinner: NgxSpinnerService, private _router: Router) {

	}

	ngOnInit() {
		console.log('INPUT CONCRETADA');
		console.log(this.concretada);
		this.getAmount();
	}

	createRating() {
		this.spinner.show();
		let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
		let params = {
			access_token: tokenId
		}
		this._apiService.addDataObject('Ratings', this.rating).subscribe(
			(result: any)=> {
				console.log(result);
				setTimeout(() => {

					this.spinner.hide();
					swal({
						title: 'Exito!',
						text: 'Calificado correctamente.',
						type: 'success',
						confirmButtonClass: 'btn ',

						confirmButtonColor: '#000'
					})



				}, 500);

			},
			error => {
				if (error != null) {
					console.log(error);
					setTimeout(() => {

						this.spinner.hide()
						swal({
							title: 'Error!',
							text: 'Error al guardar',
							type: 'error',
							confirmButtonClass: 'btn ',

							confirmButtonColor: '#000'
						})



					}, 250);
				}
			}

		);

	}
	getAmount() {
		var amountTotal = 0;
		this.concretada.purchasesOrders.forEach((order) => {
			order.sellProducts.forEach((product) => {
				amountTotal += product.amount * product.quantity;

			});


		});
		this.amount = amountTotal;
		console.log(amountTotal)
	}
	editPurchaseOrder(order: any) {
		this.spinner.show()
		let id = order.id;
		console.log(id)
		let companyId = localStorage.getItem('$LoopBack$currentCompanyId');

		let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
		let params = {
			access_token: tokenId
		}
		console.log(params)
		this._apiService.editDataObject('PurchaseOrders', id, { delivered: true }, params).subscribe(
			(result: any)=> {

				console.log(result);
				this.spinner.hide()

				swal({
					title: 'Notificado!',
					text: 'La empresa ha sido notificada .',
					type: 'success',
					confirmButtonClass: 'btn ',
					confirmButtonColor: '#000'
				})

			},
			error => {
				if (error != null) {


				}
			}
		);

	}

	confirmProduct(compra) {
		console.log(compra);
		this.orderId.emit(compra);

		
		$('#confirmCreateRating').modal('show');


	}

	concretePurchase() {
		this._router.navigate(['/concretadas/']);

		//$('#chat').show(400);
		$('#entrega').hide(10);
		$('.entrega').hide(10);
	}

	regresa() {
		this.concretada=null;
		$('#chat').hide(10);
		$('#encuesta').hide(10);
		$('#entrega').show(400);
		$('.entrega').show(400);
	}
}

