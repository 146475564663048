import { Component, OnInit, Input } from '@angular/core';
import swal from 'sweetalert2'
import { Router, ActivatedRoute } from '@angular/router';

declare var $: any;
@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.css']
})
export class RequestComponent implements OnInit {
  public editable: boolean = true;
  public paso1: boolean;
  public paso2: boolean;
  public paso3: boolean;
  public datosgrales: Array<any>;
  public usuariosAdmon: Array<any>;
  public personal: Array<any>;
  public cotizacionregular: Array<any>;
  public request;

  constructor(private _router: Router, private route: ActivatedRoute) {



  }

  ngOnInit() {
    let paramId = this.route
      .queryParams
      .subscribe(params => {
        console.log(params);
        if (params.editable) {
          this.editable = params.editable;
          this.request = params.request;
          console.log(this.request);

          this.paso1 = true;
          this.paso2 = false;
          this.paso3 = false;

        }
        // Defaults to 0 if no query param provided.


      });
    console.log(this.editable)
  }

  alertcotizacion() {
    swal("¡Excelente, tu solicitud ha sido realizada!", "Tu supervisor conframá la requisición antes de ser enviada", "success");
  }


  limpia() {
    this.paso1 = !this.paso1;
    this.paso2 = !this.paso2;
  }
  limpia2() {
    this.paso2 = !this.paso2;
    this.paso3 = !this.paso3;
  }
  limpia3() {
    this.paso3 = !this.paso3;
    this.paso1 = !this.paso1;
  }

}
