<!--DETALLE -------------------------->

<div class="zero" [ngSwitch]="categoria" *ngIf="paraaceptadas">
  <!-- PARA ACEPTADAS programar fecha de envio-->
  <div class="row width center margin-buttom-3" *ngSwitchCase="'sended'">
    <div class="zero bg_blanco width">
      <div class="row width center">
        <div class="col-1 col-md-1"></div>
        <div class="col-10 col-md-10 center title">
          COMUNICA LA FECHA DE TU ENVÍO
          <div class="row width zero margin1">
            <div class="col-12 col-md-8 zero">
              <div class="row">
                <div class="col-12 col-md-6 font-8">
                  Fecha de salida de tu producto:
                </div>
                <div class="col-12 col-md-5">
                  <div class="row">
                    <select class="col-4 col-md-4 input black zero">
                      <option class="font1">10</option>
                      <option class="font1">18</option>
                    </select>
                    <select class="col-4 col-md-4 input black zero">
                      <option class="font1">Mar</option>
                      <option class="font1">Jun</option>
                    </select>
                    <select class="col-4 col-md-4 input black zero">
                      <option class="font1">2018</option>
                      <option class="font1">2019</option>
                    </select>
                  </div>
                </div>
                <div class="col-7 col-md-7"></div>
              </div>
              <div class="row zero font-8">&nbsp;</div>
              <div class="row">
                <div class="col-12 col-md-6 font-8">
                  Fecha que recibirán tu producto
                </div>
                <div class="col-12 col-md-5">
                  <div class="row">
                    <select class="col-4 col-md-4 input black zero">
                      <option class="font1">10</option>
                      <option class="font1">18</option>
                    </select>
                    <select class="col-4 col-md-4 input black zero">
                      <option class="font1">Mar</option>
                      <option class="font1">Jun</option>
                    </select>
                    <select class="col-4 col-md-4 input black zero">
                      <option class="font1">2018</option>
                      <option class="font1">2019</option>
                    </select>
                  </div>
                </div>
                <div class="col-7 col-md-7"></div>
              </div>
            </div>
            <button class="col-12 col-md-3 zero button-green">
              <div class="font-8">Confirma entrega</div>
            </button>
          </div>
        </div>
        <div class="col-1 col-md-1"></div>
      </div>
    </div>
  </div>
  <!-- PARA ACEPTADAS venta en camino-->
  <div class="row width center margin-buttom-3" *ngSwitchCase="'sended'">
    <div class="cont_info zero bg_blanco width">
      <div class="row width center">
        <div class="col-0 col-md-1"></div>
        <div class="col-12 col-md-10 center">
          <div class="strong green">
            <i class="fa fa-check-circle-o" aria-hidden="true"></i>
            TU VENTA VA EN CAMINO A TU CLIENTE
          </div>
          <div class="row width margin1">
            <div class="col-12 col-md-7">
              <div class="row">
                <div class="col-12 col-md-6 font-8">
                  Fecha de salida de tu producto:
                </div>
                <div class="col-12 col-md-5">
                  <div class="row">
                    <select class="col-4 col-md-4 input black zero">
                      <option class="font1">10</option>
                      <option class="font1">18</option>
                    </select>
                    <select class="col-4 col-md-4 input black zero">
                      <option class="font1">Mar</option>
                      <option class="font1">Jun</option>
                    </select>
                    <select class="col-4 col-md-4 input black zero">
                      <option class="font1">2018</option>
                      <option class="font1">2019</option>
                    </select>
                  </div>
                </div>
                <div class="col-0 col-md-7"></div>
              </div>
              <div class="row zero font-8">&nbsp;</div>
              <div class="row">
                <div class="col-12 col-md-6 font-8">
                  Fecha que recibirán tu producto
                </div>
                <div class="col-12 col-md-5">
                  <div class="row">
                    <select class="col-4 col-md-4 input black zero">
                      <option class="font1">10</option>
                      <option class="font1">18</option>
                    </select>
                    <select class="col-4 col-md-4 input black zero">
                      <option class="font1">Mar</option>
                      <option class="font1">Jun</option>
                    </select>
                    <select class="col-4 col-md-4 input black zero">
                      <option class="font1">2018</option>
                      <option class="font1">2019</option>
                    </select>
                  </div>
                </div>
                <div class="col-0 col-md-7"></div>
              </div>
            </div>
            <div class="col-12 col-md-5">
              <div class="font-8">
                ¿Algo salió mal con el envío?
                <button
                  class="strong button-green whidth font-8 padding1"
                  style="height: auto"
                >
                  Envío un mensaje a
                  <span class="strong">Cemex</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-1 col-md-1"></div>
      </div>
    </div>
  </div>
  <!-- PARA ACEPTADAS confirmar entrega-->
  <div class="row width center margin-buttom-3" *ngSwitchCase="'entrega'">
    <div class="zero bg_blanco width">
      <div class="row width center">
        <div class="col-1 col-md-1"></div>
        <div class="col-10 col-md-10 center title padding-top1">
          CONFIRMA TU ENTREGA
          <div class="row width margin1">
            <div class="col-12 col-md-8 zero padding1">
              <div class="row padding1">
                <div class="col-12 col-md-6 font-8">
                  Fecha que entregaste tu producto
                </div>
                <div class="col-12 col-md-5">
                  <div class="row">
                    <select class="col-4 col-md-4 input black zero">
                      <option class="font1">10</option>
                      <option class="font1">18</option>
                    </select>
                    <select class="col-4 col-md-4 input black zero">
                      <option class="font1">Mar</option>
                      <option class="font1">Jun</option>
                    </select>
                    <select class="col-4 col-md-4 input black zero">
                      <option class="font1">2018</option>
                      <option class="font1">2019</option>
                    </select>
                  </div>
                </div>
                <div class="col-0 col-md-7"></div>
              </div>
            </div>
            <!-- <div class="col-4 col-md-4 btnFinal font-8 bg_verde">Confirma entrega</div> -->
            <button class="col-12 col-md-3 zero button-green">
              <div class="font-8">Confirma entrega</div>
            </button>
          </div>
        </div>
        <div class="col-1 col-md-1"></div>
      </div>
    </div>
  </div>
  <!-- PARA ACEPTADAS contestar encuesta-->
  <div class="row width center margin-buttom-3" *ngSwitchCase="'encuesta'">
    <div class="cont_info zero bg_blanco width">
      <div class="row width center">
        <div class="col-0 col-md-1"></div>
        <div class="col-12 col-md-10 center title">
          Nuestra meta en Ixcco es que tu empresa solo realice compras y ventas
          de manera inteligente, por lo que mejoramos al escuchar tu opinión
          contestando la siguiente encuensta
          <div class="row width margin1">
            <div class="col-0 col-md-4"></div>
            <div
              class="col-11 col-md-4 button-green font-8 padding-top05"
              data-toggle="modal bg_verde"
              data-target="#llenaencuesta"
            >
              Contestar encuesta
            </div>
            <div class="col-12 col-md-4"></div>
          </div>
        </div>
        <div class="col-0 col-md-1"></div>
      </div>
    </div>
  </div>
  <!-- PARA ACEPTADAS contestar encuesta-->
  <div class="row zero" *ngSwitchCase="'nueva'"></div>
</div>

<div class="zero">
  <div class="row zero" [ngSwitch]="fueenviada" *ngIf="paraenviada2">
    <!-- PARA BLOQUE SOLO PARA ENVIADAS cotizaciones tiempo-->
    <div class="row width center margin-buttom-3" *ngSwitchCase="'tiempo'">
      <div class="zero bg_blanco width">
        <div class="row width center">
          <div class="col-2 col-md-2"></div>
          <div class="col-8 col-md-8 center strong green-title">
            <i class="fa fa-clock-o" aria-hidden="true"></i>
            Recibirás resultados de la decisión del comprador el día 17/Abr/18
          </div>
          <div class="col-2 col-md-2"></div>
        </div>
      </div>
    </div>
    <!-- PARA BLOQUE SOLO PARA ENVIADAS cotizaciones resultado-->
    <div class="row width center margin-32" *ngSwitchCase="'resultado'">
      <div class="cont_info zero width">
        <div class="row width center">
          <div class="col-6 col-md-6 zero">
            <div class="row bg_blanco cont-detalles">
              <div class="row width center">
                <div class="col-2 col-md-2"></div>
                <div class="col-8 col-md-8 center zero">
                  <p class="font-8 zero center">
                    <span class="strong"> RESULTADOS DE TU COTIZACIÓN </span>
                  </p>
                  <div class="row zero pad6">
                    <div class="col-6 col-md-6">
                      <div class="row zero">
                        <div class="col-1 col-md-1 zero right text">
                          <i
                            class="fa fa-money text-bar"
                            aria-hidden="true"
                          ></i>
                        </div>
                        <div class="col-9 col-md-9 zero left">
                          <div class="zero">
                            <div class="stat-1 stat-bar">
                              <span
                                class="stat-bar-rating width10"
                                role="stat-bar"
                              ></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-2 col-md-2 zero">
                          <p class="zero left number-bar">10</p>
                        </div>
                      </div>
                      <div class="row zero">
                        <div class="col-1 col-md-1 zero right text">
                          <i class="fa fa-truck" aria-hidden="true"></i>
                        </div>
                        <div class="col-9 col-md-9 zero left">
                          <div class="zero">
                            <div class="stat-1 stat-bar">
                              <span
                                class="stat-bar-rating width10"
                                role="stat-bar"
                              ></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-2 col-md-2 zero">
                          <p class="zero left number-bar">10</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-md-6">
                      <div class="row zero">
                        <div class="col-1 col-md-1 zero right text">
                          <i class="fa fa-credit-card" aria-hidden="true"></i>
                        </div>
                        <div class="col-9 col-md-9 zero left">
                          <div class="zero">
                            <div class="stat-1 stat-bar">
                              <span
                                class="stat-bar-rating width10"
                                role="stat-bar"
                              ></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-2 col-md-2 zero">
                          <p class="zero left number-bar">10</p>
                        </div>
                      </div>
                      <div class="row zero">
                        <div class="col-1 col-md-1 zero right text">
                          <i class="fa fa-bookmark" aria-hidden="true"></i>
                        </div>
                        <div class="col-9 col-md-9 zero left">
                          <div class="zero">
                            <div class="stat-1 stat-bar">
                              <span
                                class="stat-bar-rating width10"
                                role="stat-bar"
                              ></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-2 col-md-2 zero">
                          <p class="zero left number-bar">10</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p class="font-8 zero center">
                    Motivo de tu rechazo:
                    <span class="strong"> Tu entrega fue muy tardía</span>
                  </p>
                </div>
                <div class="col-2 col-md-2"></div>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-6 zero">
            <div class="row bg_blanco center contenedor-detalles">
              <div class="row width center">
                <div class="col-2 col-md-2"></div>
                <div class="col-8 col-md-8 center zero">
                  <p class="font-8 zero center">
                    <span class="strong">
                      RESULTADOS DEL PROVEEDOR ELEGIDO
                    </span>
                  </p>
                  <div class="row zero pad6">
                    <div class="col-6 col-md-6">
                      <div class="row zero">
                        <div class="col-1 col-md-1 zero right text">
                          <i
                            class="fa fa-money pad5 text-bar"
                            aria-hidden="true"
                          ></i>
                        </div>
                        <div class="col-9 col-md-9 zero left">
                          <div class="zero">
                            <div class="stat-1 stat-bar">
                              <span
                                class="stat-bar-rating width80"
                                role="stat-bar"
                              ></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-2 col-md-2 zero">
                          <p class="zero left number-bar">10</p>
                        </div>
                      </div>
                      <div class="row zero">
                        <div class="col-1 col-md-1 zero right text">
                          <i class="fa fa-truck" aria-hidden="true"></i>
                        </div>
                        <div class="col-9 col-md-9 zero left">
                          <div class="zero">
                            <div class="stat-1 stat-bar">
                              <span
                                class="stat-bar-rating width40"
                                role="stat-bar"
                              ></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-2 col-md-2 zero">
                          <p class="zero left number-bar">10</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-md-6">
                      <div class="row zero">
                        <div class="col-1 col-md-1 zero right text">
                          <i class="fa fa-credit-card" aria-hidden="true"></i>
                        </div>
                        <div class="col-9 col-md-9 zero left">
                          <div class="zero">
                            <div class="stat-1 stat-bar">
                              <span
                                class="stat-bar-rating width10"
                                role="stat-bar"
                              ></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-2 col-md-2 zero">
                          <p class="zero left number-bar">10</p>
                        </div>
                      </div>
                      <div class="row zero">
                        <div class="col-1 col-md-1 zero right text">
                          <i class="fa fa-bookmark" aria-hidden="true"></i>
                        </div>
                        <div class="col-9 col-md-9 zero left">
                          <div class="zero">
                            <div class="stat-1 stat-bar">
                              <span
                                class="stat-bar-rating width60"
                                role="stat-bar"
                              ></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-2 col-md-2 zero">
                          <p class="zero left number-bar">10</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p class="font-8 zero center">
                    Motivo de tu rechazo:
                    <span class="strong"> Tu entrega fue muy tardía</span>
                  </p>
                </div>
                <div class="col-2 col-md-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- PARA BLOQUE SOLO PARA ENVIADAS   -->

  <div *ngIf="selectedRequest" class="cont_info zero bg_blanco" id="detalle">
    <div class="tab-content marg-5" id="pills-tabContent">
      <div
        class="tab-pane fade show active"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <div class="row marg-pad-bloque">
          <div class="row width" style="padding-top: 20px">
            <div class="col-3 col-md-1 zero font-8">
              <img
                *ngIf="selectedRequest.company.profileUrl"
                class="logo-brand-img"
                [src]="selectedRequest.company.profileUrl"
              />
              <img
                *ngIf="!selectedRequest.company.profileUrl"
                class="logo-brand-img"
                src="../../../assets/images/fakeCompany.png"
              />
            </div>
            <div class="col-9 col-md-2 zero collapse_title">
              <p class="col-5 col-md-12 font-8 strong zero center marginTop">
                {{ selectedRequest.company.name }}
              </p>
            </div>

            <div class="col-7 col-md-3 font-8 left">
              {{ selectedRequest.company.companyInfo.city }}
              <br />
              Ver las
              <span>
                <span class="strong"> 883 </span> reseñas
                <strong>
                  <i class="fa fa-chevron-right" aria-hidden="true"></i>
                </strong>
              </span>
            </div>
            <div class="col-4 col-md-1 font-8 left zero">
              <br />
            </div>
            <div class="col-6 col-md-2 font-8 detail-send">
              <p
                *ngIf="!selectedRequest.company.fileUrl"
                class="strong padding-top1"
                style="margin-top: 10px"
              >
                Ver currículum
              </p>
              <p
                *ngIf="selectedRequest.company.fileUrl"
                class="strong padding-top1"
                (click)="openFileCompany(selectedRequest.company.fileUrl)"
                style="margin-top: 10px"
              >
                Ver currículum
              </p>
            </div>
            <div class="col-6 col-md-3 font-8 detail-send">
              <select
                [disabled]="!paranueva"
                [(ngModel)]="currency"
                (ngModelChange)="currencyChanged($event)"
                style="margin-top: 25px"
                class="form-control"
              >
                <option
                  value="{{ currency }}"
                  *ngFor="let currency of currencies; let i = index"
                >
                  {{ currency }}
                </option>
              </select>
            </div>
          </div>

          <div
            class="row width line"
            *ngFor="
              let product of selectedRequest.requestProducts;
              let i = index
            "
          >
            <div class="col-6 col-md-3">
              <div class="row width zero">
                <div class="col-12 col-md-3 font-8">
                  <div
                    class="logo-company"
                    style="background-image: url(assets/images/fakeProduct.png)"
                  ></div>
                </div>
                <div class="col-12 col-md-9 zero font-8">
                  <p class="zero font-8 detail-send">Nombre del producto</p>
                  <p
                    *ngIf="product.name"
                    [tooltip]="product.name"
                    class="strong zero noSpaceText"
                    style="color: black; max-height: 55px; overflow-y: hidden"
                  >
                    {{ product.name }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-6 col-md-3">
              <div class="row">
                <div class="col-12 col-md-5 black font-8">
                  <p class="zero font-8">Especificación</p>
                  <div *ngIf="product.specification">
                    <p *ngIf="product.specification.unit" class="strong zero">
                      {{ product.specification.unit }}
                    </p>
                  </div>
                  <div
                    *ngIf="!product.specification && product.specificationName"
                  >
                    <p class="strong zero">{{ product.specificationName }}</p>
                  </div>
                </div>
                <div class="col-12 col-md-3 black font-8">
                  <p class="zero font-8 d">Cantidad</p>
                  <p *ngIf="product.quantity" class="strong zero">
                    {{ product.quantity }}
                  </p>
                </div>
                <div class="col-12 col-md-3 black font-8">
                  <p class="zero font-8">Unidad</p>
                  <p *ngIf="product.unit" class="strong zero">
                    {{ product.unit.name }}
                  </p>
                  <p
                    *ngIf="!product.unit && product.unitName"
                    class="strong zero"
                  >
                    {{ product.unitName }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-2 black font-8">
              <p class="zero font-8">Caracteristicas</p>
              <div class="font-8">
                <p
                  *ngIf="product.comments"
                  class="strong read-more"
                  [tooltip]="product.comments"
                  placement="bottom"
                >
                  {{ product.comments }}
                </p>
              </div>
            </div>
            <div class="col-12 col-md-4 zero black font-8">
              <div class="row">
                <div class="col-12 col-md-7 black">
                  <p
                    *ngIf="paranueva"
                    class="zero font-8"
                    style="margin-right: 50px"
                  >
                    Precio unitario
                  </p>
                  <div *ngIf="paranueva" class="row">
                    <div class="col-0">
                      <p style="margin-top: 5px" class="strong font-8"></p>
                    </div>
                    <div class="col-10" style="padding-left: 0px">
                      <input
                        class="width input center font-8 pointer"
                        style="font-size: 1em; font-weight: bold"
                        type="text"
                        name="amount"
                        [(ngModel)]="product.amount"
                        (blur)="transformAmount($event, product.amount)"
                        (focus)="
                          removeCurrencyPipeFormat($event, product.amount)
                        "
                        required
                        pattern="[0-9]+"
                        #amount="ngModel"
                      />
                      <p style="margin-top: 5px" class="strong font-7">+IVA</p>
                      <p
                        *ngIf="paranueva"
                        style="margin-top: 5px"
                        class="strong font-7"
                      >
                        Total: {{ product.amount * product.quantity }}
                      </p>
                    </div>
                    <div class="col-1"></div>
                  </div>
                  <ng-container *ngIf="selectedRequest.quotations">
                    <div *ngIf="!paranueva" class="row">
                      <div class="col-8" style="padding-left: 0px">
                        <p *ngIf="onlyViewForSales" class="strong font-8">
                          <input
                            class="width input center font-8 pointer"
                            style="font-size: 1em; font-weight: bold"
                            type="text"
                            name="amount"
                            [(ngModel)]="
                              selectedRequest.quotations[0].quotedProducts[i]
                                .amount
                            "
                            (blur)="
                              transformAmount(
                                $event,
                                selectedRequest.quotations[0].quotedProducts[i]
                                  .amount
                              )
                            "
                            (focus)="
                              removeCurrencyPipeFormat(
                                $event,
                                selectedRequest.quotations[0].quotedProducts[i]
                                  .amount
                              )
                            "
                            required
                            pattern="[0-9]+"
                            #amount="ngModel"
                          />
                        </p>
                        <p
                          *ngIf="!paranueva && selectedRequest.quotations[0]"
                          style="margin-top: 5px"
                          class="strong font-7"
                        >
                          Total:
                          {{
                            selectedRequest.quotations[0].quotedProducts[i]
                              .amount * product.quantity
                          }}
                        </p>
                      </div>
                      <div class="col-1">
                        <p
                          *ngIf="onlyViewForSales"
                          style="margin-top: 5px"
                          class="strong font-8"
                        >
                          +IVA
                        </p>
                      </div>

                      <div class="col-2">
                        <p
                          *ngIf="onlyViewForSales"
                          style="margin-top: 5px; margin-left: 5px"
                          class="strong font-8"
                        >
                          {{
                            selectedRequest.quotations[0].quotedProducts[i]
                              .brand
                          }}
                        </p>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div
                  *ngIf="selectedRequest.contestLabel === 'contest'"
                  class="col-12 col-md-7 black"
                ></div>
                <div
                  *ngIf="
                    paranueva && selectedRequest.contestLabel === 'request'
                  "
                  class="col-6 col-md-2 zero black font-8"
                  style="margin-left: 20px"
                >
                  <input
                    *ngIf="paranueva"
                    class="width input center font-8"
                    style="font-size: 1em; font-weight: bold; margin-top: 18px"
                    type="text"
                    name="brand"
                    [(ngModel)]="product.brand"
                    required
                    placeholder="Marca"
                    #brand="ngModel"
                  />
                </div>
                <div class="col-6 col-md-1 zero black font-8">
                  <button *ngIf="product.productURL" class="font-8 button-add">
                    <a
                      (click)="openProductFile(product.productURL)"
                      style="color: #000"
                      target="_blank"
                    >
                      Archivo
                      <i
                        class="fa fa-arrow-circle-o-down button-add-clipboard font-2"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="paranueva" class="row new line upload"></div>
          <div *ngIf="paranueva" class="row width line upload">
            <div class="col-12 col-md-23">
              <p class="textES texted">
                Descarga el Excel y subela con los precios de tu cotización
              </p>
            </div>

            <div class="col-3 col-md-3 zero"></div>

            <div class="col-12 col-md-3" style="margin-right: 20px">
              <a
                (click)="populateExcel()"
                class="btn btn-outline-secondary btn-personalized"
                target="_self"
                >Descargar Excel
              </a>
            </div>
            <div class="col-12 col-md-3" style="margin-right: 20px">
              <div
                class="fileUpload btn btn-outline-secondary btn-personalized"
              >
                <span>Cargar Excel</span>
                <input
                  type="file"
                  class="upload"
                  (change)="onFileChangeContest($event)"
                  multiple="false"
                />
              </div>
            </div>
            <div class="col-3 col-md-3 zero"></div>
          </div>
          <!-- DRAG N DROP -->

          <div
            *ngIf="paranueva && selectedRequest.contestLabel === 'contest'"
            class="row width line upload"
          >
            <div class="row new">
              <p class="textES texted">
                Sube los siguientes archivos requeridos por el cliente
              </p>
            </div>

            <div class="row width">
              <div class="col-3">
                <div
                  class="upload-btn-wrapper"
                  *ngIf="selectedRequest.requiredFiles.securityCheck"
                >
                  <button
                    class="btn btn-outline-secondary btn-personalized"
                    style="color: black !important"
                    [ngClass]="{ 'btn-green': addedComponent }"
                  >
                    Matrices PU
                    <i class="fa fa-paperclip" aria-hidden="true"></i>
                  </button>
                  <input
                    type="file"
                    ng2FileSelect
                    multiple
                    [uploader]="uploader"
                    (onFileSelected)="fileOverBase1($event)"
                  />
                </div>
                <!-- 	<div ng2FileDrop *ngIf="selectedRequest.requiredFiles.securityCheck"
									[ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase1($event)"
									[uploader]="uploader" class="well my-drop-zone">
									Análisis de Seguridad
								</div> -->
              </div>
              <div class="col-3">
                <div
                  class="upload-btn-wrapper"
                  *ngIf="selectedRequest.requiredFiles.suppliesCheck"
                >
                  <button
                    class="btn btn-outline-secondary btn-personalized"
                    style="color: black !important"
                    [ngClass]="{ 'btn-green': addedComponent }"
                  >
                    Explosión de insumos
                    <i class="fa fa-paperclip" aria-hidden="true"></i>
                  </button>
                  <input
                    type="file"
                    ng2FileSelect
                    multiple
                    [uploader]="uploader2"
                    (onFileSelected)="fileOverBase2($event)"
                  />
                </div>
                <!-- 	<div ng2FileDrop *ngIf="selectedRequest.requiredFiles.suppliesCheck"
									[ngClass]="{'nv-file-over2': hasBaseDropZoneOver2}"
									(fileOver)="fileOverBase2($event)" [uploader]="uploader2" class="well my-drop-zone">
									Explosión de insumos
								</div> -->
              </div>
              <div class="col-3">
                <div
                  class="upload-btn-wrapper"
                  *ngIf="selectedRequest.requiredFiles.tecnicsCheck"
                >
                  <button
                    class="btn btn-outline-secondary btn-personalized"
                    style="color: black !important"
                    [ngClass]="{ 'btn-green': addedComponent }"
                  >
                    Propuesta Técnica
                    <i class="fa fa-paperclip" aria-hidden="true"></i>
                  </button>
                  <input
                    type="file"
                    ng2FileSelect
                    multiple
                    [uploader]="uploader3"
                    (onFileSelected)="fileOverBase3($event)"
                  />
                </div>
                <!-- <div ng2FileDrop *ngIf="selectedRequest.requiredFiles.tecnicsCheck"
									[ngClass]="{'nv-file-over3': hasBaseDropZoneOver3}"
									(fileOver)="fileOverBase3($event)" [uploader]="uploader3" class="well my-drop-zone">
									Propuesta Técnica
								</div> -->
              </div>
              <div class="col-3">
                <div
                  class="upload-btn-wrapper"
                  *ngIf="selectedRequest.requiredFiles.valueCheck"
                >
                  <button
                    class="btn btn-outline-secondary btn-personalized"
                    style="color: black !important"
                    [ngClass]="{ 'btn-green': addedComponent }"
                  >
                    Ingeniería de Valor
                    <i class="fa fa-paperclip" aria-hidden="true"></i>
                  </button>
                  <input
                    type="file"
                    ng2FileSelect
                    multiple
                    [uploader]="uploader4"
                    (onFileSelected)="fileOverBase4($event)"
                  />
                </div>
                <!-- 	<div ng2FileDrop *ngIf="selectedRequest.requiredFiles.valueCheck"
									[ngClass]="{'nv-file-over4': hasBaseDropZoneOver4}"
									(fileOver)="fileOverBase4($event)" [uploader]="uploader4" class="well my-drop-zone">
									Ingeniería de Valor
								</div> -->
              </div>
            </div>
          </div>
          <div
            *ngIf="paranueva && selectedRequest.contestLabel === 'request'"
            class="row width line upload"
          >
            <div class="row new">
              <p class="textES texted">
                Puedes subir hasta 4 archivos de información para el cliente
              </p>
            </div>

            <div class="row width">
              <div class="col-3">
                <div
                  class="upload-btn-wrapper"
                  *ngIf="selectedRequest.requiredFiles.securityCheck"
                >
                  <button
                    class="btn btn-outline-secondary btn-personalized"
                    style="color: black !important"
                    [ngClass]="{ 'btn-green': addedComponent }"
                  >
                    Archivo 1
                    <i class="fa fa-paperclip" aria-hidden="true"></i>
                  </button>
                  <input
                    type="file"
                    ng2FileSelect
                    multiple
                    [uploader]="uploader"
                    (onFileSelected)="fileOverBase1($event)"
                  />
                </div>
              </div>
              <div class="col-3">
                <div
                  class="upload-btn-wrapper"
                  *ngIf="selectedRequest.requiredFiles.suppliesCheck"
                >
                  <button
                    class="btn btn-outline-secondary btn-personalized"
                    style="color: black !important"
                    [ngClass]="{ 'btn-green': addedComponent }"
                  >
                    Archivo 2
                    <i class="fa fa-paperclip" aria-hidden="true"></i>
                  </button>
                  <input
                    type="file"
                    ng2FileSelect
                    multiple
                    [uploader]="uploader2"
                    (onFileSelected)="fileOverBase2($event)"
                  />
                </div>
              </div>
              <div class="col-3">
                <div
                  class="upload-btn-wrapper"
                  *ngIf="selectedRequest.requiredFiles.tecnicsCheck"
                >
                  <button
                    class="btn btn-outline-secondary btn-personalized"
                    style="color: black !important"
                    [ngClass]="{ 'btn-green': addedComponent }"
                  >
                    Archivo 3
                    <i class="fa fa-paperclip" aria-hidden="true"></i>
                  </button>
                  <input
                    type="file"
                    ng2FileSelect
                    multiple
                    [uploader]="uploader3"
                    (onFileSelected)="fileOverBase3($event)"
                  />
                </div>
              </div>
              <div class="col-3">
                <div
                  class="upload-btn-wrapper"
                  *ngIf="selectedRequest.requiredFiles.valueCheck"
                >
                  <button
                    class="btn btn-outline-secondary btn-personalized"
                    style="color: black !important"
                    [ngClass]="{ 'btn-green': addedComponent }"
                  >
                    Archivo 4
                    <i class="fa fa-paperclip" aria-hidden="true"></i>
                  </button>
                  <input
                    type="file"
                    ng2FileSelect
                    multiple
                    [uploader]="uploader4"
                    (onFileSelected)="fileOverBase4($event)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- DRAG N DROP -->
        <div *ngIf="paranueva" class="row">
          <div class="col-3">
            <div
              style="height: 230px; width: 240px; overflow: hidden"
              class="row background-gray padding1"
            >
              <div class="row width upload">
                <div class="col-12">
                  <p>Documento</p>
                  <p
                    [tooltip]="item?.file?.name"
                    *ngFor="let item of uploader.queue"
                    style="width: 190px; overflow: hidden; height: 50px"
                  >
                    {{ item?.file?.name }}
                  </p>
                  <hr />
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div
              style="height: 230px; width: 240px; overflow: hidden"
              class="row background-gray padding1"
            >
              <div class="row width upload">
                <div class="col-12">
                  <p>Documento</p>
                  <p
                    [tooltip]="item?.file?.name"
                    *ngFor="let item of uploader2.queue"
                    style="width: 190px; overflow: hidden; height: 50px"
                  >
                    {{ item?.file?.name }}
                  </p>
                  <hr />
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div
              style="height: 230px; width: 240px; overflow: hidden"
              class="row background-gray padding1"
            >
              <div class="row width upload">
                <div class="col-12">
                  <p>Documento</p>
                  <p
                    [tooltip]="item?.file?.name"
                    *ngFor="let item of uploader3.queue"
                    style="width: 190px; overflow: hidden; height: 50px"
                  >
                    {{ item?.file?.name }}
                  </p>
                  <hr />
                </div>
              </div>
            </div>
          </div>

          <div class="col-3">
            <div
              style="height: 230px; width: 240px; overflow: hidden"
              class="row background-gray padding1"
            >
              <div class="row width upload">
                <div class="col-12">
                  <p>Documento</p>
                  <p
                    [tooltip]="item?.file?.name"
                    *ngFor="let item of uploader4.queue"
                    style="width: 190px; overflow: hidden; height: 50px"
                  >
                    {{ item?.file?.name }}
                  </p>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="line row"></div>
    <!--  *ngIf="selectedRequest.contestLabel==='request'" -->
    <div id="accordion">
      <div class="card">
        <div class="card-header" id="headingOne">
          <h5 class="mb-0">
            <div
              class="collapse_title black"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              DETALLE DEL PEDIDO
            </div>
          </h5>
        </div>
        <!-- ACORDION -->

        <div
          id="collapseOne"
          class="collapse show"
          aria-labelledby="headingOne"
          data-parent="#accordion"
        >
          <div class="card-body">
            <div class="row width">
              <div class="col-12 col-md-6 zero">
                <p class="zero left font-7">Detalle del pedido</p>
                <p
                  *ngIf="selectedRequest.details"
                  class="zero left strong font-8 details"
                >
                  {{ selectedRequest.details }}
                </p>
                <p
                  *ngIf="!selectedRequest.details"
                  class="zero left strong font-8 details"
                >
                  Sin detalles
                </p>

                <p
                  *ngIf="selectedRequest.contestLabel === 'request'"
                  class="zero left font-7"
                >
                  Margen de tiempo para entregar el producto
                </p>
                <div class="row texts font1 zero width">
                  <div class="col-12 col-md-3 zero">
                    <p
                      *ngIf="selectedRequest.contestLabel === 'request'"
                      class="zero left font-7"
                    >
                      Entre el día:
                    </p>
                    <p
                      *ngIf="
                        selectedRequest.contestLabel === 'contest' &&
                        selectedRequest.radioCheckVisit
                      "
                      class="zero left font-7"
                    >
                      Visita de obra:
                    </p>
                  </div>
                  <div class="col-12 col-md-6 zero">
                    <p
                      *ngIf="selectedRequest.contestLabel === 'request'"
                      class="zero left texts strong font-8"
                    >
                      {{
                        selectedRequest.startDeliveryDate
                          | date : "dd/MM/yyyy HH:mm"
                      }}
                    </p>
                    <p
                      *ngIf="
                        selectedRequest.contestLabel === 'contest' &&
                        selectedRequest.radioCheckVisit
                      "
                      class="zero left texts strong font-8"
                    >
                      {{
                        selectedRequest.startDeliveryDate
                          | date : "dd/MM/yyyy HH:mm"
                      }}
                    </p>
                  </div>
                </div>
                <div class="row texts font1 zero width">
                  <div class="col-12 col-md-3 zero">
                    <p
                      *ngIf="selectedRequest.contestLabel === 'request'"
                      class="zero left font-7"
                    >
                      Y el día:
                    </p>
                    <p
                      *ngIf="
                        selectedRequest.contestLabel === 'contest' &&
                        selectedRequest.radioCheckGeneral
                      "
                      class="zero left font-7"
                    >
                      Aclaracion General:
                    </p>
                  </div>
                  <div class="col-12 col-md-6 zero">
                    <p
                      *ngIf="selectedRequest.contestLabel === 'request'"
                      class="zero left texts strong font-8"
                    >
                      {{
                        selectedRequest.endDeliveryDate
                          | date : "dd/MM/yyyy HH:mm"
                      }}
                    </p>
                    <p
                      *ngIf="
                        selectedRequest.contestLabel === 'contest' &&
                        selectedRequest.radioCheckGeneral
                      "
                      class="zero left texts strong font-8"
                    >
                      {{
                        selectedRequest.endDeliveryDate
                          | date : "dd/MM/yyyy HH:mm"
                      }}
                    </p>
                  </div>
                </div>
                <p class="zero left font-7" style="margin-top: 10px">
                  Condiciones de pago:
                </p>
                <div class="row texts zero marginTop15">
                  <div
                    *ngIf="selectedRequest.paymentOptions"
                    class="col-12 col-md-3 zero size9 left"
                  >
                    <p>
                      <span class="strong"
                        >{{ selectedRequest.paymentOptions.anticipated }}%</span
                      >
                      Anticipo
                    </p>
                  </div>
                  <div
                    *ngIf="selectedRequest.paymentOptions"
                    class="col-12 col-md-4 zero size9 left"
                  >
                    <p *ngIf="selectedRequest.contestLabel === 'request'">
                      <span class="strong"
                        >{{
                          selectedRequest.paymentOptions.uponDelivery
                        }}%</span
                      >
                      Contraentrega
                    </p>
                    <p *ngIf="selectedRequest.contestLabel === 'contest'">
                      <span class="strong"
                        >{{
                          selectedRequest.paymentOptions.uponDelivery
                        }}%</span
                      >
                      Estimaciones
                    </p>
                  </div>
                  <div
                    *ngIf="selectedRequest.paymentOptions"
                    class="col-12 col-md-5 zero size9 left"
                  >
                    <p *ngIf="selectedRequest.contestLabel === 'request'">
                      <span class="strong"
                        >{{ selectedRequest.paymentOptions.credit }}%</span
                      >
                      Crédito por
                      <span class="strong">{{
                        selectedRequest.paymentOptions.days
                      }}</span>
                      días
                    </p>
                    <p *ngIf="selectedRequest.contestLabel === 'contest'">
                      <span class="strong"
                        >{{ selectedRequest.paymentOptions.credit }}%</span
                      >
                      Finiquito
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="row texts font1 zero width marginTop10">
                  <div style="padding-left: 0" class="col-12 col-md-7">
                    <p
                      class="zero left font-7"
                      *ngIf="selectedRequest.contestLabel === 'request'"
                    >
                      Fecha y hora límite para cotizar:
                    </p>
                    <p
                      class="zero left font-7"
                      *ngIf="selectedRequest.contestLabel === 'contest'"
                    >
                      Fecha y hora límite para responder:
                    </p>
                  </div>
                  <div class="col-12 col-md-5 zero">
                    <p class="zero left texts strong font-8">
                      {{
                        selectedRequest.limitQuotationDate
                          | date : "dd/MM/yyyy HH:mm"
                      }}
                    </p>
                  </div>
                </div>
                <p
                  *ngIf="selectedRequest.contestLabel === 'request'"
                  style="margin-top: 15px"
                  class="zero left font-7"
                >
                  Lugar de entrega de producto:
                </p>
                <label
                  *ngIf="selectedRequest.contestLabel === 'request'"
                  class="zero left texts strong width"
                >
                  {{ selectedRequest.selectedAddress }}
                </label>
                <div
                  style="margin-top: 10px"
                  class="zero left texts width font-7"
                >
                  <div
                    *ngIf="selectedRequest.contestLabel === 'request'"
                    class="zero left texts width font-7"
                  >
                    Puedo pasar por el producto en un radio de
                    <label class="zero left texts strong">{{
                      selectedRequest.radius
                    }}</label>
                    km
                  </div>
                </div>
                <div *ngIf="selectedRequest" class="row">
                  <div class="col-12">
                    <div id="map" #gmap class="mapStyle"></div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <table
                  *ngIf="selectedRequest.infoFiles.files"
                  class="table"
                  style="margin-bottom: 10px"
                >
                  <thead>
                    <tr>
                      <th width="50%" style="text-align: center">Archivos</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let item of selectedRequest.infoFiles.files;
                        let i = index
                      "
                    >
                      <td width="50%">
                        <a href="{{ item.url }}" target="_blank" download>{{
                          item.name
                        }}</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- Los botones Aceptar  -->
              <div class="row width margin1" *ngIf="!paranueva">
                <div class="col-0 col-md-3"></div>
                <button
                  *ngIf="!onlyViewForSales"
                  (click)="rejectRequest()"
                  class="col-5 col-md-3 button-grey"
                  style="
                    background-color: grey;
                    margin-right: 10px;
                    color: white;
                  "
                >
                  Rechazar
                </button>
                <button
                  *ngIf="!onlyViewForSales"
                  (click)="acceptRequest()"
                  class="col-6 col-md-3 button-grey"
                  style="background-color: #447f19; color: white"
                >
                  Acepto, quiero cotizar
                </button>
                <div class="col-6 col-md-3"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- ACORDION -->
        <!-- PARA BLOQUE ENVIADAS -->
        <div class="card" *ngIf="bloque">
          <div class="card-header" id="headingTwo">
            <h5 class="mb-0">
              <div
                *ngIf="selectedRequest.contestLabel === 'request'"
                class="collapse_title black"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                DETALLES SOBRE TU ENVÍO
              </div>
            </h5>
          </div>
          <div
            *ngIf="selectedRequest.contestLabel === 'request'"
            id="collapseTwo"
            class="collapse show"
            aria-labelledby="headingTwo"
            data-parent="#accordion"
          >
            <div class="card-body">
              <div class="row">
                <div *ngIf="paranueva" class="col-12 col-md-6">
                  <p *ngIf="paranueva" class="zero left font-7 marginTop10">
                    Tus Condiciones de pago:
                  </p>

                  <div *ngIf="paranueva" class="row marginTop10">
                    <div class="col-6 col-md-6 zero">
                      <div class="row">
                        <div class="col-4 col-md-4 zero">
                          <input
                            [(ngModel)]="paymentOptions.anticipated"
                            name="anticipado"
                            #anticipado="ngModel"
                            class="input text-center"
                            type="number"
                          />
                        </div>

                        <div class="col-1 col-md-1 zero">
                          <p class="font-8 strong">%</p>
                        </div>
                        <div class="col-7 col-md-6 zero">
                          <p class="font-8 strong">Anticipo</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-md-6 zero">
                      <div class="row">
                        <div class="col-3 col-md-4 zero">
                          <input
                            [(ngModel)]="paymentOptions.uponDelivery"
                            name="contraentrega"
                            #contraentrega="ngModel"
                            class="input text-center"
                            type="number"
                          />
                        </div>
                        <div class="col-1 col-md-1 zero">
                          <p class="font-8 strong">%</p>
                        </div>
                        <div class="col-8 col-md-6 zero">
                          <p class="font-8 strong">Contraentrega</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-md-12 zero marginTop20">
                      <div class="row">
                        <div class="col-2 col-md-2 zero">
                          <input
                            [(ngModel)]="paymentOptions.credit"
                            style="
                              border: solid transparent;
                              border-bottom: solid 0.2em #e0e1e2;
                              font-size: 0.7em;
                              width: 100%;
                            "
                            name="credito"
                            #credito="ngModel"
                            class="input text-center"
                            type="number"
                          />
                        </div>
                        <div class="col-1 col-md-1 zero">
                          <p class="font-8 strong">%</p>
                        </div>
                        <div class="col-4 col-md-3 zero center">
                          <p class="font-8 strong">Crédito por</p>
                        </div>
                        <div class="col-3 col-md-2 zero">
                          <input
                            [(ngModel)]="paymentOptions.days"
                            [disabled]="!paymentOptions.credit > 0"
                            name="dias"
                            #dias="ngModel"
                            class="input text-center"
                            type="number"
                            name=""
                          />
                        </div>
                        <div class="col-1 col-md-1 zero">
                          <p class="font-8 strong">días</p>
                        </div>
                        <div class="col-1 col-md-1 zero"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="!paranueva"
                  class="col-12 col-md-3 zero"
                  style="margin-top: 15px"
                >
                  <p
                    *ngIf="selectedRequest.quotations[0].deliveryDetails"
                    class="zero left font-7"
                  >
                    Detalles del envío o de tus productos:
                  </p>

                  <div style="margin-top: 15px"></div>
                </div>
                <div
                  *ngIf="!paranueva"
                  class="col-12 col-md-3 zero"
                  style="margin-top: 15px"
                >
                  <div style="margin-top: 15px">
                    <p class="zero font-7 padding1">
                      {{ selectedRequest.quotations[0].deliveryDetails }}
                    </p>
                  </div>
                </div>
                <div class="col-12 col-md-3 zero" style="margin-top: 15px">
                  <p class="zero font-7 padding1">
                    Fecha estimada que recibirán tus productos:
                  </p>
                </div>
                <div
                  *ngIf="!paranueva"
                  class="col-12 col-md-3 zero"
                  style="margin-top: 15px"
                >
                  <p class="zero font-7 padding1">
                    {{
                      selectedRequest.quotations[0].estimatedDeliveryDate
                        | date : "dd/MM/yyyy HH:mm"
                    }}
                  </p>
                </div>
                <div
                  *ngIf="paranueva"
                  class="col-10 col-md-3 zero"
                  style="margin-top: 15px"
                >
                  <input
                    type="text"
                    [(ngModel)]="estimatedDeliveryDate"
                    placeholder="Final"
                    [bsConfig]="{
                      dateInputFormat: 'DD MMMM YYYY',
                      containerClass: 'theme-default',
                      showWeekNumbers: false
                    }"
                    bsDatepicker
                    placement="top"
                    style="
                      background-color: #e9e9e9;
                      border: solid transparent;
                      margin-top: 20px;
                      border-bottom: solid 1px #999;
                      font-size: 0.8em;
                      padding: 0.5em;
                      width: 100%;
                    "
                  />
                </div>

                <div *ngIf="paranueva" class="row" style="margin-top: 15px">
                  <p class="zero left font-7">
                    Detalles del envío o de tus productos:
                  </p>
                  <div class="description">
                    <textarea
                      rows="4"
                      cols="35"
                      [(ngModel)]="deliveryDetails"
                      name="ship"
                      #ship="ngModel"
                      class="input"
                      style="
                        background-color: rgba(0, 0, 0, 0.075);
                        border-bottom: 0px solid #fff;
                      "
                    >
 &nbsp;</textarea
                    >
                    <!-- {{selectedRequest.details}}  -->
                  </div>
                </div>
                <div *ngIf="paranueva" class="col-12 col-md-6 zero">
                  <div *ngIf="paranueva" class="row">
                    <p class="zero left font-7">Confirmar Envio:</p>

                    <div class="boder-l-r">
                      <input
                        (change)="selectedConfirmShip($event)"
                        type="checkbox"
                        id="confirmShip"
                        class="filled-in chk-col-grey important-zero"
                      />
                      <label
                        style="margin-left: 5px"
                        class="fix-left checkbox-index important-zero"
                        for="confirmShip"
                      >
                      </label>
                    </div>
                  </div>
                </div>

                <div *ngIf="!paranueva" class="row marginTop10">
                  <div class="col-6 col-md-6 zero">
                    <div class="row">
                      <div class="col-4 col-md-4 zero">
                        <input
                          [(ngModel)]="
                            selectedRequest.quotations[0].paymentOptions
                              .anticipated
                          "
                          name="anticipado"
                          #anticipado="ngModel"
                          class="input text-center"
                          type="number"
                        />
                      </div>

                      <div class="col-1 col-md-1 zero">
                        <p class="font-8 strong">%</p>
                      </div>
                      <div class="col-7 col-md-6 zero">
                        <p class="font-8 strong">Anticipo</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-md-6 zero">
                    <div class="row">
                      <div class="col-3 col-md-4 zero">
                        <input
                          [(ngModel)]="
                            selectedRequest.quotations[0].paymentOptions
                              .uponDelivery
                          "
                          name="contraentrega"
                          #contraentrega="ngModel"
                          class="input text-center"
                          type="number"
                        />
                      </div>
                      <div class="col-1 col-md-1 zero">
                        <p class="font-8 strong">%</p>
                      </div>
                      <div class="col-8 col-md-6 zero">
                        <p class="font-8 strong">Contraentrega</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-md-12 zero marginTop20">
                    <div class="row">
                      <div class="col-2 col-md-2 zero">
                        <input
                          [(ngModel)]="
                            selectedRequest.quotations[0].paymentOptions.credit
                          "
                          style="
                            border: solid transparent;
                            border-bottom: solid 0.2em #e0e1e2;
                            font-size: 0.7em;
                            width: 100%;
                          "
                          name="credito"
                          #credito="ngModel"
                          class="input text-center"
                          type="number"
                        />
                      </div>
                      <div class="col-1 col-md-1 zero">
                        <p class="font-8 strong">%</p>
                      </div>
                      <div class="col-4 col-md-3 zero center">
                        <p class="font-8 strong">Crédito por</p>
                      </div>
                      <div class="col-3 col-md-2 zero">
                        <input
                          [(ngModel)]="
                            selectedRequest.quotations[0].paymentOptions.days
                          "
                          [disabled]="
                            !selectedRequest.quotations[0].paymentOptions
                              .credit > 0
                          "
                          name="dias"
                          #dias="ngModel"
                          class="input text-center"
                          type="number"
                          name=""
                        />
                      </div>
                      <div class="col-1 col-md-1 zero">
                        <p class="font-8 strong">días</p>
                      </div>
                      <div class="col-1 col-md-1 zero"></div>
                    </div>
                  </div>
                </div>
                <div *ngIf="!paranueva" class="row"></div>
                <div class="row zero width">
                  <div class="col-12 col-md-6">
                    <div class="row zero width">
                      <div class="col-12 col-md-4 zero"></div>
                      <div class="col-0 col-md-8"></div>
                    </div>
                  </div>
                </div>
                <!-- BOTONES -->
                <div class="row width margin1" *ngIf="!paranueva">
                  <div class="col-0 col-md-4"></div>
                  <button
                    (click)="editQuotation()"
                    class="col-6 col-md-4 button-grey"
                    style="color: white; background-color: black"
                  >
                    Editar cotización
                  </button>
                  <div class="col-0 col-md-4"></div>
                </div>
                <!-- Los botones  POR COTIZAR -->
                <div class="row width margin1" *ngIf="porcotizar">
                  <div class="col-0 col-md-4"></div>
                  <button
                    (click)="saveQuotation()"
                    class="col-6 col-md-4 button-grey"
                    style="color: white; background-color: black"
                  >
                    Enviar cotización
                  </button>
                  <div class="col-0 col-md-4"></div>
                </div>
                <!-- Los botones ENVIADAS NO TIENE-->
              </div>
            </div>
          </div>
          <div
            *ngIf="selectedRequest.contestLabel === 'contest'"
            id="collapseTwo"
            class="collapse show"
            aria-labelledby="headingTwo"
            data-parent="#accordion"
          >
            <div class="card-body">
              <div class="row">
                <div *ngIf="paranueva" class="col-12 col-md-6">
                  <p *ngIf="paranueva" class="zero left font-7 marginTop10">
                    Tus Condiciones de pago:
                  </p>

                  <div *ngIf="paranueva" class="row marginTop10">
                    <div class="col-6 col-md-6 zero">
                      <div class="row">
                        <div class="col-4 col-md-4 zero">
                          <input
                            [(ngModel)]="paymentOptions.anticipated"
                            name="anticipado"
                            #anticipado="ngModel"
                            class="input text-center"
                            type="number"
                          />
                        </div>

                        <div class="col-1 col-md-1 zero">
                          <p class="font-8 strong">%</p>
                        </div>
                        <div class="col-7 col-md-6 zero">
                          <p class="font-8 strong">Anticipo</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-md-6 zero">
                      <div class="row">
                        <div class="col-3 col-md-4 zero">
                          <input
                            [(ngModel)]="paymentOptions.uponDelivery"
                            name="contraentrega"
                            #contraentrega="ngModel"
                            class="input text-center"
                            type="number"
                          />
                        </div>
                        <div class="col-1 col-md-1 zero">
                          <p class="font-8 strong">%</p>
                        </div>
                        <div class="col-8 col-md-6 zero">
                          <p class="font-8 strong">Estimaciones</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-md-12 zero marginTop20">
                      <div class="row">
                        <div class="col-2 col-md-2 zero">
                          <input
                            [(ngModel)]="paymentOptions.credit"
                            style="
                              border: solid transparent;
                              border-bottom: solid 0.2em #e0e1e2;
                              font-size: 0.7em;
                              width: 100%;
                            "
                            name="credito"
                            #credito="ngModel"
                            class="input text-center"
                            type="number"
                          />
                        </div>
                        <div class="col-1 col-md-1 zero">
                          <p class="font-8 strong">%</p>
                        </div>
                        <div class="col-4 col-md-3 zero center">
                          <p class="font-8 strong">Finiquito</p>
                        </div>
                        <div class="col-3 col-md-2 zero"></div>
                        <div class="col-1 col-md-1 zero"></div>
                        <div class="col-1 col-md-1 zero"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="!paranueva"
                  class="col-12 col-md-3 zero"
                  style="margin-top: 15px"
                >
                  <p
                    *ngIf="selectedRequest.quotations[0].deliveryDetails"
                    class="zero left font-7"
                  >
                    Detalles :
                  </p>

                  <div style="margin-top: 15px"></div>
                </div>
                <div
                  *ngIf="!paranueva"
                  class="col-12 col-md-3 zero"
                  style="margin-top: 15px"
                >
                  <div style="margin-top: 15px">
                    <p class="zero font-7 padding1">
                      {{ selectedRequest.quotations[0].deliveryDetails }}
                    </p>
                  </div>
                </div>
                <!-- BOTONES -->
              </div>
              <div class="row width margin1" *ngIf="!paranueva">
                <div class="col-0 col-md-4"></div>
                <button
                  (click)="editQuotation()"
                  class="col-6 col-md-4 button-grey"
                  style="color: white; background-color: black"
                >
                  Editar cotización
                </button>
                <div class="col-0 col-md-4"></div>
              </div>
              <!-- Los botones  POR COTIZAR -->
              <div class="row width margin1" *ngIf="porcotizar">
                <div class="col-0 col-md-4"></div>

                <button
                  (click)="saveQuotation()"
                  class="col-6 col-md-4 button-grey"
                  style="color: white; background-color: black"
                >
                  Enviar cotización
                </button>
                <div class="col-0 col-md-4"></div>
              </div>
              <!-- Los botones ENVIADAS NO TIENE-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
