<div class="row zero">

	<!-- PARA BLOQUE SOLO PARA ENVIADAS cotizaciones resultado-->
	<div class="row width center margin-32">
		<div class=" cont_info zero  width">
			<div class="row width center">
				<div class="col-6 col-md-6 zero">
					<div class="row cont-detalles" style=" height: 120px;">
						<div class="row  bg_blanco marginRejected width center">
							<div class="col-2 col-md-2"></div>
							<div class="col-8 col-md-8 center zero">

								<p class="font-8 zero center">
									<span class="strong black"> RESULTADOS DE TU COTIZACIÓN </span>
								</p>
								<div class="row zero pad6">
									<div class="col-6 col-md-6">
										<div class="row zero">
											<div class="col-1 col-md-1 zero right text ">
												<i class="fa fa-money text-bar" aria-hidden="true"></i>
											</div>
											<div class="col-9 col-md-9 zero left">
												<div class="zero">
													<div class="stat-1 stat-bar">
														<span class="stat-bar-rating " [ngStyle]="{'width':  priceScore + '%'}"
														 role="stat-bar"></span>
													</div>
												</div>
											</div>
											<div class="col-2 col-md-2 zero">
												<p class="zero left number-bar icon-black">{{ priceScore }}</p>
											</div>
										</div>
										<div class="row zero">
											<div class="col-1 col-md-1 zero right text ">
												<i class="fa fa-truck" aria-hidden="true"></i>
											</div>
											<div class="col-9 col-md-9 zero left">
												<div class="zero">
													<div class="stat-1 stat-bar">
														<span class="stat-bar-rating " [ngStyle]="{'width': selectedRequest.deliveryScore + '%'}" role="stat-bar"></span>
													</div>
												</div>
											</div>
											<div class="col-2 col-md-2 zero">
												<p class="zero left number-bar icon-black">{{selectedRequest.deliveryScore.toFixed(0)}}</p>
											</div>
										</div>
									</div>
									<div class="col-6 col-md-6">
										<div class="row zero">
											<div class="col-1 col-md-1 zero right text ">
												<i class="fa fa-credit-card" aria-hidden="true"></i>
											</div>
											<div class="col-9 col-md-9 zero left">
												<div class="zero">
													<div class="stat-1 stat-bar">
														<span class="stat-bar-rating " [ngStyle]="{'width': selectedRequest.creditScore + '%'}" role="stat-bar"></span>
													</div>
												</div>
											</div>
											<div class="col-2 col-md-2 zero">
												<p class="zero left number-bar icon-black">{{selectedRequest.creditScore.toFixed(0)}}</p>
											</div>
										</div>
										<div class="row zero">
											<div class="col-1 col-md-1 zero right text ">
												<i class="fa fa-bookmark" aria-hidden="true"></i>
											</div>
											<div class="col-9 col-md-9 zero left">
												<div class="zero">
													<div class="stat-1 stat-bar">
														<span class="stat-bar-rating " [ngStyle]="{'width': (selectedRequest.creditScore+selectedRequest.deliveryScore)/2 + '%'}"
														 role="stat-bar"></span>
													</div>
												</div>
											</div>
											<div class="col-2 col-md-2 zero">
												<p class="zero left number-bar icon-black">{{((selectedRequest.creditScore+selectedRequest.deliveryScore)/2).toFixed(0)}}</p>
											</div>
										</div>
									</div>
								</div>
								<p class="font-8 zero center">Motivo de tu rechazo:
									<span class="strong black"> {{getReason(selectedRequest)}}</span>
								</p>

							</div>
							<div class="col-2 col-md-2"></div>
						</div>
					</div>
				</div>
				<div class="col-6 col-md-6 zero">
					<div class="row  center contenedor-detalles" style="height: 120px;">
						<div class="row  bg_blanco marginRejected width center">
							<div class="col-2 col-md-2"></div>
							<div class="col-8 col-md-8 center zero">

								<p class="font-8 zero center">
									<span class="strong black"> RESULTADOS GENERALES </span>
								</p>
								<div class="row zero pad6">
									<div class="col-6 col-md-6">
										<div class="row zero">
											<div class="col-1 col-md-1 zero right text ">
											</div>
											<div class="col-9 col-md-9 zero left">
												<div class="zero">
													<div class="">
														<p class="lugar strong icon-black" > Lugar en precio:&nbsp;{{ pricePlace }} </p>
													</div>
												</div>
											</div>
											<div class="col-2 col-md-2 zero">
												<!-- 	<p class="zero left number-bar">10</p> -->
											</div>
										</div>
										<div class="row zero">
											<div class="col-1 col-md-1 zero right text ">
											</div>
											<div class="col-10 col-md-10 zero left">
												<div class="zero">
													<div class="">
														<p class="lugar strong icon-black"  > Lugar en entrega:&nbsp;{{ deliveryPlace }}</p>
													</div>
												</div>
											</div>
											<div class="col-1col-md-1 zero">
												<!-- <p class="zero left number-bar">10</p> -->
											</div>
										</div>
									</div>
									<div class="col-6 col-md-6">
										<div class="row zero">
											<div class="col-1 col-md-1 zero right text ">
											</div>
											<div class="col-9 col-md-9 zero left">
												<div class="zero">
													<div class="">
														<p class="lugar strong icon-black" > Lugar en Credito:&nbsp;{{ creditPlace }}</p>
													</div>
												</div>
											</div>
											<div class="col-2 col-md-2 zero">
												<!-- <p class="zero left number-bar">10</p> -->
											</div>
										</div>
										<div class="row zero">
											<div class="col-1 col-md-1 zero right text ">
											</div>
											<div class="col-9 col-md-9 zero left">
												<div class="zero">
													<div class="">
														<p class="lugar strong icon-black"> Cotizaciones: &nbsp;{{totalQuoted}}</p>

													</div>
												</div>
											</div>
											<div class="col-2 col-md-2 zero">
												<!-- 	<p class="zero left number-bar">10</p> -->
											</div>
										</div>
									</div>
								</div>
								<p class="font-8 zero center">
									<!-- 		Motivo de tu rechazo:
									<span class="strong"> Tu entrega fue muy tardía</span> -->
								</p>


							</div>
							<div class="col-2 col-md-2"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- PARA BLOQUE SOLO PARA ENVIADAS   -->
<div *ngIf="selectedRequest" class=" cont_info zero bg_blanco" id="detalle">

	<div class="tab-content marg-5" id="pills-tabContent">
		<div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">


			<div class="row marg-pad-bloque">
				<div class="row width" style="padding-top: 20px;">

					<div class="col-3 col-md-1 zero font-8">
						<img *ngIf="selectedRequest.company.profileUrl" class="logo-brand-img" [src]="selectedRequest.company.profileUrl">
						<img *ngIf="!selectedRequest.company.profileUrl" class="logo-brand-img" src="../../../assets/images/fakeCompany.png">
					</div>
					<div class="col-9 col-md-2 zero collapse_title" style="margin-top:10px;">
						<p class="col-5 col-md-12 font-8  strong zero center   marginTop"> {{selectedRequest.company.name}} </p>

					</div>

					<div class="col-7 col-md-3 font-8 left" style="margin-top:15px;">
						{{selectedRequest.company.companyInfo.city}}
						
					</div>
					<div class="col-4 col-md-1 font-8 left zero">
						<!-- <p class="marginTop" >3, 195 Ventas 1,754 Compras</p> -->

						<br/>
					</div>
					<div class="col-6 col-md-2 font-8 detail-send">
						<p *ngIf="!selectedRequest.company.fileUrl" class="strong padding-top1  " > Sin currículum
						</p>
						<p *ngIf="selectedRequest.company.fileUrl" class="strong padding-top1 " (click)="openFileCompany(selectedRequest.company.fileUrl)"
						 >Ver currículum </p>

					</div>
					<!-- 	<div class="col-6 col-md-3 font-8 detail-send">
							<select [(ngModel)]="currency" (ngModelChange)="currencyChanged($event)" style="margin-top: 25px;" class="form-control">
								<option value="{{currency}}" *ngFor="let currency of currencies; let i = index">
									{{currency}}
								</option>

							</select>
						</div> -->
				</div>

				<div class="row width line" *ngFor="let product of selectedRequest.quotedProducts;let  i = index">
					<div class="col-6 col-md-3">
						<div class="row width zero">
							<div class="col-12 col-md-3 font-8">
								<div class="logo-company" style="background-image:url(assets/images/fakeProduct.png);"> </div>
							</div>
							<div class="col-12 col-md-9 zero font-8">
								<p class="zero font-8 detail-send"> Nombre del producto </p>
								<p *ngIf="product.name" class="strong zero " style="color: black;"> {{product.name}} </p>
							</div>
						</div>
					</div>
					<div class="col-6 col-md-3 ">
						<div class="row">
							<div class="col-12 col-md-5 black font-8">
								<p class="zero font-8 "> Especificación </p>
								<div *ngIf="product.specification" >
									<p *ngIf="product.specification.unit" class="strong zero"> {{product.specification.unit }} </p>

								</div>							</div>
							<div class="col-12 col-md-3 black font-8">
								<p class="zero font-8 d"> Cantidad </p>
								<p *ngIf="product.quantity" class="strong zero"> {{product.quantity }}</p>
							</div>
							<div class="col-12 col-md-3 black font-8">
								<p class="zero font-8 "> Unidad </p>
								<p *ngIf="product.unit" class="strong zero"> {{product.unit.name }} </p>
								<p *ngIf="!product.unit && product.unitName" class="strong zero"> {{product.unitName }} </p>

							</div>


						</div>
					</div>
					<div class="col-12 col-md-2 black font-8 ">
						<p class="zero font-8">Caracteristicas </p>
						<div class="font-8">
							<p *ngIf="product.comments" class="strong read-more" [tooltip]="product.comments" placement="bottom"> {{product.comments}}</p>
						</div>
					</div>
					<div class="col-12 col-md-4 zero black font-8 ">
						<div class="row">
							<div class="col-12 col-md-7 black">
								<p *ngIf="paranueva" class="zero font-8" style=" margin-right: 50px;"> Precio unitario </p>
								<div *ngIf="paranueva" class="row">
									<div class="col-0">
										<p style="margin-top: 5px" class="strong font-8"></p>
									</div>
									<div class="col-10" style="padding-left: 0px;">
										<input class="width input center font-8" style="font-size: 1em;font-weight: bold;" type="text" name="amount"
										 [(ngModel)]="product.amount" (blur)="transformAmount($event,product.amount)" (focus)="removeCurrencyPipeFormat($event,product.amount)"
										 required pattern="[0-9]+" #amount="ngModel">
										<p style="margin-top: 5px" class="strong font-7">+IVA</p>

									</div>
									<div class="col-1">
									</div>
								</div>
								<div class="row">
									<div class="col-8" style="padding-left: 0px;">
										<p class="strong font-8">
											<label class="input next  font-1">{{product.amount| currency}} {{selectedRequest.currency}}</label>
										</p>
									</div>
									<div class="col-1">
										<p *ngIf="onlyViewForSales" style="margin-top: 5px" class="strong font-8">+IVA</p>
									</div>

									<div class="col-2">
										<p style="margin-top: 5px ;margin-left: 5px" class="strong font-8">{{product.brand}}</p>
									</div>
								</div>
							</div>
							<div *ngIf="paranueva" class="col-6 col-md-2 zero black font-8" style="margin-left: 20px;">
								<input *ngIf="paranueva" class="width input center font-8" style="font-size: 1em;font-weight: bold; margin-top: 18px"
								 type="text" name="brand" [(ngModel)]="product.brand" required placeholder="Marca" #brand="ngModel">
							</div>
						<!-- 	<div class="col-6 col-md-1 zero black font-8">
								<button *ngIf=" product.productURL " class="font-8 button-add">
									<a (click)="openProductFile(product.productURL)" style="color:#000" target="_blank">
										Archivo
										<i class="fa fa-arrow-circle-o-down button-add-clipboard font-2" aria-hidden="true"></i>

									</a>
								</button>
							</div> -->
						</div>
					</div>
				</div>

			</div>
		</div>
		<!-- -->
		<div id="accordion">
			<div class="card">
				<div class="card-header" id="headingOne">
					<h5 class="mb-0">
						<div class=" collapse_title black" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
						 aria-controls="collapseOne">
							DETALLE DEL PEDIDO
						</div>
					</h5>
				</div>
				<!-- ACORDION -->

				<div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
					<div class="card-body">
						<div class="row width">
							<div class="col-12 col-md-6 zero">
								<p class="zero left font-7">Detalle del pedido</p>
								<p class="zero left strong font-8">{{selectedRequest.request.details}}</p>


								<p class="zero left  font-7">Margen de tiempo para entregar el producto</p>
								<div class="row texts font1 zero width">
									<div class="col-12 col-md-3 zero">
										<p class="zero left font-7 ">Entre el día:</p>
									</div>
									<div class="col-12 col-md-6 zero">
										<p class="zero left texts strong font-8">
											{{selectedRequest.request.startDeliveryDate | date: 'dd/MM/yyyy HH:mm'}}
										</p>
									</div>
								</div>
								<div class="row texts font1 zero width">
									<div class="col-12 col-md-3 zero">
										<p class="zero left font-7 ">Y el día:</p>
									</div>
									<div class="col-12 col-md-6 zero">
										<p class="zero left texts strong font-8">
											{{selectedRequest.request.endDeliveryDate | date: 'dd/MM/yyyy HH:mm'}}
										</p>
									</div>
								</div>
								<p class="zero left font-7 " style="margin-top: 10px;">Condiciones de pago:</p>
								<div class="row texts zero marginTop15">
									<div *ngIf="selectedRequest.paymentOptions " class="col-12 col-md-3 zero size9 left">
										<p>
											<span class="strong">{{selectedRequest.request.paymentOptions.anticipated}}%</span> Anticipo</p>
									</div>
									<div *ngIf="selectedRequest.paymentOptions " class="col-12 col-md-4 zero  size9 left">
										<p>
											<span class="strong">{{selectedRequest.request.paymentOptions.uponDelivery}}%</span> Contraentrega</p>
									</div>
									<div *ngIf="selectedRequest.paymentOptions " class="col-12 col-md-5 zero size9 left">
										<p>
											<span class="strong">{{selectedRequest.request.paymentOptions.credit}}%</span> Crédito por
											<span class="strong ">{{selectedRequest.request.paymentOptions.days}}</span> días</p>
									</div>
								</div>

							</div>
							<div class="col-12 col-md-6">

								<div class="row texts font1 zero width marginTop10">

									<div style="padding-left: 0;" class="col-12 col-md-7">
										<p class="zero left font-7 ">Fecha y hora límite para cotizar:</p>
									</div>
									<div class="col-12 col-md-5 zero">
										<p class="zero left texts strong font-8">
											{{selectedRequest.request.limitQuotationDate | date: 'dd/MM/yyyy HH:mm'}}

										</p>
									</div>
								</div>
								<p *ngIf="selectedRequest.selectedAddress" style="margin-top: 15px;" class="zero left font-7 ">Lugar de
									entrega de producto:</p>
								<label *ngIf="selectedRequest.selectedAddress" class="zero left texts strong width">
									{{selectedRequest.request.selectedAddress}} </label>
								<div style="margin-top: 10px;" class="zero left texts width font-7">
									<div *ngIf="selectedRequest.request.radius" class="zero left texts width font-7">
										Puedo pasar por el producto en un radio de
										<label class="zero left texts strong">{{selectedRequest.request.radius}}</label> km
									</div>
								</div>
								<div *ngIf="selectedRequest" class="row ">
									<div class="col-12">
										<div id="map" #gmap class="mapStyle"></div>
									</div>
								</div>
							</div>
							
						</div>
					</div>
				</div>
				<!-- ACORDION -->
				<!-- PARA BLOQUE ENVIADAS -->
				<div class="card" >
					<div class="card-header" id="headingTwo">
						<h5 class="mb-0">
							<div class=" collapse_title black" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
							 aria-controls="collapseTwo">
								DETALLES SOBRE TU ENVÍO
							</div>
						</h5>
					</div>
					<div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordion">
						<div class="card-body">
							<div class="row">
							

								<div  class="col-md-6">

									<p *ngIf="selectedRequest.deliveryDetails" class="zero left font-7 ">Detalles del envío o de
										tus productos:</p>

									<div class="col-12 col-md-6 zero" style="margin-top: 15px">
										<p class="zero font-7  padding1">{{selectedRequest.deliveryDetails}}</p>

									</div>
									<div class="row texts zero marginTop15">
										<div *ngIf="selectedRequest.paymentOptions " class="col-12 col-md-3 zero size9 left">
											<p>
												<span class="strong">{{selectedRequest.paymentOptions.anticipated}}%</span> Anticipo</p>
										</div>
										<div *ngIf="selectedRequest.paymentOptions " class="col-12 col-md-4 zero  size9 left">
											<p>
												<span class="strong">{{selectedRequest.paymentOptions.uponDelivery}}%</span> Contraentrega</p>
										</div>
										<div *ngIf="selectedRequest.paymentOptions " class="col-12 col-md-5 zero size9 left">
											<p>
												<span class="strong">{{selectedRequest.paymentOptions.credit}}%</span> Crédito por
												<span class="strong ">{{selectedRequest.paymentOptions.days}}</span> días</p>
										</div>
									</div>

								</div>
								<div class="col-12 col-md-6">

										<div style="padding-left: 0;" class="col-12 col-md-7">
												<p class="zero left font-7 ">Fecha estimada que recibiras tus productos:</p>
											</div>
											<div class="col-12 col-md-5 zero">
												<p class="zero left texts strong font-8">
													{{selectedRequest.estimatedDeliveryDate | date: 'dd/MM/yyyy HH:mm'}}
		
												</p>
											</div>
								</div>
								<div  class="row">

								</div>
								<div class="row zero width">
								
								
								</div>
								
							</div>
						</div>
					</div>

				</div>
			</div>