import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import swal from 'sweetalert2'

@Injectable()
export class AuthGuardService implements CanActivate {
  public isAuthenticated;
  public authToken: any;
  constructor(private _router: Router) {
    this.authToken = localStorage.getItem('$LoopBack$accessTokenId');
    console.log(this.authToken)
  }


  canActivate() {
    this.authToken = localStorage.getItem('$LoopBack$accessTokenId');
    console.log(this.authToken)
    if (this.authToken) {
      console.log('sihay token');

      return true;

    } else {
      console.log('no hay  token');
      this._router.navigate(['/login/']);
/* 
      swal({
        title: 'Error!',
        text: 'Acceso no autorizado sin sesión',
        type: 'error',
        confirmButtonClass: 'btn ',
        confirmButtonColor: '#000'
      }) */

      return false;

    }
  }

}
