<div class="container_bg" style="background-image:url(assets/images/2023/bg.png);">
	<div class="container-scroll">
		<div class="container pad0">
			<div class="cero" style="background-color:#F0F0F0">
				<div class="collapse_title pad1" style="padding: 1em; "> REPORTE DE RESULTADOS</div>
			</div>
			<div class="container_bg_difuninado cero">
				<div class="row">
					<div class="container">
						<div class=" cont_info cero">
							<div class="row cero white-bg width">
								<div class="width cero">
									<div class="collapse_title pad1" style="    text-align: left;
									margin-left: 20px;"> CONFIGURA TU
										REPORTE DE RESULTADOS </div>
								</div>
								<div class="row cero width bg_container">
									<div class="width cero pad5-1">
									</div>
									<div class="col-2 col-sm-2 col-md-2">
										<select (change)="onChangeReport($event.target.value)"
											style=" margin-top: 10px;" class="form-control mar5">
											<option>Tipo</option>
											<option>COMPRAS</option>
											<option>VENTAS</option>
										</select>

									</div>
									<div class="col-2 col-sm-2 col-md-2">
										<input type="text" class="form-control" [(ngModel)]="startTime"
											(ngModelChange)="onChangeDateFrom($event)" placeholder="Fecha Inicio"
											bsDatepicker placement="right"
											[bsConfig]="{ dateInputFormat: 'DD MMMM YYYY',containerClass: 'theme-default',showWeekNumbers: false }"
											style="    margin-top: 10px;
										 background-color:#fff; cursor: pointer;border:solid transparent; border-bottom:solid 1px #999;  padding:0.5em; width:100%;">

									</div>
									<div class="col-2 col-sm-2 col-md-2">
										<input type="text" class="form-control" [(ngModel)]="endTime"
											(ngModelChange)="onChangeDateTo($event)" placeholder="Fecha Final"
											bsDatepicker placement="right"
											[bsConfig]="{ dateInputFormat: 'DD MMMM YYYY',containerClass: 'theme-default' ,showWeekNumbers: false}"
											style="    margin-top: 10px;
										 cursor: pointer;background-color:#fff;border:solid transparent; border-bottom:solid 1px #999;  padding:0.5em; width:100%;">

									</div>
									<div *ngIf="isPurchasesReport" class="col-2 col-sm-2 col-md-2">
										<select [(ngModel)]="projectId" (change)="onChangeProject($event.target.value)"
											style=" margin-top: 10px;" class="form-control mar5">
											<option value="" hidden disabled selected>Centro de Costos</option>
											<option value="" >Todos</option>

											<option [ngValue]="project.id"
												*ngFor="let project of projects; let i = index">
												{{project.name}}
											</option>
										</select>
									</div>
									<div *ngIf="products" class="col-3 col-sm-3 col-md-3">
										<!-- 				<div class="form-group">
													<input #input required [typeaheadItemTemplate]="itemTemplate" formControlName="searchText" [typeahead]="filteredOptions" (typeaheadLoading)="changeTypeaheadLoading($event)"
														(selectedProduct)="typeaheadOnSelect($event)"
														typeaheadOptionField="name" [typeaheadWaitMs]="300"
														type="text" id="product" class="form-control">
													<ng-template class="drop-custom" #itemTemplate let-model="item" let-index="index">
														<div [style.width.px]="inputRef.nativeElement.clientWidth">
															{{model.name}}
														</div>
													</ng-template>
													<label class="form-control-placeholder" for="product">Producto</label>
												</div> -->
										<form class="example-form">
										<!-- 	<mat-form-field class="example-full-width">
												<input matInput type="text" placeholder="Producto" aria-label="Number"
													matInput [formControl]="myControl" [matAutocomplete]="auto">
												<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
													(optionSelected)='selectedProduct($event.option)'>
													<mat-option *ngFor="let option of filteredOptions | async"
														[value]="option">
														{{option.name}}
													</mat-option>
												</mat-autocomplete>
											</mat-form-field> -->
										</form>

									</div>
								<!-- 	<div
										[ngClass]="{'col-1 col-sm-1 col-md-1':isPurchasesReport,'col-2 col-sm-2 col-md-2':isSalesReport}">
										<select [(ngModel)]="specificationId"
											(ngModelChange)="selectedSpecification($event)" style="   margin-top: 10px;"
											class="form-control mar5">
											<option hidden disabled selected>Especificacion</option>
											<option [ngValue]="spec.unit"
												*ngFor="let spec of specifications; let i = index">
												{{spec.unit}}
											</option>
										</select>
									</div> -->

									<div class="row width pad1" style="padding: 1em;">
										<div class="col-xs-4 col-sm-4 col-md-4"></div>
										<div class="col-xs-4 col-sm-4 col-md-4">
											<button *ngIf="isPurchasesReport" class="btn btn-black colorBlanco"
												(click)="generateReportPurchases()">
												Generar reporte
											</button>
											<button *ngIf="isSalesReport" class="btn btn-black colorBlanco"
												(click)="generateReportSales()">
												Generar reporte
											</button>
										</div>
										<div class="col-xs-4 col-sm-4 col-md-4">
											<button *ngIf="isSalesReport " class="btn btn-black colorBlanco"
												(click)="resetFilters()">
												Reiniciar Filtros
											</button>
											<button *ngIf="isPurchasesReport " class="btn btn-black colorBlanco"
												(click)="resetFilters()">
												Reiniciar Filtros
											</button>
										</div>
									</div>
								</div>


							</div>
						</div>
						<div id='reporteCompras' *ngIf="isPurchasesReport && purchasesProducts.length  > 0">
							<div class="card  white-bg">
								<div class="card-body">
									<h4 class="card-title">Reporte Compras</h4>
									<div class="table-responsive">
										<table class="table  table-striped">
											<thead>
												<tr>
													<th>Codigo</th>
													<th>Producto</th>
													<th>Fecha</th>
													<th>Especificación</th>
													<th>Unidad</th>
													<th>Cantidad</th>
													<th>Precio</th>
													<th>Importe</th>
													<th>Moneda</th>

													<th>Empresa</th>
												</tr>
											</thead>
											<tbody>
												<tr class="pointer" (click)="viewDetailPurchase(purchase)"
													*ngFor="let purchase of purchasesProducts; let i = index">
													<td *ngIf="purchase">
														{{purchase.purchaseOrder.requestId}}</td>
													<td *ngIf="purchase">
														{{purchase.name}}</td>
													<td *ngIf="purchase">
														{{purchase.createdAt|date: 'dd/MM/yyyy'}}
													</td>
													<td *ngIf="purchase">
														<div *ngIf="purchase && purchase.specification">
															{{purchase.specification.unit}}

														</div>
														<div *ngIf="purchase && !purchase.specification">
																{{purchase.specificationName}}
	
															</div>

													</td>
													<td *ngIf="purchase">
														<div *ngIf="purchase && purchase.unit">
															{{purchase.unit.name}}
														</div>
														<div *ngIf="purchase && !purchase.unit">
																{{purchase.unitName}}
	
															</div>
													</td>
													<td *ngIf="purchase">
														{{purchase.quantity}}</td>
													<td *ngIf="purchase">
														{{purchase.amount| currency:'':'':'1.0-2'}}
													</td>

													<td *ngIf="purchase">
														{{purchase.amount*purchase.quantity | currency:'':'':'1.0-2'}}</td>
													<td *ngIf="purchase.purchaseOrder.quotation &&purchase">
														{{purchase.purchaseOrder.quotation.currency}}</td>
													<td *ngIf="purchase.purchaseOrder.company && purchase ">
														{{purchase.purchaseOrder.company.name}}
													</td>

												</tr>

											</tbody>
										</table>
									</div>
								</div>
								<div class="row zero padding1 width">

									<div class="col-6 col-md-8 zero left black font-9 number-price">
									</div>

									<div class="col-6 col-md-4 zero">
										<div class="row zero">
											<div class="col-4 col-md-6 zero strong black font-1 right"> SUBTOTAL: </div>
											<div *ngIf="subtotal" class="col-8 col-md-6 zero strong black font-1 all">
												{{ subtotal | currency }}
											</div>
										</div>
										<div class="row zero">
											<div class="col-4 col-md-6 zero strong black font-1 right "> IVA</div>
											<div *ngIf="subtotal"
												class="col-4 col-md-6 zero strong black font-8 price-number">
												{{ getIVA(subtotal) | currency }}
											</div>
										</div>
										<div class="row zero">
											<div class="col-4 col-md-6 zero strong black font-1 right"> TOTAL: </div>
											<div *ngIf="subtotal" class="col-8 col-md-6 zero strong black font-1 all">
												{{ getTotal(subtotal,true) | currency }}
											</div>
										</div>
									</div>

								</div>
								<div *ngIf="isSelectedPurchase" class="row">
									<div class="padding-2" style="width: 100%;">
										<div class=" cont_info zero">

											<div *ngIf="selectedPurchase" class="tab-content" id="pills-tabContent">
												<div class="row background-white padding-top1">
													<p class="text center strong black margin2-l-r">

													</p>
												</div>
												<div class="tab-pane fade show active" id="pills-home" role="tabpanel"
													aria-labelledby="pills-home-tab">
													<!---->
													<div class="row background-white">
														<div class="col-12 col-md-4 text center">
															<!-- logito de garantia -->
															<div class="row">
																<div class="col-0 col-md-4 "></div>
																<div class="col-12 col-md-5 zero garantia garanty"
																	style="background-image:url(assets/images/gallery/marcas/garantiablack.png);">
																	<div class="galery-numbrer">
																		{{selectedPurchase.purchaseOrder.company.ratingIxcco}}
																	</div>
																	<div class="galery-text">RECOMENDACIÓN</div>
																	<div class="galery-logo">IXCCO</div>
																</div>
																<div class="col-0 col-md-3 "></div>
															</div>
															<!-- 	Cotización para
																<strong *ngIf="concretada">{{selectedPurchase.sellProducts.length}}</strong> productos -->
														</div>
														<div class="col-12 col-md-4 black margin1">
															<div class="row width padding-top1">

																<div class=" col-3 col-md-12 zero ">
																	<img *ngIf="selectedPurchase.purchaseOrder.company.profileUrl"
																		class="logo-brand-img-2"
																		[src]="selectedPurchase.purchaseOrder.company.profileUrl">
																	<img *ngIf="!selectedPurchase.purchaseOrder.company.profileUrl"
																		class="logo-brand-img-2"
																		src="../../../assets/images/fakeCompany.png">
																</div>
																<div class=" col-9 col-md-12 zero">
																	<div class="text center-left">
																		{{selectedPurchase.purchaseOrder.company.name}}
																	</div>
																	<div *ngIf="!selectedPurchase.purchaseOrder.company.rating"
																		class="text center-left">
																		<rating [(ngModel)]="rate" [max]="max"
																			[readonly]="isReadonly"></rating>
																	</div>
																	<div *ngIf="selectedPurchase.purchaseOrder.company.rating"
																		class="text center-left">
																		<rating
																			[(ngModel)]="selectedPurchase.purchaseOrder.company.rating"
																			[max]="max" [readonly]="isReadonly">
																		</rating>
																	</div>
																	<div *ngIf="selectedPurchase.purchaseOrder.company.companyInfo"
																		class="text center-left">
																		{{selectedPurchase.purchaseOrder.company.companyInfo.city}},
																		México</div>
																</div>
															</div>
														</div>
														<div *ngIf="selectedPurchase"
															class="col-12 col-md-4 text center margin1 padding-6">

															<div class="row zero margin1">
																<div class="col-1 col-md-1 zero right text ">
																	<i class="fa fa-money pad5 text-bar"
																		aria-hidden="true"></i>
																</div>
																<div class="col-9 col-md-9 zero left">

																</div>
																<div class="col-2 col-md-2 number-bar zero">
																</div>
																<div class="col-2 col-md-2 text "></div>
																<div class=" col-10 col-md-10  zero">
																	<p class="text price text1 weigh600 "> Precio total: {{(selectedPurchase.amount*selectedPurchase.quantity)*1.16|
																		currency:'':true:'1.0-2'}}</p>
																</div>
															</div>
															<div class="row zero margin1">
																<div class="col-1 col-md-1 zero right text ">
																	<i class="fa fa-truck pad5 text-bar"
																		aria-hidden="true"></i>
																</div>
																<div class="col-9 col-md-9 zero left">
																	<div class="zero">

																	</div>
																</div>
																<div class="col-2 col-md-2 number-bar zero">
																</div>
																<div class="col-2 col-md-2 text "></div>
																<div class=" col-10 col-md-10  zero">

																	<p *ngIf="selectedRequest"
																		class="text price weigh600 text1"> Entrega
																		{{selectedRequest.endDeliveryDate | date:"MM/dd/yyyy"}}
																	</p>
																</div>
															</div>
															<div class="row zero margin1">
																<div class="col-1 col-md-1 zero right text ">
																	<i class="fa fa-credit-card pad5 text-bar"
																		aria-hidden="true"></i>
																</div>
																<div class="col-9 col-md-9 zero left">

																</div>
																<div class="col-2 col-md-2 number-bar zero">
																	<p class="zero left number-bar"></p>
																</div>
																<div class="col-2 col-md-2 text "></div>
																<div class=" col-10 col-md-10  zero">
																	<p *ngIf="selectedPurchase"
																		class="text price weigh600 text1"> An
																		&nbsp;{{selectedPurchase.purchaseOrder.paymentOptions.anticipated}}%
																		&nbsp; Co
																		&nbsp;{{selectedPurchase.purchaseOrder.paymentOptions.uponDelivery}}%
																		&nbsp;Cr
																		&nbsp;{{selectedPurchase.purchaseOrder.paymentOptions.credit}}%
																		&nbsp; por &nbsp;
																		{{selectedPurchase.purchaseOrder.paymentOptions.days}}
																		días
																	</p>
																</div>
															</div>
															<div class="row zero margin1">
																<div class="col-1 col-md-1 zero right text ">
																</div>
																<div class="col-9 col-md-9 zero left">

																</div>
																<div class="col-2 col-md-2 number-bar zero">
																	<p class="zero left number-bar"></p>
																</div>
																<div class="col-2 col-md-2 text "></div>
																<div class=" col-10 col-md-10  zero">
																</div>
															</div>
														</div>
													</div>
													<div class="row background-white"> &nbsp;</div>
													<div>
														<div *ngIf="selectedPurchase"
															class="row background-white black padding-6 border">
															<div *ngIf="selectedPurchase"
																class="col-4 col-md-1 text center zero">
																<div *ngIf="selectedPurchase.productUrl"
																	class="container-button">

																	<i class="fa fa-arrow-circle-o-down zero"
																		aria-hidden="true"></i>
																</div>
															</div>
															<div *ngIf="selectedPurchase"
																class="col-8 col-md-3 text left zeror">
																<p class="zero">Nombre del producto</p>
																<label
																	class="zero strong ">{{selectedPurchase.name}}</label>
															</div>
															<div *ngIf="selectedPurchase"
																class="col-5 col-md-1 text left zeror">
																<p class="zero">Cantidad:</p>
																<label
																	class=" strong zero">{{selectedPurchase.quantity}}</label>
															</div>

															<div *ngIf="selectedPurchase"
																class="col-5 col-md-1 text left zeror">
																<p class="zero">Unidad:</p>
																<label *ngIf="selectedPurchase.unit"
																	class=" strong zero">{{selectedPurchase.unit.name}}</label>
															</div>
															<div *ngIf="selectedPurchase"
																class="col-5 col-md-2 text left zero">
																<p class="zero">Centro de costos:</p>
																<label *ngIf="selectedRequest"
																	class=" strong zero">{{selectedRequest.projectName}}</label>
															</div>
															<div *ngIf="selectedPurchase"
																class="col-7 col-md-2 text left zero">
																<p class="zero">Precio unitario:</p>
																<label class=" strong zero">{{selectedPurchase.amount|
																		currency:'':true:'1.0-2'}}mxn</label>
															</div>

														</div>
													</div>

												</div>

												<!-- MIS USUARIOS -->


												<!-- <div class="row zero bg_blanco pad6">
														<div class="col-1 col-sm-3 col-md-3"> </div>
														<div class="col-10 col-sm-6 col-md-6 text center green font-8">
															<i class="fa fa-clock-o" aria-hidden="true" style="font-size:1.6em;"></i> &nbsp; Recibiras tus productos el dia
															<span class="bold">{{concretada.endDeliveryDate | date:"MM/dd/yyyy"}}</span>
														</div>
														<div class="col-xs-1 col-sm-3 col-md-3"> </div>
													</div>-->
												<div class="row zero bg_blanco pad6">
													<div class="col-1 col-sm-4 col-md-4 zero text center"> </div>
													<div class="col-10 col-sm-4 col-md-4 text center">
														<button
															class="boton_clip zero center pad08 collapse_title width font-1 white"
															style="background:#000;border:solid transparent;"
															(click)="verConcurso()"> Ver Concurso</button>
													</div>
													<div class="col-1 col-sm-4 col-md-4 text center"></div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div *ngIf="viewContest" class="row" style="border:solid #E9E9E9;">
									<div class="row width pad1" style="padding: 1em;">
										<div class="col-xs-2 col-sm-2 col-md-2">
											<button (click)="volverConcurso()" class="btn btn-black colorBlanco">
												Volver
											</button>
										</div>
										<div class="col-xs-6 col-sm-6 col-md-6">

										</div>
										<div class="col-xs-4 col-sm-4 col-md-4"></div>
									</div>
									<div class="row">
										<img [src]="contestImageUrl" style="width:100% ; height:100%" alt="">
									</div>
									<!-- <app-concurso [selectedRequest]="selectedRequest" [quotedCompanies]="quotedCompanies"></app-concurso> -->
								</div>
							</div>
						</div>
						<div id="ventas" *ngIf="isSalesReport">
							<div class="row  white-bg " style="padding-top: 40px;">
								<div class="col-md-6">
									<div style="display: block;">
										<canvas *ngIf="lineChartLabels.length > 0" baseChart width="400" height="400"
											[datasets]="lineChartData" [labels]="lineChartLabels"
											[options]="lineChartOptions" [colors]="lineChartColors"
											[legend]="lineChartLegend" [chartType]="lineChartType"
											(chartHover)="chartHovered($event)"
											(chartClick)="chartClicked($event)"></canvas>
									</div>
								</div>

								<div class="col-md-6" style="margin-bottom: 10px">
									<div *ngIf="showDoughnutChartWon" style="display: block">
										<canvas baseChart [data]="doughnutChartData" [labels]="doughnutChartLabels"
											[colors]="doughnutChartColorsWon" [chartType]="doughnutChartType"
											(chartHover)="chartHovered($event)"
											(chartClick)="chartClicked($event)"></canvas>
									</div>
									<div *ngIf="showDoughnutChartLose" style="display: block ; margin-top: 25px;">
										<canvas baseChart [data]="doughnutChartDataLost"
											[labels]="doughnutChartLabelsLost" [colors]="doughnutChartColorsLose"
											[chartType]="doughnutChartType" (chartHover)="chartHovered($event)"
											(chartClick)="chartClicked($event)"></canvas>
									</div>
								</div>
							</div>
						</div>




					</div>
					<!-- container -->
				</div>




			</div>
		</div>
	</div>
</div>