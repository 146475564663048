
<!-- Modal  confirm and rating-->
<div class="modal fade " id="confirmCreateRating" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
 aria-hidden="true">

	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content martop30">
			<div class="modal-body width"style="height:500px;">
				<button type="button" class="close btnColose" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<div class="row zero tabla width">
					<div class="row width" >

						<p class="font-1 black" style="text-align:center;">Tu proveedor nos informó que te enviaron los productos exitosamente, si
							esto es correcto por favor confirma el siguiente botón</p>

						<div class=" top-ask" style="  height: 100%;
						margin: 0;
						padding: 0;
						overflow-y: hidden !important;
						overflow-x: hidden;
						width: 100%;
								">
											<div class="row linehr width">
								<div class=" col-2 col-md-2 text bold zero">
									<i class="fa fa-question-circle-o ask" aria-hidden="true"></i>
								</div>
								<div class=" col-7 col-md-7 text bold zero">
									<p class="font-1 black">¿Su pedido llego completo?</p>
								</div>
								<div class=" col-3 col-md-3 text bold zero">
									<div class="row width">
										<div class=" col-4 col-md-4 text bold zero">
											<input class="hidden radio-label yes-button" type="radio" name="accept-offers" id="yes-button1" checked="checked" />
											<label class="button-label" for="yes-button1">
												<h1>Si</h1>
											</label>
										</div>
										<div class=" col-4 col-md-4 text bold zero">
											<input class="hidden radio-label no-button" type="radio" name="accept-offers" id="no-button1" />
											<label class="button-label" for="no-button1">
												<h1>No</h1>
											</label>
										</div>
										<div class=" col-4 col-md-4 text bold zero">

										</div>
									</div>
								</div>
							</div>
							<div class="row" style="padding:20px; margin-top: 20px;">
								<div class=" col-3 col-md-3 text bold zero">
									<p class="font-1 black">Reseña</p>
								</div>
								<div class=" col-9 col-md-9 text bold zero">
									<rating [max]="maxRatings" [(ngModel)]="rateRatings" [customTemplate]="tt"></rating>
									<ng-template #tt let-i="index" let-v="value">
										<button class="btn btn-{{i < v ? 'greenbg' : 'default'}}">
											{{i < v ? '&#9733;' : '&#9734;' }} </button> </ng-template> <!-- <rating [(ngModel)]="rateRatings" [max]="maxRatings"
											 [readonly]="false">
												</rating> -->
								</div>

							</div>
							<div class="row" style="padding:20px; ">
								<div class=" col-6 col-md-6 text bold zero">
									<p class="font-1 black">Comentarios</p>
								</div>

								<input type="text" class="width input2" style="border-left:0px;border-right:0px;" [(ngModel)]="ratingComment"
								 name="comment" #comment="ngModel" placeholder="">

							</div>




						</div>
					</div>
					<div class="row zero width" style="    margin-top: 30px;
					">
						<div class="col-3 col-md-3 zero font-8">
							<!-- 	<button class="width zero center strong padding5" style="border-right:solid 0.1em #999;" data-toggle="modal"
							 data-target="#llenaencuesta">
								CANCELAR ENCUESTA</button> -->
						</div>
						<div class="col-6 col-md-6 zero font-8">
							<button (click)="confirmRating()" class="width zero center strong padding5" style=" background-color: black; border:solid transparent; color:#fff;">
								GUARDAR</button>
						</div>
						<div class="col-3 col-md-3 zero font-8">
							<!-- 	<button class="width zero center strong padding5" style="border-right:solid 0.1em #999;" data-toggle="modal"
									 data-target="#llenaencuesta">
										CANCELAR ENCUESTA</button> -->
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</div>
<!-- Modal  PARA EL CUESTIONARIO-->
<div class="modal fade " id="ixccoEncuesta" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
 aria-hidden="true">

	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content martop30">
			<div class="modal-body width">
				<button type="button" class="close btnColose" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<div class="row zero tabla width">
					<div class="row width" style="height:15em;">
						<div class="container-scroll top-ask">
							<div class="row center">
								<p class="font-1 black title">Calificacion de la plataforma</p>
							</div>

							<div class="row center">
								<rating [max]="maxRatings" [(ngModel)]="rateRatings" [customTemplate]="tt"></rating>
								<ng-template #tt let-i="index" let-v="value">
									<button class="btn btn-{{i < v ? 'greenbg' : 'default'}}">
										{{i < v ? '&#9733;' : '&#9734;' }} </button> </ng-template> </div> <div class="row center" style="padding:10px;">
											<p class="font-1 black">Comentarios/Recomendaciones</p>

							</div>

							<div class="row" style="padding:10px;">
								<input type="text" class="width input2" style="border-left:0px;border-right:0px;" [(ngModel)]="ratingComment"
								 name="comment" #comment="ngModel" placeholder="">


							</div>
						</div>
					</div>
					<div class="row zero width">
						<div class="col-3 col-md-3 zero font-8">

						</div>
						<div class="col-6 col-md-6 zero font-8">
							<button (click)="surveyIxcco()" class="width zero center strong padding5" style=" background-color: black; border:solid transparent; color:#fff;">
								Responder
							</button>
						</div>
						<div class="col-3 col-md-3 zero font-8">

						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</div>

<!-- Modal encuesta ixcco-->
<div class="modal fade " id="ixccoSurvey" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
 aria-hidden="true">

	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content martop30">
			<div class="modal-body width">
				<button type="button" class="close btnColose" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<div class="row zero tabla width">
					<div class="row width" style="height:18em;">
						<div class="container-scroll top-ask">


							<div *ngFor="let question of questionsSurvey ; let i= index" class="row" style="padding:20px; ">
								<div class=" col-6 col-md-6 text bold zero">
									<p class="font-1 black">{{question.question}}</p>
								</div>

								<input *ngIf="question.type==='abierta' " type="text" class="width input2" style="border-left:0px;border-right:0px;"
								 [(ngModel)]="question.answer"  placeholder="">


								<input (change)="selectedConfirmSurvey($event,i)" *ngIf="question.type==='cerrada'" [checked]="false" type="checkbox" id="question+'i'" class="filled-in chk-col-grey" />
								<label class="fix-left" for="question+'i'"></label>

							</div>

						</div>
					</div>
					<div class="row zero width">
						<div class="col-3 col-md-3 zero font-8">

						</div>
						<div class="col-6 col-md-6 zero font-8">
							<button (click)="confirmSurvey()" class="width zero center strong padding5" style=" background-color: black; border:solid transparent; color:#fff;">
								GUARDAR</button>
						</div>
						<div class="col-3 col-md-3 zero font-8">

						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</div>

<!--fin encuesta ixcco-->
<div class="container_bg" style="background-image: url(assets/images/2023/bg.png)">
	<div class="position-page only-movile"> CONCRETADA </div>
	<div class="container-scroll">
		<!-- -->
		<!--targetVER INFORMACION DE EL PROVEEDOR-------------------------->
		<div class="row target" id="decidir">
			<div class="container">
				<div class="row zero confirmar background-gray">
					<div class="col-0 col-md-1 zero"></div>
					<div class="col-2 col-md-2 collapse_titl padding-top1">
						<p class="goBack" (click)="goBackConcretadas()">
							<i class="fa fa-chevron-left" aria-hidden="true"></i>Regresar &nbsp;&nbsp;&nbsp;</p>
					</div>
					<div class="col-10 col-md-6 zero collapse_title padding-top1 strong font-8"> VER INFORMACIÓN DE PROVEEDOR</div>
					<div class="col-0 col-md-3 zero">
						<p class="folio-title remove"> SEMANA 2 FEBRERO 2018 Folio no.215 </p>
					</div>
				</div>
				<div class="container-bg-blur zero">
<!-- 					<app-proveedor></app-proveedor>
 -->				</div>
			</div>
		</div>
		<!-- -->
		<!-- -->
		<!--target EN Negociacion-------------------------->
		<div class="row target" id="concretedNegotiation">
			<div *ngIf="concretadaSelected" class="container">
				<div id="titleConcretadas" class="row zero confirmar background-gray">
					<div class="col-0 col-md-1 zero"></div>
					<div class="col-2 col-md-2 collapse_titl padding-top1">
						<p class="goBack" (click)="goBackConcretadas()">
							<i class="fa fa-chevron-left" aria-hidden="true"></i> Regresar&nbsp;&nbsp;&nbsp;</p>
					</div>
					<div class="col-10 col-md-6 zero collapse_title padding-top1 strong font-8"> COMPRA EN NEGOCIACIÓN</div>
					<div class="col-0 col-md-3 zero">
						<p class="folio-title remove"> {{concretadaSelected.id}} </p>
					</div>
				</div>
				<div class="container-bg-blur zero">
					<app-negotiationQuotation [concretada]="concretadaSelected"></app-negotiationQuotation>
				</div>
			</div>

		</div>
		<!-- -->
		<!--CONFIRMAR ENTREGA-------------------------->
		<div class="row target" id="concretedReceived">
			<div *ngIf="concretadaRecSelected" class="container">
				<div class="row zero background-gray">
					<div class="col-0 col-md-1 zero"></div>
					<div class="col-2 col-md-2 collapse_titl padding-top1">
						<p class="goBack" (click)="goBackConcretadas()">
							<i class="fa fa-chevron-left" aria-hidden="true"></i> Regresar&nbsp;&nbsp;&nbsp;</p>
					</div>
					<div class="col-10 col-md-6 zero collapse_title padding-top1 strong font-8">CONFIRMAR ENTREGA</div>
					<div class="col-0 col-md-3 zero">
						<p class="folio-title remove"> Folio:&nbsp; {{concretadaRecSelected.id}} </p>
					</div>
				</div>
				<div *ngIf="concretadaRecSelected" class="container-bg-blur zero">
					<app-viewQuotation (orderId)="editPurchaseOrder($event)" [concretada]="concretadaRecSelected"></app-viewQuotation>
				</div>
			</div>
		</div>
		<!--CONTESTAR ENCUESTA-------------------------->
		<div class="row target" id="concretedHistory">
			<div *ngIf="concretadaHistorySelected" class="container">
				<!-- <div class="row zero background-gray is-movile-concretized"> -->
				<div class="row zero background-gray">
					<div class="col-0 col-md-1 zero"></div>
					<div class="col-2 col-md-2 collapse_titl padding-top1">
						<p class="goBack" (click)="goBackConcretadas()">
							<i class="fa fa-chevron-left" aria-hidden="true"></i> Regresar&nbsp;&nbsp;&nbsp;</p>
					</div>
					<div class="col-10 col-md-6 zero collapse_title padding-top1 strong font-8">HISTORIAL</div>
					<div class="col-0 col-md-3 zero">
						<p class="folio-title remove"> Folio:&nbsp; {{concretadaHistorySelected.id}} </p>
					</div>
				</div>
				<div  *ngIf="concretadaHistorySelected"  class="container-bg-blur zero">
					
					<app-newSurvey [concretada]="concretadaHistorySelected"></app-newSurvey>

				</div>
			</div>
		</div>
		<!-- -->
		<!--HISTORIAL-------------------------->
		<div class="row target" id="historial">
			<div class="container">
				<div class="row zero background-gray">
					<div class="col-0 col-md-1 zero"></div>
					<div class="col-2 col-md-2 collapse_titl padding-top1">
						<p (click)="goBackConcretadas()">
							<i class="fa fa-chevron-left" aria-hidden="true"></i> Regresar&nbsp;&nbsp;&nbsp;</p>
					</div>
					<div class="col-10 col-md-6 zero collapse_title padding-top1 strong font-8">HISTORIAL DE COMPRAS</div>
					<div class="col-0 col-md-3 zero">
						<p class="folio-title remove"> SEMANA 2 FEBRERO 2018 Folio no.215 </p>
					</div>
				</div>
				<div class="container-bg-blur zero">
					<app-viewHistoryQuotation></app-viewHistoryQuotation>
				</div>
			</div>
		</div>
		<!-- -->


		<div id="concreted" class="container recibidas">

			<div class="container-bg-blur" style="background-image: url(assets/images/2023/bg.png)">
				<!-- CONCREATADAS  -->
				<div class="card">
					<div class="card-header" id="newConcreted">
						<h5 class="mb-0">
							<a class="collapse_title black" data-toggle="collapse" href="#collapse-example1" aria-expanded="true"
							 aria-controls="collapse-example1">
								<i class="fa fa-chevron-down pull-right"></i>
								<div class="collapse_title"> COMPRAS CONCRETADAS </div>
							</a>
						</h5>
					</div>
					<div id="collapse-example1" class="collapse show" aria-labelledby="heading-example">
						<div class="card-block">
							<div class="row black background-white" [ngClass]="{'overTable':concretedPurchase.length>6 ,'overTableSmall':concretedPurchase.length>1 ,'overTableMed':concretedPurchase.length>3}">
								<!-- <app-client-table style="width: 100%;"  [dataIn]="concretedPurchase" [type]="'concreted'"                                                       (filterContent)="processCategorization($event)"
								(openEvent)="viewData($event)"
								></app-client-table> -->
								 <table id="tableConcreted" class="table  table-hover" cellspacing="0" width="100%" style="width:100%">
									<thead>
										<tr>
											<th class="font-7 strong text-center">FOLIO</th>
											<th class="font-7 strong text-center">NOMBRE</th>
											<th class="font-7 strong text-center">CENTRO DE COSTOS</th>
											<th class="font-7 strong text-center">EMPRESA</th>
											<th class="font-7 strong text-center">FECHA DE CREACIÓN</th>
										</tr>
									</thead>
									<tbody>
										<tr class="pointer" (click)="viewConcreted(concreted,index)" *ngFor="let concreted of concretedPurchase; let i = index">
											<td class="font-7 talble-movile">
												{{concreted.id}} </td>
											<td class="font-7 table-strong">
												{{concreted.name}} </td>
											<td class="font-7 table-strong">
												{{concreted.projectName}}</td>
											<td class="font-7 table-font7">
												{{concreted.purchasesOrders[0].company.companyInfo.fiscalName}} </td>
											<td class="font-7 table-font7">{{concreted.createdAt| date: 'dd/MM/yyyy'}}</td>

										
										</tr>
									</tbody>
								</table> 
								<div class="col-md-12 background-white" *ngIf="concretedPurchase.length===0">
									<h2 class="text-center">No hay concretadas para mostrar</h2>
								</div>



							</div>
						</div>
					</div>
				</div>
				<!-- RECIBIDAS 2 -->
				<div class="card">
					<div class="card-header" id="receivedConcreted">
						<h5 class="mb-0">
							<a class="collapse_title black" data-toggle="collapse" href="#collapse-example2" aria-expanded="true"
							 aria-controls="collapse-example2">
								<i class="fa fa-chevron-down pull-right"></i>
								<div class="collapse_title"> COMPRAS EN RECIBIDAS </div>
							</a>
						</h5>
					</div>
					<div id="collapse-example2" class="collapse show" aria-labelledby="heading-example">
						<div *ngIf="receivedPurchase" class="card-block">
							<div class="row zero black background-white" [ngClass]="{'overTable':receivedPurchase.length>6 ,'overTableSmall':receivedPurchase.length>1 ,'overTableMed':receivedPurchase.length>3}">
								<!-- <app-client-table style="width: 100%;"  [dataIn]="receivedPurchase" [type]="'received'"
								(openEvent)="viewData($event)"
								></app-client-table> -->

							 	<table id="tableRewview" class="table  table-hover" cellspacing="0" width="100%" style="width:100%">
									<thead>
										<tr>
											<th class="font-7 strong  text-center">FOLIO</th>
											<th class="font-7 strong  text-center">NOMBRE</th>
											<th class="font-7 strong  text-center">CENTRO DE COSTOS</th>
											<th class="font-7 strong  text-center">EMPRESA</th>
											<th class="font-7 strong  text-center">FECHA DE CREACIÓN</th>
										</tr>
									</thead>
									<tbody>
										<tr class="pointer" (click)="viewConcretedReceived(received)" *ngFor="let received of receivedPurchase">
											<td class="font-7 talble-movile">
												{{received.id}} </td>
											<td class="font-7 table-strong">
												{{received.name}} </td>
											<td class="font-7 table-strong">
												{{received.projectName}}</td>
											<td class="font-7 table-font7">
												{{received.purchasesOrders[0].company.companyInfo.fiscalName}} </td>
											<td class="font-7 table-font7">{{received.createdAt| date: 'dd/MM/yyyy'}}</td>


										</tr>
									</tbody>
								</table> 

								<div class="col-md-12 background-white" *ngIf="receivedPurchase.length===0">
									<h2 class="text-center">No hay recibidas para mostrar</h2>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- HISTORIAL 3 -->
				<div class="card">
					<div class="card-header" id="recordConcreted">
						<h5 class="mb-0">
							<a class="collapse_title black" data-toggle="collapse" href="#collapse-example3" aria-expanded="true"
							 aria-controls="collapse-example3">
								<i class="fa fa-chevron-down pull-right"></i>
								<div class="collapse_title"> HISTORIAL DE COMPRAS </div>
							</a>
						</h5>
					</div>
					<div id="collapse-example3" class="collapse show" aria-labelledby="heading-example">
						<div class="card-block ">
							<div class="row zero black background-white" [ngClass]="{'overTable':record.length>6 ,'overTableSmall':record.length>1 ,'overTableMed':record.length>3}">
								<!-- <app-client-table style="width: 100%;"  [dataIn]="record" [type]="'record'" 
								(openEvent)="viewData($event)"
								></app-client-table> -->

								 <table id="tableRewview" class="table  table-hover" cellspacing="0" width="100%" style="width:100%">
									<thead>
										<tr>
											<th class="font-7 strong  text-center">FOLIO</th>
											<th class="font-7 strong  text-center">NOMBRE</th>
											<th class="font-7 strong  text-center">CENTRO DE COSTOS</th>
											<th class="font-7 strong  text-center">EMPRESA</th>
											<th class="font-7 strong  text-center">FECHA DE CREACIÓN</th>
										</tr>
									</thead>
									<tbody *ngIf="record.length>0">
										<tr class="pointer" (click)="viewHistoryReceived(rec)" *ngFor="let rec of record">
											<td class="font-7 talble-movile">
												{{rec.id}} </td>
											<td class="font-7 table-strong">
												{{rec.name}} </td>
											<td class="font-7 table-strong">
												{{rec.projectName}}</td>
											<td class="font-7 table-font7">
												{{rec.purchasesOrders[0].company.companyInfo.fiscalName}} </td>
											<td class="font-7 table-font7">{{rec.createdAt| date: 'dd/MM/yyyy'}}</td>


										</tr>
									</tbody>
								</table> 
								<div class="col-md-12 background-white" *ngIf="record.length===0">
									<h2 class="text-center">No hay historial para mostrar</h2>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Footer -->
		<app-footer></app-footer>
		<!--Footer -->

	</div>
</div>