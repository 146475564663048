import { Component, OnInit ,Input} from '@angular/core';
declare var $:any;
import { ApiService } from '../../../../services/apiService';
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-newSurvey',
  templateUrl: './newSurvey.component.html',
  styleUrls: ['./newSurvey.component.css'],
  providers:[ApiService]
})
export class NewSurveyComponent implements OnInit {
	@Input() concretada: any;

	public detallecompra : Array<any>;
 // Rating
 max: number = 5;
 rate: number = 0;
 isReadonly: boolean = true;
 public rating = {
	
		companyId: "",
		quotationId: {},
		requestId: {}
 }
  constructor(private _apiService: ApiService, private spinner: NgxSpinnerService, private _router: Router) {

}
surveyIxcco(){

  //IXCCO SURVEY listed in admin ixcco
  this.spinner.show();

 
  setTimeout(() => {
    swal({
      title: 'Gracias!',
      text: 'Encuesta contestada satisfactoriamente.',
      type: 'success',
      confirmButtonClass: 'btn ',
      confirmButtonColor: '#000'
    });
    this.spinner.hide()
    $('#ixccoEncuesta').modal('hide');


  }, 350);

}

  ngOnInit() {
    console.log(this.concretada)

  }

}


