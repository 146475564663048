
import {mergeMap, map} from 'rxjs/operators';
import { filter} from 'rxjs/operators/filter';

import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

declare var jQuery: any;
declare var $: any;
import swal from 'sweetalert2'




import { NgxSpinnerService } from 'ngx-spinner';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { CardlistService } from '../../../services/cardlist.service';
import { ApiService } from '../../../services/apiService';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [CardlistService, ApiService]
})
export class HeaderComponent implements OnInit {
  public viewSales: boolean;
  public companiesToReport: any;
  public Companies: any;
  public selectedCompanyToReport: any;
  public companyToReportName = '';

  public reportComment = '';

  public salesMenu = [
    {
      id: 0,
      name: 'NUEVAS',
      path: '/inicio',
      active: false
    },
    {
      id: 1,
      name: 'POR COTIZAR',
      path: '/porcotizar',
      active: false
    },
    {
      id: 2,
      name: 'ENVIADAS',
      path: '/ventasenviadas',
      active: false
    },
    {
      id: 3,
      name: 'ACEPTADAS',
      path: '/aceptada',
      active: false
    },
    {
      id: 4,
      name: 'VER PERFILES',
      path: null,
      active: false,
      href: 'http://www.ixcco.com/blog'
    }

  ];

  public purchasesMenu = [
    {
      id: 0,
      name: 'SOLICITAR COTIZACIÓN',
      path: '/compras',
      active: false
    },
    {
      id: 1,
      name: 'ENVIADAS',
      path: '/enviadas',
      active: false
    },
    {
      id: 2,
      name: 'RECIBIDAS',
      path: '/recibidas',
      active: false
    },
    {
      id: 3,
      name: 'CONCRETADAS',
      path: '/concretadas',
      active: false
    }
    ,
    {
      id: 4,
      name: 'VER PERFILES',
      path: null,
      active: false,
      href: 'http://www.ixcco.com/blog'
    }

  ];

  rol: any;
  public reportarempresa: Array<any>;
  public reportarduplicada: Array<any>;
  public notificaciones: Array<any>;
  public isSales;
  public isPurchases;
  public viewList: Boolean = false;
  public isperfil = false;
  public userId;
  public usuario = {
    personalInfo: {
      name: '',
      firstName: '',
      lastName: ''
    },
    companyId: '',
    email: '',

    role: '',
    ventas: false,
    compras: false,
    pushTokens: []
  };
  public companyId;
  public activeId;
  public userName: String;
  public userImage: String;
  public menuSelectedId: any;
  public notifications: Array<any>;
  constructor(private _apiService: ApiService, private router: Router, private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute, private spinner: NgxSpinnerService, private _cardlistService: CardlistService) {
    this.rol = localStorage.getItem('$LoopBack$currentUserRole');
    this.viewSales = JSON.parse(localStorage.getItem('isSale'));


    this.reportarempresa = [
      {
        "id": "1",
        "logo": "assets/images/gallery/marcas/CEMEX.png",
        "nombre": "Cemex",
        "valoraciones": "2,189",
        "lugar": "Zapopan, Jalisco",
        "ventar": "1,283 ",
        "compras": "1,944"
      },
      {
        "id": "2",
        "logo": "assets/images/gallery/marcas/CEMEX.png",
        "nombre": "Cemex",
        "valoraciones": "2,189",
        "lugar": "Zapopan, Jalisco",
        "ventar": "1,283 ",
        "compras": "1,944"
      },
      {
        "id": "3",
        "logo": "assets/images/gallery/marcas/CEMEX.png",
        "nombre": "Cemex",
        "valoraciones": "2,189",
        "lugar": "Zapopan, Jalisco",
        "ventar": "1,283 ",
        "compras": "1,944"
      },
      {
        "id": "4",
        "logo": "assets/images/gallery/marcas/CEMEX.png",
        "nombre": "Cemex",
        "valoraciones": "2,189",
        "lugar": "Zapopan, Jalisco",
        "ventar": "1,283 ",
        "compras": "1,944"
      },
      {
        "id": "5",
        "logo": "assets/images/gallery/marcas/CEMEX.png",
        "nombre": "Cemex",
        "valoraciones": "2,189",
        "lugar": "Zapopan, Jalisco",
        "ventar": "1,283 ",
        "compras": "1,944"
      },
      {
        "id": "6",
        "logo": "assets/images/gallery/marcas/CEMEX.png",
        "nombre": "Cemex",
        "valoraciones": "2,189",
        "lugar": "Zapopan, Jalisco",
        "ventar": "1,283 ",
        "compras": "1,944",
      }
    ];

    this.reportarduplicada = [
      {
        "id": "1",
        "logo": "assets/images/gallery/marcas/CEMEX.png",
        "nombre": "Cemex",
        "producto": "Tornillo",
        "medida": "3/4",
        "folio": "283 ",
        "fehca": "8/Diciembre/17"
      },
      {
        "id": "2",
        "logo": "assets/images/gallery/marcas/CEMEX.png",
        "nombre": "Cemex",
        "producto": "Tornillo",
        "medida": "3/4",
        "folio": "283 ",
        "fehca": "8/Diciembre/17"
      },
      {
        "id": "3",
        "logo": "assets/images/gallery/marcas/CEMEX.png",
        "nombre": "Cemex",
        "producto": "Tornillo",
        "medida": "3/4",
        "folio": "283 ",
        "fehca": "8/Diciembre/17"
      },
      {
        "id": "4",
        "logo": "assets/images/gallery/marcas/CEMEX.png",
        "nombre": "Cemex",
        "producto": "Tornillo",
        "medida": "3/4",
        "folio": "283 ",
        "fehca": "8/Diciembre/17"
      }
    ];


  }
  setActiveSales(menu) {
    console.log(menu);
    let id = menu.id;

    if (menu.id === this.menuSelectedId) {
      console.log('MISMO MENO VENTAS')
      console.log('VENTAS MENU' + id)
      console.log(' MENU SELECTED' + this.menuSelectedId);

      if (this.menuSelectedId) {
        this.router.navigateByUrl(this.purchasesMenu[this.menuSelectedId].path, { skipLocationChange: true }).then(() =>
          this.router.navigate([this.salesMenu[this.menuSelectedId].path]));
      }




    } else {
      this.menuSelectedId = id;
      console.log(this.menuSelectedId + 'MENU SELECTEd')
      this.salesMenu.forEach((men, index) => {
        if (men.id === this.activeId) {
          console.log('ActiveID' + men.id + this.activeId)
          this.salesMenu[index].active = false;

        }
      });
      this.purchasesMenu.forEach((men, index) => {
        if (men.id === this.activeId) {
          console.log('ActiveID' + men.id + this.activeId)
          this.purchasesMenu[index].active = false;

        }
      });
      console.log(this.salesMenu)

      this.salesMenu.forEach((men) => {
        if (men.id === id)
          menu.active = true;
        this.activeId = id;


      });

      console.log(this.salesMenu);
    }
  }
  cleanMenuActive() {
    //after refresh none of the menu remains selected
    this.salesMenu.forEach((men, index) => {
      this.salesMenu[index].active = false;

    });

    this.purchasesMenu.forEach((men, index) => {
      this.purchasesMenu[index].active = false;

    });
  }
  setActivePurchases(menu) {
    let id = menu.id;
    console.log('Set purchase Menu' + id)

    if (id === this.menuSelectedId) {
      //window.location.reload();

      console.log('COMPRAS MENU' + id)
      console.log(' MENU SELECTED' + this.menuSelectedId)

      if (this.menuSelectedId) {
        this.router.navigateByUrl(this.salesMenu[this.menuSelectedId].path, { skipLocationChange: true }).then(() =>
          this.router.navigate([this.purchasesMenu[this.menuSelectedId].path]));
      }




    } else {

      this.menuSelectedId = id;
      console.log(this.menuSelectedId + 'MENU SELECTED')
      this.salesMenu.forEach((men, index) => {
        if (men.id === this.activeId) {
          console.log('ActiveID' + men.id + this.activeId)
          this.salesMenu[index].active = false;

        }
      });
      this.purchasesMenu.forEach((men, index) => {
        if (men.id === this.activeId) {
          console.log('ActiveID' + men.id + this.activeId)
          this.purchasesMenu[index].active = false;

        }
      });
      console.log(this.salesMenu)

      this.purchasesMenu.forEach((men) => {
        if (men.id === id)
          menu.active = true;
        this.activeId = id;


      });

      console.log(this.salesMenu)
    }
  }
  listViewClick() {
    this.viewList = true;

    this._cardlistService.setView(true);
  };
  cardViewClick() {
    this.viewList = false;

    this._cardlistService.setView(false);
  }
  createComplaint(company) {
    this.spinner.show();

    let companyId = localStorage.getItem('$LoopBack$currentCompanyId');
    let userId = localStorage.getItem('$LoopBack$currentUserId');

    /*"companyId": {
     "type": "any",
     "required": true
   },
   "userId": {
     "type": "any",
     "required": true
   },
   "createdAt": {
     "type": "date",
     "required": true,
     "default": "$now"
   },
   "reason": {
     "type": "string",
     "required": true
   },
   "companyReportedId": {
     "type": "any",
     "required": true
   },*/
    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
    let params = {
      access_token: tokenId
    }
    let report = {
      companyId: companyId,
      userId: userId,
      reason: this.reportComment,
      companyReportedId: this.selectedCompanyToReport.id

    };
    console.log(tokenId)

    this._apiService.addDataObject('Complaints', report).subscribe(
      result => {
        console.log(result);
        setTimeout(() => {

          this.spinner.hide()
          swal({

            title: 'Exito!',
            text: 'Empresa reportada correctamente.',
            type: 'success',
            confirmButtonClass: 'btn ',

            confirmButtonColor: '#000'
          })
          $('#modalConfirmReport').modal('hide');


        }, 500);

      },
      error => {
        if (error != null) {
          setTimeout(() => {

            this.spinner.hide()
            swal({
              title: 'Error!',
              text: 'Error al reportar empresa.',
              type: 'error'
            })
            $('#modalConfirmReport').modal('hide');


          }, 250);

          console.log(error);
        }
      }

    );

  }
  updateReportCompany(company: any) {
    console.log('company update')
    this.spinner.show();

    let companyId = localStorage.getItem('$LoopBack$currentCompanyId');

    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
    let params = {
      access_token: tokenId
    }
    console.log(params);

    this._apiService.editDataObject('CompanyReports', companyId, { status: 'reported' }, params).subscribe(
      result => {
        console.log(result);
        setTimeout(() => {

          this.spinner.hide()
          swal({

            title: 'Exito!',
            text: 'Empresa reportada correctamente.',
            type: 'success',
            confirmButtonClass: 'btn ',

            confirmButtonColor: '#000'
          })



        }, 500);


      },
      error => {
        if (error != null) {
          setTimeout(() => {

            this.spinner.hide()
            swal({
              title: 'Error!',
              text: 'Error al reportar empresa.',
              type: 'error'
            })


          }, 250);

        }
      }
    );

  }
  getCompaniesToReport(type) {
    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
    let params = {
      token: tokenId
    }
    let filter = {


    };
    this._apiService.getDataObjectsFilter(type, filter).subscribe(
      result => {
        console.log(result);
        this.companiesToReport = result;
        console.log(this.companiesToReport)

      },
      error => {
        if (error != null) {
          console.log(error);
        }
      }
    );

  }

  getCompanies() {
    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
    let params = {
      token: tokenId
    }
    let filter = {


    };
    this._apiService.getDataObjectsFilter('Companies', filter).subscribe(
      (result : any) => {

        console.log(result);
        this.Companies = result;

      },
      error => {
        if (error != null) {
          console.log(error);
        }
      }
    );

  }
  openReportCompanyModal() {
    //this.getCompaniesToReport('Companies');
    this.getCompanies();
    console.log('modal report company open');
    $('#modalReportCompany').modal('show');

  }

  openConfirmReportCompanyModal(company) {
    this.selectedCompanyToReport = company;
    this.companyToReportName = company.name;
    console.log(company)
    console.log('modal confirm report company open');
    $('#modalReportCompany').modal('hide');

    $('#modalConfirmReport').modal('show');

  }
  confirmReportCompany() {

    this.createComplaint(this.selectedCompanyToReport)

  }
  getCompanyInfo(type, companyId) {
    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');

    let params = {
      filter: { include: ['state', 'country'] },
      access_token: tokenId
    };
    this._apiService.getDataById(type, companyId, params).subscribe(
      (result:any) => {

        if (result.profileUrl) {
          this.userImage = result.profileUrl

        }
        else {

        }





      },
      error => {
        if (error != null) {
          console.log(error);
        }
      }

    );
  }
  getUserInfo(type, id, filter) {
    console.log(id + "  " + type)
    console.log('HEADER GET USER INFO')
    this._apiService.getDataById(type, id, filter).subscribe(
      (result : any) => {
        console.log(result)
        this.usuario.compras = result.compras;
        this.usuario.ventas = result.ventas;
        this.usuario.personalInfo.firstName = result.personalInfo.firstName;
        this.usuario.personalInfo.lastName = result.personalInfo.lastName;
        this.usuario.role = result.role;
        this.usuario.email = result.email;
        this.usuario.compras = result.compras;
        this.usuario.companyId = result.companyId;
        this.usuario.personalInfo.name = this.usuario.personalInfo.firstName + ' ' + this.usuario.personalInfo.lastName
        console.log(this.usuario.role)
        /*           localStorage.setItem("$LoopBack$currentUserRole", result.role);
                  localStorage.setItem("$LoopBack$currentCompanyId", result.companyId);
                  localStorage.setItem("isSales", result.seller);
                  localStorage.setItem("isPurchases", result.buyer);
                  localStorage.setItem("userBranchId", result.branchId);
                  localStorage.setItem("userName", this.usuario.personalInfo.name); */

        this.usuario.ventas === true ? this.isSales = true : this.isSales = false;
        this.usuario.compras === true ? this.isPurchases = true : this.isPurchases = false;








      },
      error => {
        // this.errorMessage = <any>error;
        // if (this.errorMessage != null) {
        console.log(error);
        //
        // }
      }
    );
  }

  ngOnInit() {
    this.userName = localStorage.getItem('userName');
    this.companyId = localStorage.getItem('$LoopBack$currentCompanyId');
    this.userId = localStorage.getItem('$LoopBack$currentUserId');

    this.isSales = localStorage.getItem('isSales');
    this.isPurchases = localStorage.getItem('isPurchases');
    this.userImage = localStorage.getItem('$LoopBack$currentUserImage');
    let tok = localStorage.getItem("$LoopBack$accessTokenId");

    console.log(this.userId)
    let filter :any = {

      access_token: tok
    };



    if (this.userId) {
      //this.getUserInfo('AppUsers', this.userId, filter);

    }

    this.getCompanyInfo('Companies', this.companyId)
    this.viewSales = JSON.parse(localStorage.getItem('isSales'));


    if (localStorage.getItem('isPerfil')) {
      let asd = localStorage.getItem('isPerfil');
      this.cdr.detectChanges();

      // console.log(asd);
      asd === 'true' ? this.isperfil = true : this.isperfil = false;
    }


    if (this.router.events) {
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route : any) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route['data']),)
        .subscribe((event) => {
          this.isperfil = event['isPerfil'];
          localStorage.setItem('isPerfil', this.isperfil.toString())
          console.log(this.isperfil)
        });

      this.getNotifications('Notifications/getNotifications', result => {
        this.notifications = result.response.notifications;

      });
    }

    $('#modalReportCompany').on('hidden.bs.modal', () => {
      console.log('Modal  report closed')

    });

    $('#modalConfirmReport').on('hidden.bs.modal', () => {
      console.log('Modal confirm report closed');
      this.selectedCompanyToReport = null;
      this.reportComment = '';

    });

  }

  ngOnDestroy() {

    this.cleanMenuActive();

  }
  getNotifications(type, cb?) {
    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
    let companyId = localStorage.getItem('$LoopBack$currentCompanyId');
    let userId = localStorage.getItem('$LoopBack$currentUserId');

    let params = {

      userId: userId,

      access_token: tokenId
    };
    this._apiService.getDataObjects(type, null, params).subscribe(
      result => {
        cb(result);
        // console.log(result);




      },
      error => {
        let errorMessage = <any>error;
        if (errorMessage != null) {
          console.log(errorMessage);

        }
      }

    );
  }


  logout() {
    this.spinner.show();
    var OneSignal = window['OneSignal'] || [];
    OneSignal.push(["setSubscription", false]);
    let userId = localStorage.getItem('$LoopBack$currentUserId');
    let filter = {
      filter: {
        where: {
          id: "5ade0eff9b235a7edf66e6ef"
        }
      },
      access_token: ""
    };
    filter.access_token = localStorage.getItem('$LoopBack$accessTokenId');
    this.getItems('AppUsers', userId, filter).then((result) => {
      console.log(result)
      this.usuario.pushTokens = [];
      this.editUser(userId);
      OneSignal.isPushNotificationsEnabled(function (isEnabled) {
        if (isEnabled)
          console.log("Push notifications are enabled!");
        else
          console.log("Push notifications are not enabled yet.");
      });

      OneSignal.on('subscriptionChange', (isSubscribed) => {

        console.log("The user's subscription state is now:", isSubscribed);

      });

    });
    localStorage.removeItem("userName");
    localStorage.removeItem("$LoopBack$currentUserId");

    localStorage.removeItem("$LoopBack$currentUserImage");
    localStorage.removeItem("$LoopBack$accessTokenId");
    localStorage.removeItem("$LoopBack$accessTokenId");
    localStorage.removeItem("$LoopBack$currentUserRole");
    localStorage.removeItem("$LoopBack$currentCompanyId");
    let asd = false;
    localStorage.setItem('isPerfil', asd.toString())
    setTimeout(() => {
      this.router.navigate(['/login']);
      this.spinner.hide();
    }, 500);
    // 
  }

  closeMenuMovil() {
    $('#sales-menu').hide(100);
    $('#buy-menu').hide(100);
  }
  closeMenu() {
    $('#buy-menu').hide(100);
    $('#sales-menu').hide(100);
  }
  toggleSales() {
    $('#venta').hide(100);
    $('#compra').show(400);
  }
  toggleAll() {
    console.log('togle all')
    $('#venta').show(400);
    $('#compra').show(400);
  }
  setSale() {
    this.viewSales = true;
    localStorage.setItem("isSales", this.viewSales.toString());
  }
  setPurchase() {
    this.viewSales = false;
    localStorage.setItem("isSales", this.viewSales.toString());

  }
  editUser(id: any) {


    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
    let params = {
      access_token: tokenId
    }
    console.log(this.usuario)
    this._apiService.editDataObject('AppUsers', id, this.usuario, params).subscribe(
      result => {
        console.log(result);

      },
      error => {
        if (error != null) {


        }
      }

    );

  }
  getItems(type, id, filter) {
    var promise = new Promise((resolve, reject) => {
      this._apiService.getDataById(type, id, filter).subscribe(
        (result:any) => {
          console.log(result)
          this.usuario.compras = result.compras;
          this.usuario.ventas = result.ventas;
          this.usuario.personalInfo.firstName = result.personalInfo.firstName;
          this.usuario.personalInfo.lastName = result.personalInfo.lastName;
          this.usuario.role = result.role;
          this.usuario.email = result.email;
          this.usuario.compras = result.compras;
          this.usuario.companyId = result.companyId;
          this.usuario.personalInfo.name = this.usuario.personalInfo.firstName + ' ' + this.usuario.personalInfo.lastName
          localStorage.setItem("$LoopBack$currentUserRole", result.role);
          localStorage.setItem("$LoopBack$currentCompanyId", result.companyId);

          resolve(result);

          if (result.role == 'Director') {

          } else if (result.role == 'Compras') {

          } else if (result.role == 'Ventas') {

          } else if (result.role == 'DirCompras') {

          } else if (result.role == 'DirVentas') {

          }

        },
        error => {
          reject();
          // this.errorMessage = <any>error;
          // if (this.errorMessage != null) {
          console.log(error);
          //
          // }
        }
      );
    });
    return promise;

  }
  perfil() {
    this.isperfil = true;
    console.log("perfil", this.isperfil)
  }

  gracias(id) {
    $('#target' + id).show(800);
    $('#gracias' + id).hide(100);

  }
  cotizacion(id) {
    $('#cotiza' + id).show(800);
    $('#graciascotiza' + id).hide(100);
  }
  //Para ventas cambiar a compras
  switchSales() {
    $('#sales').show(400);
  }
  switchBuy() {
    $('#buy').show(400);
  }


  swichToPurchases() {
    $('#ventas-moviles').hide(100);
    $('#sales').hide(100);
    $('#compras-moviles').show(400);
    this.router.navigate(['/enviadas']);
  }
  switchToSales() {
    $('#ventas-moviles').show(100);
    $('#purchases').hide(100);
    $('#compras-moviles').hide(400);
    this.router.navigate(['/inicio']);
  }

  closeSales() {
    $('#sales').hide(100);
  }
  closebuy() {
    $('#buy').hide(100);
  }

  menuSales() {
    console.log("menu");
    $('#sales-menu').show(400);
  }
  menuBuy() {
    console.log("menu");
    $('#buy-menu').show(400);
  }
  hideMenuSales() {
    $('#sales-menu').hide(100);
  }
  hideMenuBuy() {
    $('#buy-menu').hide(100);
  }

}
