<!--= botones de menu para ventas ============================================================== -->

<div class="row width zero type-view">
  <div class="col-9 col-md-0 zero text name-page is-movil">ENVIADAS</div>

  <!--  <div class="col-3 col-md-3 zero view-toggle">
        <div class="row width zero">
            <div class="col-6 col-md-1 zero margin-r-20">
                <button type="button" class="btn btn-demo button-card " (click)="cardViewClick()">
                    <i class="fa fa-th-large font-7" aria-hidden="true"></i>
                </button>
            </div>
            <div class="col-6 col-md-1 zero cero center margin-15">
                <div class="nav-item dropdown">
                    <button type="button" class="btn btn-demo button-list" (click)="listViewClick()">
                        <i class="fa fa-list font-7" aria-hidden="true"></i>
                    </button>

                </div>
            </div>
        </div>

    </div> -->
</div>
<!--= botones de menu para ventas ============================================================== -->

<!-- Modal PARA LAS RESEÑAS-->
<div
  class="modal fade gb_modal"
  id="exampleModalCenter"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content martop30">
      <div class="modal-body zero">
        <button
          type="button"
          class="close btn_close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <app-review></app-review>
      </div>
      te doke
    </div>
  </div>
</div>

<div
  class="container_bg"
  style="background-image: url(assets/images/2023/bg.png)"
>
  <div class="container-scroll">
    <!-- DETALLE COTIZACION ====== -->
    <div class="row cover" id="ver">
      <div class="container pad0">
        <div class="row zero background-gray padding-top1 strong">
          <div
            class="col-2 col-md-1 zero collapse_title goBack"
            (click)="verRegresa()"
          >
            <i
              class="fa fa-chevron-left marginTop"
              style="margin-top: 20px"
              aria-hidden="true"
            ></i>
            Regresar
          </div>
          <div class="col-0 col-md-2 zero remove">
            <p class="marginTop" style="margin-top: 20px">Venta a</p>
          </div>
          <div
            *ngIf="selectedQuotation"
            class="col-9 col-md-2 zero collapse_title font-8 right"
          >
            <span class="remove"> </span>
            <span class="strong gray" *ngIf="selectedQuotation">
              {{ selectedQuotation.company.name }}
            </span>
            <div *ngIf="selectedQuotation.company" class="">
              <rating
                *ngIf="!selectedQuotation.company.rating"
                class="remove"
                [(ngModel)]="rate"
                [max]="max"
                [readonly]="isReadonly"
              ></rating>
            </div>
            <div *ngIf="selectedQuotation.company" class="">
              <rating
                *ngIf="selectedQuotation.company.rating"
                class="remove"
                [(ngModel)]="selectedQuotation.company.rating"
                [max]="max"
                [readonly]="isReadonly"
              ></rating>
            </div>
          </div>
          <div class="col-0 col-md-3 zero collapse_title remove">
            <p class="collapse_title_bold marginTop" *ngIf="selectedQuotation">
              Centro de costos :&nbsp;{{ selectedQuotation.projectName }}
            </p>
          </div>
          <div class="col-0 col-md-3 zero collapse_title remove">
            <p class="collapse_title_bold marginTop" *ngIf="selectedQuotation">
              Nombre : &nbsp;{{ selectedQuotation.name }}
            </p>
          </div>
          <div class="col-0 col-md-1 zero collapse_title remove">
            <p class="collapse_title_bold marginTop" *ngIf="selectedQuotation">
              Folio :&nbsp;{{ selectedQuotation.id }}
            </p>
          </div>
        </div>
        <div class="container-bg-blur zero">
          <div class="row width">
            <div class="container">
              <app-respondQuotation
                *ngIf="selectedQuotation"
                [paranueva]="false"
                [request]="selectedQuotation"
                [paraenviada2]="paraenviada2"
                [paraenviada1]="paraenviada1"
                [porcotizar]="porcotizar"
                [bloque]="bloque"
                [onlyViewForSales]="true"
                [completed]="true"
                [paraaceptadas]="paraaceptadas"
              ></app-respondQuotation>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row cover" id="detailRejected">
      <div class="container pad0">
        <div class="row zero background-gray padding-top1 strong">
          <div
            class="col-2 col-md-1 zero collapse_title goBack"
            (click)="verRegresa()"
          >
            <i
              class="fa fa-chevron-left marginTop"
              style="margin-top: 10px"
              aria-hidden="true"
            ></i>
            Regresar
          </div>
          <div class="col-0 col-md-2 zero remove">
            <p class="marginTop" style="margin-top: 12px; font-size: 12px">
              Cotización rechazada por:
            </p>
          </div>
          <div
            *ngIf="rejectedQuotation"
            class="col-9 col-md-2 zero collapse_title font-8 right"
          >
            <span class="remove"> </span>
            <span class="strong gray" *ngIf="rejectedQuotation">
              {{ rejectedQuotation.request.company.name }}
            </span>
            <div *ngIf="rejectedQuotation.request.company" class="">
              <rating
                *ngIf="!rejectedQuotation.request.company.rating"
                class="remove"
                [(ngModel)]="rate"
                [max]="max"
                [readonly]="isReadonly"
              ></rating>
            </div>
            <div *ngIf="rejectedQuotation.request.company" class="">
              <rating
                *ngIf="rejectedQuotation.request.company.rating"
                class="remove"
                [(ngModel)]="rejectedQuotation.request.company.rating"
                [max]="max"
                [readonly]="isReadonly"
              ></rating>
            </div>
          </div>
          <div class="col-0 col-md-3 zero collapse_title remove">
            <p class="collapse_title_bold marginTop" *ngIf="rejectedQuotation">
              Centro de costos :&nbsp;{{
                rejectedQuotation.request.projectName
              }}
            </p>
          </div>
          <div class="col-0 col-md-3 zero collapse_title remove">
            <p class="collapse_title_bold marginTop" *ngIf="rejectedQuotation">
              Nombre : &nbsp;{{ rejectedQuotation.request.name }}
            </p>
          </div>
          <div class="col-0 col-md-1 zero collapse_title remove">
            <p class="collapse_title_bold marginTop" *ngIf="rejectedQuotation">
              Folio :&nbsp;{{ rejectedQuotation.requestId }}
            </p>
          </div>
        </div>
        <div class="container-bg-blur zero">
          <div class="row width">
            <div class="container">
              <app-rejected
                *ngIf="rejectedQuotation"
                [request]="rejectedQuotation"
              ></app-rejected>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" id="regresa">
      <div class="container-bg-blur">
        <section
          class="card-view"
          id="card"
          [ngClass]="cardView ? 'show' : 'hide'"
        >
          <!-- COLLAPSE 1 -->
          <div class="card">
            <div class="card-header" id="express">
              <h5 class="mb-0">
                <a
                  class="collapse_title wine"
                  data-toggle="collapse"
                  href="#sentQuotationsCard"
                  aria-expanded="true"
                  aria-controls="sentQuotationsCard"
                >
                  <i id="1" class="fa fa-chevron-down pull-right"></i>
                  <div class="collapse_title">COTIZACIONES ENVIADAS</div>
                </a>
              </h5>
            </div>

            <div
              id="sentQuotationsCard"
              class="collapse show"
              aria-labelledby="heading-example"
            >
              <div class="card-block">
                <div class="row zero">
                  <div
                    class="col-12 col-md-4 white zero"
                    *ngFor="let quotation of sent"
                  >
                    <div class="cards">
                      <div *ngIf="quotation" class="container_logo">
                        <ng-container *ngIf="quotation.company">
                          <img
                            *ngIf="quotation.company.profileUrl"
                            class="logo-brand-img"
                            [src]="quotation.company.profileUrl"
                          />
                          <img
                            *ngIf="!quotation.company.profileUrl"
                            class="logo-brand-img"
                            src="../../../assets/images/fakeCompany.png"
                          />
                        </ng-container>

                        <div *ngIf="quotation.company" class="text_logo">
                          {{ quotation.company.name }}
                        </div>
                        <div *ngIf="quotation.company" class="">
                          <rating
                            *ngIf="!quotation.company.rating"
                            [(ngModel)]="rate"
                            [max]="max"
                            [readonly]="isReadonly"
                          ></rating>
                        </div>
                        <div *ngIf="quotation.company" class="">
                          <rating
                            *ngIf="quotation.company.rating"
                            [(ngModel)]="quotation.company.rating"
                            [max]="max"
                            [readonly]="isReadonly"
                          ></rating>
                        </div>
                      </div>
                      <div class="container_text">
                        <div class="ready">
                          <div class="product">{{ quotation.name }}</div>
                          <div class="free">
                            <i class="fa fa-circle circle"></i>
                          </div>
                        </div>
                        <!-- <div>Recibirás resultados el día: {{quotation.quantity | date: 'dd/MM/yyyy'}}
                                                    </div> -->
                        <div>
                          Recibirás resultados el día:
                          {{ quotation.endDeliveryDate | date : "dd/MM/yyyy" }}
                        </div>
                      </div>
                      <!--   <button type="button" class="button-gray">
                                                    <div class=""> RECHAZAR </div>
                                                </button> -->
                      <button
                        (click)="ver(quotation)"
                        type="button"
                        class="button-black button-wine"
                      >
                        POR APROBAR
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- COLLAPSE 2 -->
          <div class="card">
            <div class="card-header" id="rechazadas">
              <h5 class="mb-0">
                <a
                  class="collapse_title black"
                  data-toggle="collapse"
                  href="#collapseRejected"
                  aria-expanded="true"
                  aria-controls="collapseRejected"
                >
                  <i class="fa fa-chevron-down pull-right"></i>
                  <div class="collapse_title">FEEDBACK DE TU COTIZACIÓN</div>
                </a>
              </h5>
            </div>
            <div
              id="collapseRejected"
              class="collapse show"
              aria-labelledby="heading-example"
            >
              <div class="card-block">
                <div class="row">
                  <div
                    class="col-12 col-md-4 white zero"
                    *ngFor="let quotation of rejected"
                  >
                    <div class="cards">
                      <div class="container_logo">
                        <div
                          class="logo-brand"
                          [style.background-image]="
                            'url(' + 'assets/images/fakeCompany.png' + ')'
                          "
                        ></div>
                        <div
                          *ngIf="quotation.request.company"
                          class="text_logo"
                        >
                          "{{ quotation.request.company.name }}"
                        </div>
                        <div *ngIf="!quotation.company.rating" class="">
                          <rating
                            [(ngModel)]="rate"
                            [max]="max"
                            [readonly]="isReadonly"
                          ></rating>
                        </div>
                        <div *ngIf="quotation.request.company.rating" class="">
                          <rating
                            [(ngModel)]="quotation.requestf.company.rating"
                            [max]="max"
                            [readonly]="isReadonly"
                          ></rating>
                        </div>
                      </div>
                      <div class="container_text" style="margin-top: 25px">
                        <div class="ready">
                          <div class="product">
                            {{ quotation.request.name }}
                          </div>
                          <div class="free">
                            <i class="fa fa-circle"></i>
                          </div>
                        </div>
                        <div>
                          Cantidad:
                          {{
                            quotation.amount * 1.16
                              | currency : "" : true : "1.0-2"
                          }}
                        </div>
                        <!--  <div>Recibirás resultados el día:
                                                    {{quotation.request.limitQuotationDate | date:
                                                    'dd/MM/yyyy'}}</div> -->
                      </div>
                      <button
                        (click)="openRejected(quotation)"
                        type="button"
                        class="button-black zero button-black-width"
                      >
                        <div>{{ getReason(quotation) }}</div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="list-view" id="list" [ngClass]="listView ? 'show' : 'hide'">
          <!-- COLLAPSE 1 -->
          <div class="card">
            <div class="card-header" id="express">
              <h5 class="mb-0">
                <a
                  class="collapse_title wine"
                  data-toggle="collapse"
                  href="#sentQuotationsList"
                  aria-expanded="true"
                  aria-controls="sentQuotationsList"
                >
                  <i id="1" class="fa fa-chevron-down pull-right"></i>
                  <div class="">COTIZACIONES ENVIADAS</div>
                </a>
              </h5>
            </div>

            <div
              id="sentQuotationsList"
              class="collapse show"
              aria-labelledby="heading-example"
            >
              <div class="card-block">
                <div class="row zero">
                  <!--  List -->
                  <div
                    class="width blanco zero margin-card"
                    *ngFor="let sentQuotation of sent"
                  >
                    <div
                      class="cards padding0-margin1"
                      id="sent{{ sentQuotation.id }}"
                    >
                      <div class="row zero width movil-height">
                        <div
                          *ngIf="sentQuotation"
                          class="col-3 col-md-2 zero container_logo log_movil_list logo_movil"
                        >
                          <ng-container *ngIf="sentQuotation.company">
                            <img
                              *ngIf="sentQuotation.company.profileUrl"
                              class="logo-brand-img"
                              [src]="sentQuotation.company.profileUrl"
                            />
                            <img
                              *ngIf="!sentQuotation.company.profileUrl"
                              class="logo-brand-img"
                              src="../../../assets/images/fakeCompany.png"
                            />
                          </ng-container>

                          <div *ngIf="sentQuotation.company" class="">
                            <rating
                              *ngIf="!sentQuotation.company.rating"
                              [(ngModel)]="rate"
                              [max]="max"
                              [readonly]="isReadonly"
                            ></rating>
                          </div>
                          <div *ngIf="sentQuotation.company" class="">
                            <rating
                              *ngIf="sentQuotation.company.rating"
                              [(ngModel)]="sentQuotation.company.rating"
                              [max]="max"
                              [readonly]="isReadonly"
                            ></rating>
                          </div>
                        </div>
                        <div
                          class="col-3 col-md-3 zero container_logo next width_39 zero-movil"
                        >
                          <div class="text_logo pad-top-30">
                            {{ sentQuotation.name }}
                          </div>
                        </div>
                        <div
                          class="col-3 col-md-4zero container_logo next width_39 zero-movil"
                        >
                          <div class="text_logo pad-top-30">
                            Resultados el :
                            {{
                              sentQuotation.limitQuotationDate
                                | date : "dd/MM/yyyy"
                            }}
                          </div>
                        </div>
                        <div
                          class="col-3 col-md-2 zero container_logo width_39 pad-top-30 zero-movil"
                        >
                          <button
                            (click)="ver(sentQuotation)"
                            type="button"
                            class="btn-wine next width_39 margin-top-13"
                          >
                            POR APROBAR
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--  List -->
                </div>
              </div>
            </div>
          </div>
          <!-- COLLAPSE 2 -->
          <div class="card">
            <div class="card-header" id="rechazadas">
              <h5 class="mb-0">
                <a
                  class="collapse_title black"
                  data-toggle="collapse"
                  href="#rejectedQuotations"
                  aria-expanded="true"
                  aria-controls="rejectedQuotations"
                >
                  <i class="fa fa-chevron-down pull-right"></i>
                  <div class="collapse_title">FEEDBACK DE TU COTIZACIÓN</div>
                </a>
              </h5>
            </div>
            <div
              id="rejectedQuotations"
              class="collapse show zero"
              aria-labelledby="heading-example"
            >
              <div class="card-block">
                <div class="row zero">
                  <!--  List -->
                  <div
                    class="width blanco zero margin-card"
                    *ngFor="let rejectedQuotation of rejected"
                  >
                    <div
                      class="cards padding0-margin1"
                      id="rechazada{{ rejectedQuotation.id }}"
                    >
                      <div class="row zero width movil-height">
                        <div
                          class="col-3 col-md-2 zero container_logo log_movil_list logo_movil"
                        >
                          <img
                            *ngIf="rejectedQuotation.request.company.profileUrl"
                            class="logo-brand-img"
                            [src]="rejectedQuotation.request.company.profileUrl"
                          />
                          <img
                            *ngIf="
                              !rejectedQuotation.request.company.profileUrl
                            "
                            class="logo-brand-img"
                            src="../../../assets/images/fakeCompany.png"
                          />
                          <div class="">
                            <rating
                              [(ngModel)]="rate"
                              [max]="max"
                              [readonly]="isReadonly"
                            ></rating>
                          </div>
                        </div>
                        <div
                          class="col-3 col-md-3 zero container_logo next width_39 zero-movil"
                        >
                          <div class="text_logo pad-top-30">
                            {{ rejectedQuotation.request.name }}
                          </div>
                        </div>
                        <div
                          class="col-3 col-md-4zero container_logo next width_39 zero-movil"
                        >
                          <div class="text_logo pad-top-30">
                            Resultados el :
                            {{
                              rejectedQuotation.request.limitQuotationDate
                                | date : "dd/MM/yyyy"
                            }}
                          </div>
                        </div>
                        <div
                          class="col-3 col-md-2 zero container_logo width_39 pad-top-30 zero-movil"
                        >
                          <button
                            (click)="openRejected(rejectedQuotation)"
                            type="button"
                            class="btn-wine next width_39 margin-top-13"
                          >
                            {{ getReason(rejectedQuotation) }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--  List -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer -->
    <app-footer></app-footer>
    <!--Footer -->
  </div>
</div>
