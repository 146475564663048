<table *ngIf="actions!=='true'" class="table  table-hover" cellspacing="0" width="100%" style="width:100%">
  <thead>
    <tr>
      <th class="font-7 strong text-center">FOLIO</th>
      <th class="font-7 strong text-center">NOMBRE</th>
      <th class="font-7 strong text-center">CENTRO DE COSTOS</th>
      <th *ngIf="!dataIn[0].purchasesOrders" class="font-7 strong text-center">SECTOR</th>

      <th *ngIf="dataIn[0].purchasesOrders" class="font-7 strong text-center">EMPRESA</th>
      <th class="font-7 strong text-center">FECHA DE CREACIÓN</th>
    </tr>
  </thead>
  <tbody>
    <tr class="pointer" (click)="viewInfo(data,i)" *ngFor="let data of dataIn; let i = index">
      <td class="font-7 talble-movile">
        {{data.id}} </td>
      <td class="font-7 table-strong">
        {{data.name}} </td>
      <td class="font-7 table-strong">
        {{data.projectName}}</td>
        <td *ngIf="!data.purchasesOrders" class="font-7 table-font7">
          {{data.sectorName}} </td>
      <td *ngIf="data.purchasesOrders" class="font-7 table-font7">
        {{data.purchasesOrders[0].company.companyInfo.fiscalName}} </td>
      <td class="font-7 table-font7">{{data.createdAt| date: 'dd/MM/yyyy'}}</td>


    </tr>
  </tbody>
</table>
<table *ngIf="actions==='true'" class="table  table-hover zero" cellspacing="0" width="100%" style="width:100%;">
  <thead>
    <tr>
      <th class="font-7 strong">FOLIO</th>
      <th class="font-7 strong">NOMBRE</th>
      <th class="font-7 strong">CENTRO DE COSTOS</th>
      <th class="font-7 strong">RAMO</th>
      <th class="font-7 strong">FECHA DE CREACIÓN</th>
      <th class="font-7 strong">ACCIÓN</th>

    </tr>
  </thead>
  <tbody *ngIf="type==='drafts'">
    <tr *ngFor="let data of dataIn; let i = index">
      <td  (click)="finishRequest(data , i , 'finish' , true)" class=" pointer font-7 talble-movile">
        {{data.id}} </td>
      <td (click)="finishRequest(data , i , 'finish'  , true)" class=" pointer font-7 table-strong">
        {{data.name}} </td>
      <td (click)="finishRequest(data , i , 'finish'  , true)" class=" pointer font-7 table-strong">{{data.projectName}}
      </td>
      <td  (click)="finishRequest(data , i , 'finish' ,true)" class="pointer font-7 table-font7">
        {{data.sectorName}} </td>
      <td  (click)="finishRequest(data , i , 'finish'  , true)" class="pointer font-7 table-font7">{{data.createdAt| date: 'dd/MM/yyyy'}} </td>
      <td class="font-7 table-font7">
        <a (click)="onDeleteDraftConfirm(data , i , 'delete' )" style="font-weight: 600;" class="pointer">Eliminar</a>
      </td>

    </tr>
  </tbody>
  <tbody *ngIf="type==='decision'">
    <tr *ngFor="let data of dataIn; let i = index">
      <td (click)="openPurchaseDec(data.id,data)"
        class="font-7 table-strong pointer">
        {{data.id}} </td>
      <td (click)="openPurchaseDec(data.id,data)"
        class="font-7 table-strong pointer">
        {{data.name}} </td>
      <td (click)="openPurchaseDec(data.id,data)"
        class="font-7 table-strong pointer">
        {{data.projectName}}</td>
      <td (click)="openPurchaseDec(data.id,data)"
        class="font-7 table-font7 pointer">
        {{data.sectorName}} </td>
      <td (click)="openPurchaseDec(data.id,data)"
        class="font-7 table-font7 pointer">{{data.createdAt| date: 'dd/MM/yyyy'}}
      </td>
      <td class="pointer" *ngIf="data.contestLabel" class="font-7 table-font7">
        <a *ngIf="data.contestLabel==='request'"
          (click)="reDoContest(data,'redo','request')" style="font-weight: 600;"
          class="pointer">RECOTIZAR</a>
        <a *ngIf="data.contestLabel==='contest' "
          (click)="reDoContest(data,'redo','contest')" style="font-weight: 600;"
          class="pointer">RELICITAR</a>

      </td>
      <td *ngIf="!data.contestLabel" class="font-7 table-font7">


      </td>

    </tr>
  </tbody>
</table>