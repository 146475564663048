<div class="row zero confirmar top-chat-section">
    <div class="width zero">
        <div class=" container-mesajes zero">

            <div class="tab-content marg-5 top-chat-button" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <!---->
                    <div class="row zero">
                        <div *ngIf="selectedQuotation" class="col-12 col-md-4 text left zero">
                            <div class="row zero height-scroll">
                                <div class="background-white zero scroll scroll-padding-chat">
                                    <div class="bold button-pc">INFORMACIÓN SOBRE VENTA
                                        <i id="1" class="fa fa-chevron-down pull-right"></i>
                                    </div>
                                    <button class="button-detail " type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false"
                                        aria-controls="collapseExample">
                                        <div class="bold button-movile">INFORMACIÓN SOBRE VENTA
                                            <i id="1" class="fa fa-chevron-down pull-right"></i>
                                        </div>
                                    </button>

                                    <div class="collapse collapse-pc" id="collapseExample">
                                        <div class="card card-body">
                                            <div class="text font-8">
                                                <span *ngIf="selectedQuotation.details" class="bold left"> Detalles del pedido:</span>
                                                <span *ngIf="!selectedQuotation.details">

                                                    No hay detalles indicados.
                                                </span>
                                                <p *ngIf="selectedQuotation.details">
                                                    {{selectedQuotation.details}}
                                                </p>
                                                <!--   <span *ngIf="!selectedQuotation.purchasesOrders[0].sellProducts[0].comments">
                                                  
                                                       No hay detalles indicados.
                                                </span>
                                                <p *ngFor="let comment of  selectedQuotation.purchasesOrders;let i = index">
                                                    {{comment.sellProducts[i].comments}}
                                                </p> -->
                                            </div>
                                            <div class="font-8">
                                                <span class="bold left"> {{selectedQuotation.purchasesOrders[indexPurchase].paymentOptions.anticipated}}%</span>
                                                de Anticipo
                                            </div>
                                            <div class="font-8">
                                                <span class="bold left"> {{selectedQuotation.purchasesOrders[indexPurchase].paymentOptions.uponDelivery}}% </span>
                                                Contraentrega
                                            </div>
                                            <div class="font-8">
                                                <span class="bold left"> {{selectedQuotation.purchasesOrders[indexPurchase].paymentOptions.credit}}% </span>
                                                de crédito por {{selectedQuotation.purchasesOrders[indexPurchase].paymentOptions.days}} dias
                                            </div>
                                            <div class="font-8  marginTop10">
                                                <span class="bold left "> Lugar de entrega: </span>
                                                {{selectedQuotation.selectedAddress}}
                                            </div>
                                            <!-- 	<div class="font-8">
                                                    <span class="bold left"> Colonia </span> EL Colli, Zapopan, Jalisco</div> -->
                                            <div class="font-8  marginTop10">
                                                <span class="bold left "> Cantidad total cotizada</span>
                                                ${{getAmount(selectedQuotation.purchasesOrders[indexPurchase])}}
                                            </div>
                                            <div class="font-8 marginTop10">
                                                <span class="bold left"> Fecha que recibirán tu producto:</span>
                                                {{selectedQuotation.purchasesOrders[indexPurchase].estimatedDeliveryDate| date: 'dd/MM/yyyy'}}
                                            </div>
                                            <div *ngIf="ventas" class="font-8 ">
                                                <button (click)="notificationSentProduct(selectedQuotation.purchasesOrders[indexPurchase])" class="col-12 button-green ">Notificar Producto Enviado !</button>
                                            </div>
                                            <!-- <div class="font-8">
                                                <span class="bold left"> Mis condiciones de cobro:</span>
                                            </div>
                                            <div class="font-8">
                                                <span class="bold left"> 50%</span> Anticipo</div>
                                            <div class="font-8">
                                                <span class="bold left"> 25%</span> Contraentrega</div>
                                            <div class="font-8">
                                                <span class="bold left"> 25%</span> de crédito por 30 dias</div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- ================================================== -->
                        <div class="col-12 col-md-8 text zero">
                            <div *ngIf="selectedQuotation" class="row zero padding-chat">
                                <div class="background-white zero width">
                                    <div *ngIf="!ventas" class="row zero title-chat">
                                        <img *ngIf="selectedQuotation.purchasesOrders[indexPurchase].company.profileUrl" class="logo-brand-img" [src]="selectedQuotation.purchasesOrders[indexPurchase].company.profileUrl">
                                        <img *ngIf="!selectedQuotation.purchasesOrders[indexPurchase].company.profileUrl" class="logo-brand-img" src="../../../assets/images/fakeCompany.png">
                                        <div class=" col-6 col-md-4 titole-name-logo">
                                            {{selectedQuotation.purchasesOrders[indexPurchase].company.name}} 
                                        </div>
                                    </div>
                                    <div *ngIf="ventas" class="row zero title-chat">
                                        <img *ngIf="selectedQuotation.company.profileUrl" class="logo-brand-img" [src]="selectedQuotation.company.profileUrl">
                                        <img *ngIf="!selectedQuotation.company.profileUrl" class="logo-brand-img" src="../../../assets/images/fakeCompany.png">
                                        <div class=" col-6 col-md-4 titole-name-logo">
                                            {{selectedQuotation.company.name}}
                                        </div>
                                    </div>


                                    <div class="row height-22">
                                        <div class="container scroll">
                                            <ol *ngIf="viewHist " class="chat" #scrollMe [scrollTop]="scrollMe.scrollHeight">
                                                <li [ngClass]="{'self': message._sender.userId == currentUserId , 'other': message._sender.userId != currentUserId }" *ngFor="let message of messageList; let i = index">
                                                    <div class="msg">
                                                        <!--<div class="user">nombre<span class="range admin">Dev</span></div>-->
                                                        <p> {{ message.message }} </p>
                                                        <time>{{ message.createdAt | date }}</time>
                                                    </div>
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                    <!--<div class="typezone">-->
                                    <!--                                             <form (ngSubmit)="sendMessage(msgInput.value);false;msgInput.value='';">
 -->
                                    <!--<div class="emojis"></div>-->
                                    <!--</div>-->
                                    <form (ngSubmit)="sendMessage(messageToSend);" action="">
                                        <div class="row zero container-input">
                                            <div class=" col-11 col-md-11 zero container-input">

                                                <textarea type="text" [(ngModel)]="messageToSend" name="msg" #msg="ngModel" (keydown.enter)="sendMessage(messageToSend);false;msgInput.value='';"
                                                    placeholder="Escribe un mensaje"></textarea>


                                            </div>
                                            <div class="col-1 col-md-1 zero">;
                                                <button type="submit" class="bold bg-transparent">
                                                    <i class="fa fa-chevron-circle-right clip" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                            <!--<div class="col-1 col-md-1 zero">-->
                                            <!--<button class="bold send bg-transparent">-->
                                            <!--<i class="fa fa-paperclip icon_send" aria-hidden="true"></i>-->
                                            <!--</button>-->
                                            <!--</div>-->
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <!-- ================================================= -->
                        <div class="top-chat-section"> &nbsp; </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>