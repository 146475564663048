<!-- Modal Show File-->
<div class="modal fade gb_modal" id="modalProductImage" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
 aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content martop30">
			<div class="modal-body zero">
				<button type="button" class="close btn_close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<!--  *" -->
				<div *ngIf="selectedProductImageURL" class="row zero  width" style="padding:17px;">
					<img [src]="selectedProductImageURL" style="width:460px ; height:400px" alt="">


				</div>
			</div>
		</div>
	</div>
</div>


<!--= botones de menu para ventas ============================================================== -->

<div class="row width zero type-view">
    <div class="col-9 col-md-0 zero text name-page is-movil"> POR COTIZAR </div>

   <!--  <div class="col-3 col-md-3 zero view-toggle">
        <div class="row width zero">
            <div class="col-6 col-md-1 zero margin-r-20">
                <button type="button" class="btn btn-demo button-card " (click)="cardViewClick()">
                    <i class="fa fa-th-large font-7" aria-hidden="true"></i>
                </button>
            </div>
            <div class="col-6 col-md-1 zero cero center margin-15">
                <div class="nav-item dropdown">
                    <button type="button" class="btn btn-demo button-list" (click)="listViewClick()">
                        <i class="fa fa-list font-7" aria-hidden="true"></i>
                    </button>

                </div>
            </div>
        </div>

    </div> -->
</div>
<!--= botones de menu para ventas ============================================================== -->
<div class="modal hide fade in" tabindex="" role="dialog" id="cotizacionPdf">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Tu cotizacion</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div *ngIf="previewQuotation" class="modal-body" style="height: 600px">
                <app-pdfQuotation (confirmedQuotation)="acceptedQuotation($event)" [previewQuotation]="previewQuotation"
                    [request]="selectedRequest"></app-pdfQuotation>
            </div>
            <div class="modal-footer">
                <button (click)="savePreviewedQuotation()" type="button" class="btn btn-primary" style="background-color: #000 !important ;border: 1px solid #000!important">Enviar</button>
                <button (click)="cancelPreviewedQuotation()" type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            </div>
        </div>
    </div>
</div>
<!-- Modal PARA LAS RESEÑAS-->
<div class="modal fade gb_modal" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content martop30">
            <div class="modal-body zero">
                <button type="button" class="close btn_close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
<!--                 <app-cuestionario></app-cuestionario>
 -->            </div>
        </div>
    </div>
</div>

<div class="container_bg" style="background-image: url(assets/images/2023/bg.png)">

    <!-- <div class="text name-page is-movil"> POR COTIZAR </div> -->
    <div class="container-scroll">
        <!-- DETALLE COTIZACION ====== -->
        <div class="row cover" id="ver">
            <div *ngIf="selectedRequest" class="container pad0">
                <div class="row zero background-gray padding-top1 strong">
                    <div class="col-12 col-md-2 zero collapse_title goBack" (click)="verRegresa()">
                        <i class="fa fa-chevron-left marginTop" aria-hidden="true"></i>
                    </div>
                    <div class="col-1 col-md-1 zero remove"></div>
                    <div class="col-2 col-md-2 zero remove">
                        <p class="marginTop">Venta posible a</p>
                    </div>
                    <div *ngIf="selectedRequest.company" class="col-4 col-md-4 zero remove collapse_title font-8 right">
                        <span class="strong gray ">{{selectedRequest.company.name}} </span>
                        <rating *ngIf="!selectedRequest.company.rating" class="remove" [(ngModel)]="rate" [max]="max"
                            [readonly]="isReadonly"></rating>
                        <rating *ngIf="selectedRequest.company.rating" class="remove" [(ngModel)]="selectedRequest.company.rating"
                            [max]="max" [readonly]="isReadonly"></rating>

                    </div>
                    <div class="col-3 col-md-3 zero remove collapse_title">
                        <p class="collapse_title_bold marginTop"> Folio :&nbsp;{{selectedRequest.id}}</p>
                    </div>
                </div>
                <div class="container-bg-blur zero">
                    <div class="row width">
                        <div class="container">
                            <app-respondQuotation *ngIf="verDetalleQuotation"  (fileProductUrl)="emitedProducFileURl($event)" (recQuotation)="emitedQuotation($event)"
                                [request]="selectedRequest" [paranueva]="true" [paraenviada2]="paraenviada2"
                                [paraenviada1]="paraenviada1" [porcotizar]="porcotizar" [bloque]="bloque"
                                [paraaceptadas]="paraaceptadas"></app-respondQuotation>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="container" id="regresa">

            <div class="container-bg-blur">
                <section class="card-view" id="card" [ngClass]="cardView ? 'show' : 'hide'">
                    <!-- COLLAPSE  -->
                    <div class="card">
                        <div class="card-header" id="express">
                            <h5 class="mb-0">
                                <a class="collapse_title wine" data-toggle="collapse" href="#pendingQuotationsCard"
                                    aria-expanded="true" aria-controls="pendingQuotationsCard">
                                    <i id="1" class="fa fa-chevron-down pull-right"></i>
                                    <div class="collapse_title"> POR COTIZAR </div>
                                </a>
                            </h5>
                        </div>
                        <div id="pendingQuotationsCard" class="collapse show" aria-labelledby="heading-example">
                            <div class="card-block" class="overCard" [ngClass]="{'overCard':porCotizar.length>6 ,'overCardSmall':porCotizar.length>1 ,'overCardMed':porCotizar.length>3}">
                                <div class="row" *ngIf="porCotizar.length>0 ">
                                    <!--  cards -->
                                    <div class=" col-12 col-md-4 white zero" *ngFor="let accepted of porCotizar">

                                        <div class=" cards">
                                            <div class="container_logo">
                                                <img *ngIf="accepted.company.profileUrl" class="logo-brand-img" [src]="accepted.company.profileUrl">
                                                <img *ngIf="!accepted.company.profileUrl" class="logo-brand-img" src="../../../assets/images/fakeCompany.png">
                                                <div *ngIf="accepted.company" class="text_logo">
                                                    "{{accepted.company.name}}" </div>
                                                <div class="">
                                                    <rating *ngIf="!accepted.company.rating" [(ngModel)]="rate" [max]="max"
                                                        [readonly]="isReadonly"></rating>
                                                    <rating *ngIf="accepted.company.rating" [(ngModel)]="accepted.company.rating"
                                                        [max]="max" [readonly]="isReadonly"></rating>

                                                </div>
                                            </div>
                                            <div class="container_text">
                                                <div class="ready">
                                                    <div class="product"> {{accepted.name}} </div>
                                                    <div class="free">
                                                        <i class="fa fa-circle"></i>
                                                    </div>
                                                </div>
                                                <div  *ngIf="accepted.contestLabel==='request'" >Fecha límite para cotizar: {{accepted.limitQuotationDate | date:
                                                    'dd/MM/yyyy'}}
                                                </div>
                                                <div  *ngIf="accepted.contestLabel==='request'">Fecha de entrega de pedido: {{accepted.endDeliveryDate | date:
                                                    'dd/MM/yyyy'}}</div>
                                                    <div >{{filterSubcategory(accepted.requestSubcategoriesArray,accepted.subcategoryFilter)}}</div>
                           
                                            </div>
                                            <!--  <button type="button" class="button-gray">
                                                <div (click)="niegaRechazo(accepted.id)" class=""> RECHAZAR </div>
                                            </button> -->
                                            <button (click)="ver(accepted)" type="button" class="button-black w100">
                                                VER SOLICITUD
                                            </button>
                                        </div>
                                        <div class="row cover" id="rechaza{{accepted.id}}">
                                            <div class="card-rejected">
                                                <div class="accept accept-position">
                                                    <p>Seguro que deseas rechazar la solicitud de cotización de
                                                        <span class="name-rejected">{{accepted.company.name}}</span>?</p>
                                                    <button (click)="rejectRequest(accepted)"> Si, rechazar solicitud
                                                    </button>
                                                    <button (click)="cancelar(accepted.id)"> No, volver al menú
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="porCotizar.length===0" class="col-md-12 bg_blanco">
                                    <h2 class="text-center"> No tienes nada por cotizar</h2>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="list-view" id="list" [ngClass]="listView ? 'show' : 'hide'">
                    <div class="card-header" id="express">
                        <h5 class="mb-0">
                            <a class="collapse_title wine" data-toggle="collapse" href="#pendingQuotationsList"
                                aria-expanded="true" aria-controls="pendingQuotationsList">
                                <i id="1" class="fa fa-chevron-down pull-right"></i>
                                <div class="collapse_title"> POR COTIZAR </div>
                            </a>
                        </h5>
                    </div>
                    <!-- COLLAPSE  -->
                    <div class="card">
                        <div id="pendingQuotationsList" class="collapse show zero" aria-labelledby="heading-example">
                            <div class="card-block">
                                <div class="row zero width">
                                    <!--  List -->
                                    <div class="width blanco zero margin-card" *ngFor="let accepted of porCotizar">
                                        <div class="cards padding0-margin1" id="accepted{{accepted.id}}">
                                            <div class="row zero width movil-height">

                                                <div class="col-3 col-md-2 zero container_logo log_movil_list logo_movil">
                                                    <img *ngIf="accepted.company.profileUrl" class="logo-brand-img"
                                                        [src]="accepted.company.profileUrl">
                                                    <img *ngIf="!accepted.company.profileUrl" class="logo-brand-img"
                                                        src="../../../assets/images/fakeCompany.png">
                                                    <div  *ngIf="accepted.company" class="">
                                                        <rating  *ngIf="!accepted.company.rating" [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating>

                                                    </div>
                                                    <div  *ngIf="accepted.company"  class="">
                                                        <rating  *ngIf="accepted.company.rating" [(ngModel)]="accepted.company.rating" [max]="max" [readonly]="isReadonly"></rating>

                                                    </div>
                                                </div>
                                                <div class="col-3 col-md-3 zero container_logo next width_39  zero-movil">
                                                    <div class="text_logo  pad-top-30 "> {{accepted.name}} </div>
                                                </div>
                                                <div class="col-3 col-md-4 zero container_logo next width_39  zero-movil">
                                                    <div class="text_logo  pad-top-30 ">Resultados el :
                                                        {{accepted.limitQuotationDate| date: 'dd/MM/yyyy'}} </div>
                                                </div>
                                                <div class="col-3 col-md-2 zero container_logo  width_39 pad-top-30  zero-movil">
                                                    <button (click)="ver(accepted)" type="button" class="btn-wine  next width_39  margin-top-13 ">
                                                        VER SOLICITUD </button>
                                                </div>
                                                <!--    <div class="col-3 col-md-2 zero container_logo next width_39  zero-movil">
                                                    <button  (click)="ver(quotation)" type="button" class="  next width_39  margin-top-13 ">
                                                        POR APROBAR
                                                    </button>
                                                </div> -->
                                                <!--        <div class="col-3 col-md-3 zero padding1 container_logo next width_39  zero-movil">
                                                <div class="text_logo "> {{sentQuotation.name}} </div>
                                            </div>
                                            <div class="col-4 col-md-2 zero padding1 container_text  next width_39  margin-left-24 martop-7 quotation-movil">
                                                <div class=" font0-8"><p class=" only-movile">Recibiras resultados el :</p> <span class="strong">{{sentQuotation.limitQuotationDate| date: 'dd/MM/yyyy' }}</span></div>
                                            </div>
                                            <div class="col-4 col-md-2 zero padding1 container_text  next width_39  margin-left-24 martop-7 quotation-movil">
                                                <div class=" font0-8"> <span class="strong">{{sentQuotation.limitQuotationDate| date: 'dd/MM/yyyy' }}</span></div>
                                            </div>
                                            <div class="col-4 col-md-3 zero padding1 container_text  next width_39  margin-left-24 martop-7 quotation-movil">
                                                <div class=" font0-8"> 
                                                    <button id="button-go"  (click)="ver(quotation)" type="button" class=" padding1 next width_39  margin-top-13 quote-movil movil-button">
                                                    POR APROBAR
                                                    </button></div>
                                            </div> -->
                                                <!-- <div class="col-4 col-md-2 zero padding1 container_text  next width_39  margin-left-24 martop-34 quotation-movil quote-money">
                                                <div class=" font0-8 ">A cotizar<span class="strong">{{sentQuotation.cantidad}}</span></div>
                                            </div> -->
                                                <!--    <div class="strong wine font-8 width" > 
                                                <button id="button-go"  (click)="ver(quotation)" type="button" class="col-4 col-md-3 zero padding1 next width_39  margin-top-13 quote-movil movil-button">
                                                POR APROBAR
                                                </button>
                                            </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <!--  List -->

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    <app-footer></app-footer>
    </div>
</div>