<div class="container_bg" style="background-image:url(assets/images/2023/bg.png);">
	<div class="position-page only-movile"> ENVIADAS </div>
	<div class="container-scroll">


		<!-- -->



		<div class="container regresaborrador regresaprobada">

			<div class="container-bg-blur">
				<!-- COLLAPSE 1 -->
				<div class="zero">
					<div class="card-header" id="express">
						<h5 class="mb-0">
							<a class="collapse_title black" data-toggle="collapse" href="#collapse-example" aria-expanded="true" aria-controls="collapse-example">
								<i id="1" class="fa fa-chevron-down pull-right"></i>
								<div class="collapse_title"> BORRADORES </div>
							</a>
						</h5>
					</div>
					<div id="collapse-example" class="collapse show" aria-labelledby="heading-example">
						<div class="card-block zero">
							<div class="row black background-white zero" [ngClass]="{'overTable':drafts.length>6 ,'overTableSmall':drafts.length>1 ,'overTableMed':drafts.length>3}">
							<!-- 	<app-client-table style="width: 100%;"  [dataIn]="drafts" [type]="'drafts'" [actions]="'true'"
								(openEvent)="viewData($event)"
								></app-client-table> -->
								<table id="tableDrafts" class="table  table-hover zero" cellspacing="0" width="100%" style="width:100%;">
									<thead>
										<tr>
											<th class="font-7 strong">FOLIO</th>
											<th class="font-7 strong">NOMBRE</th>
											<th class="font-7 strong">CENTRO DE COSTOS</th>
											<th class="font-7 strong">RAMO</th>
											<th class="font-7 strong">FECHA DE CREACIÓN</th>
											<th class="font-7 strong">ACCIÓN</th>

										</tr>
									</thead>
									<tbody *ngIf="drafts.length>0">
										<tr *ngFor="let draft of drafts">
											<td  (click)="finishRequest(draft.id,true)" class=" pointer font-7 talble-movile">
												{{draft.id}} </td>
											<td (click)="finishRequest(draft.id,true)" class=" pointer font-7 table-strong">
												{{draft.name}} </td>
											<td (click)="finishRequest(draft.id,true)" class=" pointer font-7 table-strong">{{draft.projectName}}
											</td>
											<td  (click)="finishRequest(draft.id,true)" class="pointer font-7 table-font7">
												{{draft.sectorName}} </td>
											<td  (click)="finishRequest(draft.id,true)" class="pointer font-7 table-font7">{{draft.createdAt| date: 'dd/MM/yyyy'}} </td>
											<td class="font-7 table-font7">
												<a (click)="onDeleteDraftConfirm(draft.id)" style="font-weight: 600;" class="pointer">Eliminar</a>
											</td>

										</tr>
									</tbody>
								</table>
 
								<div class="col-md-12" *ngIf="drafts.length===0">
									<h2 class="center"> No tienes borradores</h2>
								</div>
							</div>
						</div>
					</div>
					<!-- COLLAPSE 2 -->
					<div class="margin-top2">
						<div class="card-header" id="nuevas">
							<h5 class="mb-0">
								<a class="collapse_title black" data-toggle="collapse" href="#collapse-example2" aria-expanded="true" aria-controls="collapse-example2">
									<i class="fa fa-chevron-down pull-right"></i>
									<div class="collapse_title"> REQUISICIONES </div>
								</a>
							</h5>
						</div>
						<div id="collapse-example2" class="collapse show" aria-labelledby="heading-example">
							<div class="card-block">
								<div class="row black background-white" [ngClass]="{'overTable':pending.length>6 ,'overTableSmall':pending.length>1 ,'overTableMed':pending.length>3}">
									<table id="tablePendings" class="table  table-hover" cellspacing="0" width="100%" style="width:100%">
										<thead>
											<tr>
												<th class="font-7 strong">FOLIO</th>
												<th class="font-7 strong">NOMBRE</th>
												<th class="font-7 strong">CENTRO DE COSTOS</th>
												<th class="font-7 strong">RAMO</th>
												<th class="font-7 strong">FECHA DE CREACIÓN</th>

											</tr>
										</thead>
										<tbody *ngIf="pending.length>0">
											<tr class="pointer" (click)="finishRequest(draft.id,false)" *ngFor="let draft of pending">
												<td class="font-7 talble-movile">
													{{draft.id}} </td>
												<td class="font-7 table-strong">
													{{draft.name}} </td>
												<td class="font-7 table-strong">{{draft.projectName}}
												</td>
												<td class="font-7 table-font7">
													{{draft.sectorName}} </td>
												<td class="font-7 table-font7">{{draft.createdAt| date: 'dd/MM/yyyy'}} </td>


											</tr>
										</tbody>
									</table>

								</div>
								<div *ngIf="pending.length===0" class="col-md-12 background-white">
									<h2 class="center">No tienes requisiciones pendientes</h2>

								</div>


							</div>
						</div>
					</div>
					<!-- COLLAPSE 3 -->
					<div class="margin-top2">
						<div class="card-header" id="nuevas">
							<h5 class="mb-0">
								<a class="collapse_title black" data-toggle="collapse" href="#collapse-example3" aria-expanded="true" aria-controls="collapse-example3">
									<i class="fa fa-chevron-down pull-right"></i>
									<div class="collapse_title"> EN ESPERA DE COTIZACIONES </div>
								</a>
							</h5>
						</div>
						<div id="collapse-example3" class="collapse show" aria-labelledby="heading-example">
							<div class="card-block">
								<div class="row zero black background-white" [ngClass]="{'overTable':standby.length>6 ,'overTableSmall':standby.length>1 ,'overTableMed':standby.length>2}">
									<table id="tableStandBy" class="table  table-hover" cellspacing="0" width="100%" style="width:100%">
										<thead>
											<tr>
												<th class="font-7 strong">FOLIO</th>
												<th class="font-7 strong">NOMBRE</th>
												<th class="font-7 strong">CENTRO DE COSTOS</th>
												<th class="font-7 strong">RAMO</th>
												<th class="font-7 strong">FECHA DE CREACIÓN</th>

											</tr>
										</thead>
										<tbody *ngIf="standby.length>0">
											<tr class="pointer" (click)="finishRequest(draft.id)" *ngFor="let draft of standby">
												<td class="font-7 talble-movile">
													{{draft.id}} </td>
												<td class="font-7 table-strong">
													{{draft.name}} </td>
												<td class="font-7 table-strong">{{draft.projectName}}
												</td>
												<td class="font-7 table-font7">
													{{draft.sectorName}} </td>
												<td class="font-7 table-font7">{{draft.createdAt| date: 'dd/MM/yyyy'}} </td>



											</tr>
										</tbody>
									</table>

								</div>
								<div *ngIf="standby.length===0" class="col-md-12 background-white">
									<h2 class="center">No tienes en espera de cotizacion</h2>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Footer -->
		<app-footer></app-footer>
		<!--Footer -->

	</div>
</div>