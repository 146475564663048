import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-viewHistoryQuotation',
  templateUrl: './viewHistoryQuotation.component.html',
  styleUrls: ['./viewHistoryQuotation.component.css']
})
export class ViewHistoryQuotationComponent implements OnInit {
	public detallecompra : Array<any>;
  constructor() {
  	 

  }

  ngOnInit() {
  }

}