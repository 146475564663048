import { Component, OnInit, Input } from '@angular/core';
declare var $: any;
import * as html2canvas from "html2canvas";
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2'
import { Router } from '@angular/router';
import * as SendBird from 'SendBird';
import { UUID } from 'angular2-uuid';

@Component({
  selector: 'app-negotiationQuotation',
  templateUrl: './negotiationQuotation.component.html',
  styleUrls: ['./negotiationQuotation.component.css']
})
export class NegotiationQuotatioComponent implements OnInit {
  @Input() concretada: any;
  public detallecompra: Array<any>; public chatComponent = false;
  // Rating
  max: number = 5;
  rate: number = 4;
  isReadonly: boolean = true;
  public selectedQuotation: any;
  public selectedIndex: any;

  concretadaIndexSelected: boolean;

  public sb: any; //SendBird
  public channel: any; //Channel (Chat) SendBird
  constructor() {


  }

  ngOnInit() {
    console.log(this.concretada);
    this.selectedQuotation = this.concretada;

  }
  getAmount(compra) {
    var total = 0;
    compra.sellProducts.forEach((product) => {
      let price = product.quantity * product.amount
      total += price;
    });
    return (total * 1.16).toFixed(2)
  }


  mensajes(quotation, index?) {
    this.selectedQuotation = quotation;
    this.selectedIndex = index;

    this.concretadaIndexSelected = index;

    console.log(quotation)
    this.chatComponent = true;
    $('#verChat').show(400);
    $('#purchasesOrders').hide(10);
    $('#titleConcretadas').hide(10);

    $('.ocultartitulo').hide(10);

  }
  goBackConcreted() {
    this.chatComponent = false;
    $('#purchasesOrders').show(400);
    $('#verChat').hide(10);
    $('#titleConcretadas').show(10);

    $('.ocultartitulo').show(10);
  }
}
