import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css']
})
export class ReviewComponent implements OnInit {
	   public resenas : Array<any>;
  constructor() {
  	this.resenas = [
        {
          "nombre":"CEMEX",
          "validaciones":"2,189",
          "lugar":"Zapopan, Jalisco",
          "ventas":"2,189",
          "compras":"1,994",
          "calificacion":"4.7",
          "lasresenas":[
              {
                "nombre": "Corey",
                "horas":"3",
                "start1":"fa-star",
                "start2":"fa-star",
                "start3":"fa-star-o",
                "start4":"fa-star-o",
                "start5":"fa-star-o",
                "detalles":"Es un hecho establecido hace demasiado tiempo que un lector se distraerá con el contenido del texto de un sitio mientras que mira su diseño"
              },
              {
                "nombre": "Corey",
                "horas":"3",
                "start1":"fa-star",
                "start2":"fa-star",
                "start3":"fa-star-o",
                "start4":"fa-star-o",
                "start5":"fa-star-o",
                "detalles":"Es un hecho establecido hace demasiado tiempo que un lector se distraerá con el contenido del texto de un sitio mientras que mira su diseño"
              },
              {
                "nombre": "Corey",
                "horas":"3",
                "start1":"fa-star",
                "start2":"fa-star",
                "start3":"fa-star-o",
                "start4":"fa-star-o",
                "start5":"fa-star-o",
                "detalles":"Es un hecho establecido hace demasiado tiempo que un lector se distraerá con el contenido del texto de un sitio mientras que mira su diseño"
              },
              {
                "nombre": "Corey",
                "horas":"3",
                "start1":"fa-star",
                "start2":"fa-star",
                "start3":"fa-star-o",
                "start4":"fa-star-o",
                "start5":"fa-star-o",
                "detalles":"Es un hecho establecido hace demasiado tiempo que un lector se distraerá con el contenido del texto de un sitio mientras que mira su diseño"
              },
              {
                "nombre": "Corey",
                "horas":"3",
                "start1":"fa-star",
                "start2":"fa-star",
                "start3":"fa-star-o",
                "start4":"fa-star-o",
                "start5":"fa-star-o",
                "detalles":"Es un hecho establecido hace demasiado tiempo que un lector se distraerá con el contenido del texto de un sitio mientras que mira su diseño"
              }
          ],
          
        }
    ];


  }

  ngOnInit() {
  }

}
