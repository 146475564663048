import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
declare var $: any;
import { ChatService } from '../../../services/chat/chat.service';
import { Action } from '../../../services/chat/models/action';
import { User } from '../../../services/chat/models/user';
import { Message } from '../../../services/chat/models/message';
import { Event } from '../../../services/chat/models/event';
import { ApiService } from '../../../services/apiService';
import { Observable } from 'rxjs';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2'
import * as SendBird from 'SendBird';
import { UUID } from 'angular2-uuid';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.css'],
    providers: [ChatService, ApiService]
})



export class ChatComponent implements OnInit {
    @Input() selectedQuotation: any;
    @Input() indexPurchase: any;
    @Input() ventas: boolean;
    viewHist = false;
    /*chat stuff*/
    public ChannelHandler: any;
    action = Action;
    user: User;
    messages: Message[] = [];
    messageContent: string;
    ioConnection: any;
    public messageList: any;
    public dateNow: any;
    public messageToSend = '';
    public currentUserId = localStorage.getItem('$LoopBack$currentUserId');
    sb: any;
    channel: any;
    onReconnectStarted = null;
    onReconnectSucceeded = null;
    onReconnectFailed = null;

    constructor(private chatService: ChatService, private _router: Router, private route: ActivatedRoute, private spinner: NgxSpinnerService, private _apiService: ApiService, private cdRef: ChangeDetectorRef) {


    }

    ngAfterViewChecked() {
        // console.log( "! changement de la date du composant !" );
        this.dateNow = new Date();
        this.cdRef.detectChanges();
    }

    /*--------------------------*/
    private initIoConnection(): void {
        this.chatService.initSocket();


        this.ioConnection = this.chatService.onMessage()
            .subscribe((message: any) => {
                console.log(message)
                this.messages.push(message);
            });

        this.chatService.onEvent(Event.CONNECT)
            .subscribe(() => {
                console.log('connected');
            });

        this.chatService.onEvent(Event.DISCONNECT)
            .subscribe(() => {
                console.log('disconnected');
            });
    }


    public sendNotification(params: any, action: Action): void {
        let message: Message;

        if (action === Action.JOINED) {
            message = {
                from: this.user,
                action: action
            }
        } else if (action === Action.RENAME) {
            message = {
                action: action,
                content: {
                    username: this.user.name,
                    previousUsername: params.previousUsername
                }
            };
        }

        this.chatService.send(message);
    }

    saveMessage() {
        this.spinner.show();

        let usuario = {
            personalInfo: {
                name: '',
                fistName: '',
                lastName: ''
            },
            companyId: '',
            email: '',
            password: '',
            role: '',
            seller: false,
            buyer: false,
            branchId: ''
        }

        let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
        let params = {
            token: tokenId
        }
        this._apiService.addDataObject('Messages', usuario).subscribe(
            (result: any) => {
                console.log(result);
                setTimeout(() => {

                    this.spinner.hide()


                }, 500);

            },
            error => {
                if (error != null) {
                    setTimeout(() => {

                        this.spinner.hide()
                        swal({
                            title: 'Error!',
                            text: 'Error al Guardar.',
                            type: 'error',
                            confirmButtonClass: 'btn ',

                            confirmButtonColor: '#000'
                        })


                    }, 250);

                    console.log(error);
                }
            }
        );

    }
    notificationSentProduct(order) {

        this.editPurchaseOrder(order);

    }
    _createConnectionHandler(key) {
        const handler = new this.sb.ConnectionHandler();
        handler.onReconnectStarted = () => {
            console.log('Started')
            if (this.onReconnectStarted) {
                this.onReconnectStarted();
            }
        };
        handler.onReconnectSucceeded = () => {
            console.log('succeded')

            if (this.onReconnectSucceeded) {
                this.onReconnectSucceeded();
            }
        };
        handler.onReconnectFailed = () => {
            console.log('Failed')

            if (this.onReconnectFailed) {
                this.onReconnectFailed();
            }
        };
        this.sb.addConnectionHandler(key, handler);
    }

    createChat(quotation) {

        //if (vm.nameNewGroup.trim() != "") {
        console.log(quotation)
        var cover_url = "unknown_group.png";
        var dataFriend = {};
        var name = ''

        if (quotation.purchasesOrders[this.indexPurchase].chat) {
            console.log('Ya tiene chat esta compra');
            console.log(quotation.purchasesOrders[this.indexPurchase].chat)
            this.sb.GroupChannel.getChannel(quotation.purchasesOrders[this.indexPurchase].chat.channelUrl, (channel, error) => {

                if (error) {
                    this.spinner.hide();

                    console.error(error);
                    return;
                }
                this.channel = channel;
                this.historyMessage(this.channel, 10);
                console.log(channel);





                this.ChannelHandler.onMessageReceived = (channel, message) => {
                    console.log(channel, message);

                    this.historyMessage(this.channel, 50);
                };

                this.sb.addChannelHandler('channel', this.ChannelHandler);

                //  this.initHandler(this.sb);
            });

        } else {
            console.log('No tiene chat esta compra se va a crear');
            var poolUserIds = [quotation.userId.toString()];

            quotation.purchasesOrders.forEach((element, index) => {
                console.log(userIds);
                poolUserIds.push(element.sellerUserId);


            });
            let userIds = [quotation.userId.toString(), quotation.purchasesOrders[this.indexPurchase].sellerUserId.toString()];
            console.log(userIds)
            this.sb.GroupChannel.createChannelWithUserIds(userIds, false, name, cover_url, JSON.stringify(dataFriend), '', (createdChannel, error) => {
                console.log(createdChannel)
                if (error) {
                    this.spinner.hide();

                    console.log(error, 'error creating chat');
                    return;
                } else {
                    console.log("created", createdChannel);
                    this.saveChat(quotation, createdChannel.url)
                    this.channel = createdChannel;
                    this.historyMessage(this.channel, 50);
                    //this.initHandler(this.sb);
                    //this.sendMessage('maldito seas');
                }


            });

        }


    }

    historyMessage(channel, limit) {
        var messageListQuery = channel.createPreviousMessageListQuery();

        messageListQuery.load(limit, true, (messageList, error) => {
            if (error) {
                console.log("error-cargando mensajes", error);
                return;
            } else {
                this.viewHist = true;
                this.spinner.hide();

                console.log(messageList, 'lista de mensajessss')
                this.messageList = messageList;
                messageList.forEach(element => {
                    console.log(element.message)
                });
            }
            var messageInfo
            var sender
            var historyData = {}
            var dateConvert
            messageList.reverse()
            messageList.forEach(function (messageData) {
                messageInfo = messageData.message


                dateConvert = new Date(messageData.createdAt)
                var time = dateConvert.toLocaleTimeString()

                historyData = {
                    message: messageInfo,
                    sender: sender,
                    date: (dateConvert.getDate()).toString() + "/" + (dateConvert.getMonth() + 1).toString() + "/" + (dateConvert.getFullYear()).toString() + " " + time
                }
            })

        });
    }

    sendMessage(myNewMessage) {
        this.messageToSend = myNewMessage;
        console.log('mensaje a enviar')

        var data = {}
        var newMessage = {}
        var sender
        var dateConvert
        if (myNewMessage === '') {
            console.log('mensaje vacio')
        } else {
            this.channel.sendUserMessage(myNewMessage, JSON.stringify(data), '', (message, error) => {
                if (error) {
                    console.log("error-send message", error)
                    return
                } else {
                    console.log('message ');
                    console.log(message);

                }
                this.messageToSend = '';
                this.historyMessage(this.channel, 10)

            })

        }

    }
    test() {
        console.log('alv woee')
    }
    initHandler(sb) {
        var ChannelHandler = sb.ChannelHandler();




        this.sb.ChannelHandler().onMessageReceived = function (channel, message) {
            console.log(channel, message);

            var messageListQuery = channel.createPreviousMessageListQuery();

            messageListQuery.load(10, true, (messageList, error) => {
                if (error) {
                    console.log("error-cargando mensajes", error);
                    return;
                } else {
                    this.viewHist = true;
                    console.log(messageList, 'lista de mensajessss');
                    console.log(this.messageList, 'antessss');
                    this.messageList = messageList;
                }
            });
        };

        this.sb.addChannelHandler('channel', ChannelHandler);

    }

    getAmountTotal(quotation) {
        let amountTotal = 0;
        //console.log(quotation.purchasesOrders.length);
        quotation.purchasesOrders.forEach(element => {
            amountTotal += element.amount;
            //console.log(element.amount)

        });
        return (amountTotal * 1.16).toFixed(2);
    }

    getAmount(compra) {
        var total = 0;
        compra.sellProducts.forEach((product) => {
            let price = product.quantity * product.amount
            total += price;
        });
        return (total * 1.16).toFixed(2)
    }

    editPurchaseOrder(order: any) {
        this.spinner.show()
        let id = order.id;
        console.log(id)
        let companyId = localStorage.getItem('$LoopBack$currentCompanyId');

        let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
        let params = {
            access_token: tokenId
        }
        console.log(params)
        this._apiService.editDataObject('PurchaseOrders', id, { delivered: false, sent: true, status: 'send' }, params).subscribe(
            (result: any) => {
                console.log(result);
                this.spinner.hide()
                this.editQuotation(this.selectedQuotation);



            },
            error => {
                if (error != null) {


                }
            }
        );

    }

    editQuotation(quotation: any) {
        let id = quotation.id;
        console.log(id)
        let companyId = localStorage.getItem('$LoopBack$currentCompanyId');

        let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
        let params = {
            access_token: tokenId
        }
        console.log(params)
        this._apiService.editDataObject('Requests', id, { delivered: false, sent: true, status: 'sent' }, params).subscribe(
            (result: any) => {
                console.log(result);
                swal({
                    title: 'Notificado!',
                    text: 'La empresa ha sido notificada del envio.',
                    type: 'success',
                    confirmButtonClass: 'btn ',

                    confirmButtonColor: '#000'
                });
                this.selectedQuotation = null;

                this._router.navigateByUrl('/ventasenviadas', { skipLocationChange: true }).then(() =>
                    this._router.navigate(['/aceptada/']));
            },
            error => {
                if (error != null) {


                }
            }
        );

    }

    saveChat(quotation, chatId, id?) {
        //this.spinner.show();
        let companyId = localStorage.getItem('$LoopBack$currentCompanyId');


        let chat = {
            sellerId: quotation.purchasesOrders[this.indexPurchase].sellerUserId,
            buyerId: quotation.userId,
            requestId: quotation.purchasesOrders[0].requestId,
            active: true,
            channelUrl: chatId,
            purchaseOrderId: quotation.purchasesOrders[this.indexPurchase].id
        };
        // let companyId = localStorage.getItem('$LoopBack$currentCompanyId');

        let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
        let params = {
            access_token: tokenId
        }
        console.log(chat, 'CHAT A GUARDAR')
        this._apiService.addDataObject('Chats', chat).subscribe(
            (result: any) => {
                console.log(result);
                setTimeout(() => {


                }, 500);

            },
            error => {
                if (error != null) {
                    setTimeout(() => {


                    }, 250);

                    console.log(error);
                }
            }
        );

    }

    ngOnInit() {

        //setTimeout(() => {
        console.log(this.ventas)
        console.log(this.selectedQuotation);
        console.log(this.indexPurchase)

        // }, 500);
        this.spinner.show();

        this.sb = new SendBird({ 'appId': '14D7BB96-A65D-479E-80A9-573085F9358E' });
        this.ChannelHandler = new this.sb.ChannelHandler();

        let uuid = UUID.UUID();
        let userId = localStorage.getItem('$LoopBack$currentUserId');
        console.log('Chat Component Init');

        this.sb.connect(userId, (success, error) => {
            if (success) {
                console.log(success);
                // let asd = new SendBird({ 'appId': '14D7BB96-A65D-479E-80A9-573085F9358E' });
                // let ChannelHandler = new asd.ChannelHandler();
                //
                // ChannelHandler.onMessageReceived = function (channel, message) {
                //   console.log(message, 'aquiii mensajeeeee');
                //   console.log(channel);
                //  }; // Received a chat message.


                /* ChannelHandler.onMessageReceived((channel, message) => {
                 console.log(message);
                 console.log(channel)
                 console.log('MENSAJE RECIBIDO ALV')
                 }); */

                this.createChat(this.selectedQuotation);


            } else {
                console.log(error);

            }
        });

    }


}
