import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()

export class CardlistService {
	//public listView :Boolean = false;
	view=false
	private listView: BehaviorSubject<boolean>;

	constructor() {
		this.listView = new BehaviorSubject<boolean>(this.view);
		//this.getView();
		//this.setView(this.listView);
	}
	public getView(): Observable<boolean> {
		return this.listView.asObservable();
	}
	public setView(newValue: boolean): void {
		//console.log(newValue)
		this.view=newValue;
		//console.log(this.view)
		this.listView.next(this.view);
	}

		getView2():Observable<Boolean>{
			return  new Observable(observer =>{
			
			let asd =setInterval( ()=>{
					observer.next(this.view)
				},200);
			
	
			
		});
	}
	/* 	setView(listView :Boolean){
			console.log(listView)
			this.listView = listView;
			console.log(this.listView)
			localStorage.setItem("listView", listView.toString());
		} */
}