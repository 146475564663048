import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/apiService';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2'
import * as SendBird from 'SendBird';

import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.css'],
  providers: [ApiService]

})
export class RecoveryComponent implements OnInit {

  public viewLogin = true;
  loginForm: UntypedFormGroup;
  preSignInForm: UntypedFormGroup;
  sb: any;
  channel: any;
  public credentials = {
    email: "",
    password: ""
  };
  public preSignIn = {
    name: "",
    fiscalName: "",
    rfc: "",
    phone: "",
    email: ""
  };
  public usuario = {
    personalInfo: {
      name: '',
      firstName: '',
      lastName: ''
    },
    companyId: '',
    email: '',

    role: '',
    ventas: false,
    compras: false,
    pushTokens: []
  };

  isSubscribed: any;
  constructor(private _apiservice: ApiService, private _router: Router, private spinner: NgxSpinnerService) {
    this.preSignInForm = new UntypedFormGroup({


      //'name': new FormControl(''),
      'name': new UntypedFormControl('', Validators.required),
      'fiscalName': new UntypedFormControl('', Validators.required),

      'pre_email': new UntypedFormControl('', [Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$")]),
      'phone': new UntypedFormControl('', Validators.required),
      'role': new UntypedFormControl("", Validators.required),
      'rfc': new UntypedFormControl("", Validators.required)
    });
    this.loginForm = new UntypedFormGroup({

      'email': new UntypedFormControl('', [Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$")]),
      'code': new UntypedFormControl('', [Validators.required, Validators.minLength(4)]),

      'password': new UntypedFormControl('', [Validators.required, Validators.minLength(4)])

    });
  }
  changeView() {
    this.viewLogin = !this.viewLogin;
    if (!this.viewLogin) {
      this.preSignInForm.reset();

    }
  }
  login() {
    this.spinner.show();
    this.credentials.email = this.loginForm.value.email;
    this.credentials.password = this.loginForm.value.password;

    console.log(this.credentials, 'credenciales');
    let filter = {
      filter: {
        where: {
          id: "5ade0eff9b235a7edf66e6ef"
        }
      },
      access_token: ""
    };
    this._apiservice.loginUser('AppUsers', 'login', this.credentials).subscribe(
      (result: any) => {

        console.log(result);
        localStorage.setItem("$LoopBack$accessTokenId", result.id);
        localStorage.setItem("$LoopBack$currentUserId", result.userId);

        filter.access_token = result.id;
        this.getUserInfo('AppUsers', result.userId, filter);
        this.sb = new SendBird({ 'appId': '14D7BB96-A65D-479E-80A9-573085F9358E' });
        //let uuid = UUID.UUID();
        let userId = localStorage.getItem('$LoopBack$currentUserId');
        console.log('Chat Component Init');

        this.sb.connect(userId, (success, error) => {
          if (success) {
            console.log(success);



          } else {
            console.log(error);

          }
        });
        var OneSignal = window['OneSignal'] || [];
        console.log("Init OneSignal");
        OneSignal.push(["init", {
          appId: "bbe79970-04cf-4173-aa8f-662a9236bc06",
          autoRegister: false,
          allowLocalhostAsSecureOrigin: true,
          notifyButton: {
            enable: false
          }

        }]);
        OneSignal.push(["setSubscription", true]);

        console.log('OneSignal Initialized');
        OneSignal.isPushNotificationsEnabled(function (isEnabled) {
          if (isEnabled)
            console.log("Push notifications are enabled!");
          else
            console.log("Push notifications are not enabled yet.");
        });

        // Occurs when the user's subscription changes to a new value.
        OneSignal.on('subscriptionChange', (isSubscribed) => {
          if (isSubscribed) {
            this.isSubscribed = true;
            OneSignal.getUserId().then((userId) => {
              console.log("User ID is", userId);
              if (userId) {
                this.usuario.pushTokens = [userId];
                this.editUser(result.userId);
              }
            });
          } else {
            this.isSubscribed = false;
          }
          console.log("The user's subscription state is now:", isSubscribed);

        });



      },
      error => {
        this.spinner.hide();
        swal({
          title: "¡Error!",
          text: "Por favor revisa tus datos de acceso",
          type: "error",
          confirmButtonClass: 'btn ',

          confirmButtonColor: '#000'
        });
        // this.errorMessage = <any>error;
        // if (this.errorMessage != null) {
        //     console.log(this.errorMessage);
        //
        /*       swal(
                  {
                      title: 'Error!',
                      text: 'Error al Guardar.',
                      type: 'error',
                      confirmButtonClass: 'btn ',
 
                      confirmButtonColor: '#000'
                  }
              ) */
        // }
        console.log(error);
      }
    );
  }
  editUser(id: any) {

    console.log(id)
    /* 
            this.usuario.compras = this.newUserForm.value.compras;
            this.usuario.ventas = this.newUserForm.value.ventas;
            this.usuario.role = this.newUserForm.value.role;
            this.usuario.email = this.newUserForm.value.email;
            this.usuario.ventas = this.ventasCheck;
            this.usuario.compras = this.comprasCheck;
            this.usuario.companyId = this.companyId;
            this.usuario.personalInfo.name = this.usuario.personalInfo.fistName + ' ' + this.usuario.personalInfo.lastName */
    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
    let params = {
      access_token: tokenId
    }
    console.log(params)
    this._apiservice.editDataObject('AppUsers', id, this.usuario, params).subscribe(
      (result: any) => {
        console.log(result);

      },
      error => {
        if (error != null) {


        }
      }

    );

  }
  signIn() {
    this.spinner.show();

    this.preSignIn.name = this.preSignInForm.value.name;
    this.preSignIn.fiscalName = this.preSignInForm.value.fiscalName;
    this.preSignIn.phone = this.preSignInForm.value.phone;
    this.preSignIn.rfc = this.preSignInForm.value.rfc;
    this.preSignIn.email = this.preSignInForm.value.pre_email;
    console.log(this.preSignIn, 'Pre registro');
    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
    let variables = {
      data: this.preSignIn
    };
    let params = {
      access_token: tokenId
    };
    console.log(params);
    this._apiservice.addDataObjectUnAuth(variables, 'Companies/preSignIn').subscribe(
      (result: any) => {
        console.log(result);
        this.spinner.hide();
        swal(
          {
            title: 'Pre Registro Exitoso!',
            text: 'Te contactaremos pronto.',
            type: 'success',
            confirmButtonClass: 'btn ',

            confirmButtonColor: '#000'
          })
        this.viewLogin = true;



      },
      error => {
        this.spinner.hide();
        swal({
          title: "¡Error!",
          text: "Por favor revisa tus datos de acceso",
          type: "error",
          confirmButtonClass: 'btn ',

          confirmButtonColor: '#000'
        });

        console.log(error);
      }
    );
  }
  recoverPassword() {
    let mail = this.loginForm.value.email;
    let pw = this.loginForm.value.password;
    let cd = this.loginForm.value.code;

    let data = {
      email: mail,
      password: pw,
      code: cd
    };
    if (mail) {
      let url = 'RecoveryCodes/validateCode';

      console.log(url)
      this._apiservice.addDataObjectCustom(url, data).subscribe(
        (result: any) => {

          console.log(result);
          console.log(result);
          response:
/* accessToken:
created: "2020-12-10T01:59:36.861Z"
id: "LbXfU9mR1LlPkjv0WZ15UQd3uNdG0MHsGWTh1d23saPECYzzEXrSFPtIZMd3WMEa"
ttl: 1209600
userId: "5b328652153cb8798362f50f"
__proto__: Object
success: true
user:
buyer: false
canBuy: true
canSell: true
companyId: "5b327dcc153cb8798362f50e"
createdAt: "2018-06-26T18:30:42.172Z"
email: "gerardo@aleate.com"
id: "5b328652153cb8798362f50f"
isActive: false
personalInfo: {name: "Gerardo Almeida", firstName: "Gerardo", lastName: "Almeida"}
pushTokens: []
role: "Director"
seller: false
_configuration: null */
          localStorage.setItem("$LoopBack$accessTokenId", result.response.accessToken.id);
          localStorage.setItem("$LoopBack$currentUserId", result.response.user.id);
          let filter = {
            filter: {
              where: {
                id: "5ade0eff9b235a7edf66e6ef"
              }
            },
            access_token: ""
          };
          filter.access_token = result.response.accessToken.id;
          this.getUserInfo('AppUsers', result.response.user.id, filter);
          this.sb = new SendBird({ 'appId': '14D7BB96-A65D-479E-80A9-573085F9358E' });
          //let uuid = UUID.UUID();
          let userId = localStorage.getItem('$LoopBack$currentUserId');
          console.log('Chat Component Init');

          this.sb.connect(userId, (success, error) => {
            if (success) {
              console.log(success);



            } else {
              console.log(error);

            }
          });
          var OneSignal = window['OneSignal'] || [];
          console.log("Init OneSignal");
          OneSignal.push(["init", {
            appId: "bbe79970-04cf-4173-aa8f-662a9236bc06",
            autoRegister: false,
            allowLocalhostAsSecureOrigin: true,
            notifyButton: {
              enable: false
            }

          }]);
          OneSignal.push(["setSubscription", true]);

          console.log('OneSignal Initialized');
          OneSignal.isPushNotificationsEnabled(function (isEnabled) {
            if (isEnabled)
              console.log("Push notifications are enabled!");
            else
              console.log("Push notifications are not enabled yet.");
          });

          // Occurs when the user's subscription changes to a new value.
          OneSignal.on('subscriptionChange', (isSubscribed) => {
            if (isSubscribed) {
              this.isSubscribed = true;
              OneSignal.getUserId().then((userId) => {
                console.log("User ID is", userId);
                if (userId) {
                  this.usuario.pushTokens = [userId];
                  this.editUser(result.userId);
                }
              });
            } else {
              this.isSubscribed = false;
            }
            console.log("The user's subscription state is now:", isSubscribed);

          });


        },
        error => {
          console.log(error);

        }

      );
      /*  swal(
           {
               title: 'Alerta!',
               text: 'Se ha enviado a tu correo un código de recuperacion.',
               type: 'info',
               confirmButtonClass: 'btn ',
 
               confirmButtonColor: '#000'
           })
       this._router.navigate(['/recovery/']); */
    } else {
      swal(
        {
          title: 'Alerta!',
          text: 'El correo es obligatorio para recuperar contraseña.',
          type: 'info',
          confirmButtonClass: 'btn ',

          confirmButtonColor: '#000'
        })
    }


  }
  isAuthenticated() {
    let authToken = localStorage.getItem('$LoopBack$accessTokenId');
    console.log(authToken);
    if (authToken) {
      console.log('sihay token');
      this._router.navigate(['/inicio/']);


    } else {
      console.log('no hay  token')




    }
  }
  ngOnInit() {
    this.isAuthenticated();
    console.log('entor login');



  }

  getCompanyInfo(type, companyId) {
    let promise = new Promise((resolve, reject) => {
      // Do some async stuff
      let tokenId = localStorage.getItem('$LoopBack$accessTokenId');

      let params = {
        filter: { include: ['state', 'country', 'branches'] },
        access_token: tokenId
      };
      this._apiservice.getDataById(type, companyId, params).subscribe(
        (result: any) => {
          console.log(result)

          if (result.profileUrl) {
            localStorage.setItem("$LoopBack$currentUserImage", result.profileUrl);
          }
          if (result.branches.length == 0) {
            reject(false);

            /*  swal(
                 {
                     title: 'Alerta!',
                     text: 'Tu empresa no tiene sucursales, crea tus sucursales para poder cotizar !.',
                     type: 'info',
                     confirmButtonClass: 'btn ',
 
                     confirmButtonColor: '#000'
                 }) */
            //  this._router.navigate(['/empresa']);

          } else {
            resolve(true);

          }





        },
        error => {
          if (error != null) {
            console.log(error);
          }
        }

      );

    });
    return promise;

  }
  getUserInfo(type, id, filter) {
    console.log('getting user info')

    console.log(id)
    this._apiservice.getDataById(type, id, filter).subscribe(
      (result: any) => {
        console.log(result);
        console.log(result.role);
        this.spinner.hide()
        this.usuario.compras = result.compras;
        this.usuario.ventas = result.ventas;
        this.usuario.personalInfo.firstName = result.personalInfo.firstName;
        this.usuario.personalInfo.lastName = result.personalInfo.lastName;
        this.usuario.role = result.role;
        this.usuario.email = result.email;
        this.usuario.compras = result.compras;
        this.usuario.companyId = result.companyId;
        this.usuario.personalInfo.name = this.usuario.personalInfo.firstName + ' ' + this.usuario.personalInfo.lastName;

        localStorage.setItem("$LoopBack$currentUserRole", result.role);
        localStorage.setItem("$LoopBack$currentCompanyId", result.companyId);
        localStorage.setItem("isSales", result.seller);
        localStorage.setItem("isPurchases", result.buyer);
        localStorage.setItem("userBranchId", result.branchId);
        localStorage.setItem("userName", this.usuario.personalInfo.name);
        this.getCompanyInfo('Companies', result.companyId).then((resultCompany) => {
          console.log(resultCompany);
          if (resultCompany) {

          } else {
            //no tiene branches el wei
            swal(
              {
                title: 'Alerta!',
                text: 'Tu empresa no tiene sucursales, crea tus sucursales para poder cotizar !.',
                type: 'info',
                confirmButtonClass: 'btn ',

                confirmButtonColor: '#000'
              })
            this._router.navigate(['/empresa']);
          }
        });
        if (result.seller === true && result.buyer === false) {
          this._router.navigate(['/inicio']);
        } else if (result.seller === false && result.buyer === true) {
          this._router.navigate(['/compras']);

        } else {
          this._router.navigate(['/inicio']);

        }
        if (result.role == 'Director') {
          this._router.navigate(['/inicio']);

        } else if (result.role == 'Compras') {

        } else if (result.role == 'Ventas') {

        } else if (result.role == 'DirCompras') {

        } else if (result.role == 'DirVentas') {

        }







      },
      error => {
        this.spinner.hide()
        // this.errorMessage = <any>error;
        // if (this.errorMessage != null) {
        console.log(error);
        //
        // }
      }
    );
  }


}
