import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/apiService';

@Component({
  selector: 'app-rejected',
  templateUrl: './rejected.component.html',
  styleUrls: ['./rejected.component.css'],
  providers: [ApiService]
})
export class RejectedComponent implements OnInit {
  @Input() request: any;

  public selectedRequest: any;
  public totalQuoted = 0;
  public creditScore;
  public deliveryScore;
  public priceScore;
  public creditPlace = 0;
  public deliveryPlace = 0;
  public pricePlace = 0;
  constructor(private _apiService: ApiService) { }

  getReason(quotation) {
    let credit = {
      id: 1,
      value: quotation.creditScore
    }
    let win = {
      id: 2,
      value: (quotation.wins * 100 / quotation.quotedProducts.length).toFixed(0)
    }
    let deli = {
      id: 3,
      value: quotation.deliveryScore
    }
    let array = [credit, win, deli];
    array.sort((a, b) => {
      return a.value - b.value
    })
    /*  var lowest = array[0].value;
     var tmp;
     for (var i=array.length-1; i>=0; i--) {
         tmp = array[i].value;
         if (tmp < lowest) lowest = tmp;
     }
     console.log(tmp); */
    let valor = '';
    switch (array[0].id) {
      case 1: {
        valor = 'Peor credito';
        break;
      }
      case 2: {
        valor = 'Entrega tardia';
        break;
      }
      case 3: {
        valor = 'Alto precio';
        break;
      }
      default: {
        valor = 'rechazada';
        break;
      }

    }
    return valor;

  }
  checkIfQuoted(requestId) {
    let companyId = localStorage.getItem('$LoopBack$currentCompanyId');

    let filter = {
      filter: {
        where: {
          companyId: companyId


        }
      }

    };
    //Requests/559/quotations/
    this._apiService.getDataObjectsFilter('Requests/' + requestId + '/quotations/count', filter).subscribe(
      (result: any)=> {
        console.log('Verified if Count');

        console.log(result);

        this.totalQuoted = result.count;
      
        this.creditPlace = this.getPlaceQuotation(this.creditScore);
        this.pricePlace = this.getPlaceQuotation(this.priceScore);
        this.deliveryPlace = this.getPlaceQuotation(this.deliveryScore);

      },
      error => {
        if (error != null) {
          console.log(error);
        }
      }
    );
  }
  ngOnInit() {
    this.selectedRequest = this.request;
    this.checkIfQuoted(this.selectedRequest.request.id)

    this.creditScore = this.request.creditScore;
    this.deliveryScore = this.request.deliveryScore;
    let score = (this.request.wins * 100 / this.request.quotedProducts.length).toFixed(0);
    console.log(this.request.wins)
    console.log(this.request.quotedProducts.length)
    console.log(score)
    this.priceScore = (this.request.wins * 100 / this.request.quotedProducts.length).toFixed(0);
    console.log(this.creditScore)
    console.log(this.deliveryScore)
    console.log(this.priceScore)
   

    /*
    100 / (this.totalQuoted - 1)) * (Math.abs(indexQuotation - (this.totalQuoted - 1))
    */


  }

  getPlaceQuotation(score) {
    console.log(score)

    if (score === 0) {
      console.log('es cero')
      return this.totalQuoted;

    } else {
      let a = -score
       console.log(a * (this.totalQuoted - 1) / 100 + this.totalQuoted)
      return Number((a * (this.totalQuoted - 1) / 100 + this.totalQuoted).toFixed(0));
    }

  }

}
