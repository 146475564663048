import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from "rxjs";
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';
import { ApiService } from '../../services/apiService';
import { QueryFactory } from '../../tableQueries/queryFactory';

@Component({
  selector: 'app-ultimate-select',
  templateUrl: './ultimate-select.component.html',
  styleUrls: ['./ultimate-select.component.css']
})
export class UltimateSelectComponent implements OnInit, AfterViewInit, OnDestroy {

  // ================================ COMPONENT CONFIG AND VARIABLES ================================ //

  // --------------------------- //
  // Local variables declaration //
  // --------------------------- //
  /* config component input */

  public selectedTemp;
  @Input()
  set config(config) {
    this.configObject = config;
  }


  @Input()
  set prevValues(values) {
    console.log(values);
    this.selectedObjects = values;
    if (this.configObject) {
      console.log('entoneontoetet');
      if (!this.configObject.returnItself) {
        this.filterContent.emit(this.processEmitableObject());
      } else {
        this.filterContent.emit(this.selectedObjects);
      }
      this.loadSearch(this.search.value, true);
    }

  }

  @Output() filterContent = new EventEmitter();

  /* manage component */
  private onDestroy = new Subject<void>();
  public configObject: any;

  /* manage data */
  public filteredObjects: any[] = [];
  public selectedObjects: any[] = [];
  public searchPage = 0;
  public searchCount = 0;

  public isBigSize = window.innerWidth > 768;
  public loading = false;
  companySubCategories: any = [];
  categoriesIds: any = [];

  public itemsPerParent = 5;
  public tempId;
  public manageReturn;

  // -------------------------------------- //
  // Form inputs & validations declaration  //
  // -------------------------------------- //
  public search = new UntypedFormControl({ value: '', disabled: false });

  // --------------------- //
  // Component constructor //
  // --------------------- //
  constructor(
    public queryFactory: QueryFactory,

    private apiService: ApiService,
  ) { }

  // ------------------ //
  // On view init cycle //
  // ------------------ //
  ngOnInit() {
    window.addEventListener('resize', () => {
      this.isBigSize = window.innerWidth > 768;
    });
  }

  // --------------------- //
  // After view init cycle //
  // --------------------- //
  ngAfterViewInit() {

    /* on search change (managing 250 ms) */
    this.search.valueChanges.pipe(
      takeUntil(this.onDestroy),
      debounceTime(250),
      startWith('')
    ).subscribe((dataSearch) => {
      this.loadSearch(dataSearch, true);
    });
    this.getSubCategoriesCompany();

  }

  // ================================ MANAGE DATA METHODS ================================ //

  // ----------- //
  // LOAD SEARCH //
  // ----------- //
  loadSearch(dataSearch: string, count: boolean) {
    let companyIde = localStorage.getItem('$LoopBack$currentCompanyId');


    let textOnly = dataSearch;
    let searchText = dataSearch.toUpperCase();
    let lowerText = dataSearch.toLowerCase();
    var capText = this.toTitleCase(dataSearch);
    // Runs on every search
    let filters = {
        where: {
          and: [{},
          {
            or: [
              { id: "5c6d8d7c9e7b2301aba4b6d2" },
              { id: "5dbc653415d8e9eef62546f8" },
              { id: "5e97545ec7b97d20205a41c6" },
             /*  { name: { like: searchText } },
              { name: { like: lowerText } },
              { name: { like: capText } }, */
            ]
          }
          ],

        },
        order: "name asc"

      };
    this.apiService.getDataObjectsFilterCompany('Categories', filters).subscribe(
      (result: any) => {
        if (result) {
        console.log(result)
          /*  result.forEach(sub => {
             sub['checked'] = false;
           }); */
        };
        console.log(result);

        this.filteredObjects = result;

      },
      error => {
        if (error != null) {
          console.log(error);
        }
      }

    );
  }
  toTitleCase(str) {
    if (str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    } else {
    }

  }
  loadCategories() {
    let filters = {
      filter: {
        where: {
          and: [{},
          {
            or: [
              { id: "5c6d8d7c9e7b2301aba4b6d2" },
              { id: "5dbc653415d8e9eef62546f8" },
              { id: "5e97545ec7b97d20205a41c6" },
            ]
          }
          ],

        },
        order: "name asc"

      },
      access_token: localStorage.getItem('$LoopBack$accessTokenId')
    };
    this.apiService.getDataObjectsFilterCompany('Categories', filters).subscribe(
      (result: any) => {
        console.log(result)
      },
      error => {
        if (error != null) {
          console.log(error);
        }
      }

    );
  }
  getSubCategoriesCompany() {
    let companyId = localStorage.getItem('$LoopBack$currentCompanyId');

    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');

    let params = {
      filter: {},
      access_token: tokenId
    };
    this.apiService.getDataById('Companies', companyId, params).subscribe(
      (result: any) => {
        console.log(result);
        this.categoriesIds = result.categoriesIds;

       /*  result.subCategoriesIds.forEach(element => {


          this.apiService.getDataById('SubCategories', element, {}).subscribe(
            (result: any) => {
              this.companySubCategories.push(result);




            },
            error => {
              if (error != null) {
                console.log(error);
              }
            }

          );
        }); */


        this.filteredObjects.forEach((catVisible) => {
          if (this.categoriesIds.some(categoryId => categoryId === catVisible.id)) {
            catVisible['checked'] = true;
            console.log('coincidencia categoria')
          }
        });

      },
      error => {
        if (error != null) {
          console.log(error);
        }
      }

    );
  }
  viewSubCategoriesFromCategory(cat, checked) {
    console.log(cat);
    let filters = {
        where: {
          and: [
          {
            or: [
              { categoryId: cat },
            
            ]
          }
          ],

        },
        order: "name asc"

    };
   
    console.log(filters)
    this.apiService.getDataObjectsFilterCompany('SubCategories', filters).subscribe(
      (subCategories: any) => {
        console.log('Subcategorias en categoria')
        console.log(subCategories);
        console.log(this.companySubCategories)
        if (subCategories) {
          subCategories.forEach(sub => {
            sub['checked'] = false;
            if (!this.selectedObjects.some(selected => selected.id === sub.id)) {
              //checking if already have any subcategory of the category added
              if (this.companySubCategories.some(subCategorieInCompany => subCategorieInCompany.id === sub.id)) {
                sub['checked'] = true;
                console.log('coincidencia')
              }
              this.selectedObjects.push(sub);

            }

            if (checked) {
              if (!this.companySubCategories.some(subCategorieInCompany => subCategorieInCompany === sub.id)) {
                this.companySubCategories.push(sub.id)
              }
            }

          });
        }
      },
      error => {
        if (error != null) {
          console.log(error);
        }
      }

    );
  }
  getSubCategoriesFromCategory(categoryId, checked) {
    console.log(categoryId);
    let filter = {
      filter: {
        where: {
          categoryId: categoryId
        },
        order: "name asc"
      }
    };
    console.log(filter)
    this.apiService.getDataObjectsFilterCompany('SubCategories', filter).subscribe(
      (subCategories: any) => {
        console.log('Subcategorias en categoria')
        console.log(subCategories);
        if (subCategories) {
          subCategories.forEach(sub => {
            sub['checked'] = checked;
            if (!this.selectedObjects.some(selected => selected.id === sub.id)) {
              //checking if already have any subcategory of the category added
              if (this.companySubCategories.some(subCategorieInCompany => subCategorieInCompany.id === sub.id)) {

                sub['checked'] = true;

              }
              this.selectedObjects.push(sub);


            }

            if (checked) {
              console.log(this.companySubCategories);

              if (!this.companySubCategories.some(subCategorieInCompany => subCategorieInCompany.id === sub.id)) {
                this.companySubCategories.push(sub)
              }
            }

          });
        }
      },
      error => {
        if (error != null) {
          console.log(error);
        }
      }

    );
  }
  public removeObject(selected: any) {
    clearTimeout(this.manageReturn);
    const removeIndex = this.selectedObjects.findIndex(selObject => selObject.id === selected.id);
    this.filteredObjects.unshift(this.selectedObjects.splice(removeIndex, 1)[0]);
    this.manageReturn = setTimeout(() => {
      if (!this.configObject.returnItself) {
        let dataExport = {
          cat: this.categoriesIds,
          sub: this.processEmitableObjectId(this.companySubCategories)
        }
        //this.filterContent.emit(this.processEmitableObjectId(this.companySubCategories));
        this.filterContent.emit(dataExport)


      } else {

        let dataExport = {
          cat: this.categoriesIds,
          sub: this.processEmitableObjectId(this.companySubCategories)
        }
        //this.filterContent.emit(this.processEmitableObjectId(this.companySubCategories));
        this.filterContent.emit(dataExport)
      }
    }, 250);
  }
  public removeObjectSubCategory(selected: any, event) {
    console.log(selected)
    clearTimeout(this.manageReturn);
    const removeIndex = this.selectedObjects.findIndex(selObject => selObject.id === selected.id);
    const removeIndex2 = this.companySubCategories.findIndex(id => id.id === selected.id);
    if (event.checked) {
      this.selectedObjects[removeIndex]['checked'] = true;
      if (!this.companySubCategories.some(subCategorieInCompany => subCategorieInCompany.id === selected.id)) {
        this.companySubCategories.push(selected)
      }
      var asd = 0;
      this.selectedObjects.forEach((check) => {
        if (check.checked === true) {
          asd++;
        }
      })
      console.log(asd);
      if (asd === this.selectedObjects.length) {
        this.apiService.getDataById('categories', selected.categoryId, {}).subscribe(
          (result: any) => {
            console.log(result);
            const categoryIndex = this.filteredObjects.findIndex(category => category.id === selected.categoryId);
            this.filteredObjects[categoryIndex]['checked'] = true;
            if (!this.categoriesIds.some(categoryId => categoryId === selected.categoryId)) {
              this.categoriesIds.push(selected.categoryId)
            }




          },
          error => {
            if (error != null) {
              console.log(error);
            }
          });
      }
      this.manageReturn = setTimeout(() => {
        if (!this.configObject.returnItself) {
          //old
          let dataExport = {
            cat: this.categoriesIds,
            sub: this.processEmitableObjectId(this.companySubCategories)
          }
          this.filterContent.emit(dataExport)
        } else {
          let dataExport = {
            cat: this.categoriesIds,
            sub: this.processEmitableObjectId(this.companySubCategories)
          }
          this.filterContent.emit(dataExport)
        }
      }, 250);

    } else {
      this.selectedObjects[removeIndex]['checked'] = false;
      this.companySubCategories.splice(removeIndex2, 1);
      var asd = 0;
      this.selectedObjects.forEach((check) => {
        if (check.checked === true) {
          asd++;
        }
      })
      console.log(asd);
      if (asd !== this.selectedObjects.length) {
        this.apiService.getDataById('categories', selected.categoryId, {}).subscribe(
          (result: any) => {
            console.log(result);
            const categoryIndex = this.filteredObjects.findIndex(category => category.id === selected.categoryId);
            this.filteredObjects[categoryIndex]['checked'] = false;
            const categoryIndexCompany = this.categoriesIds.findIndex(category => category === selected.categoryId);
            this.categoriesIds.splice(categoryIndexCompany, 1);
            console.log(this.categoriesIds)




          },
          error => {
            if (error != null) {
              console.log(error);
            }
          });

      }
      let newObjects = this.selectedObjects.filter(obj => obj.checked === true);
      this.manageReturn = setTimeout(() => {
        if (!this.configObject.returnItself) {
          //old
          let dataExport = {
            cat: this.categoriesIds,
            sub: this.processEmitableObjectId(this.companySubCategories)
          }
          //this.filterContent.emit(this.processEmitableObjectId(this.companySubCategories));
          this.filterContent.emit(dataExport)
        } else {
          let dataExport = {
            cat: this.categoriesIds,
            sub: this.processEmitableObjectId(this.companySubCategories)
          }
          //this.filterContent.emit(this.processEmitableObjectId(this.companySubCategories));
          this.filterContent.emit(dataExport)
        }
      }, 250);
    }
  }


  public selectCategory(filtered: any, event) {

    console.log(this.filteredObjects)
    const temp = this.filteredObjects.findIndex(selObject => selObject.id === filtered.id);

    clearTimeout(this.manageReturn);
    if (event.checked) {
      console.log('select')

      this.selectedObjects = [];

      if (this.filteredObjects[temp]) {
        this.getSubCategoriesFromCategory(this.filteredObjects[temp].id, true);
        //MANAGING CATEGORIES
        const categoryIndex = this.filteredObjects.findIndex(category => category.id === filtered.id);
        this.filteredObjects[categoryIndex]['checked'] = true;
        if (!this.categoriesIds.some(categoryId => categoryId === filtered.id)) {
          this.categoriesIds.push(filtered.id)
        }


      }
    } else {
      //unselect category
      console.log('unselect')

      this.companySubCategories = this.companySubCategories.filter(obj => obj.categoryId !== this.filteredObjects[temp].id);

      const removeIndex = this.filteredObjects.findIndex(selObject => selObject.id === filtered.id);

      this.filteredObjects[removeIndex]['checked'] = false;


      this.selectedObjects.forEach((subcategory, index) => {
        if (this.selectedObjects[index].categoryId === filtered.id) {
          this.selectedObjects[index]['checked'] = false;

        }
      });
      if (filtered.id !== this.tempId) {
        this.selectedObjects = [];
        this.getSubCategoriesFromCategory(this.filteredObjects[temp].id, false);

      }




      const categoryIndex = this.filteredObjects.findIndex(category => category.id === filtered.id);
      this.filteredObjects[categoryIndex]['checked'] = false;
      const categoryIndexCompany = this.categoriesIds.findIndex(category => category === filtered.id);
      this.categoriesIds.splice(categoryIndexCompany, 1);

      setTimeout(() => {
        if (this.filteredObjects[temp]['seen']) {

        } else {
          this.selectedObjects = [];

        }

      }, 300)
      console.log(this.selectedObjects)

    }

    if (this.filteredObjects[temp]['seen']) {
      if (this.filteredObjects[temp]['seen'] === 'green') {
        if (filtered.id !== this.tempId) {
          console.log('todo gris')
          this.filteredObjects.forEach((filt, index) => {
            this.filteredObjects[index]['seen'] = '#67757c';
          });
          setTimeout(() => {
            this.filteredObjects[temp]['seen'] = 'green';

          }, 200);
        }
      } else {
        console.log(filtered.id + '------' + this.tempId)

        if (filtered.id !== this.tempId) {
          console.log('todo gris')
          this.filteredObjects.forEach((filt, index) => {
            this.filteredObjects[index]['seen'] = '#67757c';
          });
        }



        setTimeout(() => {
          this.filteredObjects[temp]['seen'] = 'green';

        }, 200);

      }
    } else {
      this.filteredObjects.forEach((filt, index) => {

        // this.filteredObjects[index]['seen'] = '#67757c';

      });

      setTimeout(() => {

        this.filteredObjects[temp]['seen'] = 'green';
        if (this.filteredObjects[temp]) {

        }
      });
      this.tempId = filtered.id;


    }

    this.manageReturn = setTimeout(() => {
      if (!this.configObject.returnItself) {
        let dataExport = {
          cat: this.categoriesIds,
          sub: this.processEmitableObjectId(this.companySubCategories)
        }
        //this.filterContent.emit(this.processEmitableObjectId(this.companySubCategories));
        this.filterContent.emit(dataExport)
      } else {
      }
    }, 250);
  }
  public viewSubcategoriesInCategory(filtered: any) {

    clearTimeout(this.manageReturn);

    const temp = this.filteredObjects.findIndex(selObject => selObject.id === filtered.id);
    if (this.filteredObjects[temp]['seen']) {
      if (this.filteredObjects[temp]['seen'] === 'green') {
        this.filteredObjects[temp]['seen'] = '#67757c';
        this.selectedObjects = this.selectedObjects.filter(obj => obj.categoryId !== filtered.id);

      } else {
        this.filteredObjects.forEach((filt, index) => {

          this.filteredObjects[index]['seen'] = '#67757c';

        });

        setTimeout(() => {

          this.filteredObjects[temp]['seen'] = 'green';
          if (this.filteredObjects[temp]) {
            this.selectedObjects = [];

            this.viewSubCategoriesFromCategory(this.filteredObjects[temp].id, false)
          }
        });

      }
    } else {
      this.filteredObjects.forEach((filt, index) => {

        this.filteredObjects[index]['seen'] = '#67757c';

      });

      setTimeout(() => {

        this.filteredObjects[temp]['seen'] = 'green';
        if (this.filteredObjects[temp]) {
          this.selectedObjects = [];

          this.viewSubCategoriesFromCategory(this.filteredObjects[temp].id, false)
        }
      });

    }



    this.manageReturn = setTimeout(() => {

    }, 250);
  }
  public processEmitableObject() {
    const tmpEmitable: any[] = [];
    const property = this.configObject.returnProperty;
    this.selectedObjects.forEach((data) => {
      tmpEmitable.push(data[property]);
    });
    return tmpEmitable;
  }
  public processEmitableObjectId(dataObjects) {
    const tmpEmitable: any[] = [];
    dataObjects.forEach((data) => {
      tmpEmitable.push(data['id']);
    });
    return tmpEmitable;
  }


  public onSearchScroll() {
    console.log('scroll detected');
    this.searchPage++;
    this.loadSearch(this.search.value, false);
    setTimeout(() => {
      //this.getSubCategoriesCompany();
      if (this.categoriesIds) {
        this.filteredObjects.forEach((catVisible) => {
          if (this.categoriesIds.some(categoryId => categoryId === catVisible.id)) {
            catVisible['checked'] = true;
            console.log('coincidencia categoria')
          }
        });
      }

    }, 200);
  }

  getNeqObject() {
    const neqObject: string[] = [];
    this.selectedObjects.forEach((object) => {
      neqObject.push(object.id);
    });
    return neqObject;
  }

  clearFilter() {
    this.loadSearch(this.search.value, true);
    this.selectedObjects = [];
    this.filterContent.emit(this.selectedObjects);
  }



  // ================================ DESTROY COMPONENT HOOK MANAGEMENT ================================ //

  // -------------------- //
  // On destroy lifecycle //
  // -------------------- //
  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.unsubscribe();
  }

}
