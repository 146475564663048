<div class="row" *ngFor="let compra of concretada.purchasesOrders;let i =index">
	<div class="padding-2">
		<div class=" cont_info zero">

			<div class="tab-content" id="pills-tabContent">
				<div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
					<!---->
					<div class="row background-white">
						<div class="row bg_blanco">
							<p class="text center strong padding-2">
								Nuestra meta en Ixcco es que tu empresa solo realice compras y ventas de manera inteligente, por lo que
								mejoramos al escuchar tu opinión contestando la siguente encuesta
							</p>

							<div class="col-1 col-md-4 text"></div>
							<div class="col-10 col-md-4 text center">
								<button *ngIf="!concretada.surveyChecked" class="zero center strong width white font-1 padding1" style="background:#000;border:solid transparent;"
								 data-toggle="modal" data-target="#ixccoEncuesta"> CONTESTA ENCUESTA </button>
							</div>
							<div class="col-1 col-md-4 text"></div>

						</div>

						<div class="col-12 col-md-4 text center">
							<!-- logito de garantia -->
							<div class="row">
								<div class="col-0 col-md-4 "></div>
								<div class="col-12 col-md-5 zero garantia garanty" style="background-image:url(assets/images/gallery/marcas/garantia.png);">
									<div class="galery-numbrer">{{compra.company.ratingIxcco}}</div>
									<div class="galery-text">RECOMENDACIÓN</div>
									<div class="galery-logo">IXCCO</div>
								</div>
								<div class="col-0 col-md-3 "></div>
							</div>
							<div class="row" style="padding-left:20px">
								Cotización para
								<strong *ngIf="concretada" style="    margin-left: 10px;margin-right: 10px;	">{{compra.sellProducts.length}}</strong>
								productos
							</div>
						</div>
						<div class="col-12 col-md-4 black margin1">
							<div class="row width padding-top1">
								<div class=" col-3 col-md-12 zero ">
									<img *ngIf="compra.company.profileUrl" class="logo-brand-img-2" [src]="compra.company.profileUrl">
									<img *ngIf="!compra.company.profileUrl" class="logo-brand-img-2" src="../../../assets/images/fakeCompany.png">
								</div>
								<div class=" col-9 col-md-12 zero">
									<div class="text center-left">
										{{compra.company.name}}</div>
									<div *ngIf="compra.company" class="text center-left">
										<rating *ngIf="!compra.company.rating"  [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating>
									</div>
									<div *ngIf="compra.company"  class="text center-left">
										<rating *ngIf="compra.company.rating" [(ngModel)]="compra.company.rating" [max]="max" [readonly]="isReadonly"></rating>
									</div>
									<div class="text center-left">{{compra.company.companyInfo.city}}, México</div>
								</div>

								
							</div>
						</div>
						<div class="col-12 col-md-4 text center margin1 padding-6">

							<div class="row zero margin1">
								<div class="col-1 col-md-1 zero right text ">
									<i class="fa fa-money pad5 text-bar" aria-hidden="true"></i>
								</div>
								<div class="col-9 col-md-9 zero left">
									<div class="zero">
										<div class="stat-1 stat-bar">
											<span class="stat-bar-rating" role="stat-bar" style="width:10%;"></span>
										</div>
									</div>
								</div>
								<div class="col-2 col-md-2 number-bar zero">
									<p class="zero left number-bar"></p>
								</div>
								<div class="col-2 col-md-2 text "></div>
								<div class=" col-10 col-md-10  zero">
									<p class="text price text1 weigh600 "> Precio total: {{compra.amount*1.16| currency:true:'1.0-2'}}</p>
								</div>
							</div>
							<div class="row zero margin1">
								<div class="col-1 col-md-1 zero right text ">
									<i class="fa fa-truck pad5 text-bar" aria-hidden="true"></i>
								</div>
								<div class="col-9 col-md-9 zero left">
									<div class="zero">
										<div class="stat-1 stat-bar">
											<span class="stat-bar-rating" role="stat-bar" style="width:20%;"></span>
										</div>
									</div>
								</div>
								<div class="col-2 col-md-2 number-bar zero">
									<p class="zero left number-bar"></p>
								</div>
								<div class="col-2 col-md-2 text "></div>
								<div class=" col-10 col-md-10  zero">
									<p class="text price"> </p>
								</div>
							</div>
							<div class="row zero margin1">
								<div class="col-1 col-md-1 zero right text ">
									<i class="fa fa-credit-card pad5 text-bar" aria-hidden="true"></i>
								</div>
								<div class="col-9 col-md-9 zero left">
									<div class="zero">
										<div class="stat-1 stat-bar">
											<span class="stat-bar-rating" role="stat-bar" style="width:40%;"></span>
										</div>
									</div>
								</div>
								<div class="col-2 col-md-2 number-bar zero">
									<p class="zero left number-bar"></p>
								</div>
								<div class="col-2 col-md-2 text "></div>
								<div class=" col-10 col-md-10  zero">
									<p *ngIf="concretada" class="text price weigh600 text1"> An &nbsp;{{compra.paymentOptions.anticipated}}%
										&nbsp; Co &nbsp;{{compra.paymentOptions.uponDelivery}}% &nbsp;Cr
										&nbsp;{{compra.paymentOptions.credit}}% &nbsp; por &nbsp; {{compra.paymentOptions.days}} días</p>
								</div>
							</div>

						</div>
					</div>
					<div class="row background-white"> &nbsp;</div>
					<div *ngFor="let product of compra.sellProducts">
						<div *ngIf="product" class="row background-white black padding-6 border-and-padding">
							<div *ngIf="product" class="col-4 col-md-1 text center zero">
								<div *ngIf="product.productUrl" class="container-button">
									<!-- 	<div class="container-button-text"> 2 </div>
										-->
									<i class="fa fa-arrow-circle-o-down zero" aria-hidden="true"></i>
								</div>
							</div>
							<div *ngIf="product" class="col-8 col-md-3 text left zeror">
								<p class="zero">Nombre del producto</p>
								<label class="zero strong ">{{product.name}}</label>
							</div>
							<div *ngIf="product" class="col-5 col-md-1 text left zeror">
								<p class="zero">Cantidad:</p>
								<label class=" strong zero">{{product.quantity}}</label>
							</div>

							<div *ngIf="product" class="col-5 col-md-1 text left zeror">
								<p class="zero">Unidad:</p>
								<label *ngIf="product.unit" class=" strong zero">{{product.unit.name}}</label>
							</div>
							<div *ngIf="product" class="col-5 col-md-2 text left zero">
								<p class="zero">Centro de costos:</p>
								<label class=" strong zero">{{concretada.projectName}}</label>
							</div>
							<div *ngIf="product" class="col-7 col-md-2 text left zero">
								<p class="zero">Precio unitario:</p>
								<label class=" strong zero">${{product.amount}}&nbsp;{{product.currency}}</label>
							</div>

						</div>
					</div>

				</div>

				<!-- MIS USUARIOS -->
			</div>
		</div>




	</div>