				<div class="row">
	                <div class="padding-2">  
						<div class=" cont_info zero">
							
							<div class="tab-content" id="pills-tabContent" >
								<div class="row background-white padding-top1">
									<p class="text center strong black margin2-l-r">
										Listo, realizaste una compra inteligenre exitosament.Ahora depende de ustedes estableces una buena comunicación para fijar forms de pago y envío.
									</p>
								</div>
						  		<div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
									<!---->							  			
						  			<div class="row background-white">
				  						<div class="col-12 col-md-4 text center">
				  							<!-- logito de garantia -->
				  							<div class="row">
				  								<div class="col-0 col-md-4 "></div>
				  								<div class="col-12 col-md-5 zero garantia garanty" style="background-image:url(assets/images/gallery/marcas/garantia.png);">
				  									<div class="galery-numbrer">9.5</div>
				  									<div class="galery-text">RECOMENDACIÓN</div>
				  									<div class="galery-logo">IXCCO</div>
				  								</div>
				  								<div class="col-0 col-md-3 "></div>
				  							</div>
							  				Cotozación para <strong>2</strong> productos
						  				</div>
						  				<div class="col-12 col-md-4 black margin1">
							  				<div class="row width padding-top1">
							  					
							  					<div class=" col-3 col-md-12 zero ">
								  					<img class="" alt="user" class="profile-pic logo-start" src="assets/images/gallery/marcas/CEMEX.png">	
								  				</div>
								  				
								  				<div class=" col-9 col-md-12 zero" >
								  					<div class="text center-left">
								  						CEMEX</div>
								  					<div class="text center-left">
								  						<i class="fa fa-star" aria-hidden="true"></i>
														<i class="fa fa-star" aria-hidden="true"></i>
														<i class="fa fa-star" aria-hidden="true"></i>
														<i class="fa fa-star-o" aria-hidden="true"></i>
														<i class="fa fa-star-o" aria-hidden="true"></i>	</div>
							  						<div class="text center-left">Zapopan, Jalisco, México</div>
							  					</div>

								  				<div class="col-6 col-md-12 zero text center-left" 
								  					
								  					>Ver las <strong class="strong">883</strong> reseñas > </div>
								  				<div class="col-6 col-md-12 text center-left">www.cenmex.com</div>
									  			
							  				</div>
						  				</div>
						  				<div class="col-12 col-md-4 text center margin1 padding-6">
						  					
											<div class="row zero margin1">
						  						<div class="col-1 col-md-1 zero right text " >
									  				<i class="fa fa-money pad5 text-bar" aria-hidden="true"></i>
								  				</div>
								  				<div class="col-9 col-md-9 zero left">
													<div class="zero">
													  <div class="stat-1 stat-bar">
													    <span class="stat-bar-rating" role="stat-bar" style="width:10%;"></span>
													  </div>
													</div>
								  				</div>
								  				<div class="col-2 col-md-2 number-bar zero">
									  				<p class="zero left number-bar">10</p>
								  				</div>
								  				<div class="col-2 col-md-2 text "></div>
								  				<div class=" col-10 col-md-10  zero">
									  					<p class="text price"> price total: $2, 235.00 mxn</p>
								  				</div>
							  				</div>
							  				<div class="row zero margin1">
						  						<div class="col-1 col-md-1 zero right text " >
									  				<i class="fa fa-truck pad5 text-bar" aria-hidden="true"></i>
								  				</div>
								  				<div class="col-9 col-md-9 zero left">
													<div class="zero">
													  <div class="stat-1 stat-bar">
													    <span class="stat-bar-rating" role="stat-bar" style="width:20%;"></span>
													  </div>
													</div>
								  				</div>
								  				<div class="col-2 col-md-2 number-bar zero">
									  				<p class="zero left number-bar">20</p>
								  				</div>
								  				<div class="col-2 col-md-2 text "></div>
								  				<div class=" col-10 col-md-10  zero">
									  					<p class="text price"> ENtrega 19/Mar/2018</p>
								  				</div>
							  				</div>
							  				<div class="row zero margin1">
						  						<div class="col-1 col-md-1 zero right text " >
									  				<i class="fa fa-credit-card pad5 text-bar" aria-hidden="true"></i>
								  				</div>
								  				<div class="col-9 col-md-9 zero left">
													<div class="zero">
													  <div class="stat-1 stat-bar">
													    <span class="stat-bar-rating" role="stat-bar" style="width:40%;"></span>
													  </div>
													</div>
								  				</div>
								  				<div class="col-2 col-md-2 number-bar zero">
									  				<p class="zero left number-bar">40</p>
								  				</div>
								  				<div class="col-2 col-md-2 text "></div>
								  				<div class=" col-10 col-md-10  zero">
									  					<p class="text price"> An25% &nbsp;&nbsp;&nbsp; Co25% &nbsp;&nbsp;&nbsp;Cr50% &nbsp;&nbsp;&nbsp; por 30 días</p>
								  				</div>
							  				</div>
							  				<div class="row zero margin1">
						  						<div class="col-1 col-md-1 zero right text " >
									  				<i class="fa fa-bookmark pad5 text-bar" aria-hidden="true"></i>
								  				</div>
								  				<div class="col-9 col-md-9 zero left">
													<div class="zero">
													  <div class="stat-1 stat-bar">
													    <span class="stat-bar-rating" role="stat-bar" style="width:80%;"></span>
													  </div>
													</div>
								  				</div>
								  				<div class="col-2 col-md-2 number-bar zero">
									  				<p class="zero left number-bar">80</p>
								  				</div>
								  				<div class="col-2 col-md-2 text "></div>
								  				<div class=" col-10 col-md-10  zero">
									  					<p class="text price"> Sobre 883 valoraciones</p>
								  				</div>
							  				</div>
						  				</div>
					  				</div>
					  				<div class="row background-white"> &nbsp;</div>
					  				<div class="row background-white black padding-6 border-and-padding">
				  						<div class="col-4 col-md-1 text center zero">
				  							<div class="container-button">
				  								<div class="container-button-text"> 2 </div>
				  								<i class="fa fa-arrow-circle-o-down zero" aria-hidden="true"></i>
				  							</div>
				  						</div>
				  						<div class="col-8 col-md-3 text left zeror">
				  							<p class="zero">Nombre del producto</p>
				  							<label class="zero strong ">Tornillo milimétrico "1/2" X "6"</label>
				  						</div>
				  						<div class="col-5 col-md-1 text left zeror">
				  							<p class="zero">Cantidad:</p>
				  							<label class=" strong zero">2000</label>
				  						</div>
				  						<div class="col-7 col-md-4 text left zero">
				  							<p class="zero">Caracteristicas:</p>
				  							<label class=" strong zero">2000</label>
				  						</div>
				  						<div class="col-5 col-md-2 text left zero">
				  							<p class="zero">Area:</p>
				  							<label class=" strong zero">Mantenimiento</label>
				  						</div>
				  						<div class="col-7 col-md-1 text left zero">
				  							<p class="zero">price unitario:</p>
				  							<label class=" strong zero">$2.14mxn</label>
				  						</div>
				  					</div>
				  					<div class="row background-white black padding-6 border-and-padding">
				  						<div class="col-4 col-md-1 text center zero">
				  							<div class="container-button">
				  								<div class="container-button-text"> 2 </div>
				  								<i class="fa fa-arrow-circle-o-down zero" aria-hidden="true"></i>
				  							</div>
				  						</div>
				  						<div class="col-8 col-md-3 text left zeror">
				  							<p class="zero">Nombre del producto</p>
				  							<label class="zero strong ">Tornillo milimétrico "1/2" X "6"</label>
				  						</div>
				  						<div class="col-5 col-md-1 text left zeror">
				  							<p class="zero">Cantidad:</p>
				  							<label class=" strong zero">2000</label>
				  						</div>
				  						<div class="col-7 col-md-4 text left zero">
				  							<p class="zero">Caracteristicas:</p>
				  							<label class=" strong zero">2000</label>
				  						</div>
				  						<div class="col-5 col-md-2 text left zero">
				  							<p class="zero">Area:</p>
				  							<label class=" strong zero">Mantenimiento</label>
				  						</div>
				  						<div class="col-7 col-md-1 text left zero">
				  							<p class="zero">price unitario:</p>
				  							<label class=" strong zero">$2.14mxn</label>
				  						</div>
				  					</div>
					  			</div>
							  	
							  		<!-- MIS USUARIOS -->
							  			<!-- <div class="row cero bg_blanco padding5">
					  						<div class="col-0 col-md-3"> </div>
											<div class="col-12 col-md-6 text center green font-8"> 
						  						<i class="fa fa-clock-o" aria-hidden="true" style="font-size:1.6em;"></i> &nbsp;
									            Recibiras tus productos el dia <span class="bold">19 de marzo de 2018 a las 11:00</span>
									        </div>
									        <div class="col-0 col-md-3"> </div>
					  					</div>

					  					<div class="row cero bg_blanco padding5" style="border:solid 1px #F0F0F0;">
											<div class="col-0 col-md-4 cero text center"> </div>
											<div class="col-12 col-md-4 text center"> 
												<button class="boton_clip cero center pad08 collapse_title width font-1 white" style="background:#447F19;border:solid transparent;" (click)="mensajes()"> ¡3 mensajes nuevos!</button></div>
											<div class="col-0 col-md-4 text center"></div>
										</div> -->
							</div>
						</div>
					</div>
					
	            </div>

	            		        