 <div class="row zero tabla width" *ngFor="let resena of resenas">
	<div class="row contborder border_resena width">
		<div class="col-3 col-md-3 text bold zero para_logo" style="background-image:url(assets/images/gallery/marcas/CEMEX.png);">
		</div>
		<div class="col-7 col-md-6 width">
			<div class="row width ">
				<div class="col-12 col-md-12 zero">
					<div class="left name_resena strong black">{{resena.nombre}} </div>
				</div>
				<div class="col-6 col-md-6 text zero font-8-4">
					<p class="font-8-4">{{resena.validaciones}}, valoraciones</p>
					<p class="font-8-4">{{resena.lugar}}</p>
				</div>
				<div class="col-6 col-md-6 text zero font-8-4">
					<p class="font-8-4">{{resena.ventas}} ventas</p>
					<p class="font-8-4">{{resena.compras}} compras</p>
				</div>
			</div>
		</div>
		<div class="col-2 col-md-3 text bold zero start"  style="background-image:url(assets/images/gallery/marcas/start.png);">
			<div class="start_calificacion"> {{resena.calificacion}}</div>
		</div>
	</div>

	<div class="row heightcontainer width">
		<div class="container-fluid zero">
			<div class="row width containerresena zero" *ngFor="let res of resena.lasresenas">
				<div class="col-2 col-md-2 text bold zero el-icono logo-movil"  style="background-image:url(assets/images/gallery/marcas/CEMEX.png);">
				</div>
				<div class="col-8 col-md-10 text zero">
					<div class="row bold width pad_resena">
						<div class="col-3 col-sm-3 col-md-3 left text bold zero"> {{res.nombre}} </div>
						<div class="col-5 col-sm-6 col-md-6 left text bold zero">
							<i class="fa fa-clock-o" aria-hidden="true"></i> {{res.horas}} horas </div>
						<div class="col-4 col-sm-3 col-md-3 left text bold zero">
							<i class="fa {{res.start1}} estrella" aria-hidden="true"></i>
							<i class="fa {{res.start2}} estrella" aria-hidden="true"></i>
							<i class="fa {{res.start3}} estrella" aria-hidden="true"></i>
							<i class="fa {{res.start4}} estrella" aria-hidden="true"></i>
							<i class="fa {{res.start5}} estrella" aria-hidden="true"></i>
						</div>
					</div>
					<div class="row">
						<p class="font-8-4"> {{res.detalles}} </p>
					</div>
				</div>
			</div>

		</div>
	</div>

</div> 