import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/apiService';
import { DomSanitizer } from '@angular/platform-browser';
import * as jsPDF from 'jspdf'
import { pdfA, imgDataQuot, } from '../../services/imageInterface';
import { pdfB, pdfC } from '../../services/imageInterface';

declare var $: any;

@Component({
    selector: 'app-pdfQuotation',
    templateUrl: './pdfQuotation.component.html',
    styleUrls: ['./pdfQuotation.component.css'],
    providers: [
        { provide: 'Window', useValue: window },
        ApiService
    ]
})

export class PdfQuotationComponent implements OnInit {
    @Input() previewQuotation: any;
    @Input() request: any;
    @Output() confirmedQuotation: EventEmitter<any> = new EventEmitter();
    formattedAmount: any;
    public userName = '';
    public userMail = '';

    public buyerName = '';
    public buyerMail = '';

    public companyName = '';
    public fiscalName = '';

    public companyAddress = '';
    public companyPhone;
    public companyProfile;


    public buyerCompanyName = '';
    public buyerFiscalName = '';

    public buyerCompanyAddress = '';
    public buyerCompanyPhone;
    public buyerCompanyProfile;

    public branch = '';
    public preview = false;


    public order = {};
    private errorMessage = '';
    public str: string;
    private image1 = 0;
    private image2 = 0;
    public doc = new jsPDF();
    public imgData = imgDataQuot;
    public pdfA = pdfA;

    public pdfB = pdfB;
    public pdfC = pdfC;

    constructor(@Inject('Window') private window: Window,
        public sanitizer: DomSanitizer,
        private _route: ActivatedRoute,
        private _apiService: ApiService) {
    }

    ngOnInit() {
        this.getQuotation('Quotations', this._route.snapshot.paramMap.get('id'), this.previewQuotation);
    }
    ngOnDestroy() {
        if (this.doc) {

            this.doc = null;
            this.str = '';
        }

    }
    getBase64Image(url, number, cb) {
        var img = new Image();
        img.crossOrigin = "anonymous";
        var dataURL;

        img.src = url;

        img.onload = function () {
            var canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            var context = canvas.getContext('2d');
            context.drawImage(img, 0, 0);
            dataURL = canvas.toDataURL('image/jpeg');
            cb(dataURL)
        }
    }
    formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
        console.log(amount)
        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

            const negativeSign: any = amount < 0 ? "-" : "";

            let i: any = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            let j: any = (i.length > 3) ? i.length % 3 : 0;
            console.log(negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : ""));
            return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
            console.log(e)
        }
    };
    add(a, b) {
        return a + b;
    }
    getQuotation(type, id, quotation?) {
        let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
        let params = {
            filter: {
                include: ['company', 'branch', 'user', { relation: 'request', scope: { include: ['company', 'branch'] } }]
            },
            access_token: tokenId
        };

        if (!quotation) {

            this.preview = false
            this._apiService.getDataByIdQuotation(type, id, params).subscribe(
                (result: any) => {
                    console.log(result)
                    console.log(result, ' Result Quotation');
                    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
                    let filter = {

                        access_token: tokenId
                    };
                    this.getUserBuyerName('AppUsers', result.request.userId, filter);
                    this.getBranchName('branches', result.branchId, filter);
                    this.getUserName('AppUsers', result.sellerId, filter);

                    this.getCompanyName('Companies', result.companyId, filter);

                    let totalProducts = result.quotedProducts.length;
                    let pages = Math.ceil(totalProducts / 8);
                    for (var b = 0; b < result.quotedProducts.length; b++) {
                        if (b % 8 === 0 && b != 0) {
                            console.log('NEW PAGE');
                            this.doc.addPage();
                        }
                    }
                    setTimeout(() => {
                        if (pages > 1) {

                            if (pages === 2) {

                                this.doc.setPage(1);
                                this.doc.addImage(this.pdfA, 'JPEG', 0, 0, 210, 297);
                                this.doc.setPage(2);
                                this.doc.addImage(this.pdfC, 'JPEG', 0, 0, 210, 297);


                            } else if (pages > 2) {
                                console.log('MAS DE 2 PAGINAS');
                                for (var b = 0; b < pages; b++) {
                                    this.doc.setPage(1);
                                    this.doc.addImage(this.pdfA, 'JPEG', 0, 0, 210, 297);

                                    if (b > 1) {
                                        this.doc.setPage(b)
                                        this.doc.addImage(this.pdfB, 'JPEG', 0, 0, 210, 297);

                                    }

                                }

                                this.doc.setPage(pages);
                                this.doc.addImage(this.pdfC, 'JPEG', 0, 0, 210, 297);



                            }
                        } else {
                            this.doc.addImage(this.imgData, 'JPEG', 0, 0, 210, 297);
                        }
                        this.doc.setPage(1);

                        setTimeout(() => {
                            //this.doc.addImage(this.imgData, 'JPEG', 0, 0, 210, 297);
                            this.doc.setDrawColor(255, 100, 100);
                            this.doc.setFontSize(5);


                            this.doc.setFontSize(8);
                            this.doc.setTextColor(169, 169, 169);



                            //init generate pdf

                            // Orden
                            let estimated = new Date(result.estimatedDeliveryDate);
                            var dd: any = estimated.getDate();
                            var mm: any = estimated.getMonth() + 1; //January is 0!

                            var yyyy = estimated.getFullYear();
                            if (dd < 10) {
                                dd = '0' + dd;
                            }
                            if (mm < 10) {
                                mm = '0' + mm;
                            }
                            var todayEstimated = dd + '/' + mm + '/' + yyyy;

                            this.doc.text(135, 10, new Date().toLocaleString());

                            this.doc.text(135, 64, todayEstimated.toLocaleString());


                            //Datos del cliente
                            if (this.buyerCompanyName) {
                                this.doc.text(135, 23, this.doc.splitTextToSize(this.buyerCompanyName, 50));

                            }
                            if (this.buyerCompanyAddress) {
                                this.doc.text(135, 29, this.doc.splitTextToSize(this.buyerCompanyAddress, 50));

                            }
                            if (this.buyerFiscalName) {
                                this.doc.text(135, 35, this.doc.splitTextToSize(this.buyerFiscalName, 50));

                            }
                            if (this.buyerCompanyPhone) {
                                this.doc.text(135, 39, this.doc.splitTextToSize(this.buyerCompanyPhone, 50));

                            }
                            if (this.buyerName) {

                                this.doc.text(135, 44, this.doc.splitTextToSize(this.buyerName, 50));

                            }
                            if (this.buyerMail) {

                                this.doc.text(135, 47, this.doc.splitTextToSize(this.buyerMail, 50));

                            }

                            //Provedor
                            if (this.companyName) {

                                this.doc.text(30, 23, this.companyName);
                                this.doc.text(30, 30, this.fiscalName);

                            }
                            if (this.companyPhone) {
                                this.doc.text(30, 36, this.doc.splitTextToSize(this.companyPhone, 50));  //contacto
                            }
                            //this.doc.text(30, 30, result.branch.name);
                            if (this.companyAddress) {
                                this.doc.text(30, 40, this.doc.splitTextToSize(this.companyAddress, 49));

                            }
                            //

                            //Terminos
                            if (result.currency === "USD") {
                                this.doc.text(135, 53, this.doc.splitTextToSize('Moneda: ' + 'USD', 50));

                            } else {
                                this.doc.text(135, 53, this.doc.splitTextToSize('Moneda: ' + 'MXN', 50));

                            }
                            this.doc.text(135, 56, this.doc.splitTextToSize('Anticipo: ' + result.paymentOptions.anticipated + ' %', 25));
                            this.doc.text(160, 56, this.doc.splitTextToSize('Contraentrega: ' + result.paymentOptions.uponDelivery + ' %', 30));
                            this.doc.text(135, 59, this.doc.splitTextToSize('Credito: ' + result.paymentOptions.credit + ' %', 25));
                            this.doc.text(160, 59, this.doc.splitTextToSize('Dias credito: ' + result.paymentOptions.days, 25));

                            //contacto Proveedor

                            this.doc.text(30, 50, this.doc.splitTextToSize(result.user.personalInfo.name, 50));
                            this.doc.text(30, 56, this.doc.splitTextToSize(result.user.email, 50));
                            this.doc.text(30, 66, 'C-' + result.id);

                            if (result.request.selectedAddress) {
                                this.doc.text(45, 15, this.doc.splitTextToSize('Entrega:' + '' + result.request.selectedAddress, 50));  //contacto

                            }


                            if (result.company.companyInfo.phone) {
                                this.doc.text(30, 62, this.doc.splitTextToSize(result.company.companyInfo.phone, 50));  //contacto
                            }
                            //ordenes
                            this.doc.setTextColor(0, 0, 0);

                            var total = 0;
                            for (var b = 0; b < result.quotedProducts.length; b++) {
                                if (result.quotedProducts[b] != null) {
                                    total += (Number(result.quotedProducts[b].amount) * result.quotedProducts[b].quantity);


                                }

                            }



                            var y = 0;
                            var height = 0;
                            var numberOfPages = 1;
                            for (var b = 0; b < result.quotedProducts.length; b++) {
                                if (b % 8 === 0 && b != 0) {
                                    console.log('SET PAGE');
                                    numberOfPages++;
                                    this.doc.setPage(numberOfPages)
                                    height = 0;
                                    y = 0;
                                }
                                if (result.quotedProducts[b].specification) {
                                    if (result.quotedProducts[b].specification.unit) {

                                        if (result.quotedProducts[b].comments) {
                                            var text = [result.quotedProducts[b].name + ' ' + result.quotedProducts[b].specification.unit + ' ' + ',' + '' + result.quotedProducts[b].comments];

                                        } else {
                                            var text = [result.quotedProducts[b].name + ' ' + result.quotedProducts[b].specification.unit];

                                        }

                                    }
                                } else {
                                    if (result.quotedProducts[b].specificationName) {
                                        if (result.quotedProducts[b].comments) {
                                            var text = [result.quotedProducts[b].name + ' ' + result.quotedProducts[b].specificationName + ' ' + ',' + '' + result.quotedProducts[b].comments];

                                        } else {
                                            var text = [result.quotedProducts[b].name + ' ' + result.quotedProducts[b].specificationName];

                                        }

                                    } else {

                                        var text = [result.quotedProducts[b].name + ' '];

                                    }

                                }

                                if (result.quotedProducts[b] != null) {
                                    if (b < 8) {
                                        this.doc.setFontSize(7);
                                        // ((total += (Number(result.quotedProducts[b].amount) * result.quotedProducts[b].quantity);
                                        this.doc.text(10, 90 + (b * 7) + y, this.doc.splitTextToSize(result.quotedProducts[b].id, 18));
                                        this.doc.setFontSize(7);
                                        if (result.quotedProducts[b].unit) {
                                            if (result.quotedProducts[b].unit.name) {
                                                this.doc.text(72, 90 + (b * 7) + y, this.doc.splitTextToSize(result.quotedProducts[b].unit.name, 20));


                                            }
                                        } else {

                                            if (result.quotedProducts[b].unitName) {
                                                this.doc.text(72, 90 + (b * 7) + y, this.doc.splitTextToSize(result.quotedProducts[b].unitName, 20));


                                            }
                                            //ES del Excel y no trae unidad
                                        }
                                        this.doc.text(100, 90 + (b * 7) + y, this.doc.splitTextToSize(result.quotedProducts[b].quantity.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'), 20));
                                        this.doc.text(130, 90 + (b * 7) + y, this.doc.splitTextToSize('$' + this.formatMoney(result.quotedProducts[b].amount), 20));
                                        let importe = (result.quotedProducts[b].amount * result.quotedProducts[b].quantity).toFixed(2);
                                        this.doc.text(155, 90 + (b * 7) + y, this.doc.splitTextToSize('$ ' + this.formatMoney(importe), 20));
                                        this.doc.text(193, 90 + (b * 7) + y, this.doc.splitTextToSize(((result.quotedProducts[b].amount * result.quotedProducts[b].quantity) * 100 / total).toFixed(2) + '%', 20));

                                        for (var i = 0; i < text.length; i++) {

                                            var splitTitle = this.doc.splitTextToSize(text[i], 38);
                                            //loop thru each line and output while increasing the vertical space
                                            console.log(splitTitle, b);
                                            for (var c = 0; c < splitTitle.length; c++) {

                                                this.doc.text(30, 90 + y + (b * 7), splitTitle[c]);
                                                y = y + 3;

                                            }

                                        }

                                        // this.doc.text(33, y + (b * 10), this.doc.splitTextToSize(result.quotedProducts[b].name + ' ' + result.quotedProducts[b].specification.unit, 38));

                                        this.doc.setFontSize(5);
                                        //this.doc.text(32, 90 + (b * 10) + y, this.doc.splitTextToSize(result.quotedProducts[b].comments, 28));



                                    } else {
                                        this.doc.setFontSize(7);
                                        // ((total += (Number(result.quotedProducts[b].amount) * result.quotedProducts[b].quantity);
                                        this.doc.text(10, 30 + (height * 7) + y, this.doc.splitTextToSize(result.quotedProducts[b].id, 18));
                                        this.doc.setFontSize(7);

                                        // this.doc.text(72, 30 + (height * 7) + y, this.doc.splitTextToSize(result.quotedProducts[b].unit.name, 20));
                                        if (result.quotedProducts[b].unit) {
                                            if (result.quotedProducts[b].unit.name) {
                                                this.doc.text(72, 30 + (height * 7) + y, this.doc.splitTextToSize(result.quotedProducts[b].unit.name, 20));


                                            }
                                        } else {

                                            if (result.quotedProducts[b].unitName) {
                                                this.doc.text(72, 30 + (height * 7) + y, this.doc.splitTextToSize(result.quotedProducts[b].unitName, 20));


                                            }
                                            //ES del Excel y no trae unidad
                                        }
                                        this.doc.text(100, 30 + (height * 7) + y, this.doc.splitTextToSize(result.quotedProducts[b].quantity.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'), 20));
                                        this.doc.text(130, 30 + (height * 7) + y, this.doc.splitTextToSize('$' + this.formatMoney(result.quotedProducts[b].amount), 20));
                                        let importe = (result.quotedProducts[b].amount * result.quotedProducts[b].quantity).toFixed(2);
                                        this.doc.text(155, 30 + (height * 7) + y, this.doc.splitTextToSize('$ ' + this.formatMoney(importe), 20));
                                        this.doc.text(193, 30 + (height * 7) + y, this.doc.splitTextToSize(((result.quotedProducts[b].amount * result.quotedProducts[b].quantity) * 100 / total).toFixed(2) + '%', 20));

                                        for (var i = 0; i < text.length; i++) {

                                            var splitTitle = this.doc.splitTextToSize(text[i], 38);
                                            //loop thru each line and output while increasing the vertical space
                                            console.log(splitTitle, b);
                                            for (var c = 0; c < splitTitle.length; c++) {

                                                this.doc.text(30, 30 + y + (height * 7), splitTitle[c]);
                                                y = y + 3;

                                            }

                                        }

                                        // this.doc.text(33, y + (b * 10), this.doc.splitTextToSize(result.quotedProducts[b].name + ' ' + result.quotedProducts[b].specification.unit, 38));

                                        this.doc.setFontSize(5);
                                        //this.doc.text(32, 90 + (b * 5) + y, this.doc.splitTextToSize(result.quotedProducts[b].comments, 28));
                                        height++;

                                    }





                                }

                            }
                            console.log('TOTAL' + total)
                            this.doc.setFontSize(10);
                            this.doc.setTextColor(0, 128, 0);
                            this.doc.text(150, 240, this.doc.splitTextToSize('$ ' + total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'), 50));
                            this.doc.setFontSize(8);
                            this.doc.setTextColor(169, 169, 169);
                            this.doc.text(150, 250, this.doc.splitTextToSize('IVA 16.00%', 50));
                            this.doc.text(180, 250, this.doc.splitTextToSize('$ ' + (total * .16).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'), 50));
                            this.doc.setFontSize(10);
                            this.doc.setFontType('bold');
                            this.doc.setTextColor(90, 90, 90);
                            this.doc.text(180, 257, this.doc.splitTextToSize('$ ' + (total + (total * .16)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'), 50));
                            if (result.deliveryDetails) {
                                this.doc.text(20, 225, 'Detalles:');
                                //this.doc.text(20, 230, result.deliveryDetails);
                                this.doc.setFontSize(8);

                                //this.doc.text(20, 230, result.deliveryDetails);
                                var splitTitle = this.doc.splitTextToSize(result.deliveryDetails, 120);
                                this.doc.text(15, 230, splitTitle);

                            }
                            //string pdf
                            //
                            //images here
                            this.doc.setPage(1)

                            if (result.request.company.profileUrl) {

                                this.getBase64Image(result.request.company.profileUrl, 1, (dataUrl1) => {

                                    if (result.company.profileUrl) {
                                        this.getBase64Image(result.company.profileUrl, 2,
                                            (dataUrl2) => {

                                                // console.log(dataUrl2, 'cb2');
                                                if (dataUrl1 && dataUrl2) {
                                                    this.doc.addImage(dataUrl1, 115, 11, 15, 15);
                                                    this.doc.addImage(dataUrl2, 10, 11, 15, 15);
                                                }
                                                // this.doc.save('O-' + result.id + '.pdf');
                                                this.str = this.doc.output('datauristring');

                                            });
                                    }
                                });
                            }
                        }, 500)





                    }, 100)







                    // Save the PDF
                    // this.doc.save('Test.pdf');

                    // var iframe = "<iframe class='v-w-v-h' width='100%' height='100%' src='" + string + "' type='application/pdf'></iframe>";
                    // //  var iframe = "<object data="+string+"type='application/pdf'></object>";
                    // this.window.document.write(iframe);
                },
                error => {
                    this.errorMessage = <any>error;
                    if (this.errorMessage != null) {
                        console.log(this.errorMessage);


                    }
                }
            );
        } else {
            console.log(quotation);
            console.log(this.request);

            this.preview = true
            let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
            let filter = {

                access_token: tokenId
            };
            this.getBranchName('branches', quotation.branchId, filter);
            this.getUserName('AppUsers', quotation.sellerId, filter);
            this.getUserBuyerName('AppUsers', this.request.userId, filter);

            this.getCompanyName('Companies', quotation.companyId, filter);


            let totalProducts = quotation.quotedProducts.length;
            let pages = Math.ceil(totalProducts / 8);
            for (var b = 0; b < quotation.quotedProducts.length; b++) {
                if (b % 8 === 0 && b != 0) {
                    console.log('NEW PAGE');
                    this.doc.addPage();
                }
            }


            setTimeout(() => {
                if (pages > 1) {

                    if (pages === 2) {

                        this.doc.setPage(1);
                        this.doc.addImage(this.pdfA, 'JPEG', 0, 0, 210, 297);
                        this.doc.setPage(2);
                        this.doc.addImage(this.pdfC, 'JPEG', 0, 0, 210, 297);


                    } else if (pages > 2) {
                        console.log('MAS DE 2 PAGINAS');
                        for (var b = 0; b < pages; b++) {
                            this.doc.setPage(1);
                            this.doc.addImage(this.pdfA, 'JPEG', 0, 0, 210, 297);

                            if (b > 1) {
                                this.doc.setPage(b)
                                this.doc.addImage(this.pdfB, 'JPEG', 0, 0, 210, 297);

                            }

                        }

                        this.doc.setPage(pages);
                        this.doc.addImage(this.pdfC, 'JPEG', 0, 0, 210, 297);



                    }
                } else {
                    this.doc.addImage(this.imgData, 'JPEG', 0, 0, 210, 297);
                }
                this.doc.setPage(1);
                setTimeout(() => {
                    //this.doc.addImage(this.imgData, 'JPEG', 0, 0, 210, 297);
                    this.doc.setDrawColor(255, 100, 100);
                    this.doc.setFontSize(5);


                    this.doc.setFontSize(8);
                    this.doc.setTextColor(169, 169, 169);



                    //init generate pdf
                    // Orden
                    this.doc.text(30, 65, 'C-' + quotation.requestId);// en la imagen debe decir cotizacion
                    let today = new Date();
                    let estimated = new Date(quotation.estimatedDeliveryDate);
                    var dd: any = estimated.getDate();
                    var mm: any = estimated.getMonth() + 1; //January is 0!

                    var yyyy = estimated.getFullYear();
                    if (dd < 10) {
                        dd = '0' + dd;
                    }
                    if (mm < 10) {
                        mm = '0' + mm;
                    }
                    var todayEstimated = dd + '/' + mm + '/' + yyyy;

                    this.doc.text(155, 10, today.toLocaleString());

                    this.doc.text(135, 65, todayEstimated.toLocaleString());

                    //Datos del cliente
                    if (this.request.company.name) {
                        this.doc.text(135, 23, this.doc.splitTextToSize(this.request.company.name, 50));

                    }
                    if (this.request.company.companyInfo.address) {
                        this.doc.text(135, 30, this.doc.splitTextToSize(this.request.company.companyInfo.address, 50));

                    }
                    if (this.request.company.companyInfo.fiscalName) {
                        this.doc.text(135, 37, this.doc.splitTextToSize(this.request.company.companyInfo.fiscalName, 50));

                    }
                    if (this.buyerName) {
                        this.doc.text(135, 44, this.doc.splitTextToSize(this.buyerName, 50));

                    }
                    if (this.buyerMail) {
                        this.doc.text(135, 47, this.doc.splitTextToSize(this.buyerMail, 50));

                    }
                    if (this.buyerCompanyPhone) {
                        this.doc.text(135, 50, this.doc.splitTextToSize(this.buyerCompanyPhone, 50));

                    }

                    //Provedor
                    if (this.companyName) {
                        this.doc.text(30, 36, this.companyName);
                        this.doc.text(30, 40, this.fiscalName);

                    }
                    //this.doc.text(30, 30, result.branch.name);
                    if (this.companyAddress) {
                        this.doc.text(30, 45, this.doc.splitTextToSize(this.companyAddress, 49));

                    }
                    /*  if (this.companyPhone) {
                         this.doc.text(30, 44, this.doc.splitTextToSize(this.companyPhone, 50));  //contacto
                     } */
                    //Terminos
                    this.doc.text(135, 53, this.doc.splitTextToSize('Moneda: ' + quotation.currency, 50));
                    this.doc.text(135, 56, this.doc.splitTextToSize('Anticipo: ' + quotation.paymentOptions.anticipated + ' %', 25));
                    this.doc.text(160, 56, this.doc.splitTextToSize('Contraentrega: ' + quotation.paymentOptions.uponDelivery + ' %', 30));
                    this.doc.text(135, 59, this.doc.splitTextToSize('Credito: ' + quotation.paymentOptions.credit + ' %', 25));
                    this.doc.text(160, 59, this.doc.splitTextToSize('Dias credito: ' + quotation.paymentOptions.days, 25));

                    //contacto
                    this.doc.text(30, 55, this.doc.splitTextToSize(this.userName, 50));
                    this.doc.text(30, 58, this.doc.splitTextToSize(this.userMail, 50));
                    if (this.companyPhone) {
                        this.doc.text(30, 62, this.doc.splitTextToSize(this.companyPhone, 50));  //contacto
                    }
                    if (this.request.selectedAddress) {
                        this.doc.text(45, 15, this.doc.splitTextToSize('Entrega:' + '' + this.request.selectedAddress, 50));  //contacto

                    }
                    //ordenes
                    this.doc.setTextColor(0, 0, 0);

                    var total = 0;

                    for (var b = 0; b < quotation.quotedProducts.length; b++) {
                        if (quotation.quotedProducts[b] != null) {
                            total += (Number(quotation.quotedProducts[b].amount) * quotation.quotedProducts[b].quantity);


                        }

                    }

                    var y = 0;
                    var height = 0;
                    var numberOfPages = 1;
                    for (var b = 0; b < quotation.quotedProducts.length; b++) {
                        console.log(quotation.quotedProducts[b].name);
                        var asd = quotation.quotedProducts[b].name.replace(/"/g, '&quot;')
                        console.log(asd);
                        var asd2 = quotation.quotedProducts[b].name.replace(/\"/g, "&quot;");
                        console.log(asd);
                        var asd3 = quotation.quotedProducts[b].name.replace(/\\"/g, '"').replace(/"/g, '\\"');
                        console.log(asd3);
                        const regex = /(['"]+)/gms;
                        const str = `2"`;
                        const subst = `inches`;

                        // The substituted value will be contained in the result variable
                        const result = quotation.quotedProducts[b].name.toString().replace(regex, subst);
                        console.log(result)
                        if (b % 8 === 0 && b != 0) {
                            console.log('SET PAGE');
                            numberOfPages++;
                            this.doc.setPage(numberOfPages)
                            height = 0;
                            y = 0;
                        }
                        if (quotation.quotedProducts[b].specification) {
                            if (quotation.quotedProducts[b].specification.unit) {

                                if (quotation.quotedProducts[b].comments) {
                                    var text = [quotation.quotedProducts[b].name.replace(/["']/g, "Inch") + ' ' + quotation.quotedProducts[b].specification.unit +
                                        ' ' + ',' + '' + quotation.quotedProducts[b].comments];

                                } else {
                                    var text = [quotation.quotedProducts[b].name.replace(/["']/g, "Inch") + ' ' + quotation.quotedProducts[b].specification.unit];

                                }

                            }
                        } else {
                            if (quotation.quotedProducts[b].specificationName) {
                                if (quotation.quotedProducts[b].comments) {
                                    var text = [quotation.quotedProducts[b].name.replace(/["']/g, "Inch") + ' ' + quotation.quotedProducts[b].specificationName + ' ' + ',' + '' + quotation.quotedProducts[b].comments];

                                } else {
                                    var text = [quotation.quotedProducts[b].name.replace(/["']/g, "Inch") + ' ' + quotation.quotedProducts[b].specificationName];

                                }

                            } else {

                                var text = [quotation.quotedProducts[b].name.replace(/["']/g, "Inch") + ' '];

                            }

                        }
                        if (quotation.quotedProducts[b] != null) {
                            if (b < 8) {


                                this.doc.setFontSize(7);
                                // ((total += (Number(result.quotedProducts[b].amount) * result.quotedProducts[b].quantity);
                                this.doc.text(10, 90 + (b * 7) + y, this.doc.splitTextToSize(quotation.quotedProducts[b].id, 18));
                                this.doc.setFontSize(7);

                                if (quotation.quotedProducts[b].unit) {
                                    if (quotation.quotedProducts[b].unit.name) {
                                        this.doc.text(72, 90 + (b * 7) + y, this.doc.splitTextToSize(quotation.quotedProducts[b].unit.name, 20));


                                    }
                                } else {



                                    if (quotation.quotedProducts[b].unitName) {
                                        this.doc.text(72, 90 + (b * 7) + y, this.doc.splitTextToSize(quotation.quotedProducts[b].unitName, 20));


                                    }
                                    //ES del Excel y no trae unidad
                                }

                                this.doc.text(100, 90 + (b * 7) + y, this.doc.splitTextToSize(quotation.quotedProducts[b].quantity.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'), 20));
                                this.doc.text(130, 90 + (b * 7) + y, this.doc.splitTextToSize('$' + this.formatMoney(quotation.quotedProducts[b].amount), 20));
                                let importe = (quotation.quotedProducts[b].amount * quotation.quotedProducts[b].quantity).toFixed(2);
                                this.doc.text(155, 90 + (b * 7) + y, this.doc.splitTextToSize('$ ' + this.formatMoney(importe), 20));
                                this.doc.text(193, 90 + (b * 7) + y, this.doc.splitTextToSize(((quotation.quotedProducts[b].amount * quotation.quotedProducts[b].quantity) * 100 / total).toFixed(2) + '%', 20));

                                for (var i = 0; i < text.length; i++) {

                                    var splitTitle = this.doc.splitTextToSize(text[i].replace(/["']/g, "")
                                        , 38);
                                    //loop thru each line and output while increasing the vertical space
                                    console.log(splitTitle, b);
                                    for (var c = 0; c < splitTitle.length; c++) {

                                        this.doc.text(30, 90 + y + (b * 7), splitTitle[c]);
                                        y = y + 3;

                                    }

                                }

                                // this.doc.text(33, y + (b * 10), this.doc.splitTextToSize(result.quotedProducts[b].name + ' ' + result.quotedProducts[b].specification.unit, 38));

                                this.doc.setFontSize(5);
                                // this.doc.text(32, 90 + (b * 10) + y, this.doc.splitTextToSize(quotation.quotedProducts[b].comments, 28));



                            } else {
                                //alert('asd')
                                if (b > 8) {

                                }
                                this.doc.setFontSize(7);
                                // ((total += (Number(result.quotedProducts[b].amount) * result.quotedProducts[b].quantity);
                                this.doc.text(10, 30 + (height * 7) + y, this.doc.splitTextToSize(quotation.quotedProducts[b].id, 18));
                                this.doc.setFontSize(7);

                                // this.doc.text(72, 30 + (height * 7) + y, this.doc.splitTextToSize(quotation.quotedProducts[b].unit.name, 20));
                                if (quotation.quotedProducts[b].unit) {
                                    if (quotation.quotedProducts[b].unit.name) {
                                        this.doc.text(72, 30 + (height * 7) + y, this.doc.splitTextToSize(quotation.quotedProducts[b].unit.name, 20));


                                    }
                                } else {



                                    if (quotation.quotedProducts[b].unitName) {
                                        this.doc.text(72, 30 + (height * 7) + y, this.doc.splitTextToSize(quotation.quotedProducts[b].unitName, 20));


                                    }
                                    //ES del Excel y no trae unidad
                                }

                                this.doc.text(100, 30 + (height * 7) + y, this.doc.splitTextToSize(quotation.quotedProducts[b].quantity.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'), 20));
                                this.doc.text(130, 30 + (height * 7) + y, this.doc.splitTextToSize('$' + this.formatMoney(quotation.quotedProducts[b].amount), 20));
                                let importe = (quotation.quotedProducts[b].amount * quotation.quotedProducts[b].quantity).toFixed(2);
                                this.doc.text(155, 30 + (height * 7) + y, this.doc.splitTextToSize('$ ' + this.formatMoney(importe), 20));
                                this.doc.text(193, 30 + (height * 7) + y, this.doc.splitTextToSize(((quotation.quotedProducts[b].amount * quotation.quotedProducts[b].quantity) * 100 / total).toFixed(2) + '%', 20));

                                for (var i = 0; i < text.length; i++) {

                                    var splitTitle = this.doc.splitTextToSize(text[i], 38);
                                    //loop thru each line and output while increasing the vertical space
                                    console.log(splitTitle, b);
                                    for (var c = 0; c < splitTitle.length; c++) {

                                        this.doc.text(30, 30 + y + (height * 7), splitTitle[c]);
                                        y = y + 3;

                                    }

                                }

                                // this.doc.text(33, y + (b * 10), this.doc.splitTextToSize(result.quotedProducts[b].name + ' ' + result.quotedProducts[b].specification.unit, 38));

                                this.doc.setFontSize(5);
                                //this.doc.text(32, 90 + (b * 10) + y, this.doc.splitTextToSize(quotation.quotedProducts[b].comments, 28));
                                height++;

                            }





                        }

                    }
                    this.doc.setTextColor(169, 169, 169);

                    console.log('TOTAL' + total)
                    this.doc.setFontSize(10);
                    this.doc.setTextColor(0, 128, 0);
                    this.doc.text(150, 240, this.doc.splitTextToSize('$ ' + total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'), 50));
                    this.doc.setFontSize(8);
                    this.doc.setTextColor(169, 169, 169);
                    this.doc.text(150, 250, this.doc.splitTextToSize('IVA 16.00%', 50));
                    this.doc.text(180, 250, this.doc.splitTextToSize('$ ' + (total * .16).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'), 50));
                    this.doc.setFontSize(10);
                    this.doc.setFontType('bold');
                    this.doc.setTextColor(90, 90, 90);
                    this.doc.text(180, 257, this.doc.splitTextToSize('$ ' + (total + (total * .16)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'), 50));
                    if (quotation.deliveryDetails) {
                        this.doc.text(20, 225, 'Detalles:');
                        this.doc.setFontSize(8);

                        //this.doc.text(20, 230, result.deliveryDetails);
                        var splitTitle = this.doc.splitTextToSize(quotation.deliveryDetails, 120);
                        this.doc.text(15, 230, splitTitle);

                    }
                    this.doc.setPage(1)

                    //images here
                    if (this.request.company.profileUrl) {
                        console.log('HAY IMAGENES');
                        console.log(this.request.company.profileUrl);

                        this.getBase64Image(this.request.company.profileUrl, 1, (dataUrl1) => {
                            console.log(dataUrl1, 'cb1');

                            if (this.companyProfile) {
                                console.log(this.companyProfile);

                                this.getBase64Image(this.companyProfile, 2,
                                    (dataUrl2) => {

                                        console.log(dataUrl2, 'cb2');
                                        if (dataUrl1 && dataUrl2) {
                                            this.doc.addImage(dataUrl1, 115, 11, 15, 15);
                                            this.doc.addImage(dataUrl2, 10, 11, 15, 15);
                                        }

                                    });
                            }
                        });
                    }

                }, 500)

            }, 100)


            // Save the PDF
            //this.doc.save('Test.pdf');
            setTimeout(() => {
                var data = this.doc.output('datauri');

                $('#prev').attr('src', data)
            }, 1500)





            // var iframe = "<iframe class='v-w-v-h' width='100%' height='100%' src='" + string + "' type='application/pdf'></iframe>";
            // //  var iframe = "<object data="+string+"type='application/pdf'></object>";
            // this.window.document.write(iframe);
        }
    }
    getBranchName(type: string, id: string, params: any) {
        console.log(id)
        var name;
        this._apiService.getDataById(type, id).subscribe(
            (result: any) => {


                console.log('Branch NAME')
                name = result.name
                this.branch = result.name
                console.log(this.branch);

            },
            error => {
                if (error != null) {
                    console.log(error);
                }
            }
        );
        return name;
    }
    sendQuotation() {
        this.confirmedQuotation.emit(true);

    }
    transformAmount(element, value?) {
        console.log(element.target.value)
        console.log(value);
        if (isNaN(value)) {
            console.log(" is not a number ");
            var newValue = parseFloat(value.replace(/,/g, ''));
            console.log(newValue)

            try {

                // this.formattedAmount = this.currencyPipe.transform(newValue, '', true, '1.2-2');

                element.target.value = this.formattedAmount;
            } catch (e) {
                console.log(e);
            }

        } else {
            console.log(" is a number ");
            try {
                //this.formattedAmount = this.currencyPipe.transform(value, '', true, '1.2-2');

                element.target.value = this.formattedAmount;
            } catch (e) {
                console.log(e);
            }
        }

    }
    getCompanyName(type: string, id: string, params: any) {
        console.log(id)
        var name;
        this._apiService.getDataById(type, id).subscribe(
            (result: any) => {

                console.log('company NAME')

                name = result.companyInfo.name;
                this.companyName = result.name;
                this.companyProfile = result.profileUrl;
                console.log(this.companyProfile)

                this.companyAddress = result.companyInfo.address;
                this.companyPhone = result.companyInfo.phone;
                this.fiscalName = result.companyInfo.fiscalName;

                console.log(result)

            },
            error => {
                if (error != null) {
                    console.log(error);
                }
            }
        );
        return name;
    }
    getBuyerCompanyName(type: string, id: string, params: any) {
        console.log(id)
        var name;
        this._apiService.getDataById(type, id).subscribe(
            (result: any) => {

                console.log('company NAME')
                console.log(result)

                name = result.companyInfo.name;
                this.buyerCompanyName = result.name;
                this.buyerCompanyProfile = result.profileUrl;
                console.log(this.companyProfile)

                this.buyerCompanyAddress = result.companyInfo.address;
                this.buyerCompanyPhone = result.companyInfo.phone;
                this.buyerFiscalName = result.companyInfo.fiscalName;

                console.log(result)

            },
            error => {
                if (error != null) {
                    console.log(error);
                }
            }
        );
        return name;
    }
    getUserName(type: string, id: string, params: any) {
        console.log(id)
        var name;
        this._apiService.getDataById(type, id).subscribe(
            (result: any) => {

                console.log('user NAME')
                name = result.personalInfo.name;
                this.userName = name;
                console.log(result);
                this.userMail = result.email;


            },
            error => {
                if (error != null) {
                    console.log(error);
                }
            }
        );
        return name;

    }
    getUserBuyerName(type: string, id: string, params: any) {
        var name;
        this._apiService.getDataById(type, id).subscribe(
            (result: any) => {
                console.log(result);

                name = result.personalInfo.name;
                this.buyerName = name;
                this.buyerMail = result.email;
                let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
                let filter = {

                    access_token: tokenId
                };

                this.getBuyerCompanyName('Companies', result.companyId, filter);


            },
            error => {
                if (error != null) {
                    console.log(error);
                }
            }
        );
        return name;

    }
}
