import { Component, OnInit } from '@angular/core';
declare var $: any;
import swal from 'sweetalert2'
import { NgxSpinnerService } from 'ngx-spinner';
import { CardlistService } from '../../../services/cardlist.service';
import { ApiService } from '../../../services/apiService';
import { Router, Event, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  selector: 'app-newsQuotations',
  templateUrl: './newsQuotations.component.html',
  styleUrls: ['./newsQuotations.component.css'],
  providers: [ApiService, CardlistService]
})
export class NewsQuotationsComponent implements OnInit {

  public cards: Array<any>;
  public selectedProductImageURL = '';

  public isClassVisible: string;
  public targetaExpres: Array<any>;
  public targetaNueva: Array<any>;
  public rejectedReasons: Array<any>;
  public eldetalle: Array<any>;
  public verid: any;
  public cardView: Boolean;
  public listView: Boolean;
  public expressRequests: Array<any> = [];
  public newRequests: Array<any> = [];
  public cotizacionesExpress: Array<any>;
  public cotizacionesNuevas: Array<any>;
  public requestToReject: any;
  public selectedRequest: any;
  public selectedReason = '';
  paraenviada2 = false;
  paraenviada1 = false;
  porcotizar = false;
  bloque = false;
  paraaceptadas = false;
  nueva = true;
  mobile: any;

  // Rating
  max: number = 5;
  rate: number = 4;
  isReadonly: boolean = true;

  constructor(private location: Location, private _apiService: ApiService, private spinner: NgxSpinnerService, private _cardListService: CardlistService, private _router: Router) {
    this.cardView = true;
    this.listView = false;
    this.isClassVisible = 'false';
    this.verid = 0;
    this.mobile = (window.screen.width);

    this.cards = [
      {
        title: 'title',
        sub: 'sub title',
        date: '09/10/16',
        id: 1231234123
      },
      {
        title: 'title goes here',
        sub: 'sub title',
        date: '09/10/16',
        id: 1231234123
      }
    ];


    this.rejectedReasons = [
      { "id": "0", "razon": "La fecha límite para cotizar es muy cercana", "elemento": "false" },
      { "id": "1", "razon": "La fecha límite para enviar es muy cercana", "elemento": "false" },
      { "id": "2", "razon": "Mi empresa no vende los productos solicitados", "elemento": "false" },
      { "id": "3", "razon": "La cantidad solicitada es muy poca", "elemento": "false" },
      { "id": "4", "razon": "La cantidad solicitada es demasiada", "elemento": "false" },
      { "id": "5", "razon": "La empresa que solicita la cotización no me parece confiable", "elemento": "false" }
    ];

  }
  rejectRequest(request) {
    //this.selectedRequest = request ;
    console.log(request);
    let branchId = '';
    let userRole = localStorage.getItem('$LoopBack$currentUserRole');
    if (userRole === 'Director' || userRole === 'Gerente') {
      if (request.temporalBranch) {
        branchId = request.temporalBranch;

      } else {
        console.log('no venia el temporal branch')
      }

    } else {
      branchId = localStorage.getItem('userBranchId');

    }

    if (request.rejectedBranches) {
      request.rejectedBranches.push(branchId);
      $('#rejectedReasonModal').modal('show')
      this.editRequest(request.id, request, false);
      this.requestToReject = request;

    } else {
      this.requestToReject = request;
      request['rejectedBranches'] = [branchId];
      this.editRequest(request.id, request, false);
      $('#rejectedReasonModal').modal('show')




    }

  }
  filterSubcategory(subcategories, id) {
    if (subcategories) {
      var result = subcategories.filter(obj => {
        return obj.id === id
      });
      if (result) {
        if (result[0]) {
          return result[0].name;

        } else {
          return '';
        }
      } else {
        return '';
      }
    } else {
      return '';
    }

  }
  emitedProducFileURl(event: any) {

    $('#modalProductImageInicio').modal('show');

    console.log('EVENTOOOOO', event);
    this.selectedProductImageURL = event;


  }
  ngOnInit() {



    $('#rejectedReasonModal').on('hidden.bs.modal', () => {

      this.getCotizacionesExpress('Requests/newsQuotations');


    });
    $('#modalProductImageInicio').on('hidden.bs.modal', () => {

      this.selectedProductImageURL = '';


    });
    localStorage.removeItem('provisionalRequest');
    this._router.events.subscribe(
      (value: Event) => {
        if (value instanceof NavigationEnd) {
          // this.getCotizacionesExpress('Requests/newsQuotations');

        }
      });
    this.getCotizacionesExpress('Requests/newsQuotations');
    //this.getCotizacionesNuevas('Requests/quotedRequests');
    /*Pending no borrar*/
    this._cardListService.getView().subscribe((test) => {
      console.log(test);

    });
    //location.reload();

  }
  getCotizacionesExpress(type, id?) {
    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');

    let userId = localStorage.getItem('$LoopBack$currentUserId');

    let params = {

      userId: userId,
      access_token: tokenId
    };
    if (userId && tokenId) {
      this._apiService.getDataObjects(type, userId, params).subscribe(
        (result: any) => {

          this.expressRequests = result.data.express;
          this.newRequests = result.data.news;
          console.log('Nuevas', this.newRequests);
          console.log('Express', this.expressRequests);


        },
        error => {
          let errorMessage = <any>error;
          if (errorMessage != null) {
            console.log(errorMessage);

          }
        }

      );
    }
  }
  getCotizacionesNuevas(type, id?) {

    let userId = localStorage.getItem('$LoopBack$currentUserId');


    this._apiService.getDataObjects(type, userId).subscribe(
      (result: any) => {

        console.log(result);
        this.cotizacionesNuevas = result;




      },
      error => {
        let errorMessage = <any>error;
        if (errorMessage != null) {
          console.log(errorMessage);

        }
      }

    );
  }
  cardViewClick() {
    this.listView = false;
    document.getElementById("card").style["display"] = "block";
    document.getElementById("list").style["display"] = "none";
    setTimeout(() => { this.cardView = true; });


  };
  listViewClick() {
    document.getElementById("list").style["display"] = "block";
    document.getElementById("card").style["display"] = "none";
    setTimeout(() => { this.listView = true; });
    this.cardView = false;
  };


  disables(idClic) {
    this.cleanReasons(idClic);
    //console.log("para la clase1", idClic);
    //  this.verid = idClic;
    //console.log("para la clase2", this.verid);
    this.selectedReason = this.rejectedReasons[idClic].razon;
    console.log(this.selectedReason)
    this.rejectedReasons[idClic].elemento = "true";
    //  console.log("para la clase3", this.rejectedReasons[idClic].elemento);
    this.isClassVisible = 'true';
    console.log("para la clase4", this.isClassVisible);
  };
  showRejectMenu(id) {
    $('#reject' + id).show(300);
    // $('#rechazada' + id).hide(100);
  }
  showRejectMenuExpress(id) {
    $('#rejectExpress' + id).show(300);
    // $('#rechazada' + id).hide(100);
  }
  cancelReject(id) {
    // $('#reject' + id).show(400);
    $('#reject' + id).hide(10);
  }
  ver() {

    $('#ver').show(400);
    $('#regresa').hide(10);
  }
  verRegresa() {
    this.selectedRequest = null;
    $('#regresa').show(400);
    $('#ver').hide(10);
  }

  cleanReasons(id) {
    this.rejectedReasons.forEach((reason, index) => {
      if (index != id) {
        this.rejectedReasons[index].elemento = "false";

      }
    })
  }
  saveRejectedReason() {
    if (this.selectedReason === '') {
      swal(
        'Informacion!',
        'Selecciona una razon.',
        'info'
      )
    } else {

      // $('#rejectedReasonModal').modal('hide')

      console.log(this.requestToReject);
      let branchId;
      let userRole = localStorage.getItem('$LoopBack$currentUserRole');
      let companyId = localStorage.getItem('$LoopBack$currentCompanyId');
      if (userRole === 'Director' || userRole === 'Gerente') {
        if (this.requestToReject.temporalBranch) {
          branchId = this.requestToReject.temporalBranch;

        } else {
          console.log('no venia el temporal branch')
        }

      } else {
        branchId = localStorage.getItem('userBranchId');

      }


      let reason = {
        cause: this.selectedReason,
        requestId: this.requestToReject.id,
        buyerCompanyId: companyId,
        sellerCompanyId: this.requestToReject.companyId,
        sellerBranchId: this.requestToReject.branchId,
        buyerBranchId: branchId,
      }
      console.log(reason)

      let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
      let params = {
        access_token: tokenId
      }
      this._apiService.addDataObject('RejectedRequests', reason).subscribe(
        result => {
          console.log('Insert rejected reason');
          $('#rejectedReasonModal').modal('hide');
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');

          this._router.navigateByUrl('/compras', { skipLocationChange: true }).then(() =>
            this._router.navigate(['/inicio/']));

          setTimeout(() => {
            this.spinner.hide()

            $('#rejectedReasonModal').modal('hide')

            this.selectedReason === ''

          }, 500);
        },
        error => {

          if (error != null) {
            console.log(error);

            setTimeout(() => {

              this.spinner.hide()
              swal(
                'Error!',
                'Error al Guardar.',
                'error'
              )
              this.selectedReason = ''
              $('#rejectedReasonModal').modal('hide')


            }, 250);

          }
        }

      );
    }
  }
  saveRejectedReasonDetail() {
    if (this.selectedReason === '') {
      swal(
        'Informacion!',
        'Selecciona una razon.',
        'info'
      )
    } else {

      // $('#rejectedReasonModal').modal('hide')

      console.log(this.selectedRequest);
      let branchId;
      let userRole = localStorage.getItem('$LoopBack$currentUserRole');
      let companyId = localStorage.getItem('$LoopBack$currentCompanyId');
      if (userRole === 'Director' || userRole === 'Gerente') {
        if (this.selectedRequest.temporalBranch) {
          branchId = this.selectedRequest.temporalBranch;

        } else {
          console.log('no venia el temporal branch')
        }

      } else {
        branchId = localStorage.getItem('userBranchId');

      }


      let reason = {
        cause: this.selectedReason,
        requestId: this.selectedRequest.id,
        buyerCompanyId: companyId,
        sellerCompanyId: this.selectedRequest.companyId,
        sellerBranchId: this.selectedRequest.branchId,
        buyerBranchId: branchId,
      }
      console.log(reason)

      let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
      let params = {
        access_token: tokenId
      }
      this._apiService.addDataObject('RejectedRequests', reason).subscribe(
        result => {
          console.log('Insert rejected reason');
          $('#rejectedReasonModalDetail').modal('hide');
          $('.modal-backdrop').remove();
          $('body').removeClass('modal-open');

          this._router.navigateByUrl('/compras', { skipLocationChange: true }).then(() =>
            this._router.navigate(['/inicio/']));

          setTimeout(() => {
            this.spinner.hide()

            $('#rejectedReasonModalDetauk').modal('hide')

            this.selectedReason === ''

          }, 500);
        },
        error => {

          if (error != null) {
            console.log(error);

            setTimeout(() => {

              this.spinner.hide()
              swal(
                'Error!',
                'Error al Guardar.',
                'error'
              )
              this.selectedReason = ''
              $('#rejectedReasonModalDetail').modal('hide')


            }, 250);

          }
        }

      );
    }
  }

  cancelRejectedReason() {
    if (this.selectedReason = '') {
      swal(
        'Informacion!',
        'Selecciona una razon.',
        'info'
      )
    } else {


      console.log(this.requestToReject);
      let branchId;
      let userRole = localStorage.getItem('$LoopBack$currentUserRole');
      let companyId = localStorage.getItem('$LoopBack$currentCompanyId');
      if (userRole === 'Director' || userRole === 'Gerente') {
        if (this.requestToReject.temporalBranch) {
          branchId = this.requestToReject.temporalBranch;

        } else {
          console.log('no venia el temporal branch')
        }

      } else {
        branchId = localStorage.getItem('userBranchId');

      }


      let reason = {
        cause: this.selectedReason,
        requestId: this.requestToReject.id,
        buyerCompanyId: companyId,
        sellerCompanyId: this.requestToReject.companyId,
        sellerBranchId: this.requestToReject.branchId,
        buyerBranchId: branchId,
      }
      console.log(reason)

      let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
      let params = {
        access_token: tokenId
      }
      this._apiService.addDataObject('RejectedRequests', reason).subscribe(
        result => {
          console.log('Insert rejected reason');

          setTimeout(() => {
            this.spinner.hide()

            $('#rejectedReasonModal').modal('hide')

            this.selectedReason === ''

          }, 500);
        },
        error => {
          if (error != null) {
            console.log(error);
            setTimeout(() => {

              this.spinner.hide()
              swal(
                'Error!',
                'Error al Guardar.',
                'error'
              )
              this.selectedReason = ''
              $('#rejectedReasonModal').modal('hide')


            }, 250);

          }
        }

      );
    }
  }
  editRequest(id: any, request, accepted) {
    //this.spinner.show();

    console.log(request);

    if (request.temporalBranch) {

    }

    console.log(id)

    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
    let params = {
      access_token: tokenId
    }



    console.log(params);
    this._apiService.editDataObject('Requests', id, request, params).subscribe(
      result => {
        console.log(result);
        setTimeout(() => {
          this.spinner.hide();
          if (accepted) {

            swal({
              title: 'Aceptada!',
              text: 'Cotizacion correctamente aceptada.',
              type: 'success',
              confirmButtonClass: 'btn ',

              confirmButtonColor: '#000'
            })
            this._router.navigate(['/porcotizar']);


          } else {

            //this.getCotizacionesExpress('Requests/newsQuotations');

            $('#rejectedReasonModal').modal('show')


          }




        }, 500);


      },
      error => {
        this.spinner.hide();

        if (error != null) {


        }
      }

    );

  }
  verNew(request) {


    if (this.mobile <= 900) {
      swal({
        title: '',
        text: 'Esta acción es posible en nuestra versión de escritorio',
        confirmButtonText: 'OK',
        confirmButtonColor: "#000",

        confirmButtonClass: 'btn '

      }).
        then(function () {
          window.location.href = 'http://google.com'
        })
    } else {



      this.selectedRequest = request;

      let promise = new Promise<void>((resolve, reject) => {
        localStorage.setItem('provisionalRequest', JSON.stringify(this.selectedRequest))
        resolve();

      }).then(() => {
        let branchId = '';
        let userRole = localStorage.getItem('$LoopBack$currentUserRole');
        if (userRole === 'Director' || userRole === 'Gerente') {
          if (request.temporalBranch) {
            branchId = request.temporalBranch;

          } else {
            console.log('no venia el temporal branch')
          }

        } else {
          branchId = localStorage.getItem('userBranchId');

        }
        this.selectedRequest = request;
        console.log(this.selectedRequest);


        $('#ver').show(400);
        $('#regresa').hide(10);
      })
    }
  }
}
