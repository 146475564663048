import { Component, OnInit} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
declare var $ :any;
@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls:['./pages.component.css']
})
export class PagesComponent implements OnInit {

  constructor(private router: Router) {}

  ngOnInit() { }

}
