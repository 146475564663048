import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, NavigationEnd, Event, Router } from '@angular/router';
import swal from 'sweetalert2'
import { ApiService } from '../../../services/apiService';
import { FooterComponent } from '../../../components/footer/footer.component';
declare var $: any;
@Component({
	selector: 'app-sentQuotations',
	templateUrl: './sentQuotations.component.html',
	styleUrls: ['./sentQuotations.component.css'],
	providers: [ApiService]
})

export class SentQuotations implements OnInit {
	public targetaEnviadas: Array<any>;
	public rechazada: Array<any>;
	public cardView: Boolean;
	public listView: Boolean;
	public rejected: Array<any>;
	public sent: Array<any>;
	// Rating
	max: number = 5;
	rate: number = 4;
	isReadonly: boolean = true;
	paraenviada2 = true;
	paraenviada1 = true;
	porcotizar = false;
	bloque = true;
	paranueva = false;
	paraaceptadas = false;

	fueenviada = String;
	sentQuotations = [];
	rejectedByClientQuotations = [];

	mobile: any;
	public selectedQuotation;
	public rejectedQuotation;
	constructor(private _apiService: ApiService, private _router: Router) {
		this.mobile = (window.screen.width);

		this.cardView = true;
		this.listView = false;




	}

	ngOnInit() {
		this._router.events.subscribe(
			(value: Event) => {
				if (value instanceof NavigationEnd) {
				//	this.getQuotations('Requests/sentQuotations');

				}
			});
			this.getQuotations('Requests/sentQuotations');

	}

	cardViewClick() {
		this.listView = false;
		document.getElementById("card").style["display"] = "block";
		document.getElementById("list").style["display"] = "none";
		setTimeout(() => { this.cardView = true; });


	};
	listViewClick() {
		document.getElementById("list").style["display"] = "block";
		document.getElementById("card").style["display"] = "none";
		setTimeout(() => { this.listView = true; });
		this.cardView = false;
	};
	getQuotations(type, id?) {
		let tokenId = localStorage.getItem('$LoopBack$accessTokenId');

		let branchId = localStorage.getItem('userBranchId');
		let userId = localStorage.getItem('$LoopBack$currentUserId');

		let params = {

			userId: userId,
			access_token: tokenId
		};
		this._apiService.getDataObjects(type, branchId, params).subscribe(
			(result: any)=> {


				console.log(result)
				this.rejected = result.data.rejected;
				this.sent = result.data.sent;
				console.log('sent', this.sent);
				console.log('rejected', this.rejected);
      


			},
			error => {
				let errorMessage = <any>error;
				if (errorMessage != null) {
					console.log(errorMessage);

				}
			}

		);
	}



	ver(quotation) {
		console.log(quotation)
		this.selectedQuotation = quotation;

		if (this.mobile <= 900) {
			swal({
				title: '',
				text: 'Esta acción es posible en nuestra versión de escritorio',
				confirmButtonText: 'OK',
				confirmButtonColor: "#000",

				confirmButtonClass: 'btn '

			}).
				then(function () {
					window.location.href = 'http://google.com'
				})
		}
		else {
			//this.fueenviada = fue;

			//$('#detailRejected').show(400);

			$('#ver').show(400);
			$('#regresa').hide(10);
		}
	}

	openRejected(quotation) {
		console.log(quotation)
		this.rejectedQuotation = quotation;


		if (this.mobile <= 900) {
			swal({
				title: '',
				text: 'Esta acción es posible en nuestra versión de escritorio',
				confirmButtonText: 'OK',
				confirmButtonColor: "#000",

				confirmButtonClass: 'btn '

			}).
				then(function () {
					window.location.href = 'http://google.com'
				})
		}
		else {

			$('#detailRejected').show(400);
			$('#regresa').hide(10);
		}
	}

 getReason(quotation) {
    let credit = {
      id: 1,
      value: quotation.creditScore
    }
    let win = {
      id: 2,
      value: (quotation.wins * 100 / quotation.quotedProducts.length).toFixed(0)
    }
    let deli = {
      id: 3,
      value: quotation.deliveryScore
    }
     let array = [credit, win, deli];
   array.sort((a, b) => {
      return a.value - b.value
	})
	
   /*  var lowest = array[0].value;
    var tmp;
    for (var i=array.length-1; i>=0; i--) {
        tmp = array[i].value;
        if (tmp < lowest) lowest = tmp;
		}
		
	console.log(tmp); */
	
    let valor = '';
    switch (array[0].id) {
      case 1: {
        valor = 'Peor credito';
        break;
      }
      case 2: {
        valor = 'Entrega tardia';
        break;
      }
      case 3: {
        valor = 'Alto precio';
        break;
      }
      default: {
        valor = 'Rechazada';
        break;
      }

    }
  return valor ;
  
  }

	Regresa() {
		$('#regresa').show(400);
		$('#ver').hide(10);
	}
	verRegresa() {
		this.selectedQuotation = null;
		this.rejectedQuotation=null;
		$('#regresa').show(400);
		$('#ver').hide(10);
		$('#detailRejected').hide(10);

	}

}
