<div class="container_bg" style="background-image:url(assets/images/2023/bg.png);">
    <div class="container-scroll">
        <div class="pad0">
            <div class="zero" style="background-color:#F0F0F0">
                <div class="collapse_title pad1"> EDITAR INFORMACIÓN DE MI EMPRESA</div>
            </div>
            <div class="container_bg_difuninado zero">
                <div class="row">
                    <div class="" style="width:100%">
                        <div class=" cont_info zero">
                            <ul class="nav nav-pills mb-2 zero" id="pills-tab" role="tablist">
                                <li *ngIf="usuario.role==='Director' || usuario.role==='Gerente' || usuario.role==='Sucursal' || usuario.role==='Personal'"
                                    (click)="rememberingTabs('pills-home')" class="nav-item col zero">
                                    <a class="nav-link active collapse_title pad08" id="pills-home-tab"
                                        data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home"
                                        aria-selected="true">DATOS GENERALES</a>
                                </li>
                                <li *ngIf="usuario.role==='Director' || usuario.role==='Gerente' || usuario.role==='Sucursal' "
                                    (click)="rememberingTabs('pills-profile')" class="nav-item col zero">
                                    <a class="nav-link  collapse_title pad08" id="pills-profile-tab" data-toggle="pill"
                                        href="#pills-profile" role="tab" aria-controls="pills-profile"
                                        aria-selected="false">MIS USUARIOS</a>
                                </li>
                                <li *ngIf="usuario.role==='Director' || usuario.role==='Gerente' || usuario.role==='Sucursal' "
                                    (click)="rememberingTabs('pills-projects')" class="nav-item col zero">
                                    <a class="nav-link collapse_title pad08" id="pills-projects-tab" data-toggle="pill"
                                        href="#pills-projects" role="tab" aria-controls="pills-projects"
                                        aria-selected="false">CENTRO DE COSTO</a>
                                </li>
                                <li *ngIf="usuario.role==='Director' || usuario.role==='Gerente' "
                                    (click)="rememberingTabs('pills-sucursal')" class="nav-item col zero">
                                    <a class="nav-link collapse_title pad08" id="pills-branches-tab" data-toggle="pill"
                                        href="#pills-sucursal" role="tab" aria-controls="pills-projects"
                                        aria-selected="false">SUCURSALES</a>
                                </li>

                                <li *ngIf="usuario.role==='Director' || usuario.role==='Gerente' || usuario.role==='Sucursal' || usuario.role==='Personal'"
                                    (click)="rememberingTabs('pills-notif')" class="nav-item col zero">
                                    <a class="nav-link collapse_title pad08" id="pills-branches-tab" data-toggle="pill"
                                        href="#pills-notif" role="tab" aria-controls="pills-projects"
                                        aria-selected="false">CATEGORIAS</a>
                                </li>
                                <li *ngIf="usuario.role==='Director' || usuario.role==='Gerente' || usuario.role==='Sucursal' || usuario.role==='Personal'"
                                    (click)="rememberingTabs('pills-payment')" class="nav-item col zero">
                                    <a class="nav-link collapse_title pad08" id="pills-branches-tab" data-toggle="pill"
                                        href="#pills-payment" role="tab" aria-controls="pills-payment"
                                        aria-selected="false">SUBSCRIPCION</a>
                                </li>
                            </ul>
                            <div class="tab-content marg-5" id="pills-tabContent">
                                <!--DATOS GENERALES-->

                                <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                                    aria-labelledby="pills-home-tab">
                                    <div *ngIf="!editingCompany" class="row zero bg_blanco  marg-pad-bloque">
                                        <div class="nav-item col-12 col-md-6 pad5-1">
                                            <div class="row">
                                                <div class="col-12 col-md-3 zero">
                                                    <div class="">
                                                        <img *ngIf="!profilePhoto" class="empresa_marca"
                                                            src="../../../assets/images/fakeCompany.png">

                                                        <img *ngIf="profilePhoto" class="empresa_marca"
                                                            src="{{myCompany.profileUrl}}">

                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-5">
                                                    <p>Nombre de la empresa </p>
                                                    <label>{{myCompany.name}}</label>
                                                    <p>Nombre Fiscal</p>
                                                    <label>{{myCompany.companyInfo.fiscalName}}</label>
                                                </div>
                                                <div class="col-12 col-md-4">
                                                    <p>RFC</p>
                                                    <label>{{myCompany.companyInfo.rfc}}</label>
                                                    <p>Dirección</p>
                                                    <label>{{myCompany.companyInfo.address}}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="nav-item col-12 col-md-3" style="padding:0.5em 1em;">

                                            <p>Ciudad</p>
                                            <label>{{myCompany.companyInfo.city}}</label>
                                            <p>CP</p>
                                            <label>{{myCompany.companyInfo.cp}}</label>

                                            <label> {{myCompany.comments}}</label>



                                        </div>
                                        <div class="nav-item col-12 col-md-3" style="padding:0.5em 1em;">
                                            <p>País</p>
                                            <label>{{myCompany.country}}</label>
                                            <p>Estado</p>
                                            <label>{{myCompany.state}}</label>


                                        </div>
                                        <div class="nav-item col-12 col-md-3" style="padding:0.5em 1em;">
                                            <div class="col-12 col-md-3">
                                                <button *ngIf="userRole==='Director'" type="button"
                                                    (click)="enableEditCompany()" class="btn btn-black colorBlanco">
                                                    Editar
                                                </button>

                                            </div>


                                        </div>
                                        <div class="nav-item col-12 col-md-3" style="padding:0.5em 1em;">

                                            <div class="col-12 col-md-3">
                                                <button type="button" (click)="viewNewPasswordForm()"
                                                    class="btn btn-black colorBlanco">
                                                    Editar Contraseña
                                                </button>

                                            </div>

                                        </div>
                                    </div>
                                    <!--         -->
                                    <div *ngIf="changePassword" class="row zero bg_blanco  marg-pad-bloque">
                                        <div class="nav-item col-12 col-md-12 pad5-1">
                                            <div class="row">
                                                <div class="col-12 col-md-3 align-middle">
                                                    <div class="form-group ">
                                                        <div class="col-12">
                                                            <input class="form-control" [(ngModel)]="oldPassword"
                                                                name="old" #old="ngModel" type="password" id="passwordA"
                                                                required>
                                                            <label class="form-control-placeholder"
                                                                for="passwordA">Password Actual</label>

                                                        </div>
                                                        <!--    <div class="form-control-feedback" *ngIf="newUserForm.controls['password'].errors?.required&&newUserForm.controls['password'].touched">
                                                                    Este campo es obligatorio.
                                                                </div> -->

                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-3 align-middle">
                                                    <div class="form-group ">
                                                        <div class="col-12">
                                                            <input [(ngModel)]="newPassword" name="new" #new="ngModel"
                                                                class="form-control" type="password" id="passwordN"
                                                                required>
                                                            <label class="form-control-placeholder" for="passwordN">
                                                                Nuevo Password </label>

                                                        </div>
                                                        <!--  <div class="form-control-feedback" *ngIf="newUserForm.controls['password'].errors?.required&&newUserForm.controls['password'].touched">
                                                                        Este campo es obligatorio.
                                                                    </div> -->

                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-3 align-middle">
                                                    <div class="form-group ">
                                                        <div class="col-12">
                                                            <input [(ngModel)]="confirmedPassword" name="confirm"
                                                                #confirm="ngModel" class="form-control" type="password"
                                                                id="passwordC" required>
                                                            <label class="form-control-placeholder" for="passwordC">
                                                                Confirmar Password </label>

                                                        </div>
                                                        <!--    <div class="form-control-feedback" *ngIf="newUserForm.controls['password'].errors?.required&&newUserForm.controls['password'].touched">
                                                                            Este campo es obligatorio.
                                                                        </div> -->

                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-5">
                                                    <button type="button" (click)="updatePassword()"
                                                        class="btn btn-black colorBlanco">
                                                        Cambiar Contraseña
                                                    </button>

                                                </div>
                                                <div class="col-12 col-md-2">
                                                    <button type="button" (click)="cancelPassword()"
                                                        class="btn btn-black colorBlanco">
                                                        Cancelar
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="editingCompany" class="row zero bg_blanco  marg-pad-bloque">
                                        <div class="nav-item col-12 col-md-12 pad5-1">
                                            <div class="row">
                                                <div class="col-12 col-md-3 zero">
                                                    <div class="">
                                                        <img *ngIf="!profilePhoto" class="empresa_marca_big"
                                                            src="../../../assets/images/fakeCompany.png">

                                                        <img *ngIf="profilePhoto" class="empresa_marca_big"
                                                            src="{{myCompany.profileUrl}}">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-5">
                                                    <input [(ngModel)]="myCompany.name" name="companyName"
                                                        #companyName="ngModel" class="form-control" type="text"
                                                        required>
                                                    <label class="form-control-placeholder" for="name">Nombre
                                                        Comercial</label>

                                                </div>
                                                <div class="col-12 col-md-4">
                                                    <input [(ngModel)]="myCompany.companyInfo.fiscalName"
                                                        name="fiscalName" #fiscalName="ngModel" class="form-control"
                                                        type="text" required>
                                                    <label class="form-control-placeholder" for="sat">Nombre
                                                        Fiscal</label>
                                                </div>
                                                <div class="col-12 col-md-3">
                                                </div>
                                                <div class="col-12 col-md-4">
                                                    <!--  <p>RFC</p> -->
                                                    <input [(ngModel)]="myCompany.companyInfo.rfc" name="rfc"
                                                        #rfc="ngModel" class="form-control" type="text" required>
                                                    <label class="form-control-placeholder" for="rfc">RFC</label>

                                                </div>
                                                <div class="col-12 col-md-5">
                                                    <!-- <p>Dirección</p> -->
                                                    <input [(ngModel)]="myCompany.companyInfo.address" name="address"
                                                        #address="ngModel" class="form-control" type="text" required>
                                                    <label class="form-control-placeholder" for="dire">Direccion</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-3" style=" margin-top:25px;">



                                        </div>
                                        <div class="col-12 col-md-3" style=" margin-top:25px;">
                                            <select disabled [(ngModel)]="myCompany.countryId" class="form-control">
                                                <option value="{{country.id}}"
                                                    *ngFor="let country of countries; let i = index">
                                                    {{country.name}}
                                                </option>
                                            </select>


                                        </div>
                                        <div class="col-12 col-md-3 " style=" margin-top:25px;">
                                            <select [(ngModel)]="myCompany.stateId" class="form-control">
                                                <option value="{{state.id}}"
                                                    *ngFor="let state of states; let i = index">
                                                    {{state.name}}
                                                </option>
                                            </select>

                                        </div>
                                        <div class="col-12 col-md-3" style=" margin-top:25px;">
                                            <!-- <p>Ciudad</p> -->
                                            <input [(ngModel)]="myCompany.companyInfo.cp" name="cp" #cp="ngModel"
                                                class="form-control" type="text" required>
                                            <label class="form-control-placeholder" for="ciudad">CP</label>
                                            <!-- <p>Descripcion de la empresa</p> -->


                                        </div>
                                        <div class="col-12 col-md-3" style=" margin-top:25px;">
                                            <div class="col-12 col-md-4" style=" margin-top:25px;">
                                                <button type="button" (click)="openCropperModal()"
                                                    class="btn btn-black colorBlanco">
                                                    Logo
                                                </button>

                                            </div>


                                        </div>
                                        <div class="col-12 col-md-4" style=" margin-top:25px;">
                                            <!-- <p>Ciudad</p> -->
                                            <input [(ngModel)]="myCompany.companyInfo.city" name="city" #city="ngModel"
                                                class="form-control" type="text" required>
                                            <label class="form-control-placeholder" for="ciudad">Ciudad</label>


                                        </div>

                                        <div class="col-12 col-md-5" style=" margin-top:25px;">
                                            <!-- <p>descri`cion</p> -->
                                            <input [(ngModel)]="myCompany.comments" name="comments" #comments="ngModel"
                                                class="form-control" type="text" required>
                                            <label class="form-control-placeholder"
                                                for="descripcion">Descripcion</label>
                                        </div>
                                        <div class="col-12 col-md-3" style=" margin-top:25px;">

                                            <input name="curriculum" id="curriculum" class="curriculum" type="file"
                                                ng2FileSelect [uploader]="uploaderCompanyFile"
                                                (change)="companyFileListener($event)">
                                            <label class="curriculum2 addons" for="curriculum">CV</label>



                                        </div>

                                        <div class="col-12 col-md-5" style=" margin-top:25px;">



                                        </div>

                                        <div class="nav-item col-12 " style="padding:0.5em 1em; margin-top:25px;">

                                            <div class=" ">
                                                <button *ngIf="userRole==='Director'||userRole==='Gerente'"
                                                    type="button" (click)="editCompany(myCompany)"
                                                    class="btn btn-black colorBlanco">
                                                    Guardar
                                                </button>
                                                <button *ngIf="userRole==='Director'||userRole==='Gerente'"
                                                    type="button" (click)="disableEditCompany()"
                                                    class="btn btn-black colorBlanco">
                                                    Volver
                                                </button>

                                            </div>

                                        </div>

                                    </div>
                                </div>



                                <!-- MIS USUARIOS -->
                                <div class="tab-pane fade" id="pills-profile" role="tabpanel"
                                    aria-labelledby="pills-profile-tab">
                                    <!-- <div class="tab-pane fade show active" role="tabpanel"
                                         aria-labelledby="pills-home-tab"> -->
                                    <div class="zero bg_blanco  marg-pad-bloque">
                                        <div class="background-gray marg6-8">
                                            <form [formGroup]="newUserForm" (ngSubmit)="beforeSaveEditUser()">
                                                <div class="row zero">

                                                    <div class="col-12 col-md-12">
                                                        <div class="collapse_title left pad1"> Crear Cuenta Nueva
                                                        </div>
                                                    </div>
                                                    <div class="row zero" style="width:100% ; height: 100px;"
                                                        style="border:solid #E9E9E9;">

                                                        <div class="col-12 col-md-3 align-middle">
                                                            <div class="form-group "
                                                                [ngClass]="{'has-danger':newUserForm.controls['firstName'].errors?.required &&newUserForm.controls['firstName'].touched}">
                                                                <div class="col-12">
                                                                    <input formControlName="firstName"
                                                                        class="form-control" type="text" id="firstName"
                                                                        name="name" required>
                                                                    <label class="form-control-placeholder"
                                                                        for="firstName">Nombre</label>
                                                                </div>
                                                                <div class="form-control-feedback"
                                                                    *ngIf="newUserForm.controls['firstName'].errors?.required&&newUserForm.controls['firstName'].touched">
                                                                    Este campo es obligatorio.
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-3 align-middle">
                                                            <div class="form-group "
                                                                [ngClass]="{'has-danger':newUserForm.controls['lastName'].errors?.required &&newUserForm.controls['lastName'].touched}">
                                                                <div class="col-12">
                                                                    <input formControlName="lastName"
                                                                        class="form-control" type="text" id="lastName"
                                                                        required>
                                                                    <label class="form-control-placeholder"
                                                                        for="lastName">Apellidos</label>


                                                                </div>
                                                                <div class="form-control-feedback"
                                                                    *ngIf="newUserForm.controls['lastName'].errors?.required&&newUserForm.controls['lastName'].touched">
                                                                    Este campo es obligatorio.
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-3 align-middle">
                                                            <div class="form-group "
                                                                [ngClass]="{'has-danger':newUserForm.controls['email'].errors?.required &&newUserForm.controls['email'].touched}">
                                                                <div class="col-12">
                                                                    <input formControlName="email" id="email"
                                                                        class="form-control" type="email" required>


                                                                    <label class="form-control-placeholder"
                                                                        for="email">Email</label>

                                                                </div>
                                                                <div class="form-control-feedback"
                                                                    *ngIf="newUserForm.controls['email'].errors?.required&&newUserForm.controls['email'].touched">
                                                                    Este campo es obligatorio.
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-3 align-middle">
                                                            <div *ngIf="!editingUser" class="form-group "
                                                                [ngClass]="{'has-danger':newUserForm.controls['password'].errors?.required &&newUserForm.controls['password'].touched}">
                                                                <div class="col-12">
                                                                    <input formControlName="password"
                                                                        class="form-control" type="password"
                                                                        id="password" required>
                                                                    <label class="form-control-placeholder"
                                                                        for="password">Password</label>

                                                                </div>
                                                                <div class="form-control-feedback"
                                                                    *ngIf="newUserForm.controls['password'].errors?.required&&newUserForm.controls['password'].touched">
                                                                    Este campo es obligatorio.
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-3 ">

                                                            <div class="form-group "
                                                                [ngClass]="{'has-danger':newUserForm.controls['role'].errors?.required &&newUserForm.controls['role'].touched}">
                                                                <div class="col-12">
                                                                    <select style="z-index:999999"
                                                                        formControlName="role" class="input2 pad6"
                                                                        required>
                                                                        <option value="" hidden selected default>
                                                                            Selecciona un rol de usuario:</option>
                                                                        <option
                                                                            *ngFor="let cargo of cargos ; let idx = index"
                                                                            [value]="cargo"> {{cargo}}
                                                                        </option>

                                                                    </select>

                                                                </div>
                                                                <div class="form-control-feedback"
                                                                    *ngIf="newUserForm.controls['role'].errors?.required&&newUserForm.controls['role'].touched">
                                                                    Este campo es obligatorio.
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div *ngIf="selectSucursalView" class="col-12 col-md-3 ">

                                                            <div class="form-group">
                                                                <div class="col-12">
                                                                    <select style="z-index:999999"
                                                                        formControlName="branch" class="input2"
                                                                        required>
                                                                        <option hidden selected default> Selecciona una
                                                                            sucursal:</option>
                                                                        <option
                                                                            *ngFor="let branch of branches ; let idx = index"
                                                                            [value]="branch.id"> {{branch.name}}
                                                                        </option>

                                                                    </select>

                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-2">

                                                            <input (change)="selectedVentas($event)"
                                                                [checked]="ventasCheck" type="checkbox" id="ventas"
                                                                class="filled-in chk-col-grey" />
                                                            <label class="fix-left" for="ventas">Ventas</label>
                                                            <input (change)="selectedCompras($event)"
                                                                [checked]="comprasCheck" type="checkbox" id="compras"
                                                                class="filled-in chk-col-grey" />
                                                            <label class="fix-left checkbox-index"
                                                                for="compras">Compras</label>
                                                                <input (change)="selectedTecnico($event)"
                                                                [checked]="tecnicoCheck" type="checkbox" id="tecnico"
                                                                class="filled-in chk-col-grey" />
                                                            <label class="fix-left checkbox-index"
                                                                for="tecnico">Técnico de compras</label>

                                                        </div>
                                                        <div *ngIf="permissionView" class="col-12 col-md-2">

                                                            <input (change)="selectedCanSell($event)"
                                                                [checked]="canSellCheck" type="checkbox" id="canSell"
                                                                class="filled-in chk-col-grey" />
                                                            <label class="fix-left" for="canSell">Permiso Ventas</label>
                                                            <input (change)="selectedCanBuy($event)"
                                                                [checked]="canBuyCheck" type="checkbox" id="canBuy"
                                                                class="filled-in chk-col-grey" />
                                                            <label class="fix-left checkbox-index" for="canBuy">Permiso
                                                                Compras</label>

                                                        </div>
                                                    </div>
                                                    <br>
                                                    <!--   <pre>{{newUserForm.get('firstName').value}}</pre>
                                                        <pre>{{newUserForm.get('lastName').value}}</pre>
                                                        <pre>{{newUserForm.get('email').value}}</pre>
                                                        <pre>{{newUserForm.get('password').value}}</pre>
                                                        <pre>{{newUserForm.get('role').value}}</pre>
                                                        <pre>{{newUserForm.get('branch').value}}</pre> -->


                                                    <div class="row zero  pad08" style="width:100% ; margin-top:30px;">
                                                        <div class="col-4 col-md-4"></div>

                                                        <div *ngIf="editingUser" class="col-12 col-md-4 pad08">
                                                            <button (click)="cancelEditUser()"
                                                                class="btn btn-grey colorBlanco">
                                                                Cancelar
                                                            </button>
                                                            <button type="submit" class="btn btn-black colorBlanco">
                                                                Actualizar
                                                            </button>
                                                            <!--   <button  *ngIf="selectSucursalView" [disabled]="!newUserForm.valid" type="submit" class="btn btn-black colorBlanco">
                                                                Actualizar
                                                            </button> -->
                                                        </div>

                                                        <div *ngIf="!editingUser" class="col-12 col-md-4 pad08">

                                                            <button [disabled]="!newUserForm.valid" type="submit"
                                                                class="btn btn-black colorBlanco">
                                                                Guardar
                                                            </button>
                                                        </div>

                                                        <div class="col-12 col-md-4"></div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <!--   endform -->
                                        <!--   List Users -->
                                        <div class="row zero">
                                            <div class="col-12 col-md-6 zero top2">
                                                <div class="collapse_title padding-01"> Gerente Ventas
                                                </div>

                                                <div *ngFor="let user of managers_sales" class="row zero">

                                                    <div class="col-8 col-md-6">
                                                        <label>{{user.personalInfo.name}}</label>
                                                    </div>
                                                    <div class="col-8 col-md-4">
                                                        <label>{{user.email}} </label>
                                                    </div>
                                                    <div class="col-4 col-md-2 action-buttons">
                                                        <button (click)="onBeforeEditUser(user)"
                                                            class="btn btn-sm custom-action-btn font1">
                                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                                        </button>
                                                        <button (click)="onDeleteUserConfirm(user.id)"
                                                            class="btn btn-sm custom-action-btn font1">
                                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                        </button>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 zero top2">
                                                <div class="collapse_title padding-01"> Gerente Compras
                                                </div>

                                                <div class="row zero" *ngFor="let user of managers_purchases">

                                                    <div class="col-8 col-md-6">
                                                        <label>{{user.personalInfo.name}}</label>
                                                    </div>
                                                    <div class="col-8 col-md-4">
                                                        <label>{{user.email}} </label>
                                                    </div>
                                                    <div class="col-4 col-md-2 action-buttons">
                                                        <button (click)="onBeforeEditUser(user)"
                                                            class="btn btn-sm custom-action-btn font1">
                                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                                        </button>
                                                        <button (click)="onDeleteUserConfirm(user.id)"
                                                            class="btn btn-sm custom-action-btn font1">
                                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                        </button>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                        <div class="row zero">
                                            <div class="col-12 col-md-6 zero top2">
                                                <div class="collapse_title padding-01"> Gerente Sucursal Ventas
                                                </div>

                                                <div class="row zero" *ngFor="let user of sucursalManagers_sales">
                                                    <div class="col-8 col-md-6">
                                                        <label>{{user.personalInfo.name}}</label>
                                                    </div>
                                                    <div class="col-8 col-md-4">
                                                        <label>{{user.email}}</label>
                                                    </div>
                                                    <div class="col-4 col-md-2 action-buttons">
                                                        <button (click)="onBeforeEditUser(user)"
                                                            class="btn btn-xs custom-action-btn font1">
                                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                                        </button>
                                                        <button (click)="onDeleteUserConfirm(user.id)"
                                                            class="btn btn-xs custom-action-btn font1">
                                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 zero top2">
                                                <div class="collapse_title padding-01"> Gerente Sucursal Compras
                                                </div>

                                                <div class="row zero" *ngFor="let user of sucursalManagers_purchases">
                                                    <div class="col-8 col-md-6">
                                                        <label>{{user.personalInfo.name}}</label>
                                                    </div>
                                                    <div class="col-8 col-md-4">
                                                        <label>{{user.email}} </label>
                                                    </div>
                                                    <div class="col-4 col-md-2 action-buttons">
                                                        <button (click)="onBeforeEditUser(user)"
                                                            class="btn btn-sm custom-action-btn font1">
                                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                                        </button>
                                                        <button (click)="onDeleteUserConfirm(user.id)"
                                                            class="btn btn-sm custom-action-btn font1">
                                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row padding-01">
                                            <div class="col-12 col-md-6 zero top2">
                                                <div class="collapse_title padding-01"> Personal Ventas
                                                </div>

                                                <div class="row zero" *ngFor="let user of personal_sales">
                                                    <div class="col-8 col-md-6">
                                                        <label>{{user.personalInfo.name}}</label>
                                                    </div>
                                                    <div class="col-8 col-md-4">
                                                        <label>{{user.email}}</label>
                                                    </div>
                                                    <div class="col-4 col-md-2 action-buttons">
                                                        <button (click)="onBeforeEditUser(user)"
                                                            class="btn btn-xs custom-action-btn font1">
                                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                                        </button>
                                                        <button (click)="onDeleteUserConfirm(user.id)"
                                                            class="btn btn-xs custom-action-btn font1">
                                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 zero top2">
                                                <div class="collapse_title padding-01"> Personal Compras
                                                </div>

                                                <div class="row zero" *ngFor="let user of personal_purchases">
                                                    <div class="col-8 col-md-6">
                                                        <label>{{user.personalInfo.name}}</label>
                                                    </div>
                                                    <div class="col-8 col-md-4">
                                                        <label>{{user.email}} </label>
                                                    </div>
                                                    <div class="col-4 col-md-2 action-buttons">
                                                        <button (click)="onBeforeEditUser(user)"
                                                            class="btn btn-xs custom-action-btn font1">
                                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                                        </button>
                                                        <button (click)="onDeleteUserConfirm(user.id)"
                                                            class="btn btn-xs custom-action-btn font1">
                                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 zero top2">
                                                <div class="collapse_title padding-01"> Técnico Compras
                                                </div>

                                                <div class="row zero" *ngFor="let user of tecnician_purchases">
                                                    <div class="col-8 col-md-6">
                                                        <label>{{user.personalInfo.name}}</label>
                                                    </div>
                                                    <div class="col-8 col-md-4">
                                                        <label>{{user.email}} </label>
                                                    </div>
                                                    <div class="col-4 col-md-2 action-buttons">
                                                        <button (click)="onBeforeEditUser(user)"
                                                            class="btn btn-xs custom-action-btn font1">
                                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                                        </button>
                                                        <button (click)="onDeleteUserConfirm(user.id)"
                                                            class="btn btn-xs custom-action-btn font1">
                                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                     
                                     
                                    </div>
                                    <!-- </div> -->
                                    
                                </div>
                                <!--TAB PROJECTS-->
                                <div class="tab-pane fade" id="pills-projects" role="tabpanel"
                                    aria-labelledby="pills-projects-tab">
                                    <!-- <div class="tab-pane fade show active" id="pills-project" role="tabpanel"
                                         aria-labelledby="pills-projects-tab"> -->
                                    <div class="zero bg_blanco  marg-pad-bloque">
                                        <div class="background-gray marg6-8">
                                            <form [formGroup]="newProjectForm" (ngSubmit)="beforeSaveEditProject()">
                                                <div class="row zero">
                                                    <div class="col-12 col-md-12">
                                                        <div class="collapse_title left pad1">
                                                            Agregar Centro de Costo
                                                        </div>
                                                    </div>
                                                    <div class="row zero" style="width:100%">


                                                        <div class="col-12 col-md-4">

                                                            <div class="form-group "
                                                                [ngClass]="{'has-danger':newProjectForm.controls['projectName'].errors?.required &&newProjectForm.controls['projectName'].touched}">
                                                                <div class="col-12">

                                                                    <input formControlName="projectName"
                                                                        class="form-control" type="text"
                                                                        id="projectName" required>
                                                                    <label class="form-control-placeholder"
                                                                        for="projectName">Nombre</label>

                                                                </div>
                                                                <div class="form-control-feedback"
                                                                    *ngIf="newProjectForm.controls['projectName'].errors?.required&&newProjectForm.controls['projectName'].touched">
                                                                    Este campo es obligatorio.
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div class="col-12 col-md-4">


                                                            <div class="form-group "
                                                                [ngClass]="{'has-danger':newProjectForm.controls['project_address'].errors?.required &&newProjectForm.controls['project_address'].touched}">
                                                                <div class="col-12">

                                                                    <input placeholder="" #addresstext
                                                                        formControlName="project_address"
                                                                        (onSelect)="setAddressProject($event)"
                                                                        google-place class="form-control" type="text"
                                                                        id="project_address" required>
                                                                    <label class="form-control-placeholder"
                                                                        for="project_address">Dirección</label>

                                                                </div>
                                                                <div class="form-control-feedback"
                                                                    *ngIf="newProjectForm.controls['project_address'].errors?.required&&newProjectForm.controls['project_address'].touched">
                                                                    Este campo es obligatorio.
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-4">
                                                            <div id="map" #gmapProject style="width:100%; height:200px">
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="row zero  pad08" style="width:100%">
                                                        <div class="col-12 col-md-4"></div>
                                                        <div *ngIf="editingProject"
                                                            class="col-4 col-sm-4 col-md-4 pad08">
                                                            <button (click)="cancelEditProject()"
                                                                class="btn btn-grey colorBlanco">
                                                                Cancelar
                                                            </button>
                                                            <button [disabled]="!newProjectForm.valid" type="submit"
                                                                class="btn btn-black colorBlanco">
                                                                Actualizar
                                                            </button>
                                                        </div>

                                                        <div *ngIf="!editingProject"
                                                            class="col-4 col-sm-4 col-md-4 pad08">

                                                            <button [disabled]="!newProjectForm.valid" type="submit"
                                                                class="btn btn-black colorBlanco">
                                                                Guardar
                                                            </button>
                                                        </div>


                                                        <div class="col-12 col-md-4"></div>
                                                    </div>
                                                </div>
                                            </form>


                                        </div>
                                        <div class="row padding-01">
                                            <div class="col-12 col-md-12 zero top2">
                                                <div class="collapse_title padding-01"> Centro de costo Agregados
                                                </div>
                                                <div class="row zero" *ngFor="let project of projects">
                                                    <div class="col-8 col-md-6">
                                                        <label>{{project.name}}</label>
                                                    </div>
                                                    <div class="col-8 col-md-4">
                                                        <label> {{project.request}}</label>
                                                    </div>
                                                    <div class="col-4 col-md-2">
                                                        <button (click)="onBeforeEditProject(project)"
                                                            class="btn btn-sm custom-action-btn font1">
                                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                                        </button>
                                                        <button (click)="onDeleteProjectConfirm(project.id)"
                                                            class="btn btn-sm custom-action-btn font1">
                                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <!-- </div> -->
                                </div>
                                <!--TAB SUCURSAL-->
                                <div class="tab-pane fade" id="pills-sucursal" role="tabpanel"
                                    aria-labelledby="pills-branches-tab">
                                    <!-- <div class="tab-pane fade show active" id="pills-project" role="tabpanel"
                                        *ngIf="userRole==='Director'||userRole==='Gerente'"
                                             aria-labelledby="pills-projects-tab"> -->
                                    <div class="zero bg_blanco  marg-pad-bloque">
                                        <div class="background-gray marg6-8">
                                            <form [formGroup]="newSucursalForm" (ngSubmit)="beforeSaveEditBranch()">


                                                <div class="row zero">
                                                    <div class="col-12 col-md-12">
                                                        <div *ngIf="userRole==='Director'||userRole==='Gerente'"
                                                            class="collapse_title left pad1">
                                                            Nueva Sucursal
                                                            <p class="right"> Configura radio de accion</p>
                                                        </div>
                                                        <div *ngIf="userRole==='Sucursal'||userRole==='Personal'"
                                                            class="collapse_title left pad1">
                                                            Nueva Sucursal
                                                            <p class="right"> Configura radio de accion</p>
                                                        </div>

                                                    </div>
                                                    <div class="row zero" style="width:100%">
                                                        <div class="col-12 col-md-3">

                                                            <div class="form-group "
                                                                [ngClass]="{'has-danger':newSucursalForm.controls['suc_name'].errors?.required &&newSucursalForm.controls['suc_name'].touched}">
                                                                <div class="col-12">

                                                                    <input formControlName="suc_name"
                                                                        class="form-control" type="text" id="suc_name"
                                                                        required>
                                                                    <label class="form-control-placeholder"
                                                                        for="suc_name">Nombre sucursal</label>

                                                                </div>
                                                                <div class="form-control-feedback"
                                                                    *ngIf="newSucursalForm.controls['suc_name'].errors?.required&&newSucursalForm.controls['suc_name'].touched">
                                                                    Este campo es obligatorio.
                                                                </div>

                                                            </div>
                                                            <div class="form-group "
                                                                [ngClass]="{'has-danger':newSucursalForm.controls['sales_manager'].errors?.required &&newSucursalForm.controls['sales_manager'].touched}">
                                                                <div class="col-12">
                                                                    <select
                                                                        (change)="onChangeGeneralPurchases($event.target.value)"
                                                                        formControlName="sales_manager" class="input2"
                                                                        required>
                                                                        <option value="" hidden selected default>
                                                                            Selecciona gerente compras :</option>

                                                                        <option
                                                                            *ngFor="let manager of managers_purchases ; let idx = index"
                                                                            [value]="manager.id">
                                                                            {{manager.personalInfo.name}}</option>

                                                                    </select>

                                                                </div>
                                                                <div class="form-control-feedback"
                                                                    *ngIf="newSucursalForm.controls['sales_manager'].errors?.required&&newSucursalForm.controls['sales_manager'].touched">
                                                                    Este campo es obligatorio.</div>

                                                            </div>
                                                            <div class="form-group "
                                                                [ngClass]="{'has-danger':newSucursalForm.controls['purchases_manager'].errors?.required &&newSucursalForm.controls['purchases_manager'].touched}">
                                                                <div class="col-12">
                                                                    <select
                                                                        (change)="onChangeGeneralSales($event.target.value)"
                                                                        formControlName="purchases_manager"
                                                                        class="input2" required>
                                                                        <option value="" hidden selected default>
                                                                            Selecciona gerente ventas:</option>

                                                                        <option
                                                                            *ngFor="let manager of managers_sales ; let idx = index"
                                                                            [value]="manager.id">
                                                                            {{manager.personalInfo.name}}</option>

                                                                    </select>

                                                                </div>
                                                                <div class="form-control-feedback"
                                                                    *ngIf="newSucursalForm.controls['purchases_manager'].errors?.required&&newSucursalForm.controls['purchases_manager'].touched">
                                                                    Este campo es obligatorio.</div>

                                                            </div>


                                                        </div>

                                                        <div class="col-12 col-md-4">

                                                            <div class="form-group "
                                                                [ngClass]="{'has-danger':newSucursalForm.controls['suc_address'].errors?.required &&newSucursalForm.controls['suc_address'].touched}">
                                                                <div class="col-12">
                                                                    <!--  <input
                                                                    google-place
                                                                    type="text"
                                                                    class="google-place-input"
                                                                    (onSelect)="setAddress($event)"
                                                                    placeholder="Type to search.."> -->
                                                                    <input placeholder="" formControlName="suc_address"
                                                                        (onSelect)="setAddress($event)" google-place
                                                                        class="form-control" type="text"
                                                                        id="suc_address" required>
                                                                    <label class="form-control-placeholder"
                                                                        for="suc_address">Dirección</label>

                                                                </div>
                                                                <div class="form-control-feedback"
                                                                    *ngIf="newSucursalForm.controls['suc_address'].errors?.required&&newSucursalForm.controls['suc_address'].touched">
                                                                    Este campo es obligatorio.
                                                                </div>

                                                            </div>
                                                            <div class="margin">
                                                                <div class="form-group "
                                                                    [ngClass]="{'has-danger':newSucursalForm.controls['suc_radio'].errors?.required &&newSucursalForm.controls['suc_radio'].touched}">
                                                                    <div class="col-12">

                                                                        <input formControlName="suc_radio"
                                                                            class="form-control" type="text"
                                                                            id="suc_radio" required>
                                                                        <label class="form-control-placeholder"
                                                                            for="suc_radio">Radio en KM</label>

                                                                    </div>
                                                                    <div class="form-control-feedback"
                                                                        *ngIf="newSucursalForm.controls['suc_radio'].errors?.required&&newSucursalForm.controls['suc_radio'].touched">
                                                                        Este campo es obligatorio.
                                                                    </div>

                                                                </div>


                                                            </div>
                                                            <div class="margin">
                                                                <div class="col-12 col-md-12">

                                                                    <input (change)="selectedAllCountry($event)"
                                                                        [checked]="allCountry" type="checkbox"
                                                                        id="all_country"
                                                                        class="filled-in chk-col-grey" />
                                                                    <label class="fix-left checkbox-index"
                                                                        for="all_country">Todo el paìs</label>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-5">
                                                            <div id="map" #gmap style="width:100%; height:200px"></div>

                                                        </div>
                                                    </div>
                                                    <div class="row zero  pad08" style="width:100%">
                                                        <div class="col-12 col-md-4"></div>

                                                        <div *ngIf="editingBranch" class="col-12 col-md-4 pad08">
                                                            <button (click)="cancelEditBranch()"
                                                                class="btn btn-grey colorBlanco">
                                                                Cancelar
                                                            </button>
                                                            <button [disabled]="!newSucursalForm.valid" type="submit"
                                                                class="btn btn-black colorBlanco">
                                                                Actualizar
                                                            </button>
                                                        </div>

                                                        <div *ngIf="!editingBranch && userRole==='Director'"
                                                            class="col-12 col-md-4 pad08">

                                                            <button [disabled]="!newSucursalForm.valid" type="submit"
                                                                class="btn btn-black colorBlanco">
                                                                Guardar
                                                            </button>
                                                        </div>


                                                        <div class="col-4 col-sm-4 col-md-4"></div>
                                                    </div>
                                                </div>


                                            </form>
                                        </div>

                                        <div class="row padding-01">
                                            <div *ngIf="branches" class="col-12 col-md-12zero top2">
                                                <div class="collapse_title padding-01"> Sucursales
                                                </div>

                                                <div class="row zero" *ngFor="let branch of branches">
                                                    <div class="col-8 col-md-2">
                                                        <label>{{branch.name}}</label>
                                                    </div>
                                                    <div class="col-8 col-md-8">
                                                        <label> {{branch.address}}</label>
                                                    </div>
                                                    <div class="col-4 col-md-2">
                                                        <button (click)="onBeforeEditBranch(branch)"
                                                            class="btn btn-xs custom-action-btn font1">
                                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                                        </button>
                                                        <button (click)="onDeleteBranchConfirm(branch.id)"
                                                            class="btn btn-xs custom-action-btn font1">
                                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="!branches" class="col-12 col-md-12 zero top2">
                                                <h3>No se han creado Sucursales</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- </div> -->
                                </div>
                                <!--TAB PROJECTS-->
                                <div class="tab-pane fade" id="pills-notif" role="tabpanel"
                                    aria-labelledby="pills-projects-tab">

                                    <div class="zero bg_blanco  marg-pad-bloque">
                                        <div class="background-gray marg6-8">
                                            <app-ultimate-select class="fullWidth" [config]="{
                                                       buttonIcon: 'texture',
                                                       buttonTitle: 'Categorias',
                                                       inputPlaceholder: 'Buscar ...',
                                                       showProperty: 'name',
                                                       searchModel: 'Categories',
                                                       searchProperties: ['name'],
                                                       customFilterProperties: [],
                                                       customFilterValues: [],
                                                       includeObject: [],
                                                       hasSuffix: false,
                                                       suffixType: '',
                                                       sorter: '',
                                                       returnItself: false,
                                                       returnProperty: ''
                                                    }" (filterContent)="processCategorization($event)" #productsFilter>
                                            </app-ultimate-select>

                                        </div>

                                    </div>
                                </div>
                                <!--TAB PAYMENTS-->
                                <div class="tab-pane fade" id="pills-payment" role="tabpanel"
                                    aria-labelledby="pills-payment-tab">

                                    <div class="zero bg_blanco  marg-pad-bloque">
                                        <div class="background-gray marg6-8">
                                            <div class="container" style="background-color: #F0F0F0;">
                                                <div class="row" style="background-color: #F0F0F0; padding: 25px;">
                                                    <h1>Datos de pago</h1>
                                                    <div class="col-6">
                                                        <select  [(ngModel)]="paymentType" (ngModelChange)='onOptionsSelected($event)' class="form-control">
                                                            <option value="month">
                                                                Mes
                                                            </option>
                                                            <option value="year">
                                                                Año
                                                            </option>
                                                        </select>
                                                        <!--    <form novalidate (ngSubmit)="createToken()" [formGroup]="stripeTest">
                                                            <input type="text" formControlName="name" placeholder="" style="display: none;" >
                                                            <ngx-stripe-card
                                                              [options]="cardOptions"
                                                              [elementsOptions]="elementsOptions"
                                                            ></ngx-stripe-card>
                                                            <button  class="btn btn-black colorBlanco" type="submit">
                                                              Pagar
                                                            </button>
                                                          </form> -->
                                                    </div>
                                                    <div class="col-6">
                                                        <button class="btn btn-black colorBlanco"
                                                            (click)="goToCheckout()"
                                                            id="checkout-button">Checkout</button>

                                                    </div>
                                                </div>
                                            </div>

                                            <!--         Pay:
                                               
                                                <ngx-payment-request [options]="requestOptions"
                                                    [elementsOptions]="elementsOptions" [styles]="styles"
                                                    (shippingAddressChange)="updateShippingAddress($event)"
                                                    (shippingOptionChange)="updateShippingOption($event)"
                                                    (change)="requestUpdated($event)">
                                                </ngx-payment-request> -->
                                            <!--                                          <ngx-stripe-card [options]="cardOptions" [elementsOptions]="elementsOptions"
                                                (change)="cardUpdated($event)" (error)="error = $event">
                                            </ngx-stripe-card>
                                            <div class="error">
                                                {{error?.message}}
                                            </div>
                                            <button (click)="getCardToken()" [disabled]="!complete">Get Card
                                                Token</button> -->

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- cropper modal content -->
<div class="modal fade bs-example-modal-lg" id="cropperModal" tabindex="-1" role="dialog"
    aria-labelledby="myLargeModalLabel" aria-hidden="true" style="display: none;">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h4 style="text-align:center;" class="modal-title" id="myLargeModalLabel">Ajustar Imagen</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">


                <img-cropper #cropper [image]="data" [settings]="cropperSettings"></img-cropper>
                <br>


            </div>
            <div class="modal-footer">
                <button click="uploadImage()" type="button" class="btn btn-black waves-effect text-left "
                    data-dismiss="modal">Guardar</button>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<!-- /.modal -->