<!-- -->
<!--targetVER COTIZACIÓN BORRADOR Y POR ACEPTAR para editar-------------------------->
<div class="row zero target" id="verChat">
	<div class="width zero">
		<div class="row zero confirmar background-gray">
			<div class="col-12 col-md-2 zero collapse_title goBack" (click)="goBackConcreted()">
				<i class="fa fa-chevron-left" aria-hidden="true"></i> Regresar</div>

	
			<div class="col-2 col-md-2 zero remove"></div>
			<div class="col-4 col-md-4 zero remove collapse_title font-8 right"> CONCRETAR COMPRA
				<span class="strong gray " *ngIf="selectedQuotation">{{selectedQuotation.purchasesOrders[0].company.name}} </span>
				<span *ngIf="selectedQuotation.purchasesOrders[0].company" class="collapse_title_bold">
					<rating *ngIf="!selectedQuotation.purchasesOrders[0].company.rating" class="remove" [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating>
					<rating  *ngIf="selectedQuotation.purchasesOrders[0].company.rating"  class="remove" [(ngModel)]="selectedQuotation.purchasesOrders[0].company.rating" [max]="max" [readonly]="isReadonly"></rating>

				</span>
			</div>
			<div class="col-1 col-md-1 zero remove"></div>

			<div class="col-0 col-md-3 zero">
				<p class="folio-title remove text1" *ngIf="selectedQuotation"> Folio :{{selectedQuotation.id}} </p>
			</div>
		</div>
		<div class="container_bg_difuninado zero">
			<app-chat [selectedQuotation]="selectedQuotation" [indexPurchase]="concretadaIndexSelected" *ngIf="chatComponent" [ventas]="false"></app-chat>
		</div>
	</div>
</div>

<div id="purchasesOrders">


	<div *ngFor="let compra of concretada.purchasesOrders;let i =index" class="row">
		<div class="padding-2">
			<div class=" cont_info zero">

				<div class="tab-content" id="pills-tabContent">
					<div class="row background-white padding-top1">
						<p class="text center strong black margin2-l-r">
							Listo, realizaste una compra inteligente exitosamente.Ahora depende de ustedes establecer una buena comunicación para fijar
							formas de pago y envío.
						</p>
					</div>
					<div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
						<!---->
						<div class="row background-white">
							<div class="col-12 col-md-4 text center">
								<!-- logito de garantia -->
								<div class="row">
									<div class="col-0 col-md-4 "></div>
									<div class="col-12 col-md-5 zero garantia garanty" style="background-image:url(assets/images/gallery/marcas/garantia.png);">
										<div class="galery-numbrer">{{compra.company.ratingIxcco}}</div>
										<div class="galery-text">RECOMENDACIÓN</div>
										<div class="galery-logo">IXCCO</div>
									</div>
									<div class="col-0 col-md-3 "></div>
								</div>
								Cotización para
								<strong *ngIf="concretada">{{compra.sellProducts.length}}</strong> productos
							</div>
							<div class="col-12 col-md-4 black margin1">
								<div class="row width padding-top1">

									<div class=" col-3 col-md-12 zero ">
										<img *ngIf="compra.company.profileUrl" class="logo-brand-img-2" [src]="compra.company.profileUrl">
										<img *ngIf="!compra.company.profileUrl" class="logo-brand-img-2" src="../../../assets/images/fakeCompany.png">
									</div>

									<div class=" col-9 col-md-12 zero">
										<div class="text center-left">
											{{compra.company.name}}</div>
										<div  *ngIf="compra.company" class="text center-left">
											<rating *ngIf="!compra.company.rating" [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating>
										</div>
										<div  *ngIf="compra.company" class="text center-left">
											<rating *ngIf="compra.company.rating" [(ngModel)]="compra.company.rating" [max]="max" [readonly]="isReadonly"></rating>
										</div>
										<div class="text center-left">{{compra.company.companyInfo.city}}, México</div>
									</div>
									<!-- <div class="col-6 col-md-12 zero text center-left" 
								  					data-backdrop="false" data-toggle="modal" data-target="#exampleModalCenter"
													  >Ver las <strong class="strong">883</strong> reseñas 
												 </div>
								  				<div class="col-6 col-md-12 text center-left">www.cemex.com</div> -->
								</div>
							</div>
							<div *ngIf="concretada" class="col-12 col-md-4 text center margin1 padding-6">

								<div class="row zero margin1">
									<div class="col-1 col-md-1 zero right text ">
										<i class="fa fa-money pad5 text-bar" aria-hidden="true"></i>
									</div>
									<div class="col-9 col-md-9 zero left">
										<!-- 	<div class="zero">
													  <div class="stat-1 stat-bar">
													    <span class="stat-bar-rating" role="stat-bar" style="width:10%;"></span>
													  </div>
													</div> -->
									</div>
									<div class="col-2 col-md-2 number-bar zero">
										<!-- <p class="zero left number-bar">{{compra.sellProducts.length}}</p> -->
									</div>
									<div class="col-2 col-md-2 text "></div>
									<div class=" col-10 col-md-10  zero">
										<p class="text price text1 weigh600 "> Precio total: {{getAmount(compra)| currency }}</p>
									</div>
								</div>
								<div class="row zero margin1">
									<div class="col-1 col-md-1 zero right text ">
										<i class="fa fa-truck pad5 text-bar" aria-hidden="true"></i>
									</div>
									<div class="col-9 col-md-9 zero left">
										<div class="zero">
											<!--  <div class="stat-1 stat-bar">
													    <span class="stat-bar-rating" role="stat-bar" style="width:20%;"></span>
													  </div> -->
										</div>
									</div>
									<div class="col-2 col-md-2 number-bar zero">
										<!-- 	<p   *ngIf="concretada" class="zero left number-bar">{{concretada.total}}</p> -->
									</div>
									<div class="col-2 col-md-2 text "></div>
									<div class=" col-10 col-md-10  zero">
										<p *ngIf="concretada" class="text price weigh600 text1"> Entrega {{compra.estimatedDeliveryDate | date:"MM/dd/yyyy"}}</p>
									</div>
								</div>
								<div class="row zero margin1">
									<div class="col-1 col-md-1 zero right text ">
										<i class="fa fa-credit-card pad5 text-bar" aria-hidden="true"></i>
									</div>
									<div class="col-9 col-md-9 zero left">
										<!-- 	<div class="zero">
													  <div class="stat-1 stat-bar">
													    <span class="stat-bar-rating" role="stat-bar" style="width:40%;"></span>
													  </div>
													</div> -->
									</div>
									<div class="col-2 col-md-2 number-bar zero">
										<p class="zero left number-bar"></p>
									</div>
									<div class="col-2 col-md-2 text "></div>
									<div class=" col-10 col-md-10  zero">
										<p *ngIf="concretada" class="text price weigh600 text1"> An &nbsp;{{compra.paymentOptions.anticipated}}% &nbsp; Co &nbsp;{{compra.paymentOptions.uponDelivery}}% &nbsp;Cr
											&nbsp;{{compra.paymentOptions.credit}}% &nbsp; por &nbsp; {{compra.paymentOptions.days}} días</p>
									</div>
								</div>
								<div class="row zero margin1">
									<div class="col-1 col-md-1 zero right text ">
										<!-- <i class="fa fa-bookmark pad5 text-bar" aria-hidden="true"></i> -->
									</div>
									<div class="col-9 col-md-9 zero left">
										<!-- <div class="zero">
													  <div class="stat-1 stat-bar">
													    <span class="stat-bar-rating" role="stat-bar" style="width:80%;"></span>
													  </div>
													</div> -->
									</div>
									<div class="col-2 col-md-2 number-bar zero">
										<p class="zero left number-bar"></p>
									</div>
									<div class="col-2 col-md-2 text "></div>
									<div class=" col-10 col-md-10  zero">
										<!-- <p class="text price"> Sobre 883 valoraciones</p> -->
									</div>
								</div>
							</div>
						</div>
						<div class="row background-white"> &nbsp;</div>
						<div *ngFor="let product of compra.sellProducts">
							<div *ngIf="product" class="row background-white black padding-6 border-and-padding">
								<div *ngIf="product" class="col-4 col-md-1 text center zero">
									<div *ngIf="product.productUrl" class="container-button">
										<!-- 				  								<div class="container-button-text"> 2 </div>
-->
										<i class="fa fa-arrow-circle-o-down zero" aria-hidden="true"></i>
									</div>
								</div>
								<div *ngIf="product" class="col-8 col-md-3 text left zeror">
									<p class="zero">Nombre del producto</p>
									<label class="zero strong ">{{product.name}}</label>
								</div>
								<div *ngIf="product" class="col-5 col-md-1 text left zeror">
									<p class="zero">Cantidad:</p>
									<label class=" strong zero">{{product.quantity}}</label>
								</div>
								<!-- 	<div *ngIf="product" class="col-5 col-md-1 text left zeror">
									<p class="zero">Unidad:</p>
									<label class=" strong zero">{{product.unit.name}}</label>
								</div> -->
								<!-- <div *ngIf="product" class="col-7 col-md-3 text left zero">
									<p class="zero">Caracteristicas:</p>
									<label class=" strong zero">{{product.comments}}</label>
								</div> -->
								<div *ngIf="product" class="col-5 col-md-1 text left zeror">
										<p class="zero">Unidad:</p>
										<label *ngIf="product.unit" class=" strong zero">{{product.unit.name}}</label>
										<label  *ngIf="!product.unit && product.unitName" class=" strong zero">{{product.unitName}}</label>									</div>
								<div *ngIf="product" class="col-5 col-md-2 text left zero">
									<p class="zero">Centro de costos:</p>
									<label class=" strong zero">{{concretada.projectName}}</label>
								</div>
								<div *ngIf="product" class="col-7 col-md-2 text left zero">
									<p class="zero">Precio unitario:</p>
									<label class=" strong zero">{{product.amount|currency}}</label>
								</div>

							</div>
						</div>

					</div>

					<!-- MIS USUARIOS -->


					<div class="row zero bg_blanco pad6">
						<div class="col-1 col-sm-3 col-md-3"> </div>
						<div class="col-10 col-sm-6 col-md-6 text center green font-8">
							<i class="fa fa-clock-o" aria-hidden="true" style="font-size:1.6em;"></i> &nbsp; Recibiras tus productos el dia
							<span class="bold">{{compra.estimatedDeliveryDate | date:"MM/dd/yyyy"}}</span>
						</div>
						<div class="col-xs-1 col-sm-3 col-md-3"> </div>
					</div>


					<div class="row zero bg_blanco pad6">
						<div class="col-1 col-sm-4 col-md-4 zero text center"> </div>
						<div class="col-10 col-sm-4 col-md-4 text center">
							<button class="boton_clip zero center pad08 collapse_title width font-1 white" style="background:#447F19;border:solid transparent;"
							 (click)="mensajes(concretada,i)"> Mensajes</button>
						</div>
						<div class="col-1 col-sm-4 col-md-4 text center"></div>
					</div>
				</div>
			</div>
		</div>
	</div>


</div>