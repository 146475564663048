
<section id="wrapper" class="login-register login-sidebar" style="background-image:url(assets/images/login-back.jpg); overflow:auto;">
    <div *ngIf="viewLogin ; else register" class="login-box card">
        <div class="card-body">
            <form [formGroup]="loginForm" (ngSubmit)="recoverPassword()" class="form-horizontal " id="loginform" action="index.html">


                <img class="logo " src="./assets/images/ixcco-login.png" alt="Home" />


                <div class="form-group m-t-10 " [ngClass]="{'has-danger':loginForm.controls['email'].errors?.required &&loginForm.controls['email'].touched}">
                    <div class="col-12">
                        <input class="form-login-custom" formControlName="email" type="email" placeholder="Correo electrónico">
                    </div>
                    <div class="form-control-feedback" *ngIf="loginForm.controls['email'].errors?.required&&loginForm.controls['email'].touched">
                        Este campo es obligatorio.
                    </div>
                </div>
                <div class="form-group" [ngClass]="{'has-danger':loginForm.controls['code'].errors?.required &&loginForm.controls['code'].touched}">
                    <div class="col-12">
                        <input class="form-login-custom" formControlName="code" name="code" type="text" required="" placeholder="Codigo">
                    </div>
                    <div class="form-control-feedback" *ngIf="loginForm.controls['code'].errors?.required&&loginForm.controls['code'].touched">
                        Este campo es obligatorio.
                    </div>
                </div>
                <div class="form-group" [ngClass]="{'has-danger':loginForm.controls['password'].errors?.required &&loginForm.controls['password'].touched}">
                    <div class="col-12">
                        <input class="form-login-custom" formControlName="password" name="password" type="password" required="" placeholder="Nueva Constraseña">
                    </div>
                    <div class="form-control-feedback" *ngIf="loginForm.controls['password'].errors?.required&&loginForm.controls['password'].touched">
                        Este campo es obligatorio.
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <button [disabled]="!loginForm.valid" class="btn btn-info btn-lg btn-block text-uppercase login-btn" type="submit">Recuperar</button>
                    </div>


                </div>
              

            </form>

        </div>
     
    </div>
    <div class="form-group  m-t-50">
        <div class="col-3 position-right">
        </div>


    </div>
    <ng-template #register>
        <div class="login-box card" [ngClass]="{'register-box': !viewLogin}">
            <div class="card-body">
                <form [formGroup]="preSignInForm" (ngSubmit)="recoverPassword()" class="form-horizontal " id="registerForm" action="index.html">


                    <img class="logo" src="./assets/images/BLANCO.png" alt="Home" />

                    <div class="form-group-custom "[ngClass]="{'has-danger':preSignInForm.controls['name'].errors?.required &&preSignInForm.controls['name'].touched}">
                        <div class="col-12">
                            <input class="form-login-custom" formControlName="name" name="name" type="text" placeholder="Nombre">
                        </div>
                        <div class="form-control-feedback" *ngIf="preSignInForm.controls['name'].errors?.required&&preSignInForm.controls['name'].touched">
                                Este campo es obligatorio.
                            </div>
                    </div>
                    <div class="form-group-custom" [ngClass]="{'has-danger':preSignInForm.controls['fiscalName'].errors?.required &&preSignInForm.controls['fiscalName'].touched}">
                        <div class="col-12">
                            <input class="form-login-custom" formControlName="fiscalName" name="fiscalName" type="text" required="" placeholder="Nombre Fiscal">
                        </div>
                        <div class="form-control-feedback" *ngIf="preSignInForm.controls['fiscalName'].errors?.required&&preSignInForm.controls['fiscalName'].touched">
                                Este campo es obligatorio.
                            </div>
                    </div>
                    <div class="form-group-custom "[ngClass]="{'has-danger':preSignInForm.controls['rfc'].errors?.required &&preSignInForm.controls['rfc'].touched}">
                        <div class="col-12">
                            <input class="form-login-custom" formControlName="rfc" name="rfc" type="text" placeholder="RFC">
                        </div>
                        <div class="form-control-feedback" *ngIf="preSignInForm.controls['rfc'].errors?.required&&preSignInForm.controls['rfc'].touched">
                                Este campo es obligatorio.
                            </div>
                    </div>
                    <div class="form-group-custom"[ngClass]="{'has-danger':preSignInForm.controls['phone'].errors?.required &&preSignInForm.controls['phone'].touched}">
                        <div class="col-12">
                            <input class="form-login-custom" formControlName="phone" name="phone" type="text" required="" placeholder="Telefono">
                        </div>
                        <div class="form-control-feedback" *ngIf="preSignInForm.controls['phone'].errors?.required&&preSignInForm.controls['phone'].touched">
                                Este campo es obligatorio.
                            </div>
                    </div>
                    <div class="form-group-custom"[ngClass]="{'has-danger':preSignInForm.controls['pre_email'].errors?.required &&preSignInForm.controls['pre_email'].touched}">
                        <div class="col-12">
                            <input class="form-login-custom" formControlName="pre_email" type="email" required="" placeholder="Email">
                        </div>
                        <div class="form-control-feedback" *ngIf="preSignInForm.controls['pre_email'].errors?.required&&preSignInForm.controls['pre_email'].touched">
                                Este campo es obligatorio.
                            </div>
                    </div>

                    <div class="form-group text-center">
                        <div class="col-xs-12">
                        </div>
                       

                    </div>


                </form>
                <div class="form-group text-center">
                       
                        <div class="col-12 m-t-20">
                            <button (click)="changeView() " class="btn btn-info btn-lg btn-block text-uppercase volver-btn" >Volver</button>
                        </div>
                           
                    </div>
                    <div class="text-center">
                       
                            <div class="col-12 " style="margin-top: 2em;">
                                    <a style="color:#000" href="https://app.ixcco.com/aviso-de-privacidad.pdf">Aviso de privacidad</a> 
                                </div>
                               
                        </div>

            </div>
        </div>
    </ng-template>

</section>

<ngx-spinner bdOpacity = 0.9 bdColor =  "#333" size =  "default" color =  "#fff" type =  "square-loader"></ngx-spinner>
