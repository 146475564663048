import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-client-table',
  templateUrl: './client-table.component.html',
  styleUrls: ['./client-table.component.css']
})
export class ClientTableComponent implements OnInit {
  @Input() dataIn: any;
  @Input() type: any;
  @Input() actions: any;

  @Output() openEvent = new EventEmitter();

  constructor() { }

  ngOnInit() {
    console.log(this.dataIn)
  }
  public viewInfo(obj, idx,action?) {
    let dataExport = {
      data: obj,
      index: idx,
      type: this.type,
      actions:action
    }
     console.log(dataExport)
    this.openEvent.emit(dataExport)
  }
  public finishRequest(obj, idx,action?,editable?) {
    let dataExport = {
      data: obj,
      index: idx,
      type: this.type,
      actions:action,
      editable:editable
    }
     console.log(dataExport)
    this.openEvent.emit(dataExport)
  }
  public openPurchaseDec(obj, idx,action?,editable?) {
    let dataExport = {
      data: obj,
      index: idx,
      type: this.type,
      actions:'decision',
      editable:editable
    }
     console.log(dataExport)
    this.openEvent.emit(dataExport)
  }
  public reDoContest(obj,action?,contestLabel?) {
    let dataExport = {
      data: obj,
      label: contestLabel,
      type: this.type,
      actions:'redo',
    }
     console.log(dataExport)
    this.openEvent.emit(dataExport)
  }
}
