<!-- Modal -->

<div
  class="container_bg"
  style="background-image: url(assets/images/2023/bg.png)"
>
  <div class="position-page only-movile">RECIBIDAS</div>
  <div class="container-scroll">
    <!-- -->
    <!-- -->
    <!--para generar la cotizacion-------------------------->

    <!--COMPARATIVA DECISION DE COMPRAS-->
    <!--   <div *ngIf="mobile" class="row is-movil-details target" id="movilDecision">
      <div class="width zero">
        <div class="row zero background-gray">
          <div class="col-0 col-md-1 zero"></div>
          <div class="col-2 col-md-2 collapse_title padding-top1">
            <p class="goBack" (click)="goBackRecibidas()">
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
              Regresar&nbsp;&nbsp;&nbsp;
            </p>
          </div>
          <div class="col-10 col-md-6 zero collapse_title padding-top1">
            <p>DECISIÓN DE COMPRAS</p>
          </div>
          <div class="col-0 col-md-3 zero"></div>
        </div>
        <div class="container-bg-blur zero padding-2">
          <div class="row background-white margin-top-6 font-8">
            <div class="row zero padding1 width margin1">
              <div class="col-12 col-md-6 zero left">
                <p class="font-8">
                  Selecciona tu proveedor final por cada producto
                </p>
              </div>
              <div class="col-12 col-md-2 zero left">
                <button
                  type="button"
                  class="btn btn-small btn-black-header redoBtn"
                  (click)="reDoRequest(selectedRequest.id, 'request')"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  RECOTIZAR
                </button>
              </div>

              <div class="col-12 col-md-4 zero">
                <div class="row zero">
                  <div class="col-4 col-md-4 zero">
                    <select
                      (change)="onChangeCurrencyView($event.target.value)"
                      class="zero form-control"
                    >
                      <option selected default value="">Tipo de cambio</option>
                      <option value="MXN">MXN</option>
                      <option value="USD">USD</option>
                    </select>
                  </div>
                  <div class="col-4 col-md-4 zero font-8">Dedicir por:</div>
                  <div class="col-4 col-md-4 zero">
                    <select (change)="onChange($event.target.value)">
                      <option
                        *ngFor="let option of sortOptions"
                        value="{{ option }}"
                      >
                        {{ option }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row zero width black left"
              style="background-color: #f9f9f9"
            >
              <div class="col-4 col-md-4 font-1">
                <div style="border-right: solid 1px #f0f0f0">
                  <div
                    style="
                      background-image: url(assets/images/gallery/marcas/CEMEX.png);
                      background-position: center;
                      background-size: contain;
                      background-repeat: no-repeat;
                      padding: 1em 0em;
                      margin: 1em 0em;
                    "
                  ></div>
                  <p class="minimized font-start margin1">
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star-o" aria-hidden="true"></i>
                  </p>
                </div>
              </div>
              <div class="col-8 col-md-8 zero">
                <p class="zero font-12 strong left">MOCTEZUMA</p>
                <p class="zero font-8 left">Guadalajara Jal</p>
                <div class="numbarra zero left">
                  <p class="zero center strong font-12">40</p>
                </div>
                <div class="row zero">
                  <div class="col-2 col-md-2 zero right text">
                    <i
                      class="fa fa-credit-card pad5 textbarra font-2"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div class="col-9 col-md-9 zero left">
                    <div class="zero">
                      <div class="stat-1 stat-bar start-bar-black">
                        <span
                          class="stat-bar-rating rating-black"
                          role="stat-bar"
                          style="width: 40%"
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row zero">
                  <div class="col-3 col-md-3 zero font-7">An25%</div>
                  <div class="col-3 col-md-3 zero font-7">Co25%</div>
                  <div class="col-3 col-md-3 zero font-7">Cr50%</div>
                  <div class="col-3 col-md-3 zero font-7">30 días</div>
                </div>
                <div class="margin1"></div>
              </div>
            </div>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th class="col-6 col-md-6 zero padding-top1" scope="col">
                    <p class="font-8 strong padding-top1 center">PRODUCTO</p>
                  </th>
                  <th class="col-6 col-md-6 zero padding-top1" scope="col">
                    <p class="font-8 strong padding-top1 center">PRECIO</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="row">
                      <div class="col-6 col-md-6 zero">
                        <div class="row zero font-8 padding-product strong">
                          Concreto 250 kg/cm
                          <sup class="zero">2</sup> &nbsp;&nbsp; m
                          <sup class="zero">3</sup> &nbsp;&nbsp; 48 pzas
                        </div>
                      </div>
                      <div class="col-6 col-md-6 zero">
                        <div class="row width">
                          <div class="col-3 col-md-3 font-1">
                            <i
                              class="fa fa-bookmark bookmark"
                              aria-hidden="true"
                            ></i>
                            <div class="leter-s center">s</div>
                          </div>
                          <div class="col-6 col-md-6 font-1">
                            {{ 2134 | currency }}
                          </div>
                          <div class="col-3 col-md-3 zero">
                            <div class="boder-l-r">
                              <input
                                type="checkbox"
                                id="all_country2"
                                class="filled-in chk-col-grey important-zero"
                              />
                              <label
                                class="fix-left checkbox-index important-zero"
                                for="all_country2"
                              >
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="row">
                      <div class="col-6 col-md-6 zero">
                        <div class="row zero font-8 padding-product strong">
                          Concreto 250 kg/cm
                          <sup class="zero">2</sup> &nbsp;&nbsp; m
                          <sup class="zero">3</sup> &nbsp;&nbsp; 48 pzas
                        </div>
                      </div>
                      <div class="col-6 col-md-6 zero">
                        <div class="row width">
                          <div class="col-3 col-md-3 font-1">
                            <i
                              class="fa fa-bookmark bookmark"
                              aria-hidden="true"
                            ></i>
                            <div class="leter-s center">s</div>
                          </div>
                          <div class="col-6 col-md-6 font-1">
                            {{ 2134 | currency }}
                          </div>
                          <div class="col-3 col-md-3 zero">
                            <div class="boder-l-r">
                              <input
                                type="checkbox"
                                id="all_country2"
                                class="filled-in chk-col-grey important-zero"
                              />
                              <label
                                class="fix-left checkbox-index important-zero"
                                for="all_country2"
                              >
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="row">
                      <div class="col-6 col-md-6 zero">
                        <div class="row zero font-8 padding-product strong">
                          Concreto 250 kg/cm
                          <sup class="zero">2</sup> &nbsp;&nbsp; m
                          <sup class="zero">3</sup> &nbsp;&nbsp; 48 pzas
                        </div>
                      </div>
                      <div class="col-6 col-md-6 zero">
                        <div class="row width">
                          <div class="col-3 col-md-3 font-1"></div>
                          <div class="col-6 col-md-6 font-1">
                            {{ 2134 | currency }}
                          </div>
                          <div class="col-3 col-md-3 zero">
                            <div class="boder-l-r">
                              <input
                                type="checkbox"
                                id="all_country2"
                                class="filled-in chk-col-grey important-zero"
                              />
                              <label
                                class="fix-left checkbox-index important-zero"
                                for="all_country2"
                              >
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <div
              class="row zero width black left"
              style="background-color: #f9f9f9"
            >
              <div class="col-4 col-md-4 font-1">
                <div style="border-right: solid 1px #f0f0f0">
                  <div
                    style="
                      background-image: url(assets/images/gallery/marcas/CEMEX.png);
                      background-position: center;
                      background-size: contain;
                      background-repeat: no-repeat;
                      padding: 1em 0em;
                      margin: 1em 0em;
                    "
                  ></div>
                  <p class="minimized font-start margin1">
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star-o" aria-hidden="true"></i>
                  </p>
                </div>
              </div>
              <div class="col-8 col-md-8 zero">
                <p class="zero font-12 strong left">MOCTEZUMA</p>
                <p class="zero font-8 left">Guadalajara Jal</p>
                <div class="numbarra zero left">
                  <p class="zero center strong font-12">40</p>
                </div>
                <div class="row zero">
                  <div class="col-2 col-md-2 zero right text">
                    <i
                      class="fa fa-credit-card pad5 textbarra font-2"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div class="col-9 col-md-9 zero left">
                    <div class="zero">
                      <div class="stat-1 stat-bar start-bar-black">
                        <span
                          class="stat-bar-rating rating-black"
                          role="stat-bar"
                          style="width: 40%"
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row zero">
                  <div class="col-3 col-md-3 zero font-7">An25%</div>
                  <div class="col-3 col-md-3 zero font-7">Co25%</div>
                  <div class="col-3 col-md-3 zero font-7">Cr50%</div>
                  <div class="col-3 col-md-3 zero font-7">30 días</div>
                </div>

                <div class="margin1"></div>
              </div>
            </div>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th class="col-6 col-md-6 zero padding-top1" scope="col">
                    <p class="font-8 strong padding-top1 center">PRODUCTO</p>
                  </th>
                  <th class="col-6 col-md-6 zero padding-top1" scope="col">
                    <p class="font-8 strong padding-top1 center">PRECIO</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="row">
                      <div class="col-6 col-md-6 zero">
                        <div class="row zero font-8 padding-product strong">
                          Concreto 250 kg/cm
                          <sup class="zero">2</sup> &nbsp;&nbsp; m
                          <sup class="zero">3</sup> &nbsp;&nbsp; 48 pzas
                        </div>
                      </div>
                      <div class="col-6 col-md-6 zero">
                        <div class="row width">
                          <div class="col-3 col-md-3 font-1">
                            <i
                              class="fa fa-bookmark bookmark"
                              aria-hidden="true"
                            ></i>
                            <div class="leter-s center">s</div>
                          </div>
                          <div class="col-6 col-md-6 font-1">
                            {{ 2134 | currency }}
                          </div>
                          <div class="col-3 col-md-3 zero">
                            <div class="boder-l-r">
                              <input
                                type="checkbox"
                                id="all_country2"
                                class="filled-in chk-col-grey important-zero"
                              />
                              <label
                                class="fix-left checkbox-index important-zero"
                                for="all_country2"
                              >
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="row">
                      <div class="col-6 col-md-6 zero">
                        <div class="row zero font-8 padding-product strong">
                          Concreto 250 kg/cm
                          <sup class="zero">2</sup> &nbsp;&nbsp; m
                          <sup class="zero">3</sup> &nbsp;&nbsp; 48 pzas
                        </div>
                      </div>
                      <div class="col-6 col-md-6 zero">
                        <div class="row width">
                          <div class="col-3 col-md-3 font-1">
                            <i
                              class="fa fa-bookmark bookmark"
                              aria-hidden="true"
                            ></i>
                            <div class="leter-s center">s</div>
                          </div>
                          <div class="col-6 col-md-6 font-1">
                            {{ 2134 | currency }}
                          </div>
                          <div class="col-3 col-md-3 zero">
                            <div class="boder-l-r">
                              <input
                                type="checkbox"
                                id="all_country2"
                                class="filled-in chk-col-grey important-zero"
                              />
                              <label
                                class="fix-left checkbox-index important-zero"
                                for="all_country2"
                              >
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="row">
                      <div class="col-6 col-md-6 zero">
                        <div class="row zero font-8 padding-product strong">
                          Concreto 250 kg/cm
                          <sup class="zero">2</sup> &nbsp;&nbsp; m
                          <sup class="zero">3</sup> &nbsp;&nbsp; 48 pzas
                        </div>
                      </div>
                      <div class="col-6 col-md-6 zero">
                        <div class="row width">
                          <div class="col-3 col-md-3 font-1"></div>
                          <div class="col-6 col-md-6 font-1">
                            {{ 2134 | currency }}
                          </div>
                          <div class="col-3 col-md-3 zero">
                            <div class="boder-l-r">
                              <input
                                type="checkbox"
                                id="all_country2"
                                class="filled-in chk-col-grey important-zero"
                              />
                              <label
                                class="fix-left checkbox-index important-zero"
                                for="all_country2"
                              >
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="row zero padding1 width">
              <div class="col-6 col-md-8 zero left black font-9 number-price">
                Haz seleccionado proveedores para
                <span class="strong">3/5</span> productos
              </div>
              <div class="col-6 col-md-4 zero">
                <div class="row zero">
                  <div class="col-0 col-md-6 zero"></div>
                  <div
                    class="col-12 col-md-6 zero strong black font-8 price-number"
                  >
                    {{ 5675000 | currency }} + IVA
                  </div>
                </div>
              </div>
            </div>
            <div class="row zero padding1 width">
              <div class="col-12 col-md-8 zero left black font-9"></div>
              <div class="col-12 col-md-4 zero">
                <div class="row zero">
                  <div class="col-4 col-md-6 zero strong black font-1 right">
                    TOTAL:
                  </div>
                  <div class="col-8 col-md-6 zero strong black font-1 all">
                    {{ 5675000 | currency }} MMX
                  </div>
                </div>
              </div>
            </div>
            <div>&nbsp;</div>
            <div
              class="row zero background-white padding5 width padding-01"
              style="border: solid 1px #f0f0f0"
            >
              <div class="col-xs-1 col-sm-4 col-md-4 zero text center"></div>
              <div class="col-xs-10 col-sm-4 col-md-4 text center">
                <button
                  class="zero center collapse_title width font-1 white"
                  style="background: #000; border: solid transparent"
                  (click)="mensajes()"
                >
                  Generar Orden de Compra
                </button>
              </div>
              <div class="col-xs-1 col-sm-4 col-md-4 text center"></div>
            </div>
            <div>&nbsp;</div>
          </div>
          
        </div>
      </div>
    </div> -->
    <!--COMPARATIVA DECISION DE COMPRAS REQUEST-->
    <div id="decisionCompra" class="row is-pc-details target">
      <div class="container">
        <div class="row zero background-gray">
          <div class="col-0 col-md-1 zero"></div>
          <div class="col-2 col-md-2 collapse_title padding-top1">
            <p class="goBack" (click)="goBackRecibidas()">
              <i class="fa fa-chevron-left" aria-hidden="true"></i>Regresar
              &nbsp;&nbsp;&nbsp;
            </p>
          </div>
          <div class="col-10 col-md-6 zero collapse_title padding-top1">
            <p>DECISIÓN DE COMPRAS</p>
          </div>
          <div class="col-0 col-md-3 zero"></div>
        </div>
        <div *ngIf="groupQuotations" class="container-bg-blur zero padding-2">
          <div class="row background-white margin-top-6 font-8">
            <div class="row zero padding1 width margin1">
              <div class="col-12 col-md-6 zero left">
                <p style="font-size: 1.1em; font-weight: 600; color: black">
                  Selecciona tu proveedor final por cada producto
                </p>
              </div>
              <div class="col-12 col-md-2 zero left">
                <button
                  type="button"
                  class="btn btn-small btn-black-header redoBtn"
                  (click)="reDoRequest(selectedRequest.id, 'request')"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  RECOTIZAR
                </button>
              </div>

              <div class="col-12 col-md-4 zero">
                <div class="row zero">
                  <div class="col-4 col-md-4 zero">
                    <select
                      (change)="onChangeCurrencyView($event.target.value)"
                      class="zero form-control"
                    >
                      <option selected default value="">Tipo de cambio</option>
                      <option value="MXN">MXN</option>
                      <option value="USD">USD</option>
                    </select>
                  </div>
                  <div
                    class="col-4 col-md-4 zero font-8"
                    style="margin-top: 20px"
                  >
                    Dedicir por:
                  </div>
                  <div class="col-4 col-md-4 zero">
                    <select
                      class="zero form-control"
                      [(ngModel)]="sortSelect"
                      (change)="onChangeSort($event.target.value)"
                    >
                      <option value="">Tipo Filtro</option>
                      <option
                        *ngFor="let option of sortOptions"
                        [ngValue]="option"
                      >
                        {{ option }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="canvasToPrint"
              [ngClass]="{
                renderClass: !printingCanvas,
                renderingClass: printingCanvas
              }"
            >
              <div
                *ngIf="selectedRequest"
                id="tableWrapper"
                class="canvas"
                [ngClass]="{
                  renderClass: !printingCanvas,
                  renderingClass: printingCanvas
                }"
              >
                <table class="table table-striped table-hovver zero margin1">
                  <thead class="zero margin1">
                    <tr class="zero">
                      <th class="zero" style="min-width: 500px">
                        <div
                          class="row"
                          style="
                            margin-top: 5px;
                            min-height: 170px;
                            height: auto;
                          "
                        >
                          <div class="col-5">
                            <img
                              class="logo"
                              src="./assets/images/login/icono.png"
                              alt=""
                            />
                          </div>
                          <div
                            id="cualitative-title"
                            class="col-12 col-md-12 noPaddingCol"
                          >
                            <p class="title-analysis">ANÁLISIS CUANTITATIVO</p>
                          </div>
                          <div
                            class="col-12"
                            style="
                              height: auto;
                              padding-left: 0px;
                              padding-right: 0px;
                            "
                          >
                            <p class="font-12 font-8 font-black noMb">
                              Nombre: &nbsp; {{ selectedRequest.name }}
                            </p>
                          </div>

                          <div
                            class="col-12"
                            style="
                              height: auto;
                              padding-left: 0px;
                              padding-right: 0px;
                            "
                          >
                            <p class="font-12 font-8 font-black noMb">
                              Proyecto: &nbsp;{{ selectedRequest.projectName }}
                            </p>
                          </div>

                          <div
                            class="col-12"
                            style="
                              height: auto;
                              padding-left: 0px;
                              padding-right: 0px;
                            "
                          >
                            <p
                              *ngIf="
                                this.groupQuotations &&
                                groupQuotations.contestLabel === 'request'
                              "
                              class="font-12 font-8 font-black noMb"
                            >
                              Solicita: &nbsp;
                              {{ groupQuotations.whoRequesting }}
                            </p>
                          </div>

                          <div
                            class="col-12"
                            style="
                              height: auto;
                              padding-left: 0px;
                              padding-right: 0px;
                            "
                          >
                            <p
                              *ngIf="
                                this.groupQuotations &&
                                groupQuotations.contestLabel === 'request'
                              "
                              class="font-12 font-8 font-black noMb"
                            >
                              Autoriza: &nbsp;{{
                                groupQuotations.whoAuthorizes
                              }}
                            </p>
                          </div>
                          <p class="font-12 font-8 font-black noMb text-left">
                            Responsable: &nbsp;{{ userRequest }}
                          </p>
                          <div
                            class="col-12"
                            style="
                              height: auto;
                              padding-left: 0px;
                              padding-right: 0px;
                            "
                          >
                            <p class="font-12 font-8 font-black noMb">
                              Calificador: &nbsp;{{ userName }}
                            </p>
                          </div>
                          <div
                            class="col-12"
                            style="
                              height: auto;
                              padding-left: 0px;
                              padding-right: 0px;
                            "
                          >
                            <p class="font-12 font-8 font-black noMb">
                              Fecha: &nbsp;{{
                                selectedRequest.limitQuotationDate
                                  | date : "dd/MM/yyyy"
                              }}
                            </p>
                          </div>
                          <div
                            class="col-12"
                            style="
                              height: auto;
                              padding-left: 0px;
                              padding-right: 0px;
                            "
                          >
                            <p class="font-12 font-8 font-black noMb">
                              Moneda: &nbsp; {{ currency }}
                            </p>
                          </div>
                        </div>
                        <div class="row">
                          <div
                            class="col-5 col-md-5 zero font-8 padding-product"
                          >
                            <p
                              style="font-size: 0.6em"
                              class="font-6 strong center"
                            >
                              PRODUCTO
                            </p>
                          </div>
                          <div class="col-3 col-md-3 zero font-8">
                            <p style="font-size: 0.6em" class="strong center">
                              ESPECIFICACIÓN
                            </p>
                          </div>
                          <div class="col-2 col-md-2 zero font-8">
                            <p style="font-size: 0.6em" class="strong center">
                              CANTIDAD
                            </p>
                          </div>
                          <div class="col-2 col-md-2 zero font-8">
                            <p style="font-size: 0.6em" class="strong center">
                              UNIDAD
                            </p>
                          </div>
                        </div>
                      </th>
                      <th
                        *ngFor="let quotation of quotedCompanies"
                        class="zero table-title companyWidth"
                      >
                        <div class="row companyWidth" *ngIf="quotation.company">
                          <div
                            class="col-5 col-md-5 font-1"
                            style="border-right: solid 1px #f0f0f0"
                          >
                            <div
                              id="demo"
                              style="
                                padding-top: 20px;
                                padding-bottom: 20px;
                                display: block;
                                text-align: center;
                              "
                            >
                              <img
                                data-html2canvas-ignore
                                crossorigin="anonymous"
                                crossOrigin="Anonymous"
                                *ngIf="quotation.company.profileUrl"
                                class="logo-brand-img centerDiv"
                                [src]="quotation.company.profileUrl"
                              />
                              <img
                                data-html2canvas-ignore
                                crossorigin="anonymous"
                                crossOrigin="Anonymous"
                                *ngIf="!quotation.company.profileUrl"
                                class="logo-brand-img centerDiv"
                                src="../../../assets/images/fakeCompany.png"
                              />
                              <div
                                *ngIf="quotation.company"
                                class="row"
                                style="
                                  margin-top: 20px;
                                  padding-left: 10px;
                                  display: block;
                                  text-align: center;
                                "
                              >
                                <button
                                  [tooltip]="
                                    quotation.company.name + 'Cotizacion.xlsx'
                                  "
                                  (click)="populateExcelQuotation(quotation)"
                                  class="btn btn-xs btn-secondary"
                                >
                                  <i
                                    class="fa fa-download"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="col-7 col-md-7 zero">
                            <p class="zero font-10 strong text-center">
                              {{ quotation.company.name }}
                            </p>
                            <p
                              *ngIf="quotation.company.companyInfo.city"
                              class="zero font-8 text-center"
                            >
                              {{ quotation.company.companyInfo.city }}
                            </p>
                            <p
                              *ngIf="!quotation.company.companyInfo.city"
                              class="zero font-8"
                            >
                              &nbsp;
                            </p>

                            <div class="zero center">
                              <p class="zero center strong font-12"></p>
                            </div>
                            <div
                              class="row zero d-block"
                              style="padding-left: 5px"
                            >
                              <p
                                *ngIf="quotation.company.ratingIxcco"
                                class="zero font-8 text-center"
                              >
                                {{ quotation.company.ratingIxcco }}
                              </p>
                              <p
                                *ngIf="!quotation.company.ratingIxcco"
                                class="zero font-8"
                              >
                                &nbsp;
                              </p>
                            </div>

                            <div class="row zero" style="padding-left: 0.5em">
                              <div
                                class="col-6 col-md-6 zero font-8 bolder text-center"
                              >
                                An &nbsp;{{
                                  quotation.paymentOptions.anticipated
                                }}%
                              </div>
                              <div
                                class="col-6 col-md-6 zero font-8 bolder text-center"
                              >
                                Es &nbsp;{{
                                  quotation.paymentOptions.uponDelivery
                                }}%
                              </div>
                              <div
                                class="col-6 col-md-6 zero font-8 bolder text-center"
                              >
                                Fn &nbsp;{{ quotation.paymentOptions.credit }}%
                              </div>
                              <div
                                class="col-6 col-md-6 zero font-8 bolder text-center"
                              >
                                &nbsp;{{ quotation.paymentOptions.days }}
                                días
                              </div>
                            </div>
                            <div class="margin1"></div>
                            <div class="row zero" style="padding-left: 0.7em">
                              <p class="zero font-7 left">
                                Entrega:&nbsp;{{
                                  quotation.estimatedDeliveryDate
                                    | date : "dd/MM/yyyy"
                                }}
                              </p>
                            </div>
                            <div class="row zero d-block">
                              <p
                                *ngIf="
                                  quotation.currency === 'USD';
                                  else currency
                                "
                                style="margin-left: 25px; color: green"
                                class="zero font-8¡9 left text-center"
                              >
                                {{ quotation.currency }}
                                <i
                                  style="color: green"
                                  class="fa fa-ethereum"
                                  aria-hidden="true"
                                ></i>
                              </p>
                              <ng-template #currency>
                                <p class="zero font-8¡9 left text-center">
                                  {{ quotation.currency }}
                                </p>
                              </ng-template>
                            </div>
                            <div class="row zero d-block">
                              <p
                                *ngIf="quotation.requiredFilesUrl"
                                class="zero left font-8 text-center"
                              >
                                Archivos
                              </p>
                              <p
                                *ngIf="!quotation.requiredFilesUrl"
                                class="zero left font-8 text-center"
                                style="margin-left: 16px"
                              >
                                Sin Archivos
                              </p>
                            </div>

                            <div
                              class="row"
                              *ngIf="quotation.requiredFilesUrl"
                              style="margin-top: 20px"
                            >
                              <div class="col-2">
                                <a
                                  *ngIf="quotation.requiredFilesUrl.securityUrl"
                                  [tooltip]="
                                    quotation.requiredFilesUrl.securityName
                                  "
                                  href="{{
                                    quotation.requiredFilesUrl.securityUrl
                                  }}"
                                  download
                                  target="_blank"
                                  class="btn btn-xs btn-secondary"
                                >
                                  <i
                                    class="fa fa-download"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                              <div class="col-2">
                                <a
                                  *ngIf="quotation.requiredFilesUrl.suppliesUrl"
                                  [tooltip]="
                                    quotation.requiredFilesUrl.suppliesName
                                  "
                                  href="{{
                                    quotation.requiredFilesUrl.suppliesUrl
                                  }}"
                                  download="asd"
                                  target="_blank"
                                  class="btn btn-xs btn-secondary"
                                >
                                  <i
                                    class="fa fa-download"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                              <div class="col-2">
                                <a
                                  *ngIf="quotation.requiredFilesUrl.tecnicsUrl"
                                  [tooltip]="
                                    quotation.requiredFilesUrl.tecnicsName
                                  "
                                  href="{{
                                    quotation.requiredFilesUrl.tecnicsUrl
                                  }}"
                                  download
                                  target="_blank"
                                  class="btn btn-xs btn-secondary"
                                >
                                  <i
                                    class="fa fa-download"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                              <div class="col-2">
                                <a
                                  *ngIf="quotation.requiredFilesUrl.valueUrl"
                                  [tooltip]="
                                    quotation.requiredFilesUrl.valueName
                                  "
                                  href="{{
                                    quotation.requiredFilesUrl.valueUrl
                                  }}"
                                  download
                                  target="_blank"
                                  class="btn btn-xs btn-secondary"
                                >
                                  <i
                                    class="fa fa-download"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                            </div>
                            <div class="row padding-headers mt-4">
                              <div class="col-6">
                                <p class="zero font-8">P.U</p>
                              </div>
                              <div class="col-6">
                                <p class="zero font-8">IMPORTE</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </th>

                      <th
                        style="
                          width: 150px;
                          padding-left: 50px;
                          padding-right: 50px;
                        "
                        class="zero"
                      >
                        <p class="font-8 strong center">IMPORTE</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="zero">
                    <tr
                      *ngFor="
                        let product of selectedRequest.requestProducts;
                        let indexRequest = index
                      "
                      class="zero"
                    >
                      <td class="strong tableWidth" style="height: 10px">
                        <div class="row">
                          <div
                            *ngIf="product.name.length <= 50"
                            class="col-5 col-md-5 zero font-9"
                          >
                            {{ indexRequest + 1 }}.&nbsp; {{ product.name }}
                            <div class="row">
                              <div class="col-12">
                                <sup>{{ product.comments }}</sup>
                              </div>
                            </div>
                          </div>
                          <div
                            [tooltip]="product.name"
                            *ngIf="product.name.length > 50"
                            class="col-5 col-md-5 zero font-7 noSpaceText"
                          >
                            {{ indexRequest + 1 }}.&nbsp; {{ product.name }}
                            <div class="row">
                              <div class="col-12">
                                <sup>{{ product.comments }}</sup>
                              </div>
                            </div>
                          </div>
                          <div
                            style="overflow: auto"
                            class="col-3 col-md-3 zero font-7"
                          >
                            <div *ngIf="product.specification">
                              {{ product.specification.unit }}
                            </div>
                            <div
                              *ngIf="
                                !product.specification &&
                                product.specificationName
                              "
                            >
                              {{ product.specificationName }}
                            </div>
                          </div>

                          <div class="col-2 col-md-2 zero font-7">
                            {{ product.quantity }}
                          </div>
                          <div
                            *ngIf="product.unit"
                            class="col-2 col-md-2 zero font-7"
                          >
                            {{ product.unit.name }}
                            <sup></sup>
                          </div>
                          <div
                            *ngIf="!product.unit && product.unitName"
                            class="col-2 col-md-2 zero font-7"
                          >
                            {{ product.unitName }}
                            <sup></sup>
                          </div>
                        </div>
                      </td>
                      <td
                        *ngFor="let quote of quotedCompanies; let i = index"
                        class="zero strong border-l-r companyWidth"
                        id="container-checkbox"
                      >
                        <div class="row companyWidth">
                          <div
                            *ngIf="quote.quotedProducts[indexRequest].winPrice"
                            class="col-3 col-md-3 font-1"
                            style="margin-top: 10px"
                          >
                            <i
                              class="fa fa-bookmark bookmark"
                              aria-hidden="true"
                            ></i>
                            <div class="leter-s">s</div>
                          </div>

                          <div
                            *ngIf="!quote.quotedProducts[indexRequest].winPrice"
                            class="col-3 col-md-3 font-1"
                            style="margin-top: 10px"
                          ></div>
                          <div
                            class="col-3 col-md-3 zero"
                            style="margin-top: 10px"
                          >
                            <div class="boder-l-r">
                              <input
                                (change)="
                                  radioChange(
                                    $event,
                                    quote.quotedProducts[indexRequest].amount,
                                    indexRequest,
                                    product.quantity,
                                    quote.quotedProducts[indexRequest],
                                    quote,
                                    i
                                  )
                                "
                                type="radio"
                                id="{{ 'radio' + indexRequest + i }}"
                                name="{{ 'radioGroup' + indexRequest }}"
                                class="radio-col-black with-gap"
                              />
                              <label
                                id="{{ 'radio' + indexRequest + i }}"
                                for="{{ 'radio' + indexRequest + i }}"
                              ></label>
                            </div>
                          </div>
                          <div
                            class="col-6 col-md-6 font-9"
                            style="margin-top: 15px"
                          >
                            <div class="row">
                              <div class="row">
                                <div class="col-6">
                                  <sup style="font-size: 1em">
                                    {{
                                      quote.quotedProducts[indexRequest].amount
                                        | currency
                                    }}</sup
                                  >
                                </div>
                                <div class="col-6">
                                  <sup class="ml-15" style="font-size: 1em">
                                    {{
                                      quote.quotedProducts[indexRequest]
                                        .amount * product.quantity | currency
                                    }}</sup
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td class="zero strong">
                        {{ selectedAmount[indexRequest] | currency }}
                      </td>
                    </tr>
                    <tr>
                      <div class="row" style="width: 100%">
                        <div class="col-2 col-md-2 zero elseRow">
                          <p class="strong black font-1">Subtotal</p>
                        </div>
                        <div class="col-4 col-md-4 zero font-7 nameRow"></div>
                        <div class="col-2 col-md-2 zero elseRow"></div>
                        <div class="col-2 col-md-2 zero elseRow font-7"></div>
                        <div class="col-1 col-md-1 zero font-7 elseRow"></div>
                      </div>
                      <td
                        *ngFor="let quote of quotedCompanies; let i = index"
                        class="zero strong border-l-r companyWidthContest text-right"
                        id="container-checkbox"
                      >
                        {{ quote.amount | currency }}
                      </td>
                    </tr>
                    <tr>
                      <div class="row" style="width: 100%">
                        <div class="col-2 col-md-2 zero elseRow">
                          <p class="strong black font-1">Iva</p>
                        </div>
                        <div class="col-4 col-md-4 zero font-7 nameRow"></div>
                        <div class="col-2 col-md-2 zero elseRow"></div>
                        <div class="col-2 col-md-2 zero elseRow font-7"></div>
                        <div class="col-1 col-md-1 zero font-7 elseRow"></div>
                      </div>
                      <td
                        *ngFor="let quote of quotedCompanies; let i = index"
                        class="zero strong border-l-r companyWidthContest text-right"
                        id="container-checkbox"
                      >
                        {{ quote.amount * 0.16 | currency }}
                      </td>
                    </tr>
                    <tr>
                      <div class="row" style="width: 100%">
                        <div class="col-2 col-md-2 zero elseRow">
                          <p class="strong black font-1">Total</p>
                        </div>
                        <div class="col-4 col-md-4 zero font-7 nameRow"></div>
                        <div class="col-2 col-md-2 zero elseRow"></div>
                        <div class="col-2 col-md-2 zero elseRow font-7"></div>
                        <div class="col-1 col-md-1 zero font-7 elseRow"></div>
                      </div>
                      <td
                        *ngFor="let quote of quotedCompanies; let i = index"
                        class="zero strong border-l-r companyWidthContest text-right"
                        id="container-checkbox"
                      >
                        {{ quote.amount * 1.16 | currency }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="canvas row zero padding1 width" style="width: 1050px">
                <div class="col-6 col-md-8 zero left black font-9 number-price">
                  Haz seleccionado proveedores para
                  <span *ngIf="selectedRequest" class="strong"
                    >{{ selectedAmount.length }}/{{
                      selectedRequest.requestProducts.length
                    }}</span
                  >
                  productos
                </div>
                <div class="col-6 col-md-4 zero">
                  <div class="row zero">
                    <div class="col-4 col-md-6 zero strong black font-1 right">
                      SUBTOTAL
                    </div>

                    <div
                      class="col-4 col-md-6 zero strong black font-8 price-number"
                    >
                      {{ getTotal(selectedAmount, false) | currency }}
                    </div>
                  </div>
                  <div class="row zero">
                    <div class="col-4 col-md-6 zero strong black font-1 right">
                      IVA:
                    </div>
                    <div class="col-8 col-md-6 zero strong black font-1 all">
                      {{ getIVA(selectedAmount) | currency }}
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="col-6 col-md-2 zero left black font-9 number-price"
                style="padding-top: 1em; padding-left: 2em"
              >
                <button
                  data-html2canvas-ignore
                  *ngIf="selectedRequest"
                  class="zero center collapse_title width font-1 white padding-01 disabled"
                  style="
                    margin-top: 10px;
                    background: #000;
                    border: solid transparent;
                    padding: 0.5em 0;
                  "
                  (click)="exportComparativa()"
                >
                  Exportar comparativa
                </button>
                <button
                  data-html2canvas-ignore
                  *ngIf="selectedRequest"
                  class="zero center collapse_title width font-1 white padding-01 disabled"
                  style="
                    margin-top: 10px;
                    background: #000;
                    border: solid transparent;
                    padding: 0.5em 0;
                  "
                  (click)="resetDesicion()"
                >
                  Reiniciar Decisión
                </button>
                <button
                  data-html2canvas-ignore
                  *ngIf="selectedRequest"
                  class="zero center collapse_title width font-1 white padding-01 disabled"
                  style="
                    margin-top: 10px;
                    background: #000;
                    border: solid transparent;
                    padding: 0.5em 0;
                  "
                  (click)="printCanvasContest()"
                >
                  Impresion
                </button>
                <button
                  data-html2canvas-ignore
                  *ngIf="selectedRequest"
                  class="zero center collapse_title width font-1 white padding-01 disabled"
                  style="
                    margin-top: 10px;
                    background: #000;
                    border: solid transparent;
                    padding: 0.5em 0;
                  "
                  (click)="fakePurchaseOrder()"
                >
                  Concretar
                </button>
              </div>

              <div class="canvas col-6 col-md-4 zero">
                <div class="row zero"></div>
              </div>
              <div
                *ngIf="technical || isDirector"
                class="canvas row zero padding1 width"
                style="width: 1050px; overflow: scroll"
              >
                <div class="col-12 col-md-8 zero left black font-9"></div>
                <div class="col-12 col-md-4 zero">
                  <div class="row zero">
                    <div class="col-4 col-md-6 zero strong black font-1 right">
                      TOTAL:
                    </div>
                    <div class="col-8 col-md-6 zero strong black font-1 all">
                      {{ getTotal(selectedAmount, true) | currency }} MXN
                    </div>
                  </div>
                </div>
                <div
                id="cualitative-title"
                class="col-12 col-md-12 noPaddingCol padding-top1"
              >
                <div
                  class="row"
                  style="margin-top: 5px; min-height: 170px; height: auto"
                >
                  <div class="col-5">
                    <img
                      class="logo"
                      src="./assets/images/login/icono.png"
                      alt=""
                    />
                    <div
                      id="cualitative-title"
                      class="col-12 col-md-12 noPaddingCol text-left"
                    >
                      <p class="title-analysis">ANÁLISIS CUALITATIVO</p>
                    </div>
                    <div
                      class="col-12"
                      style="
                        height: auto;
                        padding-left: 0px;
                        padding-right: 0px;
                      "
                    >
                      <p class="font-12 font-8 font-black noMb text-left">
                        Nombre: &nbsp; {{ selectedRequest.name }}
                      </p>
                    </div>

                    <div
                      class="col-12"
                      style="
                        height: auto;
                        padding-left: 0px;
                        padding-right: 0px;
                      "
                    >
                      <p class="font-12 font-8 font-black noMb text-left">
                        Proyecto: &nbsp;{{ selectedRequest.projectName }}
                      </p>
                    </div>

                    <div
                      class="col-12"
                      style="
                        height: auto;
                        padding-left: 0px;
                        padding-right: 0px;
                      "
                    >
                      <p
                        *ngIf="
                          this.groupQuotations &&
                          groupQuotations.contestLabel === 'request'
                        "
                        class="font-12 font-8 font-black noMb text-left"
                      >
                        Solicita: &nbsp;
                        {{ groupQuotations.whoRequesting }}
                      </p>
                    </div>

                    <div
                      class="col-12"
                      style="
                        height: auto;
                        padding-left: 0px;
                        padding-right: 0px;
                      "
                    >
                      <p
                        *ngIf="
                          this.groupQuotations &&
                          groupQuotations.contestLabel === 'request'
                        "
                        class="font-12 font-8 font-black noMb text-left"
                      >
                        Autoriza: &nbsp;{{ groupQuotations.whoAuthorizes }}
                      </p>
                    </div>
                    <div
                      class="col-12"
                      style="
                        height: auto;
                        padding-left: 0px;
                        padding-right: 0px;
                      "
                    >
                      <p class="font-12 font-8 font-black noMb text-left">
                        Responsable: &nbsp;{{ userRequest }}
                      </p>
                    </div>
                    <div
                      class="col-12"
                      style="
                        height: auto;
                        padding-left: 0px;
                        padding-right: 0px;
                      "
                    >
                      <p class="font-12 font-8 font-black noMb text-left">
                        Calificador: &nbsp;{{ userName }}
                      </p>
                    </div>
                    <div
                      class="col-12"
                      style="
                        height: auto;
                        padding-left: 0px;
                        padding-right: 0px;
                      "
                    >
                      <p class="font-12 font-8 font-black noMb text-left">
                        Fecha: &nbsp;{{
                          selectedRequest.limitQuotationDate
                            | date : "dd/MM/yyyy"
                        }}
                      </p>
                    </div>
                    <div
                      class="col-12"
                      style="
                        height: auto;
                        padding-left: 0px;
                        padding-right: 0px;
                      "
                    >
                      <p class="font-12 font-8 font-black noMb text-left">
                        Moneda: &nbsp; {{ currency }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <table id="cualitative" class="mb-40">
                <tr>
                  <th class="border width-300">Aspectos a calificar</th>
                  <th class="border width-200">Ponderación</th>

                  <th
                    class="border width-300"
                    *ngFor="let quote of qualitative; let i = index"
                  >
                    <p class="text-center font-16">
                      {{ quote.company.name }}
                    </p>
                    <p
                      *ngIf="quote.company.companyInfo.city"
                      class="zero font-8 text-center"
                    >
                      {{ quote.company.companyInfo.city }}
                    </p>

                    <div class="row padding-headers mt-4">
                      <div class="col-6">
                        <p class="zero font-8 text-right">CALIF</p>
                      </div>
                      <div class="col-6">
                        <p class="zero font-8 text-left">VALOR</p>
                      </div>
                    </div>
                  </th>
                </tr>
                <tr>
                  <td class="d-grid">
                    <ng-container
                      *ngFor="let aspect of aspects; let i = index"
                    >
                      <div class="w-100">
                        <input
                          [disabled]="disableQualitative"
                          class="w-100 input-analysis no-boder"
                          [(ngModel)]="aspect.aspect"
                          type="text"
                        />
                      </div>
                    </ng-container>
                  </td>
                  <td class="d-grid">
                    <ng-container
                      *ngFor="let aspect of aspects; let i = index"
                    >
                      <div class="w-100">
                        <input
                          class="input-analysis no-boder"
                          [disabled]="disableQualitative"
                          [(ngModel)]="aspect.ponderation"
                          type="number"
                        />
                      </div>
                    </ng-container>
                  </td>
                  <td
                    class="d-grid"
                    *ngFor="let data of qualitative; let i = index"
                  >
                    <ng-container
                      *ngFor="
                        let qualy of data.company.qualitative;
                        let i = index
                      "
                    >
                      <div class="w-100">
                        <input
                          [disabled]="disableQualitative"
                          class="width-input input-analysis no-boder"
                          [(ngModel)]="qualy.calif"
                          (ngModelChange)="validateAnalysis(qualy.calif)"
                          type="number"
                        />
                        <label for="" class="p-label mb-0">{{
                          (qualy.calif * aspects[i].ponderation) / 100
                        }}</label>
                      </div>
                    </ng-container>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button
                      class="mt-4 btn-qualy"
                      type="button"
                      *ngIf="!disableQualitative"
                      (click)="addAspect()"
                    >
                      Agregar
                    </button>
                    <button
                      class="mt-4 btn-qualy ml-15"
                      type="button"
                      *ngIf="!disableQualitative"
                      (click)="removeAspect()"
                    >
                      Quitar
                    </button>
                  </td>
                  <td>
                    <p class="p-label">{{ getPonderation() }} %</p>
                    <p style="color: red" *ngIf="getPonderation() > 100">
                      La ponderación no puede ser mas de 100% .
                    </p>
                  </td>
                  <td
                    class="d-grid"
                    *ngFor="let data of qualitative; let i = index"
                  >
                    <div class="w-100">
                      <label for="" class="p-label label-14">
                        {{
                          getTotals(data.company.qualitative, i)
                            | number : "1.0-0"
                        }}
                      </label>
                      <label for="" class="p-label new-margin label-14">
                        {{
                          getTotalsRep(data.company.qualitative, i)
                            | number : "1.1-1"
                        }}
                      </label>
                    </div>
                  </td>
                </tr>
                <tr id="resumen" class="mt-40">
                  <td class="width-350">
                    <p class="title-resume">RESUMEN</p>
                  </td>
                  <td class="width-250">
                    <p class="strong black font-1">IMPORTE DE LA PROPUESTA</p>
                  </td>

                  <td
                    *ngFor="let quote of quotedCompanies; let i = index"
                    class="zero strong black border-l-r companyWidthContest"
                    id="container-checkbox"
                  >
                    {{ quote.amount * 1.16 | currency }}
                  </td>
                </tr>
                <tr id="resumen2" class="mt-40">
                  <td class="width-350"></td>
                  <td class="width-250">
                    <p class="strong black font-1">DIFERENCIA VS MAS BAJO</p>
                  </td>

                  <td
                    [ngClass]="{
                      badPrice: quote.amount * 1.16 > minValue,
                      goodPrice: quote.amount * 1.16 === minValue,
                      black: minValue === quote.amount * 1.16
                    }"
                    *ngFor="let quote of quotedCompanies; let i = index"
                    class="zero strong black border-l-r companyWidthContest"
                    id="container-checkbox"
                  >
                    ${{ getDifResume(quote, quotedCompanies) }}
                  </td>
                </tr>
                <tr id="resumen3" class="mt-40">
                  <td class="width-300"></td>
                  <td class="width-200">
                    <p class="strong black font-1">%</p>
                  </td>

                  <td
                    [ngClass]="{
                      badPrice: quote.amount * 1.16 > minValue,
                      goodPrice: quote.amount * 1.16 === minValue,
                    }"
                    *ngFor="let quote of quotedCompanies; let i = index"
                    class="zero strong black border-l-r companyWidthContest"
                    id="container-checkbox"
                  >
                    {{
                      getDifPercentage(quote, quotedCompanies) 
                        | number : "1.0-0"
                    }}
                    %
                  </td>
                </tr>
                <tr id="resumen4" class="mt-40">
                  <td class="width-350"></td>
                  <td class="width-250">
                    <p class="strong black font-1">
                      CALIFICACIÓN CUALITATIVA
                    </p>
                  </td>

                  <td
                    *ngFor="let data of qualitative; let i = index"
                    class="zero strong black border-l-r companyWidthContest"
                    id="container-checkbox"
                  >
                    {{
                      getTotalsRep(data.company.qualitative, i)
                        | number : "1.1-1"
                    }}
                  </td>
                </tr>
              </table>
              <div *ngIf="qualitative" class="col-12 padding-top1">
                <label for="" class="p-label label-18"> Conclusiones </label>
              </div>
              <div *ngIf="qualitative" class="col-12 padding-top1">
                <textarea
                  [(ngModel)]="qualitative.conclusion"
                  [disabled]="disableQualitative"
                  name=""
                  id=""
                  cols="160"
                  rows="8"
                ></textarea>
              </div>
            </div>
            <div
              *ngIf="technical || isDirector"
              class="row d-block text-center"
            >
              <button
                class="mt-4 btn-qualy"
                type="button"
                (click)="saveAnalysis()"
                *ngIf="!disableQualitative"
              >
                Guardar Análisis Cualitativo
              </button>
              <button
                class="mt-4 btn-qualy align-right"
                type="button"
                (click)="exportAnalysis()"
              >
                Exportar Análisis Cualitativo
              </button>
            </div>
            <div class="canvas row zero padding1 width" style="width: 1050px">
              <div class="col-12 col-md-8 zero left black font-9"></div>
              <div class="col-12 col-md-4 zero">
                <div
                  class="row zero"
                  *ngIf="groupQuotations.contestLabel === 'request'"
                >
                  <div class="col-4 col-md-6 zero strong black font-1 right">
                    TOTAL:
                  </div>
                  <div class="col-8 col-md-6 zero strong black font-1 all">
                    {{ getTotal(selectedAmount, true) | currency }} MXN
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div>&nbsp;</div>
            <div
              class="row zero background-white padding5 width"
              style="border: solid 1px #f0f0f0; width: 100%"
            >
              <div class="col-xs-1 col-sm-4 col-md-4 zero text center"></div>
              <div class="col-xs-10 col-sm-4 col-md-4 text center mt-4">
                <button
                  *ngIf="selectedRequest && !technical"
                  class="zero center collapse_title width font-1 white padding-01 disabled"
                  [disabled]="
                    selectedAmount.length <
                    selectedRequest.requestProducts.length
                  "
                  style="
                    background: #000;
                    border: solid transparent;
                    padding: 1em 0;
                  "
                  (click)="openPreviewPurchaseOrder()"
                >
                  Generar Orden de Compra
                </button>
              </div>
              <div class="col-xs-1 col-sm-4 col-md-4 text center"></div>
            </div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
    <!--END COMPARATIVA DECISION DE COMPRAS REQUEST-->

    <!--COMPARATIVA DECISION DE CONTEST-->
    <div id="decisionContest" class="row is-pc-details target">
      <div class="container">
        <div class="row zero background-gray">
          <div class="col-0 col-md-1 zero"></div>
          <div class="col-2 col-md-2 collapse_title padding-top1">
            <p class="goBack" (click)="goBackRecibidas()">
              <i class="fa fa-chevron-left" aria-hidden="true"></i>Regresar
              &nbsp;&nbsp;&nbsp;
            </p>
          </div>
          <div class="col-10 col-md-6 zero collapse_title padding-top1">
            <p>DECISIÓN DE LICITACIÓN</p>
          </div>
          <div class="col-0 col-md-3 zero"></div>
        </div>
        <div *ngIf="groupQuotations" class="container-bg-blur zero padding-2">
          <div class="row background-white margin-top-6 font-8">
            <div class="row zero padding1 width margin1">
              <div class="col-12 col-md-6 zero left"></div>
              <div class="col-12 col-md-2 zero left">
                <button
                  type="button"
                  class="btn btn-small btn-black-header redoBtn"
                  (click)="reDoRequest(selectedRequest.id, 'contest')"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  RELICITAR
                </button>
              </div>

              <div class="col-12 col-md-4 zero">
                <div class="row zero">
                  <div class="col-4 col-md-4 zero">
                    <select
                      (change)="onChangeCurrencyView($event.target.value)"
                      class="zero form-control"
                    >
                      <option selected default value="">Tipo de cambio</option>
                      <option value="MXN">MXN</option>
                      <option value="USD">USD</option>
                    </select>
                  </div>
                  <div
                    class="col-4 col-md-4 zero font-8"
                    style="margin-top: 20px"
                  >
                    Dedicir por:
                  </div>
                  <div class="col-4 col-md-4 zero">
                    <select
                      class="zero form-control"
                      [(ngModel)]="sortSelect"
                      (change)="onChangeSort($event.target.value)"
                    >
                      <option value="">Tipo Filtro</option>
                      <option
                        *ngFor="let option of sortOptionsContest"
                        [ngValue]="option"
                      >
                        {{ option }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="canvasToPrintContest"
              [ngClass]="{
                renderClass: !printingCanvas,
                renderingClass: printingCanvas
              }"
            >
              <div
                *ngIf="selectedRequest"
                id="tableWrapper"
                class="canvas"
                [ngClass]="{
                  renderClass: !printingCanvas,
                  renderingClass: printingCanvas
                }"
              >
                <table class="table table-striped table-hovver zero margin1">
                  <thead class="zero margin1">
                    <tr class="zero">
                      <th class="zero" style="min-width: 450px">
                        <div
                          class="row"
                          style="
                            margin-top: 5px;
                            min-height: 170px;
                            height: auto;
                          "
                        >
                          <div class="col-5">
                            <img
                              class="logo"
                              src="./assets/images/login/icono.png"
                              alt=""
                            />
                          </div>
                          <div
                            id="cualitative-title"
                            class="col-12 col-md-12 noPaddingCol"
                          >
                            <p class="title-analysis">ANÁLISIS CUANTITATIVO</p>
                          </div>
                          <div
                            class="col-12"
                            style="
                              height: auto;
                              padding-left: 0px;
                              padding-right: 0px;
                            "
                          >
                            <p class="font-12 font-8 font-black noMb">
                              Nombre: &nbsp; {{ selectedRequest.name }}
                            </p>
                          </div>

                          <div
                            class="col-12"
                            style="
                              height: auto;
                              padding-left: 0px;
                              padding-right: 0px;
                            "
                          >
                            <p class="font-12 font-8 font-black noMb">
                              Proyecto: &nbsp;{{ selectedRequest.projectName }}
                            </p>
                          </div>

                          <div
                            class="col-12"
                            style="
                              height: auto;
                              padding-left: 0px;
                              padding-right: 0px;
                            "
                          >
                            <p
                              *ngIf="
                                this.groupQuotations &&
                                groupQuotations.contestLabel === 'request'
                              "
                              class="font-12 font-8 font-black noMb"
                            >
                              Solicita: &nbsp;
                              {{ groupQuotations.whoRequesting }}
                            </p>
                          </div>

                          <div
                            class="col-12"
                            style="
                              height: auto;
                              padding-left: 0px;
                              padding-right: 0px;
                            "
                          >
                            <p
                              *ngIf="
                                this.groupQuotations &&
                                groupQuotations.contestLabel === 'request'
                              "
                              class="font-12 font-8 font-black noMb"
                            >
                              Autoriza: &nbsp;{{
                                groupQuotations.whoAuthorizes
                              }}
                            </p>
                          </div>
                          <div
                            class="col-12"
                            style="
                              height: auto;
                              padding-left: 0px;
                              padding-right: 0px;
                            "
                          >
                            <p class="font-12 font-8 font-black noMb text-left">
                              Responsable: &nbsp;{{ userRequest }}
                            </p>
                          </div>
                          <div
                            class="col-12"
                            style="
                              height: auto;
                              padding-left: 0px;
                              padding-right: 0px;
                            "
                          >
                            <p class="font-12 font-8 font-black noMb">
                              Calificador: &nbsp;{{ userName }}
                            </p>
                          </div>
                          <div
                            class="col-12"
                            style="
                              height: auto;
                              padding-left: 0px;
                              padding-right: 0px;
                            "
                          >
                            <p class="font-12 font-8 font-black noMb">
                              Fecha: &nbsp;{{
                                selectedRequest.limitQuotationDate
                                  | date : "dd/MM/yyyy"
                              }}
                            </p>
                          </div>
                          <div
                            class="col-12"
                            style="
                              height: auto;
                              padding-left: 0px;
                              padding-right: 0px;
                            "
                          >
                            <p class="font-12 font-8 font-black noMb">
                              Moneda: &nbsp; {{ currency }}
                            </p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-2 col-md-2 zero font-8">
                            <p style="font-size: 0.8em" class="strong center">
                              CÓDIGO
                            </p>
                          </div>
                          <div
                            class="col-3 col-md-3 zero font-8 padding-product"
                          >
                            <p
                              style="font-size: 0.8em"
                              class="font-6 strong center"
                            >
                              CONCEPTO
                            </p>
                          </div>
                          <div class="col-1 col-md-2 zero font-8">
                            <p style="font-size: 0.8em" class="strong center">
                              CANTIDAD
                            </p>
                          </div>
                          <div class="col-1 col-md-1 zero font-8">
                            <p style="font-size: 0.8em" class="strong center">
                              UNIDAD
                            </p>
                          </div>
                          <div class="col-1 col-md-1 zero font-8">
                            <p style="font-size: 0.8em" class="strong center">
                              PU
                            </p>
                          </div>
                          <div class="col-2 col-md-2 zero font-8">
                            <p style="font-size: 0.8em" class="strong center">
                              IMPORTE
                            </p>
                          </div>
                          <div class="col-1 col-md-1 zero font-8">
                            <p
                              style="font-size: 0.8em"
                              class="strong center m-l-15"
                            >
                              %
                            </p>
                          </div>
                        </div>
                      </th>
                      <th
                        *ngFor="let quotation of quotedCompanies"
                        class="zero table-title companyWidthContest"
                      >
                        <div
                          class="row companyWidthContest"
                          *ngIf="quotation.company"
                        >
                          <div
                            class="col-5 col-md-5 font-1"
                            style="border-right: solid 1px #f0f0f0"
                          >
                            <div
                              id="demo"
                              style="
                                padding-top: 20px;
                                padding-bottom: 20px;
                                display: block;
                                text-align: center;
                              "
                            >
                              <img
                                data-html2canvas-ignore
                                crossorigin="anonymous"
                                crossOrigin="Anonymous"
                                *ngIf="quotation.company.profileUrl"
                                class="logo-brand-img centerDiv"
                                [src]="quotation.company.profileUrl"
                              />
                              <img
                                data-html2canvas-ignore
                                crossorigin="anonymous"
                                crossOrigin="Anonymous"
                                *ngIf="!quotation.company.profileUrl"
                                class="logo-brand-img centerDiv"
                                src="../../../assets/images/fakeCompany.png"
                              />
                              <div
                                *ngIf="quotation.company"
                                class="row"
                                style="
                                  margin-top: 20px;
                                  padding-left: 10px;
                                  display: block;
                                  text-align: center;
                                "
                              >
                                <button
                                  [tooltip]="
                                    quotation.company.name + 'Cotizacion.xlsx'
                                  "
                                  (click)="populateExcelQuotation(quotation)"
                                  class="btn btn-xs btn-secondary"
                                >
                                  <i
                                    class="fa fa-download"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="col-7 col-md-7 zero">
                            <p class="zero font-10 strong text-center">
                              {{ quotation.company.name }}
                            </p>
                            <p
                              *ngIf="quotation.company.companyInfo.city"
                              class="zero font-8 text-center"
                            >
                              {{ quotation.company.companyInfo.city }}
                            </p>
                            <p
                              *ngIf="!quotation.company.companyInfo.city"
                              class="zero font-8"
                            >
                              &nbsp;
                            </p>

                            <div class="zero center">
                              <p class="zero center strong font-12"></p>
                            </div>
                            <div
                              class="row zero d-block"
                              style="padding-left: 5px"
                            >
                              <p
                                *ngIf="quotation.company.ratingIxcco"
                                class="zero font-8 text-center"
                              >
                                {{ quotation.company.ratingIxcco }}
                              </p>
                              <p
                                *ngIf="!quotation.company.ratingIxcco"
                                class="zero font-8"
                              >
                                &nbsp;
                              </p>
                              <!--  <div class="col-2 col-md-2 zero right text">
                                <i
                                  class="fa fa-credit-card pad5 textbarra font-2"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div class="col-9 col-md-9 zero">
                                <div class="zero">
                                  <div class="stat-1 stat-bar start-bar-black">
                                    <span
                                      *ngIf="quotation.creditScore"
                                      class="stat-bar-rating rating-black"
                                      role="stat-bar"
                                      [ngStyle]="{
                                        width: quotation.creditScore + '%'
                                      }"
                                    ></span>
                                  </div>
                                </div>
                              </div> -->
                            </div>

                            <div class="row zero" style="padding-left: 0.5em">
                              <div
                                class="col-6 col-md-6 zero font-8 bolder text-center"
                              >
                                An &nbsp;{{
                                  quotation.paymentOptions.anticipated
                                }}%
                              </div>
                              <div
                                class="col-6 col-md-6 zero font-8 bolder text-center"
                              >
                                Es &nbsp;{{
                                  quotation.paymentOptions.uponDelivery
                                }}%
                              </div>
                              <div
                                class="col-6 col-md-6 zero font-8 bolder text-center"
                              >
                                Fn &nbsp;{{ quotation.paymentOptions.credit }}%
                              </div>
                              <div
                                class="col-6 col-md-6 zero font-8 bolder text-center"
                              >
                                &nbsp;{{ quotation.paymentOptions.days }}
                                días
                              </div>
                            </div>
                            <div class="margin1"></div>
                            <div
                              class="row zero"
                              style="padding-left: 0.7em"
                            ></div>
                            <div class="row zero d-block">
                              <p
                                *ngIf="
                                  quotation.currency === 'USD';
                                  else currency
                                "
                                style="margin-left: 25px; color: green"
                                class="zero font-8¡9 left text-center"
                              >
                                {{ quotation.currency }}
                                <i
                                  style="color: green"
                                  class="fa fa-ethereum"
                                  aria-hidden="true"
                                ></i>
                              </p>
                              <ng-template #currency>
                                <p class="zero font-8¡9 left text-center">
                                  {{ quotation.currency }}
                                </p>
                              </ng-template>
                            </div>
                            <div class="row zero d-block">
                              <p
                                *ngIf="quotation.requiredFilesUrl"
                                class="zero left font-8 text-center"
                              >
                                Archivos
                              </p>
                              <p
                                *ngIf="!quotation.requiredFilesUrl"
                                class="zero left font-8 text-center"
                                style="margin-left: 16px"
                              >
                                Sin Archivos
                              </p>
                            </div>

                            <div
                              class="row"
                              *ngIf="quotation.requiredFilesUrl"
                              style="margin-top: 20px"
                            >
                              <div class="col-2">
                                <a
                                  *ngIf="quotation.requiredFilesUrl.securityUrl"
                                  [tooltip]="
                                    quotation.requiredFilesUrl.securityName
                                  "
                                  href="{{
                                    quotation.requiredFilesUrl.securityUrl
                                  }}"
                                  download
                                  target="_blank"
                                  class="btn btn-xs btn-secondary"
                                >
                                  <i
                                    class="fa fa-download"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                              <div class="col-2">
                                <a
                                  *ngIf="quotation.requiredFilesUrl.suppliesUrl"
                                  [tooltip]="
                                    quotation.requiredFilesUrl.suppliesName
                                  "
                                  href="{{
                                    quotation.requiredFilesUrl.suppliesUrl
                                  }}"
                                  download="asd"
                                  target="_blank"
                                  class="btn btn-xs btn-secondary"
                                >
                                  <i
                                    class="fa fa-download"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                              <div class="col-2">
                                <a
                                  *ngIf="quotation.requiredFilesUrl.tecnicsUrl"
                                  [tooltip]="
                                    quotation.requiredFilesUrl.tecnicsName
                                  "
                                  href="{{
                                    quotation.requiredFilesUrl.tecnicsUrl
                                  }}"
                                  download
                                  target="_blank"
                                  class="btn btn-xs btn-secondary"
                                >
                                  <i
                                    class="fa fa-download"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                              <div class="col-2">
                                <a
                                  *ngIf="quotation.requiredFilesUrl.valueUrl"
                                  [tooltip]="
                                    quotation.requiredFilesUrl.valueName
                                  "
                                  href="{{
                                    quotation.requiredFilesUrl.valueUrl
                                  }}"
                                  download
                                  target="_blank"
                                  class="btn btn-xs btn-secondary"
                                >
                                  <i
                                    class="fa fa-download"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                            </div>
                            <div class="row padding-headers mt-4">
                              <div class="col-6">
                                <p class="zero font-8">P.U</p>
                              </div>
                              <div class="col-6">
                                <p class="zero font-8">IMPORTE</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="zero">
                    <tr
                      *ngFor="
                        let product of selectedRequest.requestProducts;
                        let indexRequest = index
                      "
                      class="zero"
                    >
                      <td class="strong" style="height: 10px">
                        <div class="row" style="width: 100%">
                          <div class="col-2 col-md-2 zero elseRow">
                            <p>{{ product.code }}</p>
                          </div>
                          <div
                            *ngIf="product.name.length <= 40"
                            class="col-3 col-md-3 zero nameRow"
                          >
                            <p class="font-9">
                              {{ indexRequest + 1 }}.&nbsp; {{ product.name }}
                            </p>
                            <div class="row">
                              <div class="col-12">
                                <sup>{{ product.comments }}</sup>
                              </div>
                            </div>
                          </div>

                          <div
                            [tooltip]="product.name"
                            *ngIf="product.name.length > 40"
                            class="col-3 col-md-3 zero nameRow"
                          >
                            <p class="font-9 over">{{ product.name }}</p>

                            <div class="row">
                              <div class="col-12">
                                <sup>{{ product.comments }}</sup>
                              </div>
                            </div>
                          </div>

                          <div class="col-2 col-md-2 zero elseRow">
                            <p class="font-7">{{ product.quantity }}</p>
                          </div>
                          <div
                            *ngIf="product.unit"
                            class="col-2 col-md-2 zero elseRow font-7"
                          >
                            <p class="font-7">{{ product.unit.name }}</p>

                            <sup></sup>
                          </div>
                          <div
                            *ngIf="!product.unit && product.unitName"
                            class="col-1 col-md-1 zero elseRow"
                          >
                            <p class="font-7">
                              {{ product.unitName }}
                            </p>

                            <sup></sup>
                          </div>
                          <div
                            *ngIf="
                              !product.specification &&
                              product.specificationName
                            "
                            style="overflow: auto"
                            class="col-1 col-md-1 zero elseRow"
                          >
                            <p class="font-7">
                              ${{ product.price | number : "1.2-2" }}
                            </p>
                          </div>
                          <div
                            *ngIf="
                              !product.specification &&
                              product.specificationName
                            "
                            style="overflow: auto"
                            class="col-2 col-md-2 zero elseRow"
                          >
                            <p class="font-7">
                              ${{
                                product.price * product.quantity
                                  | number : "1.2-2"
                              }}
                            </p>
                          </div>
                          <div
                            *ngIf="product.price"
                            style="overflow: auto"
                            class="col-1 col-md-1 zero font-7 elseRow"
                          >
                            <p class="font-7" style="font-size: 8px !important">
                              {{
                                getPercentContest(
                                  selectedRequest.requestProducts,
                                  product.price,
                                  product.quantity
                                )
                              }}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td
                        *ngFor="let quote of quotedCompanies; let i = index"
                        class="zero strong border-l-r companyWidthContest"
                        id="container-checkbox"
                      >
                        <div class="row companyWidthContest">
                          <div
                            *ngIf="quote.quotedProducts[indexRequest].winPrice"
                            class="col-1 col-md-1 font-1"
                            style="margin-top: 10px"
                          >
                            <i
                              class="fa fa-bookmark bookmark"
                              aria-hidden="true"
                            ></i>
                            <div class="leter-s sContest">s</div>
                          </div>

                          <div
                            *ngIf="!quote.quotedProducts[indexRequest].winPrice"
                            class="col-1 col-md-1 font-1"
                            style="margin-top: 10px"
                          ></div>
                          <div
                            class="col-2 col-md-2 fontPercent noPaddingCol"
                            [ngClass]="{
                              badPrice:
                                product.price <
                                quote.quotedProducts[indexRequest].amount,
                              goodPrice:
                                product.price >
                                quote.quotedProducts[indexRequest].amount
                            }"
                            style="margin-top: 20px"
                          >
                            {{
                              getPercentage(
                                product.price,
                                quote.quotedProducts[indexRequest].amount
                              )
                            }}
                            %
                          </div>
                          <div
                            style="margin-top: 15px"
                            *ngIf="
                              product.price <
                              quote.quotedProducts[indexRequest].amount
                            "
                            class="col-2 col-md-2"
                          >
                            <i
                              class="fa fa-arrow-up"
                              style="color: red"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div
                            style="margin-top: 15px"
                            *ngIf="
                              product.price >
                              quote.quotedProducts[indexRequest].amount
                            "
                            class="col-2 col-md-2"
                          >
                            <i
                              class="fa fa-arrow-down"
                              style="color: green"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div
                            style="margin-top: 15px"
                            *ngIf="
                              product.price ===
                              quote.quotedProducts[indexRequest].amount
                            "
                            class="col-2 col-md-2"
                          ></div>

                          <div
                            class="col-6 col-md-6 font8"
                            style="margin-top: 15px"
                          >
                            <div class="row">
                              <div class="col-6">
                                <sup style="font-size: 1em">
                                  {{
                                    quote.quotedProducts[indexRequest].amount
                                      | currency
                                  }}</sup
                                >
                              </div>
                              <div class="col-6">
                                <sup class="ml-15" style="font-size: 1em">
                                  {{
                                    quote.quotedProducts[indexRequest].amount *
                                      product.quantity | currency
                                  }}</sup
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <div class="row" style="width: 100%">
                        <div class="col-2 col-md-2 zero elseRow">
                          <p class="strong black font-1">Subtotal</p>
                        </div>
                        <div class="col-4 col-md-4 zero font-7 nameRow"></div>
                        <div class="col-2 col-md-2 zero elseRow"></div>
                        <div class="col-2 col-md-2 zero elseRow font-7"></div>
                        <div class="col-1 col-md-1 zero strong elseRow">
                          {{ subtotalBase | currency }}
                        </div>
                      </div>
                      <td
                        *ngFor="let quote of quotedCompanies; let i = index"
                        class="zero strong border-l-r companyWidthContest text-right"
                        id="container-checkbox"
                      >
                        {{ quote.amount | currency }}
                      </td>
                    </tr>
                    <tr>
                      <div class="row" style="width: 100%">
                        <div class="col-2 col-md-2 zero elseRow">
                          <p class="strong black font-1">Iva</p>
                        </div>
                        <div class="col-4 col-md-4 zero font-7 nameRow"></div>
                        <div class="col-2 col-md-2 zero elseRow"></div>
                        <div class="col-2 col-md-2 zero elseRow font-7"></div>
                        <div class="col-1 col-md-1 zero strong elseRow">
                          {{ subtotalBase * 0.16 | currency }}
                        </div>
                        <div class="col-1 col-md-1 zero font-7 elseRow"></div>
                      </div>
                      <td
                        *ngFor="let quote of quotedCompanies; let i = index"
                        class="zero strong border-l-r companyWidthContest text-right"
                        id="container-checkbox"
                      >
                        {{ quote.amount * 0.16 | currency }}
                      </td>
                    </tr>
                    <tr>
                      <div class="row" style="width: 100%">
                        <div class="col-2 col-md-2 zero elseRow">
                          <p class="strong black font-1">Total</p>
                        </div>
                        <div class="col-4 col-md-4 zero font-7 nameRow"></div>
                        <div class="col-2 col-md-2 zero elseRow"></div>
                        <div class="col-2 col-md-2 zero elseRow font-7"></div>
                        <div class="col-1 col-md-1 zero strong elseRow">
                          {{ subtotalBase * 1.16 | currency }}
                        </div>
                        <div class="col-1 col-md-1 zero font-7 elseRow"></div>
                      </div>
                      <td
                        *ngFor="let quote of quotedCompanies; let i = index"
                        class="zero strong border-l-r companyWidthContest text-right"
                        id="container-checkbox"
                      >
                        {{ quote.amount * 1.16 | currency }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="canvas row zero padding1 width" style="width: 1050px">
                <div
                  class="col-6 col-md-8 zero left black font-9 number-price"
                  *ngIf="groupQuotations.contestLabel === 'request'"
                >
                  Haz seleccionado proveedores para
                  <span *ngIf="selectedRequest" class="strong"
                    >{{ selectedAmount.length }}/{{
                      selectedRequest.requestProducts.length
                    }}</span
                  >
                  productos
                </div>
                <div class="col-6 col-md-4 zero">
                  <div
                    class="row zero"
                    *ngIf="groupQuotations.contestLabel === 'request'"
                  >
                    <div class="col-4 col-md-6 zero strong black font-1 right">
                      SUBTOTAL
                    </div>
                    <div
                      class="col-4 col-md-6 zero strong black font-8 price-number"
                    >
                      {{ getTotal(selectedAmount, false) | currency }}
                    </div>
                  </div>
                  <div
                    class="row zero"
                    *ngIf="groupQuotations.contestLabel === 'request'"
                  >
                    <div class="col-4 col-md-6 zero strong black font-1 right">
                      IVA:
                    </div>
                    <div class="col-8 col-md-6 zero strong black font-1 all">
                      {{ getIVA(selectedAmount) | currency }}
                    </div>
                  </div>
                  <div
                    class="row zero"
                    *ngIf="groupQuotations.contestLabel === 'request'"
                  >
                    <div class="col-4 col-md-6 zero strong black font-1 right">
                      Total:
                    </div>
                    <div class="col-8 col-md-6 zero strong black font-1 all">
                      {{ getIVA(selectedAmount) | currency }}
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="col-6 col-md-2 zero left black font-9 number-price"
                style="padding-top: 1em; padding-left: 2em"
              >
                <button
                  data-html2canvas-ignore
                  *ngIf="selectedRequest"
                  class="zero center collapse_title width font-1 white padding-01 disabled"
                  style="
                    margin-top: 10px;
                    background: #000;
                    border: solid transparent;
                    padding: 0.5em 0;
                  "
                  [disabled]="blockExport"
                  (click)="exportComparativa()"
                >
                  Exportar comparativa
                </button>

                <button
                  data-html2canvas-ignore
                  *ngIf="selectedRequest"
                  class="zero center collapse_title width font-1 white padding-01 disabled"
                  style="
                    margin-top: 10px;
                    background: #000;
                    border: solid transparent;
                    padding: 0.5em 0;
                  "
                  (click)="printCanvasContestLic()"
                >
                  Impresion
                </button>
              </div>

              <div class="canvas col-6 col-md-4 zero">
                <div class="row zero"></div>
              </div>
              <div
                *ngIf="technical || isDirector"
                class="canvas row zero padding1 width"
                style="width: 1050px; overflow: scroll"
              >
                <div class="col-12 col-md-8 zero left black font-9"></div>
                <div class="col-12 col-md-4 zero">
                  <!--   <div class="row zero">
                    <div class="col-4 col-md-6 zero strong black font-1 right">
                      TOTAL:
                    </div>
                    <div class="col-8 col-md-6 zero strong black font-1 all">
                      {{ getTotal(selectedAmount, true) | currency }} MXN
                    </div>
                  </div> -->
                </div>
                <div
                  id="cualitative-title"
                  class="col-12 col-md-12 noPaddingCol padding-top1"
                >
                  <div
                    class="row"
                    style="margin-top: 5px; min-height: 170px; height: auto"
                  >
                    <div class="col-5">
                      <img
                        class="logo"
                        src="./assets/images/login/icono.png"
                        alt=""
                      />
                      <div
                        id="cualitative-title"
                        class="col-12 col-md-12 noPaddingCol text-left"
                      >
                        <p class="title-analysis">ANÁLISIS CUALITATIVO</p>
                      </div>
                      <div
                        class="col-12"
                        style="
                          height: auto;
                          padding-left: 0px;
                          padding-right: 0px;
                        "
                      >
                        <p class="font-12 font-8 font-black noMb text-left">
                          Nombre: &nbsp; {{ selectedRequest.name }}
                        </p>
                      </div>

                      <div
                        class="col-12"
                        style="
                          height: auto;
                          padding-left: 0px;
                          padding-right: 0px;
                        "
                      >
                        <p class="font-12 font-8 font-black noMb text-left">
                          Proyecto: &nbsp;{{ selectedRequest.projectName }}
                        </p>
                      </div>

                      <div
                        class="col-12"
                        style="
                          height: auto;
                          padding-left: 0px;
                          padding-right: 0px;
                        "
                      >
                        <p
                          *ngIf="
                            this.groupQuotations &&
                            groupQuotations.contestLabel === 'request'
                          "
                          class="font-12 font-8 font-black noMb text-left"
                        >
                          Solicita: &nbsp;
                          {{ groupQuotations.whoRequesting }}
                        </p>
                      </div>

                      <div
                        class="col-12"
                        style="
                          height: auto;
                          padding-left: 0px;
                          padding-right: 0px;
                        "
                      >
                        <p
                          *ngIf="
                            this.groupQuotations &&
                            groupQuotations.contestLabel === 'request'
                          "
                          class="font-12 font-8 font-black noMb text-left"
                        >
                          Autoriza: &nbsp;{{ groupQuotations.whoAuthorizes }}
                        </p>
                      </div>
                      <div
                        class="col-12"
                        style="
                          height: auto;
                          padding-left: 0px;
                          padding-right: 0px;
                        "
                      >
                        <p class="font-12 font-8 font-black noMb text-left">
                          Responsable: &nbsp;{{ userRequest }}
                        </p>
                      </div>
                      <div
                        class="col-12"
                        style="
                          height: auto;
                          padding-left: 0px;
                          padding-right: 0px;
                        "
                      >
                        <p class="font-12 font-8 font-black noMb text-left">
                          Calificador: &nbsp;{{ userName }}
                        </p>
                      </div>
                      <div
                        class="col-12"
                        style="
                          height: auto;
                          padding-left: 0px;
                          padding-right: 0px;
                        "
                      >
                        <p class="font-12 font-8 font-black noMb text-left">
                          Fecha: &nbsp;{{
                            selectedRequest.limitQuotationDate
                              | date : "dd/MM/yyyy"
                          }}
                        </p>
                      </div>
                      <div
                        class="col-12"
                        style="
                          height: auto;
                          padding-left: 0px;
                          padding-right: 0px;
                        "
                      >
                        <p class="font-12 font-8 font-black noMb text-left">
                          Moneda: &nbsp; {{ currency }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <table id="cualitative" class="mb-40">
                  <tr>
                    <th class="border width-300">Aspectos a calificar</th>
                    <th class="border width-200">Ponderación</th>

                    <th
                      class="border width-300"
                      *ngFor="let quote of qualitative; let i = index"
                    >
                      <p class="text-center font-16">
                        {{ quote.company.name }}
                      </p>
                      <p
                        *ngIf="quote.company.companyInfo.city"
                        class="zero font-8 text-center"
                      >
                        {{ quote.company.companyInfo.city }}
                      </p>

                      <div class="row padding-headers mt-4">
                        <div class="col-6">
                          <p class="zero font-8 text-right">CALIF</p>
                        </div>
                        <div class="col-6">
                          <p class="zero font-8 text-left">VALOR</p>
                        </div>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <td class="d-grid">
                      <ng-container
                        *ngFor="let aspect of aspects; let i = index"
                      >
                        <div class="w-100">
                          <input
                            [disabled]="disableQualitative"
                            class="w-100 input-analysis no-boder"
                            [(ngModel)]="aspect.aspect"
                            type="text"
                          />
                        </div>
                      </ng-container>
                    </td>
                    <td class="d-grid">
                      <ng-container
                        *ngFor="let aspect of aspects; let i = index"
                      >
                        <div class="w-100">
                          <input
                            class="input-analysis no-boder"
                            [disabled]="disableQualitative"
                            [(ngModel)]="aspect.ponderation"
                            type="number"
                          />
                        </div>
                      </ng-container>
                    </td>
                    <td
                      class="d-grid"
                      *ngFor="let data of qualitative; let i = index"
                    >
                      <ng-container
                        *ngFor="
                          let qualy of data.company.qualitative;
                          let i = index
                        "
                      >
                        <div class="w-100">
                          <input
                            [disabled]="disableQualitative"
                            class="width-input input-analysis no-boder"
                            [(ngModel)]="qualy.calif"
                            (ngModelChange)="validateAnalysis(qualy.calif)"
                            type="number"
                          />
                          <label for="" class="p-label mb-0">{{
                            (qualy.calif * aspects[i].ponderation) / 100
                          }}</label>
                        </div>
                      </ng-container>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <button
                        class="mt-4 btn-qualy"
                        type="button"
                        *ngIf="!disableQualitative"
                        (click)="addAspect()"
                      >
                        Agregar
                      </button>
                      <button
                        class="mt-4 btn-qualy ml-15"
                        type="button"
                        *ngIf="!disableQualitative"
                        (click)="removeAspect()"
                      >
                        Quitar
                      </button>
                    </td>
                    <td>
                      <p class="p-label">{{ getPonderation() }} %</p>
                      <p style="color: red" *ngIf="getPonderation() > 100">
                        La ponderación no puede ser mas de 100% .
                      </p>
                    </td>
                    <td
                      class="d-grid"
                      *ngFor="let data of qualitative; let i = index"
                    >
                      <div class="w-100">
                        <label for="" class="p-label label-14">
                          {{
                            getTotals(data.company.qualitative, i)
                              | number : "1.0-0"
                          }}
                        </label>
                        <label for="" class="p-label new-margin label-14">
                          {{
                            getTotalsRep(data.company.qualitative, i)
                              | number : "1.1-1"
                          }}
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr id="resumen" class="mt-40">
                    <td class="width-350">
                      <p class="title-resume">RESUMEN</p>
                    </td>
                    <td class="width-250">
                      <p class="strong black font-1">IMPORTE DE LA PROPUESTA</p>
                    </td>

                    <td
                      *ngFor="let quote of quotedCompanies; let i = index"
                      class="zero strong black border-l-r companyWidthContest"
                      id="container-checkbox"
                    >
                      {{ quote.amount * 1.16 | currency }}
                    </td>
                  </tr>
                  <tr id="resumen2" class="mt-40">
                    <td class="width-350"></td>
                    <td class="width-250">
                      <p class="strong black font-1">DIFERENCIA VS MAS BAJO</p>
                    </td>

                    <td
                      [ngClass]="{
                        badPrice: quote.amount * 1.16 > minValue,
                        goodPrice: quote.amount * 1.16 === minValue,
                        black: minValue === quote.amount * 1.16
                      }"
                      *ngFor="let quote of quotedCompanies; let i = index"
                      class="zero strong black border-l-r companyWidthContest"
                      id="container-checkbox"
                    >
                      {{ getDifResume(quote, quotedCompanies)  | currency  }}
                    </td>
                  </tr>
                  <tr id="resumen3" class="mt-40">
                    <td class="width-300"></td>
                    <td class="width-200">
                      <p class="strong black font-1">%</p>
                    </td>

                    <td
                      [ngClass]="{
                        badPrice: quote.amount * 1.16 > minValue,
                        goodPrice: quote.amount * 1.16 === minValue,
                      }"
                      *ngFor="let quote of quotedCompanies; let i = index"
                      class="zero strong black border-l-r companyWidthContest"
                      id="container-checkbox"
                    >
                      {{
                        getDifPercentage(quote, quotedCompanies) 
                          | number : "1.0-0"
                      }}
                      %
                    </td>
                  </tr>
                  <tr id="resumen4" class="mt-40">
                    <td class="width-350"></td>
                    <td class="width-250">
                      <p class="strong black font-1">
                        CALIFICACIÓN CUALITATIVA
                      </p>
                    </td>

                    <td
                      *ngFor="let data of qualitative; let i = index"
                      class="zero strong black border-l-r companyWidthContest"
                      id="container-checkbox"
                    >
                      {{
                        getTotalsRep(data.company.qualitative, i)
                          | number : "1.1-1"
                      }}
                    </td>
                  </tr>
                </table>
                <div *ngIf="qualitative" class="col-12 padding-top1">
                  <label for="" class="p-label label-18"> Conclusiones </label>
                </div>
                <div *ngIf="qualitative" class="col-12 padding-top1">
                  <textarea
                    [(ngModel)]="qualitative.conclusion"
                    [disabled]="disableQualitative"
                    name=""
                    id=""
                    cols="160"
                    rows="8"
                  ></textarea>
                </div>
              </div>
              <div
                *ngIf="technical || isDirector"
                class="row d-block text-center"
              >
                <button
                  class="mt-4 btn-qualy"
                  type="button"
                  (click)="saveAnalysis()"
                  *ngIf="!disableQualitative"
                >
                  Guardar Análisis Cualitativo
                </button>
                <button
                  class="mt-4 btn-qualy align-right"
                  type="button"
                  (click)="exportAnalysis()"
                >
                  Exportar Análisis Cualitativo
                </button>
              </div>
              <div class="canvas row zero padding1 width" style="width: 1050px">
                <div class="col-12 col-md-8 zero left black font-9"></div>
                <div class="col-12 col-md-4 zero">
                  <div
                    class="row zero"
                    *ngIf="groupQuotations.contestLabel === 'request'"
                  >
                    <div class="col-4 col-md-6 zero strong black font-1 right">
                      TOTAL:
                    </div>
                    <div class="col-8 col-md-6 zero strong black font-1 all">
                      {{ getTotal(selectedAmount, true) | currency }} MXN
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>&nbsp;</div>
            <div
              class="row zero background-white padding5 width"
              style="border: solid 1px #f0f0f0; width: 100%"
            >
              <div class="col-xs-1 col-sm-4 col-md-4 zero text center"></div>
              <div class="col-xs-10 col-sm-4 col-md-4 text center"></div>
              <div class="col-xs-1 col-sm-4 col-md-4 text center"></div>
            </div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
          </div>
          <!--  -->
        </div>
      </div>
    </div>
    <!--END COMPARATIVA DECISION DE COMPRAS Contest-->

    <!-- <button  class="zero center collapse_title width font-1 white padding-01 disabled" style="background:#000;border:solid transparent;padding:.5em 0;"
		                             (click)="createCanvasContest()">
		   Test Canvas</button>
		   <button  class="zero center collapse_title width font-1 white padding-01 disabled" style="background:#000;border:solid transparent;padding:.5em 0;"
					                             (click)="saveContest()">
					    Contest</button> -->
    <!-- -->
    <!--VER COTIZACIÓN BORRADOR Y POR ACEPTAR para editar -->
    <div class="row target" id="decidir">
      <div class="container pad0">
        <div class="row zero confirmar background-gray">
          <div class="col-0 col-md-1zero"></div>
          <div class="col-2 col-md-2 collapse_titl padding-top1">
            <p class="goBack" (click)="goBackRecibidas()">
              <i class="fa fa-chevron-left" aria-hidden="true"></i>Regresar
              &nbsp;&nbsp;&nbsp;
            </p>
          </div>
          <div
            class="col-10 col-md-6 zero collapse_title padding-top1 strong font-8"
          >
            DECISIÓN DE COMPRA
          </div>
          <div class="col-3 col-md-3 zero"></div>
        </div>
        <div class="container-bg-blur zero padding-2"></div>
      </div>
    </div>
    <!-- -->

    <div id="tablesReceived" class="container recibidas">
      <div class="container-bg-blur">
        <!-- COLLAPSE 1 -->
        <div class="card">
          <div class="card-header" id="nuevas">
            <h5 class="mb-0">
              <a
                class="collapse_title black"
                data-toggle="collapse"
                href="#collapse-example1"
                aria-expanded="true"
                aria-controls="collapse-example1"
              >
                <i class="fa fa-chevron-down pull-right"></i>
                <div class="collapse_title">COTIZACIONES RECIBIDAS</div>
              </a>
            </h5>
          </div>
          <div
            id="collapse-example1"
            class="collapse show important-zero"
            aria-labelledby="heading-example"
          >
            <div class="card-block">
              <div
                class="row zero black background-white"
                [ngClass]="{
                  overTable: received.length > 6,
                  overTableSmall: received.length > 1,
                  overTableMed: received.length > 3
                }"
              >
                <!-- <app-client-table style="width: 100%;" [dataIn]="received" [type]="'decision'"
									[actions]="'true'" (openEvent)="viewData($event)"></app-client-table> -->
                <table
                  id="tableReceived"
                  class="table table-hover zero"
                  cellspacing="0"
                  width="100%"
                  style="width: 100%"
                >
                  <thead>
                    <tr>
                      <th class="font-7 strong center">FOLIO</th>
                      <th class="font-7 strong center">NOMBRE</th>
                      <th class="font-7 strong center">CENTRO DE COSTOS</th>
                      <!-- 											<th class="font-7 strong center ">RAMO</th>
 -->
                      <th class="font-7 strong center">CATEGORIA</th>
                      <th class="font-7 strong center">
                        FECHA LIMITE DE CIERRE
                      </th>
                      <th class="font-7 strong center">ACCION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let rec of received">
                      <td
                        (click)="openPurchaseDec(rec.id, rec)"
                        class="font-7 table-strong pointer"
                      >
                        {{ rec.id }}
                      </td>
                      <td
                        (click)="openPurchaseDec(rec.id, rec)"
                        class="font-7 table-strong pointer"
                      >
                        {{ rec.name }}
                      </td>
                      <td
                        (click)="openPurchaseDec(rec.id, rec)"
                        class="font-7 table-strong pointer"
                      >
                        {{ rec.projectName }}
                      </td>
                      <!-- <td (click)="openPurchaseDec(rec.id,rec)"
												class="font-7 table-font7 pointer">
												{{rec.sectorName}} </td> -->
                      <td
                        *ngIf="rec"
                        (click)="openPurchaseDec(rec.id, rec)"
                        class="font-7 table-font7 pointer"
                      >
                        {{
                          filterSubcategory(
                            rec.requestSubcategoriesArray,
                            rec.subcategoryFilter
                          )
                        }}
                      </td>
                      <td
                        (click)="openPurchaseDec(rec.id, rec)"
                        class="font-7 table-font7 pointer"
                      >
                        {{ rec.createdAt | date : "dd/MM/yyyy" }}
                      </td>
                      <td
                        class="pointer"
                        *ngIf="rec.contestLabel"
                        class="font-7 table-font7"
                      >
                        <a
                          *ngIf="rec.contestLabel === 'request'"
                          (click)="reDoContest(rec.id, 'request')"
                          style="font-weight: 600"
                          class="pointer"
                          >RECOTIZAR</a
                        >
                        <a
                          *ngIf="rec.contestLabel === 'contest'"
                          (click)="reDoContest(rec.id, 'contest')"
                          style="font-weight: 600"
                          class="pointer"
                          >RELICITAR</a
                        >
                      </td>
                      <td
                        *ngIf="!rec.contestLabel"
                        class="font-7 table-font7"
                      ></td>
                    </tr>
                  </tbody>
                </table>
                <div
                  class="col-md-12 background-white"
                  *ngIf="received.length === 0"
                >
                  <h2 class="text-center">No tienes recibidas para mostrar</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- COLLAPSE 2 -->
        <div class="card">
          <div class="card-header" id="nuevas">
            <h5 class="mb-0">
              <a
                class="collapse_title black"
                data-toggle="collapse"
                href="#collapse-example2"
                aria-expanded="true"
                aria-controls="collapse-example2"
              >
                <i class="fa fa-chevron-down pull-right"></i>
                <div class="collapse_title">
                  COMPRAS EN REVISIÓN DE SUPERVISOR
                </div>
              </a>
            </h5>
          </div>
          <div
            id="collapse-example2"
            class="collapse show"
            aria-labelledby="heading-example"
          >
            <div class="card-block">
              <div
                class="row zero black background-white"
                [ngClass]="{
                  overTable: review.length > 6,
                  overTableSmall: review.length > 1,
                  overTableMed: review.length > 3
                }"
              >
                <table
                  id="tableRewview"
                  class="table table-hover"
                  cellspacing="0"
                  width="100%"
                  style="width: 100%"
                >
                  <thead>
                    <tr>
                      <th class="font-7 center strong">FOLIO</th>
                      <th class="font-7 center strong">NOMBRE</th>
                      <th class="font-7 center strong">CENTRO DE COSTOS</th>
                      <th class="font-7 center strong">RAMO</th>
                      <th class="font-7 center strong">FECHA DE CREACIÓN</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="pointer"
                      (click)="finishRequest(rev.id)"
                      *ngFor="let rev of review"
                    >
                      <td class="font-7 talble-movile">
                        {{ rev.id }}
                      </td>
                      <td class="font-7 table-strong">
                        {{ rev.name }}
                      </td>
                      <td class="font-7 table-strong">
                        {{ rev.projectName }}
                      </td>
                      <td class="font-7 table-font7">
                        {{ rev.sectorName }}
                      </td>
                      <td class="font-7 table-font7">
                        {{ rev.createdAt | date : "dd/MM/yyyy" }}
                      </td>

                      <!-- 	<td class="font-8 talble-movile strong">
												<a class="button">Ver solicitud</a>
											</td> -->
                    </tr>
                  </tbody>
                </table>
                <div
                  class="col-12 col-md-12 background-white"
                  *ngIf="review.length === 0"
                >
                  <h2 class="text-center">
                    No tienes en revision para mostrar
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <app-footer></app-footer>
    <!--Footer -->
  </div>
</div>
<div class="modal hide fade in" tabindex="" role="dialog" id="orderPdf">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Orden De Compra</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div *ngIf="previewOrder" class="modal-body" style="height: 600px">
        <ng-container
          *ngFor="let order of prePurchaseOrders; let i = index"
          style="height: 100%"
        >
          <app-pdfOrder
            *ngIf="i === purchaseOrderIndex"
            (confirmedOrder)="order($event)"
            [purchaseOrder]="order"
            [request]="selectedRequest"
            [extra]="extra"
          ></app-pdfOrder>
        </ng-container>
      </div>
      <div class="modal-footer" style="display: block">
        <button
          type="button"
          class="btn btn-secondary pull-left"
          data-dismiss="modal"
          (click)="nextPreviewPurchaseOrderCancelled()"
        >
          Cancelar esta Orden
        </button>

        <button
          type="button"
          class="btn btn-primary pull-right"
          (click)="nextPreviewPurchaseOrder()"
          style="
            background-color: #000 !important ;
            border: 1px solid #000 !important;
          "
        >
          Enviar Compra
        </button>
        <button
          *ngIf="purchaseOrderIndex === 0"
          type="button"
          class="btn btn-secondary pull-right"
          data-dismiss="modal"
        >
          Volver
        </button>
      </div>
    </div>
  </div>
</div>
e
