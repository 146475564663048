<!----------------------->
<!-- SEARCHBAR AND BUTTONS -->
<div class="row" style="width:100%">
  <div class="col-6 ">
    <p class="popUpTag orangeText newTitle fontTitle" (click)="$event.stopPropagation();">
      {{ configObject ? configObject.buttonTitle : 'N/A'}}
      <mat-spinner *ngIf="loading" class="modalSpinner"></mat-spinner>
    </p>
  </div>
  <div class="col-6 ">
    <p class="popUpTag orangeText newTitle fontTitle" (click)="$event.stopPropagation();">
      SubCategorias
      <mat-spinner *ngIf="loading" class="modalSpinner"></mat-spinner>
    </p>
  </div>
</div>



<div class="popUpTag2 customSelectContainer customAutoInputContainer searchContainer"
  (click)="$event.stopPropagation();">
  <div class="row fullWidth">
    <!----------------------->
    <!-- INPUT -->
    <div class="col-12 col-md-8">
      <div class="customInput">
        <input type="text" class="fullHeight" matInput
          [placeholder]="configObject ? configObject.inputPlaceholder : 'N/A'" id="searchInput" #searchInput
          [formControl]="search">
        <button type="button" class="searchbarSuffix fsize22" *ngIf="search.value"  aria-label="Clear"
          (click)="search.setValue('')">
   </button>
      </div>
    </div>
    <!----------------------->
    <!-- ACTION BUTTONS -->
    <div class="col-12 col-md-4">
      <div class="actionButtonsContainer">
        <!----------------------->
        <!-- edit button -->
        <button class="orangeButton topButton" matRipple (click)="clearFilter()">
 <span>Limpiar </span>
        </button>
      </div>
    </div>
  </div>
</div>



<!----------------------->
<!-- SELECTED LIST (MOBILE) -->
<p class="popUpTag orangeText" *ngIf="!isBigSize" (click)="$event.stopPropagation();">
  <label class="inputLabel">Seleccionados</label>
</p>
<div class="selectedContainer" *ngIf="!isBigSize">
  <p class="popUpTag popUpMenuText filterTagItem cut-text" *ngFor="let selected of selectedObjects"
    (click)="$event.stopPropagation();">
    <mat-checkbox checked="true" (change)="removeObject(selected)">{{ selected[configObject.showProperty] }}
    </mat-checkbox>
  </p>
  <p *ngIf="selectedObjects.length === 0" (click)="$event.stopPropagation();" class="modalNotFound"
    id="responsiveNotSelected">
    No hay clientes seleccionados
  </p>
</div>
<div class="row">
  <!----------------------->
  <!-- SEARCH LIST -->
  <div class="col-12 col-md-6 searchListContainer" (click)="$event.stopPropagation();">

    <div infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="25" (scrolled)="onSearchScroll()"
      [scrollWindow]="false" (click)="$event.stopPropagation();" class="searchResultsContainer">
      <div class="ltPopUpTag popUpMenuText cut-text checkItem pad3" *ngFor="let filtered of filteredObjects">
        <mat-checkbox   [checked]="filtered.checked" (change)="selectCategory(filtered,$event)"   color="primary">
        </mat-checkbox><span style="margin-left:2% ; cursor:pointer;"  [style.color]="filtered.seen" class="selectView" (click)="viewSubcategoriesInCategory(filtered)" >{{ filtered[configObject.showProperty] }}</span>
        <!-- SPECIFIC FUNCTIONALITY TEMPLATES -->
        <div *ngIf="configObject.hasSuffix">
          <!----------------------->
          <!-- BRANCH ACCESS (USER TYPE) -->
          <div *ngIf="configObject.suffixType === 'branchFilter'">
            <div *ngIf="filtered.role !== 'generalAdmin'">
              <div class="branchesCircleContainer">
                <div class="branchCircle" *ngFor="let branch of filtered.branches"
                  [style.background-color]="branch.color" [matTooltip]="branch.name" matTooltipPosition="above">
                </div>
              </div>
            </div>
            <p *ngIf="filtered.role === 'generalAdmin'" matTooltip="Admin" matTooltipPosition="above">A</p>
          </div>
          <!----------------------->
          <div>
          </div>
        </div>
        <div>
          <i  (click)="viewSubcategoriesInCategory(filtered)" [style.color]="filtered.seen"  style="cursor:pointer;" class="fa fa-eye selectView" aria-hidden="true"></i>

        </div>
      </div>
    </div>
  </div>
  <!----------------------->
  <!-- SELECTED LIST (DESKTOP) -->
  <div class="col-12 col-md-6 resultsListContainer" *ngIf="isBigSize" (click)="$event.stopPropagation();">
   
    <div class="selectedContainer">
      <p class="ltPopUpTag popUpMenuText filterTagItem cut-text" *ngFor="let selected of selectedObjects"
        (click)="$event.stopPropagation();">
        <mat-checkbox color="primary" [checked]="selected.checked" (change)="removeObjectSubCategory(selected,$event)">
          {{ selected[configObject.showProperty] }}</mat-checkbox>
      </p>
    </div>
  </div>
</div>
<div class="row" style="width:100% ; margin-top: 50px ; background-color: #f7f7f7">
    <mat-chip-list >
        <mat-chip *ngFor="let sub of companySubCategories" [selectable]="false"
        [removable]="false" > {{sub.name}}</mat-chip>
       
      </mat-chip-list>
    </div>