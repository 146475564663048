import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { RespondQuotation } from '../../shared/respondQuotation/respondQuotation.component';
import { CardlistService } from '../../../services/cardlist.service';


import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../../../services/apiService';
import swal from 'sweetalert2'
import { Router, Event, NavigationEnd } from '@angular/router';
import { QueryFactory } from '../../../tableQueries/queryFactory';
import { FooterComponent } from '../../../components/footer/footer.component';

declare var $: any;
@Component({
  selector: 'app-pendingQuotations',
  templateUrl: './pendingQuotations.component.html',
  styleUrls: ['./pendingQuotations.component.css'],
  providers: [ApiService, CardlistService]
})

export class PendingQuotationsComponent implements OnInit {
  public isClassVisible: string;
  public targetaExpres: Array<any>;
  public targetaNueva: Array<any>;
  public razonderechazo: Array<any>;
  public eldetalle: Array<any>;
  public verid: any;
  public cardView: Boolean;
  public listView: Boolean;
  public porCotizar: Array<any> = [

  ];
  public selectedProductImageURL = '';
  public verDetalleQuotation = false;
  public previewQuotation: any;
  public selectedRequest: any;
  paraenviada2 = false;
  paraenviada1 = false;
  porcotizar = true;
  bloque = true;
  paranueva = false;
  paraaceptadas = false;
  // Rating
  max: number = 5;
  rate: number = 4;
  isReadonly: boolean = true;
  constructor(private _apiService: ApiService,
    public queryFactory: QueryFactory,
    private _cardListService: CardlistService, private spinner: NgxSpinnerService, private _router: Router) {
    this.isClassVisible == 'false';
    this.verid = 0;

    this.cardView = true;
    this.listView = false;


    this.razonderechazo = [
      { "id": "0", "razon": "La fecha límite para cotizar es muy cercana", "elemento": "false" },
      { "id": "1", "razon": "La fecha límite para enviar es muy cercana", "elemento": "false" },
      { "id": "2", "razon": "Mi empresa no vende los productos solicitadso", "elemento": "false" },
      { "id": "3", "razon": "La cantidad solicitada es muy poca", "elemento": "false" },
      { "id": "4", "razon": "La cantidad solicitada es demasiada", "elemento": "false" },
      { "id": "5", "razon": "La empresa que solicita la cotización no me parece confiable", "elemento": "false" }
    ];

  }
  filterSubcategory(subcategories, id) {
    if (subcategories) {
      var result = subcategories.filter(obj => {
        return obj.id === id
      });
      if (result) {
        if (result[0]) {
          return result[0].name;

        } else {
          return '';
        }
      } else {
        return '';
      }
    } else {
      return '';
    }

  }
  disables(idClic) {

    this.verid = idClic;
    this.razonderechazo[idClic].elemento = "true";
    this.isClassVisible = 'true';
  }
  emitedQuotation(event: any) {
    console.log('EVENTOOOOO', event);
    this.previewQuotation = event;
  }
  emitedProducFileURl(event: any) {
    console.log('EVENTOOOOO', event);
    $('#modalProductImage').modal('show');
    this.selectedProductImageURL = event;
  }
  confirmedQuotation(event: any) {
    console.log('EVENTOOOOO', event);
  }
  getPorCotizar(type, id?) {
    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');

    let branchId = localStorage.getItem('userBranchId');
    let userId = localStorage.getItem('$LoopBack$currentUserId');

    let params = {

      userId: userId,
      access_token: tokenId
    };
    this._apiService.getDataObjects(type, branchId, params).subscribe(
      (result: any)=> {

        this.porCotizar = result.data.accepted;
        console.log(this.porCotizar)



      },
      error => {
        let errorMessage = <any>error;
        if (errorMessage != null) {
          console.log(errorMessage);

        }
      }

    );
  }


  ngOnInit() {
    this._router.events.subscribe(
      (value: Event) => {
        if (value instanceof NavigationEnd) {
          this.getPorCotizar('Requests/pendingQuotations');

        }
      });
    this.getPorCotizar('Requests/pendingQuotations');
  
    this._cardListService.getView().subscribe((test) => {
      /* console.log(test); */
      this.listView = test;
      this.cardView = !test;
    });

    $('#cotizacionPdf').on('hidden.bs.modal', () => {

      this.previewQuotation = false;
    });
  }

  cardViewClick() {
    this.listView = false;
    document.getElementById("card").style["display"] = "block";
    document.getElementById("list").style["display"] = "none";
    setTimeout(() => { this.cardView = true; });


  };
  listViewClick() {
    document.getElementById("list").style["display"] = "block";
    document.getElementById("card").style["display"] = "none";
    setTimeout(() => { this.listView = true; });
    this.cardView = false;
  };
  savePreviewedQuotation() {
    // $('#cotizacionPdf').modal('show')



    this.spinner.show();

    let quotedProducts = [];
    let quotedProduct = {};
    let today = new Date()
    let companyId = localStorage.getItem('$LoopBack$currentCompanyId');

    let branchId = '';
    let userRole = localStorage.getItem('$LoopBack$currentUserRole');
    if (userRole === 'Director' || userRole === 'Gerente') {
      if (this.selectedRequest.temporalBranch) {
        branchId = this.selectedRequest.temporalBranch;

      } else {
        console.log('no venia el temporal branch')
      }

    } else {
      branchId = localStorage.getItem('userBranchId');

    }
    if (this.checkIfQuoted(this.selectedRequest.id)) {



    } else {
      if (this.selectedRequest.quotedBranches) {
        console.log('YA HAY QUOTED');

        this.selectedRequest.quotedBranches.push(branchId);
        this.editRequest(this.selectedRequest.id, this.selectedRequest, true);
      } else {
        console.log('NO HAY QUOTED');

        this.selectedRequest['quotedBranches'] = [branchId];
        console.log(this.selectedRequest.quotedBranches);
        this.editRequest(this.selectedRequest.id, this.selectedRequest, true);


      }
      console.log(this.selectedRequest)
    }


    this.selectedRequest.requestProducts.forEach((product, index) => {
      quotedProduct['name'] = product.name;
      quotedProduct['createdAt'] = product.createdAt;
      quotedProduct['requestId'] = product.requestId;
      quotedProduct['quantity'] = product.quantity;
      quotedProduct['categoryId'] = product.categoryId;
      quotedProduct['specificationId'] = product.specificationId;
      quotedProduct['subCategoryId'] = product.subCategoryId;
      quotedProduct['companyId'] = product.companyId;
      quotedProduct['userId'] = product.userId;

      quotedProduct['requestProductId'] = product.requestProductId;
      quotedProduct['comments'] = product.comments;
      quotedProduct['amount'] = Number(product.amount);
      // quotedProduct['amount'] = parseFloat(product.amount).toFixed(2)

      quotedProducts.push(quotedProduct);
      console.log(product)

      console.log(product.amount)
      console.log(Number(product.amount))
      console.log(product.quantity)



    });
    let userId = localStorage.getItem('$LoopBack$currentUserId');

    let quotation = this.previewQuotation;
    quotation.branchId = this.selectedRequest.temporalBranch;
    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
    let params = {
      access_token: tokenId
    }
    console.log(quotation);



    this._apiService.addDataObject('Quotations' , quotation).subscribe(
      (result: any)=> {
        console.log('inserto quotation')
        setTimeout(() => {
          this.spinner.hide()
          swal({
            title: 'Guardado!',
            text: 'Cambios guardados correctamente.',
            type: 'success',
            confirmButtonClass: 'btn ',

            confirmButtonColor: '#000'
          })
          $('#cotizacionPdf').modal('hide')
          this._router.navigate(['/ventasenviadas']);



        }, 500);
      },
      error => {
        if (error != null) {
          // console.log(error);
          setTimeout(() => {

            this.spinner.hide()
            swal(
              'Error!',
              'Error al Guardar.',
              'error'
            )
            $('#cotizacionPdf').modal('hide')


          }, 250);

        }
      }

    );


  }
  checkIfQuoted(requestId):any {
    let companyId = localStorage.getItem('$LoopBack$currentCompanyId');

    let filter = {
      filter: {
        where: {
          companyId: companyId


        }
      }

    };
    this._apiService.getDataObjectsFilter('Requests/' + requestId + '/quotations/count', filter).subscribe(
      (result: any)=> {
        console.log('Verified if Count');

        console.log(result);
        this.checkIfQuotedBranch(this.selectedRequest.id)
        if (result.count === 0) {
          return false;
        } else {
          return true;

        }

      },
      error => {
        if (error != null) {
          console.log(error);
          return false;
        }
      }
    );
  }
  checkIfQuotedBranch(requestId) {
      // Do some async stuff
      let tokenId = localStorage.getItem('$LoopBack$accessTokenId');

      let params = {

        access_token: tokenId
      };
      this._apiService.getDataById('Requests', requestId, params).subscribe(
        (result: any)=> {
          console.log(result)
        this.selectedRequest.quotedBranches =result.quotedBranches;
        





        },
        error => {
          if (error != null) {
            console.log(error);
          }
        }

      );

  

  }
  cancelPreviewedQuotation() {



    swal(
      'Cancelado!',
      'No se envio la cotizacion.',
      'error'
    )
    $('#cotizacionPdf').modal('hide')

  }
  PreviewedQuotation() {
    // $('#cotizacionPdf').modal('show')



    this.spinner.show();

    let quotedProducts = [];
    let quotedProduct = {};
    let today = new Date()
    let companyId = localStorage.getItem('$LoopBack$currentCompanyId');

    let branchId = '';
    let userRole = localStorage.getItem('$LoopBack$currentUserRole');
    if (userRole === 'Director' || userRole === 'Gerente') {
      if (this.selectedRequest.temporalBranch) {
        branchId = this.selectedRequest.temporalBranch;

      } else {
        console.log('no venia el temporal branch')
      }

    } else {
      branchId = localStorage.getItem('userBranchId');

    }
    if (this.selectedRequest.quotedBranches) {
      console.log('YA HAY QUOTED');

      this.selectedRequest.quotedBranches.push(branchId);
      this.editRequest(this.selectedRequest.id, this.selectedRequest, true);
    } else {
      console.log('NO HAY QUOTED');

      this.selectedRequest['quotedBranches'] = [branchId];
      console.log(this.selectedRequest.quotedBranches);
      this.editRequest(this.selectedRequest.id, this.selectedRequest, true);


    }
    console.log(this.selectedRequest.requestProducts)

    this.selectedRequest.requestProducts.forEach((product, index) => {
      quotedProduct['name'] = product.name;
      quotedProduct['createdAt'] = product.createdAt;
      quotedProduct['requestId'] = product.requestId;
      quotedProduct['quantity'] = product.quantity;
      quotedProduct['categoryId'] = product.categoryId;
      quotedProduct['specificationId'] = product.specificationId;
      quotedProduct['subCategoryId'] = product.subCategoryId;
      quotedProduct['companyId'] = product.companyId;
      quotedProduct['userId'] = product.userId;

      quotedProduct['requestProductId'] = product.requestProductId;
      quotedProduct['comments'] = product.comments;
      quotedProduct['amount'] = Number(product.amount);
      // quotedProduct['amount'] = parseFloat(product.amount).toFixed(2)

      quotedProducts.push(quotedProduct);
      console.log(product)

      console.log(product.amount)
      console.log(Number(product.amount))
      console.log(product.quantity)



    });
    let userId = localStorage.getItem('$LoopBack$currentUserId');

    let quotation = this.previewQuotation;

    //this.previewQuotation=quotation;
    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
    let params = {
      access_token: tokenId
    }
    console.log(quotation);



    this._apiService.addDataObject('Quotations' , quotation).subscribe(
      (result: any)=> {
        console.log('inserto quotation')
        setTimeout(() => {
          this.spinner.hide()
          swal({
            title: 'Guardado!',
            text: 'Cambios guardados correctamente.',
            type: 'success',
            confirmButtonClass: 'btn ',

            confirmButtonColor: '#000'
          })
          $('#cotizacionPdf').modal('hide')
          this._router.navigate(['/ventasenviadas']);



        }, 500);
      },
      error => {
        if (error != null) {
          // console.log(error);
          setTimeout(() => {

            this.spinner.hide()
            swal(
              'Error!',
              'Error al Guardar.',
              'error'
            )
            $('#cotizacionPdf').modal('hide')


          }, 250);

        }
      }

    );


  }
  rejectRequest(request) {
    //this.selectedRequest = request ;
    console.log(request);
    let branchId = '';
    let userRole = localStorage.getItem('$LoopBack$currentUserRole');
    if (userRole === 'Director' || userRole === 'Gerente') {
      if (request.temporalBranch) {
        branchId = request.temporalBranch;

      } else {
        console.log('no venia el temporal branch')
      }

    } else {
      branchId = localStorage.getItem('userBranchId');

    }
    if (request.rejectedBranches) {
      request.rejectedBranches.push(branchId);
      this.editRequest(request.id, request, false);
    } else {

      request['rejectedBranches'] = [branchId];
      this.editRequest(request.id, request, false);


    }

  }
  editRequest(id: any, request, accepted) {
    this.spinner.show();

    console.log(request);

    let branchId = localStorage.getItem('userBranchId');

    console.log(id)
    if (request.temporalBranch) {
      //delete request.temporalBranch;

    }

    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
    let params = {
      access_token: tokenId
    }
    console.log(params)
    this._apiService.editDataObject('Requests', id, request, params).subscribe(
      (result: any)=> {
        console.log(result);
        setTimeout(() => {
          this.spinner.hide();
          if (accepted) {
            swal({
              title: 'Aceptada!',
              text: 'Cotizacion correctamente aceptada.',
              type: 'success',
              confirmButtonClass: 'btn ',

              confirmButtonColor: '#000'
            })

          } else {
            this.spinner.hide();
            swal({
              title: 'Rechazada!',
              text: 'Cotizacion rechazada .',
              type: 'info',
              confirmButtonClass: 'btn ',

              confirmButtonColor: '#000'
            })
          }

          this._router.navigate(['/porcotizar']);



        }, 500);


      },
      error => {
        this.spinner.hide();

        if (error != null) {


        }
      }

    );

  }
  ver(requestAccepted) {
    let branchId = localStorage.getItem('userBranchId');
    this.verDetalleQuotation = true;
    this.selectedRequest = requestAccepted;
    console.log(this.selectedRequest);
    //  localStorage.setItem('provisionalRequest', JSON.stringify(this.selectedRequest))

    $('#ver').show(400);
    $('#regresa').hide(10);
    $('#paraenviada1').hide(0);
    $('#paraenviada2').hide(0);
  }
  verRegresa() {
    this.selectedRequest = null;
    this.verDetalleQuotation = false;

    $('#regresa').show(400);
    $('#ver').hide(10);
  }
  niegaRechazo(id) {
    $('#rechaza' + id).show(600);
    $('#rechazada' + id).hide(100);
  }
  cancelar(id) {
    $('#rechazada' + id).show(400);
    $('#rechaza' + id).hide(10);
  }
}
