<!--= botones de menu para ventas ============================================================== -->
<div class="row width zero type-view">
    <div class="col-9 col-md-0 zero text name-page is-movil"> ACEPTADAS </div>

 <!--    <div class="col-3 col-md-3 zero view-toggle">
        <div class="row width zero">
            <div class="col-6 col-md-1 zero margin-r-20">
                <button type="button" class="btn btn-demo button-card " (click)="cardViewClick()">
                    <i class="fa fa-th-large font-7" aria-hidden="true"></i>
                </button>
            </div>
            <div class="col-6 col-md-1 zero cero center margin-15">
                <div class="nav-item dropdown">
                    <button type="button" class="btn btn-demo button-list" (click)="listViewClick()">
                        <i class="fa fa-list font-7" aria-hidden="true"></i>
                    </button>

                </div>
            </div>
        </div>

    </div> -->
</div>
<!--= botones de menu para ventas ============================================================== -->

<!-- Modal PARA LAS encuesta de ventas-->
<div class="modal fade gb_modal" id="llenaencuesta" tabindex="-1" data-backdrop="false" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content martop30">
            <div class="modal-body zero">
                <button type="button" class="close btn_close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>

                <div class="row tabla width">
                    <div class="row width" style="height:14em">
                        <div class="collapse_title padding1 center font-8"> Contesta la encuesta</div>
                        <div class="container-fluid">
                            <div class="row linehr width asks">
                                <div class="col-2 col-md-2 text bold zero">
                                    <i class="fa fa-question-circle-o ask" aria-hidden="true"></i>
                                </div>
                                <div class="col-7 col-md-7 text bold zero">
                                    <p class="asks">¿Su pedido llego completo?</p>
                                </div>
                                <div class="col-3 col-md-3 text bold zero">
                                    <div class="row width">
                                        <div class="col-4 col-md-4 text bold zero">
                                            <input class="hidden radio-label yes-button" type="radio"
                                                name="accept-offers" id="yes-button1" checked="checked" />
                                            <label class="button-label" for="yes-button1">
                                                <h1>Si</h1>
                                            </label>
                                        </div>
                                        <div class="col-4 col-md-4 text bold zero">
                                            <input class="hidden radio-label no-button" type="radio"
                                                name="accept-offers" id="no-button1" />
                                            <label class="button-label" for="no-button1">
                                                <h1>No</h1>
                                            </label>
                                        </div>
                                        <div class="col-4 col-md-4 text bold zero">

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row linehr width asks">
                                <div class="col-2 col-md-2 text bold zero">
                                    <i class="fa fa-question-circle-o ask" aria-hidden="true"></i>
                                </div>
                                <div class="col-7 col-md-7 text bold zero">
                                    <p class="asks">¿Su pedido llego completo?</p>
                                </div>
                                <div class="col-3 col-md-3 text bold zero">
                                    <div class="row width">
                                        <div class="col-4 col-md-4 text bold zero">
                                            <input class="hidden radio-label yes-button" type="radio"
                                                name="accept-offers" id="yes-button2" checked="checked" />
                                            <label class="button-label" for="yes-button2">
                                                <h1>Si</h1>
                                            </label>
                                        </div>
                                        <div class="col-4 col-md-4 text bold zero">
                                            <input class="hidden radio-label no-button" type="radio"
                                                name="accept-offers" id="no-button2" />
                                            <label class="button-label" for="no-button2">
                                                <h1>No</h1>
                                            </label>
                                        </div>
                                        <div class="col-4 col-md-4 text bold zero">

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row linehr width asks">
                                <div class="col-2 col-md-2 text bold zero">
                                    <i class="fa fa-question-circle-o ask" aria-hidden="true"></i>
                                </div>
                                <div class="col-7 col-md-7 text bold zero">
                                    <p class="asks">¿Su pedido llego completo?</p>
                                </div>
                                <div class="col-3 col-md-3 text bold zero">
                                    <div class="row width">
                                        <div class="col-4 col-md-4 text bold zero">
                                            <input class="hidden radio-label yes-button" type="radio"
                                                name="accept-offers" id="yes-button3" checked="checked" />
                                            <label class="button-label" for="yes-button3">
                                                <h1>Si</h1>
                                            </label>
                                        </div>
                                        <div class="col-4 col-md-4 text bold zero">
                                            <input class="hidden radio-label no-button" type="radio"
                                                name="accept-offers" id="no-button3" />
                                            <label class="button-label" for="no-button3">
                                                <h1>No</h1>
                                            </label>
                                        </div>
                                        <div class="col-4 col-md-4 text bold zero">

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row linehr width asks">
                                <div class="col-2 col-md-2 text bold zero">
                                    <i class="fa fa-question-circle-o ask" aria-hidden="true"></i>
                                </div>
                                <div class="col-7 col-md-7 text bold zero">
                                    <p class="asks">¿Su pedido llego completo?</p>
                                </div>
                                <div class="col-3 col-md-3 text bold zero">
                                    <div class="row width">
                                        <div class="col-4 col-md-4 text bold zero">
                                            <input class="hidden radio-label yes-button" type="radio"
                                                name="accept-offers" id="yes-button5" checked="checked" />
                                            <label class="button-label" for="yes-button5">
                                                <h1>Si</h1>
                                            </label>
                                        </div>
                                        <div class="col-4 col-md-4 text bold zero">
                                            <input class="hidden radio-label no-button" type="radio"
                                                name="accept-offers" id="no-button5" />
                                            <label class="button-label" for="no-button5">
                                                <h1>No</h1>
                                            </label>
                                        </div>
                                        <div class="col-4 col-md-4 text bold zero">

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row linehr width asks">
                                <div class="col-2 col-md-2 text bold zero">
                                    <i class="fa fa-question-circle-o ask" aria-hidden="true"></i>
                                </div>
                                <div class="col-7 col-md-7 text bold zero">
                                    <p class="asks">¿Su pedido llego completo?</p>
                                </div>
                                <div class="col-3 col-md-3 text bold zero">
                                    <div class="row width">
                                        <div class="col-4 col-md-4 text bold zero">
                                            <input class="hidden radio-label yes-button" type="radio"
                                                name="accept-offers" id="yes-button6" checked="checked" />
                                            <label class="button-label" for="yes-button6">
                                                <h1>Si</h1>
                                            </label>
                                        </div>
                                        <div class="col-4 col-md-4 text bold zero">
                                            <input class="hidden radio-label no-button" type="radio"
                                                name="accept-offers" id="no-button6" />
                                            <label class="button-label" for="no-button6">
                                                <h1>No</h1>
                                            </label>
                                        </div>
                                        <div class="col-4 col-md-4 text bold zero">

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="row zero width">
                        <div class="col-3 col-md-3"></div>
                        <div class="col-6 col-md-6">
                            <button class="zero font8 btn_encuesta" data-toggle="modal" data-target="#llenaencuesta">
                                ENCUESTA TERMINADA</button>
                        </div>
                        <div class="col-3 col-md-3"></div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Modal PARA LAS RESEÑAS-->
<div class="modal fade gb_modal" id="exampleModalCenter" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content martop30">
            <div class="modal-body zero">
                <button type="button" class="close btn_close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <app-review></app-review>
            </div>
        </div>
    </div>
</div>

<div class="container_bg" style="background-image: url(assets/images/2023/bg.png)">

    <div class="container-scroll">
        <!-- DETALLE COTIZACION ====== -->
        <div class="row cover" id="verchat">
            <div *ngIf="selectedQuotation" class="container pad0">
                <div class="row zero background-gray padding-top1 strong">
                    <div class="col-12 col-md-2 zero collapse_title goBack" (click)="verRegresa()"> <i
                            class="fa fa-chevron-left" aria-hidden="true"></i> Regresar</div>
                    <div class="col-2 col-md-2 zero remove"></div>
                    <div class="col-4 col-md-4 zero remove collapse_title font-8 right"> Venta concretada <span
                            class="strong gray " *ngIf="selectedQuotation">{{selectedQuotation.company.name}} </span>
                        <span *ngIf="selectedQuotation.company" class="collapse_title_bold">
                            <rating *ngIf="!selectedQuotation.company.rating" class="remove" [(ngModel)]="rate"
                                [max]="max" [readonly]="isReadonly"></rating>
                            <rating *ngIf="selectedQuotation.company.rating" class="remove"
                                [(ngModel)]="selectedQuotation.company.rating" [max]="max" [readonly]="isReadonly">
                            </rating>

                        </span>
                    </div>
                    <div class="col-1 col-md-1 zero remove"></div>

                    <div class="col-5 col-md-3 remove zero collapse_title">
                        <p class="collapse_title_bold" *ngIf="selectedQuotation"> Folio :{{selectedQuotation.id}} </p>
                    </div>
                </div>
                <div class="container-bg-blur zero">
                    <div class="row width">
                        <div class="container ">

                            <app-chat [selectedQuotation]="selectedQuotation" [indexPurchase]="0"
                                *ngIf="chatComponent" [ventas]="true"></app-chat>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- DETALLE COTIZACION ====== -->
        <div class="row cover" id="verSendedOld">
            <div class="container pad0">
                <div *ngIf="selectedQuotation" class="row zero background-gray padding-top1 strong">
                    <div class="col-12 col-md-2 zero collapse_title goBack" (click)="Regresa()"> <i
                            class="fa fa-chevron-left" aria-hidden="true"></i> TORNILLOS "3/4"</div>
                    <div class="col-1 col-md-1 remove zero"></div>
                    <div class="col-2 col-md-2 remove zero"></div>
                    <div class="col-4 col-md-4 remove zero collapse_title font-8 right"> Venta a <span
                            class="strong gray ">
                        </span>
                        <span *ngIf="selectedQuotation.company" class="collapse_title_bold">
                            <rating *ngIf="!selectedQuotation.company.rating" class="remove" [(ngModel)]="rate"
                                [max]="max" [readonly]="isReadonly"></rating>
                            <rating *ngIf="selectedQuotation.company.rating" class="remove"
                                [(ngModel)]="selectedQuotation.company.rating" [max]="max" [readonly]="isReadonly">
                            </rating>

                        </span>
                    </div>
                    <div class="col-3 col-md-3 remove zero collapse_title">
                        <p class="collapse_title_bold" *ngIf="selectedQuotation"> {{selectedQuotation.id}} </p>
                    </div>
                </div>
                <div class="container-bg-blur zero">
                    <div class="row width">
                        <div class="container " *ngIf="selectedQuotation">
                            <app-viewAcceptedQuotation [request]="selectedQuotation"></app-viewAcceptedQuotation>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="row cover" id="verSended">
            <div class="container">
                <div class="row zero background-gray  strong" style="padding-top: .5em; padding-bottom: 0;">
                    <div class="col-2 col-md-1 zero collapse_title goBack" (click)="verRegresa()">
                        <i class="fa fa-chevron-left marginTop" aria-hidden="true"></i>
                    </div>
                    <div class="col-0 col-md-2 zero remove">
                        <p  style="font-size: .8em; margin-top:.5em;"> Venta  a</p>
                    </div>
                    <div *ngIf="selectedQuotation" class="col-9 col-md-2 zero collapse_title font-8 right"
                        style="padding-top: .5em;">
                        <span class="remove"> </span>
                        <span class="strong gray " style="font-size: .8em ; " *ngIf="selectedQuotation">
                            {{selectedQuotation.purchasesOrders[0].company.name}} </span>
                        <div *ngIf="selectedQuotation.purchasesOrders[0].company" class="">
                            <rating *ngIf="!selectedQuotation.purchasesOrders[0].company.rating" class="remove" [(ngModel)]="rate"
                                [max]="max" [readonly]="isReadonly"></rating>
                        </div>
                        <div *ngIf="selectedQuotation.company" class="">
                            <rating *ngIf="selectedQuotation.company.rating" class="remove"
                                [(ngModel)]="selectedQuotation.company.rating" [max]="max" [readonly]="isReadonly">
                            </rating>
                        </div>
                    </div>
                    <div class="col-0 col-md-1 zero collapse_title remove">
                        <!--   <p class="collapse_title_bold marginTop" *ngIf="selectedRequest" > Centro de costos :&nbsp;{{selectedRequest.projectName}} </p> -->
                    </div>
                    <div class="col-0 col-md-3 zero collapse_title remove">
                        <p class=" " style="font-size: .8em; margin-top:1.5em;" *ngIf="selectedQuotation">Nombre :
                            &nbsp;{{selectedQuotation.name}} </p>
                    </div>
                    <div class="col-0 col-md-1 zero collapse_title remove">
                        <!--   <p class="collapse_title_bold marginTop" *ngIf="selectedRequest" > Centro de costos :&nbsp;{{selectedRequest.projectName}} </p> -->
                    </div>
                    <div class="col-0 col-md-1 zero collapse_title remove">
                        <p class=" " style="font-size: .8em;margin-top:1.5em;" *ngIf="selectedQuotation"> Folio
                            :&nbsp;{{selectedQuotation.id}} </p>
                    </div>
                    <div class="col-0 col-md-1 zero collapse_title remove">
                        <!--   <p class="collapse_title_bold marginTop" *ngIf="selectedRequest" > Centro de costos :&nbsp;{{selectedRequest.projectName}} </p> -->
                    </div>
                </div>
                <div class="container-bg-blur zero">
                    <div class="row width">

                        <div class="container " *ngIf="selectedQuotation">
                            <app-viewAcceptedQuotation [request]="selectedQuotation"></app-viewAcceptedQuotation>
                        </div>


                    </div>
                </div>
            </div>
        </div>
        <div class="row cover" id="verHistory">
                <div class="container">
                    <div class="row zero background-gray  strong" style="padding-top: .5em; padding-bottom: 0;">
                        <div class="col-2 col-md-1 zero collapse_title goBack" (click)="verRegresa()">
                            <i class="fa fa-chevron-left marginTop" aria-hidden="true"></i>
                        </div>
                        <div class="col-0 col-md-2 zero remove">
                            <p  style="font-size: .8em; margin-top:.5em;"> Venta  a</p>
                        </div>
                        <div *ngIf="selectedQuotation" class="col-9 col-md-2 zero collapse_title font-8 right"
                            style="padding-top: .5em;">
                            <span class="remove"> </span>
                            <span class="strong gray " style="font-size: .8em ; " *ngIf="selectedQuotation">
                                {{selectedQuotation.company.name}} </span>
                            <div *ngIf="selectedQuotation.company" class="">
                                <rating *ngIf="!selectedQuotation.company.rating" class="remove" [(ngModel)]="rate"
                                    [max]="max" [readonly]="isReadonly"></rating>
                            </div>
                            <div *ngIf="selectedQuotation.company" class="">
                                <rating *ngIf="selectedQuotation.company.rating" class="remove"
                                    [(ngModel)]="selectedQuotation.company.rating" [max]="max" [readonly]="isReadonly">
                                </rating>
                            </div>
                        </div>
                        <div class="col-0 col-md-1 zero collapse_title remove">
                            <!--   <p class="collapse_title_bold marginTop" *ngIf="selectedRequest" > Centro de costos :&nbsp;{{selectedRequest.projectName}} </p> -->
                        </div>
                        <div class="col-0 col-md-3 zero collapse_title remove">
                            <p class=" " style="font-size: .8em; margin-top:1.5em;" *ngIf="selectedQuotation">Nombre :
                                &nbsp;{{selectedQuotation.name}} </p>
                        </div>
                        <div class="col-0 col-md-1 zero collapse_title remove">
                            <!--   <p class="collapse_title_bold marginTop" *ngIf="selectedRequest" > Centro de costos :&nbsp;{{selectedRequest.projectName}} </p> -->
                        </div>
                        <div class="col-0 col-md-1 zero collapse_title remove">
                            <p class=" " style="font-size: .8em;margin-top:1.5em;" *ngIf="selectedQuotation"> Folio
                                :&nbsp;{{selectedQuotation.id}} </p>
                        </div>
                        <div class="col-0 col-md-1 zero collapse_title remove">
                            <!--   <p class="collapse_title_bold marginTop" *ngIf="selectedRequest" > Centro de costos :&nbsp;{{selectedRequest.projectName}} </p> -->
                        </div>
                    </div>
                    <div class="container-bg-blur zero">
                        <div class="row width">
    
                            <div class="container " *ngIf="selectedQuotation">
                                <app-viewCompletedQuotation [request]="selectedQuotation"></app-viewCompletedQuotation>
                            </div>
    
    
                        </div>
                    </div>
                </div>
            </div>

        <div class="container" id="regresa">

            <div class="container-bg-blur">
                <section class="card-view" id="card" [ngClass]="cardView ? 'show' : 'hide'">
                    <!-- COLLAPSE 1 -->
                    <div class="card">
                        <div class="card-header" id="express">
                            <h5 class="mb-0">
                                <a class="collapse_title wine" data-toggle="collapse" href="#acceptedQuotationsCard"
                                    aria-expanded="true" aria-controls="acceptedQuotationsCard">
                                    <i id="1" class="fa fa-chevron-down pull-right"></i>
                                    <div class="collapse_title"> EN CHAT DE NEGOCIACIÓN CON CLIENTE </div>
                                </a>
                            </h5>
                        </div>

                        <div id="acceptedQuotationsCard" class="collapse show" aria-labelledby="heading-example">
                            <div class="card-block">
                                <div class="row zero " *ngIf="soldQuotations.length>0 ">


                                    <!--  cards -->
                                    <div class=" col-md-4 white zero" *ngFor="let sold of soldQuotations">
                                        <div class=" cards" id="{{sold.id}}">
                                            <!-- Rechazar ó Aceptar solicitur -->
                                            <div class="row">
                                                <div class="container_logo card-height">
                                                    <img *ngIf="sold.purchasesOrders[0].company.profileUrl"
                                                        class="logo-brand-img"
                                                        [src]="sold.company.profileUrl">
                                                    <img *ngIf="!sold.company.profileUrl"
                                                        class="logo-brand-img"
                                                        src="../../../assets/images/fakeCompany.png">
                                                    <div class="text_logo"> {{sold.company.name}}
                                                    </div>
                                                    <div class="text_logo">
                                                        <rating *ngIf="!sold.company.rating"
                                                            class="remove" [(ngModel)]="rate" [max]="max"
                                                            [readonly]="isReadonly"></rating>
                                                        <rating *ngIf="sold.company.rating"
                                                            class="remove"
                                                            [(ngModel)]="sold.company.rating"
                                                            [max]="max" [readonly]="isReadonly"></rating>

                                                    </div>
                                                </div>
                                                <div class="container_text card-height">
                                                    <div class="ready">
                                                        <div class="product"> {{sold.name}} </div>
                                                        <div class="free"> <i class="fa fa-circle"></i> </div>
                                                    </div>
                                                    <div>Cantidad a cotizar: {{sold.total}}</div>
                                                    <div>Recibirás resultados el día: {{sold.endDeliveryDate| date:
                                                        'dd/MM/yyyy'}} </div>
                                                </div>

                                                <button (click)="openChat(sold)" type="button"
                                                    class="button-black zero button-black-width font-7">
                                                    <div> Mensajes </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- COLLAPSE 2 -->
                    <div class="card">
                        <div class="card-header" id="venta">
                            <h5 class="mb-0">
                                <a class="collapse_title black" data-toggle="collapse" href="#collapse-example2"
                                    aria-expanded="true" aria-controls="collapse-example2">
                                    <i class="fa fa-chevron-down pull-right"></i>
                                    <div class="collapse_title"> VENTAS ENVIADAS</div>
                                </a>
                            </h5>
                        </div>
                        <div id="collapse-example2" class="collapse show" aria-labelledby="heading-example">
                            <div class="card-block">
                                <div class="row" *ngIf="forSendQuotations.length>0 ">


                                    <!--  cards -->
                                    <div class=" col-md-4 white zero" *ngFor="let sended of forSendQuotations">
                                        <div class=" cards" id="{{sended.id}}">
                                            <!-- Rechazar ó Aceptar solicitur -->
                                            <div class="row">
                                                <div class="container_logo card-height">
                                                    <img *ngIf="sended.company.profileUrl"
                                                        class="logo-brand-img"
                                                        [src]="sended.company.profileUrl">
                                                    <img *ngIf="!sended.company.profileUrl"
                                                        class="logo-brand-img"
                                                        src="../../../assets/images/fakeCompany.png">
                                                    <div class="text_logo"> {{sended.company.name}}
                                                    </div>
                                                    <div class="text_logo">
                                                        <rating *ngIf="!sended.company.rating"
                                                            class="remove" [(ngModel)]="rate" [max]="max"
                                                            [readonly]="isReadonly"></rating>
                                                        <rating *ngIf="sended.company.rating"
                                                            class="remove"
                                                            [(ngModel)]="sended.company.rating"
                                                            [max]="max" [readonly]="isReadonly"></rating>

                                                    </div>
                                                </div>
                                                <div class="container_text card-height">
                                                    <div class="ready">
                                                        <div class="product"> {{sended.name}} </div>
                                                        <div class="free"> <i class="fa fa-circle"></i> </div>
                                                    </div>
                                                    <div>Cantidad a cotizar: {{sended.total}}</div>
                                                    <div>Recibirás resultados el día: {{sended.endDeliveryDate| date:
                                                                        'dd/MM/yyyy'}} </div>
                                                </div>

                                                <button (click)="verSended(sended)" type="button"
                                                    class="button-black zero button-black-width font-7">
                                                    <div> Ver </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- COLLAPSE 3 -->
                    <div class="card">
                        <div class="card-header" id="nuevas">
                            <h5 class="mb-0">
                                <a class="collapse_title black" data-toggle="collapse" href="#collapse-example1"
                                    aria-expanded="true" aria-controls="collapse-example1">
                                    <i class="fa fa-chevron-down pull-right"></i>
                                    <div class="collapse_title"> VENTAS </div>
                                </a>
                            </h5>
                        </div>
                        <div id="collapse-example1" class="collapse show" aria-labelledby="heading-example">
                            <div class="card-block">
                                <div class="row" *ngIf="completeQuotations.length>0 ">


                                         <!--  cards -->
                                         <div class=" col-md-4 white zero" *ngFor="let sended of completeQuotations">
                                                <div class=" cards" id="{{sended.id}}">
                                                    <!-- Rechazar ó Aceptar solicitur -->
                                                    <div class="row">
                                                        <div class="container_logo card-height">
                                                            <img *ngIf="sended.company.profileUrl"
                                                                class="logo-brand-img"
                                                                [src]="sended.company.profileUrl">
                                                            <img *ngIf="!sended.company.profileUrl"
                                                                class="logo-brand-img"
                                                                src="../../../assets/images/fakeCompany.png">
                                                            <div class="text_logo"> {{sended.company.name}}
                                                            </div>
                                                            <div class="text_logo">
                                                                <rating *ngIf="!sended.company.rating"
                                                                    class="remove" [(ngModel)]="rate" [max]="max"
                                                                    [readonly]="isReadonly"></rating>
                                                                <rating *ngIf="sended.company.rating"
                                                                    class="remove"
                                                                    [(ngModel)]="sended.company.rating"
                                                                    [max]="max" [readonly]="isReadonly"></rating>
        
                                                            </div>
                                                        </div>
                                                        <div class="container_text card-height">
                                                            <div class="ready">
                                                                <div class="product"> {{sended.name}} </div>
                                                                <div class="free"> <i class="fa fa-circle"></i> </div>
                                                            </div>
                                                            <div>Cantidad a cotizar: {{sended.total}}</div>
                                                            <div>Recibirás resultados el día: {{sended.endDeliveryDate| date:
                                                                                'dd/MM/yyyy'}} </div>
                                                        </div>
        
                                                        <button (click)="verHistory(sended)" type="button"
                                                            class="button-black zero button-black-width font-7">
                                                            <div> Ver </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="list-view" id="list" [ngClass]="listView ? 'show' : 'hide'">
                    <!-- COLLAPSE 1 -->
                    <div class="card">
                        <div class="card-header" id="express">
                            <h5 class="mb-0">
                                <a class="collapse_title wine" data-toggle="collapse" href="#acceptedQuotationsList"
                                    aria-expanded="true" aria-controls="acceptedQuotationsList">
                                    <i id="1" class="fa fa-chevron-down pull-right"></i>
                                    <div class="collapse_title"> EN CHAT DE NEGOCIACIÓN CON CLIENTE </div>
                                </a>
                            </h5>
                        </div>

                        <div id="acceptedQuotationsList" class="collapse show zero" aria-labelledby="heading-example">
                            <div class="card-block">
                                <div class="row zero width">
                                    <!--  List -->
                                    <div class="width blanco zero margin-card" *ngFor="let sold of soldQuotations">
                                        <div class="cards padding0-margin1" id="accepted{{sold.id}}">
                                            <div class="row zero width movil-height">

                                                <div
                                                    class="col-3 col-md-2 zero container_logo log_movil_list logo_movil">
                                                    <img *ngIf="sold.company.profileUrl"
                                                        class="logo-brand-img"
                                                        [src]="sold.company.profileUrl">
                                                    <img *ngIf="!sold.company.profileUrl"
                                                        class="logo-brand-img"
                                                        src="../../../assets/images/fakeCompany.png">
                                                    <div class="">
                                                        <rating *ngIf="!sold.company.rating"
                                                            [(ngModel)]="rate" [max]="max" [readonly]="isReadonly">
                                                        </rating>
                                                        <rating *ngIf="sold.company.rating"
                                                            class="remove"
                                                            [(ngModel)]="sold.company.rating"
                                                            [max]="max" [readonly]="isReadonly"></rating>

                                                    </div>

                                                </div>
                                                <div
                                                    class="col-3 col-md-3 zero container_logo next width_39  zero-movil">
                                                    <div class="text_logo  pad-top-30 "> {{sold.name}} </div>
                                                </div>
                                                <div
                                                    class="col-3 col-md-4 zero container_logo next width_39  zero-movil">
                                                    <div class="text_logo  pad-top-30 ">Envio el :
                                                        {{sold.endDeliveryDate| date: 'dd/MM/yyyy'}} </div>
                                                </div>
                                                <div
                                                    class="col-3 col-md-2 zero container_logo  width_39 pad-top-30  zero-movil">
                                                    <button (click)="openChat(sold)" type="button"
                                                        class="btn-wine  next width_39  margin-top-13 ">
                                                        Mensajes </button>
                                                </div>
                                                <!--    <div class="col-3 col-md-2 zero container_logo next width_39  zero-movil">
                                            <button  (click)="ver(quotation)" type="button" class="  next width_39  margin-top-13 ">
                                                POR APROBAR
                                            </button>
                                        </div> -->
                                                <!--        <div class="col-3 col-md-3 zero padding1 container_logo next width_39  zero-movil">
                                        <div class="text_logo "> {{sentQuotation.name}} </div>
                                    </div>
                                    <div class="col-4 col-md-2 zero padding1 container_text  next width_39  margin-left-24 martop-7 quotation-movil">
                                        <div class=" font0-8"><p class=" only-movile">Recibiras resultados el :</p> <span class="strong">{{sentQuotation.limitQuotationDate| date: 'dd/MM/yyyy' }}</span></div>
                                    </div>
                                    <div class="col-4 col-md-2 zero padding1 container_text  next width_39  margin-left-24 martop-7 quotation-movil">
                                        <div class=" font0-8"> <span class="strong">{{sentQuotation.limitQuotationDate| date: 'dd/MM/yyyy' }}</span></div>
                                    </div>
                                    <div class="col-4 col-md-3 zero padding1 container_text  next width_39  margin-left-24 martop-7 quotation-movil">
                                        <div class=" font0-8"> 
                                            <button id="button-go"  (click)="ver(quotation)" type="button" class=" padding1 next width_39  margin-top-13 quote-movil movil-button">
                                            POR APROBAR
                                            </button></div>
                                    </div> -->
                                                <!-- <div class="col-4 col-md-2 zero padding1 container_text  next width_39  margin-left-24 martop-34 quotation-movil quote-money">
                                        <div class=" font0-8 ">A cotizar<span class="strong">{{sentQuotation.cantidad}}</span></div>
                                    </div> -->
                                                <!--    <div class="strong wine font-8 width" > 
                                        <button id="button-go"  (click)="ver(quotation)" type="button" class="col-4 col-md-3 zero padding1 next width_39  margin-top-13 quote-movil movil-button">
                                        POR APROBAR
                                        </button>
                                    </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <!--  List -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- COLLAPSE 2 -->
                    <div class="card">
                        <div class="card-header" id="venta">
                            <h5 class="mb-0">
                                <a class="collapse_title black" data-toggle="collapse" href="#collapse-example2"
                                    aria-expanded="true" aria-controls="collapse-example2">
                                    <i class="fa fa-chevron-down pull-right"></i>
                                    <div class="collapse_title"> VENTAS POR ENVIAR</div>
                                </a>
                            </h5>
                        </div>
                        <div id="collapse-example2" class="collapse show zero" aria-labelledby="heading-example">
                            <div class="card-block">
                                <div class="row zero width">
                                    <!--  cards -->
                                    <div class="col-12 col-md-12 white zero margin-card"
                                        *ngFor="let rechazada of forSendQuotations">
                                        <div class="cards padding0-margin1" id="rechazada{{rechazada.id}}">
                                            <!-- Rechazar ó Aceptar solicitur -->
                                            <div class="row zero width movil-height">
                                                <div class="col-3 col-md-1 zero container_text point-movil">
                                                    <div class="ready ">
                                                        <div class="free ">
                                                            <i class="fa fa-circle"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    class="col-3 col-md-1 zero container_logo log_movil_list logo_movil">
                                                    <div class="logo-brand logo-brand-line logo-brand-movil"
                                                        [style.background-image]="'url(' + rechazada.logo + ')'"> </div>
                                                </div>
                                                <div
                                                    class="col-3 col-md-2 zero padding1 container_logo next width_39  zero-movil">
                                                    <div class="text_logo  "> "{{rechazada.nombre}}" </div>
                                                </div>
                                                <div
                                                    class="col-4 col-md-2 zero padding1 container_logo next width_39  start-movil">
                                                    <div class="text_logo ">
                                                        <rating *ngIf="!rechazada.company.rating" class="remove"
                                                            [(ngModel)]="rate" [max]="max" [readonly]="isReadonly">
                                                        </rating>
                                                        <rating *ngIf="rechazada.company.rating" class="remove"
                                                            [(ngModel)]="rechazada.company.rating" [max]="max"
                                                            [readonly]="isReadonly"></rating>

                                                    </div>
                                                </div>
                                                <div
                                                    class="col-4 col-md-2 zero padding1 container_text  next width_39  margin-left-24 martop-7 quotation-movil">
                                                    <div class=" font0-8">
                                                        <p class=" only-movile">Cotizar</p> <span
                                                            class="strong">{{rechazada.fecha1}}</span>
                                                    </div>
                                                </div>
                                                <div
                                                    class="col-4 col-md-0 zero padding1 container_text  next width_39  margin-left-24 martop-34  quotation-movil quote-money">
                                                    <div class=" font0-8 ">A cotizar<span
                                                            class="strong">{{rechazada.cantidad}}</span></div>
                                                </div>
                                                <button type="button"
                                                    class="col-4 col-md-3 zero padding1 next width_39  margin-top-26 quote-movil">
                                                    <div class="strong wine  font-8" (click)="ver(rechazada.categoria)">
                                                        {{rechazada.btn}} </div>
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                    <!--  cards -->

                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- COLLAPSE 3 -->
                    <div class="card">
                        <div class="card-header" id="nuevas">
                            <h5 class="mb-0">
                                <a class="collapse_title black" data-toggle="collapse" href="#collapse-example1"
                                    aria-expanded="true" aria-controls="collapse-example1">
                                    <i class="fa fa-chevron-down pull-right"></i>
                                    <div class="collapse_title"> VENTAS </div>
                                </a>
                            </h5>
                        </div>
                        <div id="collapse-example1" class="collapse show zero" aria-labelledby="heading-example">
                            <div class="card-block">
                                <div class="row zero width">
                                    <!--  cards -->
                                    <!--  cards -->
                                    <div class="col-12 col-md-12 white zero margin-card"
                                        *ngFor="let rechazada of rechazada">
                                        <div class="cards padding0-margin1" id="rechazada{{rechazada.id}}">
                                            <!-- Rechazar ó Aceptar solicitur -->
                                            <div class="row zero width movil-height">
                                                <div class="col-3 col-md-1 zero container_text point-movil">
                                                    <div class="ready ">
                                                        <div class="free ">
                                                            <i class="fa fa-circle"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-3 col-md-1 zero container_logo log_movil_list logo_movil"
                                                    style="">
                                                    <div class="logo-brand logo-brand-line logo-brand-movil" style=" 
                                                    " [style.background-image]="'url(' + rechazada.logo + ')'"> </div>
                                                </div>
                                                <div
                                                    class="col-3 col-md-2 zero padding1 container_logo next width_39  zero-movil">
                                                    <div class="text_logo  "> "{{rechazada.nombre}}" </div>
                                                </div>
                                                <div
                                                    class="col-4 col-md-2 zero padding1 container_logo next width_39  start-movil">
                                                    <div class="text_logo ">
                                                        <rating *ngIf="!rechazada.company.rating" class="remove"
                                                            [(ngModel)]="rate" [max]="max" [readonly]="isReadonly">
                                                        </rating>
                                                        <rating *ngIf="rechazada.company.rating" class="remove"
                                                            [(ngModel)]="rechazada.company.rating" [max]="max"
                                                            [readonly]="isReadonly"></rating>
                                                    </div>
                                                </div>
                                                <div
                                                    class="col-4 col-md-2 zero padding1 container_text  next width_39  margin-left-24 martop-7 quotation-movil">
                                                    <div class=" font0-8">
                                                        <p class=" only-movile">Cotizar</p> <span
                                                            class="strong">{{rechazada.fecha1}}</span>
                                                    </div>
                                                </div>
                                                <div
                                                    class="col-4 col-md-0 zero padding1 container_text  next width_39  margin-left-24 martop-34  quotation-movil quote-money">
                                                    <div class=" font0-8 ">A cotizar<span
                                                            class="strong">{{rechazada.cantidad}}</span></div>
                                                </div>
                                                <button type="button"
                                                    class="col-4 col-md-3 zero padding1 next width_39  margin-top-26 quote-movil">
                                                    <div class="strong wine  font-8" (click)="ver(rechazada.categoria)">
                                                        {{rechazada.btn}}</div>
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                    <!-- cards -->

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      	<!-- Footer -->
		<app-footer></app-footer>
		<!--Footer -->
        <div class="padding-top1"></div>
    </div>
</div>
<!-- Modal  PARA EL CUESTIONARIO-->
<div class="modal fade " id="ixccoEncuesta" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
 aria-hidden="true">

	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content martop30">
			<div class="modal-body width">
				<button type="button" class="close btnColose" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<div class="row zero tabla width">
					<div class="row width" style="height:15em;">
						<div class="container-scroll top-ask">
							<div class="row center">
								<p class="font-1 black title">Calificacion de la plataforma</p>
							</div>

							<div class="row center">
								<rating [max]="maxRatings" [(ngModel)]="rateRatings" [customTemplate]="tt"></rating>
								<ng-template #tt let-i="index" let-v="value">
									<button class="btn btn-{{i < v ? 'greenbg' : 'default'}}">
										{{i < v ? '&#9733;' : '&#9734;' }} </button> </ng-template> </div> <div class="row center" style="padding:10px;">
											<p class="font-1 black">Comentarios/Recomendaciones</p>

							</div>

							<div class="row" style="padding:10px;">
								<input type="text" class="width input2" style="border-left:0px;border-right:0px;" [(ngModel)]="ratingComment"
								 name="comment" #comment="ngModel" placeholder="">


							</div>
						</div>
					</div>
					<div class="row zero width">
						<div class="col-3 col-md-3 zero font-8">

						</div>
						<div class="col-6 col-md-6 zero font-8">
							<button (click)="surveyIxcco()" class="width zero center strong padding5" style=" background-color: black; border:solid transparent; color:#fff;">
								Responder
							</button>
						</div>
						<div class="col-3 col-md-3 zero font-8">

						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</div>