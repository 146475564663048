import { Injectable } from '@angular/core';
import { Observable ,  Observer } from 'rxjs';
import * as socketIo from 'socket.io-client';
import { Event } from './models/event';
import { Message } from './models/message';
const SERVER_URL = 'http://localhost:3002/';

//const SERVER_URL = 'http://52.36.70.201:3002/';
@Injectable()
export class ChatService {
  private socket;

  constructor() { }
 
  public initSocket(): void {
      this.socket = socketIo(SERVER_URL);
    //  this.send('asdasd');
  }
  public initChat(): void {
    this.socket = socketIo(SERVER_URL);
  //  this.send('asdasd');
}

  public send(message: any): void {
      this.socket.emit('message', message);
  }

  public onMessage(): Observable<any> {
      return new Observable<any>(observer => {
          this.socket.on('message1', (data: any) => observer.next(data));
      });
  }

  public onEvent(event : Event): Observable<any> {
      return new Observable<Event>(observer => {
          this.socket.on(event, () => observer.next());
      });
  }

}
