<div class="container">
  <div class="row zero">
    <div class="col-12 col-md-10 zero">
      <div class="row zero">
        <div class="col-6 col-md-2 zero footer-button1" style="padding: 1.25em ;margin-top:3em;margin-bottom: 1.5em;">
          <div class="cero button"> <a class="about"  href="https://www.ixcco.com" target="_blank">SOBRE IXCCO</a>
          </div>
        </div>
        <div class="col-3 col-md-2 zero footer-button1" style="padding: 1.25em;margin-top:3em;">
          <div class="cero button"> <a class="about"  href="https://www.ixcco.com" target="_blank">FAQ</a> </div>
        </div>
        <div class="col-3 col-md-2 zero footer-button1" style="padding: 1.25em;margin-top:3em;">
          <div class="cero button-"> <a class="about"  href="https://www.ixcco.com" target="_blank">
              !CONTACTANOS! </a> </div>
        </div>
      </div>
    </div>

    <div class="col-0 col-md-2 zero footer-button2" style="padding: 1.25em;margin-top:3em;">
      <div class="button">
        <i class="fa fa-camera" aria-hidden="true"></i>
      </div>
    </div>
  </div>
</div>