import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/apiService';

import { Router, NavigationEnd, Event } from '@angular/router';
import swal from 'sweetalert2'
import { NgxSpinnerService } from 'ngx-spinner';
import { FooterComponent } from '../../../components/footer/footer.component';
import { ClientTableComponent } from '../../../components/client-table/client-table.component';
declare var $: any;

@Component({
  selector: 'app-sended',
  templateUrl: './sended.component.html',
  styleUrls: ['./sended.component.css'],
  providers: [ApiService]
})

export class SendedComponent implements OnInit {
  public draftsView: boolean = true;
  public drafts: Array<any> = [];
  public pending: Array<any> = [];
  public standby: Array<any> = [];
  public errorMessage;

  public project = {
    ad: localStorage.getItem('asd')
  }
  mobile: any;
  constructor(private _apiService: ApiService, private _router: Router, private spinner: NgxSpinnerService) {
    this.mobile = (window.screen.width);


  }


  viewData(evt) {
    console.log(evt)
    if (evt.actions === 'finish') {
      this.finishRequest(evt.data.id, true)

    } else if (evt.actions === 'delete') {
      this.onDeleteDraftConfirm(evt.data.id)


    }
  }
  finishRequest(draft, editable) {
    console.log(draft)
    if (editable) {
      this._router.navigate(['/compras'], { queryParams: { draftId: draft, editable: true } });

    } else {
      this._router.navigate(['/compras'], { queryParams: { draftId: draft, editable: false } });

    }



  }
  ngOnInit() {
    this._router.events.subscribe(
      (value: Event) => {
        if (value instanceof NavigationEnd) {
          this.getBorradoresComprasEnviadas('Requests/sentRequests', '5ade0ecf9b235a7edf66e6ee');

        }
      });
    this.getBorradoresComprasEnviadas('Requests/sentRequests', '5ade0ecf9b235a7edf66e6ee');

  }
  
  onDeleteDraftConfirm(id) {
    console.log(id)
    swal({
      title: '¿Estas seguro ?',
      text: "Esta accion es irreversible",
      type: 'info',
      showCancelButton: true,
      confirmButtonColor: '#000',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si, Eliminar!',
      confirmButtonClass: 'btn ',
      cancelButtonClass: 'btn btn-danger',
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.deleteDraft(id);
       
      } else if (result.dismiss) {
        swal({
          title: 'Cancelado',
          text: 'No se realizaron cambios',
          type: 'info',
          confirmButtonClass: 'btn ',

          confirmButtonColor: '#000'
        })
      }
    })


  }

  deleteDraft(id: any) {
    this.spinner.show();
    console.log(this.project)
    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
    let params = {
      access_token: tokenId
    }
    console.log(params)
    this._apiService.deleteDataObject('Requests', id, params).subscribe(
      (result: any)=> {
        console.log(result);

        setTimeout(() => {
          this.spinner.hide();
          swal({
            title: 'Eliminado!',
            text: 'se ha eliminado correctamente.',
            type: 'success',
            confirmButtonClass: 'btn ',

            confirmButtonColor: '#000'
          })

          this.getBorradoresComprasEnviadas('Requests/sentRequests', '5ade0ecf9b235a7edf66e6ee');



        }, 500);

      },
      error => {
        if (error != null) {
          this.spinner.hide()
          console.log(error);
          setTimeout(() => {


            swal({
              title: 'Error!',
              text: 'Error al eliminar',
              type: 'error',
              confirmButtonClass: 'btn ',

              confirmButtonColor: '#000'
            })



          }, 250);
        }
      }

    );

  }
  getBorradoresComprasEnviadas(type, id?) {
    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
    let companyId = localStorage.getItem('$LoopBack$currentCompanyId');
    let userId = localStorage.getItem('$LoopBack$currentUserId');

    console.log(companyId)
    let params = {

      //companyId: companyId,
      userId: userId,
      access_token: tokenId
    };
    this._apiService.getDataObjects(type, null, params).subscribe(
      (result: any)=> {

        console.log(result);
        if (id) {
          this.drafts = result.data.drafts;
          this.pending = result.data.pending;

          this.standby = result.data.standby;
        }



      },
      error => {
        this.errorMessage = <any>error;
        if (this.errorMessage != null) {
          console.log(this.errorMessage);

        }
      }

    );
  }
 
  regresaborrado() {
    $('.regresaborrador').show(400);
    $('#verborrador').hide(10);
  }



}
