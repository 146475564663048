

<!--VER COTIZACIÓN ACEPTADA-------------------------->
<div class="row target" id="encuesta">
	<div class="width zero">

	<!-- 	<div *ngIf="concretada" class="container_bg_difuninado zero">
			<app-newSurvey [concretada]="concretada"></app-newSurvey>
		</div> -->
	</div>
</div>

<div class="row zero target" id="chat">
	<div class="width zero">
		<div class="row zero confirmar background-gray">
			<div class="col-0 col-md-1 zero"></div>
			<div class="col-2 col-md-2 collapse_titl padding-top1">
				<p (click)="regresa()" class="goBack">
					<i class="fa fa-chevron-left" aria-hidden="true"></i> &nbsp;&nbsp;&nbsp;</p>
			</div>
			<div class="col-10 col-md-6 zero collapse_title padding-top1 strong font-8"> COMPRA CONCRETADA </div>
			<div class="col-0 col-md-3 zero">
				<p class="folio-title remove"> Folio :{{concretada.id}} </p>
			</div>
		</div>
		<div class="container_bg_difuninado zero">
			<app-chat *ngIf="chatComponent"></app-chat>
		</div>
	</div>
</div>



<div class="row" *ngFor="let compra of concretada.purchasesOrders;let i =index" id="entrega">

	<div class=" cont_info zero" style="width: 100%;">
		<div class="padding-2">
			<div class="row background-white">
				<p class="text center strong padding-2"> Tu proveedor nos informó que te enviaron los productos exitosamente, si
					esto es correcto por favor confirma el siguiente botón</p>
				<!-- 	<div class="col-0 col-md-2 text center"></div>
				<div class="col-12 col-md-4 text center">Fecha que recibiste el producto:</div>
				<div class="col-12 col-md-4 text center strong">
					<div class="row">
						<div class="col-0 col-md-1 text"></div>
						<div class="col-4 col-md-3 text">
							<select class="select input font-12 center">
								<option>1</option>
								<option>10</option>
								<option>31</option>
							</select>
						</div>
						<div class="col-4 col-md-4 text center">
							<select class="select input font-12 center">
								<option>Enero</option>
								<option>Marzo</option>
								<option>Diciembre</option>
							</select>
						</div>
						<div class="col-4 col-md-3 text center">
							<select class="select input font-12 center">
								<option>2000</option>
								<option>2010</option>
								<option>2018</option>
							</select>
						</div>
						<div class="col-0 col-md-1 text"></div>
					</div>
				</div>
				<div class="col-0 col-md-2 text center"></div> -->
			</div>
			<div class="row zero background-white" style="padding:1.5em 0 2em 0.5em">
				<div class="col-1 col-md-4 zero text center"> </div>
				<div class="col-10 col-md-4 text center">
					<button *ngIf="!compra.delivered" class="boton_clip zero center pad08 collapse_title " style="background:#000;border:solid transparent;color:#fff;width:100%; font-size:1em;"
					 (click)="confirmProduct(compra)"> Confirmar producto recibido </button>
				</div>
				<div class="col-1 col-md-4 text center"></div>
			</div>
			<div class="tab-content" id="pills-tabContent">
				<div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
					<!---->
					<div class="row background-white">
						<div class="col-12 col-md-4 text center">
							<!-- logo de garantia -->
							<div class="row">
								<div class="col-0 col-md-4 "></div>
								<div *ngIf="compra.company" class="col-12 col-md-5 zero garantia garanty" style="background-image:url(assets/images/gallery/marcas/garantiablack.png);">
									<div class="galery-numbrer">{{compra.company.ratingIxcco}}</div>
									<div class="galery-text">RECOMENDACIÓN</div>
									<div class="galery-logo">IXCCO</div>
								</div>
								<div class="col-0 col-md-3 "></div>

							</div>
							<div class="row" style="padding-left:20px">
								Cotización para
								<strong *ngIf="compra" style=" margin-left: 10px;margin-right: 10px;">{{compra.sellProducts.length}}</strong>
								productos
							</div>
						</div>
						<div class="col-12 col-md-4 black margin1">
							<div class="row width padding-top1">

								<div  *ngIf="compra.company" class=" col-3 col-md-12 zero ">
									<img *ngIf="compra.company.profileUrl" class="logo-brand-img-2" [src]="compra.company.profileUrl">
									<img *ngIf="!compra.company.profileUrl" class="logo-brand-img-2" src="../../../assets/images/fakeCompany.png">
								</div>

								<div *ngIf="compra.company" class=" col-9 col-md-12 zero">
									<div class="text center-left">
										{{compra.company.name}}</div>
									<div *ngIf="compra.company" class="text center-left">
										<rating  *ngIf="compra.company.rating" [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating>
									</div>
									<div class="text center-left">{{compra.company.companyInfo.city}}, México</div>
								</div>


							</div>
						</div>
						<div class="col-12 col-md-4 text center margin1 padding-6">

							<div class="row zero margin1">
								<div class="col-1 col-md-1 zero right text ">
									<i class="fa fa-money pad5 text-bar" aria-hidden="true"></i>
								</div>
								<div class="col-9 col-md-9 zero left">
									<div class="zero">
										<div class="stat-1 stat-bar">
											<span *ngIf="compra.sellProducts[0]" class="stat-bar-rating" role="stat-bar" [ngStyle]="{'width': compra.sellProducts[0].creditScore + '%'}"></span>
										</div>
									</div>
								</div>
								<div class="col-2 col-md-2 number-bar zero">
									<p class="zero left number-bar"></p>
								</div>
								<div class="col-2 col-md-2 text "></div>
								<div class=" col-10 col-md-10  zero">
									<p class="text price text1 weigh600 "> Precio total: {{amount*1.16| currency:'MXN':true:'1.0-2'}}</p>
								</div>
							</div>
							<div class="row zero margin1">
								<div class="col-1 col-md-1 zero right text ">
									<i class="fa fa-truck pad5 text-bar" aria-hidden="true"></i>
								</div>
								<div class="col-9 col-md-9 zero left">
									<div class="zero">
										<div class="stat-1 stat-bar">
											<span  *ngIf="compra.sellProducts[0]" class="stat-bar-rating" role="stat-bar" [ngStyle]="{'width': compra.sellProducts[0].deliveryScore + '%'}"></span>
										</div>
									</div>
								</div>
								<div class="col-2 col-md-2 number-bar zero">
									<p class="zero left number-bar"></p>
								</div>
								<div class="col-2 col-md-2 text "></div>
								<div class=" col-10 col-md-10  zero">
									<p class="text price text1 weigh600 ">  {{compra.estimatedDeliveryDate | date: 'dd/MM/yyyy'}}</p>
								</div>
							</div>
							<div class="row zero margin1">
								<div class="col-1 col-md-1 zero right text ">
									<i class="fa fa-credit-card pad5 text-bar" aria-hidden="true"></i>
								</div>
								<div class="col-9 col-md-9 zero left">
									<div class="zero">
										<div class="stat-1 stat-bar">
											<span  *ngIf="compra.sellProducts[0]" class="stat-bar-rating" role="stat-bar" [ngStyle]="{'width': compra.sellProducts[0].creditScore + '%'}"></span>
										</div>
									</div>
								</div>
								<div class="col-2 col-md-2 number-bar zero">
									<p class="zero left number-bar"></p>
								</div>
								<div class="col-2 col-md-2 text "></div>
								<div class=" col-10 col-md-10  zero">
									<p *ngIf="concretada" class="text price weigh600 text1"> An &nbsp;{{compra.paymentOptions.anticipated}}%
										&nbsp; Co &nbsp;{{compra.paymentOptions.uponDelivery}}% &nbsp;Cr
										&nbsp;{{compra.paymentOptions.credit}}% &nbsp; por &nbsp; {{compra.paymentOptions.days}} días</p>
								</div>
							</div>
							<div class="row zero margin1">
								<!-- 	<div class="col-1 col-md-1 zero right text ">
									<i class="fa fa-bookmark pad5 text-bar" aria-hidden="true"></i>
								</div> -->
								<!-- 	<div class="col-9 col-md-9 zero left">
									<div class="zero">
										<div class="stat-1 stat-bar">
											<span class="stat-bar-rating" role="stat-bar" style="width:80%;"></span>
										</div>
									</div>
								</div> -->
								<div class="col-2 col-md-2 number-bar zero">
									<!-- 	<p class="zero left number-bar">80</p> 
									-->
								</div>
								<div class="col-2 col-md-2 text "></div>
								<div class=" col-10 col-md-10  zero">
								
								</div>
							</div>
						</div>
					</div>
					<div class="row background-white"> &nbsp;</div>

					<div *ngFor="let product of compra.sellProducts">
						<div *ngIf="product" class="row background-white black padding-6 border-and-padding">
							<div *ngIf="product" class="col-4 col-md-1 text center zero">
								<div *ngIf="product.productUrl" class="container-button">
									<!-- 		<div class="container-button-text"> 2 </div>
									-->
									<i class="fa fa-arrow-circle-o-down zero" aria-hidden="true"></i>
								</div>
							</div>
							<div *ngIf="product" class="col-8 col-md-3 text left zeror">
								<p class="zero">Nombre del producto</p>
								<label class="zero strong ">{{product.name}}</label>
							</div>
							<div *ngIf="product" class="col-5 col-md-1 text left zeror">
								<p class="zero">Cantidad:</p>
								<label class=" strong zero">{{product.quantity}}</label>
							</div>

							<div *ngIf="product" class="col-5 col-md-1 text left zeror">
								<p class="zero">Unidad:</p>
								<label *ngIf="product.unit" class=" strong zero">{{product.unit.name}}</label>
								<label  *ngIf="!product.unit && product.unitName" class=" strong zero">{{product.unitName}}</label>

							</div>
							<div *ngIf="product" class="col-5 col-md-2 text left zero">
								<p class="zero">Centro de costos:</p>
								<label class=" strong zero">{{concretada.projectName}}</label>
							</div>
							<div *ngIf="product" class="col-7 col-md-2 text left zero">
								<p class="zero">Precio unitario:</p>
								<label class=" strong zero">${{product.amount}}mxn</label>
							</div>

						</div>
					</div>

					<div class=" row background-white padding1 width ">
						<div class="col-0 col-md-4 zero"></div>
						<div class="col-0 col-md-4 zero">
							<!-- 	<button class="zero center strong width white font-1 padding1" style="background:#000;border:solid transparent;"
								 data-toggle="modal" data-target="#llenaencuesta"> CONTESTA ENCUESTA </button> -->
							<!-- <button *ngIf="!compra.delivered " class="boton_clip zero center font-8 width collapse_title" (click)="confirmProduct(compra)" style=" background-color: black; border:solid transparent; color:#fff;">
								CONCRETAR
							</button> -->
						</div>
						<div class="col-0 col-md-4 zero"></div>
					</div>
				</div>

				<!-- MIS USUARIOS -->
			</div>
		</div>

	</div>