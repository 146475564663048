import { Injectable } from '@angular/core';
import {  HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
/* If you use the legacy HttpModule and the Http service,
 switch to HttpClientModule and the HttpClient service. 
 HttpClient simplifies the default ergonomics 
 (you don't need to map to JSON anymore) and now 
 supports typed return values and interceptors. 
 Read more on angular.io.
 */

@Injectable()


export class ApiService {
    //API URL
    //public url: string = 'https://api.ixcco.com/api/';


    //public url: string = 'https://pruebasapi.ixcco.com/v1.0/';


    //PROD BUCKET
    public URL = 'https://api.ixcco.com/v1.0/LocalContainers/temporalfiles/upload';
    public URLBUCKET = 'https://api.ixcco.com/v1.0/LocalContainers/temporalfiles/download/';

    //PRUEBASBUCKET
    //public URL = 'https://pruebasapi.ixcco.com/v1.0/LocalContainers/temporalfiles/upload';
    //public URLBUCKET = 'https://pruebasapi.ixcco.com/v1.0/LocalContainers/temporalfiles/download/';

    // Local variables //
    private env: any;
    private url: string;
    private imageUrl: string;

    public object: string;


    constructor(private _http: HttpClient) {
        this.env = environment;
        this.url = this.env.url;
    }

    /// model -- objeto a guardar -- {'name': 'Carlos'}
    // type -- el modelo (api path) -- ejemplo : AppUser
    // id -- Referencia de datos a filtrar
    // params -- parametros del header y filtros-- ejemplo token

    getDataObjects(type: string, id?: string, params?: any) {
        const paramss = {

            access_token: localStorage.getItem('$LoopBack$accessTokenId')
        };
        //conditional to access remote methos on backend for querying some needed data
        if (params) {
            return this._http.get(this.url + type, {
                params: params,
            });
        } else {
            return this._http.get(this.url + type, {
                params: paramss,
            });
        }

    }
    getDataO(type: string) {
        //conditional to access remote methos on backend for querying some needed data

        return this._http.get(type);


    }
    postDataObjects(type: string, id?: string, params?: any) {

        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        /* return this._http.post(this.url + type + '?access_token=' + token.access_token, params, { headers: headers })
        .map(res => res.json()); */

        //conditional to access remote methos on backend for querying some needed data
        if (id) {
            return this._http.post(this.url + type, {
                params: params,
            });
        } else {
            return this._http.post(this.url + type, {
                params: params,
            });
        }

    }
    getDataObjectsFilterCompany(type: string, params: any) {
        var paramss = {

            access_token: localStorage.getItem('$LoopBack$accessTokenId')
        };
        //conditional for queryin data filter object needed in the params
        console.log(this.url + type + '?filter=' + JSON.stringify(params))
        return this._http.get(this.url + type + '?filter=' + JSON.stringify(params), {
            params: paramss
        });
    }

    //nice -- funciona bien
    getDataObjectsFilter(type: string, params: any) {
        var paramss = {

            access_token: localStorage.getItem('$LoopBack$accessTokenId')
        };
        //conditional for queryin data filter object needed in the params
        return this._http.get(this.url + type, {
            params: paramss
        });
    }

    //nice -- funciona bien
    getDataByIdOld(type: string, id: string, params: any) {
        console.log(params)

        //conditional to access remote methos on backend for querying some needed data
        return this._http.get(this.url + type + '/' + id, {
            params: params
        });
    }

    getDataByIdQuotation(type: string, id: string, filter?) {
        let filterd= {
                include: ['company', 'branch', 'user', { relation: 'request', scope: { include: ['company', 'branch'] } }]
            };
         var params = {

            access_token: localStorage.getItem('$LoopBack$accessTokenId')
        }; 
        console.log(this.url + type + id);

        return this._http.get(this.url + '/' + type + '/' + id+ '?filter=' + JSON.stringify(filterd), {
            params: params
        });



    }
    getDataById(type: string, id: string, filter?) {
        var params = {

            access_token: localStorage.getItem('$LoopBack$accessTokenId')
        };
        console.log(this.url + type + id);

        return this._http.get(this.url + '/' + type + '/' + id, {
            params: params
        });



    }
    getDataByRequest(type: string, id: string, filter?) {
        console.log(this.url + type + id);
        var params = {

            access_token: localStorage.getItem('$LoopBack$accessTokenId')
        };
        if (filter) {
            return this._http.get(this.url + '/' + type + '/' + id + '?filter={"order":"createdAt ASC","include":["requestProducts"]}',{
                params: params
            });

        } else {
            return this._http.get(this.url + '/' + type + '/' + id);

        }

    }
    //nice -- funciona bien
    addDataObjectCustom(type: string, data: any, token?: any) {
        let jsonContent = JSON.stringify(data);
        //let params = jsonContent;
        var params = {

            access_token: localStorage.getItem('$LoopBack$accessTokenId')
        };
        //let headers = new Headers({ 'Content-Type': 'application/json' });
        return this._http.post(this.url + type + '?access_token=' + params.access_token, data);
    }


    // Add a new data object //
    addDataObject(type: string, model: any) {
        var params = {

            access_token: localStorage.getItem('$LoopBack$accessTokenId')
        };
        return this._http.post(this.url + type + '?access_token=' + params.access_token, model);
    }

    addDataObjectUnAuth(model: any, type: string) {
        let jsonContent = JSON.stringify(model);
        let params = jsonContent;
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this._http.post(this.url + type, params, {});
    }


    // Edit object per id //
    editDataObject(type: string, id: string, model: any, token?) {
        var params = {

            access_token: localStorage.getItem('$LoopBack$accessTokenId')
        };
        return this._http.patch(this.url + type + '/' + id + '?access_token=' + params.access_token, model);
    }
    updateDataObject(type: string, id: string, model: any, token?) {
        var params = {

            access_token: localStorage.getItem('$LoopBack$accessTokenId')
        };
        return this._http.put(this.url + type + '/' + id + '?access_token=' + params.access_token, model);
    }
    //nice -- probar
    deleteDataObject(type: string, id: string, params: any) {
        return this._http.delete(this.url + type + '/' + id + '?access_token=' + params.access_token);
    }
    loginUser2(model: string, type: string, credentials: any): Observable<any> {
        return this._http.post(this.url + model + '/' + type, credentials);
    }
    //nice -- funciona bien
    loginUser(model: string, type: string, credentials: any) {

        return this._http.post(this.url + model + '/' + type, credentials);
    }
    // Add image to the bucket
    addFile(file: File, id: string) {
        const formData = new FormData();
        formData.append('file', file, id);
        return this._http.post(this.url, formData);
    }
    // Add image to the bucket
    addImage(image: File, id: string) {
        const formData = new FormData();
        formData.append('image', image, id);
        return this._http.post(this.url, formData);
    }
    // Add image object (multiple images) to the bucket
    addImageObject(image: File[], id: string[]) {
        const formData = new FormData();
        for (let x = 0; x < image.length; x++) {
            formData.append('image', image[x], id[x]);
        }
        return this._http.post(this.url, formData);
    }


}
