import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services/apiService';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private spinner: NgxSpinnerService, private api: ApiService, private router: Router) {
        console.log('error interceptor loaded');
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err) => {
                console.log(err)
                /*   if (err.error instanceof ErrorEvent) {
                      console.error('An error occurred:', err.error.message);
                      return throwError(err.error);
                  } else {
                      console.error(err); */
                if (err.status === 401) {
                    this.logout();

                    return throwError('Acceso denegado');
                }

                //}
            })
        );
    }
    logout() {
        swal({
            title: 'Acceso denegado!',
            text: 'Por favor vuelva a iniciar sesion',
            type: 'error',
            confirmButtonClass: 'btn ',
            confirmButtonColor: '#000'
          });
        localStorage.removeItem("userName");
        localStorage.removeItem("$LoopBack$currentUserId");
        localStorage.removeItem("$LoopBack$currentUserImage");
        localStorage.removeItem("$LoopBack$accessTokenId");
        localStorage.removeItem("$LoopBack$accessTokenId");
        localStorage.removeItem("$LoopBack$currentUserRole");
        localStorage.removeItem("$LoopBack$currentCompanyId");
        let asd = false;
        localStorage.setItem('isPerfil', asd.toString())
        setTimeout(() => {
            this.router.navigate(['/login']);
        }, 500);
    }
}
