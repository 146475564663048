<div class="zero">
  <!-- PARA ACEPTADAS programar fecha de envio-->
  <!-- 	<div class="row width center margin-buttom-3" *ngSwitchCase="'sended'">
		<div class=" zero bg_blanco width">
			<div class="row width center">
				<div class="col-1 col-md-1"></div>
				<div class=" col-10 col-md-10 center title">
					COMUNICA LA FECHA DE TU ENVÍO
					<div class="row width zero margin1">
						<div class="col-12 col-md-8 zero">
							<div class="row">
								<div class="col-12 col-md-6 font-8">Fecha de salida de tu producto:</div>
								<div class="col-12 col-md-5">
									<div class="row">
										<select class="col-4 col-md-4 input black zero">
											<option class="font1">10</option>
											<option class="font1">18</option>
										</select>
										<select class="col-4 col-md-4 input black zero">
											<option class="font1">Mar</option>
											<option class="font1">Jun</option>
										</select>
										<select class="col-4 col-md-4 input black zero">
											<option class="font1">2018</option>
											<option class="font1">2019</option>
										</select>
									</div>
								</div>
								<div class="col-7 col-md-7"></div>
							</div>
							<div class="row zero font-8">&nbsp;</div>
							<div class="row">
								<div class="col-12 col-md-6 font-8">Fecha que recibirán tu producto</div>
								<div class="col-12 col-md-5">
									<div class="row">
										<select class="col-4 col-md-4 input black zero">
											<option class="font1">10</option>
											<option class="font1">18</option>
										</select>
										<select class="col-4 col-md-4 input black zero">
											<option class="font1">Mar</option>
											<option class="font1">Jun</option>
										</select>
										<select class="col-4 col-md-4 input black zero">
											<option class="font1">2018</option>
											<option class="font1">2019</option>
										</select>
									</div>
								</div>
								<div class="col-7 col-md-7"></div>
							</div>
						</div>
						<button class="col-12 col-md-3 zero button-green">
							<div class=" font-8">Confirma entrega</div>
						</button>
					</div>
				</div>
				<div class="col-1 col-md-1"></div>
			</div>
		</div>
	</div> -->
  <!-- PARA ACEPTADAS venta en camino-->
  <div class="row width center margin-buttom-3" *ngIf="status==='sent'">
    <div class=" cont_info zero bg_blanco width">
      <div class="row width center">
        <div class="col-0 col-md-1"></div>
        <div class=" col-12 col-md-10 center">
          <div class="strong green">
            <i class="fa fa-check-circle-o" aria-hidden="true"></i>
            TU VENTA VA EN CAMINO A TU CLIENTE
          </div>
          <!-- 		<div class="row width margin1">
						<div class="col-12 col-md-7">
							<div class="row">
								<div class="col-12 col-md-6 font-8">Fecha de salida de tu producto:</div>
								<div class="col-12 col-md-5">
									<div class="row">
										<select class="col-4 col-md-4 input black zero">
											<option class="font1">10</option>
											<option class="font1">18</option>
										</select>
										<select class="col-4 col-md-4 input black zero">
											<option class="font1">Mar</option>
											<option class="font1">Jun</option>
										</select>
										<select class="col-4 col-md-4 input black zero">
											<option class="font1">2018</option>
											<option class="font1">2019</option>
										</select>
									</div>
								</div>
								<div class="col-0 col-md-7"></div>
							</div>
							<div class="row zero font-8">&nbsp;</div>
							<div class="row">
								<div class="col-12 col-md-6 font-8">Fecha que recibirán tu producto</div>
								<div class="col-12 col-md-5">
									<div class="row">
										<select class="col-4 col-md-4 input  black zero">
											<option class="font1">10</option>
											<option class="font1">18</option>
										</select>
										<select class="col-4 col-md-4 input black zero">
											<option class="font1">Mar</option>
											<option class="font1">Jun</option>
										</select>
										<select class="col-4 col-md-4 input black zero">
											<option class="font1">2018</option>
											<option class="font1">2019</option>
										</select>
									</div>
								</div>
								<div class="col-0 col-md-7"></div>
							</div>
						</div>
						<div class="col-12 col-md-5">
							<div class="font-8">¿Algo salió mal con el envío?
								<button class="strong button-green whidth font-8 padding1" style="height:auto">Envío un
									mensaje a
									<span class="strong ">Cemex</span>
								</button>
							</div>
						</div>

					</div> -->
        </div>
        <div class="col-1 col-md-1"></div>
      </div>
    </div>
  </div>
  <!-- PARA ACEPTADAS confirmar entrega-->
  <div class="row width center margin-buttom-3" *ngIf="status==='completed'">
    <div class="zero bg_blanco width">
      <div class="row width center">
        <div class="col-1 col-md-1"></div>
        <div class=" col-10 col-md-10 center title padding-top1">
          CONFIRMA TU ENTREGA
          <!-- 		<div class="row width margin1">
						<div class="col-12 col-md-8 zero padding1">
							<div class="row padding1">
								<div class="col-12 col-md-6 font-8">Fecha que entregaste tu producto</div>
								<div class="col-12 col-md-5">
									<div class="row">
										<select class="col-4 col-md-4 input black zero">
											<option class="font1">10</option>
											<option class="font1">18</option>
										</select>
										<select class="col-4 col-md-4 input black zero">
											<option class="font1">Mar</option>
											<option class="font1">Jun</option>
										</select>
										<select class="col-4 col-md-4 input black zero">
											<option class="font1">2018</option>
											<option class="font1">2019</option>
										</select>
									</div>
								</div>
								<div class="col-0 col-md-7"></div>
							</div>
						</div>
						<button class="col-12 col-md-3 zero button-green">
							<div class=" font-8">Confirma entrega</div>
						</button>

					</div> -->
        </div>
        <div class="col-1 col-md-1"></div>
      </div>
    </div>
  </div>
  <!-- PARA ACEPTADAS contestar encuesta-->
  <!-- 	<div class="row width center margin-buttom-3" *ngSwitchCase="'encuesta'">
		<div class=" cont_info zero bg_blanco width">
			<div class="row width center">
				<div class="col-0 col-md-1"></div>
				<div class=" col-12 col-md-10 center title">
					Nuestra meta en Ixcco es que tu empresa solo realice compras y ventas de manera inteligente, por lo
					que mejoramos
					al escuchar
					tu opinión contestando la siguiente encuensta
					<div class="row width margin1">
						<div class="col-0 col-md-4"></div>
						<div class="col-11 col-md-4 button-green font-8 padding-top05" data-toggle="modal bg_verde"
							data-target="#llenaencuesta">Contestar
							encuesta
						</div>
						<div class="col-12 col-md-4"></div>
					</div>
				</div>
				<div class="col-0 col-md-1"></div>
			</div>
		</div>
	</div> -->

</div>
<div *ngIf="selectedRequest" class=" cont_info zero bg_blanco" id="detalle">

  <div class="tab-content marg-5" id="pills-tabContent">
    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">


      <div class="row marg-pad-bloque">
        <div class="row width" style="padding-top: 20px;">

          <div class="col-3 col-md-1 zero font-8">
            <img *ngIf="selectedRequest.company.profileUrl" class="logo-brand-img"
              [src]="selectedRequest.company.profileUrl">
            <img *ngIf="!selectedRequest.company.profileUrl" class="logo-brand-img"
              src="../../../assets/images/fakeCompany.png">
          </div>
          <div class="col-9 col-md-2 zero collapse_title">
            <p class="col-5 col-md-12 font-8  strong zero center   marginTop">
              {{selectedRequest.company.name}} </p>

          </div>

          <div class="col-7 col-md-3 font-8 left">
            {{selectedRequest.company.companyInfo.city}}
            <br /> Ver las
            <span data-toggle="modal" data-target="#exampleModalCenter">
              <span class="strong"> 883 </span> reseñas
              <strong>
                <i class="fa fa-chevron-right" aria-hidden="true"></i>
              </strong>
            </span>
          </div>
          <div class="col-4 col-md-1 font-8 left zero">
            <!-- <p class="marginTop" >3, 195 Ventas 1,754 Compras</p> -->

            <br />
          </div>
          <div class="col-6 col-md-2 font-8 detail-send">
            <p *ngIf="!selectedRequest.company.fileUrl" class="strong padding-top1  " style="margin-top:10px">Ver
              currículum
            </p>
            <p *ngIf="selectedRequest.company.fileUrl" class="strong padding-top1 "
              (click)="openFileCompany(selectedRequest.company.fileUrl)" style="margin-top:10px">Ver
              currículum </p>

          </div>
          <div class="col-6 col-md-3 font-8 detail-send">
            <select [(ngModel)]="currency" (ngModelChange)="currencyChanged($event)" class="form-control">
              <option value="{{currency}}" *ngFor="let currency of currencies; let i = index">
                {{currency}}
              </option>

            </select>
          </div>
        </div>

        <div class="row width line"
          *ngFor="let product of selectedRequest.purchasesOrders[0].sellProducts;let  i = index">
          <div class="col-6 col-md-3">
            <div class="row width zero">
              <div class="col-12 col-md-3 font-8">
                <div class="logo-company" style="background-image:url(assets/images/fakeProduct.png);"> </div>
              </div>
              <div class="col-12 col-md-9 zero font-8">
                <p class="zero font-8 detail-send"> Nombre del producto </p>
                <p *ngIf="product.name" class="strong zero " style="color: black;"> {{product.name}}
                </p>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-3 ">
            <div class="row">
              <div class="col-12 col-md-5 black font-8">
                <p class="zero font-8 "> Especificación </p>
                <div *ngIf="product.specification">
                  <p *ngIf="product.specification.unit" class="strong zero">
                    {{product.specification.unit }} </p>

                </div>
                <div *ngIf="!product.specification && product.specificationName">
                  <p class="strong zero"> {{product.specificationName }} </p>

                </div>
              </div>
              <div class="col-12 col-md-3 black font-8">
                <p class="zero font-8 d"> Cantidad </p>
                <p *ngIf="product.quantity" class="strong zero"> {{product.quantity }}</p>
              </div>
              <div class="col-12 col-md-3 black font-8">
                <p class="zero font-8 "> Unidad </p>
                <p *ngIf="product.unit" class="strong zero"> {{product.unit.name }} </p>
                <p *ngIf="!product.unit && product.unitName" class="strong zero">
                  {{product.unitName }} </p>

              </div>


            </div>
          </div>
          <div class="col-12 col-md-2 black font-8 ">
            <p class="zero font-8">Caracteristicas </p>
            <div class="font-8">
              <p *ngIf="product.comments" class="strong read-more" [tooltip]="product.comments" placement="bottom">
                {{product.comments}}</p>
            </div>
          </div>
          <div class="col-12 col-md-4 zero black font-8 ">
            <div class="row">
              <div class="col-12 col-md-7 black">
                <p class="zero font-8" style=" margin-right: 50px;"> Precio
                  unitario </p>

                <div class="row">
                  <div class="col-8" style="padding-left: 0px;">
                    <p class="strong font-8">
                      <label class="input next  font-1">{{product.amount| currency}}
                      </label>
                    </p>
                  </div>
                  <div class="col-1">
                    <p style="margin-top: 5px" class="strong font-8">
                      +IVA</p>
                  </div>

                  <div class="col-2">
                    <p style="margin-top: 5px ;margin-left: 5px" class="strong font-8">
                      {{product.brand}}</p>
                  </div>
                </div>
              </div>

              <div class="col-6 col-md-1 zero black font-8">
                <button *ngIf=" product.productURL " class="font-8 button-add">
                  <a (click)="openProductFile(product.productURL)" style="color:#000" target="_blank">
                    Archivo
                    <i class="fa fa-arrow-circle-o-down button-add-clipboard font-2" aria-hidden="true"></i>

                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- -->
    <div id="accordion">
      <div class="card">
        <div class="card-header" id="headingOne">
          <h5 class="mb-0">
            <div class=" collapse_title black" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
              aria-controls="collapseOne">
              DETALLE DEL PEDIDO
            </div>
          </h5>
        </div>
        <!-- ACORDION -->

        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
          <div class="card-body">
            <div class="row width">
              <div class="col-12 col-md-6 zero">
                <p class="zero left font-7">Detalle del pedido</p>
                <p class="zero left strong font-8">{{selectedRequest.details}}</p>


                <p class="zero left  font-7">Margen de tiempo para entregar el producto</p>
                <div class="row texts font1 zero width">
                  <div class="col-12 col-md-3 zero">
                    <p class="zero left font-7 ">Entre el día:</p>
                  </div>
                  <div class="col-12 col-md-6 zero">
                    <p class="zero left texts strong font-8">
                      {{selectedRequest.startDeliveryDate | date: 'dd/MM/yyyy HH:mm'}}
                    </p>
                  </div>
                </div>
                <div class="row texts font1 zero width">
                  <div class="col-12 col-md-3 zero">
                    <p class="zero left font-7 ">Y el día:</p>
                  </div>
                  <div class="col-12 col-md-6 zero">
                    <p class="zero left texts strong font-8">
                      {{selectedRequest.endDeliveryDate | date: 'dd/MM/yyyy HH:mm'}}
                    </p>
                  </div>
                </div>
                <p class="zero left font-7 " style="margin-top: 10px;">Condiciones de pago:</p>
                <div class="row texts zero marginTop15">
                  <div *ngIf="selectedRequest.paymentOptions " class="col-12 col-md-3 zero size9 left">
                    <p>
                      <span class="strong">{{selectedRequest.paymentOptions.anticipated}}%</span>
                      Anticipo</p>
                  </div>
                  <div *ngIf="selectedRequest.paymentOptions " class="col-12 col-md-4 zero  size9 left">
                    <p>
                      <span class="strong">{{selectedRequest.paymentOptions.uponDelivery}}%</span>
                      Contraentrega</p>
                  </div>
                  <div *ngIf="selectedRequest.paymentOptions " class="col-12 col-md-5 zero size9 left">
                    <p>
                      <span class="strong">{{selectedRequest.paymentOptions.credit}}%</span>
                      Crédito por
                      <span class="strong ">{{selectedRequest.paymentOptions.days}}</span>
                      días</p>
                  </div>
                </div>

              </div>
              <div class="col-12 col-md-6">

                <div class="row texts font1 zero width marginTop10">

                  <div style="padding-left: 0;" class="col-12 col-md-7">
                    <p class="zero left font-7 ">Fecha y hora límite para cotizar:</p>
                  </div>
                  <div class="col-12 col-md-5 zero">
                    <p class="zero left texts strong font-8">
                      {{selectedRequest.limitQuotationDate | date: 'dd/MM/yyyy HH:mm'}}

                    </p>
                  </div>
                </div>
                <p *ngIf="selectedRequest.selectedAddress" style="margin-top: 15px;" class="zero left font-7 ">Lugar de
                  entrega de producto:</p>
                <label *ngIf="selectedRequest.selectedAddress" class="zero left texts strong width">
                  {{selectedRequest.selectedAddress}} </label>
                <div style="margin-top: 10px;" class="zero left texts width font-7">
                  <div *ngIf="selectedRequest.pickupRadius" class="zero left texts width font-7">
                    Puedo pasar por el producto en un radio de
                    <label class="zero left texts strong">{{selectedRequest.radius}}</label> km
                  </div>
                </div>
                <div *ngIf="selectedRequest" class="row ">
                  <div class="col-12">
                    <div id="map" #gmap class="mapStyle"></div>
                  </div>
                </div>
              </div>
              <!-- Los botones Aceptar  -->

            </div>
          </div>
        </div>
        <!-- ACORDION -->
        <!-- PARA BLOQUE ENVIADAS -->
        <div class="card">
          <div class="card-header" id="headingTwo">
            <h5 class="mb-0">
              <div class=" collapse_title black" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                aria-controls="collapseTwo">
                DETALLES SOBRE TU ENVÍO
              </div>
            </h5>
          </div>
          <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordion">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-md-3">
                  <p class="zero left font-7 marginTop10 ">Tus Condiciones de
                    pago:</p>


                </div>
                <div class="col-md-6" style="margin-top: 15px">

                  <div class="row texts zero marginTop15">
                    <div *ngIf="selectedRequest.paymentOptions " class="col-12 col-md-3 zero size9 left">
                      <p>
                        <span class="strong">{{selectedRequest.paymentOptions.anticipated}}%</span>
                        Anticipo</p>
                    </div>
                    <div *ngIf="selectedRequest.paymentOptions " class="col-12 col-md-4 zero  size9 left">
                      <p>
                        <span class="strong">{{selectedRequest.paymentOptions.uponDelivery}}%</span>
                        Contraentrega</p>
                    </div>
                    <div *ngIf="selectedRequest.paymentOptions " class="col-12 col-md-5 zero size9 left">
                      <p>
                        <span class="strong">{{selectedRequest.paymentOptions.credit}}%</span>
                        Crédito por
                        <span class="strong ">{{selectedRequest.paymentOptions.days}}</span>
                        días</p>
                    </div>
                  </div>

                </div>
                <div class="col-12 col-md-3 zero" style="margin-top: 15px">
                
  
                  </div>
                <div class="col-12 col-md-3 zero" style="margin-top: 25px">
                  <p *ngIf="selectedRequest.shipmentDetails" class="zero left font-7 ">Detalles del envío
                    o de
                    tus productos:</p>

                </div>
                <div class="col-12 col-md-3 zero" style="margin-top: 15px">

                  <div >
                    <p class="zero font-7  padding1">
                      {{selectedRequest.shipmentDetails}}</p>
                  </div>
                </div>
                <div class="col-12 col-md-3 zero" style="margin-top: 15px">
                  <p class="zero font-7  padding1">Fecha estimada que recibirán tus productos:</p>

                </div>
                <div class="col-12 col-md-3 zero" style="margin-top: 15px">
                  <p class="zero font-7  padding1">
                    {{selectedRequest.purchasesOrders[0].estimatedDeliveryDate | date: 'dd/MM/yyyy '}}
                  </p>

                </div>



                <div class="row zero width">

                  <div class="col-12 col-md-6">

                    <div class="row zero width">
                      <div class="col-12 col-md-4 zero">

                      </div>
                      <div class="col-0 col-md-8 "> </div>
                    </div>
                  </div>
                </div>
                <!-- BOTONES -->

                <!-- Los botones  POR COTIZAR -->
                <div class="row width margin1" *ngIf="porcotizar">
                  <div class="col-0 col-md-4"></div>


                  <div class="col-0 col-md-4"></div>
                </div>
                <!-- Los botones ENVIADAS NO TIENE-->
              </div>
            </div>
          </div>

        </div>
      </div>