<div class="container_bg" style="background-image:url('../../../assets/images/background/agro.jpg');">
	<div class="container-scroll zero">
		<div class="container zero">
			<div class="container_bg_difuninado">
				<app-agro ></app-agro>
			</div>
		</div>

		
	</div>

</div>