import { Component, OnInit } from '@angular/core';
declare var $: any;
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2'
import { Router, Event, NavigationEnd } from '@angular/router';
import { QueryFactory } from '../../../tableQueries/queryFactory';
import { ApiService } from '../../../services/apiService';
import { FooterComponent } from '../../../components/footer/footer.component';
import { ClientTableComponent } from '../../../components/client-table/client-table.component';
@Component({
  selector: 'app-concreted',
  templateUrl: './concreted.component.html',
  styleUrls: ['./concreted.component.css'],
  providers: [ApiService]
})

export class ConcretedComponent implements OnInit {
  
  public borradores: boolean;
  public survey: any;
  public concretedPurchase: Array<any> = [];
  public receivedPurchase: Array<any> = [];
  public record: Array<any> = [];
  public resenas: Array<any>;
  public errorMessage;
  concretadaSelected: any;
  concretadaIndexSelected: boolean;
  concretadaRecSelected: any;
  concretadaRecIndexSelected: boolean;
  concretadaHistoryIndexSelected: boolean;
  concretadaHistorySelected: any;
  public questionsSurvey: Array<any>;
  // Rating
  max: number = 5;
  rate: number = 0;
  isReadonly: boolean = true;
  maxRatings: number = 5;
  rateRatings: number = 0;
  public ratingComment = '';
  public rating = {

    companyId: "",
    quotationId: {},
    requestId: {}

  };
  quotationId = ''
  requestId = '';


  constructor(private _apiService: ApiService, public queryFactory: QueryFactory,
    private spinner: NgxSpinnerService, private _router: Router) {


  }

  ngOnInit() {
    this._router.events.subscribe(
      (value: Event) => {
        if (value instanceof NavigationEnd) {
          this.getComprasConcretadas('Requests/concretedRequests');

        }
      });
    this.getComprasConcretadas('Requests/concretedRequests');
    this.validateDeliver();

    //Re-start values rating modal
    // $('#confirmCreateRating').modal('show');

    $('#confirmCreateRating').on('hidden.bs.modal', () => {
      this.maxRatings = 5;
      this.rateRatings = 0;
      this.ratingComment = '';
      this.getComprasConcretadas('Requests/concretedRequests');
      this._router.navigate(['/concretadas/']);


      //Checks if every order of the purchases is delivered to mark the complete request delivered
      let poSent = 0;
      this.requestId = this.concretadaRecSelected.purchasesOrders[0].requestId;
      if (this.concretadaRecSelected.purchasesOrders.length === 1) {
        console.log('ya la enviaron completo')

        this.editRequest(this.requestId);
      }
      this.goBackConcretadas();
      this.concretadaRecSelected = null;

    });



  }

  openSurvey(concreted) {
    console.log('Open Survey Function')
    console.log(concreted);

    var now = new Date();

    //end delivery date
    let i = 0;
    for (i = 0; i < concreted.length; i++) {
      let date = new Date(concreted.endDeliveryDate);

      if (date < now) {
        if (!concreted[i].survey) {
          let request = concreted[i];

          this.requestId = request.purchasesOrders[0].requestId;
          //this.getSurvey();
          break;
        }

      }
    }

  }
  openSurveySeller(received) {
    /*
        this.concretedPurchase = result.data.sold;
        this.receivedPurchase = result.data.delivered;
        this.record = result.data.record;
        this.openSurvey(this.receivedPurchase)*/


    console.log('Open Survey Function')
    console.log(received);

    var now = new Date();

    //end delivery date
    let i = 0;
    for (i = 0; i < received.length; i++) {
      let date = new Date(received.endDeliveryDate);

      if (date < now) {
        if (!received[i].survey) {
          this.concretedPurchase = received[i];

          this.requestId = this.concretadaHistorySelected.purchasesOrders[0].requestId;
          //this.getSurvey();
          break;
        }

      }
    }

  }
  confirmRating(order?) {
    this.createRating();
    //this.editPurchaseOrder(order); 

  }
  createRating() {
    let rating = {

      companyId: localStorage.getItem('$LoopBack$currentCompanyId'),
      quotationId: this.concretadaRecSelected.id,
      requestId: this.requestId,
      stars: this.rateRatings,
      comments: this.ratingComment


    };

    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
    let params = {
      access_token: tokenId
    }

    this._apiService.addDataObject('Ratings', rating).subscribe(
      (result: any) => {
        console.log('Guardo el Rating');

        console.log(result);
        $('#confirmCreateRating').modal('hide');
        this.spinner.hide();
        this.getComprasConcretadas('Requests/concretedRequests');
        this.goBackConcretadas();

        //this.confirmSurveySeller();


      },
      error => {
        if (error != null) {
          $('#confirmCreateRating').modal('hide');
          this.spinner.hide();
          console.log('Error en el Rating');
          $('#confirmCreateRating').modal('hide');

          console.log(error);

        }
      }

    );

  }
  getSurveys(type) {
    let filter = {
      filter: {
        where: {
        }
      }

    };
    this._apiService.getDataObjectsFilter(type, filter).subscribe(
      (result: any) => {
        console.log('Surveys');

        console.log(result);


      },
      error => {
        if (error != null) {
          console.log(error);
        }
      }
    );
  }
  selectedConfirmSurvey(e, index) {
    console.log(index)
    if (e.target.checked) {
      this.questionsSurvey[index].answer = true;

    }
    else {
      this.questionsSurvey[index].answer = false;

    }

  }
  confirmSurvey() {
    //OLd
    //IXCCO SURVEY listed in admin ixcco
    this.spinner.show();

    this.questionsSurvey.forEach((question) => {
      this.saveAnswer(question.answer, question.questionId);
      console.log(question.answer);

    });
    setTimeout(() => {
      swal({
        title: 'Gracias!',
        text: 'Encuesta contestada satisfactoriamente.',
        type: 'success',
        confirmButtonClass: 'btn ',
        confirmButtonColor: '#000'
      });
      this.spinner.hide()
      $('#ixccoSurvey').modal('hide');
      //$('#confirmCreateRating').modal('hide');

      // this.getQuestions(this.survey.id);
      this.editRequestSurvey(this.concretadaHistorySelected.id, this.survey.id);

    }, 250);

  }
  surveyIxcco() {

    //IXCCO SURVEY listed in admin ixcco
    this.spinner.show();


    setTimeout(() => {
      swal({
        title: 'Gracias!',
        text: 'Encuesta contestada satisfactoriamente.',
        type: 'success',
        confirmButtonClass: 'btn ',
        confirmButtonColor: '#000'
      });
      this.spinner.hide()
      $('#ixccoEncuesta').modal('hide');


    }, 350);
    if (this.survey) {
      this.editRequestSurvey(this.concretadaHistorySelected.id, this.survey.id);

    } else {
      this.editRequestSurveyIxcco(this.concretadaHistorySelected.id, '');

    }

    this.goBackConcretadas();

  }

  saveAnswer(answer, questionId) {

    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
    let companyId = localStorage.getItem('$LoopBack$currentCompanyId');
    let userId = localStorage.getItem('$LoopBack$currentUserId');
    let userRole = localStorage.getItem('$LoopBack$currentUserRole');
    let branchId;
    if (userRole === 'Director' || userRole === 'Gerente') {
      if (this.concretadaHistorySelected.temporalBranch) {
        console.log(this.concretadaHistorySelected.temporalBranch, ' temporal branch')

        branchId = this.concretadaHistorySelected.temporalBranch;
        console.log(this.concretadaHistorySelected);

      } else {
        console.log('no venia el temporal branch')
      }

    } else {
      branchId = localStorage.getItem('userBranchId');

    }
    let params = {
      token: tokenId
    }
    let answerA = {
      answer: answer,
      companyId: companyId,
      branchId: "string",
      userId: userId,
      questionId: questionId

    }
    this._apiService.addDataObject('Answers', answer).subscribe(
      (result: any) => {
        console.log(result);




      },
      error => {
        if (error != null) {


          console.log(error);
        }
      }

    );

  }

  getQuestions(id) {
    console.log(id + "SURVEY")
    let where = {
      surveyId: id
    }
    const getDataQuery = this.queryFactory.generateGetQuery('Questions', where, 100, 0, null, []);

    this._apiService.getDataObjects(getDataQuery).subscribe(
      (result: any) => {
        console.log('Questions');

        let questions = result;
        questions.forEach((question) => {
          let qa = {};
          if (question.type === 'abierta') {
            qa = {
              question: question.question,
              answer: '',
              type: question.type,
              qestionId: question.id
            }
          } else {
            qa = {
              question: question.question,
              answer: false,
              type: question.type,
              qestionId: question.id

            }
          }
          if (this.questionsSurvey) {
            this.questionsSurvey.push(qa);

          } else {
            this.questionsSurvey = [qa];
          }
        });
        console.log(this.questionsSurvey)
        console.log(result);
        $('#ixccoSurvey').modal('show');



      },
      error => {
        if (error != null) {
          console.log(error);
        }
      }
    );
  }

  editRequest(requestId: any) {
    console.log('Request Delivered update')

    console.log(requestId)
    let companyId = localStorage.getItem('$LoopBack$currentCompanyId');

    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
    let params = {
      access_token: tokenId
    }
    console.log(params)
    this._apiService.editDataObject('Requests', requestId, { delivered: true, status: 'completed' }, params).subscribe(
      (result: any) => {
        console.log(result);
        this.goBackConcretadas();

      },
      error => {
        if (error != null) {


        }
      }
    );

  }
  editPurchaseOrder(order: any) {
    console.log('PurchaseOrder Delivered update')
    console.log(order)

    this.spinner.show()
    let companyId = localStorage.getItem('$LoopBack$currentCompanyId');

    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
    let params = {
      access_token: tokenId
    }
    console.log(params)
    //this._apiService.editDataObject('Requests', id, { survey: true, surveyId: surveyId }, params).subscribe(

    this._apiService.editDataObject('PurchaseOrders', order.id, { delivered: true }, params).subscribe(
      (result: any) => {
        console.log(result);

        this.spinner.hide();

        swal({
          title: 'Notificado!',
          text: 'Se ha confirmado la orden.',
          type: 'success',
          confirmButtonClass: 'btn ',
          confirmButtonColor: '#000'
        });

        setTimeout(() => {
          this.getComprasConcretadas('Requests/concretedRequests');

        }, 500)

      },
      error => {
        this.spinner.hide()
        console.log(error);
        this.getComprasConcretadas('Requests/concretedRequests');

        if (error != null) {


        }
      }
    );

  }
  getComprasConcretadas(type, id?) {
    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
    let companyId = localStorage.getItem('$LoopBack$currentCompanyId');
    let userId = localStorage.getItem('$LoopBack$currentUserId');
    let today = new Date();
    let params = {
      date: today,
      userId: userId,
      access_token: tokenId
    };
    this._apiService.getDataObjects(type, null, params).subscribe(
      (result: any) => {

        console.log(result);

        this.concretedPurchase = result.data.sold;
        this.receivedPurchase = result.data.delivered;


        this.record = result.data.record;
        this.openSurvey(this.receivedPurchase)





      },
      error => {
        this.errorMessage = <any>error;
        if (this.errorMessage != null) {
          console.log(this.errorMessage);

        }
      }

    );
  }
  getComprasConcretadasValidate(type, id?) {
    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
    let companyId = localStorage.getItem('$LoopBack$currentCompanyId');
    let userId = localStorage.getItem('$LoopBack$currentUserId');
    let today = new Date();
    let params = {
      date: today,
      userId: userId,
      access_token: tokenId
    };
    this._apiService.getDataObjects(type, null, params).subscribe(
      (result: any) => {

        console.log(result);

        this.concretedPurchase = result.data.sold;
        this.receivedPurchase = result.data.delivered;
        this.record = result.data.record;
        this.openSurvey(this.receivedPurchase);

      },
      error => {
        this.errorMessage = <any>error;
        if (this.errorMessage != null) {
          console.log(this.errorMessage);

        }
      }

    );
  }
  validateDeliver() {
    this.receivedPurchase.forEach((rec) => {
      this.requestId = rec.requestId;
      let poSent = 0;
      rec.purchasesOrders.forEach((po) => {
        if (po.delivered) {
          poSent++;
          console.log('entregaron po')
        }
        else {
          console.log('no entregaron  po')
        }

      });

      if (poSent === rec.length) {
        console.log('ya la enviaron completo')

        this.editRequest(this.requestId);

      }
    })
    setTimeout(() => {
      this.getComprasConcretadas('Requests/concretedRequests');
    }, 500)
  }
  getSurvey() {

    let where = {
      active: true

    }
    const getDataQuery = this.queryFactory.generateGetQuery('Surveys', where, 100, 0, null, []);

    this._apiService.getDataObjects(getDataQuery).subscribe(
      (result: any) => {
        console.log('Survey active');
        console.log(result);
        this.survey = result[0];
        this.getQuestions(this.survey.id)

      },
      error => {
        if (error != null) {
          console.log(error);
        }
      }
    );

  }
  editRequestSurvey(id: any, surveyId) {
    var surv
    if (surveyId) {
      surv = surveyId

    } else {
      surv = ''
    }
    //mark down the quotation to avoid showing the survey form more than once
    console.log(id)
    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
    let params = {
      access_token: tokenId
    }
    console.log(params);
    this._apiService.editDataObject('Requests', id, { surveyChecked: true, surveyId: surv }, params).subscribe(
      (result: any) => {
        console.log(result);
        setTimeout(() => {


        }, 500);

      },
      error => {
        if (error != null) {

          this.spinner.hide();
          // this.newProjectForm.reset();

          console.log(error);

        }
      }

    );

  }
  editRequestSurveyIxcco(id: any, surveyId) {

    //mark down the quotation to avoid showing the survey form more than once
    console.log(id)
    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
    let params = {
      access_token: tokenId
    }
    console.log(params);
    this._apiService.editDataObject('Requests', id, { surveyChecked: true, surveyId: '' }, params).subscribe(
      (result: any) => {
        console.log(result);
        this.rateRatings = 0;
        this.ratingComment = '';


        setTimeout(() => {


        }, 500);

      },
      error => {
        if (error != null) {

          this.spinner.hide();
          // this.newProjectForm.reset();

          console.log(error);

        }
      }

    );

  }
  proveedor() {
    $('#decidir').show(400);
    $('.recibidas').hide(10);

  }
  viewData(evt) {
    if (evt.type === 'concreted') {
      this.viewConcreted(evt.data, evt.index)

    } else if (evt.type === 'received') {
      this.viewConcretedReceived(evt.data, evt.index)


    } else if (evt.type === 'record') {
      this.viewHistoryReceived(evt.data, evt.index)


    }
  }
  viewConcreted(concretada, idx) {
    this.concretadaIndexSelected = idx;
    this.concretadaSelected = concretada;
    this.concretadaRecSelected = null;
    this.concretadaHistorySelected = null;
    $('#concretedNegotiation').show(400);
    $('.recibidas').hide(10);

  }
  viewConcretedReceived(concretada, idx) {
    this.concretadaRecIndexSelected = idx;
    this.concretadaRecSelected = concretada;
    this.concretadaHistorySelected = null;
    this.concretadaSelected = null;
    console.log('------------- Recibida')
    console.log(concretada)
    console.log('-------------Recibida')

    this.requestId = this.concretadaRecSelected.purchasesOrders[0].requestId;
    let poSent = 0;
    this.concretadaRecSelected.purchasesOrders.forEach((po) => {
      if (po.delivered) {
        poSent++;
        console.log('entregaron po')
      }
      else {
        console.log('no entregaron  po')
      }

    });

    if (poSent === this.concretadaRecSelected.purchasesOrders.length) {
      console.log('ya la enviaron completo')

      this.editRequest(this.requestId);
    }

    $('#concretedReceived').show(400);
    $('.recibidas').hide(10);
  }
  viewHistoryReceived(concretada, idx) {
    this.concretadaHistoryIndexSelected = idx;
    this.concretadaHistorySelected = concretada;
    this.concretadaRecSelected = null;
    this.concretadaSelected = null;
    this.requestId = this.concretadaHistorySelected.purchasesOrders[0].requestId;
    if (!concretada.survey) {
      this.getSurvey();

    }

    console.log(concretada);


    $('#concretedHistory').show(400);
    $('.recibidas').hide(10);
  }
  encuesta() {
    $('#encuestas').show(400);
    $('.recibidas').hide(10);
  }


  goBackConcretadas() {
    this.concretadaRecSelected = null;
    this.concretadaHistorySelected = null;
    this.concretadaSelected = null;
    this.concretedPurchase = [];
    this.receivedPurchase = [];
    this.record = [];
    this.spinner.show();
    setTimeout(() => {
      this.getComprasConcretadas('Requests/concretedRequests');
      this.spinner.hide();
    }, 500)

    $('.recibidas').show(400);
    $('#concretedReceived').hide(400);
    $('#concretedHistory').hide(400);
    $('#concretedNegotiation').hide(400);

  }

  historia() {
    $('#historial').show(400);
    $('.recibidas').hide(10);
  }

}
