/// <reference types="@types/googlemaps" />

import { Component, OnInit, Input, ViewChild, Output } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { CardlistService } from "../../../services/cardlist.service";
import { ApiService } from "../../../services/apiService";
import swal from "sweetalert2";
import { Router } from "@angular/router";
//import { } from '@types/googlemaps';
import { CurrencyPipe } from "@angular/common";
import { EventEmitter } from "@angular/core";
import { BsDatepickerConfig, BsLocaleService } from "ngx-bootstrap/datepicker";
import { listLocales } from "ngx-bootstrap/chronos";
import { FileUploader, FileItem } from "ng2-file-upload";
import { UUID } from "angular2-uuid";

import {} from "events";
declare var $: any;
import * as XLSX from "xlsx";
import { QueryFactory } from "../../../tableQueries/queryFactory";
import { PdfQuotationComponent } from "../../../modals/pdfQuotation/pdfQuotation.component";
type AOA = Array<Array<any>>;

@Component({
  selector: "app-respondQuotation",
  templateUrl: "./respondQuotation.component.html",
  styleUrls: ["./respondQuotation.component.css"],
  providers: [ApiService, CardlistService, CurrencyPipe],
})
export class RespondQuotation implements OnInit {
  @Input() paraenviada2: boolean;
  @Input() paraenviada1: boolean;
  @Input() porcotizar: boolean;
  @Input() request: any;
  @Input() onlyViewForSales: boolean;
  @Input() completed: boolean;

  @Input() bloque: boolean;
  @Input() paraaceptadas: boolean;
  @Input() paranueva: boolean ; //si puedees modificar valores de la cotizacion

  @Input() categoria: String;
  @Input() fueenviada: String;

  @Output() recQuotation: EventEmitter<any> = new EventEmitter();
  @Output() fileProductUrl: EventEmitter<any> = new EventEmitter();

  public afuConfig: any = {
    multiple: false,
    formatsAllowed: ".jpg,.png",
    maxSize: "1",
    uploadAPI: {
      url: "https://example-file-upload-api",
      headers: {
        "Content-Type": "text/plain;charset=UTF-8",
      },
    },
    theme: "dragNDrop",
    hideProgressBar: true,
    hideResetBtn: true,
    hideSelectBtn: true,
    replaceTexts: {
      selectFileBtn: "Select Files",
      resetBtn: "Reset",
      uploadBtn: "Upload",
      dragNDropBox: "Drag N Drop",
      attachPinBtn: "Attach Files...",
      afterUploadMsg_success: "Successfully Uploaded !",
      afterUploadMsg_error: "Upload Failed !",
    },
  };

  public securityCheck = true;
  public suppliesCheck = true;
  public tecnicsCheck = true;
  public valueCheck = true;

  public securityUrl = "";
  public suppliesUrl = "";
  public tecnicsUrl = "";
  public valueUrl = "";

  public securityName = "";
  public suppliesName = "";
  public tecnicsName = "";
  public valueName = "";

  public files: any;

  public selectedReason = "";
  public codesToReview = [];
  public selectedRequest: any;
  public previewQuotation: any;
  formattedAmount: string = "0";
  public canSend = false;
  value: any;
  locale = "en";
  locales = listLocales();
  @ViewChild("gmap") gmapElement: any;
  public isMXN = true;
  public USDCURRENCY: number;
  data: any;
  map: google.maps.Map;
  public editar: any = "true";
  public actualBranch = "";
  public userRole = "";
  public currentAddress = "";
  public currentLng: any;
  public currentLat: any;
  public marker: any;
  public location: any;
  excelUploaded = false;
  public uploader: FileUploader;
  public uploader2: FileUploader;
  public uploader3: FileUploader;
  public uploader4: FileUploader;
  public URL;
  public selectedProductImageURL = "";
  estimatedDeliveryDate = new Date();
  public paymentOptions = {
    anticipated: 0,
    uponDelivery: 0,
    credit: 0,
    days: 0,
  };
  public manageReturn;

  public URLBUCKET;
  /*VARIABLES DRAG N DROP*/
  public hasBaseDropZoneOver: boolean = false;
  public hasBaseDropZoneOver2: boolean = false;

  public hasBaseDropZoneOver3: boolean = false;
  public hasBaseDropZoneOver4: boolean = false;

  public hasAnotherDropZoneOver: boolean = false;

  public deliveryDetails = "";
  public currencies = ["MXN", "USD"];
  public currency = "MXN";
  constructor(
    private localeService: BsLocaleService,
    private queryFactory: QueryFactory,
    private currencyPipe: CurrencyPipe,
    private _apiService: ApiService,
    private spinner: NgxSpinnerService,
    private _router: Router
  ) {
    console.log('respond Quotation component nueva solicitud' + this.paranueva);
    console.log(this.request)

    this.URL = this._apiService.URL;

    this.uploader = new FileUploader({ url: this.URL });
    this.uploader2 = new FileUploader({ url: this.URL });
    this.uploader3 = new FileUploader({ url: this.URL });
    this.uploader4 = new FileUploader({ url: this.URL });
    this.URLBUCKET = this._apiService.URLBUCKET;

    this.paraenviada2 = true;
    this.paraenviada1 = true;
    this.porcotizar = true;
    this.bloque = true;
    this.paraaceptadas = true;
  }
  download(item) {
    console.log(item);
    this._apiService.getDataO(item).subscribe(
      (result) => {
        console.log(result);
      },
      (error) => {
        this.spinner.hide();

        if (error != null) {
        }
      }
    );
  }

  transformAmount(element, value?) {
    console.log(element.target.value);
    console.log(value);
    if (isNaN(value)) {
      console.log(" is not a number ");
      var newValue = parseFloat(value.replace(/,/g, ""));
      console.log(newValue);

      try {
        this.formattedAmount = this.currencyPipe.transform(
          newValue,
          "",
          true,
          "1.2-2"
        );

        element.target.value = this.formattedAmount;
      } catch (e) {
        console.log(e);
      }
    } else {
      console.log(" is a number ");
      try {
        this.formattedAmount = this.currencyPipe.transform(
          value,
          "",
          true,
          "1.2-2"
        );

        element.target.value = this.formattedAmount;
      } catch (e) {
        console.log(e);
      }
    }
  }
  populateExcel() {
    if (this.selectedRequest.contestLabel === "contest") {
      let headers = [
        "Codigo",
        "Concepto",
        "Unidad",
        "Cantidad",
        "Precio Unitario",
      ];
      var arraysExcel = [];
      //arraysExcel.push(headers);
      this.selectedRequest.requestProducts.forEach((productExcel) => {
        let objRow = {
          Codigo: productExcel.code,
          Concepto: productExcel.name,
          Unidad: productExcel.unitName,
          Cantidad: productExcel.quantity,
          PU: "",
        };
        // let row = [productExcel.subCategory,productExcel.name,productExcel.unitName,productExcel.quantity,''];
        arraysExcel.push(objRow);
      });
      setTimeout(() => {
        this.exportAsExcelFile(arraysExcel, headers);
      }, 500);
    } else {
      console.log(this.selectedRequest.requestProducts);
      let headers = [
        "Concepto",
        "Especificacion",
        "Unidad",
        "Cantidad",
        "Precio Unitario",
        "Marca",
      ];
      var arraysExcel = [];

      this.selectedRequest.requestProducts.forEach((productExcel) => {
        let objRow = {
          Codigo: productExcel.id,
          Concepto: productExcel.name,
          Especificacion: productExcel.specificationName,
          Cantidad: productExcel.quantity,
          Unidad: productExcel.unitName,
          PU: "",
          Marca: "",
        };
        arraysExcel.push(objRow);
      });
      setTimeout(() => {
        this.exportAsExcelFile(arraysExcel, headers);
      }, 500);
    }
  }
  populateExcelRequest() {
    let headers = [
      "Codigo",
      "Concepto",
      "Unidad",
      "Cantidad",
      "Precio Unitario",
    ];
    var arraysExcel = [];
    this.selectedRequest.requestProducts.forEach((productExcel) => {
      let objRow = {
        Codigo: productExcel.code,
        Concepto: productExcel.name,
        Unidad: productExcel.unitName,
        Cantidad: productExcel.quantity,
        PU: "",
      };
      arraysExcel.push(objRow);
    });
    setTimeout(() => {
      this.exportAsExcelFile(arraysExcel, headers);
    }, 500);
  }
  public exportAsExcelFile(
    json: any[],
    headers: any,
    excelFileName?: string
  ): void {
    console.log(json);
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    XLSX.writeFile(workbook, "ExcelUsuario.xlsx");
  }

  convertToCSV(objArray) {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "";
    var row = "";

    for (var index in objArray[0]) {
      //Now convert each value to string and comma-separated
      row += index + ",";
    }
    row = row.slice(0, -1);
    //append Label row with line break
    str += row + "\r\n";

    for (var i = 0; i < array.length; i++) {
      var line = "";
      for (var index in array[i]) {
        if (line != "") line += ",";

        line += array[i][index];
      }
      str += line + "\r\n";
    }
    return str;
  }

  downloadExcel() {
    var csvData = this.convertToCSV(this.request.products);
    var a = document.createElement("a");
    a.setAttribute("style", "display:none;");
    document.body.appendChild(a);
    var blob = new Blob([csvData], { type: "text/csv" });
    var url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = "User_upload.csv"; /* your file name*/
    a.click();
    return "success";
  }
  onFileChangeContest(evt: any) {
    //this.spinner.show();

    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) throw new Error("Cannot use multiple files");
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });
      console.log(this.data);
      // console.log(this.data[1]);
      const columns = JSON.stringify(this.data[0]);
      const excel = JSON.stringify(this.data);
      console.log(excel);
      if (this.selectedRequest.contestLabel === "contest") {
        this.processExcelDataContest(this.data);
      } else {
        this.processExcelDataRequest(this.data);
      }
      this.excelUploaded = true;
    };
    reader.readAsBinaryString(target.files[0]);
  }
  processExcelDataContest(data) {
    var prices = 0;
    var code = 0;
    var codes = [];
    let obj = {};
    var error = false;
    data.splice(0, 1);
    console.log(data);
    console.log(this.codesToReview);

    data.forEach((product, index) => {
      var stringProduct = product[0].toString();
      console.log(stringProduct);
      console.log(
        this.codesToReview.includes(stringProduct.toLowerCase()) +
          "-----" +
          stringProduct.toLowerCase()
      );
      if (this.codesToReview.includes(stringProduct.toString().toLowerCase())) {
        code++;
        console.log(this.codesToReview[index] + "-----" + stringProduct);
        codes.push(this.codesToReview[index]);
      }
      if (product[4]) {
        prices++;
      } else {
        console.log("error" + product[4]);
        error = true;
      }
      obj[stringProduct.toLowerCase()] = Number(product[4]).toFixed(2);
    });
    this.selectedRequest.requestProducts.forEach((element, idx) => {
      this.selectedRequest.requestProducts[idx]["amount"] =
        obj[this.selectedRequest.requestProducts[idx].lowercaseCode];
    });
    console.log(obj);
    console.log(this.selectedRequest.requestProducts);

    console.log(prices);
    console.log(data.length);

    if (prices !== data.length || error) {
      swal({
        title: "Alerta!",
        text: "Faltan Precios en la cotizacion, favor de intentar nuevamente",
        type: "warning",
        confirmButtonClass: "btn ",

        confirmButtonColor: "#000",
      });
    } else if (code !== this.codesToReview.length) {
      console.log(code);
      console.log(this.codesToReview);
      swal({
        title: "Alerta!",
        text: "Uno o mas codigos contienen errores o hacen falta" + "",
        type: "warning",
        confirmButtonClass: "btn ",

        confirmButtonColor: "#000",
      });
    } else {
      swal({
        title: "Exito!",
        text: "Precios subidos correctamente",
        type: "success",
        confirmButtonClass: "btn ",

        confirmButtonColor: "#000",
      });
      this.spinner.hide();
      this.excelUploaded = true;
      console.log(this.selectedRequest.requestProducts);
    }
  }
  processExcelDataRequest(data) {
    var prices = 0;
    var code = 0;
    var codes = [];
    let obj = {};
    var error = false;
    data.splice(0, 1);
    console.log(data);
    console.log(this.codesToReview);

    data.forEach((product, index) => {
      var stringProduct = product[0].toString();
      console.log(stringProduct);
      console.log(
        this.codesToReview.includes(stringProduct.toLowerCase()) +
          "-----" +
          stringProduct.toLowerCase()
      );
      if (this.codesToReview.includes(stringProduct.toString().toLowerCase())) {
        code++;
        console.log(this.codesToReview[index] + "-----" + stringProduct);
        codes.push(this.codesToReview[index]);
      }
      if (product[5]) {
        prices++;
      } else {
        console.log("error" + product[5]);
        error = true;
      }
      obj[stringProduct.toLowerCase()] = Number(product[5]).toFixed(2);

      obj[stringProduct.toLowerCase() + "brand"] = product[6];
    });
    this.selectedRequest.requestProducts.forEach((element, idx) => {
      this.selectedRequest.requestProducts[idx]["amount"] =
        obj[this.selectedRequest.requestProducts[idx].lowercaseCode];
      this.selectedRequest.requestProducts[idx]["brand"] =
        obj[this.selectedRequest.requestProducts[idx].lowercaseCode + "brand"];
    });
    console.log(obj);
    console.log(this.selectedRequest.requestProducts);

    console.log(prices);
    console.log(data.length);

    if (prices !== data.length || error) {
      swal({
        title: "Alerta!",
        text: "Faltan Precios en la cotizacion, favor de intentar nuevamente",
        type: "warning",
        confirmButtonClass: "btn ",

        confirmButtonColor: "#000",
      });
    } else if (code !== this.codesToReview.length) {
      console.log(code);
      console.log(this.codesToReview);
      swal({
        title: "Alerta!",
        text: "Uno o mas codigos contienen errores o hacen falta" + "",
        type: "warning",
        confirmButtonClass: "btn ",

        confirmButtonColor: "#000",
      });
    } else {
      swal({
        title: "Exito!",
        text: "Precios subidos correctamente",
        type: "success",
        confirmButtonClass: "btn ",

        confirmButtonColor: "#000",
      });
      this.spinner.hide();
      this.excelUploaded = true;
      console.log(this.selectedRequest.requestProducts);
    }
  }
  public getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  public fileOverBase1(e: any): void {
    clearTimeout(this.manageReturn);

    console.log(e);
    this.hasBaseDropZoneOver = e;

    this.manageReturn = setTimeout(() => {
      console.log(this.uploader.queue);
      if (this.uploader.queue) {
        this.uploader.uploadItem(this.uploader.queue[0]);

        console.log(this.uploader.queue[0]._file.name);

        /* let uuid = UUID.UUID();
  public securityUrl = "";
  public suppliesUrl = "";
  public tecnicsUrl = "";
  public valueUrl = "";

        this.uploader.queue[0].file.name = uuid + '.jpg';
        this.uploader.queue[0].upload();
        console.log(this.URLBUCKET + uuid + '.jpg');*/
        this.securityUrl = this.URLBUCKET + this.uploader.queue[0]._file.name;
        this.securityName = this.uploader.queue[0]._file.name;
      }
    }, 250);
  }

  public fileOverBase2(e: any): void {
    clearTimeout(this.manageReturn);

    console.log(e);
    this.hasBaseDropZoneOver2 = e;

    this.manageReturn = setTimeout(() => {
      console.log(this.uploader2.queue);
      if (this.uploader2.queue) {
        this.uploader2.uploadItem(this.uploader2.queue[0]);

        console.log(this.uploader2.queue[0]._file.name);

        /* let uuid = UUID.UUID();
  public securityUrl = "";
  public suppliesUrl = "";
  public tecnicsUrl = "";
  public valueUrl = "";

        this.uploader.queue[0].file.name = uuid + '.jpg';
        this.uploader.queue[0].upload();
        console.log(this.URLBUCKET + uuid + '.jpg');*/
        this.suppliesUrl = this.URLBUCKET + this.uploader2.queue[0]._file.name;
        this.suppliesName = this.uploader2.queue[0]._file.name;
      }
    }, 250);
  }

  public fileOverBase3(e: any): void {
    clearTimeout(this.manageReturn);

    console.log(e);
    this.hasBaseDropZoneOver3 = e;

    this.manageReturn = setTimeout(() => {
      if (this.uploader3.queue) {
        this.uploader3.uploadItem(this.uploader3.queue[0]);

        console.log(this.uploader3.queue[0]._file.name);

        /* let uuid = UUID.UUID();
  public securityUrl = "";
  public suppliesUrl = "";
  public tecnicsUrl = "";
  public valueUrl = "";

        */
        this.tecnicsUrl = this.URLBUCKET + this.uploader3.queue[0]._file.name;
        this.tecnicsName = this.uploader3.queue[0]._file.name;
      }
    }, 250);
  }

  public fileOverBase4(e: any): void {
    clearTimeout(this.manageReturn);

    console.log(e);
    this.hasBaseDropZoneOver4 = e;

    this.manageReturn = setTimeout(() => {
      console.log(this.uploader4.queue);
      if (this.uploader4.queue) {
        this.uploader4.uploadItem(this.uploader4.queue[0]);

        console.log(this.uploader4.queue[0]._file.name);

        /* let uuid = UUID.UUID();
  public securityUrl = "";
  public suppliesUrl = "";
  public tecnicsUrl = "";
  public valueUrl = "";

       */
        this.valueUrl = this.URLBUCKET + this.uploader4.queue[0]._file.name;
        this.valueName = this.uploader4.queue[0]._file.name;
      }
    }, 250);
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  removeCurrencyPipeFormat(element, value?) {
    try {
      if (element.target.value.indexOf("$") !== -1) {
        this.formattedAmount = this.formattedAmount.replace(/[$,]/g, "");
        element.target.value = this.formattedAmount;
      } else {
        this.formattedAmount = "0";
      }
    } catch (e) {
      console.log(e);
    }
  }
  checkURL(url) {
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }

  openProductFile(fileUrl) {
    console.log(this.checkURL(fileUrl));
    if (this.checkURL(fileUrl)) {
      this.fileProductUrl.emit(fileUrl);
    } else {
      window.open(fileUrl, "_blank");
    }
  }
  acceptRequest() {
    console.log("acepte");

    console.log(this.selectedRequest);
    let branchId = "";
    let userRole = localStorage.getItem("$LoopBack$currentUserRole");
    if (userRole === "Director" || userRole === "Gerente") {
      if (this.selectedRequest.temporalBranch) {
        console.log(this.selectedRequest.temporalBranch, " temporal branch");

        branchId = this.selectedRequest.temporalBranch;
        console.log(this.selectedRequest);
      } else {
        console.log("no venia el temporal branch");
      }
    } else {
      branchId = localStorage.getItem("userBranchId");
    }

    this.checkIfAcceptedBranch(this.selectedRequest.id, branchId).then(
      (accepted) => {
        if (accepted) {
          swal({
            title: "Alerta!",
            text: "Cotizacion previamente aceptada.",
            type: "info",
            confirmButtonClass: "btn ",

            confirmButtonColor: "#000",
          });
          this._router.navigate(["/porcotizar"]);
        } else {
          if (this.selectedRequest.acceptedBranches) {
            this.selectedRequest.acceptedBranches.push(branchId);
            this.editRequest(
              this.selectedRequest.id,
              this.selectedRequest,
              true
            );
          } else {
            this.selectedRequest["acceptedBranches"] = [branchId];
            this.editRequest(
              this.selectedRequest.id,
              this.selectedRequest,
              true
            );
          }
        }
      }
    );
  }

  rejectRequest() {
    let branchId = "";
    let userRole = localStorage.getItem("$LoopBack$currentUserRole");
    if (userRole === "Director" || userRole === "Gerente") {
      if (this.selectedRequest.temporalBranch) {
        branchId = this.selectedRequest.temporalBranch;
      } else {
        console.log("no venia el temporal branch");
      }
    } else {
      branchId = localStorage.getItem("userBranchId");
    }
    if (this.selectedRequest.rejectedBranches) {
      this.selectedRequest.rejectedBranches.push(branchId);
      $("#rejectedReasonModalDetail").modal("show");

      this.editRequestReject(
        this.selectedRequest.id,
        this.selectedRequest,
        false
      );
    } else {
      this.selectedRequest["rejectedBranches"] = [branchId];

      $("#rejectedReasonModalDetail").modal("show");

      this.editRequestReject(
        this.selectedRequest.id,
        this.selectedRequest,
        false
      );
    }
    console.log(this.selectedRequest);
  }
  selectedConfirmShip(e) {
    if (e.target.checked) {
      this.canSend = true;
    } else {
      this.canSend = false;
    }
    console.log(this.canSend);
  }
  editRequest(id: any, request: any, accepted) {
    this.spinner.show();

    console.log(request);

    console.log(id);

    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let params = {
      access_token: tokenId,
    };
    console.log(params);
    this._apiService.editDataObject("Requests", id, request, params).subscribe(
      (result) => {
        console.log(result);
        setTimeout(() => {
          this.spinner.hide();
          if (request.temporalBranch) {
            delete request.temporalBranch;
          }
          if (accepted) {
          } else {
          }

          this.spinner.hide();
          this._router.navigate(["/porcotizar"]);
        }, 500);
      },
      (error) => {
        this.spinner.hide();

        if (error != null) {
        }
      }
    );
  }
  editRequestReject(id: any, request: any, accepted) {
    this.spinner.show();

    console.log(request);

    console.log(id);

    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let params = {
      access_token: tokenId,
    };
    console.log(params);
    this._apiService.editDataObject("Requests", id, request, params).subscribe(
      (result) => {
        console.log(result);
        setTimeout(() => {
          this.spinner.hide();

          this.spinner.hide();
        }, 500);
      },
      (error) => {
        this.spinner.hide();

        if (error != null) {
          console.log(error);
        }
      }
    );
  }
  initMap(geopoint) {
    console.log(geopoint);
    if (this.map) {
      this.map = null;
    }
    setTimeout(() => {
      var mapProp = {
        center: new google.maps.LatLng(geopoint.lat, geopoint.lng),
        zoom: 15,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      };
      this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
      this.showMarkerPosition(geopoint);
    }, 1000);
  }

  showMarkerPosition(position) {
    this.currentLat = position.lat;
    this.currentLng = position.lng;

    let location = new google.maps.LatLng(position.lat, position.lng);
    this.map.panTo(location);

    if (!this.marker) {
      this.marker = new google.maps.Marker({
        draggable: false,
        animation: google.maps.Animation.DROP,
        position: location,

        map: this.map,
        title: "Tu Sucursal",
      });
      this.marker.setPosition(location);
    } else {
      this.marker = null;
      this.marker = new google.maps.Marker({
        draggable: false,
        animation: google.maps.Animation.DROP,
        position: location,

        map: this.map,
        title: "Tu Sucursal",
      });
      this.marker.setPosition(location);
    }
  }
  reviewedQuotation() {
    swal({
      title: "Guardado!",
      text: "Cambios guardados correctamente.",
      type: "success",
      confirmButtonClass: "btn ",

      confirmButtonColor: "#000",
    });
  }
  saveQuotation() {
    console.log(this.selectedRequest);

    this.paymentOptions.credit = this.paymentOptions.credit
      ? this.paymentOptions.credit
      : 0;
    this.paymentOptions.anticipated = this.paymentOptions.anticipated
      ? this.paymentOptions.anticipated
      : 0;
    this.paymentOptions.uponDelivery = this.paymentOptions.uponDelivery
      ? this.paymentOptions.uponDelivery
      : 0;
    this.paymentOptions.days = this.paymentOptions.days
      ? this.paymentOptions.days
      : 0;

    let payO =
      this.paymentOptions.credit +
      this.paymentOptions.anticipated +
      this.paymentOptions.uponDelivery;
    let productsWithoutPrice = 0;
    this.selectedRequest.requestProducts.forEach((product) => {
      if (!product.amount) {
        productsWithoutPrice++;
      }
    });
    if (productsWithoutPrice > 0) {
      swal({
        title: "¿Alerta ?",
        text: "Hay productos sin precio asignado en la cotizacion, deseas continuar ?",
        type: "info",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, continuar!",
        confirmButtonClass: "btn ",
        confirmButtonColor: "#000",
        cancelButtonClass: "btn btn-danger",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          // result.dismiss can be 'cancel', 'overlay',
          if (payO === 100) {
            if (this.selectedRequest.contestLabel === "request") {
              if (this.estimatedDeliveryDate) {
                $("#cotizacionPdf").modal("show");

                //this.spinner.show();
                this.request.shipmentDetails = this.deliveryDetails;
                this.request = this.selectedRequest;
                console.log(this.request);
                let quotedProducts = [];
                let quotedProduct = {};
                let today = new Date();
                let companyId = localStorage.getItem(
                  "$LoopBack$currentCompanyId"
                );

                let branchId = "";
                let userRole = localStorage.getItem(
                  "$LoopBack$currentUserRole"
                );
                if (userRole === "Director" || userRole === "Gerente") {
                  if (this.request.temporalBranch) {
                    console.log(
                      this.request.temporalBranch,
                      " temporal branch"
                    );

                    branchId = this.request.temporalBranch;
                    console.log(this.request);
                  } else {
                    console.log("no venia el temporal branch");
                  }
                } else {
                  branchId = localStorage.getItem("userBranchId");
                }

                console.log(this.selectedRequest.requestProducts);

                this.selectedRequest.requestProducts.forEach(
                  (product, index) => {
                    quotedProduct["name"] = product.name;
                    quotedProduct["createdAt"] = product.createdAt;
                    quotedProduct["requestId"] = product.requestId;
                    quotedProduct["quantity"] = product.quantity;
                    quotedProduct["categoryId"] = product.categoryId;
                    quotedProduct["specificationId"] = product.specificationId;
                    quotedProduct["subCategoryId"] = product.subCategoryId;
                    quotedProduct["companyId"] = product.companyId;
                    quotedProduct["userId"] = product.userId;
                    quotedProduct["requestProductId"] =
                      product.requestProductId;
                    quotedProduct["comments"] = product.brand;
                    quotedProduct["amount"] = Number(product.amount);
                    // quotedProduct['amount'] = parseFloat(product.amount).toFixed(2)

                    quotedProducts.push(quotedProduct);
                    console.log(product);

                    console.log(product.amount);
                    console.log(Number(product.amount));
                    console.log(product.quantity);
                  }
                );

                let userId = localStorage.getItem("$LoopBack$currentUserId");
                this.selectedRequest.requestProducts.forEach(
                  (product, index) => {
                    console.log(
                      this.selectedRequest.requestProducts[index].comments
                    );
                    console.log(
                      this.selectedRequest.requestProducts[index].brand
                    );

                    this.selectedRequest.requestProducts[index].comments =
                      this.selectedRequest.requestProducts[index].brand;
                    console.log(
                      this.selectedRequest.requestProducts[index].comments
                    );
                  }
                );
                var files = {
                  securityUrl: this.securityUrl,
                  suppliesUrl: this.suppliesUrl,
                  tecnicsUrl: this.tecnicsUrl,
                  valueUrl: this.valueUrl,
                  securityName: this.securityName,
                  suppliesName: this.suppliesName,
                  tecnicsName: this.tecnicsName,
                  valueName: this.valueName,
                };
                let quotation = {
                  paymentOptions: this.paymentOptions,
                  branchId: branchId,
                  requestId: this.request.id,
                  sellerId: userId,
                  estimatedDeliveryDate: this.estimatedDeliveryDate,
                  deliveryDetails: this.deliveryDetails,
                  companyId: companyId,
                  quotedProducts: this.selectedRequest.requestProducts,
                  currency: this.currency,
                  canSend: this.canSend,
                  requiredFilesUrl: files,
                };

                /* public securityUrl = "";
                public suppliesUrl = "";
                public tecnicsUrl = "";
                 public valueUrl = ""*/

                this.previewQuotation = quotation;
                let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
                let params = {
                  access_token: tokenId,
                };
                console.log(quotation);
                this.checkIfQuotedBranch(
                  this.selectedRequest.id,
                  branchId
                ).then((accepted) => {
                  if (accepted) {
                    this.spinner.hide();
                    swal({
                      title: "Alerta!",
                      text: "Esta cotizacion ya fue enviada previamente por la empresa.",
                      type: "info",
                      confirmButtonClass: "btn ",
                      confirmButtonColor: "#000",
                    });

                    $("#cotizacionPdf").modal("hide");
                    $(".modal-backdrop").remove();
                    $("body").removeClass("modal-open");
                    this._router.navigate(["/ventasenviadas"]);
                  } else {
                    this.recQuotation.emit(quotation);
                  }
                });
              } else {
                swal({
                  title: "Error!",
                  text: "Por favor selecciona una fecha estimada de entrega.",
                  type: "error",
                  confirmButtonClass: "btn ",

                  confirmButtonColor: "#000",
                });
              }
            } else {
              //ES LICITACION
              // $('#cotizacionPdf').modal('show')
              //this.spinner.show();
              this.request.shipmentDetails = this.deliveryDetails;
              this.request = this.selectedRequest;
              console.log(this.request);
              let quotedProducts = [];
              let quotedProduct = {};
              let today = new Date();
              let companyId = localStorage.getItem(
                "$LoopBack$currentCompanyId"
              );
              let branchId = "";
              let userRole = localStorage.getItem("$LoopBack$currentUserRole");
              if (userRole === "Director" || userRole === "Gerente") {
                if (this.request.temporalBranch) {
                  console.log(this.request.temporalBranch, " temporal branch");

                  branchId = this.request.temporalBranch;
                  console.log(this.request);
                } else {
                  console.log("no venia el temporal branch");
                }
              } else {
                branchId = localStorage.getItem("userBranchId");
              }

              console.log(this.selectedRequest.requestProducts);

              this.selectedRequest.requestProducts.forEach((product, index) => {
                quotedProduct["name"] = product.name;
                quotedProduct["createdAt"] = product.createdAt;
                quotedProduct["requestId"] = product.requestId;
                quotedProduct["quantity"] = product.quantity;
                quotedProduct["categoryId"] = product.categoryId;
                quotedProduct["specificationId"] = product.specificationId;
                quotedProduct["subCategoryId"] = product.subCategoryId;
                quotedProduct["companyId"] = product.companyId;
                quotedProduct["userId"] = product.userId;
                quotedProduct["requestProductId"] = product.requestProductId;
                quotedProduct["comments"] = product.comments;
                if (isNaN(product.amount)) {
                  console.log(" is not a number ");
                  var newValue = parseFloat(product.amount.replace(/,/g, ""));
                  console.log(newValue);
                  quotedProduct["amount"] = Number(newValue);
                  this.selectedRequest.requestProducts[index].amount =
                    Number(newValue);
                  //Number(product.amount);

                  try {
                    this.formattedAmount = this.currencyPipe.transform(
                      newValue,
                      "",
                      true,
                      "1.2-2"
                    );

                    // product.amount= this.formattedAmount;
                  } catch (e) {
                    console.log(e);
                  }
                } else {
                  quotedProduct["amount"] = Number(product.amount);
                  // quotedProduct['amount'] = parseFloat(product.amount).toFixed(2)

                  console.log(product);

                  console.log(product.amount);
                  console.log(Number(product.amount));
                  console.log(product.quantity);
                }

                console.log(quotedProduct);

                quotedProducts.push(quotedProduct);
              });
              this.selectedRequest.requestProducts.forEach((product, index) => {
                console.log(
                  this.selectedRequest.requestProducts[index].comments
                );
                console.log(this.selectedRequest.requestProducts[index].brand);

                this.selectedRequest.requestProducts[index].comments =
                  this.selectedRequest.requestProducts[index].brand;
                console.log(
                  this.selectedRequest.requestProducts[index].comments
                );
              });
              let userId = localStorage.getItem("$LoopBack$currentUserId");
              var files = {
                securityUrl: this.securityUrl,
                suppliesUrl: this.suppliesUrl,
                tecnicsUrl: this.tecnicsUrl,
                valueUrl: this.valueUrl,
                securityName: this.securityName,
                suppliesName: this.suppliesName,
                tecnicsName: this.tecnicsName,
                valueName: this.valueName,
              };
              let quotation = {
                paymentOptions: this.paymentOptions,
                branchId: branchId,
                requestId: this.request.id,
                sellerId: userId,
                estimatedDeliveryDate: this.estimatedDeliveryDate,
                deliveryDetails: this.deliveryDetails,
                companyId: companyId,
                quotedProducts: this.selectedRequest.requestProducts,
                currency: this.currency,
                canSend: this.canSend,
                requiredFilesUrl: files,
              };

              this.previewQuotation = quotation;
              let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
              let params = {
                access_token: tokenId,
              };
              console.log(quotation);
              console.log(this.checkIfQuotedBranch);

              this.checkIfQuotedBranch(this.selectedRequest.id, branchId).then(
                (accepted) => {
                  if (accepted) {
                    this.spinner.hide();
                    swal({
                      title: "Alerta!",
                      text: "Esta cotizacion ya fue enviada previamente por la empresa.",
                      type: "info",
                      confirmButtonClass: "btn ",
                      confirmButtonColor: "#000",
                    });

                    $("#cotizacionPdf").modal("hide");
                    $(".modal-backdrop").remove();
                    $("body").removeClass("modal-open");
                    this._router.navigate(["/ventasenviadas"]);
                  } else {
                    //this.recQuotation.emit(quotation);
                    this.saveContestQuotation(quotation);
                  }
                }
              );
            }
          } else {
            if (payO > 100) {
              swal({
                title: "Error!",
                text: "Las condiciones de pago superan el 100%.",
                type: "error",
                confirmButtonClass: "btn ",

                confirmButtonColor: "#000",
              });
            } else if (payO < 100) {
              swal({
                title: "Error!",
                text: "Las condiciones de pago  no cumplen el 100%.",
                type: "error",
                confirmButtonClass: "btn ",

                confirmButtonColor: "#000",
              });
            }
          }
        } else if (result.dismiss) {
          /*  swal({
               title: 'Cancelado',
               text: 'No se realizaron cambios',
               type: 'info',
               confirmButtonClass: 'btn ',
   
               confirmButtonColor: '#000'
           }) */
        }
      });
    } else {
      if (this.selectedRequest.contestLabel === "request") {
        if (payO === 100) {
          if (this.estimatedDeliveryDate) {
            $("#cotizacionPdf").modal("show");

            //this.spinner.show();
            this.request.shipmentDetails = this.deliveryDetails;
            this.request = this.selectedRequest;
            console.log(this.request);
            let quotedProducts = [];
            let quotedProduct = {};
            let today = new Date();
            let companyId = localStorage.getItem("$LoopBack$currentCompanyId");

            let branchId = "";
            let userRole = localStorage.getItem("$LoopBack$currentUserRole");
            if (userRole === "Director" || userRole === "Gerente") {
              if (this.request.temporalBranch) {
                console.log(this.request.temporalBranch, " temporal branch");

                branchId = this.request.temporalBranch;
                console.log(this.request);
              } else {
                console.log("no venia el temporal branch");
              }
            } else {
              branchId = localStorage.getItem("userBranchId");
            }

            console.log(this.selectedRequest.requestProducts);

            this.selectedRequest.requestProducts.forEach((product, index) => {
              quotedProduct["name"] = product.name;
              quotedProduct["createdAt"] = product.createdAt;
              quotedProduct["requestId"] = product.requestId;
              quotedProduct["quantity"] = product.quantity;
              quotedProduct["categoryId"] = product.categoryId;
              quotedProduct["specificationId"] = product.specificationId;
              quotedProduct["subCategoryId"] = product.subCategoryId;
              quotedProduct["companyId"] = product.companyId;
              quotedProduct["userId"] = product.userId;
              quotedProduct["requestProductId"] = product.requestProductId;
              quotedProduct["comments"] = product.comments;
              if (isNaN(product.amount)) {
                console.log(" is not a number ");
                var newValue = parseFloat(product.amount.replace(/,/g, ""));
                console.log(newValue);
                quotedProduct["amount"] = Number(newValue);
                this.selectedRequest.requestProducts[index].amount =
                  Number(newValue);
                //Number(product.amount);

                try {
                  this.formattedAmount = this.currencyPipe.transform(
                    newValue,
                    "",
                    true,
                    "1.2-2"
                  );

                  // product.amount= this.formattedAmount;
                } catch (e) {
                  console.log(e);
                }
              } else {
                quotedProduct["amount"] = Number(product.amount);
                // quotedProduct['amount'] = parseFloat(product.amount).toFixed(2)

                console.log(product);

                console.log(product.amount);
                console.log(Number(product.amount));
                console.log(product.quantity);
              }

              console.log(quotedProduct);

              quotedProducts.push(quotedProduct);
            });
            this.selectedRequest.requestProducts.forEach((product, index) => {
              console.log(this.selectedRequest.requestProducts[index].comments);
              console.log(this.selectedRequest.requestProducts[index].brand);

              this.selectedRequest.requestProducts[index].comments =
                this.selectedRequest.requestProducts[index].brand;
              console.log(this.selectedRequest.requestProducts[index].comments);
            });
            let userId = localStorage.getItem("$LoopBack$currentUserId");
            var files = {
              securityUrl: this.securityUrl,
              suppliesUrl: this.suppliesUrl,
              tecnicsUrl: this.tecnicsUrl,
              valueUrl: this.valueUrl,
              securityName: this.securityName,
              suppliesName: this.suppliesName,
              tecnicsName: this.tecnicsName,
              valueName: this.valueName,
            };
            console.log("Aaaaaaaaaaaa");
            console.log(files);
            let quotation = {
              paymentOptions: this.paymentOptions,
              branchId: branchId,
              requestId: this.request.id,
              sellerId: userId,
              estimatedDeliveryDate: this.estimatedDeliveryDate,
              deliveryDetails: this.deliveryDetails,
              companyId: companyId,
              quotedProducts: this.selectedRequest.requestProducts,
              currency: this.currency,
              canSend: this.canSend,
              requiredFilesUrl: files,
            };

            this.previewQuotation = quotation;
            let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
            let params = {
              access_token: tokenId,
            };
            console.log(quotation);
            console.log(this.checkIfQuotedBranch);

            this.checkIfQuotedBranch(this.selectedRequest.id, branchId).then(
              (accepted) => {
                if (accepted) {
                  this.spinner.hide();
                  swal({
                    title: "Alerta!",
                    text: "Esta cotizacion ya fue enviada previamente por la empresa.",
                    type: "info",
                    confirmButtonClass: "btn ",
                    confirmButtonColor: "#000",
                  });

                  $("#cotizacionPdf").modal("hide");
                  $(".modal-backdrop").remove();
                  $("body").removeClass("modal-open");
                  this._router.navigate(["/ventasenviadas"]);
                } else {
                  this.recQuotation.emit(quotation);
                }
              }
            );
          } else {
            swal({
              title: "Error!",
              text: "Por favor selecciona una fecha estimada de entrega.",
              type: "error",
              confirmButtonClass: "btn ",

              confirmButtonColor: "#000",
            });
          }
        } else {
          if (payO > 100) {
            swal({
              title: "Error!",
              text: "Las condiciones de pago superan el 100%.",
              type: "error",
              confirmButtonClass: "btn ",

              confirmButtonColor: "#000",
            });
          } else if (payO < 100) {
            swal({
              title: "Error!",
              text: "Las condiciones de pago  no cumplen el 100%.",
              type: "error",
              confirmButtonClass: "btn ",

              confirmButtonColor: "#000",
            });
          }
        }
      } else {
        //ES LICITACION
        // $('#cotizacionPdf').modal('show')
        //this.spinner.show();
        this.request.shipmentDetails = this.deliveryDetails;
        this.request = this.selectedRequest;
        console.log(this.request);
        let quotedProducts = [];
        let quotedProduct = {};
        let today = new Date();
        let companyId = localStorage.getItem("$LoopBack$currentCompanyId");
        let branchId = "";
        let userRole = localStorage.getItem("$LoopBack$currentUserRole");
        if (userRole === "Director" || userRole === "Gerente") {
          if (this.request.temporalBranch) {
            console.log(this.request.temporalBranch, " temporal branch");

            branchId = this.request.temporalBranch;
            console.log(this.request);
          } else {
            console.log("no venia el temporal branch");
          }
        } else {
          branchId = localStorage.getItem("userBranchId");
        }

        console.log(this.selectedRequest.requestProducts);

        this.selectedRequest.requestProducts.forEach((product, index) => {
          quotedProduct["name"] = product.name;
          quotedProduct["createdAt"] = product.createdAt;
          quotedProduct["requestId"] = product.requestId;
          quotedProduct["quantity"] = product.quantity;
          quotedProduct["categoryId"] = product.categoryId;
          quotedProduct["specificationId"] = product.specificationId;
          quotedProduct["subCategoryId"] = product.subCategoryId;
          quotedProduct["companyId"] = product.companyId;
          quotedProduct["userId"] = product.userId;
          quotedProduct["requestProductId"] = product.requestProductId;
          quotedProduct["comments"] = product.comments;
          if (isNaN(product.amount)) {
            console.log(" is not a number ");
            var newValue = parseFloat(product.amount.replace(/,/g, ""));
            console.log(newValue);
            quotedProduct["amount"] = Number(newValue);
            this.selectedRequest.requestProducts[index].amount =
              Number(newValue);
            //Number(product.amount);

            try {
              this.formattedAmount = this.currencyPipe.transform(
                newValue,
                "",
                true,
                "1.2-2"
              );

              // product.amount= this.formattedAmount;
            } catch (e) {
              console.log(e);
            }
          } else {
            quotedProduct["amount"] = Number(product.amount);

            console.log(product);

            console.log(product.amount);
            console.log(Number(product.amount));
            console.log(product.quantity);
          }


        });
        this.selectedRequest.requestProducts.forEach((product, index) => {
         
          this.selectedRequest.requestProducts[index].comments =
            this.selectedRequest.requestProducts[index].brand;
        });
        let userId = localStorage.getItem("$LoopBack$currentUserId");
        var files = {
          securityUrl: this.securityUrl,
          suppliesUrl: this.suppliesUrl,
          tecnicsUrl: this.tecnicsUrl,
          valueUrl: this.valueUrl,
          securityName: this.securityName,
          suppliesName: this.suppliesName,
          tecnicsName: this.tecnicsName,
          valueName: this.valueName,
        };
        let quotation = {
          paymentOptions: this.paymentOptions,
          branchId: branchId,
          requestId: this.request.id,
          sellerId: userId,
          estimatedDeliveryDate: this.estimatedDeliveryDate,
          deliveryDetails: this.deliveryDetails,
          companyId: companyId,
          quotedProducts: this.selectedRequest.requestProducts,
          currency: this.currency,
          canSend: this.canSend,
          requiredFilesUrl: files,
        };

        this.previewQuotation = quotation;
        let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
        let params = {
          access_token: tokenId,
        };
        console.log(quotation);
        console.log(this.checkIfQuotedBranch);

        this.checkIfQuotedBranch(this.selectedRequest.id, branchId).then(
          (accepted) => {
            if (accepted) {
              this.spinner.hide();
              swal({
                title: "Alerta!",
                text: "Esta cotizacion ya fue enviada previamente por la empresa.",
                type: "info",
                confirmButtonClass: "btn ",
                confirmButtonColor: "#000",
              });

              $("#cotizacionPdf").modal("hide");
              $(".modal-backdrop").remove();
              $("body").removeClass("modal-open");
              this._router.navigate(["/ventasenviadas"]);
            } else {
              //this.recQuotation.emit(quotation);
              this.saveContestQuotation(quotation);
            }
          }
        );
      }
    }
  }
  editQuotation() {
    console.log(this.selectedRequest.quotations[0]);
   // this.paymentOptions = this.selectedRequest.quotations[0].paymentOptions;
   this.selectedRequest.quotations[0].paymentOptions.credit = this.selectedRequest.quotations[0].paymentOptions.credit
      ? this.selectedRequest.quotations[0].paymentOptions.credit
      : 0;
      this.selectedRequest.quotations[0].paymentOptions.anticipated = this.selectedRequest.quotations[0].paymentOptions.anticipated
      ? this.selectedRequest.quotations[0].paymentOptions.anticipated
      : 0;
      this.selectedRequest.quotations[0].paymentOptions.uponDelivery = this.selectedRequest.quotations[0].paymentOptions.uponDelivery
      ? this.selectedRequest.quotations[0].paymentOptions.uponDelivery
      : 0;
      this.selectedRequest.quotations[0].paymentOptions.days = this.selectedRequest.quotations[0].paymentOptions.days
      ? this.selectedRequest.quotations[0].paymentOptions.days
      : 0;

    let payO =
    this.selectedRequest.quotations[0].paymentOptions.credit +
    this.selectedRequest.quotations[0].paymentOptions.anticipated +
    this.selectedRequest.quotations[0].paymentOptions.uponDelivery;
    let productsWithoutPrice = 0;
    this.selectedRequest.quotations[0].quotedProducts.forEach((product) => {
      if (!product.amount) {
        productsWithoutPrice++;
      }
    });
    if (productsWithoutPrice > 0) {
      swal({
        title: "¿Alerta ?",
        text: "Hay productos sin precio asignado en la cotizacion, deseas continuar ?",
        type: "info",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, continuar!",
        confirmButtonClass: "btn ",
        confirmButtonColor: "#000",
        cancelButtonClass: "btn btn-danger",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          // result.dismiss can be 'cancel', 'overlay',
          if (payO === 100) {
            if (this.selectedRequest.contestLabel === "request") {
              if (this.estimatedDeliveryDate) {
                $("#cotizacionPdf").modal("show");

                //this.spinner.show();
                this.request.shipmentDetails = this.deliveryDetails;
                this.request = this.selectedRequest;
                console.log(this.request);
                let quotedProducts = [];
                let quotedProduct = {};
                let today = new Date();
                let companyId = localStorage.getItem(
                  "$LoopBack$currentCompanyId"
                );

                let branchId = "";
                let userRole = localStorage.getItem(
                  "$LoopBack$currentUserRole"
                );
                if (userRole === "Director" || userRole === "Gerente") {
                  if (this.request.temporalBranch) {
                    console.log(
                      this.request.temporalBranch,
                      " temporal branch"
                    );

                    branchId = this.request.temporalBranch;
                    console.log(this.request);
                  } else {
                    console.log("no venia el temporal branch");
                  }
                } else {
                  branchId = localStorage.getItem("userBranchId");
                }

                console.log(this.selectedRequest.requestProducts);

                this.selectedRequest.requestProducts.forEach(
                  (product, index) => {
                    quotedProduct["name"] = product.name;
                    quotedProduct["createdAt"] = product.createdAt;
                    quotedProduct["requestId"] = product.requestId;
                    quotedProduct["quantity"] = product.quantity;
                    quotedProduct["categoryId"] = product.categoryId;
                    quotedProduct["specificationId"] = product.specificationId;
                    quotedProduct["subCategoryId"] = product.subCategoryId;
                    quotedProduct["companyId"] = product.companyId;
                    quotedProduct["userId"] = product.userId;
                    quotedProduct["requestProductId"] =
                      product.requestProductId;
                    quotedProduct["comments"] = product.brand;
                    quotedProduct["amount"] = Number(product.amount);
                    // quotedProduct['amount'] = parseFloat(product.amount).toFixed(2)

                    quotedProducts.push(quotedProduct);
                    console.log(product);

                    console.log(product.amount);
                    console.log(Number(product.amount));
                    console.log(product.quantity);
                  }
                );

                let userId = localStorage.getItem("$LoopBack$currentUserId");
                this.selectedRequest.requestProducts.forEach(
                  (product, index) => {
                    console.log(
                      this.selectedRequest.requestProducts[index].comments
                    );
                    console.log(
                      this.selectedRequest.requestProducts[index].brand
                    );

                    this.selectedRequest.requestProducts[index].comments =
                      this.selectedRequest.requestProducts[index].brand;
                    console.log(
                      this.selectedRequest.requestProducts[index].comments
                    );
                  }
                );
                var files = {
                  securityUrl: this.securityUrl,
                  suppliesUrl: this.suppliesUrl,
                  tecnicsUrl: this.tecnicsUrl,
                  valueUrl: this.valueUrl,
                  securityName: this.securityName,
                  suppliesName: this.suppliesName,
                  tecnicsName: this.tecnicsName,
                  valueName: this.valueName,
                };
                let quotation = {
                  paymentOptions: this.paymentOptions,
                  branchId: branchId,
                  requestId: this.request.id,
                  sellerId: userId,
                  estimatedDeliveryDate: this.estimatedDeliveryDate,
                  deliveryDetails: this.deliveryDetails,
                  companyId: companyId,
                  quotedProducts: this.selectedRequest.requestProducts,
                  currency: this.currency,
                  canSend: this.canSend,
                  requiredFilesUrl: files,
                };

                this.previewQuotation = quotation;
                let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
                let params = {
                  access_token: tokenId,
                };
                console.log(quotation);
                this.checkIfQuotedBranch(
                  this.selectedRequest.id,
                  branchId
                ).then((accepted) => {
                  if (accepted) {
                    this.spinner.hide();
                    swal({
                      title: "Alerta!",
                      text: "Esta cotizacion ya fue enviada previamente por la empresa.",
                      type: "info",
                      confirmButtonClass: "btn ",
                      confirmButtonColor: "#000",
                    });

                    $("#cotizacionPdf").modal("hide");
                    $(".modal-backdrop").remove();
                    $("body").removeClass("modal-open");
                    this._router.navigate(["/ventasenviadas"]);
                  } else {
                    this.recQuotation.emit(quotation);
                  }
                });
              } else {
                swal({
                  title: "Error!",
                  text: "Por favor selecciona una fecha estimada de entrega.",
                  type: "error",
                  confirmButtonClass: "btn ",

                  confirmButtonColor: "#000",
                });
              }
            } else {
              //ES LICITACION
              // $('#cotizacionPdf').modal('show')
              //this.spinner.show();
              this.request.shipmentDetails = this.deliveryDetails;
              this.request = this.selectedRequest;
              console.log(this.request);
              let quotedProducts = [];
              let quotedProduct = {};
              let today = new Date();
              let companyId = localStorage.getItem(
                "$LoopBack$currentCompanyId"
              );
              let branchId = "";
              let userRole = localStorage.getItem("$LoopBack$currentUserRole");
              if (userRole === "Director" || userRole === "Gerente") {
                if (this.request.temporalBranch) {
                  console.log(this.request.temporalBranch, " temporal branch");

                  branchId = this.request.temporalBranch;
                  console.log(this.request);
                } else {
                  console.log("no venia el temporal branch");
                }
              } else {
                branchId = localStorage.getItem("userBranchId");
              }

              console.log(this.selectedRequest.requestProducts);

              this.selectedRequest.requestProducts.forEach((product, index) => {
                quotedProduct["name"] = product.name;
                quotedProduct["createdAt"] = product.createdAt;
                quotedProduct["requestId"] = product.requestId;
                quotedProduct["quantity"] = product.quantity;
                quotedProduct["categoryId"] = product.categoryId;
                quotedProduct["specificationId"] = product.specificationId;
                quotedProduct["subCategoryId"] = product.subCategoryId;
                quotedProduct["companyId"] = product.companyId;
                quotedProduct["userId"] = product.userId;
                quotedProduct["requestProductId"] = product.requestProductId;
                quotedProduct["comments"] = product.comments;
                if (isNaN(product.amount)) {
                  console.log(" is not a number ");
                  var newValue = parseFloat(product.amount.replace(/,/g, ""));
                  console.log(newValue);
                  quotedProduct["amount"] = Number(newValue);
                  this.selectedRequest.requestProducts[index].amount =
                    Number(newValue);
                  //Number(product.amount);

                  try {
                    this.formattedAmount = this.currencyPipe.transform(
                      newValue,
                      "",
                      true,
                      "1.2-2"
                    );

                    // product.amount= this.formattedAmount;
                  } catch (e) {
                    console.log(e);
                  }
                } else {
                  quotedProduct["amount"] = Number(product.amount);
                  // quotedProduct['amount'] = parseFloat(product.amount).toFixed(2)

                  console.log(product);

                  console.log(product.amount);
                  console.log(Number(product.amount));
                  console.log(product.quantity);
                }

                console.log(quotedProduct);

                quotedProducts.push(quotedProduct);
              });
              this.selectedRequest.requestProducts.forEach((product, index) => {
                console.log(
                  this.selectedRequest.requestProducts[index].comments
                );
                console.log(this.selectedRequest.requestProducts[index].brand);

                this.selectedRequest.requestProducts[index].comments =
                  this.selectedRequest.requestProducts[index].brand;
                console.log(
                  this.selectedRequest.requestProducts[index].comments
                );
              });
              let userId = localStorage.getItem("$LoopBack$currentUserId");
              var files = {
                securityUrl: this.securityUrl,
                suppliesUrl: this.suppliesUrl,
                tecnicsUrl: this.tecnicsUrl,
                valueUrl: this.valueUrl,
                securityName: this.securityName,
                suppliesName: this.suppliesName,
                tecnicsName: this.tecnicsName,
                valueName: this.valueName,
              };
              let quotation = {
                paymentOptions: this.paymentOptions,
                branchId: branchId,
                requestId: this.request.id,
                sellerId: userId,
                estimatedDeliveryDate: this.estimatedDeliveryDate,
                deliveryDetails: this.deliveryDetails,
                companyId: companyId,
                quotedProducts: this.selectedRequest.requestProducts,
                currency: this.currency,
                canSend: this.canSend,
                requiredFilesUrl: files,
              };

              this.previewQuotation = quotation;
              let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
              let params = {
                access_token: tokenId,
              };
              console.log(quotation);
              console.log(this.checkIfQuotedBranch);

              this.checkIfQuotedBranch(this.selectedRequest.id, branchId).then(
                (accepted) => {
                  if (accepted) {
                    this.spinner.hide();
                    swal({
                      title: "Alerta!",
                      text: "Esta cotizacion ya fue enviada previamente por la empresa.",
                      type: "info",
                      confirmButtonClass: "btn ",
                      confirmButtonColor: "#000",
                    });

                    $("#cotizacionPdf").modal("hide");
                    $(".modal-backdrop").remove();
                    $("body").removeClass("modal-open");
                    this._router.navigate(["/ventasenviadas"]);
                  } else {
                    //this.recQuotation.emit(quotation);
                    this.saveContestQuotation(quotation);
                  }
                }
              );
            }
          } else {
            if (payO > 100) {
              swal({
                title: "Error!",
                text: "Las condiciones de pago superan el 100%.",
                type: "error",
                confirmButtonClass: "btn ",

                confirmButtonColor: "#000",
              });
            } else if (payO < 100) {
              swal({
                title: "Error!",
                text: "Las condiciones de pago  no cumplen el 100%.",
                type: "error",
                confirmButtonClass: "btn ",

                confirmButtonColor: "#000",
              });
            }
          }
        } else if (result.dismiss) {
        }
      });
    } else {
      let quotDate = new Date(this.selectedRequest.limitQuotationDate);
      let today = new Date();
      if (quotDate.getTime() > today.getTime()) {
        this.patchQuotation(
          this.selectedRequest.quotations[0].id,
          this.selectedRequest.quotations[0].quotedProducts,
        );
      } else {
        swal({
          title: "Fecha limite superada!",
          text: "Ya no es posible editar la cotización",
          type: "warning",
          confirmButtonClass: "btn ",

          confirmButtonColor: "#000",
        });
      }
    }
  }
  patchQuotation(id: any, products: any) {
    this.spinner.show();
    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let params = {
      access_token: tokenId,
      
    };
    let obj = this.selectedRequest.quotations[0];
    obj.quotedProducts =products;
    console.log(obj)
    let patch = {
      quotedProducts: products,
    };
 
     this._apiService.updateDataObject("Quotations", id, obj, params).subscribe(
      (result) => {
        console.log(result);
        swal({
          title: "Guardado!",
          text: "Cambios guardados correctamente.",
          type: "success",
          confirmButtonClass: "btn ",

          confirmButtonColor: "#000",
        });
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        if (error != null) {
          console.log(error);
        }
      }
    ); 
  }
  saveContestQuotation(quotationContest) {
    this.spinner.show();

    let quotedProducts = [];
    let quotedProduct = {};

    let branchId = "";
    let userRole = localStorage.getItem("$LoopBack$currentUserRole");
    if (userRole === "Director" || userRole === "Gerente") {
      if (this.selectedRequest.temporalBranch) {
        branchId = this.selectedRequest.temporalBranch;
      } else {
        console.log("no venia el temporal branch");
      }
    } else {
      branchId = localStorage.getItem("userBranchId");
    }
    if (this.checkIfQuoted(this.selectedRequest.id)) {
    } else {
      if (this.selectedRequest.quotedBranches) {
        console.log("YA HAY QUOTED");

        this.selectedRequest.quotedBranches.push(branchId);
        this.editRequest(this.selectedRequest.id, this.selectedRequest, true);
      } else {
        console.log("NO HAY QUOTED");

        this.selectedRequest["quotedBranches"] = [branchId];
        console.log(this.selectedRequest.quotedBranches);
        this.editRequest(this.selectedRequest.id, this.selectedRequest, true);
      }
      console.log(this.selectedRequest);
    }

    this.selectedRequest.requestProducts.forEach((product, index) => {
      quotedProduct["name"] = product.name;
      quotedProduct["createdAt"] = product.createdAt;
      quotedProduct["requestId"] = product.requestId;
      quotedProduct["quantity"] = product.quantity;
      quotedProduct["categoryId"] = product.categoryId;
      quotedProduct["specificationId"] = product.specificationId;
      quotedProduct["subCategoryId"] = product.subCategoryId;
      quotedProduct["companyId"] = product.companyId;
      quotedProduct["userId"] = product.userId;

      quotedProduct["requestProductId"] = product.requestProductId;
      quotedProduct["comments"] = product.comments;
      quotedProduct["amount"] = Number(product.amount);
      // quotedProduct['amount'] = parseFloat(product.amount).toFixed(2)

      quotedProducts.push(quotedProduct);
      console.log(product);

      console.log(product.amount);
      console.log(Number(product.amount));
      console.log(product.quantity);
    });

    let quotation = this.previewQuotation;
    quotation.branchId = this.selectedRequest.temporalBranch;
    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let params = {
      access_token: tokenId,
    };
    console.log(quotation);

    this._apiService.addDataObject("Quotations", quotation).subscribe(
      (result) => {
        console.log("inserto quotation");
        setTimeout(() => {
          this.spinner.hide();
          swal({
            title: "Guardado!",
            text: "Cambios guardados correctamente.",
            type: "success",
            confirmButtonClass: "btn ",

            confirmButtonColor: "#000",
          });
          $("#cotizacionPdf").modal("hide");
          this._router.navigate(["/ventasenviadas"]);
        }, 500);
      },
      (error) => {
        if (error != null) {
          // console.log(error);
          setTimeout(() => {
            this.spinner.hide();
            swal("Error!", "Error al Guardar.", "error");
            $("#cotizacionPdf").modal("hide");
          }, 250);
        }
      }
    );
  }
  saveRejectedReason() {
    if (this.selectedReason === "") {
      swal("Informacion!", "Selecciona una razon.", "info");
    } else {
      // $('#rejectedReasonModal').modal('hide')

      console.log(this.selectedRequest);
      let branchId;
      let userRole = localStorage.getItem("$LoopBack$currentUserRole");
      let companyId = localStorage.getItem("$LoopBack$currentCompanyId");
      if (userRole === "Director" || userRole === "Gerente") {
        if (this.selectedRequest.temporalBranch) {
          branchId = this.selectedRequest.temporalBranch;
        } else {
          console.log("no venia el temporal branch");
        }
      } else {
        branchId = localStorage.getItem("userBranchId");
      }

      let reason = {
        cause: this.selectedReason,
        requestId: this.selectedRequest.id,
        buyerCompanyId: companyId,
        sellerCompanyId: this.selectedRequest.companyId,
        sellerBranchId: this.selectedRequest.branchId,
        buyerBranchId: branchId,
      };
      console.log(reason);

      let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
      let params = {
        access_token: tokenId,
      };
      this._apiService.addDataObject("RejectedRequests", reason).subscribe(
        (result) => {
          console.log("Insert rejected reason");
          $("#rejectedReasonModal").modal("hide");
          $(".modal-backdrop").remove();
          $("body").removeClass("modal-open");

          this._router
            .navigateByUrl("/compras", { skipLocationChange: true })
            .then(() => this._router.navigate(["/inicio/"]));

          setTimeout(() => {
            this.spinner.hide();

            $("#rejectedReasonModal").modal("hide");

            this.selectedReason === "";
          }, 500);
        },
        (error) => {
          if (error != null) {
            console.log(error);

            setTimeout(() => {
              this.spinner.hide();
              swal("Error!", "Error al Guardar.", "error");
              this.selectedReason = "";
              $("#rejectedReasonModal").modal("hide");
            }, 250);
          }
        }
      );
    }
  }
  public checkSecurity(event) {
    event.checked ? (this.securityCheck = true) : (this.securityCheck = false);
  }
  public checkSupplies(event) {
    event.checked ? (this.suppliesCheck = true) : (this.suppliesCheck = false);
  }
  public checkTecnics(event) {
    event.checked ? (this.tecnicsCheck = true) : (this.tecnicsCheck = false);
  }
  public checkEngineering(event) {
    event.checked ? (this.valueCheck = true) : (this.valueCheck = false);
  }
  public openFileCompany(url) {
    window.open(
      url,
      "_blank",
      "toolbar=no,top=300,left=400,width=800,height=600"
    );
  }
  ngOnInit() {
    this.getCurrencies("currencies");
    $("#modalProductImage").on("hidden.bs.modal", () => {
      this.selectedProductImageURL = "";
    });

    console.log(this.request);
    console.log(this.request.limitQuotationDate);
    console.log(this.request.pickupRadius);
    this.request.requestProducts.forEach((element, index) => {
      if (this.request.contestLabel === "contest") {
        console.log("CONTEST");
        if (element.code) {
          this.codesToReview.push(element.code.toString().toLowerCase());
          this.request.requestProducts[index]["lowercaseCode"] = element.code
            .toString()
            .toLowerCase();
        }
      } else {
        console.log("REQUEST");

        if (element.id) {
          this.codesToReview.push(element.id.toString().toLowerCase());
          this.request.requestProducts[index]["lowercaseCode"] = element.id
            .toString()
            .toLowerCase();
        }
      }
    });
    console.log(this.request.infoFiles);
    let files = [];
    if (this.request.infoFiles) {
      if (this.request.infoFiles.files.length) {
        console.log("asdasdasd");
        files = Array.from(new Set(this.request.infoFiles.files));
        console.log(files);
      } else {
        console.log("a123123123sdasdasd");
      }
    }
    this.request["infoFiles"] = {
      files: files,
    };
    console.log(this.codesToReview);
    if (!this.request.contestLabel) {
      this.request["contestLabel"] = "request";
    }

    this.localeService.use("es");

    if (this.onlyViewForSales) {
    } else {
      this.onlyViewForSales = false;
    }
    this.selectedRequest = this.request;
    console.log(this.request)
    console.log(this.paranueva)
    if(this.selectedRequest.quotations){
      this.paranueva=false
    }
    if (this.selectedRequest.geopoint) {
      this.initMap(this.selectedRequest.geopoint);
    }
  }
  currencyChanged(data) {
    console.log(data);
    data === "MXN" ? (this.isMXN = true) : (this.isMXN = false);
    console.log(this.isMXN);
  }
  getCurrencies(type) {
    let where = {
      active: true,
    };

    const getDataQuery = this.queryFactory.generateGetQuery(
      type,
      where,
      100,
      0,
      null,
      []
    );

    this._apiService.getDataObjects(getDataQuery).subscribe(
      (result: any) => {
        console.log(result);
        this.USDCURRENCY = Number(result[0].mxn);
      },
      (error) => {
        if (error != null) {
          console.log(error);
        }
      }
    );
  }
  checkIfAcceptedBranch(requestId, branchId) {
    console.log(branchId);

    let promise = new Promise((resolve, reject) => {
      // Do some async stuff
      let tokenId = localStorage.getItem("$LoopBack$accessTokenId");

      let params = {
        access_token: tokenId,
      };
      this._apiService.getDataById("Requests", requestId, params).subscribe(
        (result: any) => {
          console.log(result);
          this.selectedRequest.acceptedBranches = result.acceptedBranches;
          console.log(result.acceptedBranches);

          if (result.acceptedBranches) {
            var contains = result.acceptedBranches.indexOf(branchId) > -1;
            if (contains) {
              resolve(true);
            } else {
              resolve(false);
            }
          } else {
            resolve(false);
          }
        },
        (error) => {
          if (error != null) {
            console.log(error);
          }
        }
      );
    });
    return promise;
  }

  checkIfPermissionSales(type, id) {
    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let userId = localStorage.getItem("$LoopBack$currentUserId");

    let filter = {
      access_token: tokenId,
    };
    this._apiService.getDataById("AppUsers", userId, filter).subscribe(
      (result: any) => {
        console.log(result);

        if (result.canSell) {
          return true;
        } else {
          return false;
        }
      },
      (error) => {
        // this.errorMessage = <any>error;
        // if (this.errorMessage != null) {
        console.log(error);
        //
        // }
      }
    );
  }

  checkIfAccepted(companyId, requestId) {
    let filter = {
      filter: {
        where: {
          companyId: companyId,
        },
      },
    };
    //Requests/559/quotations/
    this._apiService
      .getDataObjectsFilter(
        "Requests/" + requestId + "/quotations/count",
        filter
      )
      .subscribe(
        (result: any) => {
          console.log("Verified if Count");

          console.log(result);
          console.log(result);
          if (result.count === 0) {
            return false;
          } else {
            return true;
          }
        },
        (error) => {
          if (error != null) {
            console.log(error);
            return false;
          }
        }
      );
  }
  checkIfQuotedBranch(requestId, branchId) {
    let promise = new Promise((resolve, reject) => {
      // Do some async stuff
      let tokenId = localStorage.getItem("$LoopBack$accessTokenId");

      let params = {
        access_token: tokenId,
      };
      this._apiService.getDataById("Requests", requestId, params).subscribe(
        (result: any) => {
          console.log(result);
          this.selectedRequest.quotedBranches = result.quotedBranches;
          if (result.quotedBranches) {
            var contains = result.quotedBranches.indexOf(branchId) > -1;
            if (contains) {
              resolve(true);
            } else {
              resolve(false);
            }
          } else {
            resolve(false);
          }
        },
        (error) => {
          if (error != null) {
            console.log(error);
          }
        }
      );
    });
    return promise;
  }
  checkIfQuoted(requestId): any {
    let companyId = localStorage.getItem("$LoopBack$currentCompanyId");

    let filter = {
      filter: {
        where: {
          companyId: companyId,
        },
      },
    };
    //Requests/559/quotations/
    this._apiService
      .getDataObjectsFilter(
        "Requests/" + requestId + "/quotations/count",
        filter
      )
      .subscribe(
        (result: any) => {
          console.log("Verified if Count");

          console.log(result);
          if (result.count === 0) {
            return false;
          } else {
            return true;
          }
        },
        (error) => {
          if (error != null) {
            console.log(error);
            return false;
          }
        }
      );
  }

  ngAfterViewInit() {}
}
