<div class="container_bg" style="background-image:url(assets/images/2023/bg.png);">
    <div class="position-page only-movile"> COTIZACIÓN REGULAR </div>
    <div class="container-scroll">
        <div class="container regresaborrador regresaprobada">
            <div class="container-bg-blur">
                <div class="row zero">
                    <!-- PADO 1 -->
                    <div class="row style-firsth" *ngIf="paso1">
                        <div class="container-element padding-paso1">

                            <div class="zero">

                                <div class="row">
                                    <div class="container">
                                        <!--PASO 1 COTIZACIÓN-->
                                        <div class=" cont_info zero">
                                            <ul *ngIf="contest==='request'"
                                                class="nav nav-pills mb-2 zero background-gray " id="pills-tab"
                                                role="tablist">
                                                <li (click)="isExpress(false)" class="nav-item col-12 col-md-6 zero">
                                                    <a class="btn nav-link active collapse_title pad6"
                                                        id="pills-home-tab" data-toggle="pill" role="tab"
                                                        aria-controls="pills-home" aria-selected="true"> COTIZACIÓN
                                                        REGULAR
                                                    </a>
                                                </li>
                                                <li (click)="isExpress(true)" class="nav-item col-12 col-md-6 zero">
                                                    <a class="btn nav-link collapse_title pad6" id="pills-profile-tab"
                                                        data-toggle="pill" role="tab" aria-controls="pills-profile"
                                                        aria-selected="false"> COTIZACIÓN EXPRESS</a>
                                                </li>
                                            </ul>
                                            <ul *ngIf="contest==='contest'" class="nav nav-pills mb-2 zero  "
                                                id="pills-tab" role="tablist">

                                                <li class="nav-item col-12 col-md-12 zero">
                                                    <a class="btn nav-link collapse_title pad6" id="pills-profile-tab"
                                                        data-toggle="pill" role="tab" aria-controls="pills-profile"
                                                        aria-selected="false"> LICITACIÓN</a>
                                                </li>
                                            </ul>
                                            <div class="tab-content margin-14" id="pills-tabContent">
                                                <!---->
                                                <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                                                    aria-labelledby="pills-home-tab">


                                                    <div class="row zero background-white marg-pad-bloque" id="cotiza">
                                                        <p style=" font-size: 15px; font-weight: bold; ">
                                                            &nbsp;&nbsp;&nbsp;PASO 1/2</p>
                                                        <div class="col-12 zero mt-10">
                                                            <p style=" font-size: 15px; font-weight: bold; ">
                                                                &nbsp;&nbsp;&nbsp;Selecciona el tipo de concurso</p>
                                                            <div class="col-4 zero pd-10">
                                                                <select [(ngModel)]="contest" (change)="changeContest()"
                                                                    [ngModelOptions]="{standalone: true}" type="text"
                                                                    class="form-control" autocomplete="off" required>
                                                                    <option selected default value="request">Cotización
                                                                        de Materiales
                                                                    </option>
                                                                    <option value="contest">Licitación de Obra</option>
                                                                </select>
                                                            </div>

                                                        </div>
                                                        <div class="row zero"></div>
                                                        <div class="col-12 zero">
                                                            <div *ngIf="contest==='request'"
                                                                class="collapse_title center margin-bot-10">SOLICITAR
                                                                PRODUCTOS</div>
                                                        </div>
                                                        <div *ngIf="  contest==='contest'" class="col-12 zero">
                                                            <div class="collapse_title center margin-bot-10">LICITAR
                                                            </div>
                                                        </div>
                                                        <div *ngIf="contest==='request'" class="col-12 zero">
                                                            <form [formGroup]="cotizacionForm">


                                                                <div class="row">
                                                                    <div class="col-12 col-md-4">
                                                                        <div class="form-group">
                                                                            <input autocomplete="off"
                                                                                formControlName="name" type="text"
                                                                                id="name" class="form-control" required>
                                                                            <label class="form-control-placeholder"
                                                                                for="name">Nombre
                                                                                de la cotización</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-md-4">
                                                                        <div class="form-group">
                                                                            <select type="text"
                                                                                formControlName="project" id="project"
                                                                                class="form-control" autocomplete="off"
                                                                                required>
                                                                                <option value="" hidden></option>
                                                                                <option [ngValue]="project.id"
                                                                                    *ngFor="let project of projects; let i = index">
                                                                                    {{project.name}}
                                                                                </option>
                                                                            </select>
                                                                            <label class="form-control-placeholder"
                                                                                for="project">Centro
                                                                                de costos
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-md-4">
                                                                        <div class="form-group">
                                                                            <select type="text"
                                                                                formControlName="sectorSelect"
                                                                                id="sector" autocomplete="off"
                                                                                class="form-control" required>
                                                                                <option value="" hidden></option>
                                                                                <option [ngValue]="sector.id"
                                                                                    *ngFor="let sector of sectors; let i = index">
                                                                                    {{sector.name}}
                                                                                </option>
                                                                            </select>
                                                                            <label class="form-control-placeholder"
                                                                                for="sector">Ramo</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-md-4 margin-top1">
                                                                        <select formControlName="branch"
                                                                            *ngIf="userRole==='Director'||userRole==='Gerente'"
                                                                            autocomplete="off" class="form-control"
                                                                            id="suc" required>
                                                                            <option value="" hidden disabled></option>
                                                                            <option [ngValue]="branch.id"
                                                                                *ngFor="let branch of branches ; let idx = index">
                                                                                {{branch.name}}
                                                                            </option>

                                                                        </select>
                                                                        <label
                                                                            *ngIf="userRole==='Director'||userRole==='Gerente'"
                                                                            class="form-control-placeholder"
                                                                            for="suc">Sucursal</label>

                                                                    </div>
                                                                    <div class="col-12 col-md-4 margin-top1">
                                                                        <div class="form-group">
                                                                            <input type="text" autocomplete="off"
                                                                                formControlName="userRequested"
                                                                                class="form-control" id="solicita"
                                                                                required>
                                                                            <label class="form-control-placeholder"
                                                                                for="solicita">Solicita</label>
                                                                        </div>


                                                                    </div>
                                                                    <div class="col-12 col-md-4 margin-top1">
                                                                        <div class="form-group">
                                                                            <input type="text" autocomplete="off"
                                                                                formControlName="userAuthorize"
                                                                                class="form-control" id="autoriza"
                                                                                required>
                                                                            <label class="form-control-placeholder"
                                                                                for="solicita">Autoriza</label>
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                        <div *ngIf="contest==='contest'" class="col-12 zero">
                                                            <form [formGroup]="licitacionForm">


                                                                <div class="row">
                                                                    <div class="col-12 col-md-4">
                                                                        <div class="form-group">
                                                                            <input autocomplete="off"
                                                                                formControlName="name" type="text"
                                                                                id="name" class="form-control" required>
                                                                            <label class="form-control-placeholder"
                                                                                for="name">Nombre
                                                                                de la licitación</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-md-4">
                                                                        <div class="form-group">
                                                                            <select type="text"
                                                                                formControlName="project" id="project"
                                                                                class="form-control" autocomplete="off"
                                                                                required>
                                                                                <option value="" hidden></option>
                                                                                <option [ngValue]="project.id"
                                                                                    *ngFor="let project of projects; let i = index">
                                                                                    {{project.name}}
                                                                                </option>
                                                                            </select>
                                                                            <label class="form-control-placeholder"
                                                                                for="project">Centro
                                                                                de costos
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-4 zero pd-10">
                                                                        <select [(ngModel)]="contestType"
                                                                            (change)="changeContestPublic()"
                                                                            [ngModelOptions]="{standalone: true}"
                                                                            type="text" class="form-control"
                                                                            autocomplete="off" required>
                                                                            <option selected default value="public">
                                                                                Red IXCCO
                                                                            </option>
                                                                            <option value="private">Privada</option>
                                                                        </select>
                                                                    </div>

                                                                </div>
                                                            </form>
                                                        </div>
                                                        <!-- EXCELCONTEST -->
                                                        <div class="col-12 zero"
                                                            *ngIf="!excelUploaded && contest==='contest' && !reDo">
                                                            <p class="textES"> Descargar plantilla de Licitación y
                                                                llenar
                                                                formulario
                                                            </p>
                                                            <div style="height:70px;"
                                                                class="row background-gray padding1">

                                                                <div class="col-7">

                                                                    <p class="textE">Se debera descargar y llenar la
                                                                        informacion requerida para configurar y mandar
                                                                        tu solicitud
                                                                    </p>
                                                                </div>
                                                                <div *ngIf="!excelUploaded" class="col-2"
                                                                    style="margin-right:20px;">
                                                                    <a href="../../../../../assets/downloads/Licitacion_Ixcco.xlsx"
                                                                        class="btn btn-outline-secondary  btn-personalized "
                                                                        target="_blank"
                                                                        download="Licitacion_Ixcco.xlsx">Descargar
                                                                        Plantilla</a>


                                                                </div>
                                                                <div *ngIf="!excelUploaded" class="col-2"
                                                                    style="margin-right:20px;">
                                                                    <div
                                                                        class="fileUpload btn btn-outline-secondary  btn-personalized">
                                                                        <span>Cargar Plantilla</span>
                                                                        <input type="file" class="upload"
                                                                            (change)="onFileChangeContest($event)"
                                                                            multiple="false" />

                                                                    </div>
                                                                </div>
                                                                <div *ngIf="excelUploaded" class="col-4">
                                                                    <form [formGroup]="subForm">

                                                                        <div class="form-group">


                                                                            <input #input required autocomplete=off
                                                                                [typeaheadItemTemplate]="itemTemplate"
                                                                                formControlName="searchSubText"
                                                                                [typeahead]="dataSourceSub"
                                                                                [typeaheadScrollable]="true"
                                                                                [typeaheadOptionsInScrollableView]="9"
                                                                                (typeaheadLoading)="changeTypeaheadLoadingSub($event)"
                                                                                (typeaheadOnSelect)="typeaheadOnSelectSub($event)"
                                                                                typeaheadOptionField="name"
                                                                                [typeaheadWaitMs]="300" type="text"
                                                                                id="subCategory" class="form-control">
                                                                            <ng-template
                                                                                style="height : 320px !important"
                                                                                class="drop-custom dropped"
                                                                                #itemTemplate let-model="item"
                                                                                let-index="index">
                                                                                <div
                                                                                    [style.width.px]="inputRef.nativeElement.clientWidth">
                                                                                    {{model.name}}
                                                                                </div>
                                                                            </ng-template>
                                                                            <label (click)="setFocusAutocomplete(i)"
                                                                                class="form-control-placeholder"
                                                                                for="product">Sub Categoria</label>
                                                                        </div>
                                                                    </form>

                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- EXCEL CONTEST -->
                                                        <!-- EXCELCONTEST REDO -->
                                                        <div class="col-12 zero"
                                                            *ngIf="!excelUploaded && contest==='contest' && reDo">
                                                            <p class="textES"> Descargar plantilla de Licitación para
                                                                agregar o cambiar informacion
                                                            </p>
                                                            <div style="height:70px;"
                                                                class="row background-gray padding1">

                                                                <div class="col-7">

                                                                    <p class="textE">Se debera descargar y llenar la
                                                                        informacion requerida para configurar y mandar
                                                                        tu solicitud
                                                                    </p>
                                                                </div>
                                                                <div *ngIf="!excelUploaded" class="col-2"
                                                                    style="margin-right:20px;">
                                                                    <a (click)="reDoTemplate()"
                                                                        class="btn btn-outline-secondary  btn-personalized">Descargar
                                                                        Relicitación</a>

                                                                </div>
                                                                <div *ngIf="!excelUploaded" class="col-2"
                                                                    style="margin-right:20px;">
                                                                    <div
                                                                        class="fileUpload btn btn-outline-secondary  btn-personalized">
                                                                        <span>Cargar Relicitación</span>
                                                                        <input type="file" class="upload"
                                                                            (change)="onFileChangeContestRedo($event)"
                                                                            multiple="false" />

                                                                    </div>
                                                                </div>
                                                                <div *ngIf="excelUploaded" class="col-4">
                                                                    <form [formGroup]="subForm">

                                                                        <div class="form-group">


                                                                            <input #input required autocomplete=off
                                                                                [typeaheadItemTemplate]="itemTemplate"
                                                                                formControlName="searchSubText"
                                                                                [typeahead]="dataSourceSub"
                                                                                [typeaheadScrollable]="true"
                                                                                [typeaheadOptionsInScrollableView]="9"
                                                                                (typeaheadLoading)="changeTypeaheadLoadingSub($event)"
                                                                                (typeaheadOnSelect)="typeaheadOnSelectSub($event)"
                                                                                typeaheadOptionField="name"
                                                                                [typeaheadWaitMs]="300" type="text"
                                                                                id="subCategory" class="form-control">
                                                                            <ng-template
                                                                                style="height : 320px !important"
                                                                                class="drop-custom dropped"
                                                                                #itemTemplate let-model="item"
                                                                                let-index="index">
                                                                                <div
                                                                                    [style.width.px]="inputRef.nativeElement.clientWidth">
                                                                                    {{model.name}}
                                                                                </div>
                                                                            </ng-template>
                                                                            <label (click)="setFocusAutocomplete(i)"
                                                                                class="form-control-placeholder"
                                                                                for="product">Sub Categoria</label>
                                                                        </div>
                                                                    </form>

                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- EXCEL CONTEST  REDO-->
                                                        <!-- EXCEL REQUEST -->
                                                        <div class="col-12 zero"
                                                            *ngIf="!excelUploaded && contest==='request' &&!reDo">
                                                            <p class="textES">Opcion 1: Descargar archivo y llenar
                                                                formulario
                                                            </p>
                                                            <div style="height:70px;"
                                                                class="row background-gray padding1">

                                                                <div class="col-7">

                                                                    <p class="textE">Si ya cuentas con tu cotización
                                                                        hecha en un
                                                                        archivo de EXCEL, por ejemplo, descarga la
                                                                        plantilla de cotizaciones IXCCO y copia y pega
                                                                        tu
                                                                        cotización en las casillas correspondientes.
                                                                        Posteriormente adjunta la plantilla, escribe el
                                                                        nombre de la solicitud y da click en Siguiente
                                                                        para que configures tu solicitud.
                                                                    </p>
                                                                </div>
                                                                <div *ngIf="!excelUploaded" class="col-2"
                                                                    style="margin-right:20px;">
                                                                    <a href="../../../../../assets/downloads/Plantilla_ixcco.xlsx"
                                                                        class="btn btn-outline-secondary  btn-personalized "
                                                                        target="_self"
                                                                        download="Plantilla-ixcco.xlsx">Descargar
                                                                        Plantilla</a>
                                                                    <!--  <a href="https://app.ixcco.com/downloads/Plantilla-Ixcco.xlsx"
                                                                        class="btn btn-outline-secondary  btn-personalized "
                                                                        target="_self"
                                                                        download="Plantilla-Ixcco.xlsx">Descargar
                                                                        Plantilla</a> -->

                                                                </div>
                                                                <div *ngIf="!excelUploaded" class="col-2"
                                                                    style="margin-right:20px;">
                                                                    <div
                                                                        class="fileUpload btn btn-outline-secondary  btn-personalized">
                                                                        <span>Cargar Plantilla</span>
                                                                        <input type="file" class="upload"
                                                                            (change)="onFileChange($event)"
                                                                            multiple="false" />

                                                                    </div>
                                                                </div>
                                                                <div *ngIf="excelUploaded" class="col-4">
                                                                    <form [formGroup]="subForm">

                                                                        <div class="form-group">


                                                                            <input #input required autocomplete=off
                                                                                [typeaheadItemTemplate]="itemTemplate"
                                                                                formControlName="searchSubText"
                                                                                [typeahead]="dataSourceSub"
                                                                                [typeaheadScrollable]="true"
                                                                                [typeaheadOptionsInScrollableView]="9"
                                                                                (typeaheadLoading)="changeTypeaheadLoadingSub($event)"
                                                                                (typeaheadOnSelect)="typeaheadOnSelectSub($event)"
                                                                                typeaheadOptionField="name"
                                                                                [typeaheadWaitMs]="300" type="text"
                                                                                id="subCategory" class="form-control">
                                                                            <ng-template
                                                                                style="height : 320px !important"
                                                                                class="drop-custom dropped"
                                                                                #itemTemplate let-model="item"
                                                                                let-index="index">
                                                                                <div
                                                                                    [style.width.px]="inputRef.nativeElement.clientWidth">
                                                                                    {{model.name}}
                                                                                </div>
                                                                            </ng-template>
                                                                            <label class="form-control-placeholder"
                                                                                for="product">Sub Categoria</label>
                                                                        </div>
                                                                    </form>

                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- END EXCEL -->
                                                        <!-- EXCEL REQUEST -->
                                                        <!-- excel request REDO -->
                                                        <div class="col-12 zero"
                                                            *ngIf="!excelUploaded && contest==='request' && reDo">
                                                            <p class="textES"> Descargar plantilla de Cotización para
                                                                agregar o cambiar informacion
                                                            </p>
                                                            <div style="height:70px;"
                                                                class="row background-gray padding1">

                                                                <div class="col-7">

                                                                    <p class="textE">Se debera descargar y llenar la
                                                                        informacion requerida para configurar y mandar
                                                                        tu solicitud
                                                                    </p>
                                                                </div>
                                                                <div *ngIf="!excelUploaded" class="col-2"
                                                                    style="margin-right:20px;"> 
                                                                    <a (click)="reDoTemplateRequest()"
                                                                        class="btn btn-outline-secondary  btn-personalized">Descargar
                                                                        Recotización</a>

                                                                </div>
                                                                <div *ngIf="!excelUploaded" class="col-2"
                                                                    style="margin-right:20px;">
                                                                    <div
                                                                        class="fileUpload btn btn-outline-secondary  btn-personalized">
                                                                        <span>Cargar Recotización</span>
                                                                        <input type="file" class="upload"
                                                                            (change)="onFileChangeRedo($event)"
                                                                            multiple="false" />

                                                                    </div>
                                                                </div>
                                                                <div *ngIf="excelUploaded" class="col-4">
                                                                    <form [formGroup]="subForm">

                                                                        <div class="form-group">


                                                                            <input #input required autocomplete=off
                                                                                [typeaheadItemTemplate]="itemTemplate"
                                                                                formControlName="searchSubText"
                                                                                [typeahead]="dataSourceSub"
                                                                                [typeaheadScrollable]="true"
                                                                                [typeaheadOptionsInScrollableView]="9"
                                                                                (typeaheadLoading)="changeTypeaheadLoadingSub($event)"
                                                                                (typeaheadOnSelect)="typeaheadOnSelectSub($event)"
                                                                                typeaheadOptionField="name"
                                                                                [typeaheadWaitMs]="300" type="text"
                                                                                id="subCategory" class="form-control">
                                                                            <ng-template
                                                                                style="height : 320px !important"
                                                                                class="drop-custom dropped"
                                                                                #itemTemplate let-model="item"
                                                                                let-index="index">
                                                                                <div
                                                                                    [style.width.px]="inputRef.nativeElement.clientWidth">
                                                                                    {{model.name}}
                                                                                </div>
                                                                            </ng-template>
                                                                            <label (click)="setFocusAutocomplete(i)"
                                                                                class="form-control-placeholder"
                                                                                for="product">Sub Categoria</label>
                                                                        </div>
                                                                    </form>

                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- EXCEL request  REDO-->
                                                        <div *ngIf="excelUploaded && contest==='request'"
                                                            class="col-12 zero">
                                                            <p class="textES">Selecciona tus subcategorias</p>

                                                            <div *ngFor="let sub of subCategoriesInExcel; let i = index"
                                                                class="col-12 zero">
                                                                <div style="height:70px;"
                                                                    class="row background-gray padding1">
                                                                    <div class="col-7">

                                                                        <p class="subcategoryExcel">
                                                                            {{sub.cat}}
                                                                        </p>
                                                                    </div>
                                                                    <div *ngIf="excelUploaded" class="col-4">
                                                                        <form>
                                                                            <div class="form-group">
                                                                                 <input required
                                                                                    [(ngModel)]="sub.autocomplete"
                                                                                    [ngModelOptions]="{standalone: true}"
                                                                                    [typeahead]="dataSourceSub"
                                                                                    [typeaheadScrollable]="true"
                                                                                    [typeaheadOptionsInScrollableView]="9"
                                                                                    (typeaheadLoading)="changeTypeaheadLoadingSub($event,i)"
                                                                                    (typeaheadOnSelect)="typeaheadOnSelectSubNew($event,i,sub.cat)"
                                                                                    typeaheadOptionField="name"
                                                                                    [typeaheadWaitMs]="0" type="text"
                                                                                    id="subCategory{{i}}"
                                                                                    class="form-control">

                                                                                <label id="label{{i}}"
                                                                                    (click)="setFocusAutocomplete(i)"
                                                                                    class="form-control-placeholder"
                                                                                    for="product">Sub Categoria</label> 
                                                                            </div>
                                                                        </form>

                                                                    </div>
                                                                    <div class="col-1">

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="excelUploaded && contest==='contest'"
                                                        class="col-12 zero">
                                                        <p class="textES">Selecciona tus subcategorias de constructora</p>

                                                        <div *ngFor="let sub of subCategoriesInExcel; let i = index"
                                                            class="col-12 zero">


                                                            <div style="height:70px;"
                                                                class="row background-gray padding1">

                                                                <div class="col-7">

                                                                    <p class="subcategoryExcel">
                                                                        {{sub.cat}}
                                                                    </p>
                                                                </div>


                                                                <div *ngIf="excelUploaded" class="col-4">

                                                                    <form>

                                                                        <div class="form-group">
                                                                            <input required
                                                                                [(ngModel)]="sub.autocomplete"
                                                                                (change)="cambio(i)"
                                                                                [ngModelOptions]="{standalone: true}"
                                                                                [typeahead]="dataSourceSubContest"
                                                                                [typeaheadScrollable]="true"
                                                                                [typeaheadOptionsInScrollableView]="9"
                                                                                (typeaheadLoading)="changeTypeaheadLoadingSub($event,i)"
                                                                                (typeaheadOnSelect)="typeaheadOnSelectSubNew($event,i,sub.cat)"
                                                                                typeaheadOptionField="name"
                                                                                [typeaheadWaitMs]="300" type="text"
                                                                                id="subCategory{{i}}"
                                                                                class="form-control">

                                                                            <label id="label{{i}}"
                                                                                (click)="setFocusAutocomplete(i)"
                                                                                class="form-control-placeholder"
                                                                                for="product">Sub Categoria</label>
                                                                        </div>
                                                                    </form>

                                                                </div>
                                                                <div class="col-1">

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        <!-- END EXCEL NEW -->
                                                        <!-- INVITATION -->

                                                        <div *ngIf=" contest==='contest' " class="col-12 zero">
                                                            <p class="textES">Empresas a invitar</p>

                                                            <div *ngFor="let invited of companiesInvited; let i = index"
                                                                class="col-12 zero">


                                                                <div style="height:70px;"
                                                                    class="row background-gray padding1">

                                                                    <div class="col-4">

                                                                        <div class="form-group">
                                                                            <input [(ngModel)]="invited.name"
                                                                                [ngModelOptions]="{standalone: true}"
                                                                                autocomplete="off" type="text" id="name"
                                                                                class="form-control" required>
                                                                            <label class="form-control-placeholder"
                                                                                for="name">Nombre
                                                                            </label>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-3">

                                                                        <div class="form-group">
                                                                            <input [(ngModel)]="invited.email"
                                                                                (change)="checkIfInvitedExist(invited.email , i)"
                                                                                [ngModelOptions]="{standalone: true}"
                                                                                autocomplete="off" type="text" id="mail"
                                                                                class="form-control" required>
                                                                            <label class="form-control-placeholder"
                                                                                for="mail">Email
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-3">

                                                                        <div class="form-group">
                                                                            <input [(ngModel)]="invited.phone"
                                                                                [ngModelOptions]="{standalone: true}"
                                                                                autocomplete="off" type="text"
                                                                                id="phone" class="form-control"
                                                                                required>
                                                                            <label class="form-control-placeholder"
                                                                                for="phone">Telefono
                                                                            </label>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-1">
                                                                        <button type="button" (click)="addInvited()"
                                                                            class="btn  btn-black  btn-circle">+</button>
                                                                    </div>
                                                                    <div class="col-1">
                                                                        <button type="button" (click)="delInvited(i)"
                                                                            class="btn   btn-black btn-circle">x</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- END INVITATION -->
                                                        <div *ngIf="contest==='request1' " class="col-12 zero"
                                                            style="margin-top: 10px;">
                                                            <p class="textES">Opción 2: Solicitar cada producto
                                                            </p>
                                                            <form [formGroup]="productForm">
                                                                <div style=" margin-top: 10px;
                                                                " class="row zero background-gray padding1 padding-20">
                                                                    <div class="col-12 col-md-4">
                                                                        <div class="form-group">
                                                                            <input #input required autocomplete=off
                                                                                [typeaheadItemTemplate]="itemTemplate"
                                                                                formControlName="searchText"
                                                                                [typeahead]="dataSource"
                                                                                [typeaheadScrollable]="true"
                                                                                [typeaheadOptionsInScrollableView]="9"
                                                                                (typeaheadLoading)="changeTypeaheadLoading($event)"
                                                                                (typeaheadOnSelect)="typeaheadOnSelect($event)"
                                                                                typeaheadOptionField="name"
                                                                                [typeaheadWaitMs]="300" type="text"
                                                                                id="product" class="form-control">
                                                                            <ng-template
                                                                                style="height : 320px !important"
                                                                                class="drop-custom dropped"
                                                                                #itemTemplate let-model="item"
                                                                                let-index="index">
                                                                                <div
                                                                                    [style.width.px]="inputRef.nativeElement.clientWidth">
                                                                                    {{model.name}}
                                                                                </div>
                                                                            </ng-template>
                                                                            <label
                                                                                (click)="setFocusAutocompleteGeneric('product')"
                                                                                class="form-control-placeholder"
                                                                                for="product">Producto</label>
                                                                        </div>

                                                                    </div>

                                                                    <div class="col-12 col-md-4">
                                                                        <div class="form-group">
                                                                            <select id="format" type="text"
                                                                                formControlName="specificationSelect"
                                                                                class="form-control" required>

                                                                                <option [ngValue]="specification.id"
                                                                                    *ngFor="let specification of specifications">


                                                                                    {{specification.unit}}
                                                                                </option>
                                                                            </select>
                                                                            <label class="form-control-placeholder"
                                                                                for="format">Especificación</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-md-2">
                                                                        <div class="form-group">
                                                                            <!-- (ngModelChange)="newProduct.quantity = $event" -->
                                                                            <input type="number"
                                                                                formControlName="quantity"
                                                                                class="form-control" id="quantity"
                                                                                required>
                                                                            <label class="form-control-placeholder"
                                                                                for="quantity">Cantidad</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-md-2">
                                                                        <div class="form-group">
                                                                            <select id="unit" type="text"
                                                                                formControlName="unitSelect"
                                                                                class="form-control" required>

                                                                                <option [ngValue]="unit.id"
                                                                                    *ngFor="let unit of units">


                                                                                    {{unit.name}}
                                                                                </option>
                                                                            </select>
                                                                            <label style=""
                                                                                class="form-control-placeholder"
                                                                                for="unit">Unidad</label>
                                                                        </div>
                                                                        <!--         <div class="form-group">
                                                                            <select type="text" id="format" formControlName="unitSelect" class="form-control" required>
                                                                                
                                                                                <option [ngValue]="unit.id" *ngFor="let unit of units">


                                                                                    {{unit.name}}
                                                                                </option>
                                                                            </select>
                                                                            <label class="form-control-placeholder" for="format">Unidad</label>
                                                                        </div> -->
                                                                    </div>


                                                                    <div class="col-12 col-md-6">
                                                                        <div class="form-group">
                                                                            <select type="text" class="form-control"
                                                                                id="newcat" formControlName="categoryId"
                                                                                required>
                                                                                <option [ngValue]="category.id">
                                                                                    {{category.name}}
                                                                                </option>
                                                                            </select>
                                                                            <label class="form-control-placeholder"
                                                                                for="newcat">Categoria</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-md-6">
                                                                        <div class="form-group">
                                                                            <select type="text" class="form-control"
                                                                                id="newsub"
                                                                                formControlName="subCategoryId"
                                                                                required>
                                                                                <option [ngValue]="subCategory.id">
                                                                                    {{subCategory.name}}
                                                                                </option>
                                                                            </select>
                                                                            <label class="form-control-placeholder"
                                                                                for="newsub">Sub
                                                                                Categoria
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-md-8 margin-top1">
                                                                        <input type="text" class="width input2"
                                                                            style="border-left:0px;border-right:0px;"
                                                                            name="" formControlName="comments"
                                                                            placeholder="Detalle/Marca">
                                                                    </div>
                                                                    <div class="col-12 col-md-4 margin-top1">
                                                                        <div class="upload-btn-wrapper">
                                                                            <button class="btn btn-small btn-black"
                                                                                style="color: white !important;"
                                                                                [ngClass]="{'btn-green':addedComponent}">
                                                                                Adjuntar componente
                                                                                <i class="fa fa-paperclip"
                                                                                    aria-hidden="true"></i>
                                                                            </button>
                                                                            <input type="file" ng2FileSelect
                                                                                [uploader]="uploaderRequest"
                                                                                (change)="uploadFiles($event)" />
                                                                        </div>

                                                                    </div>

                                                                    <div class="col-12 center margin-top1">
                                                                        <button *ngIf="editingProduct"
                                                                            [disabled]="!productForm.valid"
                                                                            (click)="editProduct()"
                                                                            class="btn btn-small btn-black">
                                                                            Editar producto
                                                                        </button>
                                                                        <button *ngIf="!editingProduct"
                                                                            [disabled]="!productForm.valid"
                                                                            (click)="addProduct()"
                                                                            class="btn btn-small btn-white">
                                                                            Agregar producto
                                                                        </button>

                                                                        <div>
                                                                            <!-- <pre>{{productForm.get('subCategoryId').valid}}</pre>
                                                                            <pre>{{productForm.get('categoryId').valid}}</pre>
                                                                            <pre>{{productForm.get('productId').valid}}</pre>
                                                                            <pre>{{productForm.get('quantity').valid}}</pre>
                                                                            <pre>{{productForm.get('comments').valid}}</pre>
                                                                            <pre>{{productForm.get('specificationId').valid}}</pre>
                                                                            <pre>{{productForm.get('unitId').valid}}</pre>
                                                                            <pre>{{productForm.get('category').valid}}</pre>
                                                                            <pre>{{productForm.get('subCategory').valid}}subca</pre>
                                                                            <pre>{{productForm.get('specification').valid}}spec</pre>
                                                                            <pre>{{productForm.get('product').valid}}product</pre>
                                                                            <pre>{{productForm.get('unit').valid}}unit</pre>
                                                                            <pre>{{productForm.get('unitSelect').valid}}unit select</pre>
                                                                            <pre>{{productForm.get('specificationSelect').valid}} spec select</pre>
                                                                            <pre>{{productForm.get('searchText').valid}} search</pre>  -->

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>

                                                        <div id="table" #table *ngIf="contest==='request'"
                                                            class="col-12 zero is-pc-details margin-card marging-table-buy-pc">
                                                            <div class="row">
                                                                <div class="col-12 col-md-2">
                                                                    <p class="strong text-center">Subcategoria</p>
                                                                </div>
                                                                <div class="col-12 col-md-2">
                                                                    <p class="strong text-center">Nombre del Producto
                                                                    </p>
                                                                </div>
                                                                <div class="col-12 col-md-1">
                                                                    <p class="strong text-center">Cantidad</p>
                                                                </div>
                                                                <div class="col-12 col-md-1">
                                                                    <p class="strong text-center">Especificación</p>
                                                                </div>
                                                                <div class="col-12 col-md-1">
                                                                    <p class="strong text-center">Unidad</p>
                                                                </div>
                                                                <div class="col-12 col-md-2">
                                                                    <p class="strong text-center">Categoria</p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div *ngIf="contest==='request'"
                                                            class="col-12 zero marging-table-buy-movil">
                                                            <div class="row" style="margin-top:10px;"
                                                                *ngFor="let product of request.products ; let i = index">
                                                                <div class="col-12 col-md-2">
                                                                    <p class="strong is-movil-details">Subcategorias</p>
                                                                    <label
                                                                        class="font-8 text-center">{{product.subCategory}}</label>
                                                                </div>
                                                                <div class="col-12 col-md-2">
                                                                    <p class="strong is-movil-details">Nombre del
                                                                        Producto</p>
                                                                    <label
                                                                        class="font-8 text-center">{{product.name}}</label>
                                                                    <div class="row">

                                                                        <label *ngIf="product.comments"
                                                                            class="font-8 text-center"
                                                                            style="font-size: 0.6em; color: gray">{{product.comments}}</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 col-md-1">
                                                                    <p class="strong is-movil-details">Cantidad</p>
                                                                    <label
                                                                        class="font-8 text-center">{{product.quantity}}</label>
                                                                </div>
                                                                <div class="col-6 col-md-1">
                                                                    <p class="strong is-movil-details">Especificación
                                                                    </p>
                                                                    <label
                                                                        class="font-8 text-center">{{product.specificationName}}</label>
                                                                </div>
                                                                <div class="col-6 col-md-1">
                                                                    <p class="strong is-movil-details">Unidad</p>
                                                                    <label
                                                                        class="font-8 text-center">{{product.unitName}}</label>
                                                                </div>

                                                                <div class="col-12 col-md-2" *ngIf="!excelUploaded">
                                                                    <p class="strong is-movil-details">Categoria</p>
                                                                    <label
                                                                        class="font-8 text-center">{{product.category}}</label>
                                                                </div>
                                                                <div class="col-12 col-md-2" *ngIf="excelUploaded">
                                                                    <p class="strong is-movil-details">Categoria</p>
                                                                    <label class="font-8 text-center">NA</label>
                                                                </div>

                                                                <div class="col-12 col-md-3">

                                                                    <div class="row marging-table-buy-movil">
                                                                        <div class="col-3 col-md-3 zero">
                                                                            <label *ngIf="excelUploaded"
                                                                                class="font-8 text-center">S/Archivo</label>
                                                                            <button
                                                                                *ngIf="product.productURL && !excelUploaded"
                                                                                class="btn-next">
                                                                                <a href="{{product.productURL}}"
                                                                                    download>
                                                                                    <p class="btn-next-text">
                                                                                    </p>
                                                                                    <i class="fa fa-paperclip color_white"
                                                                                        aria-hidden="true"></i>
                                                                                </a>
                                                                            </button>

                                                                        </div>
                                                                        <div class="col-1 col-md-1"> </div>
                                                                        <div class="col-7 col-md-7 zero">
                                                                            <button *ngIf="!fileUploaded && !reDo"
                                                                                (click)="editDraftProduct(product,i)"
                                                                                class="button_clip zero pad6"
                                                                                style="border:solid transparent;width:100%; border-radius:0.5em">
                                                                                Editar Producto
                                                                            </button>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- FILES -->
                                                        <div class="col-12 zero" *ngIf="contest==='contest'">
                                                            <p class="textES texted"> Seleccionar los archivos que se
                                                                requieran  200 MB limite 

                                                            </p>
                                                            <div style="height:70px;"
                                                                class="row background-gray padding1">
                                                                <div *ngIf="contest==='contest'"
                                                                    class="row width line upload">

                                                                    <div class="col-3">
                                                                        <mat-checkbox color="secondary"
                                                                            [(ngModel)]="securityCheck"
                                                                            (change)="checkSecurity($event)">
                                                                            Matrices de PU</mat-checkbox>
                                                                    </div>
                                                                    <div class="col-3">

                                                                        <mat-checkbox color="primary"
                                                                            [(ngModel)]="suppliesCheck"
                                                                            (change)="checkSupplies($event)">
                                                                            Explosión de Insumos </mat-checkbox>
                                                                    </div>
                                                                    <div class="col-3">

                                                                        <mat-checkbox color="primary"
                                                                            [(ngModel)]="tecnicsCheck"
                                                                            (change)="checkTecnics($event)">
                                                                            Propuesta Técnica </mat-checkbox>
                                                                    </div>
                                                                    <div class="col-3">

                                                                        <mat-checkbox color="primary"
                                                                            [(ngModel)]="valueCheck"
                                                                            (change)="checkEngineering($event)">
                                                                            Ingeniería de Valor </mat-checkbox>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- END files -->

                                                        <!-- DOCUMENT -->
                                                        <div class="col-12 zero" >
                                                            <div class="col-12 zero" >
                                                                <p class="textES texted"> Agregar documentos a enviar
                                                                </p>
                                                                <div style="height:80px;"
                                                                    class="row background-gray padding1">
                                                                    <div
                                                                        class="row width line upload">
                                                                        <div class="col-4">
                                                                            <div class="upload-btn-wrapper">
                                                                                <button
                                                                                    class="btn btn-outline-secondary  btn-personalized"
                                                                                    style="color: black !important;"
                                                                                    [ngClass]="{'btn-green':addedComponent}">
                                                                                    Subir Documentos(max 200MB)
                                                                                    <i class="fa fa-paperclip"
                                                                                        aria-hidden="true"></i>
                                                                                </button>
                                                                                <input type="file" ng2FileSelect
                                                                                    multiple
                                                                                    [uploader]="uploaderDocuments"
                                                                                    (onFileSelected)="uploadFilesDocuments($event)" />
                                                                            </div>

                                                                          
                                                                        </div>
                                                                        <div class="col-4">
                                                                            <p class="textES texted">Si el archivo pesa mas de 200MB favor de proporcionar URL de wetransfer
                                                                            </p>
                                                                        </div>
                                                                        <div class="col-12 col-md-4 margin-top1">
                                                                            <div class="form-group">
                                                                                <input [(ngModel)]="transferUrl" type="text" autocomplete="off"
                                                                                    class="form-control" id="transfer"
                                                                                    required>
                                                                                <label class="form-control-placeholder"
                                                                                    for="transfer">URL de documento</label>
                                                                            </div>
    
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <p class="textES texted"> Agregados
                                                            </p>
                                                            <div style="height:200px; overflow: auto;"
                                                                class="row background-gray padding1">
                                                                <div 
                                                                    class="row width line upload">
                                                                    <div class="col-12">
                                                                        <table class="table" style="margin-bottom:10px">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th width="50%"
                                                                                        style="text-align: center;">
                                                                                        Nombre</th>

                                                                                    <th style="text-align: center;">
                                                                                        Status</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr
                                                                                    *ngFor="let item of uploaderDocuments.queue">
                                                                                    <td width="50%">
                                                                                        <strong>{{ item?.file?.name }}</strong>
                                                                                        <span
                                                                                            *ngIf="getFileExtension(item?.file?.name)==='xlsx' "><i
                                                                                                class="fa fa-file-excel-o "
                                                                                                style="color: #447F19;"></i></span>
                                                                                                <span
                                                                                            *ngIf="getFileExtension(item?.file?.name)==='xls' "><i
                                                                                                class="fa fa-file-excel-o "
                                                                                                style="color: #447F19;"></i></span>
                                                                                        <span
                                                                                            *ngIf="getFileExtension(item?.file?.name)==='ods' "><i
                                                                                                class="fa fa-file-excel-o"
                                                                                                style="color: #447F19;"></i></span>
                                                                                        <span
                                                                                            *ngIf="getFileExtension(item?.file?.name)==='pdf' "><i
                                                                                                class="fa fa-file-pdf-o"
                                                                                                style="color: rgb(185, 25, 3);"></i></span>

                                                                                        <span
                                                                                            *ngIf="getFileExtension(item?.file?.name)==='docx' "><i
                                                                                                class="fa fa-file-word-o "
                                                                                                style="color: rgb(8, 2, 92);"></i></span>

                                                                                    </td>


                                                                                    <td class="text-center">
                                                                                        <span><i class="fa fa-check-circle "
                                                                                                style="color: #000;"></i></span>

                                                                                    </td>

                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <hr>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- END DOCUMENT -->

                                                        <div id="table" #table *ngIf="contest==='contest'"
                                                            class="col-12 zero " style="margin-top:3.2em">
                                                            <div class="row">
                                                                <div class="col-12 col-md-2">
                                                                    <p class="strong text-center">Código</p>
                                                                </div>
                                                                <div class="col-12 col-md-3">
                                                                    <p class="strong text-center">Concepto
                                                                    </p>
                                                                </div>
                                                                <div class="col-12 col-md-1">
                                                                    <p class="strong text-center">Unidad</p>
                                                                </div>
                                                                <div class="col-12 col-md-1">
                                                                    <p class="strong text-center">Cantidad </p>
                                                                </div>
                                                                <div class="col-12 col-md-1">
                                                                    <p class="strong text-center">Categoria </p>
                                                                </div>
                                                                <div class="col-12 col-md-2">
                                                                    <p *ngIf="!reDo" class="strong text-center">Precio
                                                                        Unitario</p>
                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div *ngIf="contest==='contest'"
                                                            class="col-12 zero marging-table-buy-movil">
                                                            <div class="row" style="margin-top:10px;"
                                                                *ngFor="let product of request.products ; let i = index">
                                                                <div class="col-12 col-md-2 rowHeightLic">
                                                                    <p class="strong is-movil-details">Código</p>
                                                                    <label
                                                                        class="font-8 text-center">{{product.code}}</label>
                                                                </div>
                                                                <div [tooltip]="product.name"
                                                                    class="col-12 col-md-3 rowHeightLic">
                                                                    <p class="strong is-movil-details">Concepto</p>
                                                                    <label [tooltip]="product.name"
                                                                        *ngIf="product.name.length < 50"
                                                                        class="font-8 text-center">{{product.name}}</label>
                                                                    <label [tooltip]="product.name"
                                                                        *ngIf="product.name.length > 50"
                                                                        class="font-8 text-center">{{product.name}}</label>

                                                                </div>
                                                                <div class="col-6 col-md-1 rowHeightLic">
                                                                    <p class="strong is-movil-details">Unidad
                                                                    </p>
                                                                    <label
                                                                        class="font-8 text-center">{{product.unitName}}</label>
                                                                </div>
                                                                <div class="col-6 col-md-1 rowHeightLic">
                                                                    <p class="strong is-movil-details">Cantidad</p>
                                                                    <label
                                                                        class="font-8 text-center">{{product.quantity}}</label>
                                                                </div>
                                                                <div class="col-6 col-md-1 rowHeightLic">
                                                                    <p class="strong is-movil-details">Sub</p>
                                                                    <label
                                                                        class="font-8 text-center">{{product.subCategory}}</label>
                                                                </div>
                                                                <div class="col-6 col-md-2 rowHeightLic">
                                                                    <p *ngIf="!reDo" class="strong is-movil-details">
                                                                        Precio Unitario
                                                                    </p>
                                                                    <label *ngIf="!reDo"
                                                                        class="font-8 text-center"> $ {{product.price}}</label>
                                                                </div>



                                                                <div class="col-12 col-md-3 rowHeightLic">

                                                                    <div class="row marging-table-buy-movil">
                                                                        <div class="col-3 col-md-3 zero">

                                                                            <button
                                                                                *ngIf="product.productURL && !fileUploaded"
                                                                                class="btn-next">
                                                                                <a href="{{product.productURL}}"
                                                                                    download>
                                                                                    <p class="btn-next-text">
                                                                                    </p>
                                                                                    <i class="fa fa-paperclip color_white"
                                                                                        aria-hidden="true"></i>
                                                                                </a>
                                                                            </button>

                                                                        </div>
                                                                        <div class="col-1 col-md-1"> </div>
                                                                        <div class="col-7 col-md-7 zero">
                                                                            <button *ngIf="!fileUploaded && !reDo"
                                                                                (click)="editDraftProduct(product,i)"
                                                                                class="button_clip zero pad6"
                                                                                style="border:solid transparent;width:100%; border-radius:0.5em">
                                                                                Editar Producto
                                                                            </button>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="row">



                                                        </div>

                                                        <div class="col-3 col-md-3 zero"></div>

                                                        <div *ngIf="completingDraft" class="col-12 col-md-6 zero ">
                                                            <button [disabled]="request.products.length===0 "
                                                                (click)="completeBorrador()"
                                                                class="btn button_clip zero center collapse_title btn-next">
                                                                Siguiente
                                                            </button>
                                                        </div>
                                                        <div *ngIf="!completingDraft"
                                                            class="col-12 col-md-6 zero padding1">

                                                            <button [disabled]="request.products.length===0 "
                                                                (click)="saveBorrador()"
                                                                class="btn button_clip zero center collapse_title btn-next">
                                                                Siguiente / Guardar borrador
                                                            </button>
                                                        </div>
                                                        <div class="col-3 col-md-3 zero"></div>
                                                    </div>
                                                    <div class="">&nbsp;</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- PADO 2 -->
                    <div class="row style-firsth" *ngIf="paso2">
                        <div class="container-element padding0">
                            <div class="row zero background-gray">
                                <div class="col-1 col-md-1 zero"></div>
                                <div class="col-2 col-md-2 collapse_titl padding1">
                                    <p (click)="limpia()" style="    font-size: 15px; font-weight: bold; ">
                                        <i class="fa fa-chevron-left" aria-hidden="true"></i> &nbsp;&nbsp;&nbsp;PASO
                                        2/2</p>
                                </div>
                                <div class="col-6 col-md-6 zero collapse_title padding1"> CONFIGURA TU SOLICITUD DE
                                    COTIZACIÓN
                                </div>
                                <div class="col-3 col-md-3 zero"></div>
                            </div>
                            <div class="zero padding-paso2">
                                <div class="row">
                                    <div class="container">
                                        <!--PASO 2 COTIZACIÓN-->
                                        <div class=" cont_info zero">
                                            <ul class="nav nav-pills mb-2 zero background-gray "
                                                style="padding: 5px!important;" id="pills-tab" role="tablist"
                                                style="border-top: solid 0.01em #c0c0c0;">
                                                <li class="nav-item col-0 col-md-4 zero" style="    padding-top: 10px;">

                                                </li>
                                                <li class="nav-item col-12 col-md-5 zero"
                                                    style="    padding-top: 10px;">
                                                </li>
                                                <li *ngIf="contest==='contest'" class="nav-item col-12 col-md-3 zero">
                                                    <div class="collapse_title pad6 green"
                                                        style="color:#447F19!important">Licitación</div>

                                                </li>
                                                <li *ngIf="contest==='request'" class="nav-item col-12 col-md-3 zero">
                                                    <div *ngIf="!request.isExpress" class="collapse_title pad6 green"
                                                        style="color:#447F19!important">Cotización Regular</div>

                                                    <div *ngIf="request.isExpress" class="collapse_title pad6 wine"
                                                        style="color: #7f1919 !important">Cotización Express</div>
                                                </li>
                                            </ul>
                                            <div class="tab-content margin-paso2" id="pills-tabContent">
                                                <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                                                    aria-labelledby="pills-home-tab">
                                                    <div class="row background-white marg-pad-bloque">
                                                        <div class="col-12 col-md-6">
                                                            <p *ngIf="contest==='request'"> Escribe aquí los detalles de
                                                                tu pedido </p>
                                                            <input *ngIf="contest==='request'" type="text"
                                                                class="width input2" style="border-left:0px;"
                                                                [(ngModel)]="request.details" name="details"
                                                                #details="ngModel" placeholder="Detalles del pedido">
                                                            <p *ngIf="contest==='contest'"> Escribe aquí las bases de la
                                                                licitación </p>
                                                            <textarea *ngIf="contest==='contest'" type="text"
                                                                class="width input2"
                                                                style="border-left:0px;overflow-y:scroll;"
                                                                [(ngModel)]="request.details" rows="10" name="details"
                                                                #details="ngModel"
                                                                placeholder="Bases de la licitación"></textarea>

                                                            <p *ngIf="contest==='request'" class="margin-top1">
                                                                <span class="required">*</span>Margen de tiempo para
                                                                recibir el producto:</p>
                                                            <div *ngIf="contest==='request'" class="row">
                                                                <p>Entre el día</p>
                                                            </div>
                                                            <div *ngIf="contest==='contest'" class="row">
                                                                <div class="col-1">
                                                                    <input (change)="changeVisit()"
                                                                        [checked]="radioCheckVisit" type="checkbox"
                                                                        id="checkVisit"
                                                                        class="filled-in chk-col-grey" />
                                                                    <label class="fix-left" for="checkVisit"></label>

                                                                </div>
                                                                <div class="col-11">
                                                                    <p>Visita de Obra</p>


                                                                </div>
                                                            </div>
                                                            <div *ngIf="radioCheckVisit" class="row zero">
                                                                <div class="col-12 col-md-6 zero height2">
                                                                    <p class="datepicker-top"></p>
                                                                    <input type="text" class="first-day"
                                                                        [(ngModel)]="timeToReceiveStart"
                                                                        placeholder="Inicio" bsDatepicker
                                                                        placement="right"
                                                                        [bsConfig]="{ dateInputFormat: 'DD MMMM YYYY',containerClass: 'theme-default',showWeekNumbers: false }"
                                                                        style="background-color:#E9E9E9;border:solid transparent; border-bottom:solid 1px #999;  padding:0.5em; width:100%;">
                                                                </div>

                                                                <div class="col-12 col-md-6">
                                                                    <timepicker [(ngModel)]="timeToReceiveStart"
                                                                        [showMeridian]="true"></timepicker>
                                                                </div>
                                                            </div>
                                                            <p class="margin-top1">
                                                            </p>
                                                            <div *ngIf="contest==='request'" class="row">
                                                                <p>Y el día</p>
                                                            </div>
                                                            <div *ngIf="contest==='contest'" class="row">
                                                                <div class="col-1">
                                                                    <input
                                                                        (change)="changeVisitGeneral()"
                                                                        [checked]="radioCheckGeneral" type="checkbox"
                                                                        id="checkGeneral"
                                                                        class="filled-in chk-col-grey" />
                                                                    <label class="fix-left" for="checkGeneral"></label>

                                                                </div>
                                                                <div class="col-11">
                                                                    <p>Aclaracion General</p>

                                                                </div>
                                                            </div>
                                                            <div *ngIf="radioCheckGeneral" class="row zero">
                                                                <div class="col-12 col-md-6 zero height2">
                                                                    <p class="datepicker-top"></p>
                                                                    <input type="text" class="first-day"
                                                                        [(ngModel)]="timeToReceiveEnd"
                                                                        placeholder="Inicio" bsDatepicker
                                                                        placement="right"
                                                                        [bsConfig]="{ dateInputFormat: 'DD MMMM YYYY',containerClass: 'theme-default',showWeekNumbers: false }"
                                                                        style="background-color:#E9E9E9;border:solid transparent; border-bottom:solid 1px #999;  padding:0.5em; width:100%;">
                                                                </div>

                                                                <div class="col-12 col-md-6">
                                                                    <timepicker [(ngModel)]="timeToReceiveEnd"
                                                                        [showMeridian]="true"></timepicker>
                                                                </div>
                                                            </div>

                                                            <p class="margin-top1">
                                                                <span class="required">*</span>Selecciona los
                                                                porcentajes en los siguentes parámetros
                                                                para definir el crédito que necesitas
                                                            </p>
                                                            <div *ngIf="contest==='request'" class="row">
                                                                <div class="col-6 col-md-4 zero">
                                                                    <div class="row">
                                                                        <div class="col-1 col-md-1 zero">
                                                                            <p class="font1"> %</p>
                                                                        </div>
                                                                        <div class="col-1 col-md-1 zero">

                                                                        </div>
                                                                        <div class="col-4 col-md-3 zero">
                                                                            <input
                                                                                [(ngModel)]="paymentOptions.anticipated"
                                                                                name="anticipado" #anticipado="ngModel"
                                                                                class="input text-center"
                                                                                type="number" />
                                                                        </div>
                                                                        <div class="col-7 col-md-3 zero">
                                                                            <p class="font1">Anticipo</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 col-md-6 zero">
                                                                    <div class="row">
                                                                        <div class="col-1 col-md-1 zero">
                                                                            <p class="font1">% </p>
                                                                        </div>

                                                                        <div class="col-3 col-md-3 zero">
                                                                            <input
                                                                                [(ngModel)]="paymentOptions.uponDelivery"
                                                                                name="contraentrega"
                                                                                #contraentrega="ngModel"
                                                                                class="text-center input"
                                                                                type="number" />
                                                                        </div>

                                                                        <div class="col-8 col-md-2 zero">
                                                                            <p class="font1">
                                                                                Contraentrega</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-md-7 zero marginTop20">
                                                                    <div class="row">
                                                                        <div class="col-1 col-md-1 zero">
                                                                            <p class="font1">% </p>
                                                                        </div>

                                                                        <div class="col-2 col-md-2 zero">
                                                                            <input [(ngModel)]="paymentOptions.credit"
                                                                                style="border:solid transparent; border-bottom:solid 0.2em #E0E1E2;font-size:0.7em; width:100%;"
                                                                                name="credito" #credito="ngModel"
                                                                                class="input text-center"
                                                                                type="number" />
                                                                        </div>
                                                                        <div class="col-1 col-md-1 zero">

                                                                        </div>
                                                                        <div class="col-4 col-md-4 zero center">
                                                                            <p class="font1">Crédito por</p>
                                                                        </div>
                                                                        <div class="col-3 col-md-3 zero">
                                                                            <input [(ngModel)]="paymentOptions.days"
                                                                                [disabled]="!paymentOptions.credit>0"
                                                                                name="dias" #dias="ngModel"
                                                                                class="input text-center" type="number"
                                                                                name="" />
                                                                        </div>
                                                                        <div class="col-1 col-md-1 zero">
                                                                            <p class="font1">días</p>
                                                                        </div>
                                                                        <div class="col-1 col-md-1 zero"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="contest==='contest'" class="row">
                                                                <div class="col-6 col-md-4 zero">
                                                                    <div class="row">
                                                                        <div class="col-1 col-md-1 zero">
                                                                            <p class="font1"> %</p>
                                                                        </div>
                                                                        <div class="col-1 col-md-1 zero">

                                                                        </div>
                                                                        <div class="col-4 col-md-3 zero">
                                                                            <input
                                                                                [(ngModel)]="paymentOptions.anticipated"
                                                                                name="anticipado" #anticipado="ngModel"
                                                                                class="input text-center"
                                                                                type="number" />
                                                                        </div>
                                                                        <div class="col-7 col-md-3 zero">
                                                                            <p class="font1">Anticipo</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 col-md-6 zero">
                                                                    <div class="row">
                                                                        <div class="col-1 col-md-1 zero">
                                                                            <p class="font1">% </p>
                                                                        </div>

                                                                        <div class="col-3 col-md-3 zero">
                                                                            <input
                                                                                [(ngModel)]="paymentOptions.uponDelivery"
                                                                                name="contraentrega"
                                                                                #contraentrega="ngModel"
                                                                                class="text-center input"
                                                                                type="number" />
                                                                        </div>

                                                                        <div class="col-8 col-md-2 zero">
                                                                            <p class="font1">
                                                                                Estimaciones</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-md-7 zero marginTop20">
                                                                    <div class="row">
                                                                        <div class="col-1 col-md-1 zero">
                                                                            <p class="font1">% </p>
                                                                        </div>

                                                                        <div class="col-2 col-md-2 zero">
                                                                            <input [(ngModel)]="paymentOptions.credit"
                                                                                style="border:solid transparent; border-bottom:solid 0.2em #E0E1E2;font-size:0.7em; width:100%;"
                                                                                name="credito" #credito="ngModel"
                                                                                class="input text-center"
                                                                                type="number" />
                                                                        </div>
                                                                        <div class="col-1 col-md-1 zero">

                                                                        </div>
                                                                        <div class="col-4 col-md-4 zero center">
                                                                            <p class="font1">Finiquito</p>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>

                                                        <div class="col-12 col-md-6">
                                                            <p class="margin-top1">
                                                                <span class="required">*</span>Fecha y hora límite para
                                                                recibir propuestas:</p>
                                                            <div class="row">


                                                                <div style="margin-top:30px;" class="col-12 col-md-6">
                                                                    <input type="text" [(ngModel)]="timeToReceiveLimit"
                                                                        placeholder="Fecha Limite" class="" bsDatepicker
                                                                        placement="bottom"
                                                                        [bsConfig]="{ dateInputFormat: 'DD MMMM YYYY',containerClass: 'theme-default',showWeekNumbers: false }"
                                                                        style="background-color:#E9E9E9;border:solid transparent; border-bottom:solid 1px #999;  margin-top:0em; padding:0.5em; width:100%;">
                                                                </div>
                                                                <div class="col-12 col-md-6">
                                                                    <timepicker [(ngModel)]="timeToReceiveLimit"
                                                                        [showMeridian]="true"></timepicker>

                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-12 col-md-5 zero">
                                                                    <p class="">
                                                                        <span class="required">*</span>
                                                                        Lugar:</p>
                                                                </div>
                                                                <div class="col-12 col-md-5 zero">

                                                                </div>
                                                            </div>
                                                            <div class="row ">
                                                                <div class="col-12">
                                                                    <div *ngIf="viewMap" id="map" #gmap
                                                                        class="mapStyle"></div>
                                                                </div>
                                                            </div>
                                                            <div class="row top20">
                                                                <input (onSelect)="setAddress($event)" google-place
                                                                    [(ngModel)]="currentAddress" name="sucAddress"
                                                                    #sucAddress="ngModel" class="form-control"
                                                                    type="text" placeholder="Direccion">

                                                            </div>
                                                            <div *ngIf="contest==='request'"
                                                                class="row margin-top1 marginTop20">
                                                                <div class="padding-left10" class="col-2 col-md-1 zero">

                                                                    <input (change)="selectedRadio($event)"
                                                                        [checked]="radioCheck" type="checkbox"
                                                                        id="pickupRadio"
                                                                        class="filled-in chk-col-grey" />
                                                                    <label class="fix-left" for="pickupRadio"></label>

                                                                </div>
                                                                <div class="col-7 col-md-7 padding-left0 marginTop5">
                                                                    <p>Puede pasar por el producto en un radio de:</p>

                                                                </div>
                                                                <div class="col-3 col-md-2 zero">
                                                                    <div *ngIf="radioCheck">
                                                                        <select [(ngModel)]="request.radius"
                                                                            class="input">
                                                                            <option selected hidden default disabled>
                                                                                Selecciona el radio:
                                                                            </option>
                                                                            <option value="{{radio}}"
                                                                                *ngFor="let radio of radios; let i = index">
                                                                                {{radio}}Km
                                                                            </option>

                                                                        </select>

                                                                    </div>

                                                                </div>
                                                                <div class="col-0 col-md-2 zero"></div>
                                                            </div>
                                                            <div class="row margin-top1">

                                                            </div>

                                                        </div>
                                                        <div class="row width">
                                                            <div class="col-3 col-md-3 zero"></div>

                                                            <!--   guardar borrador -->
                                                            <div class="col-6 col-md-6 zero padding1">
                                                                <button
                                                                    class="btn button_clip zero center collapse_title btn-next"
                                                                    (click)="limpia2()"
                                                                    [disabled]="!this.currentAddress">
                                                                    Siguiente
                                                                </button>
                                                            </div>
                                                            <div class="col-3 col-md-3 zero"></div>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="padding-1">&nbsp;</div>
                                                <!-- MIS USUARIOS -->
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- CONFIRMAR COTIZACION -->
                    <div class="row style-firsth" *ngIf="paso3">
                        <div class="container-element padding0">
                            <div class="row zero background-gray">
                                <div class="col-0 col-md-1 zero"></div>
                                <div class="col-0 col-md-2 collapse_titl padding1"></div>
                                <div class="col-12 col-md-6 zero collapse_title padding1"> CONFIGURA TU SOLICITUD DE
                                    COTIZACIÓN
                                </div>
                                <div class="col-0 col-md-3 zero"></div>
                            </div>
                            <div *ngFor="let request of arrayRequests" class="zero padding-paso3">
                                <div class="row">
                                    <div class="container-element">
                                        <!--PASO 2 COTIZACIÓN-->
                                        <div class="cont_info zero">
                                            <ul class="nav nav-pills mb-2 zero background-gray " id="pills-tab"
                                                role="tablist" style="border-top: solid 0.01em #c0c0c0;">
                                                <li *ngIf="contest==='request'" class="nav-item col-0 col-md-4 zero"
                                                    style="    padding-top: 10px;">
                                                    Centro de costos: {{request.projectName}}
                                                </li>
                                                <li class="nav-item col-12 col-md-5 zero"
                                                    style="    padding-top: 10px;">
                                                    Folio: {{request.folio}}
                                                </li>
                                                <li *ngIf="contest==='contest'" class="nav-item col-12 col-md-3 zero">
                                                    <div class="collapse_title pad6 green"
                                                        style="color:#447F19!important">Licitación</div>


                                                </li>
                                                <li *ngIf="contest==='request'" class="nav-item col-12 col-md-3 zero">
                                                    <div *ngIf="!request.isExpress" class="collapse_title pad6 green"
                                                        style="color:#447F19!important">Cotización Regular</div>

                                                    <div *ngIf="request.isExpress" class="collapse_title pad6 wine"
                                                        style="color: #7f1919 !important">Cotización Express</div>
                                                </li>
                                            </ul>
                                            <div class="tab-content" id="pills-tabContent" style="margin-top: -1.4em;">
                                                <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                                                    aria-labelledby="pills-home-tab">
                                                    <div class="row background-white marg-pad-bloque">
                                                        <div *ngFor="let product of request.products" class="row">
                                                            <div class="col-12 col-md-6 zero">
                                                                <div class="row zero">
                                                                    <div class="col-12 col-md-6">
                                                                        <p class="text-center">Nombre del Producto</p>
                                                                        <label
                                                                            class="text-center noSpaceText">{{product.name}}</label>
                                                                        <label class="text-center "
                                                                            style="font-size: 0.6em; color: gray">{{product.comments}}</label>
                                                                    </div>
                                                                    <div class="col-6 col-md-2">
                                                                        <p class="text-center">Especificacion</p>
                                                                        <label *ngIf="product.specification"
                                                                            class="text-center">{{product.specification}}</label>
                                                                        <label
                                                                            *ngIf="!product.specification && product.specificationName"
                                                                            class="text-center">{{product.specificationName}}</label>

                                                                    </div>
                                                                    <div class="col-6 col-md-2">
                                                                        <p class="text-center">Cantidad</p>
                                                                        <label
                                                                            class="text-center">{{product.quantity}}</label>
                                                                    </div>

                                                                    <div class="col-6 col-md-2">
                                                                        <p class="text-center">Unidad</p>
                                                                        <label
                                                                            class="text-center">{{product.unitName}}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-md-6 zero">
                                                                <div class="row zero">
                                                                    <div class="col-12 col-md-6">
                                                                        <p class="text-center">Categoria</p>
                                                                        <label
                                                                            class="text-center">{{product.category}}</label>
                                                                    </div>
                                                                    <div class="col-12 col-md-4">
                                                                        <p class="text-center">Subcategorias</p>
                                                                        <label
                                                                            class="text-center">{{product.subCategory}}</label>
                                                                    </div>
                                                                    <!-- <div class="col-4 col-md-4">
                                                                        <p>Área</p>
                                                                        <label>{{product.category}}</label>
                                                                    </div> -->
                                                                </div>
                                                            </div>

                                                            <div class="col-12 col-md-9 padding-0">

                                                            </div>
                                                            <div class="col-12 col-md-3">
                                                                <div class="container">
                                                                    <div class="row">
                                                                        <div class="col-3 col-md-6 zero"></div>
                                                                        <div class="col-4 col-md-3 zero">

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-3 col-md-3 zero">
                                                            </div>
                                                            <hr class="hr">
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--PASO 2 COTIZACIÓN-->
                            <div class="cont_info padding-paso3-2">
                                <div class="tab-content marg-5" id="pills-tabContent">
                                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                                        aria-labelledby="pills-home-tab">
                                        <div class="row bg_blanco marg-pad-bloque">
                                            <div *ngIf="contest==='request'" class="col-12 col-md-6">
                                                <p *ngIf="!request.details" class=""> No hay detalle de tu pedido </p>

                                                <p *ngIf="request.details" class=""> Detalle de tu pedido </p>
                                                <p style="font-size: 0.6em; color: gray">
                                                    {{request.details}}
                                                </p>
                                                <p class="zero left font-7 " style="margin-top: 10px;">Condiciones de
                                                    pago:</p>
                                                <div class="row texts zero marginTop25">
                                                    <div *ngIf="paymentOptions " class="col-12 col-md-3 zero  left">
                                                        <p class="paySize">
                                                            <span class="strong">{{paymentOptions.anticipated}}%</span>
                                                            Anticipo</p>
                                                    </div>
                                                    <div *ngIf="paymentOptions " class="col-12 col-md-4 zero   left">
                                                        <p class="paySize">
                                                            <span class="strong">{{paymentOptions.uponDelivery}}%</span>
                                                            Contraentrega</p>
                                                    </div>
                                                    <div *ngIf="paymentOptions " class="col-12 col-md-5 zero   left">
                                                        <p class="paySize">
                                                            <span class="strong">{{paymentOptions.credit}}%</span>
                                                            Crédito por
                                                            <span class="strong ">{{paymentOptions.days}}</span> días
                                                        </p>
                                                    </div>
                                                </div>

                                                <p *ngIf="contest==='request'" class="top1 marginTop10"
                                                    style="margin-top: 20px">Margen de tiempo
                                                    para recibir el producto:</p>
                                                <div class="row marginTop10">
                                                    <div class="col-12 col-md-3 zero left">
                                                        <p *ngIf="contest==='request'">Entre el día</p>
                                                        <p *ngIf="contest==='contest'">Visita de obra</p>

                                                    </div>
                                                    <div class="col-12 col-md-4 zero">
                                                        <label> {{timeToReceiveStart | date: 'dd/MM/yyyy hh:mm a'}}
                                                        </label>
                                                    </div>

                                                </div>
                                                <div class="row">

                                                    <div *ngIf="contest==='contest'"
                                                        class="col-7 col-md-7 padding-left0 marginTop5">
                                                        <p>Visita de Obra:</p>
                                                    </div>

                                                    <div class="col-12 col-md-3 zero left">
                                                        <p *ngIf="contest==='request'">Y el día</p>
                                                        <p *ngIf="contest==='contest'">Aclaracion General</p>

                                                    </div>
                                                    <div class="col-12 col-md-4 zero">
                                                        <label> {{timeToReceiveEnd | date: 'dd/MM/yyyy hh:mm a' }}
                                                        </label>
                                                    </div>

                                                </div>
                                            </div>
                                            <div *ngIf="contest==='contest'" class="col-12 col-md-6">
                                                <p *ngIf="!request.details" class=""> No hay detalle de tu pedido </p>

                                                <p *ngIf="request.details" class=""> Detalles </p>
                                                <p style="font-size: 0.6em; color: gray">
                                                    {{request.details}}
                                                </p>
                                                <p class="zero left font-7 " style="margin-top: 10px;">Condiciones de
                                                    pago:</p>
                                                <div class="row texts zero marginTop25">
                                                    <div *ngIf="paymentOptions " class="col-12 col-md-3 zero  left">
                                                        <p class="paySize">
                                                            <span class="strong">{{paymentOptions.anticipated}}%</span>
                                                            Anticipo</p>
                                                    </div>
                                                    <div *ngIf="paymentOptions " class="col-12 col-md-4 zero   left">
                                                        <p class="paySize">
                                                            <span class="strong">{{paymentOptions.uponDelivery}}%</span>
                                                            Estimaciones</p>
                                                    </div>
                                                    <div *ngIf="paymentOptions " class="col-12 col-md-5 zero   left">
                                                        <p class="paySize">
                                                            <span class="strong">{{paymentOptions.credit}}%</span>
                                                            Finiquito
                                                            <span class="strong ">{{paymentOptions.days}}</span> días
                                                        </p>
                                                    </div>
                                                </div>



                                            </div>
                                            <div class="col-12 col-md-6 ">

                                                <div class="row zero" style="margin-bottom: 15px">
                                                    <div class="col-6 col-md-6 zero">
                                                        <p class="">Fecha y hora límite para recibir participacion:</p>
                                                    </div>
                                                    <div class="col-6 col-md-4 zero">
                                                        <p>&nbsp; &nbsp;&nbsp;{{timeToReceiveLimit | date: 'dd/MM/yyyy
                                                            hh:mm
                                                            a '}} </p>
                                                    </div>

                                                </div>
                                                <div class="row zero">
                                                    <div class="col-12 col-md-5 zero">
                                                        <p class="">
                                                            Lugar :</p>
                                                    </div>
                                                    <div class="col-12 col-md-7 zero">
                                                        <label *ngIf="currentAddress">{{currentAddress}}</label>
                                                        <label
                                                            *ngIf="request.selectedAddress && !currentAddress">{{request.selectedAddress}}</label>


                                                    </div>
                                                </div>
                                                <div *ngIf="request.pickupRadius" class="row margin-top1">

                                                    <div *ngIf="request.pickupRadius" class="col-8 col-md-7 zero">
                                                        <p>Puede pasar por el producto en un radio de:</p>
                                                    </div>
                                                    <div *ngIf="request.pickupRadius" class="col-4 col-md-2 zero">
                                                        <label> {{request.radius}} km</label>
                                                    </div>
                                                    <div class="col-0 col-md-2 zero"></div>
                                                </div>

                                            </div>


                                            <div class="row width">
                                                <div class="col-0 col-md-3 zero"></div>
                                                <!--      <div class="col-3 col-md-2 padding1">
                                                         <button [disabled]="request.products.length===0" class="button_clip  center pad6 collapse_title" style="border:solid transparent;width:100%;    font-size: 0.8em;">
                                                             Guardar borrador
                                                         </button>
                                                     </div> -->
                                                <div class="col-12 col-md-3 padding1">

                                                </div>
                                                <div class="col-12 col-md-3 padding1">

                                                </div>
                                                <div class="col-0 col-md-3 zero"></div>
                                            </div>

                                            <div class="row width">
                                                <div class="col-12 col-md-3 zero"></div>

                                                <div *ngIf="editar==='false' && contest==='request'"
                                                    class="col-12 col-md-6 padding1">
                                                    <button [routerLink]="['../enviadas']"
                                                        [disabled]="request.products.length===0"
                                                        class="boton_clip  center collapse_title boton-quotation-one">
                                                        Volver
                                                    </button>
                                                </div>
                                                <div *ngIf="editar==='true' && contest==='request'"
                                                    class="col-12 col-md-3 padding1">
                                                    <button
                                                        class="boton_clip  center collapse_title boton-quotation-two"
                                                        style="background-color: grey;" (click)="limpia3()">
                                                        Editar cotización
                                                    </button>

                                                </div>

                                                <div *ngIf="editar==='true'  && contest==='contest'"
                                                    class="col-12 col-md-6 padding1">
                                                    <button [disabled]="request.products.length===0"
                                                        class="boton_clip  center collapse_title boton-quotation-three"
                                                        (click)="multipleCreateAndSendRequest()">
                                                        Crear licitación
                                                    </button>
                                                </div>
                                                <div *ngIf="editar==='true'  && contest==='request'"
                                                    class="col-12 col-md-3 padding1">
                                                    <button [disabled]="request.products.length===0"
                                                        class="boton_clip  center collapse_title boton-quotation-three"
                                                        (click)="multipleCreateAndSendRequest()">
                                                        Crear cotización
                                                    </button>
                                                </div>
                                                <div class="col-12 col-md-3 zero"></div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="padding-top6"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>