<div class="container_bg" style="background-image:url(assets/images/2023/bg.png);">
	<div class="container-scroll zero">
		<div class="container zero">
			<div class="container_bg_difuninado">
				<app-newRequest ></app-newRequest>
			</div>
		</div>

		
	</div>

</div>