import { Routes, RouterModule, CanActivate } from '@angular/router';
import { PagesComponent } from './pages/pages.component';
import { AuthGuardService } from './services/auth-guard.service';
import { ReceivedComponent } from './pages/client/received/received.component';
import { SendedComponent } from './pages/client/sended/sended.component';
import { RequestComponent } from './pages/client/request/request.component';
import { AcceptedQuotations } from './pages/provider/acceptedQuotations/acceptedQuotations.component';
import { SentQuotations } from './pages/provider/sentQuotations/sentQuotations.component';
import { PendingQuotationsComponent } from './pages/provider/pendingQuotations/pendingQuotations.component';
import { Companycomponent } from './pages/user/company/company.component';
import { ReportsComponent } from './pages/provider/reports/reports.component';
import { LoginComponent } from './pages/user/login/login.component';
import { NewsQuotationsComponent } from './pages/provider/newsQuotation/newsQuotations.component';
import { PdfOrderComponent } from './modals/pdfOrder/pdfOrder.component';
import { PdfQuotationComponent } from './modals/pdfQuotation/pdfQuotation.component';
import { NopagefoundComponent } from './pages/shared/nopagefound/nopagefound.component';
import { ConcretedComponent } from './pages/client/concreted/concreted.component';
import { RequestAgroComponent } from './pages/client/request-agro/request-agro.component';
import { RecoveryComponent } from './pages/user/recovery/recovery.component';


const appRoutes: Routes = [
    {
        path: '', component: PagesComponent,
        children: [

            /*CLIENTE*/
            { path: 'concretadas', component: ConcretedComponent, canActivate: [AuthGuardService], data: { isPerfil: false } },
            { path: 'recibidas', component: ReceivedComponent, canActivate: [AuthGuardService], data: { isPerfil: false } },
            { path: 'enviadas', component: SendedComponent, canActivate: [AuthGuardService], data: { isPerfil: false } },
            { path: 'compras', component: RequestComponent, canActivate: [AuthGuardService], data: { isPerfil: false } },
            { path: 'agro', component: RequestAgroComponent, canActivate: [AuthGuardService], data: { isPerfil: false } },

            /*END CLIENTE*/
          //  { path: 'reporte', component: ReporteresultadosComponent, canActivate: [AuthGuardService], data: { isPerfil: true } },
            { path: 'aceptada', component: AcceptedQuotations, canActivate: [AuthGuardService], data: { isPerfil: false } },
            { path: 'ventasenviadas', component: SentQuotations, canActivate: [AuthGuardService], data: { isPerfil: false } },
            { path: 'porcotizar', component: PendingQuotationsComponent, canActivate: [AuthGuardService], data: { isPerfil: false } },
            { path: 'empresa', component: Companycomponent, canActivate: [AuthGuardService], data: { isPerfil: true } },
            { path: 'empresa/:id', component: Companycomponent, canActivate: [AuthGuardService], data: { isPerfil: true } },
            { path: 'reportes', component: ReportsComponent, canActivate: [AuthGuardService], data: { isPerfil: false } },
        //    { path: 'reporte', component: ReporteresultadosComponent, canActivate: [AuthGuardService], data: { isPerfil: false } },
            { path: 'register', component: LoginComponent, data: { isPerfil: false } },
            { path: 'inicio', component: NewsQuotationsComponent, canActivate: [AuthGuardService], data: { isPerfil: false } },
            { path: '', redirectTo: '/inicio', pathMatch: 'full' }
        ]
    },

    //   { path: 'aprobarCotizacion/:id/:token', component: CotizacionAceptarComponent },
    { path: 'login', component: LoginComponent },
    { path: 'recovery', component: RecoveryComponent },
    { path: 'po/:id', component: PdfOrderComponent },
    { path: 'cotizacion/:id', component: PdfQuotationComponent },
    { path: 'register', component: LoginComponent },

    { path: '**', component: NopagefoundComponent }
];

export const APP_ROUTES = RouterModule.forRoot(appRoutes, {
    useHash: true
});
