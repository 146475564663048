/// <reference types="@types/googlemaps" />

import { Component, OnInit, ChangeDetectorRef, NgZone } from "@angular/core";
import { ApiService } from "../../../services/apiService";
import { NgxSpinnerService } from "ngx-spinner";
import swal from "sweetalert2";
import { Router, ActivatedRoute } from "@angular/router";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormBuilder,
} from "@angular/forms";
import { ViewChild } from "@angular/core";
//import { } from '@types/googlemaps';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import { FileUploader, FileItem } from "ng2-file-upload";
import { UUID } from "angular2-uuid";
import { ImageCropperComponent, CropperSettings } from "ngx-img-cropper";
import { QueryFactory } from "../../../tableQueries/queryFactory";
import { GooglePlacesDirective } from "../../../directives/google-places.directive";
import { Payment } from "../../../interfaces/payment";

GooglePlacesDirective;
declare var $: any;

@Component({
  selector: "app-company",
  templateUrl: "./company.component.html",
  styleUrls: ["./company.component.css"],
  providers: [ApiService],
  viewProviders: [GooglePlacesDirective],
})
export class Companycomponent implements OnInit {
  //  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  @ViewChild("gmap", { static: true }) gmapElement: any;
  @ViewChild("gmapProject", { static: true }) gmapElementProject: any;
  @ViewChild("project_address") addresstext: any;
  public stripe: any = Stripe(
    "pk_test_51HjCPsLALPfGo9G6NrX24y36wjTZfg50wY1u3CLbZsrEXdX6BW2scKpWxxtpcKIkWbklGXlNrczpdG6UbMXngNmv00puh31riZ"
  );

  /*STRIPE*/
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: "#000",
        color: "#31325F",
        fontWeight: "600",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: "25px",
        "::placeholder": {
          color: "#000",
        },
      },
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: "es",
  };

  stripeTest: UntypedFormGroup;
  cropper: ImageCropperComponent;
  cropperSettings: CropperSettings;
  data: any;
  croppedImage: any;
  imageChangedEvent: any = "";
  public fileURL;
  map: google.maps.Map;
  mapProject: google.maps.Map;
  public uploader: FileUploader;
  public uploaderCompanyFile: FileUploader;

  public profilePhoto: boolean;
  public credentials = {
    email: "",
    password: "",
  };

  public profileUrl;
  public branchId;
  public myCompany = {
    name: "",
    stateId: "",
    country: "",
    fileURL: "",
    subCategoriesIds: [],
    categoriesIds: [],
    state: "",
    profileUrl: "",
    countryId: "589261469108562ad9903f62",
    companyInfo: {
      name: "",
      fiscalName: "",
      publicName: "",
      phone: "",
      email: "",
      city: "",
      address: "",
      rfc: "",
      cp: "",
      comments: "",
    },
    id: "",
  };
  public editingCompany = false;
  public datosgrales: Array<any>;
  public usuariosAdmon: Array<string>;
  public managers: Array<string>;
  public sucursalManagers: Array<string>;
  public personal: Array<string>;

  public managers_sales: Array<string>;
  public sucursalManagers_sales: Array<string>;
  public personal_sales: Array<string>;

  public managers_purchases: Array<string>;
  public sucursalManagers_purchases: Array<string>;
  public personal_purchases: Array<string>;
  public tecnician_purchases: Array<string>;

  public project = {
    name: "",
    address: "",
    geopoint: {},
    companyId: localStorage.getItem("companyId"),
    createdById: localStorage.getItem("$LoopBack$currentUserId"),
  };
  public editingProject = false;
  public editingUser = false;
  public editingBranch = false;
  public states = [];
  public countries = [];

  public userRole: any;
  public companyId: any;
  public userId: any;
  public branches: Array<any>;
  public projects: Array<any>;
  public cargos: Array<any>;
  public usuario = {
    personalInfo: {
      name: "",
      firstName: "",
      lastName: "",
    },
    password: "",
    companyId: "",
    email: "",

    role: "",
    seller: false,
    buyer: false,
    canBuy: false,
    canSell: false,
    technicalUser: false,
  };

  public branch = {
    name: "",
    address: "",
    geoPoint: {
      lat: "",
      lng: "",
    },
    salesManagerBranchId: {},
    purchasesManagerBranchId: {},
    generalSalesManagerId: {},
    generalPurchasesManagerId: {},
    radio: 0,
    allCountry: false,
    companyId: "",
  };
  public selectSucursalView = false;
  public selectSucursal = "";

  public selectedPurchasesUser = "";
  public selectedSalesUser = "";

  public selectedPurchasesManagerUser = "";
  public selectedSalesManagerUser = "";
  ventasCheck = false;
  comprasCheck = false;
  canSellCheck = false;
  canBuyCheck = false;
  // NEW
  tecnicoCheck = false;

  public permissionView = true;
  allCountry = false;

  newUserForm: UntypedFormGroup;
  newSucursalForm: UntypedFormGroup;
  newProjectForm: UntypedFormGroup;
  /*MAP VARIABLES*/

  public radioInKm = 1;
  public circleRadio: any;
  public currentLng: any;
  public currentLat: any;
  public marker: any;
  public currentAddress = "";

  /*MAP PROJECT VARIABLES*/
  public currentLngProject: any;
  public currentLatProject: any;
  public markerProject: any;
  public currentAddressProject = "";
  /*--------------------------*/

  public oldPassword;
  public newPassword;
  public confirmedPassword;
  public changePassword = false;
  public paymentType = "month";
  public URL;
  public URLBUCKET;

  constructor(
    private _apiService: ApiService,
    private fb: UntypedFormBuilder,
    public queryFactory: QueryFactory,
    private _router: Router,
    private _route: ActivatedRoute,
    private zone: NgZone,
    private cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService
  ) {
    this.cropperSettings = new CropperSettings();
    this.cropperSettings.width = 100;
    this.cropperSettings.height = 100;
    this.cropperSettings.croppedWidth = 100;
    this.cropperSettings.croppedHeight = 100;
    this.cropperSettings.canvasWidth = 400;
    this.cropperSettings.canvasHeight = 300;

    this.URL = this._apiService.URL;
    this.URLBUCKET = this._apiService.URLBUCKET;
    this.uploader = new FileUploader({ url: this.URL });
    this.uploaderCompanyFile = new FileUploader({ url: this.URL });

    this.data = {};

    this.stripeTest = this.fb.group({
      name: ["", [Validators.required]],
    });
    this.newUserForm = new UntypedFormGroup({
      firstName: new UntypedFormControl("", Validators.required),
      lastName: new UntypedFormControl("", Validators.required),
      email: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$"),
      ]),
      password: new UntypedFormControl("", Validators.required),
      role: new UntypedFormControl("", Validators.required),
      branch: new UntypedFormControl(""),
      ventas: new UntypedFormControl(false),
      compras: new UntypedFormControl(false),
      canSell: new UntypedFormControl(false),
      canBuy: new UntypedFormControl(false),
      technical: new UntypedFormControl(false),
    });
    this.newProjectForm = new UntypedFormGroup({
      projectName: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(4),
      ]),
      project_address: new UntypedFormControl("", Validators.required),
    });

    this.newSucursalForm = new UntypedFormGroup({
      //'name': new FormControl(''),
      suc_name: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(4),
      ]),
      suc_address: new UntypedFormControl("", Validators.required),
      // 'companyId': string,
      sales_manager: new UntypedFormControl("", Validators.required),
      purchases_manager: new UntypedFormControl("", Validators.required),
      suc_sales_manager: new UntypedFormControl(""),
      suc_purchases_manager: new UntypedFormControl(""),
      suc_radio: new UntypedFormControl("", Validators.required),
      suc_all_country: new UntypedFormControl(false),
    });

    this.newUserForm.controls["role"].statusChanges.subscribe((data) => {
      let rol = this.newUserForm.controls["role"].value;
      console.log(rol);
      if (rol === "Sucursal" || rol === "Personal") {
        this.selectSucursalView = true;
        if (this.branches.length === 0) {
          swal({
            title: "Alerta",
            text: "No hay sucursales creadas , Crear una nueva Sucursal antes de continuar",
            type: "info",
            confirmButtonClass: "btn ",

            confirmButtonColor: "#000",
          });
          $('.nav-pills a[href="#pills-sucursal"]').tab("show");
        }
      } else {
        this.selectSucursalView = false;
      }
      if (rol === "Director") {
        this.permissionView = false;
      }
    });

    this.newUserForm.controls["branch"].statusChanges.subscribe((data) => {
      let rol = this.newUserForm.controls["role"].value;
      console.log(rol);
      if (rol === "Sucursal" || rol === "Personal") {
        this.selectSucursalView = true;
      } else {
        this.selectSucursalView = false;
      }
    });
    this.newSucursalForm.controls["suc_radio"].statusChanges.subscribe(
      (data) => {
        this.radioInKm = this.newSucursalForm.controls["suc_radio"].value;
        console.log(this.radioInKm);
        this.initCircleRadio(null);
        this.initCircleRadio(this.radioInKm);
      }
    );

    let timer = null;
    this.newSucursalForm.controls["suc_name"].statusChanges.subscribe(
      (data) => {
        console.log(data);
        if (data === "VALID") {
        }
      }
    );

    if (this._route.snapshot.paramMap.get("id")) {
      let paymentStatus = this._route.snapshot.paramMap.get("id");
      this.spinner.show();
      if (paymentStatus === "success") {
        this.setPaidCompany();
        setTimeout(() => {
          this.spinner.hide();
          swal({
            title: "Correcto!",
            text: "El pago se ha procesado correctamente.",
            type: "success",
            confirmButtonClass: "btn ",
            confirmButtonColor: "#000",
          });
        }, 500);
      } else {
        setTimeout(() => {
          this.spinner.hide();
          swal({
            title: "Error!",
            text: "El pago no se ha podido procesar.",
            type: "error",
            confirmButtonClass: "btn ",
            confirmButtonColor: "#000",
          });
        }, 500);
      }
    }
  }
  async goto() {
    /*  let arrayPrices;
         this._stripe.retrieveSource
         const products = await this.stripe.prices.list({
             limit: 3,
         });
         console.log('------');
         console.log(products.data);
         products.data.forEach(element => {
             arrayPrices.push({
                 price_data: {
                     currency: element.currency,
                     product_data: {
                         name: element.nickname,
                     },
                     unit_amount: element.unit_amount
                 },
                 quantity: 1,
             })
         });
         console.log('----arrayPrices----');
         console.log(arrayPrices); */
  }
  onOptionsSelected(value) {
    console.log(value);
    this.paymentType = value;
    console.log(this.paymentType);
  }
  goToCheckout() {
    /*  this.
         fetch('/create-checkout-session', {
             method: 'POST',
           })
           .then(function(response) {
             return response.json();
           })
           .then(function(session) {
             return stripe.redirectToCheckout({ sessionId: session.id });
           })
           .then(function(result) {
            
             if (result.error) {
               alert(result.error.message);
             }
           })
           .catch(function(error) {
             console.error('Error:', error);
           });
         }); */
    /*         this._stripe.paymentRequest()
        
         */
    let data = {
      price: "price_1HkGP1LALPfGo9G6TJ7xtygw",
    };
    let url;
    console.log(this.paymentType);
    if (this.paymentType === "month") {
      url = "Payments/createCheckoutMonth";
    } else {
      url = "Payments/createCheckout";
    }
    console.log(url);
    this._apiService.addDataObjectCustom(url, data).subscribe(
      (result: any) => {
        console.log(result);
        console.log(result.success.id);
        this.stripe.redirectToCheckout({ sessionId: result.success.id });
      },
      (error) => {
        console.log(error);
      }
    );
  }
  /*   createToken(): void {
        const name = this.stripeTest.get('name').value;
        console.log(this.card)
        this._stripe
            .createToken(this.card.getCard(), { name })
            .subscribe((result) => {
                if (result.token) {
                    // Use the token
                    console.log(result.token.id);
                    const paymentIntent: Payment = {
                        token: result.token.id,
                        amount: 20,
                        currency: "USD",
                        description: "Subscription Year"

                    }
                } else if (result.error) {
                    // Error creating the token
                    console.log(result.error.message);
                }
            });
    } */
  //Method to be invoked everytime we receive a new instance
  //of the address object from the onSelect event emitter.
  setAddress(geoPoint) {
    //We are wrapping this in a NgZone to reflect the changes
    //to the object in the DOM.
    this.zone.run(() => {
      console.log(geoPoint);

      console.log(this.currentLng);
      this.currentLat = geoPoint.lat;
      this.currentLng = geoPoint.lng;
      console.log(this.currentLat);
      console.log(this.currentLng);

      this.map.setCenter(
        new google.maps.LatLng(this.currentLat, this.currentLng)
      );

      /* this.currentLat = position.coords.latitude;
            this.currentLng = position.coords.longitude; */

      let pos = {
        coords: {
          latitude: geoPoint.lat,
          longitude: geoPoint.lng,
        },
      };

      this.showMarkerPosition(pos);
      this.initCircleRadio(null);

      this.initCircleRadio(this.radioInKm);

      this.map.setZoom(12);
    });
  }
  setAddressProject(geoPoint) {
    console.log(geoPoint);
    //We are wrapping this in a NgZone to reflect the changes
    //to the object in the DOM.
    /* const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement, {  });
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
            const place = autocomplete.getPlace();
            console.log(place)
        }); */
    this.zone.run(() => {
      console.log(geoPoint);

      this.currentLatProject = geoPoint.lat;
      this.currentLngProject = geoPoint.lng;
      this.newProjectForm.controls["project_address"].setValue(
        geoPoint.place.formatted_address
      );
      console.log(this.currentLatProject);
      console.log(this.currentLngProject);

      this.mapProject.setCenter(
        new google.maps.LatLng(this.currentLatProject, this.currentLngProject)
      );

      /* this.currentLat = position.coords.latitude;
            this.currentLng = position.coords.longitude; */

      let pos = {
        coords: {
          latitude: geoPoint.lat,
          longitude: geoPoint.lng,
        },
      };

      this.showMarkerPositionProject(pos);

      this.map.setZoom(12);
    });
  }
  companyFileListener($event) {
    this.spinner.show();
    var file: File = $event.target.files[0];
    console.log($event);
    console.log(this.uploaderCompanyFile.queue[0]);
    console.log(this.URLBUCKET + file.name);
    this.fileURL = this.URLBUCKET + file.name;
    this.uploaderCompanyFile.queue[0].upload();
    setTimeout(() => {
      this.spinner.hide();
      swal({
        title: "Correcto!",
        text: "informacion cargada correctamente.",
        type: "success",
        confirmButtonClass: "btn ",

        confirmButtonColor: "#000",
      });
    }, 500);
  }
  fileChangeListener($event) {
    var image: any = new Image();
    var file: File = $event.target.files[0];
    var myReader: FileReader = new FileReader();
    var that = this;
    myReader.onloadend = function (loadEvent: any) {
      image.src = loadEvent.target.result;
      that.cropper.setImage(image);
      console.log(image);
    };
    myReader.readAsDataURL(file);
    this.croppedImage = file;
  }
  openCropperModal() {
    $("#cropperModal").modal("show");
  }
  uploadImage3() {
    this.uploader.queue[0].upload();
  }
  selectedAllCountry(e: any) {
    if (e.target.checked) {
      this.allCountry = true;
    } else {
      this.allCountry = false;
    }
  }
  enableEditCompany() {
    this.editingCompany = true;
  }
  disableEditCompany() {
    this.editingCompany = false;
  }
  selectedVentas(e: any) {
    if (e.target.checked) {
      this.usuario.seller = true;
      this.ventasCheck = true;
    } else {
      this.usuario.seller = false;
      this.ventasCheck = false;
    }
  }
  selectedCompras(e: any) {
    if (e.target.checked) {
      this.usuario.buyer = true;
      this.comprasCheck = true;
    } else {
      this.usuario.buyer = false;
      this.comprasCheck = false;
    }
  }
  selectedTecnico(e: any) {
    if (e.target.checked) {
      this.usuario.technicalUser = true;
      this.tecnicoCheck = true;
    } else {
      this.usuario.technicalUser = false;
      this.tecnicoCheck = false;
    }
  }
  selectedCanSell(e: any) {
    if (e.target.checked) {
      this.usuario.canSell = true;
      this.canSellCheck = true;
    } else {
      this.usuario.canSell = false;
      this.canSellCheck = false;
    }
  }
  selectedCanBuy(e: any) {
    if (e.target.checked) {
      this.usuario.canBuy = true;
      this.canBuyCheck = true;
    } else {
      this.usuario.canBuy = false;
      this.canBuyCheck = false;
    }
  }
  onChangeSales(newValue) {
    this.selectedSalesUser = newValue;
  }
  onChangePurchases(newValue) {
    this.selectedPurchasesUser = newValue;
  }
  onChangeGeneralPurchases(newValue) {
    this.selectedPurchasesManagerUser = newValue;
  }
  onChangeGeneralSales(newValue) {
    this.selectedSalesManagerUser = newValue;
  }
  getUserInfo(type, id, filter) {
    this._apiService.getDataById(type, id, filter).subscribe(
      (result: any) => {
        console.log(result);
        this.usuario.buyer = result.buyer;
        this.usuario.seller = result.seller;
        this.usuario.personalInfo.firstName = result.personalInfo.firstName;
        this.usuario.personalInfo.lastName = result.personalInfo.lastName;
        this.usuario.role = result.role;
        this.usuario.email = result.email;
        this.usuario.canBuy = result.canBuy;
        this.usuario.canSell = result.canSell;
        this.usuario.companyId = result.companyId;
        this.usuario.personalInfo.name =
          this.usuario.personalInfo.firstName +
          " " +
          this.usuario.personalInfo.lastName;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  viewNewPasswordForm() {
    this.changePassword = true;
  }
  cancelPassword() {
    this.changePassword = false;
  }
  updatePassword() {
    if (this.newPassword === this.confirmedPassword) {
      this.spinner.show();
      this.credentials.email = this.usuario.email;
      this.credentials.password = this.oldPassword;

      console.log(this.credentials, "credenciales");
      let filter = {
        filter: {
          where: {
            id: "5ade0eff9b235a7edf66e6ef",
          },
        },
        access_token: "",
      };
      this._apiService
        .loginUser("AppUsers", "login", this.credentials)
        .subscribe(
          (result: any) => {
            this.editPassword(this.userId);
          },
          (error) => {
            this.spinner.hide();
            swal({
              title: "¡Error!",
              text: "Por favor revisa tus datos de acceso",
              type: "error",
              confirmButtonClass: "btn ",

              confirmButtonColor: "#000",
            });

            console.log(error);
          }
        );
    } else {
      console.log(this.newPassword);
      console.log(this.confirmedPassword);

      swal({
        title: "¡Error!",
        text: "Las constraseñas no coinciden",
        type: "error",
        confirmButtonClass: "btn ",

        confirmButtonColor: "#000",
      });
    }
  }
  editPassword(id: any) {
    console.log(id);

    this.usuario.password = this.newPassword;
    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let params = {
      access_token: tokenId,
    };
    console.log(params);
    this._apiService
      .editDataObject("AppUsers", id, this.usuario, params)
      .subscribe(
        (result: any) => {
          console.log(result);
          this.cancelPassword();
          setTimeout(() => {
            this.spinner.hide();
            swal({
              title: "Actualizado!",
              text: "informacion actualizada correctamente.",
              type: "success",
              confirmButtonClass: "btn ",

              confirmButtonColor: "#000",
            });
          }, 500);
        },
        (error) => {
          if (error != null) {
            this.spinner.hide();

            console.log(error);
            setTimeout(() => {
              this.cancelPassword();
              swal({
                title: "Error!",
                text: "Error al actualizar",
                type: "error",
                confirmButtonClass: "btn ",

                confirmButtonColor: "#000",
              });
            }, 250);
          }
        }
      );
  }
  ngOnInit() {
    this.getCountries("Countries", "");
    this.getStates("States", "");
    //this.userRole = localStorage.getItem('$LoopBack$currentUserRole');
    this.companyId = localStorage.getItem("$LoopBack$currentCompanyId");
    this.userId = localStorage.getItem("$LoopBack$currentUserId");
    this.branchId = localStorage.getItem("userBranchId");
    let filter = {
      filter: {
        where: {
          id: "5ade0eff9b235a7edf66e6ef",
        },
      },
      access_token: "",
    };
    this.getUserInfo("AppUsers", this.userId, filter);
    this.getUserInfoRole("AppUsers", this.userId, filter);

    console.log(this.companyId);
    console.log(this.userId);

    this.getUsers("AppUsers", this.companyId);
    this.getBranches("branches", this.companyId);
    this.getProjects("Projects", this.companyId);
    this.getCompanyInfo("Companies", this.companyId);
    //this.getBranchInfo('branches', this.companyId)

    console.log(this.cargos);
    //this.cdr.detectChanges();
    //this.setRememberedTab();
    this.initMap();
    this.initMapProject();
    $("#cropperModal").on("hidden.bs.modal", () => {
      console.log(this.data.image);

      let dataURI = this.data.image;
      var binary = atob(dataURI.split(",")[1]);
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      var array = [];
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      let fileToUpload = new Blob([new Uint8Array(array)], {
        type: mimeString,
      });
      this.uploadCropperImage(fileToUpload);
    });
  }
  getUserInfoRole(type, id, filter) {
    this._apiService.getDataById(type, id, filter).subscribe(
      (result: any) => {
        /*  localStorage.setItem("$LoopBack$currentUserRole", result.role);
                 localStorage.setItem("$LoopBack$currentCompanyId", result.companyId);
                 localStorage.setItem("isSales", result.seller);
                 localStorage.setItem("isPurchases", result.buyer);
                 localStorage.setItem("userBranchId", result.branchId);
                 localStorage.setItem("userName", this.usuario.personalInfo.name); */
        this.userRole = result.role;

        this.populateListRoles(this.userRole);
      },
      (error) => {
        // this.errorMessage = <any>error;
        // if (this.errorMessage != null) {
        console.log(error);
        //
        // }
      }
    );
  }
  uploadCropperImage(file: any) {
    console.log(file);
    let fileItem = new FileItem(this.uploader, file, {});
    //* convert to image*//
    // let dataURI =file;
    //  var dataURItoBlob = function(dataURI) {
    /*      var binary = atob(dataURI.split(',')[1]);
             var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
             var array = [];
             for (var i = 0; i < binary.length; i++) {
               array.push(binary.charCodeAt(i));
             }
             var fileToUpload = new Blob([new Uint8Array(array)], { type: mimeString });
             
               this.uploader.queue.push(file)
               this.uploader.queue[0].upload(); */
    this.uploader.queue.push(fileItem);
    //this.uploader.addToQueue(file);
    console.log(this.uploader.queue);
    this.uploader.queue[0].file.type = "image/jpeg";
    let uuid = UUID.UUID();

    this.uploader.queue[0].file.name = uuid + ".jpg";
    this.uploader.queue[0].upload();
    console.log(uuid + ".jpg");
    this.profileUrl = this.URLBUCKET + uuid + ".jpg";
    localStorage.setItem("$LoopBack$currentUserImage", this.profileUrl);
    //   };
  }
  cropperImage(file: any) {
    console.log(file);
    let fileItem = new FileItem(this.uploader, file, {});

    this.uploader.queue.push(fileItem);
    //this.uploader.addToQueue(file);
    console.log(this.uploader.queue);
    this.uploader.queue[0].file.type = "image/jpeg";
    let uuid = UUID.UUID();

    this.uploader.queue[0].file.name = uuid + ".jpg";
    this.uploader.queue[0].upload();
    console.log(uuid + ".jpg");
    this.profileUrl = this.URLBUCKET + uuid + ".jpg";
    localStorage.setItem("$LoopBack$currentUserImage", this.profileUrl);
    //   };
  }
  initMap() {
    // Create the map.
    var mapProp = {
      center: new google.maps.LatLng(20.651801, -103.405265),
      zoom: 10,

      mapTypeControl: false,
      streetViewControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };

    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    setTimeout(() => {
      this.getUserCurrentPosition();
    }, 1000);
  }
  initMapProject() {
    // Create the map.
    var mapProp = {
      center: new google.maps.LatLng(20.651801, -103.405265),
      zoom: 10,

      mapTypeControl: false,
      streetViewControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };

    this.mapProject = new google.maps.Map(
      this.gmapElementProject.nativeElement,
      mapProp
    );
    setTimeout(() => {
      this.getUserCurrentPosition();
    }, 1000);
  }
  initCircleRadio(radioInKm, center?) {
    if (radioInKm) {
      this.circleRadio = new google.maps.Circle({
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        map: this.map,
        editable: false,
        draggable: false,
        center: new google.maps.LatLng(this.currentLat, this.currentLng),
        radius: radioInKm * 1000,
      });

      this.circleRadio.setMap(this.map);
    } else {
      this.circleRadio.setMap(null);
    }
    google.maps.event.addListener(this.circleRadio, "radius_changed", () => {
      let radio = Math.trunc(this.circleRadio.getRadius() / 1000);
      console.log(radio);
      this.newSucursalForm.controls["suc_radio"].setValue(radio);
    });
  }
  showMarkerPosition(position) {
    this.currentLat = position.coords.latitude;
    this.currentLng = position.coords.longitude;

    let location = new google.maps.LatLng(
      position.coords.latitude,
      position.coords.longitude
    );
    this.map.panTo(location);

    if (!this.marker) {
      this.marker = new google.maps.Marker({
        draggable: true,
        animation: google.maps.Animation.DROP,
        position: location,

        map: this.map,
        title: "Tu Sucursal",
      });
      this.marker.setPosition(location);
      this.marker.addListener("dragend", (event) => {
        console.log(this.currentLng);
        this.currentLat = event.latLng.lat();
        this.currentLng = event.latLng.lng();
        console.log(this.currentLat);
        console.log(this.currentLng);
        let location = new google.maps.LatLng(
          position.coords.latitude,
          position.coords.longitude
        );

        this.map.setCenter(
          new google.maps.LatLng(this.currentLat, this.currentLng)
        );
        this.initCircleRadio(null);
        this.circleRadio.setMap(null);
        this.circleRadio = null;

        this.initCircleRadio(this.radioInKm);

        this.map.setZoom(12);

        var geocoder = new google.maps.Geocoder();
        this.getAddressFromCoords(geocoder, this.map, null);
      });
    } else {
      if (this.circleRadio) {
      }
      this.circleRadio.setMap(null);

      this.marker.setMap(null);
      this.marker = new google.maps.Marker({
        draggable: true,
        animation: google.maps.Animation.DROP,
        position: location,

        map: this.map,
        title: "Tu Sucursal",
      });
      this.marker.setPosition(location);
      this.marker.addListener("dragend", (event) => {
        // console.log(event);

        console.log(this.currentLng);
        this.currentLat = event.latLng.lat();
        this.currentLng = event.latLng.lng();
        console.log(this.currentLat);
        this.map.setCenter(
          new google.maps.LatLng(this.currentLat, this.currentLng)
        );
        this.initCircleRadio(null);
        this.circleRadio.setMap(null);
        this.circleRadio = null;

        this.initCircleRadio(this.radioInKm);

        this.map.setZoom(12);

        console.log(this.currentLng);
        var geocoder = new google.maps.Geocoder();
        this.getAddressFromCoords(geocoder, this.map, null);
      });
    }
  }
  showMarkerPositionProject(position) {
    this.currentLatProject = position.coords.latitude;
    this.currentLngProject = position.coords.longitude;

    let location = new google.maps.LatLng(
      position.coords.latitude,
      position.coords.longitude
    );
    this.mapProject.panTo(location);

    if (!this.markerProject) {
      this.markerProject = new google.maps.Marker({
        draggable: true,
        animation: google.maps.Animation.DROP,
        position: location,

        map: this.mapProject,
        title: "Tu Sucursal",
      });
      this.markerProject.setPosition(location);
      this.markerProject.addListener("dragend", (event) => {
        this.currentLatProject = event.latLng.lat();
        this.currentLngProject = event.latLng.lng();

        let location = new google.maps.LatLng(
          position.coords.latitude,
          position.coords.longitude
        );

        this.mapProject.setCenter(
          new google.maps.LatLng(this.currentLatProject, this.currentLngProject)
        );

        this.map.setZoom(12);

        var geocoder = new google.maps.Geocoder();
        this.getAddressFromCoords(geocoder, this.mapProject, null);
      });
    } else {
      this.marker.setMap(null);
      this.marker = new google.maps.Marker({
        draggable: true,
        animation: google.maps.Animation.DROP,
        position: location,

        map: this.map,
        title: "Tu Sucursal",
      });
      this.markerProject.setPosition(location);
      this.markerProject.addListener("dragend", (event) => {
        // console.log(event);

        this.currentLatProject = event.latLng.lat();
        this.currentLngProject = event.latLng.lng();
        console.log(this.currentLatProject);
        this.mapProject.setCenter(
          new google.maps.LatLng(this.currentLatProject, this.currentLngProject)
        );

        this.map.setZoom(12);

        var geocoder = new google.maps.Geocoder();
        this.getAddressFromCoords(geocoder, this.mapProject, null);
      });
    }
  }
  getUserPosition() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          //this.geoLocationEnabled=true;

          console.log(position);
          this.showMarkerPosition(position);
          var geocoder = new google.maps.Geocoder();

          this.getAddressFromCoords(geocoder, this.map, position);
        },
        (error) => {
          // this.geoLocationEnabled=false;
          if (error.code == error.PERMISSION_DENIED)
            swal({
              title: "Advertencia!",
              text: "Importante, la geolocalización es necesaria para el funcionamiento de esta plataforma, para continuar por favor otorga los permisos al navegador y refresca la pagina.",
              type: "error",
              confirmButtonClass: "btn ",

              confirmButtonColor: "#000",
            });
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }
  getUserCurrentPosition() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position);
        console.log(position);
        this.showMarkerPosition(position);
        this.currentLat = position.coords.latitude;
        this.currentLng = position.coords.longitude;
        let latlng = { lat: this.currentLat, lng: this.currentLng };
        this.initCircleRadio(5);

        //this.map.setCenter(latlng);
        var geocoder = new google.maps.Geocoder();

        this.getAddressFromCoords(geocoder, this.map, position);
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  getAddressFromCoords(geocoder, map, position) {
    let latlng = { lat: this.currentLat, lng: this.currentLng };

    geocoder.geocode({ location: latlng }, (results, status) => {
      console.log(results);
      if (status === "OK") {
        if (results) {
          this.currentAddress = results[0].formatted_address;
          this.newSucursalForm.controls["suc_address"].setValue(
            this.currentAddress
          );
          console.log(this.currentAddress);
        } else {
          window.alert("No results found");
        }
      } else {
        window.alert("Geocoder failed due to: " + status);
      }
    });
  }
  populateListRoles(role) {
    console.log(role);
    switch (role) {
      case "Director": {
        this.cargos = ["Gerente", "Sucursal", "Personal"];
        break;
      }
      case "Gerente": {
        this.cargos = ["Personal", "Sucursal"];

        break;
      }
      case "Sucursal": {
        this.cargos = ["Personal"];

        break;
      }
      case "Personal": {
        this.cargos = ["Personal"];

        break;
      }
      default: {
        this.cargos = [];
        break;
      }
    }
  }

  beforeSaveEditUser() {
    console.log(this.editingUser);
    console.log(this.usuario, "data");
    console.log(this.newUserForm.value.role);
    if (this.editingUser) {
      if (
        this.newUserForm.value.role === "Personal" ||
        this.newUserForm.value.role === "Sucursal"
      ) {
        if (this.newUserForm.value.branch) {
          this.usuario["branchId"] = this.newUserForm.value.branch;
          this.onEditUserConfirm(this.usuario);
        } else {
          swal({
            title: "Alerta!",
            text: "Favor de completar la sucursal del usuario",
            type: "info",
            confirmButtonClass: "btn ",

            confirmButtonColor: "#000",
          });
        }
      } else {
        this.onEditUserConfirm(this.usuario);
      }
    } else {
      this.saveUser();
    }

    /*     } else {
                swal(
                    {
                        title: 'Alerta!',
                        text: 'Favor de completar la informacion del usuario',
                        type: 'info',
                        confirmButtonClass: 'btn ',

                        confirmButtonColor: '#000'
                    });

            } */
  }
  onBeforeEditUser(user) {
    console.log(user);

    this.usuario = user;
    this.editingUser = true;
    this.newUserForm.controls["firstName"].setValue(
      user.personalInfo.firstName
    );
    this.newUserForm.controls["lastName"].setValue(user.personalInfo.lastName);
    this.newUserForm.controls["password"].setValue("     ");

    this.newUserForm.controls["email"].setValue(user.email);
    this.newUserForm.controls["role"].setValue(user.role);
    this.newUserForm.controls["compras"].setValue(user.compras);
    this.newUserForm.controls["ventas"].setValue(user.ventas);
    if (user.buyer) {
      $("#compras").prop("checked", true);
      this.comprasCheck = true;
    }
    if (user.seller) {
      this.ventasCheck = true;

      $("#ventas").prop("checked", true);
    }
    if (user.canBuy) {
      $("#canBuy").prop("checked", true);
      this.canBuyCheck = true;
    }
    if (user.canSell) {
      this.canSellCheck = true;

      $("#canSell").prop("checked", true);
    }
  }

  cancelEditUser() {
    this.newUserForm.reset();
    $("#compras").prop("checked", false);
    $("#ventas").prop("checked", false);
    this.editingUser = false;
  }

  onEditUserConfirm(user: any) {
    let userToEdit: any = user;
    console.log(userToEdit);

    swal({
      title: "¿Estas seguro ?",
      text: "Editar Informacion",
      type: "info",
      showCancelButton: true,
      confirmButtonColor: "#000",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si!",
      confirmButtonClass: "btn ",

      cancelButtonClass: "btn btn-danger",
      buttonsStyling: false,
    }).then((result) => {
      console.log(result);
      if (result.value) {
        this.editUser(userToEdit.id);
      } else if (result.dismiss) {
        swal({
          title: "Cancelado",
          text: "No se realizaron cambios",
          type: "info",
          confirmButtonClass: "btn ",

          confirmButtonColor: "#000",
        });
      }
    });
  }

  editUser(id: any) {
    this.spinner.show();
    console.log(id);

    this.usuario.buyer = this.newUserForm.value.compras;
    this.usuario.seller = this.newUserForm.value.ventas;
    this.usuario.personalInfo.firstName = this.newUserForm.value.firstName;
    this.usuario.personalInfo.lastName = this.newUserForm.value.lastName;
    //  this.usuario.password = this.newUserForm.value.password;
    this.usuario.role = this.newUserForm.value.role;
    this.usuario.email = this.newUserForm.value.email;
    this.usuario.seller = this.ventasCheck;
    this.usuario.buyer = this.comprasCheck;
    this.usuario.canBuy = this.canBuyCheck;
    this.usuario.canSell = this.canSellCheck;
    this.usuario.technicalUser = this.tecnicoCheck;

    this.usuario.companyId = this.companyId;
    if (
      this.newUserForm.value.role === "personal" ||
      this.newUserForm.value.role === "sucursal"
    ) {
      if (this.newUserForm.value.branch) {
        this.usuario["branchId"] = this.newUserForm.value.branch;
      }
    }

    if (!this.permissionView) {
      this.usuario.canSell = true;
      this.usuario.canBuy = true;
    }

    this.usuario.personalInfo.name =
      this.usuario.personalInfo.firstName +
      " " +
      this.usuario.personalInfo.lastName;
    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let params = {
      access_token: tokenId,
    };
    console.log(params);

    this._apiService
      .editDataObject("AppUsers", id, this.usuario, params)
      .subscribe(
        (result: any) => {
          console.log(result);
          this.cancelEditProject();
          setTimeout(() => {
            this.newUserForm.reset();
            this.editingUser = false;
            $("#compras").prop("checked", false);
            this.comprasCheck = false;
            $("#ventas").prop("checked", false);
            this.ventasCheck = false;
            this.spinner.hide();
            swal({
              title: "Actualizado!",
              text: "informacion actualizada correctamente.",
              type: "success",
              confirmButtonClass: "btn ",

              confirmButtonColor: "#000",
            });

            this.getUsers("AppUsers", this.companyId);
          }, 500);
        },
        (error) => {
          if (error != null) {
            this.spinner.hide();
            this.newUserForm.reset();
            this.editingUser = false;
            $("#compras").prop("checked", false);
            this.comprasCheck = false;
            $("#ventas").prop("checked", false);
            this.ventasCheck = false;
            console.log(error);
            setTimeout(() => {
              this.cancelEditUser();
              swal({
                title: "Error!",
                text: "Error al actualizar",
                type: "error",
                confirmButtonClass: "btn ",

                confirmButtonColor: "#000",
              });
            }, 250);
          }
        }
      );
  }
  saveUser() {
    this.spinner.show();
    console.log(this.newUserForm.value);
    let usuario = {
      personalInfo: {
        name: "",
        firstName: "",
        lastName: "",
      },
      companyId: "",
      email: "",
      password: "",
      role: "",
      seller: false,
      buyer: false,
      canBuy: false,
      canSell: false,
      technicalUser: false,

      branchId: "",
    };
    console.log(this.newUserForm.value.branch);
    usuario.companyId = localStorage.getItem("$LoopBack$currentCompanyId");

    usuario.buyer = this.newUserForm.value.compras;
    usuario.seller = this.newUserForm.value.ventas;
    usuario.personalInfo.firstName = this.newUserForm.value.firstName;
    usuario.personalInfo.lastName = this.newUserForm.value.lastName;
    usuario.password = this.newUserForm.value.password;
    usuario.role = this.newUserForm.value.role;
    usuario.email = this.newUserForm.value.email;
    usuario.seller = this.ventasCheck;
    usuario.buyer = this.comprasCheck;
    usuario.canBuy = this.canBuyCheck;
    usuario.canSell = this.canSellCheck;
    usuario.technicalUser = this.tecnicoCheck;

    if (!this.permissionView) {
      this.usuario.canSell = true;
      this.usuario.canBuy = true;
    }

    usuario.companyId = this.companyId;
    usuario.branchId = this.newUserForm.value.branch;

    usuario.personalInfo.name =
      usuario.personalInfo.firstName + " " + usuario.personalInfo.lastName;
    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let params = {
      token: tokenId,
    };
    console.log(usuario, "USUARIO A GUARDAR");
    this._apiService.addDataObject("AppUsers", usuario).subscribe(
      (result: any) => {
        console.log(result);
        setTimeout(() => {
          this.spinner.hide();
          swal({
            title: "Guardado!",
            text: "Cambios guardados correctamente.",
            type: "success",
            confirmButtonClass: "btn ",

            confirmButtonColor: "#000",
          });
          this.getUsers("AppUsers", this.companyId);

          this.newUserForm.reset();
          this.comprasCheck = false;
          this.ventasCheck = false;
        }, 500);
      },
      (error) => {
        if (error != null) {
          setTimeout(() => {
            this.spinner.hide();
            swal({
              title: "Error!",
              text: "Error al guardar.",
              type: "error",
              confirmButtonClass: "btn ",

              confirmButtonColor: "#000",
            });
            this.newUserForm.reset();
            this.comprasCheck = false;
            this.ventasCheck = false;
          }, 250);

          console.log(error);
        }
      }
    );
  }
  uploadImage(file?: any) {
    console.log(this.uploader.queue);
    this.uploader.queue[0].file.type = "image/jpeg";
    let uuid = UUID.UUID();

    this.uploader.queue[0].file.name = uuid + ".jpg";
    this.myCompany.profileUrl = this.URLBUCKET + uuid + ".jpg";
    this.uploader.queue[0].upload();
    console.log(uuid + ".jpg");
  }
  getCompanyInfo(type, companyId) {
    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");

    let params = {
      filter: { include: ["state", "country"] },
      access_token: tokenId,
    };
    this._apiService.getDataById(type, companyId, params).subscribe(
      (result: any) => {
        console.log(result);
        this.stripeTest.controls["name"].setValue(result.name);
        this.myCompany.name = result.name;
        this.myCompany.companyInfo.fiscalName = result.companyInfo.fiscalName;
        this.myCompany.companyInfo.email = result.companyInfo.email;
        this.myCompany.companyInfo.phone = result.companyInfo.phone;
        //this.myCompany.companyInfo.stateId = result.companyInfo.state;
        this.myCompany.categoriesIds = result.categoriesIds;
        this.myCompany.subCategoriesIds = result.subCategoriesIds;

        this.myCompany.companyInfo.phone = result.companyInfo.phone;

        this.myCompany.companyInfo.address = result.companyInfo.address;
        this.myCompany.companyInfo.comments = result.companyInfo.comments;
        this.myCompany.companyInfo.rfc = result.companyInfo.rfc;
        this.myCompany.companyInfo.cp = result.companyInfo.cp;
        this.myCompany.profileUrl = result.profileUrl;
        if (this.myCompany.profileUrl === "") {
          this.profilePhoto = false;
        } else {
          this.profilePhoto = true;
        }
        if (result.country) {
          this.myCompany.country = result.country.name;
        }
        if (result.state) {
          this.myCompany.state = result.state.name;
        }

        this.myCompany.companyInfo.city = result.companyInfo.city;
        this.myCompany.id = result.id;
        console.log(this.myCompany);
      },
      (error) => {
        if (error != null) {
          console.log(error);
        }
      }
    );
  }
  processCategorization(data) {
    var idsCategorization;
    let companyId = localStorage.getItem("$LoopBack$currentCompanyId");

    console.log(data);
    let obj = {
      subCategoriesIds: data.sub,
      categoriesIds: data.cat,
    };
    this.patchSubcategoriesCompany(companyId, obj);
  }
  setPaidCompany() {
    let companyId = localStorage.getItem("$LoopBack$currentCompanyId");

    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let params = {
      access_token: tokenId,
    };
    this._apiService
      .editDataObject("Companies", companyId, { isActive: true }, params)
      .subscribe(
        (result: any) => {
          console.log(result);
        },
        (error) => {
          if (error != null) {
            console.log(error);
            /*  this.disableEditCompany();

                     this.cancelEditUser();
                     swal({
                         title: 'Error!',
                         text: 'Error al actualizar',
                         type: 'error',
                         confirmButtonClass: 'btn ',

                         confirmButtonColor: '#000'
                     }) */
          }
        }
      );
  }
  patchSubcategoriesCompany(companyId, data) {
    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let params = {
      access_token: tokenId,
    };
    this._apiService
      .editDataObject("Companies", companyId, data, params)
      .subscribe(
        (result: any) => {
          console.log(result);
        },
        (error) => {
          if (error != null) {
            console.log(error);
            /*  this.disableEditCompany();

                     this.cancelEditUser();
                     swal({
                         title: 'Error!',
                         text: 'Error al actualizar',
                         type: 'error',
                         confirmButtonClass: 'btn ',

                         confirmButtonColor: '#000'
                     }) */
          }
        }
      );
  }
  editCompany(company: any) {
    this.spinner.show();
    console.log(company);
    let id = company.id;
    console.log(id);
    /*  if (this.uploader.queue[0]) {
             this.uploadImage();
         } */
    this.myCompany.fileURL = this.fileURL;

    this.myCompany.profileUrl = this.profileUrl;
    this.myCompany.stateId = this.myCompany.stateId;

    console.log(this.myCompany);
    setTimeout(() => {
      let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
      let params = {
        access_token: tokenId,
      };
      console.log(params);
      this._apiService
        .editDataObject("Companies", id, this.myCompany, params)
        .subscribe(
          (result: any) => {
            console.log(result);
            this.disableEditCompany();
            setTimeout(() => {
              this.spinner.hide();
              this.getCompanyInfo("Companies", this.companyId);
              swal({
                title: "Actualizado!",
                text: "informacion actualizada correctamente.",
                type: "success",
                confirmButtonClass: "btn ",

                confirmButtonColor: "#000",
              });
            }, 250);
          },
          (error) => {
            if (error != null) {
              this.spinner.hide();

              setTimeout(() => {
                this.disableEditCompany();

                this.cancelEditUser();
                swal({
                  title: "Error!",
                  text: "Error al actualizar",
                  type: "error",
                  confirmButtonClass: "btn ",

                  confirmButtonColor: "#000",
                });
              }, 250);
            }
          }
        );
    }, 500);
  }
  onDeleteUserConfirm(project: any) {
    console.log(project);
    swal({
      title: "¿Estas seguro ?",
      text: "Esta accion es irreversible",
      type: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, Eliminar!",
      confirmButtonClass: "btn btn-success",
      cancelButtonClass: "btn btn-danger",
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        this.deleteUser(project);
        // result.dismiss can be 'cancel', 'overlay',
        //'close', and 'timer'
      } else if (result.dismiss) {
        swal({
          title: "Cancelado",
          text: "No se realizaron cambios",
          type: "info",
          confirmButtonClass: "btn ",

          confirmButtonColor: "#000",
        });
      }
    });
  }

  deleteUser(id: any) {
    this.spinner.show();
    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let params = {
      access_token: tokenId,
    };
    console.log(params);
    this._apiService.deleteDataObject("AppUsers", id, params).subscribe(
      (result: any) => {
        console.log(result);

        setTimeout(() => {
          this.spinner.hide();
          swal({
            title: "Eliminado!",
            text: "se ha eliminado correctamente.",
            type: "success",
            confirmButtonClass: "btn ",

            confirmButtonColor: "#000",
          });

          this.getUsers("AppUsers", this.companyId);
        }, 500);
      },
      (error) => {
        if (error != null) {
          this.spinner.hide();
          console.log(error);
          setTimeout(() => {
            swal({
              title: "Error!",
              text: "Error al eliminar",
              type: "error",
              confirmButtonClass: "btn ",

              confirmButtonColor: "#000",
            });
          }, 250);
        }
      }
    );
  }
  saveBranch() {
    this.spinner.show();
    console.log(this.newSucursalForm.value);
    this.branch.name = this.newSucursalForm.value.suc_name;
    this.branch.address = this.newSucursalForm.value.suc_address;
    this.branch.geoPoint.lat = this.currentLat;
    this.branch.geoPoint.lng = this.currentLng;
    this.branch.salesManagerBranchId = this.selectedSalesUser;
    this.branch.purchasesManagerBranchId = this.selectedPurchasesUser;
    this.branch.generalPurchasesManagerId = this.selectedPurchasesManagerUser;
    this.branch.generalSalesManagerId = this.selectedSalesManagerUser;

    this.branch.radio = this.newSucursalForm.value.suc_radio;
    this.branch.allCountry = this.allCountry;
    this.branch.companyId = this.companyId;
    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let params = {
      token: tokenId,
    };
    this._apiService.addDataObject("branches", this.branch).subscribe(
      (result: any) => {
        console.log(result);
        setTimeout(() => {
          this.getBranches("branches", this.companyId);
          this.spinner.hide();
          swal({
            title: "Guardado!",
            text: "Cambios guardados correctamente.",
            type: "success",
            confirmButtonClass: "btn ",

            confirmButtonColor: "#000",
          });
          this.newSucursalForm.reset();
          this.initMap();
        }, 500);
      },
      (error) => {
        if (error != null) {
          console.log(error);
          setTimeout(() => {
            this.spinner.hide();
            swal({
              title: "Error!",
              text: "Error al Guardar.",
              type: "error",
              confirmButtonClass: "btn ",

              confirmButtonColor: "#000",
            });
            this.newSucursalForm.reset();

            this.initMap();
          }, 250);
        }
      }
    );
  }
  beforeSaveEditBranch() {
    this.branch.allCountry = this.allCountry;
    console.log(this.editingBranch);
    console.log(this.branch, "data");
    if (this.editingBranch) {
      this.onEditBranchConfirm(this.branch);
    } else {
      this.saveBranch();
    }
  }
  onBeforeEditBranch(branch) {
    console.log(branch);
    this.branch = branch;
    this.editingBranch = true;

    this.newSucursalForm.controls["suc_name"].setValue(this.branch.name);
    this.newSucursalForm.controls["suc_address"].setValue(this.branch.address);
    this.newSucursalForm.controls["sales_manager"].setValue(
      this.branch.generalSalesManagerId
    );
    this.newSucursalForm.controls["purchases_manager"].setValue(
      this.branch.generalPurchasesManagerId
    );

    this.newSucursalForm.controls["suc_sales_manager"].setValue(
      this.branch.salesManagerBranchId
    );
    this.newSucursalForm.controls["suc_purchases_manager"].setValue(
      this.branch.purchasesManagerBranchId
    );
    this.newSucursalForm.controls["suc_radio"].setValue(this.branch.radio);
    this.newSucursalForm.controls["suc_all_country"].setValue(
      this.branch.allCountry
    );
    if (branch.allCountry) {
      $("#all_country").prop("checked", true);
      this.allCountry = true;
    }
  }

  cancelEditBranch() {
    this.newSucursalForm.reset();

    this.editingBranch = false;
  }

  onEditBranchConfirm(branch: any) {
    let branchtoEdit: any = branch;
    console.log(branchtoEdit);
    branchtoEdit.allCountry = this.allCountry;

    swal({
      title: "¿Estas seguro ?",
      text: "Editar Informacion",
      type: "info",
      showCancelButton: true,
      confirmButtonColor: "#000",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si!",
      confirmButtonClass: "btn ",
      cancelButtonClass: "btn btn-danger",
      buttonsStyling: false,
    }).then((result) => {
      console.log(result);
      if (result.value) {
        this.editBranch(branchtoEdit.id, branchtoEdit);
      } else if (result.dismiss) {
        swal({
          title: "Cancelado",
          text: "No se realizaron cambios",
          type: "info",
          confirmButtonClass: "btn ",

          confirmButtonColor: "#000",
        });
      }
    });
  }
  editBranch(id: any, branch) {
    this.spinner.show();
    console.log(id);

    this.branch.name = this.newSucursalForm.value.suc_name;
    this.branch.address = this.newSucursalForm.value.suc_address;
    this.branch.geoPoint.lat = this.currentLat;
    this.branch.geoPoint.lng = this.currentLng;
    this.branch.salesManagerBranchId = this.selectedSalesUser;
    this.branch.purchasesManagerBranchId = this.selectedPurchasesUser;
    this.branch.generalPurchasesManagerId = this.selectedPurchasesManagerUser;
    this.branch.generalSalesManagerId = this.selectedSalesManagerUser;
    this.branch.radio = this.newSucursalForm.value.suc_radio;
    this.branch.allCountry = branch.allCountry;

    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let params = {
      access_token: tokenId,
    };
    console.log(params);
    this._apiService
      .editDataObject("branches", id, this.branch, params)
      .subscribe(
        (result: any) => {
          console.log(result);
          this.cancelEditProject();
          setTimeout(() => {
            this.newSucursalForm.reset();

            this.editingBranch = false;
            this.newSucursalForm.controls["suc_address"].setValue("");

            this.spinner.hide();
            swal({
              title: "Actualizado!",
              text: "informacion actualizada correctamente.",
              type: "success",
              confirmButtonClass: "btn ",

              confirmButtonColor: "#000",
            });
            this.getBranches("branches", this.companyId);
          }, 500);
        },
        (error) => {
          if (error != null) {
            this.spinner.hide();
            this.newSucursalForm.reset();
            this.editingBranch = false;

            console.log(error);
            setTimeout(() => {
              this.cancelEditUser();
              swal({
                title: "Error!",
                text: "Error al actualizar",
                type: "error",
                confirmButtonClass: "btn ",

                confirmButtonColor: "#000",
              });
            }, 250);
          }
        }
      );
  }
  getBranchInfo(type, branch) {
    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");

    let params = {
      filter: { include: ["state", "country"] },
      access_token: tokenId,
    };
    this._apiService.getDataById(type, branch, params).subscribe(
      (result: any) => {
        console.log(result);
        this.myCompany.name = result.name;
        this.myCompany.companyInfo.fiscalName = result.companyInfo.fiscalName;
        this.myCompany.companyInfo.address = result.companyInfo.address;
        this.myCompany.companyInfo.comments = result.companyInfo.comments;
        this.myCompany.companyInfo.rfc = result.companyInfo.rfc;
        this.myCompany.companyInfo.cp = result.companyInfo.cp;
        this.myCompany.profileUrl = result.profileUrl;
        if (this.myCompany.profileUrl === "") {
          this.profilePhoto = false;
        } else {
          this.profilePhoto = true;
        }
        if (result.country) {
          this.myCompany.country = result.country.name;
        }
        if (result.state) {
          this.myCompany.state = result.state.name;
        }

        this.myCompany.companyInfo.city = result.companyInfo.city;
        this.myCompany.id = result.id;
        console.log(this.myCompany.id);
      },
      (error) => {
        if (error != null) {
          console.log(error);
        }
      }
    );
  }
  onDeleteBranchConfirm(branch: any) {
    console.log(branch);
    swal({
      title: "¿Estas seguro ?",
      text: "Esta accion es irreversible",
      type: "info",
      showCancelButton: true,
      confirmButtonColor: "#000",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, Eliminar!",
      confirmButtonClass: "btn ",
      cancelButtonClass: "btn btn-danger",
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        this.deleteBranch(branch);
        // result.dismiss can be 'cancel', 'overlay',
        //'close', and 'timer'
      } else if (result.dismiss) {
        swal({
          title: "Cancelado",
          text: "No se realizaron cambios",
          type: "info",
          confirmButtonClass: "btn ",

          confirmButtonColor: "#000",
        });
      }
    });
  }

  deleteBranch(id: any) {
    this.spinner.show();
    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let params = {
      access_token: tokenId,
    };
    console.log(params);
    this._apiService.deleteDataObject("branches", id, params).subscribe(
      (result: any) => {
        console.log(result);

        setTimeout(() => {
          this.spinner.hide();
          swal({
            title: "Eliminado!",
            text: "se ha eliminado correctamente.",
            type: "success",
            confirmButtonClass: "btn ",

            confirmButtonColor: "#000",
          });

          this.getBranches("branches", this.companyId);
        }, 500);
      },
      (error) => {
        if (error != null) {
          this.spinner.hide();
          console.log(error);
          setTimeout(() => {
            swal({
              title: "Error!",
              text: "Error al eliminar",
              type: "error",
              confirmButtonClass: "btn ",

              confirmButtonColor: "#000",
            });
          }, 250);
        }
      }
    );
  }
  beforeSaveEditProject() {
    console.log(this.project, "data");
    if (this.editingProject) {
      this.onEditProjectConfirm(this.project);
    } else {
      this.saveProject(this.project);
    }
  }
  saveProject(form: any) {
    let geo = {
      lat: this.currentLatProject,
      lng: this.currentLngProject,
    };
    /* this.branch.address = this.newSucursalForm.value.suc_address;
        this.branch.geoPoint.lat = this.currentLat;
        this.branch.geoPoint.lng = this.currentLng; */
    this.project = {
      address: this.newProjectForm.value.project_address,
      name: this.newProjectForm.value.projectName,
      geopoint: geo,
      companyId: localStorage.getItem("$LoopBack$currentCompanyId"),
      createdById: localStorage.getItem("$LoopBack$currentUserId"),
    };
    this.spinner.show();
    console.log(this.project);
    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let params = {
      access_token: tokenId,
    };
    this._apiService.addDataObject("Projects", this.project).subscribe(
      (result: any) => {
        console.log(result);
        setTimeout(() => {
          this.spinner.hide();
          this.newProjectForm.reset();
          this.getProjects("Projects", this.companyId);
          swal({
            title: "Guardado!",
            text: "Cambios guardados correctamente.",
            type: "success",
            confirmButtonClass: "btn ",
            confirmButtonColor: "#000",
          });

          this.marker.setMap(null);
        }, 500);
      },
      (error) => {
        if (error != null) {
          console.log(error);
          setTimeout(() => {
            this.spinner.hide();
            swal({
              title: "Error!",
              text: "Error al guardar",
              type: "error",
              confirmButtonClass: "btn ",

              confirmButtonColor: "#000",
            });
          }, 250);
        }
      }
    );
  }
  onBeforeEditProject(project) {
    console.log(project);
    // this.project.name  = this.newProjectForm.value.projectName,
    this.project = project;
    this.editingProject = true;
    this.newProjectForm.controls["projectName"].setValue(project.name);
    this.newProjectForm.controls["project_address"].setValue(project.address);
  }

  cancelEditProject() {
    this.newProjectForm.reset();
    this.editingProject = false;
  }

  onEditProjectConfirm(project: any) {
    let projectToEdit: any = project;
    console.log(projectToEdit);

    swal({
      title: "¿Estas seguro ?",
      text: "Editar Informacion",
      type: "info",
      showCancelButton: true,
      confirmButtonColor: "#000",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si!",
      confirmButtonClass: "btn ",
      cancelButtonClass: "btn btn-danger",
      buttonsStyling: false,
    }).then((result) => {
      console.log(result);
      if (result.value) {
        this.editProject(projectToEdit.id);

        // result.dismiss can be 'cancel', 'overlay',
        // 'close', and 'timer'
      } else if (result.dismiss) {
        swal({
          title: "Cancelado",
          text: "No se realizaron cambios",
          type: "info",
          confirmButtonClass: "btn ",

          confirmButtonColor: "#000",
        });
      }
    });
  }

  editProject(id: any) {
    this.spinner.show();
    console.log(id);
    console.log(this.project);
    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let params = {
      access_token: tokenId,
    };
    let geo = {
      lat: this.currentLatProject,
      lng: this.currentLngProject,
    };
    console.log(params);
    /*   this.project.name = this.newProjectForm.value.projectName; */
    this.project = {
      address: this.newProjectForm.value.project_address,
      name: this.newProjectForm.value.projectName,
      geopoint: geo,
      companyId: localStorage.getItem("$LoopBack$currentCompanyId"),
      createdById: localStorage.getItem("$LoopBack$currentUserId"),
    };
    this._apiService
      .editDataObject("Projects", id, this.project, params)
      .subscribe(
        (result: any) => {
          console.log(result);
          this.cancelEditProject();
          setTimeout(() => {
            this.newProjectForm.reset();

            this.spinner.hide();
            swal({
              title: "Actualizado!",
              text: "informacion actualizada correctamente.",
              type: "success",
              confirmButtonClass: "btn ",

              confirmButtonColor: "#000",
            });

            this.getProjects("Projects", this.companyId);
          }, 500);
        },
        (error) => {
          if (error != null) {
            this.spinner.hide();
            this.newProjectForm.reset();

            console.log(error);
            setTimeout(() => {
              this.cancelEditProject();
              swal({
                title: "Error!",
                text: "Error al actualizar",
                type: "error",
                confirmButtonClass: "btn ",

                confirmButtonColor: "#000",
              });
            }, 250);
          }
        }
      );
  }

  onDeleteProjectConfirm(project: any) {
    console.log(project);
    swal({
      title: "¿Estas seguro ?",
      text: "Esta accion es irreversible",
      type: "info",
      showCancelButton: true,
      confirmButtonColor: "#000",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, Eliminar!",
      confirmButtonClass: "btn ",
      cancelButtonClass: "btn btn-danger",
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        this.deleteProject(project);
        // result.dismiss can be 'cancel', 'overlay',
        //'close', and 'timer'
      } else if (result.dismiss) {
        swal({
          title: "Cancelado",
          text: "No se realizaron cambios",
          type: "info",
          confirmButtonClass: "btn ",

          confirmButtonColor: "#000",
        });
      }
    });
  }

  deleteProject(id: any) {
    this.spinner.show();
    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let params = {
      access_token: tokenId,
    };
    console.log(params);
    this._apiService.deleteDataObject("Projects", id, params).subscribe(
      (result: any) => {
        console.log(result);

        setTimeout(() => {
          this.spinner.hide();
          swal({
            title: "Eliminado!",
            text: "se ha eliminado correctamente.",
            type: "success",
            confirmButtonClass: "btn ",

            confirmButtonColor: "#000",
          });

          this.project = {
            name: "",
            address: "",
            companyId: localStorage.getItem("companyId"),
            createdById: localStorage.getItem("$LoopBack$currentUserId"),
            geopoint: {},
          };
          this.getProjects("Projects", this.companyId);
        }, 500);
      },
      (error) => {
        if (error != null) {
          this.spinner.hide();
          console.log(error);
          setTimeout(() => {
            swal({
              title: "Error!",
              text: "Error al eliminar",
              type: "error",
              confirmButtonClass: "btn ",

              confirmButtonColor: "#000",
            });
          }, 250);
        }
      }
    );
  }
  
  formatDataUsers(users) {
    this.managers_sales = [];
    this.managers_purchases = [];
    this.sucursalManagers_purchases = [];
    this.sucursalManagers_sales = [];
    this.tecnician_purchases = [];

    this.personal_purchases = [];
    this.personal_sales = [];

    users.forEach((user, index) => {
      console.log(user);
      switch (user.role) {
        case "Director": {
          break;
        }
        case "Gerente": {
          if (user.seller === true && user.buyer === true) {
            if (this.managers_sales) {
              this.managers_sales.push(user);
            } else {
              this.managers_sales = [user];
            }
            if (this.managers_purchases) {
              this.managers_purchases.push(user);
            } else {
              this.managers_purchases = [user];
            }
          } else if (user.seller === true || user.canSell === true) {
            if (this.managers_sales) {
              this.managers_sales.push(user);
            } else {
              this.managers_sales = [user];
            }
          } else if (user.buyer === true || user.canBuy === true) {
            if (this.managers_purchases) {
              this.managers_purchases.push(user);
            } else {
              this.managers_purchases = [user];
            }
          } else if (user.technicalUser) {
            if (this.tecnician_purchases) {
              this.tecnician_purchases.push(user);
            } else {
              this.tecnician_purchases = [user];
            }
          }

          break;
        }
        case "Sucursal": {
          if (user.seller === true && user.buyer === true) {
            if (this.sucursalManagers_sales) {
              this.sucursalManagers_sales.push(user);
            } else {
              this.sucursalManagers_sales = [user];
            }
            if (this.sucursalManagers_purchases) {
              this.sucursalManagers_purchases.push(user);
            } else {
              this.sucursalManagers_purchases = [user];
            }
          } else if (user.seller === true || user.canSell === true) {
            if (this.sucursalManagers_sales) {
              this.sucursalManagers_sales.push(user);
            } else {
              this.sucursalManagers_sales = [user];
            }
          } else if (user.buyer === true || user.canBuy === true) {
            if (this.sucursalManagers_purchases) {
              this.sucursalManagers_purchases.push(user);
            } else {
              this.sucursalManagers_purchases = [user];
            }
          } else if (user.technicalUser) {
            if (this.tecnician_purchases) {
              this.tecnician_purchases.push(user);
            } else {
              this.tecnician_purchases = [user];
            }
          }

          break;
        }
        case "Personal": {
          if (user.seller === true && user.buyer === true) {
            if (this.personal_sales) {
              this.personal_sales.push(user);
            } else {
              this.personal_sales = [user];
            }
            if (this.personal_purchases) {
              this.personal_purchases.push(user);
            } else {
              this.personal_purchases = [user];
            }
          } else if (user.seller === true || user.canSell === true) {
            if (this.personal_sales) {
              this.personal_sales.push(user);
            } else {
              this.personal_sales = [user];
            }
          } else if (user.buyer === true || user.canBuy === true) {
            if (this.personal_purchases) {
              this.personal_purchases.push(user);
            } else {
              this.personal_purchases = [user];
            }
          } else if (user.technicalUser) {
            if (this.tecnician_purchases) {
              this.tecnician_purchases.push(user);
            } else {
              this.tecnician_purchases = [user];
            }
          }

          break;
        }
        default: {
          if (user.technicalUser) {
            if (this.tecnician_purchases) {
              this.tecnician_purchases.push(user);
            } else {
              this.tecnician_purchases = [user];
            }
          }

          break;
        }
      }
    });
    /* this.setUsersDivision(this.managers);
        this.setUsersDivision(this.sucursalManagers);
        this.setUsersDivision(this.personal); */
  }
  setUsersDivision(arrayUsers) {
    arrayUsers.forEach((user, index) => {});
  }
  getUsers(type, companyId?) {
    let companyIde = localStorage.getItem("$LoopBack$currentCompanyId");

    console.log(companyId);
    let where = {
      companyId: companyIde,
    };

    const getDataQuery = this.queryFactory.generateGetQuery(
      type,
      where,
      100,
      0,
      null,
      []
    );

    this._apiService.getDataObjects(getDataQuery).subscribe(
      (result: any) => {
        console.log(result);
        this.formatDataUsers(result);
      },
      (error) => {
        if (error != null) {
          console.log(error);
        }
      }
    );
  }

  rememberingTabs(tabSelected) {
    localStorage.setItem("tabSelected", tabSelected);
    this.disableEditCompany();
  }
  asd() {
    $('.nav-pills a[href="#pills-profile"]').tab("show");
  }
  setRememberedTab() {
    let tab = localStorage.getItem("tabSelected");
    console.log(tab);
    switch (tab) {
      case "pills-home": {
        $('.nav-pills a[href="#pills-home"]').tab("show");
        break;
      }
      case "pills-profile": {
        $('.nav-pills a[href="#pills-profile"]').tab("show");
        break;
      }
      case "pills-projects": {
        $('.nav-pills a[href="#pills-projects"]').tab("show");
        break;
      }
      case "pills-sucursal": {
        $('.nav-pills a[href="#pills-sucursal"]').tab("show");
        break;
      }
      default: {
        $('.nav-pills a[href="#pills-home"]').tab("show");
        break;
      }
    }
  }
  getBranches(type, companyId) {
    console.log(companyId);
    let where = {
      companyId: companyId,
    };

    const getDataQuery = this.queryFactory.generateGetQuery(
      type,
      where,
      100,
      0,
      null,
      []
    );

    this._apiService.getDataObjects(getDataQuery).subscribe(
      (result: any) => {
        console.log(result);
        this.branches = result;
        var asd = [];
        if (this.userRole === "Sucursal" || this.userRole === "Personal") {
          this.branches.forEach((branch) => {
            if (branch.id === this.branchId) asd.push(branch);
            this.branches = asd;
          });
        }
      },
      (error) => {
        if (error != null) {
          console.log(error);
        }
      }
    );
  }
  getProjects(type, companyId) {
    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");

    let where = {
      companyId: companyId,
    };

    const getDataQuery = this.queryFactory.generateGetQuery(
      type,
      where,
      100,
      0,
      null,
      []
    );

    this._apiService.getDataObjects(getDataQuery).subscribe(
      (result: any) => {
        this.projects = result;

        console.log(this.projects);
      },
      (error) => {
        if (error != null) {
          console.log(error);
        }
      }
    );
  }
  getCountries(type, companyId) {
    const getDataQuery = this.queryFactory.generateGetQuery(
      type,
      {},
      100,
      0,
      null,
      []
    );

    this._apiService.getDataObjects(getDataQuery).subscribe(
      (result: any) => {
        console.log(result);
        this.countries = result;
      },
      (error) => {
        if (error != null) {
          console.log(error);
        }
      }
    );
  }
  getStates(type, companyId) {
    // "589261469108562ad9903f62"
    const getDataQuery = this.queryFactory.generateGetQuery(
      type,
      {},
      100,
      0,
      null,
      []
    );

    this._apiService.getDataObjects(getDataQuery).subscribe(
      (result: any) => {
        this.states = result;
        this.states = this.states.filter(
          (state) => state.countryId === "589261469108562ad9903f62"
        );
      },
      (error) => {
        if (error != null) {
          console.log(error);
        }
      }
    );
  }
}

// }
