
<div *ngIf="str">
    <iframe class='v-w-v-h' width='100%' height='100%' [src]='sanitizer.bypassSecurityTrustResourceUrl(str)' type='text/html'></iframe>
</div>
<div class="center" style=" height: 100%;" *ngIf="!str">
    <h3 *ngIf="!preview">
        Cargando PDF
    </h3>
    <iframe id="previewOrder" *ngIf="preview" type="application/pdf" width='100%' height='100%'></iframe>

</div>