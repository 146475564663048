import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/apiService';
import { pdfA, imgData, pdfAOrden } from '../../services/imageInterface';
import { pdfB } from '../../services/imageInterface';
import { pdfC } from '../../services/imageInterface';

import { DomSanitizer } from '@angular/platform-browser';
import * as jsPDF from 'jspdf'
import { orden1 } from '../../services/ordenImg';
declare var $: any;

@Component({
    selector: 'app-pdfOrder',
    templateUrl: './pdfOrder.component.html',
    styleUrls: ['./pdfOrder.component.css'],
    providers: [
        { provide: 'Window', useValue: window },
        ApiService
    ]
})
export class PdfOrderComponent implements OnInit {
    //define variables
    @Input() purchaseOrder: any;
    @Input() request: any;

    @Input() extra: any;

    @Output() confirmedOrder: EventEmitter<any> = new EventEmitter();
    public order = {};
    private errorMessage = '';
    public str: string;
    public userName = '';
    public userMail = '';
    currency = ''
    public companyName = '';
    public companyAddress = '';
    public companyPhone;
    public prePurchaseOrders: any;
    public branch = '';
    public preview = false;
    public data: any;
    public doc = new jsPDF('p', 'mm', 'a4', 0);
    public imgData = imgData;
    public pdfA = pdfA;
    public pdfAOrden = pdfAOrden;
    public newOrden = orden1;
    public purchaseQuotation;
    public pdfB = pdfB;
    public pdfC = pdfC;

    constructor(@Inject('Window') private window: Window,
        private _route: ActivatedRoute,
        public sanitizer: DomSanitizer,
        private _apiService: ApiService) {
    }

    ngOnInit() {

        if (this.purchaseOrder) {
            let asd: any = this.purchaseOrder
            this.getQuotation(asd.quotationId, {});
            this.generatePreviewPdf(this.purchaseOrder, this.extra, 0)

            this.preview = true



        } else {
            console.log('Entro al else')
            if (this._route.snapshot.paramMap.get('id')) {
                this.getOrder('PurchaseOrders', this._route.snapshot.paramMap.get('id'));

            } else {
                this.data = null
            }

        }
    }
    ngOnDestroy() {
        if (this.doc) {

            this.doc = null;
            this.str = '';
        }

    }

    formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

            const negativeSign = amount < 0 ? "-" : "";

            let i: any = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            let j = (i.length > 3) ? i.length % 3 : 0;

            return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
            console.log(e)
        }
    };

    generatePreviewPdf(order: any, extra, index) {

        this.getOrderCurrency('Quotations', order.quotationId, {})
        //IMAGENES DE FONDO
        //this.doc.addImage(this.pdfA, 'JPEG', 0, 0, 210, 297);

        let totalProducts = order.sellProducts.length;
        let pages = Math.ceil(totalProducts / 8);
        console.log('PAGES' + pages);

        for (var b = 0; b < order.sellProducts.length; b++) {
            if (b % 8 === 0 && b != 0) {
                console.log('NEW PAGE' + b + 1);
                this.doc.addPage();
            }
        }
        /*   for (var b = 0; b < pages; b++) {
                  console.log('NEW PAGE' +b );
                  this.doc.addPage();
              
          } */
        setTimeout(() => {
            console.log(orden1)

            console.log(this.pdfAOrden)
            console.log(this.imgData)
            if (pages > 1) {

                if (pages === 2) {
                    console.log('dos')

                    this.doc.setPage(1);
                    this.doc.addImage(this.pdfAOrden, 'JPEG', 0, 0, 210, 297, undefined, 'FAST');
                    this.doc.setPage(2);
                    this.doc.addImage(this.pdfC, 'JPEG', 0, 0, 210, 297, undefined, 'FAST');


                } else if (pages > 2) {
                    console.log('MAS DE 2 PAGINAS');
                    for (var b = 0; b < pages; b++) {

                        this.doc.setPage(1);
                        this.doc.addImage(this.pdfAOrden, 'JPEG', 0, 0, 210, 297, undefined, 'FAST');

                        if (b > 1) {
                            this.doc.setPage(b)
                            this.doc.addImage(this.pdfB, 'JPEG', 0, 0, 210, 297, undefined, 'FAST');

                        }

                    }

                    this.doc.setPage(pages);
                    this.doc.addImage(this.pdfC, 'JPEG', 0, 0, 210, 297, undefined, 'FAST');



                }
            } else {
                console.log('una pagina')
                this.doc.addImage(this.imgData, 'JPEG', 0, 0, 210, 297, undefined, 'FAST');
            }

            setTimeout(() => {
                this.doc.setDrawColor(255, 100, 100);
                this.doc.setFontSize(5);


                this.doc.setFontSize(8);
                this.doc.setTextColor(169, 169, 169);


                this.doc.setPage(1)

                //init generate pdf
                // Orden
                this.doc.text(30, 41, 'No. de orden: O-' + this.request.id);
                //this.doc.text(30, 45, 'Fecha: ' + this.request.estimatedDeliveryDate.toLocaleString());

                //Datos del cliente
                if (this.extra.companyName) {
                    this.doc.text(30, 48, this.doc.splitTextToSize(this.extra.companyName, 75));
                    if (this.extra.companyName) {
                        this.doc.text(30, 48, this.doc.splitTextToSize(this.extra.companyName, 75));

                    }
                    if (this.extra.fiscalName) {
                        this.doc.text(30, 52, this.doc.splitTextToSize(this.extra.companyName, 75));

                    }
                    if (this.extra.email) {
                        this.doc.text(30, 56, this.doc.splitTextToSize(this.extra.email, 75));

                    }
                }
                if (this.extra.companyAddress) {
                    this.doc.text(30, 60, this.doc.splitTextToSize(this.extra.companyAddress, 75));

                }
                if (this.extra.companyPhone) {
                    this.doc.text(30, 64, this.doc.splitTextToSize(this.extra.companyPhone, 75));

                }

                //Provedor 
                if (order.company.name) {
                    this.doc.text(135, 38, order.company.name);

                }
                if (order.company.companyInfo.fiscalName) {
                    this.doc.text(135, 42, order.company.companyInfo.fiscalName);

                }
                if (order.company.companyInfo.address) {
                    this.doc.text(135, 46, this.doc.splitTextToSize(order.company.companyInfo.address, 50));

                }

                if (order.company.companyInfo.phone) {
                    this.doc.text(135, 54, this.doc.splitTextToSize(order.company.companyInfo.phone, 50));

                }
                //Direccion de entrega
                if (this.request.selectedAddress) {
                    console.log(this.request.selectedAddress)
                    this.doc.text(135, 58, this.doc.splitTextToSize(this.request.selectedAddress, 50));
                }
                let today = new Date();


                this.doc.text(155, 5, today.toLocaleString());

                //Terminos
                if (this.currency) {
                    if (this.currency === "USD") {
                        this.doc.text(135, 65, this.doc.splitTextToSize('Moneda: ' + 'USD', 50));

                    } else {
                        this.doc.text(135, 65, this.doc.splitTextToSize('Moneda: ' + 'MXN', 50));

                    }
                } else {
                    this.doc.text(135, 65, this.doc.splitTextToSize('Moneda: ' + 'MXN', 50));

                }
                if (this.purchaseQuotation) {
                    let estimated = new Date(this.purchaseQuotation.estimatedDeliveryDate);
                    var dd: any = estimated.getDate();
                    var mm: any = estimated.getMonth() + 1; //January is 0!

                    var yyyy = estimated.getFullYear();
                    if (dd < 10) {
                        dd = '0' + dd;
                    }
                    if (mm < 10) {
                        mm = '0' + mm;
                    }
                    var todayEstimated = dd + '/' + mm + '/' + yyyy;
                    this.doc.text(160, 65, this.doc.splitTextToSize(todayEstimated, 50));

                }
                let asd = 10;
                this.doc.text(30, 78, this.doc.splitTextToSize('Anticipo: ' + order.paymentOptions.anticipated + ' %', 25));
                this.doc.text(58, 78, this.doc.splitTextToSize('Contraentrega: ' + order.paymentOptions.uponDelivery + ' %', 25));
                this.doc.text(30, 83, this.doc.splitTextToSize('Credito: ' + order.paymentOptions.credit + ' %', 25));
                this.doc.text(58, 83, this.doc.splitTextToSize('Dias credito: ' + order.paymentOptions.days, 25));


                //Contacto
                this.doc.text(135, 78, this.doc.splitTextToSize(order.user.personalInfo.name, 50));
                this.doc.text(135, 83, this.doc.splitTextToSize(order.user.email, 50));
                if (order.user.phone) {
                    this.doc.text(135, 83, this.doc.splitTextToSize(order.user.phone, 50));
                }


                var total = 0;
                for (var b = 0; b < order.sellProducts.length; b++) {
                    if (order.sellProducts[b] != null) {
                        total += (Number(order.sellProducts[b].amount) * order.sellProducts[b].quantity);
                    }
                }



                var y = 0;
                var height = 0;
                var numberOfPages = 1;
                for (var b = 0; b < order.sellProducts.length; b++) {
                    if (b % 8 === 0 && b != 0) {
                        console.log('SET PAGE');
                        numberOfPages++;
                        this.doc.setPage(numberOfPages)
                        height = 0;
                        y = 0;
                    }



                    if (order.sellProducts[b].specification) {
                        if (order.sellProducts[b].specification.unit) {

                            if (order.sellProducts[b].comments) {
                                var text = [order.sellProducts[b].name + ' ' + order.sellProducts[b].specification.unit + ' ' + ',' + ' ' + order.sellProducts[b].comments];

                            } else {
                                var text = [order.sellProducts[b].name + ' ' + order.sellProducts[b].specification.unit];

                            }

                        }
                    } else {
                        if (order.sellProducts[b].specificationName) {
                            if (order.sellProducts[b].comments) {
                                var text = [order.sellProducts[b].name + ' ' + order.sellProducts[b].specificationName + ' ' + ',' + ' ' + order.sellProducts[b].comments];

                            } else {
                                var text = [order.sellProducts[b].name + ' ' + order.sellProducts[b].specificationName];

                            }

                        } else {

                            var text = [order.sellProducts[b].name + ' '];

                        }

                    }
                    //var text = [order.sellProducts[b].name + ' ' + order.sellProducts[b].specification.unit];
                    if (order.sellProducts[b] != null) {

                        if (b < 8) {
                            console.log('hoja 1')
                            this.doc.setFontSize(7);
                            console.log(b, y + '-> MEDIDAS 1')

                            this.doc.text(10, 120 + (b * 10) + y, this.doc.splitTextToSize(order.sellProducts[b].id, 18));//id

                            if (order.sellProducts[b].unit) {
                                if (order.sellProducts[b].unit.name) {
                                    this.doc.text(100, 120 + (b * 10) + y, this.doc.splitTextToSize(order.sellProducts[b].unit.name, 20));//unidad

                                }


                            }
                            else {
                                if (order.sellProducts[b].unitName) {
                                    this.doc.text(100, 120 + (b * 10) + y, this.doc.splitTextToSize(order.sellProducts[b].unitName, 20));//unidad

                                }
                                //ES del Excel y no trae unidad
                            }
                            console.log(b, y + '-> MEDIDAS 2')
                            this.doc.text(70, 120 + (b * 10) + y, this.doc.splitTextToSize(order.sellProducts[b].quantity.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'), 20));//Cantidad
                            this.doc.text(128, 120 + (b * 10) + y, this.doc.splitTextToSize('$' + this.formatMoney(order.sellProducts[b].amount), 20)); //precio unitario
                            let importe = (order.sellProducts[b].amount * order.sellProducts[b].quantity).toFixed(2);
                            this.doc.text(158, 120 + (b * 10) + y, this.doc.splitTextToSize('$' + this.formatMoney(importe), 20));//importe
                            this.doc.text(193, 120 + (b * 10) + y, this.doc.splitTextToSize(((order.sellProducts[b].amount * order.sellProducts[b].quantity) * 100 / total).toFixed(2) + '%', 20));//porcentaje
                            console.log(b, y + '-> MEDIDAS 3')

                            for (var i = 0; i < text.length; i++) {
                                var splitTitle = this.doc.splitTextToSize(text[i], 38); //name
                                //loop thru each line and output while increasing the vertical space
                                console.log(splitTitle, b);
                                for (var c = 0; c < splitTitle.length; c++) {
                                    this.doc.text(30, 120 + (b * 10) + y, splitTitle[c]);
                                    y = y + 3;
                                }

                            }
                            console.log(b, y + '-> MEDIDAS 4')

                        }
                        else {
                            console.log('segunda hoja')
                            this.doc.setFontSize(7);
                            console.log(height, y + '->MEDIDAS')
                            this.doc.text(10, 30 + (height * 10) + y, this.doc.splitTextToSize(order.sellProducts[b].id, 18));
                            //this.doc.text(100, 30 + (height * 10) + y, this.doc.splitTextToSize(order.sellProducts[b].unit.name, 20));
                            if (order.sellProducts[b].unit) {
                                if (order.sellProducts[b].unit.name) {
                                    this.doc.text(100, 30 + (height * 10) + y, this.doc.splitTextToSize(order.sellProducts[b].unit.name, 20));//unidad

                                }


                            }
                            else {


                                if (order.sellProducts[b].unitName) {
                                    this.doc.text(100, 30 + (height * 10) + y, this.doc.splitTextToSize(order.sellProducts[b].unitName, 20));//unidad

                                }


                                //ES del Excel y no trae unidad
                            }
                            this.doc.text(70, 30 + (height * 10) + y, this.doc.splitTextToSize(order.sellProducts[b].quantity.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'), 20));
                            this.doc.text(128, 30 + (height * 10) + y, this.doc.splitTextToSize('$' + this.formatMoney(order.sellProducts[b].amount), 20));
                            let importe = (order.sellProducts[b].amount * order.sellProducts[b].quantity).toFixed(2);
                            this.doc.text(158, 30 + (height * 10) + y, this.doc.splitTextToSize('$' + this.formatMoney(importe), 20));
                            this.doc.text(193, 30 + (height * 10) + y, this.doc.splitTextToSize(((order.sellProducts[b].amount * order.sellProducts[b].quantity) * 100 / total).toFixed(2) + '%', 20));
                            for (var i = 0; i < text.length; i++) {
                                var splitTitle = this.doc.splitTextToSize(text[i], 38); //name
                                //loop thru each line and output while increasing the vertical space
                                console.log(splitTitle, b);
                                for (var c = 0; c < splitTitle.length; c++) {
                                    this.doc.text(30, 30 + (height * 10) + y, splitTitle[c]);
                                    y = y + 3;
                                }

                            }
                            height++;


                        }


                    }

                }

                this.doc.setFontSize(10);
                this.doc.setTextColor(0, 128, 0);
                this.doc.text(150, 265, this.doc.splitTextToSize('$ ' + total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'), 50));
                this.doc.setFontSize(8);
                this.doc.setTextColor(169, 169, 169);
                this.doc.text(150, 270, this.doc.splitTextToSize('IVA 16.00%', 50));
                this.doc.text(180, 270, this.doc.splitTextToSize('$ ' + (total * .16).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'), 50));
                this.doc.setFontSize(10);
                this.doc.setFontType('bold');
                this.doc.setTextColor(90, 90, 90);
                this.doc.text(150, 280, this.doc.splitTextToSize('Total Neto: $ ' + (total + (total * .16)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'), 50));
                this.doc.text(20, 255, 'Detalles:');
                this.doc.setFontSize(8);

                //this.doc.text(20, 230, result.deliveryDetails);
                var splitTitle = this.doc.splitTextToSize(this.purchaseQuotation.deliveryDetails, 120);
                this.doc.text(15, 260, splitTitle);
                //string pdf
                //images here

                this.doc.setPage(1)

                if (order.company.profileUrl) {
                  
                    this.getBase64Image(order.company.profileUrl,  (dataUrl1) => {
                        console.log('cb1');
                        console.log(dataUrl1);
                        this.doc.addImage(dataUrl1, 115, 15, 15, 15);

                    });
                }


                if (order.request.company.profileUrl) {
                    this.getBase64Image2(order.request.company.profileUrl, 
                        (dataUrl2) => {

                            console.log('cb2');
                            console.log(dataUrl2);
                            console.log(order.request.company.profileUrl);

                        
                           this.doc.addImage(dataUrl2, 8, 15, 15, 15);
                            

                        });
                } 
            }, 500);





        }, 100)

        setTimeout(() => {
            this.data = this.doc.output('datauri');
            console.log(this.data)

            $('#previewOrder').attr('src', this.data)

        }, 2000);

        // }); 
    }
    getQuotation(id: string, params: any) {
        console.log(id)
        var name;
        this._apiService.getDataById('Quotations', id, params).subscribe(
            (result: any)=> {

                this.purchaseQuotation = result

                console.log(this.purchaseQuotation);

            },
            error => {
                if (error != null) {
                    console.log(error);
                }
            }
        );
        return name;
    }
    getBranchName(type: string, id: string, params: any) {
        console.log(id)
        var name;
        this._apiService.getDataById(type, id).subscribe(
            (result: any)=> {


                console.log('Branch NAME')
                name = result.name
                this.branch = result.name
                console.log(this.branch);

            },
            error => {
                if (error != null) {
                    console.log(error);
                }
            }
        );
        return name;
    }
    getOrderCurrency(type: string, id: string, params: any) {
        console.log(id)
        var name;
        this._apiService.getDataById(type, id).subscribe(
            (result: any)=> {


                this.currency = result.currency;
                console.log(result)

            },
            error => {
                if (error != null) {
                    console.log(error);
                }
            }
        );
        return name;
    }
    getCompanyName(type: string, id: string, params: any) {
        console.log(id)
        var name;
        this._apiService.getDataById(type, id).subscribe(
            (result: any)=> {

                console.log('company NAME')

                name = result.companyInfo.name;
                this.companyName = result.name;
                this.companyAddress = result.companyInfo.address;
                this.companyPhone = result.companyInfo.phone;

                console.log(result)

            },
            error => {
                if (error != null) {
                    console.log(error);
                }
            }
        );
        return name;
    }
    getUserName(type: string, id: string, params: any) {
        console.log(id)
        var name;
        this._apiService.getDataById(type, id).subscribe(
            (result: any)=> {

                console.log('user NAME')
                name = result.personalInfo.name;
                this.userName = name;
                console.log(this.userName);


            },
            error => {
                if (error != null) {
                    console.log(error);
                }
            }
        );
        return name;

    }

    getBase64Image(url, cb) {
        console.log('1')
        console.log(url)
        var img = new Image();
        img.crossOrigin = "anonymous";
        var dataURL;

        img.src = url;

        img.onload = function () {
            var canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            var context = canvas.getContext('2d');
            context.drawImage(img, 0, 0);
            dataURL = canvas.toDataURL('image/jpeg');
            cb(dataURL)
        }
    }
    getBase64Image2(url, cb) {
        console.log('2')
        console.log(url)

        var img = new Image();
        img.crossOrigin = "anonymous";
        var dataURL;

        img.src = url;

        img.onload = function () {
            var canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            var context = canvas.getContext('2d');
            context.drawImage(img, 0, 0);
            dataURL = canvas.toDataURL('image/jpeg');
            cb(dataURL)
        }
    }
    getOrder(type, id, order?) {
        let tokenId = localStorage.getItem('$LoopBack$accessTokenId');


        let params = {
            filter: {
                include: ['company', 'sellProducts', 'branch', 'user', { relation: 'request', scope: { include: 'company' } }]
            },
            access_token: tokenId
        };
        this._apiService.getDataById(type, id).subscribe(
            (result: any)=> {

                console.log(result);

                //IMAGENES DE FONDO
                //this.doc.addImage(this.pdfA, 'JPEG', 0, 0, 210, 297);
                this.getQuotation(result.quotationId, {});
                let totalProducts = result.sellProducts.length;
                let pages = Math.floor(totalProducts / 8);
                for (var b = 0; b < result.sellProducts.length; b++) {
                    if (b % 8 === 0 && b != 0) {
                        console.log('NEW PAGE');
                        this.doc.addPage();
                    }
                }

                setTimeout(() => {

                    if (pages > 1) {

                        if (pages === 2) {

                            this.doc.setPage(1);
                            this.doc.addImage(this.pdfAOrden, 'JPEG', 0, 0, 210, 297);
                            this.doc.setPage(2);
                            this.doc.addImage(this.pdfC, 'JPEG', 0, 0, 210, 297);


                        } else if (pages > 2) {
                            console.log('MAS DE 2 PAGINAS');
                            for (var b = 0; b < pages; b++) {
                                this.doc.setPage(1);
                                this.doc.addImage(this.pdfAOrden, 'JPEG', 0, 0, 210, 297);

                                if (b > 1) {
                                    this.doc.setPage(b)
                                    this.doc.addImage(this.pdfB, 'JPEG', 0, 0, 210, 297);

                                }

                            }

                            this.doc.setPage(pages);
                            this.doc.addImage(this.pdfC, 'JPEG', 0, 0, 210, 297);



                        }
                    } else {
                        this.doc.addImage(this.imgData, 'JPEG', 0, 0, 210, 297);
                    }


                    setTimeout(() => {
                        this.doc.setDrawColor(255, 100, 100);
                        this.doc.setFontSize(5);

                        //cuadriculado de hoja
                        // for (var a = 0; a < 297; a += 5) {
                        //     this.doc.line(0, a, 210, a);
                        //     this.doc.text(0, a, a.toString());
                        //     this.doc.line(a, 0, a, 297);
                        //     this.doc.text(a, 5, a.toString());
                        // }
                        this.doc.setFontSize(8);
                        this.doc.setTextColor(169, 169, 169);
                        this.doc.setPage(1)


                        //init generate pdf
                        // Orden
                        this.doc.text(30, 37, 'No. de compra: ' + result.id);
                        this.doc.text(30, 41, 'No. de orden: O-' + result.request.id);

                        /// cambie esto por que no sabia
                        //this.doc.text(30, 45, 'Fecha: ' + result.estimatedDeliveryDate.toLocaleString());

                        //Datos del cliente
                        if (result.request.company.name) {
                            this.doc.text(30, 48, this.doc.splitTextToSize(result.request.company.name, 75));

                        }
                        if (result.request.company.companyInfo.fiscalName) {
                            this.doc.text(30, 52, this.doc.splitTextToSize(result.request.company.companyInfo.fiscalName, 75));

                        }
                        if (result.request.company.companyInfo.email) {
                            this.doc.text(30, 56, this.doc.splitTextToSize(result.request.company.companyInfo.email, 75));

                        }
                        if (result.request.company.companyInfo.address) {
                            this.doc.text(30, 60, this.doc.splitTextToSize(result.request.company.companyInfo.address, 75));

                        }
                        if (result.request.company.companyInfo.phone) {
                            this.doc.text(30, 64, this.doc.splitTextToSize(result.request.company.companyInfo.phone, 75));

                        }

                        //Provedor 
                        if (result.company.name) {
                            this.doc.text(135, 38, result.company.name);

                        }
                        if (result.company.companyInfo.fiscalName) {
                            this.doc.text(135, 42, result.company.companyInfo.fiscalName);

                        }
                        if (result.company.companyInfo.address) {
                            this.doc.text(135, 46, this.doc.splitTextToSize(result.company.companyInfo.address, 50));

                        }

                        if (result.company.companyInfo.phone) {
                            this.doc.text(135, 54, this.doc.splitTextToSize(result.company.companyInfo.phone, 50));

                        }
                        let today = new Date();


                        this.doc.text(155, 5, today.toLocaleString());

                        //Direccion de entrega
                        if (result.request.selectedAddress) {
                            this.doc.text(135, 58, this.doc.splitTextToSize(result.request.selectedAddress, 50));

                        }

                        //Terminos
                        if (result.request.currency) {
                            if (result.request.currency === "USD") {
                                this.doc.text(135, 65, this.doc.splitTextToSize('Moneda: ' + 'USD', 50));

                            } else {
                                this.doc.text(135, 65, this.doc.splitTextToSize('Moneda: ' + 'MXN', 50));

                            }
                        } else {
                            this.doc.text(135, 65, this.doc.splitTextToSize('Moneda: ' + 'MXN', 50));

                        }

                        this.doc.text(30, 78, this.doc.splitTextToSize('Anticipo: ' + result.paymentOptions.anticipated + ' %', 25));
                        this.doc.text(58, 78, this.doc.splitTextToSize('Contraentrega: ' + result.paymentOptions.uponDelivery + ' %', 25));
                        this.doc.text(30, 83, this.doc.splitTextToSize('Credito: ' + result.paymentOptions.credit + ' %', 25));
                        this.doc.text(58, 83, this.doc.splitTextToSize('Dias credito: ' + result.paymentOptions.days, 25));
                        // this.doc.text(135, 12,result.request.deliveryAddress);


                        //Contacto
                        this.doc.text(135, 78, this.doc.splitTextToSize(result.user.personalInfo.name, 50));
                        this.doc.text(135, 83, this.doc.splitTextToSize(result.user.email, 50));
                        if (result.user.phone) {
                            this.doc.text(135, 83, this.doc.splitTextToSize(result.user.phone, 50));
                        }


                        var total = 0;
                        for (var b = 0; b < result.sellProducts.length; b++) {
                            if (result.sellProducts[b] != null) {
                                total += (Number(result.sellProducts[b].amount) * result.sellProducts[b].quantity);


                            }

                        }
                        //ordenes
                        this.doc.setTextColor(0, 0, 0);

                        var y = 0;
                        var height = 0;
                        var numberOfPages = 1;
                        for (var b = 0; b < result.sellProducts.length; b++) {
                            if (b % 8 === 0 && b != 0) {
                                console.log('SET PAGE');
                                numberOfPages++;
                                this.doc.setPage(numberOfPages)
                                //this.doc.addPage();
                                height = 0;
                                y = 0;
                            }

                            /*       if (result.sellProducts[b].specification) {
                                      if (result.sellProducts[b].specification.unit) {
                                          var text = [result.sellProducts[b].name + ' ' + result.sellProducts[b].specification.unit];
      
                                      }
                                  } else {
                                      if (result.sellProducts[b].specificationName) {
                                          var text = [result.sellProducts[b].name + ' ' + result.sellProducts[b].specificationName];
      
                                      } else {
                                          var text = [result.sellProducts[b].name + ' '];
      
                                      }
                                  } */
                            if (result.sellProducts[b].specification) {
                                if (result.sellProducts[b].specification.unit) {

                                    if (result.sellProducts[b].comments) {
                                        var text = [result.sellProducts[b].name + ' ' + result.sellProducts[b].specification.unit + ' ' + 'Marca :' + ' ' + result.sellProducts[b].comments];

                                    } else {
                                        var text = [result.sellProducts[b].name + ' ' + result.sellProducts[b].specification.unit];

                                    }

                                }
                            } else {
                                if (result.sellProducts[b].specificationName) {
                                    if (result.sellProducts[b].comments) {
                                        var text = [result.sellProducts[b].name + ' ' + result.sellProducts[b].specificationName + ' ' + ',' + ' ' + result.sellProducts[b].comments];

                                    } else {
                                        var text = [result.sellProducts[b].name + ' ' + result.sellProducts[b].specificationName];

                                    }

                                } else {

                                    var text = [result.sellProducts[b].name + ' '];

                                }

                            }
                            // var text = [result.sellProducts[b].name + ' ' + result.sellProducts[b].specification.unit];
                            if (result.sellProducts[b] != null) {

                                if (b < 8) {
                                    this.doc.setFontSize(7);
                                    this.doc.text(10, 115 + (b * 10) + y, this.doc.splitTextToSize(result.sellProducts[b].id, 18));
                                    if (result.sellProducts[b].unit) {
                                        if (result.sellProducts[b].unit.name) {
                                            this.doc.text(100, 115 + (b * 10) + y, this.doc.splitTextToSize(result.sellProducts[b].unit.name, 20));//unidad

                                        }


                                    }
                                    else {


                                        if (result.sellProducts[b].unitName) {
                                            this.doc.text(100, 115 + (b * 10) + y, this.doc.splitTextToSize(result.sellProducts[b].unitName, 20));//unidad

                                        }


                                        //ES del Excel y no trae unidad
                                    }
                                    this.doc.text(70, 115 + (b * 10) + y, this.doc.splitTextToSize(result.sellProducts[b].quantity.toString(), 20));
                                    this.doc.text(128, 115 + (b * 10) + y, this.doc.splitTextToSize('$' + this.formatMoney(result.sellProducts[b].amount), 20));
                                    let importe = (result.sellProducts[b].amount * result.sellProducts[b].quantity).toFixed(2);
                                    this.doc.text(158, 115 + (b * 10) + y, this.doc.splitTextToSize(this.formatMoney(importe), 20));
                                    this.doc.text(193, 115 + (b * 10) + y, this.doc.splitTextToSize(((result.sellProducts[b].amount * result.sellProducts[b].quantity) * 100 / total).toFixed(2) + '%', 20));
                                    for (var i = 0; i < text.length; i++) {
                                        var splitTitle = this.doc.splitTextToSize(text[i], 38);
                                        //loop thru each line and output while increasing the vertical space
                                        console.log(splitTitle, b);
                                        for (var c = 0; c < splitTitle.length; c++) {
                                            this.doc.text(30, 115 + y + (b * 10), splitTitle[c]);
                                            y = y + 3;
                                        }

                                    }
                                } else {
                                    this.doc.setFontSize(7);
                                    this.doc.text(10, 25 + (b * 10) + y, this.doc.splitTextToSize(result.sellProducts[b].id, 18));
                                    //this.doc.text(100, 25 + (b * 10) + y, this.doc.splitTextToSize(result.sellProducts[b].unit.name, 20));
                                    if (result.sellProducts[b].unit) {
                                        if (result.sellProducts[b].unit.name) {
                                            this.doc.text(100, 25 + (b * 10) + y, this.doc.splitTextToSize(result.sellProducts[b].unit.name, 20));//unidad

                                        }


                                    }
                                    else {


                                        if (result.sellProducts[b].unitName) {
                                            this.doc.text(100, 25 + (b * 10) + y, this.doc.splitTextToSize(result.sellProducts[b].unitName, 20));//unidad

                                        }


                                        //ES del Excel y no trae unidad
                                    }
                                    this.doc.text(70, 25 + (b * 10) + y, this.doc.splitTextToSize(result.sellProducts[b].quantity.toString(), 20));
                                    this.doc.text(128, 25 + (b * 10) + y, this.doc.splitTextToSize('$' + this.formatMoney(result.sellProducts[b].amount), 20));
                                    let importe = (result.sellProducts[b].amount * result.sellProducts[b].quantity).toFixed(2);
                                    this.doc.text(158, 25 + (b * 10) + y, this.doc.splitTextToSize(this.formatMoney(importe), 20));
                                    this.doc.text(193, 25 + (b * 10) + y, this.doc.splitTextToSize(((result.sellProducts[b].amount * result.sellProducts[b].quantity) * 100 / total).toFixed(2) + '%', 20));
                                    for (var i = 0; i < text.length; i++) {
                                        var splitTitle = this.doc.splitTextToSize(text[i], 38);
                                        //loop thru each line and output while increasing the vertical space
                                        console.log(splitTitle, b);
                                        for (var c = 0; c < splitTitle.length; c++) {
                                            this.doc.text(30, 25 + y + (b * 10), splitTitle[c]);
                                            y = y + 3;
                                        }
                                        // this.doc.text(32, 90 + (b * 10) + y, this.doc.splitTextToSize(result.quotedProducts[b].comments, 28));

                                        height++;


                                    }
                                }

                            }

                        }
                        this.doc.setFontSize(10);
                        this.doc.setTextColor(0, 128, 0);
                        this.doc.text(150, 265, this.doc.splitTextToSize('$ ' + total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'), 50));
                        this.doc.setFontSize(8);
                        this.doc.setTextColor(169, 169, 169);
                        this.doc.text(150, 270, this.doc.splitTextToSize('IVA 16.00%', 50));
                        this.doc.text(180, 270, this.doc.splitTextToSize('$ ' + (total * .16).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'), 50));
                        this.doc.setFontSize(10);
                        this.doc.setFontType('bold');
                        this.doc.setTextColor(90, 90, 90);
                        this.doc.text(150, 280, this.doc.splitTextToSize('Total Neto: $ ' + (total + (total * .16)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'), 50));
                        this.doc.text(20, 255, 'Detalles:');
                        //this.doc.text(20, 230, result.deliveryDetails);
                        this.doc.setFontSize(8);

                        //this.doc.text(20, 230, result.deliveryDetails);
                        var splitTitle = this.doc.splitTextToSize(this.purchaseQuotation.deliveryDetails, 120);
                        this.doc.text(15, 260, splitTitle);


                        //string pdf


                        this.doc.setPage(1)

                        //images here
                        if (result.company.profileUrl) {
                            this.getBase64Image(result.company.profileUrl, (dataUrl1) => {
                                console.log(dataUrl1, 'cb1');

                                if (result.request.company.profileUrl) {
                                    this.getBase64Image(result.request.company.profileUrl, 
                                        (dataUrl2) => {

                                            console.log(dataUrl2, 'cb2');
                                            if (dataUrl1 && dataUrl2) {
                                                this.doc.addImage(dataUrl1, 115, 15, 15, 15, undefined, 'FAST');

                                                this.doc.addImage(dataUrl2, 8, 15, 15, 15, undefined, 'FAST');

                                            }
                                            // this.doc.save('O-' + result.request.id + '.pdf');
                                            this.str = this.doc.output('datauristring');
                                        });
                                }
                            });
                        }

                    }, 500);
                });
                // Save the PDF
                // this.doc.save('Test.pdf');

                // var iframe = "";
                // //  var iframe = "<object data="+string+"type='application/pdf'></object>";
                // this.window.document.write(iframe);
            },
            error => {
                this.errorMessage = <any>error;
                if (this.errorMessage != null) {
                    console.log(this.errorMessage);
                }
            }
        );
    }
    showOrder(type, id, order?) {
        let tokenId = localStorage.getItem('$LoopBack$accessTokenId');


        let params = {
            filter: {
                include: ['company', 'branch', 'user', { relation: 'request', scope: { include: 'company' } }]
            },
            access_token: tokenId
        };
        this._apiService.getDataById(type, id).subscribe(
            (result: any)=> {

                console.log(result);

                this.doc.addImage(this.imgData, 'JPEG', 0, 0, 210, 297);
                this.doc.setDrawColor(255, 100, 100);
                this.doc.setFontSize(5);
                this.doc.setFontSize(8);
                this.doc.setTextColor(169, 169, 169);


                //init generate pdf
                // Orden
                this.doc.text(30, 37, 'No. de compra: ' + result.id);
                this.doc.text(30, 41, 'No. de orden: O-' + result.request.id);
                // this.doc.text(30, 45, 'Fecha: ' + this.estimated.toLocaleString());

                //Datos del cliente
                this.doc.text(30, 52, this.doc.splitTextToSize(result.request.company.name, 75));
                this.doc.text(30, 56, this.doc.splitTextToSize(result.request.company.companyInfo.address, 75));

                //Provedor 
                this.doc.text(135, 35, result.branch.name);
                this.doc.text(135, 39, this.doc.splitTextToSize(result.branch.companyInfo.address, 50));


                //Direccion de entrega
                this.doc.text(135, 52, this.doc.splitTextToSize(result.request.selectedAddress, 50));

                //Terminos
                if (result.request.currency) {
                    if (result.request.currency === "USD") {
                        this.doc.text(135, 53, this.doc.splitTextToSize('Moneda: ' + 'USD', 50));

                    } else {

                    }
                } else {
                    this.doc.text(135, 53, this.doc.splitTextToSize('Moneda: ' + 'MXN', 50));

                }

                this.doc.text(30, 80, this.doc.splitTextToSize('Anticipo: ' + result.request.paymentOptions.anticipated + ' %', 25));
                this.doc.text(58, 80, this.doc.splitTextToSize('Contraentrega: ' + result.request.paymentOptions.uponDelivery + ' %', 25));
                this.doc.text(30, 85, this.doc.splitTextToSize('Credito: ' + result.request.paymentOptions.credit + ' %', 25));
                this.doc.text(58, 85, this.doc.splitTextToSize('Dias credito: ' + result.request.paymentOptions.days, 25));
                // this.doc.text(135, 12,result.request.deliveryAddress);


                //Contacto
                this.doc.text(135, 75, this.doc.splitTextToSize(result.user.personalInfo.name, 50));
                this.doc.text(135, 80, this.doc.splitTextToSize(result.user.email, 50));
                if (result.user.phone) {
                    this.doc.text(135, 85, this.doc.splitTextToSize(result.user.phone, 50));
                }


                var total = 0;

                for (var b = 0; b < result.sellProducts.length; b++) {
                    if (result.sellProducts[b] != null) {
                        total += (Number(result.sellProducts[b].amount) * result.sellProducts[b].quantity);


                    }

                }

                //ordenes
                this.doc.setTextColor(0, 0, 0);

                var y = 0;
                for (var b = 0; b < result.sellProducts.length; b++) {
                    var lengthOfPage = 500;
                    var text = [result.sellProducts[b].name + ' ' + result.sellProducts[b].specification.unit];
                    if (result.sellProducts[b] != null) {
                        this.doc.setFontSize(7);
                        this.doc.text(10, 115 + (b * 5) + y, this.doc.splitTextToSize(result.sellProducts[b].id, 18));
                        //   this.doc.text(100, 115 + (b * 10) + y, this.doc.splitTextToSize(result.sellProducts[b].unit.name, 20));
                        if (result.sellProducts[b].unit) {
                            if (result.sellProducts[b].unit.name) {
                                this.doc.text(100, 120 + (b * 10) + y, this.doc.splitTextToSize(result.sellProducts[b].unit.name, 20));//unidad

                            }


                        }
                        else {


                            if (result.sellProducts[b].unitName) {
                                this.doc.text(100, 120 + (b * 10) + y, this.doc.splitTextToSize(result.sellProducts[b].unitName, 20));//unidad

                            }


                            //ES del Excel y no trae unidad
                        }
                        this.doc.text(70, 115 + (b * 10) + y, this.doc.splitTextToSize(result.sellProducts[b].quantity.toString(), 20));
                        this.doc.text(128, 115 + (b * 10) + y, this.doc.splitTextToSize('$' + this.formatMoney(result.sellProducts[b].amount), 20));
                        let importe = (result.sellProducts[b].amount * result.sellProducts[b].quantity).toFixed(2);
                        this.doc.text(158, 115 + (b * 10) + y, this.doc.splitTextToSize(this.formatMoney(importe), 20));
                        this.doc.text(193, 115 + (b * 10) + y, this.doc.splitTextToSize(((result.sellProducts[b].amount * result.sellProducts[b].quantity) * 100 / total).toFixed(2) + '%', 20));
                        for (var i = 0; i < text.length; i++) {
                            var splitTitle = this.doc.splitTextToSize(text[i], 38);
                            //loop thru each line and output while increasing the vertical space
                            console.log(splitTitle, b);
                            for (var c = 0; c < splitTitle.length; c++) {
                                this.doc.text(30, 90 + y + (b * 5), splitTitle[c]);
                                y = y + 3;
                            }

                        }

                    }

                }
                this.doc.setFontSize(10);
                this.doc.setTextColor(0, 128, 0);
                this.doc.text(150, 260, this.doc.splitTextToSize('$ ' + total.toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,'), 50));
                this.doc.setFontSize(8);
                this.doc.setTextColor(169, 169, 169);
                this.doc.text(150, 270, this.doc.splitTextToSize('IVA 16.00%', 50));
                this.doc.text(180, 270, this.doc.splitTextToSize('$ ' + (total * .16).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,'), 50));
                this.doc.setFontSize(10);
                this.doc.setFontType('bold');
                this.doc.setTextColor(90, 90, 90);
                this.doc.text(150, 280, this.doc.splitTextToSize('Total Neto: $ ' + (total + (total * .16)).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,'), 50));
                //string pdf


                //images here
                if (result.company.profileUrl) {
                    this.getBase64Image(result.company.profileUrl, (dataUrl1) => {
                        console.log(dataUrl1, 'cb1');

                        if (result.request.company.profileUrl) {
                            this.getBase64Image(result.request.company.profileUrl, 
                                (dataUrl2) => {

                                    console.log(dataUrl2, 'cb2');
                                    if (dataUrl1 && dataUrl2) {
                                        this.doc.addImage(dataUrl1, 145, 7, 25, 25);
                                        this.doc.addImage(dataUrl2, 40, 7, 25, 25);

                                    }
                                    this.doc.save('O-' + result.request.id + '.pdf');
                                    this.str = this.doc.output('datauristring');
                                });
                        }
                    });
                }

                // console.log(this.str);

                // Save the PDF
                // doc.save('Test.pdf');

                // var iframe = "";
                // //  var iframe = "<object data="+string+"type='application/pdf'></object>";
                // this.window.document.write(iframe);
            },
            error => {
                this.errorMessage = <any>error;
                if (this.errorMessage != null) {
                    console.log(this.errorMessage);
                }
            }
        );
    }

    showOrderSetTest(type, id, order?) {
        let tokenId = localStorage.getItem('$LoopBack$accessTokenId');


        let params = {
            filter: {
                include: ['company', 'branch', 'user', { relation: 'request', scope: { include: 'company' } }]
            },
            access_token: tokenId
        };
        this._apiService.getDataById(type, id).subscribe(
            (result: any)=> {

                console.log(result);

                this.doc.addImage(this.imgData, 'JPEG', 0, 0, 210, 297);
                this.doc.setDrawColor(255, 100, 100);
                this.doc.setFontSize(5);
                this.doc.setFontSize(8);
                this.doc.setTextColor(169, 169, 169);


                //init generate pdf
                // Orden
                this.doc.text(30, 37, 'No. de compra: ' + result.id);
                this.doc.text(30, 41, 'No. de orden: O-' + result.request.id);
                // this.doc.text(30, 45, 'Fecha: ' + this.estimated.toLocaleString());

                //Datos del cliente
                this.doc.text(30, 52, this.doc.splitTextToSize(result.request.company.name, 75));
                this.doc.text(30, 56, this.doc.splitTextToSize(result.request.company.companyInfo.address, 75));

                //Provedor 
                this.doc.text(135, 35, result.branch.name);
                this.doc.text(135, 39, this.doc.splitTextToSize(result.branch.companyInfo.address, 50));


                //Direccion de entrega
                this.doc.text(135, 52, this.doc.splitTextToSize(result.request.selectedAddress, 50));

                //Terminos
                if (result.request.currency) {
                    if (result.request.currency === "USD") {
                        this.doc.text(135, 53, this.doc.splitTextToSize('Moneda: ' + 'USD', 50));

                    } else {

                    }
                } else {
                    this.doc.text(135, 53, this.doc.splitTextToSize('Moneda: ' + 'MXN', 50));

                }

                this.doc.text(30, 80, this.doc.splitTextToSize('Anticipo: ' + result.request.paymentOptions.anticipated + ' %', 25));
                this.doc.text(58, 80, this.doc.splitTextToSize('Contraentrega: ' + result.request.paymentOptions.uponDelivery + ' %', 25));
                this.doc.text(30, 85, this.doc.splitTextToSize('Credito: ' + result.request.paymentOptions.credit + ' %', 25));
                this.doc.text(58, 85, this.doc.splitTextToSize('Dias credito: ' + result.request.paymentOptions.days, 25));
                // this.doc.text(135, 12,result.request.deliveryAddress);


                //Contacto
                this.doc.text(135, 75, this.doc.splitTextToSize(result.user.personalInfo.name, 50));
                this.doc.text(135, 80, this.doc.splitTextToSize(result.user.email, 50));
                if (result.user.phone) {
                    this.doc.text(135, 85, this.doc.splitTextToSize(result.user.phone, 50));
                }


                var total = 0;

                for (var b = 0; b < result.sellProducts.length; b++) {
                    if (result.sellProducts[b] != null) {
                        total += (Number(result.sellProducts[b].amount) * result.sellProducts[b].quantity);


                    }

                }

                //ordenes
                this.doc.setTextColor(0, 0, 0);

                var y = 0;
                for (var b = 0; b < result.sellProducts.length; b++) {
                    var lengthOfPage = 500;
                    var text = [result.sellProducts[b].name + ' ' + result.sellProducts[b].specification.unit];
                    if (result.sellProducts[b] != null) {
                        this.doc.setFontSize(7);
                        this.doc.text(10, 115 + (b * 5) + y, this.doc.splitTextToSize(result.sellProducts[b].id, 18));
                        //   this.doc.text(100, 115 + (b * 10) + y, this.doc.splitTextToSize(result.sellProducts[b].unit.name, 20));
                        if (result.sellProducts[b].unit) {
                            if (result.sellProducts[b].unit.name) {
                                this.doc.text(100, 120 + (b * 10) + y, this.doc.splitTextToSize(result.sellProducts[b].unit.name, 20));//unidad

                            }


                        }
                        else {


                            if (result.sellProducts[b].unitName) {
                                this.doc.text(100, 120 + (b * 10) + y, this.doc.splitTextToSize(result.sellProducts[b].unitName, 20));//unidad

                            }


                            //ES del Excel y no trae unidad
                        }
                        this.doc.text(70, 115 + (b * 10) + y, this.doc.splitTextToSize(result.sellProducts[b].quantity.toString(), 20));
                        this.doc.text(128, 115 + (b * 10) + y, this.doc.splitTextToSize('$' + this.formatMoney(result.sellProducts[b].amount), 20));
                        let importe = (result.sellProducts[b].amount * result.sellProducts[b].quantity).toFixed(2);
                        this.doc.text(158, 115 + (b * 10) + y, this.doc.splitTextToSize(this.formatMoney(importe), 20));
                        this.doc.text(193, 115 + (b * 10) + y, this.doc.splitTextToSize(((result.sellProducts[b].amount * result.sellProducts[b].quantity) * 100 / total).toFixed(2) + '%', 20));
                        for (var i = 0; i < text.length; i++) {
                            var splitTitle = this.doc.splitTextToSize(text[i], 38);
                            //loop thru each line and output while increasing the vertical space
                            console.log(splitTitle, b);
                            for (var c = 0; c < splitTitle.length; c++) {
                                this.doc.text(30, 90 + y + (b * 5), splitTitle[c]);
                                y = y + 3;
                            }

                        }

                    }

                }
                this.doc.setFontSize(10);
                this.doc.setTextColor(0, 128, 0);
                this.doc.text(150, 260, this.doc.splitTextToSize('$ ' + total.toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,'), 50));
                this.doc.setFontSize(8);
                this.doc.setTextColor(169, 169, 169);
                this.doc.text(150, 270, this.doc.splitTextToSize('IVA 16.00%', 50));
                this.doc.text(180, 270, this.doc.splitTextToSize('$ ' + (total * .16).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,'), 50));
                this.doc.setFontSize(10);
                this.doc.setFontType('bold');
                this.doc.setTextColor(90, 90, 90);
                this.doc.text(150, 280, this.doc.splitTextToSize('Total Neto: $ ' + (total + (total * .16)).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,'), 50));
                //string pdf


                //images here
                if (result.company.profileUrl) {
                    this.getBase64Image(result.company.profileUrl, (dataUrl1) => {
                        console.log(dataUrl1, 'cb1');

                        if (result.request.company.profileUrl) {
                            this.getBase64Image(result.request.company.profileUrl, 
                                (dataUrl2) => {

                                    console.log(dataUrl2, 'cb2');
                                    if (dataUrl1 && dataUrl2) {
                                        this.doc.addImage(dataUrl1, 145, 7, 25, 25);
                                        this.doc.addImage(dataUrl2, 40, 7, 25, 25);

                                    }
                                    this.doc.save('O-' + result.request.id + '.pdf');
                                    this.str = this.doc.output('datauristring');
                                });
                        }
                    });
                }

                // console.log(this.str);

                // Save the PDF
                // doc.save('Test.pdf');

                // var iframe = "";
                // //  var iframe = "<object data="+string+"type='application/pdf'></object>";
                // this.window.document.write(iframe);
            },
            error => {
                this.errorMessage = <any>error;
                if (this.errorMessage != null) {
                    console.log(this.errorMessage);
                }
            }
        );
    }
}
