
/// <reference types="@types/googlemaps" />
import { Component, OnInit, ViewChild, ElementRef, Input, NgZone } from '@angular/core';
declare var $: any;
import swal from 'sweetalert2'
import { TypeaheadModule, TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { ApiService } from '../../../../services/apiService';
import { Observable, Subject } from 'rxjs';

import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { listLocales } from 'ngx-bootstrap/chronos';
//import { } from '@types/googlemaps';
import { FileUploader, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';
import * as XLSX from 'xlsx';
import { takeUntil } from 'rxjs/operators';
import { QueryFactory } from '../../../../tableQueries/queryFactory';
import { GooglePlacesDirective } from '../../../../directives/google-places.directive';


declare var $: any;
type AOA = Array<Array<any>>;

function s2ab(s: string): ArrayBuffer {
    const buf: ArrayBuffer = new ArrayBuffer(s.length);
    const view: Uint8Array = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}



@Component({
    selector: 'app-agro',
    templateUrl: './agro.component.html',
    styleUrls: ['./agro.component.css'],
    providers: [ApiService],
    viewProviders: [GooglePlacesDirective]
})
export class AgroComponent implements OnInit {

    @ViewChild('gmap') gmapElement: any;
    public reDo = false;
    public URL;
    public alreadyUploaded;
    public URLBUCKET = "";
    public contest = 'contest';
    public contestType = 'public';
    public transferUrl = '';
    public autocompleteIndex;

    public manageReturn;
    public infoFiles: any = [];
    public fileUploaded = false;
    public USDCURRENCY: number;
    public uploaderRequest: FileUploader;
    public uploaderDocuments: FileUploader;
    /*VARIABLES DRAG N DROP*/
    public hasBaseDropZoneOver: boolean = false;
    public hasAnotherDropZoneOver: boolean = false;
    public fileToUploadName: String;
    public currentProductFileURL: String;
    data: any;
    map: google.maps.Map;
    viewMap = false;
    projectGeopoint: any;
    projectAddress: any;
    public securityCheck = true;
    public suppliesCheck = true;
    public tecnicsCheck = true;
    public valueCheck = true;

    public editar: any = 'true';
    public actualBranch = '';
    public userRole = '';
    public currentAddress = '';
    public currentLng: any;
    public currentLat: any;
    public marker: any;
    public location: any;
    public completingDraft = false;
    public editingProduct = false;
    public draftToCompletId = '';
    timeToReceiveStart = new Date();
    timeToReceiveEnd = new Date();
    timeToReceiveLimit = new Date();
    locale = 'en';
    locales = listLocales();
    public geoLocationEnabled;
    public states = [];
    // end variables
    addedComponent = false;
    @ViewChild('input')
    inputRef: ElementRef;
    public projects: Array<any>;
    public sectors: Array<any>;
    public selected: string;
    public typeaheadLoading: boolean;
    public typeaheadNoResults: boolean;
    public dataSource: Observable<any>;
    public dataSourceSub: Observable<any>;
    public dataSourceSubContest: Observable<any>;

    public requestSubcategoriesArray = [];
    public asyncSelected: string;
    public subCategory = {};
    public subCategoriesInExcel = [];
    public category = {};
    public searchText: string;
    public units: Array<any>;

    public specifications: Array<any>;
    sucursalDisabled = true;
    isContestPublic = true;
    excelUploaded = false;
    public arrayRequests: Array<any> = [];
    public savedDrafts = [];
    public request = {
        name: '',
        companyId: localStorage.getItem('$LoopBack$currentCompanyId'),
        userId: localStorage.getItem('$LoopBack$currentCompanyId'),
        sectorId: '',
        sectorName: '',
        whoRequesting: '',
        whoAuthorizes: '',
        products: [],
        isExpress: false,
        limitQuotationDate: new Date(),
        startDeliveryDate: new Date(),
        endDeliveryDate: new Date(),
        purchaseDate: new Date(),
        radius: 10,
        pickupRadius: false,
        stateId: '',
        branchId: '',
        selectedAddress: '',
        allCountry: false,
        status: 'draft',
        geopoint: {},
        projectName: '',
        projectId: '',
        details: '',
        paymentOptions: {},
        requestSubcategoriesArray: [],
        radioCheckVisit: true,
        radioCheckGeneral: true,
    };

    public paymentOptions = {
        anticipated: 0,
        uponDelivery: 0,
        credit: 0,
        days: 0

    }
    public sectorId;
    public folio;
    public requestSector;
    public requestRadius;
    public radioCheck = false;
    public radioCheckVisit = true;
    public radioCheckGeneral = true;
    public products = [];
    public newProduct = {
        requestId: '',
        subCategoryId: '',
        categoryId: '',
        productId: '',
        quantity: '',
        comments: '',
        specificationId: '',
        unitId: '',
        subCategory: '',
        category: '',
        name: '',
        specification: '',
        unitName: '',
        specificationName: '',
        projectId: '',
        productURL: '',
        companyId: localStorage.getItem("$LoopBack$currentCompanyId"),
        userId: localStorage.getItem("$LoopBack$currentUserId"),
    };
    public companiesInvited = [
        {
            name: '',
            email: '',
            phone: '',
            companyId: ''

        }];
    public selectedBranch: any = 'Selecciona una sucursal:';
    public cotizacionForm: UntypedFormGroup;
    public licitacionForm: UntypedFormGroup;

    public productForm: UntypedFormGroup;
    public subForm: UntypedFormGroup;

    public companyId;
    public radios = [10, 100, 1000];
    public indexOnEdit: number;
    //provisional variables
    public paso1: boolean;
    public paso2: boolean;
    public paso3: boolean;
    public datosgrales: Array<any>;
    public usuariosAdmon: Array<any>;
    public personal: Array<any>;
    public cotizacionregular: Array<any>;
    public branches: Array<any>;

    // Constructor
    constructor(private queryFactory: QueryFactory, private zone: NgZone, private localeService: BsLocaleService, private _router: Router, private route: ActivatedRoute, private spinner: NgxSpinnerService, private _apiService: ApiService) {
        this.URL = this._apiService.URL;
        this.URLBUCKET = this._apiService.URLBUCKET;
        this.uploaderRequest = new FileUploader({ url: "https://api.ixcco.com/v1.0/LocalContainers/temporalfiles/upload" });
        this.uploaderDocuments = new FileUploader({ url: "https://api.ixcco.com/v1.0/LocalContainers/temporalfiles/upload" });

        this.cotizacionForm = new UntypedFormGroup({
            'name': new UntypedFormControl('', [Validators.required]),
            'sectorSelect': new UntypedFormControl('5f356f31ce874abb21b1398f', Validators.required),
            'sectorId': new UntypedFormControl('', Validators.required),
            'project': new UntypedFormControl({}, Validators.required),
            'branch': new UntypedFormControl(''),
            'userRequested': new UntypedFormControl(''),
            'userAuthorize': new UntypedFormControl(''),

        });
        this.licitacionForm = new UntypedFormGroup({
            'name': new UntypedFormControl('', [Validators.required]),
            'project': new UntypedFormControl({}, Validators.required),


        });
        this.subForm = new UntypedFormGroup({
            'searchSubText': new UntypedFormControl(null),
            'subCategoryId': new UntypedFormControl(null),
            'subCategory': new UntypedFormControl('')


        });
        this.productForm = new UntypedFormGroup({
            'subCategoryId': new UntypedFormControl('', [Validators.required]),
            'categoryId': new UntypedFormControl(''),
            'productId': new UntypedFormControl("", Validators.required),
            'quantity': new UntypedFormControl("", Validators.required),
            'comments': new UntypedFormControl(""),
            'specificationId': new UntypedFormControl(""),
            'unitId': new UntypedFormControl(""),
            'category': new UntypedFormControl(''),
            'subCategory': new UntypedFormControl(''),
            'specification': new UntypedFormControl(''),
            'specificationName': new UntypedFormControl(''),
            'product': new UntypedFormControl(''),
            'unit': new UntypedFormControl(''),
            'unitSelect': new UntypedFormControl(''),
            'specificationSelect': new UntypedFormControl('', Validators.required),
            'searchText': new UntypedFormControl('')
        });
        let timer = null;

        this.cotizacionForm.controls['name'].statusChanges
            .subscribe(data => {
                clearTimeout(timer);
                timer = setTimeout(() => {
                    if (data === 'VALID') {
                        console.log(data);
                        let name = this.cotizacionForm.controls['name'].value;

                        this.titleCaseWord(name);
                        clearTimeout(timer);


                    }
                }, 500)



            });
        this.licitacionForm.controls['name'].statusChanges
            .subscribe(data => {
                clearTimeout(timer);
                timer = setTimeout(() => {
                    if (data === 'VALID') {
                        console.log(data);
                        let name = this.licitacionForm.controls['name'].value;

                        this.titleCaseWordLic(name);
                        clearTimeout(timer);


                    }
                }, 500)



            });
        this.productForm.controls['specificationSelect'].statusChanges
            .subscribe(data => {
                if (data === 'VALID') {
                    console.log(data);
                    let formato = this.productForm.controls['specificationSelect'].value;
                    this.productForm.controls['specificationId'].setValue(formato);

                    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
                    let filter = {
                        access_token: tokenId
                    };
                    this.getSpecificationName('Specifications', formato, filter);
                }

            });
        this.productForm.controls['unitSelect'].statusChanges
            .subscribe(data => {
                if (data === 'VALID') {
                    console.log(data);
                    let unidad = this.productForm.controls['unitSelect'].value;

                    this.productForm.controls['unitId'].setValue(unidad);

                    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
                    let filter = {
                        access_token: tokenId
                    };
                    this.getUnitName('Units', unidad, filter);
                    // }

                }

            });
        this.cotizacionForm.controls['sectorSelect'].statusChanges
            .subscribe(data => {
                console.log(data)
                if (data === 'VALID') {
                    let sector = this.cotizacionForm.controls['sectorSelect'].value;
                    this.sectorId = sector;
                    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
                    let filter = {

                        access_token: tokenId
                    };
                    this.getSectorName('Sectors', sector, filter);

                }
            });
        this.licitacionForm.controls['project'].statusChanges
            .subscribe(data => {
                if (data === 'VALID') {
                    let project = this.licitacionForm.controls['project'].value;

                    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
                    let filter = {

                        access_token: tokenId
                    };
                    this.getProjectName('Projects', project, filter);
                }
            });
        this.cotizacionForm.controls['project'].statusChanges
            .subscribe(data => {
                if (data === 'VALID') {
                    let project = this.cotizacionForm.controls['project'].value;

                    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
                    let filter = {

                        access_token: tokenId
                    };
                    this.getProjectName('Projects', project, filter);
                }
            });

        this.cotizacionForm.controls['branch'].statusChanges
            .subscribe(data => {
                console.log(data, 'BRANCHHH')
                if (data === 'VALID') {
                    let asd = this.cotizacionForm.controls['branch'].value;
                    console.log(asd)
                    this.actualBranch = data;
                }
            });
        this.paso1 = true;
        this.paso2 = false;
        this.paso3 = false;


        this.dataSource = new Observable((observer: any) => {
            console.log(this.autocompleteIndex)
            let textOnly = this.subCategoriesInExcel[this.autocompleteIndex]["autocomplete"];
            console.log(textOnly);
            let searchText = textOnly.toUpperCase();
            let lowerText = textOnly.toLowerCase();
            var capText = this.toTitleCase(textOnly);
            // Runs on every search
            let filters = {
                filter: {
                    where: {
                        and: [{ sectorId: this.sectorId },
                        {
                            or: [
                                { name: { like: searchText } },
                                { name: { like: lowerText } },
                                { name: { like: capText } },
                            ]
                        }
                        ],

                    }
                },
                access_token: localStorage.getItem('$LoopBack$accessTokenId')
            };
            this._apiService.getDataObjectsFilter('Products', filters).subscribe(
                (result: any) => {
                    console.log(result);
                    console.log(result.length);

                    var test = textOnly.split(' ');
                    console.log(test);
                    if (result.length === 0) {
                        console.log('0 RESULTS')
                        let i = 0;
                        let searchText = test[i].toUpperCase();
                        let lowerText = test[i].toLowerCase();
                        var capText = this.toTitleCase(test[i]);
                        // Runs on every search
                        let filters = {
                            filter: {
                                where: {
                                    and: [{ sectorId: this.sectorId },
                                    {
                                        or: [
                                            { name: { like: searchText } },
                                            { name: { like: lowerText } },
                                            { name: { like: capText } },
                                        ]
                                    }
                                    ],

                                }
                            },
                            access_token: localStorage.getItem('$LoopBack$accessTokenId')
                        };

                        this._apiService.getDataObjectsFilter('Products', filters).subscribe(
                            (result: any) => {

                                if (result.lenght === 0) {
                                    console.log('0 RESULTS --2')

                                    i++;
                                    let searchText = test[i].toUpperCase();
                                    let lowerText = test[i].toLowerCase();
                                    var capText = this.toTitleCase(test[i]);
                                    // Runs on every search
                                    let filters = {
                                        filter: {
                                            where: {
                                                and: [{ sectorId: this.sectorId },
                                                {
                                                    or: [
                                                        { name: { like: searchText } },
                                                        { name: { like: lowerText } },
                                                        { name: { like: capText } },
                                                    ]
                                                }
                                                ],

                                            }
                                        },
                                        access_token: localStorage.getItem('$LoopBack$accessTokenId')
                                    };
                                } else {
                                    observer.next(result);

                                }
                            }
                        );


                    } else {
                        observer.next(result);

                    }
                }
            );

        });

        this.dataSourceSub = new Observable((observer: any) => {
            console.log(this.autocompleteIndex)
            let textOnly = this.subCategoriesInExcel[this.autocompleteIndex]["autocomplete"];
            console.log(textOnly);
            let searchText = textOnly.toUpperCase();
            let lowerText = textOnly.toLowerCase();
            var capText = this.toTitleCase(textOnly);
            // Runs on every search

            let where = {
                //and: [{ categoryId: "5dbc653415d8e9eef62546f8" },
                and: [{ categoryId: "5c6d8d7c9e7b2301aba4b6d2" },
                {
                    or: [
                        { name: { like: searchText } },
                        { name: { like: lowerText } },
                        { name: { like: capText } },
                    ]
                }
                ],

            }
            const getDataQuery = this.queryFactory.generateGetQuery('SubCategories', where, 100, 0, null, []);

            this._apiService.getDataObjects(getDataQuery).subscribe(
                (result: any) => {
                    console.log(result);
                    console.log(result.length);

                    var test = textOnly.split(' ');
                    console.log(test);
                    if (result.length === 0) {
                        console.log('0 RESULTS')
                        let i = 0;

                        let searchText = test[i].toUpperCase();
                        let lowerText = test[i].toLowerCase();
                        var capText = this.toTitleCase(test[i]);
                        // Runs on every search
                        let filters = {
                            filter: {
                                where: {
                                    and: [{ categoryId: "5c6d8d7c9e7b2301aba4b6d2" },
                                    {
                                        or: [
                                            { name: { like: searchText } },
                                            { name: { like: lowerText } },
                                            { name: { like: capText } },
                                        ]
                                    }
                                    ],

                                }
                            },
                            access_token: localStorage.getItem('$LoopBack$accessTokenId')
                        };

                        this._apiService.getDataObjectsFilter('SubCategories', filters).subscribe(
                            (result: any) => {

                                if (result.lenght === 0) {
                                    console.log('0 RESULTS --2')

                                    i++;
                                    let searchText = test[i].toUpperCase();
                                    let lowerText = test[i].toLowerCase();
                                    var capText = this.toTitleCase(test[i]);
                                    // Runs on every search
                                    let filters = {
                                        filter: {
                                            where: {
                                                and: [{ sectorId: this.sectorId },
                                                {
                                                    or: [
                                                        { name: { like: searchText } },
                                                        { name: { like: lowerText } },
                                                        { name: { like: capText } },
                                                    ]
                                                }
                                                ],

                                            }
                                        },
                                        access_token: localStorage.getItem('$LoopBack$accessTokenId')
                                    };
                                } else {
                                    observer.next(result);

                                }
                            }
                        );




                        //}
                    } else {
                        observer.next(result);

                    }
                }
            );

        });

        this.dataSourceSubContest = new Observable((observer: any) => {
            console.log(this.autocompleteIndex)
            let textOnly = this.subCategoriesInExcel[this.autocompleteIndex]["autocomplete"];
            console.log(textOnly);
            let searchText = textOnly.toUpperCase();
            let lowerText = textOnly.toLowerCase();
            var capText = this.toTitleCase(textOnly);
            // Runs on every search

            let where = {
                and: [{ categoryId: "5dbc653415d8e9eef62546f8" },
                {
                    or: [
                        { name: { like: searchText } },
                        { name: { like: lowerText } },
                        { name: { like: capText } },
                    ]
                }
                ],

            }
            const getDataQuery = this.queryFactory.generateGetQuery('SubCategories', where, 100, 0, null, []);

            this._apiService.getDataObjects(getDataQuery).subscribe(
                (result: any) => {
                    console.log(result);
                    console.log(result.length);

                    var test = textOnly.split(' ');
                    console.log(test);
                    if (result.length === 0) {
                        console.log('0 RESULTS')
                        let i = 0;

                        let searchText = test[i].toUpperCase();
                        let lowerText = test[i].toLowerCase();
                        var capText = this.toTitleCase(test[i]);
                        // Runs on every search
                        let filters = {
                            filter: {
                                where: {
                                    and: [{ categoryId: "5c6d8d7c9e7b2301aba4b6d2" },
                                    {
                                        or: [
                                            { name: { like: searchText } },
                                            { name: { like: lowerText } },
                                            { name: { like: capText } },
                                        ]
                                    }
                                    ],

                                }
                            },
                            access_token: localStorage.getItem('$LoopBack$accessTokenId')
                        };

                        this._apiService.getDataObjectsFilter('SubCategories', filters).subscribe(
                            (result: any) => {

                                if (result.lenght === 0) {
                                    console.log('0 RESULTS --2')

                                    i++;
                                    let searchText = test[i].toUpperCase();
                                    let lowerText = test[i].toLowerCase();
                                    var capText = this.toTitleCase(test[i]);
                                    // Runs on every search
                                    let filters = {
                                        filter: {
                                            where: {
                                                and: [{ sectorId: this.sectorId },
                                                {
                                                    or: [
                                                        { name: { like: searchText } },
                                                        { name: { like: lowerText } },
                                                        { name: { like: capText } },
                                                    ]
                                                }
                                                ],

                                            }
                                        },
                                        access_token: localStorage.getItem('$LoopBack$accessTokenId')
                                    };
                                } else {
                                    observer.next(result);

                                }
                            }
                        );




                        //}
                    } else {
                        observer.next(result);

                    }
                }
            );


        });

    }

    populateExcel() {

        let headers = ['Codigo', 'Concepto', 'Unidad', 'Cantidad', 'Precio Unitario'];
        var arraysExcel = []
        this.request.products.forEach((productExcel) => {
            let objRow = {
                Codigo: productExcel.subCategory,
                Concepto: productExcel.name,
                Unidad: productExcel.unitName,
                Cantidad: productExcel.quantity,
                PU: ''

            }
            arraysExcel.push(objRow);

        });
        setTimeout(() => {
            this.exportAsExcelFile(arraysExcel, headers)

        }, 500)


    }
    public exportAsExcelFile(json: any[], headers: any, excelFileName?: string): void {
        console.log(json)
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        XLSX.writeFile(workbook, 'ExcelUsuario.xlsx');
    }



    //Method to be invoked everytime we receive a new instance 
    //of the address object from the onSelect event emitter.
    setAddress(geoPoint) {
        //We are wrapping this in a NgZone to reflect the changes
        //to the object in the DOM.
        this.zone.run(() => {

            this.currentLat = geoPoint.lat;
            this.currentLng = geoPoint.lng;
            this.currentAddress = geoPoint.place.formatted_address;
            this.request.selectedAddress = geoPoint.place.formatted_address;

            this.map.setCenter(new google.maps.LatLng(this.currentLat, this.currentLng));

            let pos = {
                coords: {
                    latitude: geoPoint.lat,
                    longitude: geoPoint.lng
                }
            }

            this.showMarkerPosition(pos);

            this.map.setZoom(12);

        });
    }
    cambio(i) {
        this.autocompleteIndex = i;
    }
    getCurrencies(type) {
        let where = {
            active: true
        };

        const getDataQuery = this.queryFactory.generateGetQuery(type, where, 100, 0, null, []);

        this._apiService.getDataObjects(getDataQuery).subscribe(
            (result: any) => {

                console.log(result);
                this.USDCURRENCY = Number(result[0].mxn)

            },
            error => {
                if (error != null) {
                    console.log(error);
                }
            }
        );
    }
    toTitleCase(str) {
        if (str) {
            return str.replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        } else {
            console.log(str)
        }

    }

    compareByID(itemOne, itemTwo) {
        return itemOne && itemTwo && itemOne.ID == itemTwo.ID;
    }

    isExpress(flag) {

        this.request.isExpress = flag;
        console.log(this.request.isExpress)
    }

    initMap(pos?) {
        this.viewMap = true;
        if (this.map) {
            this.map = null;
        }
        setTimeout(() => {
            var mapProp = {
                center: new google.maps.LatLng(20.651801, -103.405265),
                zoom: 10,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            };
            this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
        }, 1250);
        if (this.projectGeopoint) {
            let pos = {
                coords: {
                    latitude: this.projectGeopoint.lat,
                    longitude: this.projectGeopoint.lng
                }
            }
            this.currentLat = this.projectGeopoint.lat;
            this.currentLng = this.projectGeopoint.lng;
            if (this.map) {

            } else {
            }
            this.showMarkerPosition(pos);

            var geocoder = new google.maps.Geocoder;

            this.getAddressFromCoords(geocoder, this.map, pos);

        } else {
            this.getUserCurrentPosition();

        }

    }
    reInitMap(pos?) {
        if (this.map) {
            this.map = null;
        }
        setTimeout(() => {
            var mapProp = {
                center: new google.maps.LatLng(pos.lat, pos.lng),
                zoom: 8,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            };
            this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
            this.map.setCenter(pos);
            if (this.projectGeopoint) {
                let pos = {
                    coords: {
                        latitude: this.projectGeopoint.lat,
                        longitude: this.projectGeopoint.lng
                    }
                }
                if (this.map) {

                } else {
                }
                this.showMarkerPosition(pos);

                var geocoder = new google.maps.Geocoder;

                this.getAddressFromCoords(geocoder, this.map, pos);
            } else {
                this.getUserCurrentPosition();

            }
        }, 1250);


    }
    showMarkerPosition(position) {
        this.currentLat = position.coords.latitude;
        this.currentLng = position.coords.longitude;
        var myLatLng = { lat: this.currentLat, lng: this.currentLng };


        let location = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

        if (!this.map) {

            this.reInitMap(myLatLng);
        } else {

            this.map.setCenter(new google.maps.LatLng(this.currentLat, this.currentLng));
            if (!this.marker) {

                this.marker = new google.maps.Marker({

                    draggable: true,
                    animation: google.maps.Animation.DROP,
                    position: location,

                    map: this.map,
                    title: 'Tu Sucursal'
                });
                this.marker.setPosition(location);
                this.marker.addListener('dragend', (event) => {


                    this.currentLat = event.latLng.lat();
                    this.currentLng = event.latLng.lng();


                    this.map.setCenter(new google.maps.LatLng(this.currentLat, this.currentLng));
                    this.map.setZoom(8);

                    var geocoder = new google.maps.Geocoder;
                    this.getAddressFromCoords(geocoder, this.map, null);

                });

            }
            else {
                this.marker.setMap(null);
                this.marker = null;
                this.marker = new google.maps.Marker({

                    draggable: true,
                    animation: google.maps.Animation.DROP,
                    position: location,

                    map: this.map,
                    title: 'Tu Sucursal'
                });
                this.marker.setPosition(location);
                this.marker.addListener('dragend', (event) => {

                    this.currentLat = event.latLng.lat();
                    this.currentLng = event.latLng.lng();
                    this.map.setCenter(new google.maps.LatLng(this.currentLat, this.currentLng));
                    this.map.setZoom(8);

                    var geocoder = new google.maps.Geocoder;
                    this.getAddressFromCoords(geocoder, this.map, null);

                });

            }

        }



    }

    getUserCurrentPosition() {
        console.log('user position ')
        if (navigator.geolocation) {

            navigator.geolocation.getCurrentPosition((position) => {
                console.log('geolocation')
                this.geoLocationEnabled = true;
                console.log(position)

                localStorage.setItem('geo', JSON.stringify(position))
                console.log(JSON.stringify(position));

                this.currentLat = position.coords.latitude;
                this.currentLng = position.coords.longitude;

                if (this.map) {
                    console.log(this.map)
                    console.log('Map Ok')
                } else {
                    console.log('Map unidefined')
                }
                this.showMarkerPosition(position);

                var geocoder = new google.maps.Geocoder;

                this.getAddressFromCoords(geocoder, this.map, position);

            }, (error) => {
                this.geoLocationEnabled = false;
                if (error.code == error.PERMISSION_DENIED)
                    swal(
                        {
                            title: 'Advertencia!',
                            text: 'Importante, la geolocalización es necesaria para el funcionamiento de esta plataforma, para continuar por favor otorga los permisos al navegador y refresca la pagina.',
                            type: 'error',
                            confirmButtonClass: 'btn ',

                            confirmButtonColor: '#000'
                        });


            });
        } else {

            alert("Geolocation is not supported by this browser.");
        }
    }

    getAddressFromCoords(geocoder, map, position) {
        let latlng = { lat: this.currentLat, lng: this.currentLng };

        geocoder.geocode({ 'location': latlng }, (results, status) => {
            if (status === 'OK') {
                if (results) {
                    if (map) {
                        map.setZoom(16);

                    }


                    this.currentAddress = results[0].formatted_address;

                } else {
                    window.alert('No results found');
                }
            } else {
                window.alert('Geocoder failed due to: ' + status);
            }
        });
    }

    onChangeSucursal(value) {
        this.sucursalDisabled = false;
        this.request.branchId = value.id;
        this.request.geopoint = value.geoPoint;
        this.request.selectedAddress = value.address;
    }

    selectedAllCountry(e) {
        if (e.target.checked) {
            this.request.allCountry = true;

        }
        else {
            this.request.allCountry = false;

        }

    }

    applyLocale(pop: any) {
        this.localeService.use(this.locale);
        pop.hide();
        pop.show();
    }
    public fileOverBase1(e: any): void {
        clearTimeout(this.manageReturn);

        console.log(e)
        this.hasBaseDropZoneOver = e;

        this.manageReturn = setTimeout(() => {
            console.log(this.uploaderDocuments.queue);
            if (this.uploaderDocuments.queue) {
/*                 this.uploaderDocuments.uploadItem(this.uploaderDocuments.queue[0]);

                console.log(this.uploaderDocuments.queue[0]._file.name)


                let url = this.URLBUCKET + this.uploaderDocuments.queue[0]._file.name;
                this.infoFiles.push(url)
 */            }

        }, 250);

    }
    uploadFiles($event: any) {
        console.log($event.target.files[0])

        this.fileToUploadName = $event.target.files[0].name.toString();

        if (this.uploaderRequest.queue[0]) {
            this.uploadFile();
        }
    }
    uploadFilesDocuments($event: any) {
        console.log($event)

    }

    getFileExtension(filename) {
        return filename.substring(filename.lastIndexOf('.') + 1, filename.length).toString() || filename;

    }
    uploadFile(file?: any) {


        if (this.uploaderRequest.queue[0]) {
            this.currentProductFileURL = this.URLBUCKET + this.fileToUploadName;
            console.log(this.currentProductFileURL)
            //this.uploaderRequest.uploadItem(this.uploaderRequest.queue[0]);
            if (this.uploaderRequest.queue) {
                this.uploaderRequest.queue.forEach((file, index) => {
                    this.uploaderRequest.uploadItem(this.uploaderRequest.queue[index]);
                });

            }
            //this.uploaderRequest.queue[0].upload();
            console.log(this.URLBUCKET + this.fileToUploadName);
            swal({

                title: 'Correcto !',
                text: 'Componente Adjuntado correctamente',
                type: 'success',
                customClass: 'bordera',
                confirmButtonClass: 'btn ',

                confirmButtonColor: '#000'
            })

            this.addedComponent = true;

            setTimeout(() => {
                // if (this.uploaderRequest.queue.length > 1) {
                this.uploaderRequest.clearQueue()
                // }
            }, 1000);
        } else {
            console.log('No hay file')
        }


    }

    ngOnInit() {


        this.userRole = localStorage.getItem('$LoopBack$currentUserRole');
        this.companyId = localStorage.getItem('$LoopBack$currentCompanyId');

        this.getCountries('Countries', '');
        this.getStates('States', '')
        this.getBranches('branches', this.companyId)

        this.localeService.use('es');

        let where = {
            companyId: localStorage.getItem('$LoopBack$currentCompanyId')
        };
        this.getItems('Projects', where,
            result => {
                this.projects = result;
                if (result.length === 0) {
                    swal({
                        title: 'Alerta',
                        text: 'No hay Centro de Costo creados , Crear una nuevo Centro de Costo antes de continuar',
                        type: 'info',
                        confirmButtonClass: 'btn ',
                        confirmButtonColor: '#000'
                    }
                    )
                    this._router.navigate(['/empresa/', { isPerfil: true }]);

                }

            }
        );
        this.getItems('Sectors', {}, result => {
            this.sectors = result;
            console.log('sectors')
            console.log(this.sectors)
        }
        );
        let paramId = this.route
            .queryParams
            .subscribe(params => {
                console.log(params)
                // Defaults to 0 if no query param provided.
                this.draftToCompletId = params['draftId'] || '';
                this.folio = this.draftToCompletId;

                console.log(this.draftToCompletId);
                console.log(params);
                if (params['redo']) {
                    this.contest = params['contest'];
                    this.draftToCompletId = params['id'] || '';
                    this.folio = params['id'] || '';
                    console.log('rehacer contest o request')

                    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
                    let filter = {
                        filter: {
                            where: {
                                id: "5ade0eff9b235a7edf66e6ef"
                            },
                            include: {
                                relation: 'requestProducts',
                                filter: { include: ['specification', 'unit', 'category', 'subCategory'] },

                                scope: {
                                    order: 'indexArray ASC',
                                    include: ['subCategory', 'specification', 'unit', 'category']
                                }
                            }
                        },
                        access_token: tokenId
                    };

                    this.redoRequest('Requests', this.draftToCompletId, filter);


                } else {
                    console.log('draft');
                    if (this.draftToCompletId) {
                        console.log('complete Draft request')
                        this.getDraftToComplete('Requests', this.draftToCompletId, {});
                    }



                    console.log(params.editable);
                    if (params.editable) {
                        console.log('editable');
                        this.editar = params.editable.toString();
                        console.log(this.editar)
                        if (this.editar === 'false') {
                            console.log('NO ES EDITABLE' + this.editar);
                            console.log('paso 3');
                            this.paso1 = false;
                            this.paso2 = false;
                            this.paso3 = true;


                        }
                    } else {
                        console.log('Iniciacion completar')

                        this.editar = 'true';
                        if (this.paso2) {

                        }
                    }


                    let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
                    let filter = {
                        filter: {
                            where: {
                                id: "5ade0eff9b235a7edf66e6ef"
                            },
                            include: {
                                relation: 'requestProducts',
                                filter: { include: ['specification', 'unit', 'category', 'subCategory'] },

                                scope: {
                                    order: 'indexArray ASC',
                                    include: ['subCategory', 'specification', 'unit', 'category']
                                }
                            }
                        },
                        access_token: tokenId
                    };

                }


            });




    }



    getBranches(type, companyId) {
        let where = {
            companyId: companyId
        };

        const getDataQuery = this.queryFactory.generateGetQuery(type, where, 100, 0, null, []);
        this._apiService.getDataObjects(getDataQuery).subscribe(
            (result: any) => {
                this.branches = result



            },
            error => {
                console.log(error);

            }
        );
        /*     this._apiService.getDataObjectsFilter("Branches", JSON.stringify(filter))
                .pipe(takeUntil(this.onDestroy)).subscribe((result: any) => {
                    console.log(result)
                    this.branches = result
    
                },
                    error => {
                        if (error != null) {
                            console.log(error);
                        }
                    }
                ); */

    }


    selectedRadio(e: any) {

        if (e.target.checked) {
            this.request.pickupRadius = true;
            this.radioCheck = true
        }
        else {
            this.request.pickupRadius = false;
            this.radioCheck = false
        }

    }

    alertcotizacion() {
        swal("¡Excelente, tu solicitud ha sido realizada!", "Tu supervisor conframá la requisición antes de ser enviada", "success");
    }


    getItems(type, where, cb?) {
        var params = {
            filter: {
                where: where
            },
            access_token: localStorage.getItem('$LoopBack$accessTokenId')
        };
        const getDataQuery = this.queryFactory.generateGetQuery(type, where, 100, 0, null, []);
        this._apiService.getDataObjects(getDataQuery).subscribe(
            (result: any) => {
                console.log(result);
                cb(result);


            },
            error => {
                console.log(error);

            }
        );
        /*        this._apiService.getDataObjectsFilter(type, JSON.stringify(params)).subscribe(
                   (result: any) => {
                       cb(result);
       
                   },
                   error => {
                       console.log(error);
       
                   }
               ); */

    }


    limpia() {
        this.paso1 = !this.paso1;
        this.paso2 = !this.paso2;
    }

    limpia2() {

        this.paymentOptions.credit = this.paymentOptions.credit ? this.paymentOptions.credit : 0;
        this.paymentOptions.anticipated = this.paymentOptions.anticipated ? this.paymentOptions.anticipated : 0;
        this.paymentOptions.uponDelivery = this.paymentOptions.uponDelivery ? this.paymentOptions.uponDelivery : 0;
        this.paymentOptions.days = this.paymentOptions.days ? this.paymentOptions.days : 0;


        let payO = this.paymentOptions.credit + this.paymentOptions.anticipated + this.paymentOptions.uponDelivery;

        if (payO === 100) {

            console.log(this.request)
            console.log(this.radioCheckGeneral)
            console.log(this.radioCheckVisit)

            this.paso2 = !this.paso2;
            this.paso3 = !this.paso3;
        } else {

            if (payO > 100) {
                swal({
                    title: 'Error!',
                    text: 'Las condiciones de pago superan el 100%.',
                    type: 'error',
                    confirmButtonClass: 'btn ',

                    confirmButtonColor: '#000'
                })
            } else if (payO < 100) {
                swal({
                    title: 'Error!',
                    text: 'Las condiciones de pago  no cumplen el 100%.',
                    type: 'error',
                    confirmButtonClass: 'btn ',

                    confirmButtonColor: '#000'
                })
            }

        }
    }


    onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
        console.log(response)
        let data = JSON.parse(response); //success server response
    }
    changeVisitGeneral() {
        console.log(this.radioCheckGeneral);
        this.radioCheckGeneral = !this.radioCheckGeneral;
        this.request.radioCheckGeneral = !this.radioCheckGeneral;
        console.log(this.radioCheckGeneral);

    }
    changeVisit() {
        console.log(this.radioCheckVisit);
        this.radioCheckVisit = !this.radioCheckVisit;
        this.request.radioCheckVisit = !this.radioCheckVisit;
        console.log(this.radioCheckVisit);

    }
    saveBorrador() {

        if (this.fileUploaded || this.reDo) {
            if (this.request.products[0].subCategoryId) {
                //** find branch if is a Sucursal or Personale user **//
                if (this.userRole === 'Director' || this.userRole === 'Gerente') {
                    if (this.request.branchId === '') {
                        this.request.branchId = this.cotizacionForm.value.branch;
                    }

                } else {
                    this.request.branchId = localStorage.getItem('userBranchId');
                }
                //** done branch Id**/

                if (this.request.branchId) {
                    this.paso1 = false;
                    this.paso2 = true;
                    this.initMap();
                    console.log('SEPARATING BY CATEGORIES EXCEL');
                    console.log(this.request);
                    if (this.uploaderDocuments.queue) {
                        this.uploaderDocuments.queue.forEach((file, index) => {
                            this.uploaderDocuments.uploadItem(this.uploaderDocuments.queue[index]);


                            let url = this.URLBUCKET + this.uploaderDocuments.queue[index]._file.name;
                            let obj = {
                                name: this.uploaderDocuments.queue[index]._file.name,
                                url: this.URLBUCKET + this.uploaderDocuments.queue[index]._file.name
                            }
                            if (this.infoFiles.length) {
                                console.log(this.infoFiles);
                                if (this.infoFiles.some(e => e.name === obj.name)) {
                                } else { this.infoFiles.push(obj); }

                            }
                            else {

                                this.infoFiles = [obj];
                            }
                            if (this.transferUrl) {
                                let newobj = {
                                    name: 'ArchivoExterno',
                                    url: this.transferUrl
                                }
                                if (this.infoFiles.length) {
                                    console.log(this.infoFiles);
                                    if (this.infoFiles.some(e => e.name === newobj.name)) {
                                    } else { this.infoFiles.push(newobj); }

                                }
                                else {

                                    this.infoFiles = [newobj];
                                }
                            }
                            console.log('INFO FILES');
                            console.log(this.infoFiles);


                        });
                        this.alreadyUploaded = true;

                    }
                    this.request.requestSubcategoriesArray = this.requestSubcategoriesArray;
                    this.separateRequest()


                } else {

                    if (this.contest === "request") {
                        swal(
                            {
                                title: "¡Advertencia!",
                                text: "Por favor selecciona una sucursal antes de continuar",
                                type: "info",
                                confirmButtonClass: 'btn ',
                                confirmButtonColor: '#000'
                            });
                        $('#cotiza').animate({ scrollTop: 0 }, 'slow');
                    } else {
                        let name = this.licitacionForm.controls['name'].value;
                        console.log(name)
                        if (name === '') {
                            swal(
                                {
                                    title: "¡Advertencia!",
                                    text: "Por favor de agregar el nombre de la licitación",
                                    type: "info",
                                    confirmButtonClass: 'btn ',
                                    confirmButtonColor: '#000'
                                });
                        } else {


                            if (this.uploaderDocuments.queue) {
                                this.uploaderDocuments.queue.forEach((file, index) => {
                                    this.uploaderDocuments.uploadItem(this.uploaderDocuments.queue[index]);


                                    let url = this.URLBUCKET + this.uploaderDocuments.queue[index]._file.name;
                                    let obj = {
                                        name: this.uploaderDocuments.queue[index]._file.name,
                                        url: this.URLBUCKET + this.uploaderDocuments.queue[index]._file.name
                                    }
                                    if (this.infoFiles.length) {
                                        console.log(this.infoFiles);
                                        if (this.infoFiles.some(e => e.name === obj.name)) {
                                        } else { this.infoFiles.push(obj); }

                                    }
                                    else {

                                        this.infoFiles = [obj];
                                    }
                                    if (this.transferUrl) {
                                        let newobj = {
                                            name: 'ArchivoExterno',
                                            url: this.transferUrl
                                        }
                                        if (this.infoFiles.length) {
                                            console.log(this.infoFiles);
                                            if (this.infoFiles.some(e => e.name === newobj.name)) {
                                            } else { this.infoFiles.push(newobj); }

                                        }
                                        else {

                                            this.infoFiles = [newobj];
                                        }
                                    }
                                    console.log('INFO FILES');
                                    console.log(this.infoFiles);
                                });
                                this.alreadyUploaded = true;

                            }

                            console.log('Es licitacion');
                            this.paso1 = false;
                            this.paso2 = true;
                            this.initMap();
                            console.log('SEPARATING BY CATEGORIES EXCEL');
                            this.request.requestSubcategoriesArray = this.requestSubcategoriesArray;
                            console.log(this.request);

                            this.separateRequest()
                        }


                    }



                }
            } else {
                if (this.contest === "request") {
                    swal(
                        {
                            title: "Alerta!",
                            text: "Por favor selecciona una subcategoria antes de continuar",
                            type: "info",
                            confirmButtonClass: 'btn ',

                            confirmButtonColor: '#000'
                        });
                    console.log(this.request.products);
                    window.scroll(0, 0);
                } else {
                    console.log(this.request.products);
                    let name = this.licitacionForm.controls['name'].value;
                    console.log(name);
                    if (!this.request.products[0].subCategoryId) {
                        //LICITACION

                        swal(
                            {
                                title: "Alerta!",
                                text: "Por favor selecciona una subcategoria antes de continuar",
                                type: "info",
                                confirmButtonClass: 'btn ',
                                confirmButtonColor: '#000'
                            });
                        window.scroll(0, 0);
                    }
                    else if (name === '') {
                        swal(
                            {
                                title: "¡Advertencia!",
                                text: "Por favor de agregar el nombre de la licitación",
                                type: "info",
                                confirmButtonClass: 'btn ',
                                confirmButtonColor: '#000'
                            });
                    } else {
                        console.log('Es licitacion');
                        this.paso1 = false;
                        this.paso2 = true;
                        this.initMap();
                        console.log('SEPARATING BY CATEGORIES EXCEL');
                        if (this.uploaderDocuments.queue) {
                            this.uploaderDocuments.queue.forEach((file, index) => {
                                this.uploaderDocuments.uploadItem(this.uploaderDocuments.queue[index]);
                                let url = this.URLBUCKET + this.uploaderDocuments.queue[index]._file.name;
                                let obj = {
                                    name: this.uploaderDocuments.queue[index]._file.name,
                                    url: this.URLBUCKET + this.uploaderDocuments.queue[index]._file.name
                                }
                                this.infoFiles.push(obj)
                            });
                            this.alreadyUploaded = true;

                        }

                        console.log(this.request);
                        this.request.requestSubcategoriesArray = this.requestSubcategoriesArray;
                        this.separateRequest()
                    }


                }

            }

        } else {
            //Carga manual de productos


            //** find branch if is a Sucursal or Personal user **//
            if (this.userRole === 'Director' || this.userRole === 'Gerente') {
                if (this.request.branchId === '') {
                    this.request.branchId = this.cotizacionForm.value.branch;
                }
            } else {
                this.request.branchId = localStorage.getItem('userBranchId');
            }
            //** done branch Id**/

            if (this.request.branchId) {
                this.paso1 = false;
                this.paso2 = true;
                this.initMap();
                //Aqui se debe empezar la separacion
                console.log(this.request);
                console.log('SEPARATING BY CATEGORIES');

                console.log(this.request);
                this.request['contestType'] = this.contestType;
                this.request['companiesInvited'] = this.companiesInvited;
                this.request.requestSubcategoriesArray = this.requestSubcategoriesArray;
                this.separateRequest();


            } else {
                swal(
                    {
                        title: "¡Advertencia!",
                        text: "Por favor selecciona una sucursal antes de continuar",
                        type: "info",
                        confirmButtonClass: 'btn ',
                        confirmButtonColor: '#000'
                    });
                $('#cotiza').animate({ scrollTop: 0 }, 'slow');


            }



        }
    }

    saveSeparatedRequest(req, idx) {
        console.log(req);
        this.saveRequest_new(req, idx).then((id) => {
            console.log(req);
            let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
            let params = {
                access_token: tokenId
            };
            req.products.forEach((product, index) => {
                product.requestId = id;
                let newProduct = {
                    requestId: product.requestId,
                    subCategoryId: product.subCategoryId,
                    categoryId: product.categoryId,
                    productId: product.productId,
                    quantity: product.quantity,
                    comments: product.comments,
                    specificationId: product.specificationId,
                    unitId: product.unitId,
                    unitName: product.unitName,
                    specificationName: product.specificationName,
                    indexArray: index,
                    name: product.name,
                    productURL: product.productURL,
                    projectId: product.projectId,
                    currency: product.currency,
                    price: product.price,
                    code: product.code,
                    companyId: localStorage.getItem("$LoopBack$currentCompanyId"),
                    userId: localStorage.getItem("$LoopBack$currentUserId"),
                }

                product.subCategoryId2 = product.subCategoryId;
                this._apiService.addDataObject('RequestProducts', newProduct).subscribe(
                    result => {

                    },
                    error => {
                        if (error != null) {
                            console.log(error);


                        }
                    }
                );
            })
        });
    }
    copyRequest() {
        var arrayRequest = [];
        console.log(this.request);
        if (this.requestSubcategoriesArray.length > 0) {
            for (let subcategory of this.requestSubcategoriesArray) {
                let newRequest = JSON.stringify(this.request);
                let parsedRequest = JSON.parse(newRequest);
                parsedRequest['subcategoryFilter'] = subcategory.id;
                arrayRequest.push(parsedRequest);


            }

            console.log(arrayRequest);
        } else {
            let newRequest = JSON.stringify(this.request);
            let parsedRequest = JSON.parse(newRequest);
            arrayRequest.push(parsedRequest);
        }


        return arrayRequest;

    }
    async separateRequest() {
        var aarrayRequests = await this.copyRequest();
        console.log(aarrayRequests);
        setTimeout(() => {
            //  this.requestSubcategoriesArray.forEach((subcategory, index) => {


            for (let request of aarrayRequests) {
                if (request.products.some(product => product.subCategoryId === request.subcategoryFilter)) {
                    console.log("Object found inside the array.");
                    request.products = request.products.filter(
                        prod => prod.subCategoryId === request.subcategoryFilter);

                }



            }


        }, 100)

        setTimeout(() => {
            this.arrayRequests = aarrayRequests;
            aarrayRequests.forEach((requestTosave, idx) => {
                console.log(requestTosave);
                requestTosave['requiredFiles'] = {
                    securityCheck: this.securityCheck,
                    suppliesCheck: this.suppliesCheck,
                    tecnicsCheck: this.tecnicsCheck,
                    valueCheck: this.valueCheck,


                }

                let files = [];
                if (this.infoFiles.length) {
                    files = Array.from(new Set(this.infoFiles));

                }
                requestTosave['infoFiles'] = {
                    files: files
                };
                ;

                this.saveSeparatedRequest(requestTosave, idx);

            });
        }, 700)

    }
    multipleCreateAndSendRequest() {
        console.log(this.arrayRequests);
        this.arrayRequests.forEach((requestTosave, idx) => {
            console.log(requestTosave);
            this.createAndSendRequest(requestTosave);

        });
    }
    saveRequest_new(request, idx) {
        console.log(request)
        this.spinner.show();
        if (this.contest === 'request') {
            request.name = this.cotizacionForm.value.name

        } else {
            request.name = this.licitacionForm.value.name

        }
        request.companyId = localStorage.getItem('$LoopBack$currentCompanyId');
        request.userId = localStorage.getItem('$LoopBack$currentUserId');
        request.endDeliveryDate = this.timeToReceiveEnd;
        request.startDeliveryDate = this.timeToReceiveStart;
        request.limitQuotationDate = this.timeToReceiveLimit;
        let today = new Date()
        request.purchaseDate = today;
        request.whoAuthorizes = this.cotizacionForm.value.userAuthorize;
        request.whoRequesting = this.cotizacionForm.value.userRequested;
        //keeping reference of subcategories array in case only save the draft

        var promise = new Promise((resolve, reject) => {
            let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
            let params = {
                access_token: tokenId
            }
            this._apiService.addDataObject('Requests', request).subscribe(
                (result: any) => {
                    console.log(result);
                    this.savedDrafts.push(result);
                    this.arrayRequests[idx]['folio'] = result.id
                    setTimeout(() => {
                        this.spinner.hide();
                        // if(idx===0){
                        swal(
                            {
                                title: 'Guardado!',
                                text: 'Cambios guardados correctamente.',
                                type: 'success',
                                confirmButtonClass: 'btn ',

                                confirmButtonColor: '#000'
                            });
                        //}

                        resolve(result.id);


                    }, 500);
                },
                error => {
                    if (error != null) {
                        console.log(error);
                        setTimeout(() => {

                            this.spinner.hide()
                            swal(
                                {
                                    title: 'Error!',
                                    text: 'Error al Guardar.',
                                    type: 'error',
                                    confirmButtonClass: 'btn ',

                                    confirmButtonColor: '#000'
                                }
                            )
                            reject();


                        }, 250);

                    }
                }
            );
        });
        return promise;


    }
    completeBorrador() {
        this.paso1 = !this.paso1;
        this.paso2 = !this.paso2;
        this.initMap();

    }

    getSectorName(type: string, id: string, params: any) {

        this._apiService.getDataById(type, id, params).subscribe(
            (result: any) => {

                console.log('SECTOR NAME');
                this.request.sectorName = result.name;
                this.request.sectorId = result.id;
            },
            error => {
                if (error != null) {
                    console.log(error);
                }
            }
        );
    }

    getSpecificationName(type: string, id: string, params: any) {

        this._apiService.getDataById(type, id, params).subscribe(
            (result: any) => {

                console.log(result);
                console.log('spec NAME');
                this.productForm.controls['specificationName'].setValue(result.unit)
                this.productForm.controls['specification'].setValue(result.unit)

                this.productForm.controls['specificationId'].setValue(result.id)


            },
            error => {
                if (error != null) {
                    console.log(error);
                }
            }
        );
    }

    getUnitName(type: string, id: string, params: any) {
        if (id) {
            this._apiService.getDataById(type, id, params).subscribe(
                (result: any) => {

                    console.log(result);
                    console.log('unit NAME');
                    this.productForm.controls['unit'].setValue(result.name);
                    this.productForm.controls['unitId'].setValue(result.id);


                },
                error => {
                    if (error != null) {
                        console.log(error);
                    }
                }
            );
        }

    }

    getProjectName(type: string, id: string, params: any) {

        this._apiService.getDataById(type, id, params).subscribe(
            (result: any) => {

                console.log(result);
                console.log('PROJECT NAME');
                if (result.geopoint) {
                    this.projectGeopoint = result.geopoint;
                    console.log(this.projectGeopoint);

                }
                if (result.address) {
                    this.projectAddress = result.address;
                    this.currentAddress = result.address;

                }
                this.request.projectName = result.name;
                this.request.projectId = result.id
            },
            error => {
                if (error != null) {
                    console.log(error);
                }
            }
        );

    }

    getProduct(type: string, id: string, params: any, cb?) {

        this._apiService.getDataById(type, id, params).subscribe(
            result => {

                console.log(result);
                cb(result)


            },
            error => {
                if (error != null) {
                    console.log(error);
                }
            }
        );
    }
    reDoTemplate() {
        let request = this.request;

        let headers = ['Codigo', 'Concepto', 'Unidad', 'Cantidad', 'Precio Unitario'];
        var arraysExcel = []
        //arraysExcel.push(headers);
        this.request.products.forEach((productExcel) => {
            let objRow = {
                Codigo: productExcel.code,
                Concepto: productExcel.name,
                Unidad: productExcel.unitName,
                Cantidad: productExcel.quantity,
                PU: ''

            }
            arraysExcel.push(objRow);

        });
        setTimeout(() => {
            this.exportAsExcelFile(arraysExcel, headers)

        }, 500)
    }
    reDoTemplateRequest() {
        let request = this.request;

        let headers = ['Subcategoria', 'Nombre', 'Especificacion', 'Cantidad', 'Unidad', 'Marca'];
        var arraysExcel = []
        //arraysExcel.push(headers);
        /*   name: "mad"
          createdAt: "2020-04-01T22:36:43.990Z"
          indexArray: 0
          projectId: { }
          requestId: 1858
          amount: 0
          quantity: 100
          specificationName: "k"
          subCategoryId: "5c6d8d7c9e7b2301aba4b6da"
          unitName: "Unidad"
          companyId: "5b327dcc153cb8798362f50e"
          userId: "5b328652153cb8798362f50f"
          id: "5e85177b1696a52d37212224"
          subCategory: "ACERO DE REFUERZO Y ESTRUCTURAL"
          category: "" */
        this.request.products.forEach((productExcel) => {
            let objRow = {
                Subcategoria: productExcel.subCategory,
                Nombre: productExcel.name,
                Especificacion: productExcel.specificationName,
                Cantidad: productExcel.quantity,
                Unidad: productExcel.unitName,
                Marca: ''


            }
            arraysExcel.push(objRow);

        });
        setTimeout(() => {
            this.exportAsExcelFile(arraysExcel, headers)

        }, 500)
    }

    redoRequest(type: string, id: string, params: any) {
        console.log(id);
        this._apiService.getDataByRequest(type, id, ['requestProducts']).subscribe(
            (result: any) => {

                console.log(result);
                this.cotizacionForm.controls['name'].setValue(result.name);
                this.licitacionForm.controls['name'].setValue(result.name);
                this.reDo = true;
                this.contest = result.contestLabel;
                this.infoFiles = result.infoFiles;
                this.cotizacionForm.controls['project'].setValue(result.projectId);
                this.licitacionForm.controls['project'].setValue(result.projectId);

                this.cotizacionForm.controls['sectorSelect'].setValue(result.sectorId);
                this.cotizacionForm.controls['branch'].setValue(result.branchId);
                console.log(result);
                this.request.name = result.name;
                this.request.isExpress = false;
                this.timeToReceiveLimit = result.limitQuotationDate;
                this.timeToReceiveStart = result.startDeliveryDate;
                this.timeToReceiveEnd = result.endDeliveryDate;
                this.request.purchaseDate = result.purchaseDate;
                this.request.radius = result.radius;
                this.request.pickupRadius = result.pickupRadius;
                this.request.stateId = result.stateId;
                this.request.branchId = result.branchId;
                this.request.selectedAddress = result.selectedAddress;
                this.request.geopoint = result.geopoint;
                this.request.products = result.requestProducts;
                this.paymentOptions = result.paymentOptions;
                this.request.whoAuthorizes = result.whoAuthorizes;
                this.request.whoRequesting = result.whoRequesting;
                this.request.details = result.details;
                this.requestSubcategoriesArray = result.requestSubcategoriesArray;
                this.request.requestSubcategoriesArray = result.requestSubcategoriesArray;
                this.arrayRequests.push(this.request)

                this.request.products.forEach((product, index) => {
                    if (result.requestProducts[index].category) {
                        product.category = result.requestProducts[index].category.name;

                    } else {
                        product.category = '';
                    }
                    if (result.requestProducts[index].subCategory) {
                        product.subCategory = result.requestProducts[index].subCategory.name;
                    } else {
                        product.subCategory = '';
                    }



                    if (result.requestProducts[index].specification) {
                        if (result.requestProducts[index].specification.unit) {
                            product.specification = result.requestProducts[index].specification.unit;

                        } else {
                            product.specification = result.requestProducts[index].specification;

                        }

                    }
                    if (result.requestProducts[index].unit) {
                        if (result.requestProducts[index].unit.name) {
                            product.unitName = result.requestProducts[index].unit.name;

                        } else {
                            product.unitName = result.requestProducts[index].unitName;

                        }

                    }
                });
                if (result.requiredFiles) {

                    this.securityCheck = result.requiredFiles.securityCheck;
                    this.suppliesCheck = result.requiredFiles.suppliesCheck;
                    this.tecnicsCheck = result.requiredFiles.tecnicsCheck;
                    this.valueCheck = result.requiredFiles.valueCheck;

                }
                console.log(this.request);
                console.log('REDO request products')
                this.productForm.reset();
                this.request.products.sort((a, b) => (a.indexArray > b.indexArray) ? 1 : -1)


                console.log(this.request.products)

            },
            error => {
                if (error != null) {
                    console.log(error);
                }
            }
        );
        let where = {
            id: id
        };


    }

    getDraftToComplete(type: string, id: string, params: any) {
        console.log('draft to complete');
        this._apiService.getDataByRequest(type, id, params).subscribe(
            (result: any) => {
                this.cotizacionForm.controls['name'].setValue(result.name);
                this.cotizacionForm.controls['project'].setValue(result.projectId);
                this.cotizacionForm.controls['sectorSelect'].setValue(result.sectorId);
                this.cotizacionForm.controls['branch'].setValue(result.branchId);
                console.log(result);
                this.request.name = result.name;
                this.request['folio'] = result.id;

                this.request.isExpress = false;
                this.timeToReceiveLimit = result.limitQuotationDate;
                this.timeToReceiveStart = result.startDeliveryDate;
                this.timeToReceiveEnd = result.endDeliveryDate;
                this.request.purchaseDate = result.purchaseDate;
                this.request.radius = result.radius;
                this.request.pickupRadius = result.pickupRadius;
                this.request.stateId = result.stateId;
                this.request.branchId = result.branchId;
                this.request.selectedAddress = result.selectedAddress;
                this.request.geopoint = result.geopoint;
                this.request.products = result.requestProducts;
                this.paymentOptions = result.paymentOptions;
                this.request.whoAuthorizes = result.whoAuthorizes;
                this.request.whoRequesting = result.whoRequesting;
                this.request.details = result.details;
                this.request.requestSubcategoriesArray = result.requestSubcategoriesArray;
                let newRequest = JSON.stringify(this.request)
                this.arrayRequests.push(this.request)
                //new function separate requests
                /*                 this.separateRequest(newRequest);
                 */
                this.request.products.forEach((product, index) => {
                    if (result.requestProducts[index].category) {
                        product.category = result.requestProducts[index].category.name;

                    } else {
                        product.category = '';
                    }
                    if (result.requestProducts[index].subCategory) {
                        product.subCategory = result.requestProducts[index].subCategory.name;
                    } else {
                        product.subCategory = '';
                    }



                    if (result.requestProducts[index].specification) {
                        if (result.requestProducts[index].specification.unit) {
                            product.specification = result.requestProducts[index].specification.unit;

                        } else {
                            product.specification = result.requestProducts[index].specification;

                        }

                    }
                    if (result.requestProducts[index].unit) {
                        if (result.requestProducts[index].unit.name) {
                            product.unitName = result.requestProducts[index].unit.name;

                        } else {
                            product.unitName = result.requestProducts[index].unitName;

                        }

                    }
                });
                console.log(this.request);

                this.productForm.reset();

            },
            error => {
                if (error != null) {
                    console.log(error);
                }
            }
        );
    }

    editDraftProduct(product, index) {
        this.editingProduct = true;
        this.indexOnEdit = index;
        let whereUnit = {
            productId: product.productId
        };
        this.getItems('Units', whereUnit, result => {
            console.log(result, ' units');
            this.units = result;
        });
        let whereSpec = {
            productId: product.productId
        };
        this.getItems('Specifications', whereSpec, result => {
            console.log(result, ' test');

            this.specifications = result;
        });

        this.typeaheadOnEdit(product.id, product.specificationId)

        this.productForm.controls['searchText'].setValue(product.name);
        this.productForm.controls['quantity'].setValue(product.quantity);
        this.productForm.controls['categoryId'].setValue(product.categoryId);
        this.productForm.controls['category'].setValue(product.category);
        this.productForm.controls['subCategoryId'].setValue(product.subCategoryId);
        this.productForm.controls['subCategory'].setValue(product.subCategory);
        this.productForm.controls['comments'].setValue(product.comments);
        this.productForm.controls['specificationSelect'].setValue(product.specificationId);
        this.productForm.controls['specificationId'].setValue(product.specificationId);
        this.productForm.controls['specificationName'].setValue(product.specification);
        this.productForm.controls['unit'].setValue(product.unit);
        this.productForm.controls['unitId'].setValue(product.unitId);
        this.productForm.controls['unitSelect'].setValue(product.unitId);
        this.productForm.controls['productId'].setValue(product.productId);
        this.productForm.controls['product'].setValue(product.name);




    }

    limpia3() {
        this.completingDraft = true;
        this.paso1 = true;
        this.paso2 = false;
        this.paso3 = false;

    }

    //typehaead

    changeTypeaheadLoading(e: boolean): void {
        this.typeaheadLoading = e;
    }
    changeTypeaheadLoadingSub(e: boolean): void {
        this.typeaheadLoading = e;

    }


    // typeahead callback selected item
    typeaheadOnSelect(e: TypeaheadMatch): void {
        if (e.item.id) {
            let where = {
                productId: e.item.id
            };
            this.productForm.controls['productId'].setValue(e.item.id);
            this.productForm.controls['product'].setValue(e.item.name);
            // this.newProduct.productId = e.item.id;
            // this.newProduct.name = e.item.name;
            console.log(e, 'testttta');
            let whereSpec = {
                productId: e.item.id
            };
            this.getItems('Specifications', whereSpec, result => {
                console.log(result, ' test');

                this.productForm.controls['specificationSelect'].setValue(result[0].id);

                this.specifications = result;
            });
            let whereUnit = {
                productId: e.item.id
            };
            console.log(e.item)
            this.getItems('Units', whereUnit, result => {
                console.log(result, ' units');
                this.units = result;
                this.productForm.controls['unitSelect'].setValue(result[0].id);

            });
            let whereCategory = {
                id: e.item.subCategoryId
            };
            this.getItems('SubCategories', whereCategory, result => {
                console.log(result, ' test sub category');
                this.productForm.controls['subCategoryId'].setValue(result[0].id);
                this.productForm.controls['subCategory'].setValue(result[0].name);
                /* this.newProduct.subCategoryId = result[0].id;
                this.newProduct.subCategory = result[0].name; */
                this.subCategory = result[0];
                console.log(this.newProduct);
                this.getItems('Categories', { id: result[0].categoryId }, result => {
                    console.log(result, ' test sub category');
                    this.productForm.controls['categoryId'].setValue(result[0].id);
                    this.productForm.controls['category'].setValue(result[0].name);
                    /*   this.newProduct.categoryId = result[0].id;
                      this.newProduct.category = result[0].name; */
                    this.category = result[0];
                    console.log(this.newProduct);
                });
            });

        }
    }
    typeaheadOnSelectSub(e: TypeaheadMatch): void {
        if (e.item.id) {
            let where = {
                subCategoryId: e.item.id
            };
            this.subForm.controls['subCategoryId'].setValue(e.item.id);
            this.subForm.controls['subCategory'].setValue(e.item.name);


            this.request.products.forEach((product) => {
                product['subCategoryId'] = this.subForm.controls['subCategoryId'].value;
                product['subCategory'] = this.subForm.controls['subCategory'].value;

            });
            console.log(this.request.products)


        }
    }

    typeaheadOnSelectSubNew(e: TypeaheadMatch, index, excelCat): void {
        console.log(e)
        console.log(excelCat)


        if (e.item.id) {
            this.request.products.forEach((product, idx) => {

                if (product.subCategoryExcel === excelCat) {
                    this.request.products[idx].subCategoryId = e.item.id;
                    this.request.products[idx].subCategory = e.item.name;
                    this.request.products[idx].autocomplete = e.item.name;
                } else {
                    console.log(product.subCategoryExcel)

                    console.log('no substitution')
                    console.log(excelCat)

                }
            })


            /*
                  Pushing the subcategorie to an array of subcategories
                  for further processing request depending on the length of this array
              */
            console.log('Added product');
            console.log(e.item.name)

            if (this.requestSubcategoriesArray.length) {
                if (this.requestSubcategoriesArray.some(subcategorie => subcategorie.id === e.item.id)) {
                    console.log('already in array');

                }
                else {
                    if (this.requestSubcategoriesArray.some(subcategorie => subcategorie.index === index)) {
                        console.log('already index');

                        this.requestSubcategoriesArray.forEach((subc) => {
                            if (subc.index === index) {
                                this.requestSubcategoriesArray[index].id = e.item.id;
                                this.requestSubcategoriesArray[index].name = e.item.name;
                                this.requestSubcategoriesArray[index].index = index;

                            }

                        });
                    }
                    else {
                        console.log('push');
                        console.log({
                            id: e.item.id,
                            name: e.item.name,
                            index: index
                        })
                        if (index === 0) {
                            this.requestSubcategoriesArray.push({
                                id: e.item.id,
                                name: e.item.name,
                                index: 0
                            }
                            );
                        } else {
                            this.requestSubcategoriesArray.push({
                                id: e.item.id,
                                name: e.item.name,
                                index: index
                            }
                            );
                        }


                    }


                }//end of else
                // }
            } else {
                this.requestSubcategoriesArray.push({
                    id: e.item.id,
                    name: e.item.name,
                    index: index

                }
                );

            }



        }
        console.log(this.requestSubcategoriesArray)
        console.log(this.request.products)

    }

    // typeahead callback selected item
    typeaheadOnEdit(productId, specId): void {
        console.log('ejecute este');
        console.log(specId)
        let subcategoryId;
        let categoryId;
        if (productId) {
            let where = {
                productId: productId
            };
            this.productForm.controls['productId'].setValue(productId);

            let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
            let filter = {

                access_token: tokenId
            };
            this.getItems('Specifications', {}, result => {
                console.log(result, ' testedit');
                this.specifications = result;
                this.productForm.controls['specificationSelect'].setValue(specId);
                this.productForm.controls['specificationId'].setValue(specId);


            });
            this.getProduct('RequestProducts', productId, filter, result => {
                subcategoryId = result.subcategoryId;
                categoryId = result.categoryId;

                this.productForm.controls['productId'].setValue(productId);


                let whereCategory = {
                    id: subcategoryId
                };
                this.getItems('SubCategories', whereCategory, result => {
                    console.log(result, ' test sub category');
                    this.productForm.controls['subCategoryId'].setValue(result[0].id);
                    this.productForm.controls['subCategory'].setValue(result[0].name);
                    this.newProduct.subCategoryId = result[0].id;
                    this.newProduct.subCategory = result[0].name;
                    this.subCategory = result[0];
                    console.log(this.newProduct);
                    this.getItems('Categories', { id: result[0].categoryId }, result => {
                        console.log(result, ' test sub category');
                        this.productForm.controls['categoryId'].setValue(result[0].id);
                        this.productForm.controls['category'].setValue(result[0].name);
                        this.newProduct.categoryId = result[0].id;
                        this.newProduct.category = result[0].name;
                        this.category = result[0];
                        console.log(this.newProduct);
                    });
                });

            });


        }
    }


    saveRequest() {
        this.spinner.show();
        this.request.name = this.cotizacionForm.value.name
        this.request.companyId = localStorage.getItem('$LoopBack$currentCompanyId');
        this.request.userId = localStorage.getItem('$LoopBack$currentUserId');
        this.request.endDeliveryDate = this.timeToReceiveEnd;
        this.request.startDeliveryDate = this.timeToReceiveStart;
        this.request.limitQuotationDate = this.timeToReceiveLimit;
        let today = new Date()
        this.request.purchaseDate = today;

        this.request.whoAuthorizes = this.cotizacionForm.value.userAuthorize;
        this.request.whoRequesting = this.cotizacionForm.value.userRequested;
        //keeping reference of subcategories array in case only save the draft
        console.log(this.requestSubcategoriesArray)
        this.request.requestSubcategoriesArray = this.requestSubcategoriesArray;
        console.log(this.request);

        var promise = new Promise((resolve, reject) => {
            let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
            let params = {
                access_token: tokenId
            }
            this._apiService.addDataObject('Requests', this.request).subscribe(
                (result: any) => {
                    console.log(result);
                    setTimeout(() => {
                        this.spinner.hide()
                        swal(
                            {
                                title: 'Guardado!',
                                text: 'Cambios guardados correctamente.',
                                type: 'success',
                                confirmButtonClass: 'btn ',

                                confirmButtonColor: '#000'
                            }
                        )
                        resolve(result.id);


                    }, 500);
                },
                error => {
                    if (error != null) {
                        console.log(error);
                        setTimeout(() => {

                            this.spinner.hide()
                            swal(
                                {
                                    title: 'Error!',
                                    text: 'Error al Guardar.',
                                    type: 'error',
                                    confirmButtonClass: 'btn ',

                                    confirmButtonColor: '#000'
                                }
                            )
                            reject();


                        }, 250);

                    }
                }
            );
        });
        return promise;


    }

    createAndSendRequest(req) {
        let request = {
            // id:'',
            name: '',
            companyId: localStorage.getItem('$LoopBack$currentCompanyId'),
            userId: localStorage.getItem('$LoopBack$currentCompanyId'),
            sectorId: '',
            sectorName: '',
            whoRequesting: '',
            whoAuthorizes: '',
            products: [],
            isExpress: false,
            limitQuotationDate: new Date(),
            startDeliveryDate: new Date(),
            endDeliveryDate: new Date(),
            purchaseDate: new Date(),
            radius: 10,
            pickupRadius: false,
            stateId: '',
            branchId: '',
            selectedAddress: '',
            allCountry: false,
            status: 'draft',
            geopoint: { lat: this.currentLat, lng: this.currentLng },
            projectName: '',
            projectId: '',
            details: '',
            paymentOptions: {},
            requestSubcategoriesArray: this.requestSubcategoriesArray
        }
        this.spinner.show();
        request.name = this.cotizacionForm.value.name
        request.sectorId = this.cotizacionForm.value.sectorId
        request.companyId = localStorage.getItem('$LoopBack$currentCompanyId');
        request.userId = localStorage.getItem('$LoopBack$currentUserId');
        request.endDeliveryDate = this.timeToReceiveEnd;
        request.startDeliveryDate = this.timeToReceiveStart;
        request.limitQuotationDate = this.timeToReceiveLimit;
        request.selectedAddress = this.currentAddress;
        let today = new Date()
        request.purchaseDate = today;
        request.paymentOptions = this.paymentOptions;
        request.branchId = this.cotizacionForm.value.branch;
        request.details = this.request.details;
        request['id'] = req.folio;
        request['contestType'] = this.contestType;
        request['contestLabel'] = this.contest;

        request['requiredFiles'] = {
            securityCheck: this.securityCheck,
            suppliesCheck: this.suppliesCheck,
            tecnicsCheck: this.tecnicsCheck,
            valueCheck: this.valueCheck,
        }
        let files = [];
        console.log(this.infoFiles);
        if (this.infoFiles.length) {
            files = Array.from(new Set(this.infoFiles));
            console.log(files);

        }
        request['infoFiles'] = {
            files: files
        };
        request['radioCheckGeneral'] = this.radioCheckGeneral;
        request['radioCheckVisit'] = this.radioCheckVisit;

        request['companiesInvited'] = this.companiesInvited;
        if (this.projectGeopoint) {

            this.currentLat = this.projectGeopoint.lat;
            this.currentLng = this.projectGeopoint.lng;
        }
        var data = {
            requestId: Number(req.folio),
            requestToSave: request,
            userId: request.userId,
            geoPoint: { lat: this.currentLat, lng: this.currentLng }

        };
        request.geopoint = { lat: this.currentLat, lng: this.currentLng }

        console.log('aASDASDASDASDASDASD')
        console.log(request);
        if (request.geopoint) {

        } else {
            request.geopoint = { lat: '20.651801', lng: '-103.405265' }
        }

        let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
        let params = {
            access_token: tokenId
        }
        console.log(data);

        this._apiService.addDataObjectCustom('Requests/createAndSendRequest', data, params).subscribe(
            result => {
                console.log(result);
                setTimeout(() => {
                    this.spinner.hide()
                    swal(
                        {
                            title: 'Guardado!',
                            text: 'Cambios guardados correctamente.',
                            type: 'success',
                            confirmButtonClass: 'btn ',
                            confirmButtonColor: '#000'
                        }
                    )
                    this._router.navigate(['/enviadas/']);


                }, 500);
            },
            error => {
                if (error != null) {
                    console.log(error);
                    setTimeout(() => {

                        this.spinner.hide()
                        swal(
                            {
                                title: 'Error!',
                                text: 'Error al Guardar.',
                                type: 'error',
                                confirmButtonClass: 'btn ',

                                confirmButtonColor: '#000'
                            }
                        )


                    }, 250);

                }
            }
        );


    }


    saveProduct() {

        this.saveRequest().then((id) => {
            console.log(id);

            console.log(this.newProduct)
            let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
            let params = {
                access_token: tokenId
            }
            this._apiService.addDataObject('RequestProducts', this.newProduct).subscribe(
                result => {
                    setTimeout(() => {
                        this.spinner.hide()
                        swal(
                            {
                                title: 'Guardado!',
                                text: 'Cambios guardados correctamente.',
                                type: 'success',
                                confirmButtonClass: 'btn ',
                                confirmButtonColor: '#000'
                            }
                        )
                        this.productForm.reset();

                    }, 500);
                },
                error => {
                    if (error != null) {
                        setTimeout(() => {
                            this.spinner.hide()
                            swal(
                                {
                                    title: 'Error!',
                                    text: 'Error al Guardar.',
                                    type: 'error',
                                    confirmButtonClass: 'btn ',
                                    confirmButtonColor: '#000'
                                }
                            )
                            this.productForm.reset();


                        }, 250);

                    }
                }
            );
        })


    }

    addProduct() {
        console.log('Add product')

        var url;
        if (this.currentProductFileURL) {
            url = this.currentProductFileURL.toString()
        } else {
            url = "";
        }

        this.newProduct = {
            requestId: '',
            subCategoryId: this.productForm.value.subCategoryId,
            categoryId: this.productForm.value.categoryId,
            productId: this.productForm.value.productId,
            quantity: this.productForm.value.quantity,
            comments: this.productForm.value.comments,
            specificationId: this.productForm.value.specificationSelect,
            unitId: this.productForm.value.unitSelect,
            subCategory: this.productForm.value.subCategory,
            category: this.productForm.value.category,
            name: this.productForm.value.product,
            specificationName: this.productForm.value.specificationName,
            unitName: this.productForm.value.unit,
            specification: this.productForm.value.specification,
            projectId: this.cotizacionForm.value.project,
            productURL: url,
            companyId: localStorage.getItem("$LoopBack$currentCompanyId"),
            userId: localStorage.getItem("$LoopBack$currentUserId"),

        };
        this.request.products.push(this.newProduct);
        this.products.push(this.newProduct);
        /*
        Pushing the subcategorie to an array of subcategories
        for further processing request depending on the length of this array
        */
        console.log('Added product')
        if (this.requestSubcategoriesArray.length) {
            for (var g = 0; g < this.requestSubcategoriesArray.length; g++) {
                if (this.requestSubcategoriesArray[g].id == this.productForm.value.subCategoryId) {
                    console.log('already in array');
                }
                else {
                    this.requestSubcategoriesArray.push({
                        id: this.productForm.value.subCategoryId,
                        name: this.productForm.value.subCategory,

                    }
                    );

                }//end of else
            }
        } else {
            this.requestSubcategoriesArray.push({
                id: this.productForm.value.subCategoryId,
                name: this.productForm.value.subCategory,

            }
            );
        }
        console.log(this.requestSubcategoriesArray)
        console.log(this.request)

        /*---------------------   */


        this.searchText = '';
        this.currentProductFileURL = "";
        this.units = [];
        this.specifications = [];
        this.productForm.reset();
        this.addedComponent = false;


    }

    onFileChange(evt: any) {
        //this.spinner.show();

        /* wire up file reader */
        const target: DataTransfer = <DataTransfer>(evt.target);
        if (target.files.length !== 1) throw new Error('Cannot use multiple files');
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
            /* read workbook */
            const bstr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

            /* grab first sheet */
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];

            /* save data */
            this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
            console.log(this.data);
            // console.log(this.data[1]);
            const columns = JSON.stringify(this.data[0]);
            const excel = JSON.stringify(this.data);
            console.log(excel)
            this.processExcelData(this.data);
            this.fileUploaded = true
            // this.process_data(this.data);
            // this.router.navigate(['excel/', { columnas: columns, data: excel }]);
        };
        reader.readAsBinaryString(target.files[0]);
    }

    onFileChangeRedo(evt: any) {
        //this.spinner.show();

        /* wire up file reader */
        const target: DataTransfer = <DataTransfer>(evt.target);
        if (target.files.length !== 1) throw new Error('Cannot use multiple files');
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
            /* read workbook */
            const bstr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

            /* grab first sheet */
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];

            /* save data */
            this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
            console.log(this.data);
            // console.log(this.data[1]);
            const columns = JSON.stringify(this.data[0]);
            const excel = JSON.stringify(this.data);
            console.log(excel)
            this.processExcelDataRedo(this.data);
            this.fileUploaded = true
            // this.process_data(this.data);
            // this.router.navigate(['excel/', { columnas: columns, data: excel }]);
        };
        reader.readAsBinaryString(target.files[0]);
    }
    onFileChangeContest(evt: any) {

        /* wire up file reader */
        const target: DataTransfer = <DataTransfer>(evt.target);
        if (target.files.length !== 1) throw new Error('Cannot use multiple files');
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
            /* read workbook */
            const bstr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

            /* grab first sheet */
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];

            /* save data */
            this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
            console.log(this.data);
            // console.log(this.data[1]);
            const columns = JSON.stringify(this.data[0]);
            const excel = JSON.stringify(this.data);
            console.log(excel)
            this.processExcelDataContest(this.data);
            this.fileUploaded = true

        };
        reader.readAsBinaryString(target.files[0]);
    }
    onFileChangeContestRedo(evt: any) {
        //this.spinner.show();

        /* wire up file reader */
        const target: DataTransfer = <DataTransfer>(evt.target);
        if (target.files.length !== 1) throw new Error('Cannot use multiple files');
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
            /* read workbook */
            const bstr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

            /* grab first sheet */
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];

            /* save data */
            this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
            console.log(this.data);
            // console.log(this.data[1]);
            const columns = JSON.stringify(this.data[0]);
            const excel = JSON.stringify(this.data);
            console.log(excel)
            this.processExcelDataContestRedo(this.data);
            this.fileUploaded = true

        };
        reader.readAsBinaryString(target.files[0]);
    }
    processExcelData(data) {
        console.log(data);
        /*         1: "Especificación"
         */
        if (data[0][0] === "Subcategoria") {
            var invalidQty = 0;
            data.forEach((productExcel, index) => {
                if (index !== 0) {
                    if (productExcel[0]) {
                        if (isNaN(productExcel[3])) {
                            invalidQty++;
                            console.log('isnan')
                        }

                        let newProduct = {
                            requestId: '',
                            quantity: isNaN(productExcel[3]) ? null : productExcel[3].toFixed(2),
                            comments: productExcel[5],
                            name: productExcel[1],
                            specification: productExcel[2],
                            specificationName: productExcel[2],
                            unitName: productExcel[4],
                            subCategory: productExcel[0],
                            subCategoryExcel: productExcel[0],
                            projectId: this.cotizacionForm.value.project,
                            companyId: localStorage.getItem("$LoopBack$currentCompanyId"),
                            userId: localStorage.getItem("$LoopBack$currentUserId"),


                        };
                        newProduct['autocomplete'] = '';
                        this.request.products.push(newProduct);
                        if (this.subCategoriesInExcel.some(subcategorie => subcategorie.cat === productExcel[0])) {
                            console.log('already in array');
                        }
                        else {
                            this.subCategoriesInExcel.push(
                                {
                                    cat: productExcel[0],
                                    autocomplete: ''
                                }
                            );

                        }


                    }

                }


            });
            console.log(this.request.products);
            console.log(this.subCategoriesInExcel);
            console.log('invalidQTY----' + invalidQty);
            if (invalidQty > 0) {
                swal(
                    {
                        title: 'Advertencia!',
                        text: 'Importante, parece que las cantidades estan mal por favor revisa la plantilla.',
                        type: 'error',
                        confirmButtonClass: 'btn ',

                        confirmButtonColor: '#000'
                    });
                this.spinner.hide();
                this.excelUploaded = false;
                this.request.products = [];
                this.subCategoriesInExcel = [];

            } else {
                this.spinner.hide();
                this.excelUploaded = true;
                document.getElementById('table').scrollIntoView();

            }


        } else {
            swal(
                {
                    title: 'Advertencia!',
                    text: 'Importante, parece que estas usando una plantilla equivocada.',
                    type: 'error',
                    confirmButtonClass: 'btn ',

                    confirmButtonColor: '#000'
                });

        }

    }
    processExcelDataRedo(data) {
        this.request.products = [];

        console.log(data);
        /*         1: "Especificación"
         */
        if (data[0][0] === "Subcategoria") {


            var invalidQty = 0;
            data.forEach((productExcel, index) => {
                if (index !== 0) {
                    if (productExcel[0]) {
                        if (isNaN(productExcel[3])) {
                            invalidQty++;
                        }
                        let newProduct = {
                            requestId: '',
                            quantity: productExcel[3].toFixed(2),
                            comments: productExcel[5],
                            name: productExcel[1],
                            specification: productExcel[2],
                            specificationName: productExcel[2],
                            unitName: productExcel[4],
                            subCategory: productExcel[0],
                            subCategoryExcel: productExcel[0],
                            projectId: this.cotizacionForm.value.project,
                            companyId: localStorage.getItem("$LoopBack$currentCompanyId"),
                            userId: localStorage.getItem("$LoopBack$currentUserId"),


                        };
                        newProduct['autocomplete'] = '';
                        this.request.products.push(newProduct);
                        if (this.subCategoriesInExcel.some(subcategorie => subcategorie.cat === productExcel[0])) {
                            console.log('already in array');
                        }
                        else {
                            this.subCategoriesInExcel.push(
                                {
                                    cat: productExcel[0],
                                    autocomplete: ''
                                }
                            );

                        }


                    }

                }


            });
            console.log(this.request.products)
            console.log(this.subCategoriesInExcel)
            if (invalidQty > 0) {
                swal(
                    {
                        title: 'Advertencia!',
                        text: 'Importante, parece que las cantidades estan mal por favor revisa la plantilla.',
                        type: 'error',
                        confirmButtonClass: 'btn ',

                        confirmButtonColor: '#000'
                    });
                this.spinner.hide();
                this.excelUploaded = false;
                this.request.products = [];
                this.subCategoriesInExcel = [];

            } else {
                this.spinner.hide();
                this.excelUploaded = true;
                document.getElementById('table').scrollIntoView();




            }


        } else {
            swal(
                {
                    title: 'Advertencia!',
                    text: 'Importante, parece que estas usando una plantilla equivocada.',
                    type: 'error',
                    confirmButtonClass: 'btn ',

                    confirmButtonColor: '#000'
                });

        }

    }
    processExcelDataContest(data) {
        console.log(data)

        data.forEach((productExcel, index) => {
            if (productExcel) {
                let concept = {
                    requestId: '',
                    quantity: Number(productExcel[3]).toFixed(2),
                    comments: '',
                    name: productExcel[1],
                    specification: 'NA',
                    specificationName: 'NA',
                    unitName: productExcel[2],
                    price: Number(productExcel[4]).toFixed(2),
                    subCategory: productExcel[6],
                    code: productExcel[0],
                    subCategoryExcel: productExcel[6],
                    projectId: this.cotizacionForm.value.project,
                    companyId: localStorage.getItem("$LoopBack$currentCompanyId"),
                    userId: localStorage.getItem("$LoopBack$currentUserId"),

                };
                if (index !== 0) {
                    if (this.subCategoriesInExcel.some(subcategorie => subcategorie.cat === productExcel[6])) {
                        console.log('already in array');

                    } else {
                        console.log('toAddCat' + productExcel[6]);

                        if (productExcel[6]) {
                            this.subCategoriesInExcel.push(
                                {
                                    cat: productExcel[6],
                                    autocomplete: ''
                                }
                            );
                        }



                    }
                }
                if (index !== 0) {
                    if (productExcel[0] && productExcel[1] && productExcel[2]
                        && productExcel[3] && productExcel[4] && productExcel[6]) {
                        this.request.products.push(concept);


                    }
                }



            }


        });
        console.log(this.subCategoriesInExcel)

        console.log(this.request.products)
        document.getElementById('table').scrollIntoView();


        this.spinner.hide();
        this.excelUploaded = true;

    }
    processExcelDataContestRedo(data) {
        this.request.products = [];
        data.forEach((productExcel, index) => {
            //  if (index !== 0) {
            if (productExcel) {
                let concept = {

                    requestId: '',
                    quantity: Number(productExcel[3]).toFixed(2),
                    comments: '',
                    name: productExcel[1],
                    specification: 'NA',
                    specificationName: 'NA',
                    unitName: productExcel[2],
                    price: Number(productExcel[4]).toFixed(2),
                    subCategory: productExcel[6],
                    code: productExcel[0],
                    subCategoryExcel: productExcel[6],
                    projectId: this.cotizacionForm.value.project,
                    companyId: localStorage.getItem("$LoopBack$currentCompanyId"),
                    userId: localStorage.getItem("$LoopBack$currentUserId"),

                };
                if (index !== 0) {
                    if (this.subCategoriesInExcel.some(subcategorie => subcategorie.cat === productExcel[6])) {
                        console.log('already in array');

                    } else {
                        console.log('toAddCat' + productExcel[6]);

                        if (productExcel[6]) {
                            this.subCategoriesInExcel.push(
                                {
                                    cat: productExcel[6],
                                    autocomplete: ''
                                }
                            );
                        }



                    }
                }
                if (index !== 0) {
                    if (productExcel[0] && productExcel[1] && productExcel[2]
                        && productExcel[3] && productExcel[4] && productExcel[6]) {
                        this.request.products.push(concept);


                    }
                }



            }


        });
        console.log(this.request.products)
        document.getElementById('table').scrollIntoView();


        this.spinner.hide();
        this.excelUploaded = true;

    }
    isString(val) {
        return typeof val === 'string';
    }
    setFocusAutocomplete(index) {
        document.getElementById("subCategory" + index).focus();

    }
    setFocusAutocompleteGeneric(id) {
        document.getElementById(id).focus();

    }
    changeContest() {
        console.log(this.contest)
    }
    changeContestPublic() {
        console.log(this.contestType)
    }
    checkIfInvitedExist(emailRec, index) {
        clearTimeout(this.manageReturn);

        this.manageReturn = setTimeout(() => {

            let filter = {
                filter: {
                    where: {
                        'companyInfo.email': emailRec


                    }
                }

            };
            this._apiService.getDataObjectsFilter('Companies', filter).subscribe(
                (result: any) => {
                    console.log(result);

                    if (result.some(company => company.companyInfo.email === emailRec)) {
                        console.log("Object found inside the array.");
                        const temp = result.findIndex(selObject => selObject.companyInfo.email === emailRec);
                        console.log(result[temp]);
                        this.companiesInvited[index]['companyId'] = result[temp].id;
                    }

                },
                error => {
                    if (error != null) {
                        console.log(error);
                    }
                }
            );

        }, 300);
    }
    addInvited() {
        let company = {
            name: '',
            email: '',
            phone: '',
            companyId: ''

        };
        this.companiesInvited.push(company)
    }
    delInvited(index) {
        this.companiesInvited.splice(index, 1);

    }
    editProduct(idx) {
        console.log(this.indexOnEdit)
        var url;
        if (this.currentProductFileURL) {
            url = this.currentProductFileURL.toString()
        } else {
            url = "";
        }
        if (this.completeBorrador) {
            this.newProduct = {
                requestId: '',
                subCategoryId: this.productForm.value.subCategoryId,
                categoryId: this.productForm.value.categoryId,
                productId: this.productForm.value.productId,
                quantity: this.productForm.value.quantity,
                comments: this.productForm.value.comments,
                specificationId: this.productForm.value.specificationId,
                subCategory: this.productForm.value.subCategory,
                category: this.productForm.value.category,
                name: this.productForm.value.product,
                specification: this.productForm.value.specification,
                projectId: this.cotizacionForm.value.project,
                unitName: this.productForm.value.unit,
                specificationName: this.productForm.value.specificationName,

                unitId: this.productForm.value.unitSelect,
                productURL: url,
                companyId: localStorage.getItem("$LoopBack$currentCompanyId"),
                userId: localStorage.getItem("$LoopBack$currentUserId"),

            };

            console.log('log okok', this.newProduct);
            this.request.products[this.indexOnEdit] = this.newProduct;
            this.searchText = '';
            this.productForm.reset();
            this.units = [];
            this.specifications = [];
            this.editingProduct = false;

        }
    }
    getCompanyInvited(type, companyId) {

        this._apiService.getDataObjectsFilter(type, {}).subscribe(
            result => {
                console.log(result);

            },
            error => {
                if (error != null) {
                    console.log(error);
                }
            }
        );
    }
    getCountries(type, companyId) {

        this._apiService.getDataObjectsFilter(type, {}).subscribe(
            result => {

            },
            error => {
                if (error != null) {
                    console.log(error);
                }
            }
        );
    }

    getStates(type, companyId) {

        this._apiService.getDataObjectsFilter(type, {}).subscribe(
            (result: any) => {
                this.states = result;
                this.states = this.states.filter(
                    state => state.countryId === "589261469108562ad9903f62");


            },
            error => {
                if (error != null) {
                    console.log(error);
                }
            }
        );
    }


    titleCaseWord(word: string) {
        if (!word) return word;
        console.log(word[0].toUpperCase() + word.substr(1).toLowerCase());
        let title = word[0].toUpperCase() + word.substr(1).toLowerCase();
        this.cotizacionForm.controls['name'].setValue(title);


    }
    titleCaseWordLic(word: string) {
        if (!word) return word;
        console.log(word[0].toUpperCase() + word.substr(1).toLowerCase());
        let title = word[0].toUpperCase() + word.substr(1).toLowerCase();
        this.licitacionForm.controls['name'].setValue(title);


    }
}

