import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { UiSwitchModule } from 'angular2-ui-switch'
// Typeahead
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FileUploadModule } from 'ng2-file-upload';


//Select autocomplete
/* import { MatInputModule, MatTableModule, MatProgressSpinnerModule, MatIconModule, MatCheckboxModule, MatTooltipModule } from '@angular/material';*/
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ChartsModule } from 'ng2-charts';
//import {MaterialModule} from "../material/material.module";
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AppComponent } from './app.component';

//Routes
import { APP_ROUTES } from './app.routes';
import { ColorPickerModule } from 'ngx-color-picker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { RatingModule } from 'ngx-bootstrap/rating';

import { NgxSpinnerModule } from 'ngx-spinner';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
defineLocale('es', esLocale);

import { ImageCropperComponent, CropperSettings, ImageCropperModule } from "ngx-img-cropper";


import { PagesComponent } from './pages/pages.component';

import { AuthGuardService } from './services/auth-guard.service';

import { CardlistService } from './services/cardlist.service';


import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
//import { CotizacionAceptarComponent } from './shared/cotizacionAceptar/cotizacionAceptar.component';
import { LoginComponent } from './pages/user/login/login.component';
import { NopagefoundComponent } from './pages/shared/nopagefound/nopagefound.component';
import { HeaderComponent } from './pages/shared/header/header.component';

import { NewsQuotationsComponent } from './pages/provider/newsQuotation/newsQuotations.component';
import { Companycomponent } from './pages/user/company/company.component';
import { RequestComponent } from './pages/client/request/request.component';
import { SendedComponent } from './pages/client/sended/sended.component';
import { NewRequest } from './pages/shared/client/newRequest/newRequest.component';
import { ReceivedComponent } from './pages/client/received/received.component';

import { ViewQuotationComponent } from './pages/shared/toConcrete/viewQuotation/viewQuotation.component';
import { ViewHistoryQuotationComponent } from './pages/shared/toConcrete/viewHistoryQuotation/viewHistoryQuotation.component';
import { NegotiationQuotatioComponent } from './pages/shared/toConcrete/negotiationQuotation/negotiationQuotation.component';
import { NewSurveyComponent } from './pages/shared/toConcrete/newSurvey/newSurvey.component';
import { ChatComponent } from './pages/shared/chat/chat.component';
import { PendingQuotationsComponent } from './pages/provider/pendingQuotations/pendingQuotations.component';
import { ReviewComponent } from './pages/shared/review/review.component';
import { SentQuotations } from './pages/provider/sentQuotations/sentQuotations.component';
import { RespondQuotation } from './pages/shared/respondQuotation/respondQuotation.component';
import { AcceptedQuotations } from './pages/provider/acceptedQuotations/acceptedQuotations.component';
import { PdfOrderComponent } from './modals/pdfOrder/pdfOrder.component';
import { PdfQuotationComponent } from './modals/pdfQuotation/pdfQuotation.component';
import { ReportsComponent } from './pages/provider/reports/reports.component';
import { RejectedComponent } from './pages/shared/rejected/rejected.component';
import { ViewAcceptedQuotationComponent } from './pages/shared/viewAcceptedQuotation/viewAcceptedQuotation.component';
import { ViewCompletedQuotationComponent } from './pages/shared/viewCompletedQuotation/viewCompletedQuotation.component';
import { UltimateSelectComponent } from './components/ultimate-select/ultimate-select.component';
import { FooterComponent } from './components/footer/footer.component';
import { ClientTableComponent } from './components/client-table/client-table.component';
import { ConcretedComponent } from './pages/client/concreted/concreted.component';
import { GooglePlacesDirective } from './directives/google-places.directive';
import { GooglePlaceDirective } from './google-place.directive';
import { ErrorInterceptor } from './components/interceptors/error.interceptor';
import { ApiService } from './services/apiService';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { AgroComponent } from './pages/shared/client/agro/agro.component';
import { RequestAgroComponent } from './pages/client/request-agro/request-agro.component';
import { RecoveryComponent } from './pages/user/recovery/recovery.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NopagefoundComponent,
    HeaderComponent,
    NewsQuotationsComponent,
    Companycomponent,
    RequestComponent,
    SendedComponent,
    NewRequest,
    ReceivedComponent,
    ConcretedComponent,
    ViewQuotationComponent,
    ViewHistoryQuotationComponent,
    NegotiationQuotatioComponent,
    NewSurveyComponent,
    PagesComponent,
    ChatComponent,
    PendingQuotationsComponent,
    ReviewComponent,
    SentQuotations,
    RespondQuotation,
    AcceptedQuotations,
    PdfOrderComponent,
    PdfQuotationComponent,
    ReportsComponent,
    RejectedComponent,
    ViewAcceptedQuotationComponent,
    ViewCompletedQuotationComponent,
    UltimateSelectComponent,
    GooglePlacesDirective,
    GooglePlaceDirective,
    FooterComponent,
    ClientTableComponent,
    AgroComponent,
    RequestAgroComponent,
    RecoveryComponent
  ],
  imports: [
    BrowserModule,
    APP_ROUTES,
    HttpClientModule,
    FormsModule,
     ReactiveFormsModule,
    ColorPickerModule,
    FileUploadModule,
    NgxSpinnerModule,
    ImageCropperModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatIconModule,
    MatCheckboxModule,
    MatChipsModule,
    MatInputModule,
    MatTableModule,
    MatAutocompleteModule,
    ChartsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    // UiSwitchModule,
    RatingModule.forRoot(),
    BrowserAnimationsModule,
    TypeaheadModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    TooltipModule.forRoot(),
   // NgxStripeModule.forRoot('pk_test_51HjCPsLALPfGo9G6NrX24y36wjTZfg50wY1u3CLbZsrEXdX6BW2scKpWxxtpcKIkWbklGXlNrczpdG6UbMXngNmv00puh31riZ'),

  ],
  providers: [AuthGuardService, CardlistService, ApiService, { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
