<!-- Modal  confirm and rating-->
<div class="modal fade " id="rejectedReasonModal" tabindex="-1" role="dialog" style=" z-index:99999999" aria-labelledby="exampleModalCenterTitle"
 aria-hidden="true">

	<div class="modal-dialog modal-md" >
	    <div class="modal-content">
            <div class="modal-header">
                <h4 style="text-align:center;" class="modal-title" id="myLargeModalLabel">Haz rechazado la solicitud de cotización exitosamente</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <div class="row ">
                    <p class="subtitle" style=" padding-left: 30px; "> Por último, platícanos el motivo de esta
                        decisión
                    </p>
                    <div *ngFor="let reason of rejectedReasons; let i=index" (click)="disables(i)" class="zero modal-rejected center disabled-{{reason.elemento}} cursor-btn">
                        <div class="container-rejected" *ngIf="reason.id != verid">{{reason.razon}}</div>
                    </div>
                    <div class="col-4 col-md-4 "></div>
                    <div class="col-4 col-md-4 ">
                        <button type="button" (click)=" saveRejectedReason()" [disabled]="selectedReason===''" class="margin1  width btn-black">
                            Listo </button>
                    </div>
                    <div class="col-4 col-md-4 "></div>
                </div>

            </div>
            <div class="modal-footer">
            </div>
        </div>
	</div>
</div>
<!-- Modal  confirm and rating-->
<div class="modal fade " id="rejectedReasonModalDetail" tabindex="-1" role="dialog" style=" z-index:99999999" aria-labelledby="exampleModalCenterTitle"
 aria-hidden="true">

	<div class="modal-dialog modal-md" >
	    <div class="modal-content">
            <div class="modal-header">
                <h4 style="text-align:center;" class="modal-title" id="myLargeModalLabel">Haz rechazado la solicitud de cotización exitosamente</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <div class="row ">
                    <p class="subtitle" style=" padding-left: 30px; "> Por último, platícanos el motivo de esta
                        decisión
                    </p>
                    <div *ngFor="let reason of rejectedReasons; let i=index" (click)="disables(i)" class="zero modal-rejected center disabled-{{reason.elemento}} cursor-btn">
                        <div class="container-rejected" *ngIf="reason.id != verid">{{reason.razon}}</div>
                    </div>
                    <div class="col-4 col-md-4 "></div>
                    <div class="col-4 col-md-4 ">
                        <button type="button" (click)=" saveRejectedReasonDetail()" [disabled]="selectedReason===''" class="margin1  width btn-black">
                            Listo </button>
                    </div>
                    <div class="col-4 col-md-4 "></div>
                </div>

            </div>
            <div class="modal-footer">
            </div>
        </div>
	</div>
</div>
<div class="modal fade bs-example-modal-lg" id="rejectedReasonModal1" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
    aria-hidden="true" >
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h4 style="text-align:center;" class="modal-title" id="myLargeModalLabel">Haz rechazado la solicitud de cotización exitosamente</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <div class="row ">
                    <p class="subtitle  " style="    padding-left: 30px; "> Por último, platícanos el motivo de esta
                        decisión
                    </p>
                    <div *ngFor="let reason of rejectedReasons; let i=index" (click)="disables(i)" class="zero modal-rejected center disabled-{{reason.elemento}} cursor-btn">
                        <div class="container-rejected" *ngIf="reason.id != verid">{{reason.razon}}</div>
                    </div>
                    <div class="col-4 col-md-4 "></div>
                    <div class="col-4 col-md-4 ">
                        <button type="button" (click)=" saveRejectedReason()" [disabled]="selectedReason===''" class="margin1  width btn-black">
                            Listo </button>
                    </div>
                    <div class="col-4 col-md-4 "></div>
                </div>

            </div>
            <div class="modal-footer">
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<!-- /.modal -->
<!-- Modal Show File-->
<div class="modal fade gb_modal" id="modalProductImageInicio" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
 aria-hidden="true"  style="position:fixed; z-index:99999999">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content martop30">
			<div class="modal-body zero">
				<button type="button" class="close btn_close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
                <div *ngIf="selectedProductImageURL" class="row zero  width" style="padding:17px;">
                    
					<img [src]="selectedProductImageURL" style="width:460px ; height:400px" alt="">


				</div>
			</div>
		</div>
	</div>
</div>
<!-- Modal Show File-->

<!--= botones de menu para ventas ============================================================== -->
<!-- <div class="row zero view-toggle type-view" style="">
    <div class="cero center ">

        <button type="button" class="btn btn-demo button-card" (click)="cardViewClick()">
            <i class="fa fa-th-large font-7" aria-hidden="true"></i>
        </button>
    </div>
    <div class="cero center ">

        <div class="nav-item dropdown">
            <button type="button" class="btn btn-demo button-list" (click)="listViewClick()">
                <i class="fa fa-list font-7" aria-hidden="true" style=""></i>
            </button>

        </div>
    </div>
</div> -->



<div class="row width zero type-view">
    <div class="col-9 col-md-0 zero text name-page is-movil"> NUEVA</div>

<!--     <div class="col-3 col-md-3 zero view-toggle">
        <div class="row width zero">
            <div class="col-6 col-md-1 zero margin-r-20">
                <button type="button" class="btn btn-demo button-card" (click)="cardViewClick()">
                    <i class="fa fa-th-large font-7" aria-hidden="true"></i>
                </button>
            </div>
            <div class="col-6 col-md-1 zero cero center margin-15">
                <div class="nav-item dropdown">
                    <button type="button" class="btn btn-demo button-list" (click)="listViewClick()">
                        <i class="fa fa-list font-7" aria-hidden="true"></i>
                    </button>

                </div>
            </div>
        </div>

    </div> -->
</div>
<!--= botones de menu para ventas ============================================================== -->

<!-- Modal PARA LAS RESEÑAS-->
<div class="modal fade gb_modal" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content martop30">
            <div class="modal-body zero">
                <button type="button" class="close btn_close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
<!--                 <app-cuestionario></app-cuestionario>
 -->            </div>
        </div>
    </div>
</div>


<div class="container_bg" style="background-image: url(assets/images/2023/bg.png)">
    <!-- <div class="text name-page is-movil"> NUEVA</div> -->
    <div class="container-scroll">

        <!-- DETALLE COTIZACION ====== -->
        <div class="row cover" id="ver">
            <div class="container">
                <div class="row zero background-gray  strong" style="padding-top: .5em; padding-bottom: 0;">
                    <div  class="col-2 col-md-1 zero collapse_title goBack" (click)="verRegresa()">
                        <i class="fa fa-chevron-left marginTop" aria-hidden="true"></i>
                    </div>
                    <div class="col-0 col-md-2 zero remove">
                        <p class=" " style="font-size: .8em; margin-top:1.5em;"> Venta posible a</p>
                    </div>
                    <div *ngIf="selectedRequest" class="col-9 col-md-2 zero collapse_title font-8 right" style="padding-top: .5em;">
                        <span class="remove"> </span>
                        <span class="strong gray " style="font-size: .8em ; " *ngIf="selectedRequest">
                            {{selectedRequest.company.name}} </span>
                        <div *ngIf="selectedRequest.company"  class="">
                            <rating *ngIf="!selectedRequest.company.rating" class="remove" [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating>
                        </div>
                        <div *ngIf="selectedRequest.company"  class="">
                            <rating *ngIf="selectedRequest.company.rating" class="remove" [(ngModel)]="selectedRequest.company.rating"[max]="max" [readonly]="isReadonly"></rating>
                        </div>
                    </div>
                    <div class="col-0 col-md-1 zero collapse_title remove">
                        <!--   <p class="collapse_title_bold marginTop" *ngIf="selectedRequest" > Centro de costos :&nbsp;{{selectedRequest.projectName}} </p> -->
                    </div>
                    <div class="col-0 col-md-3 zero collapse_title remove">
                        <p class=" " style="font-size: .8em; margin-top:1.5em;" *ngIf="selectedRequest">Nombre :
                            &nbsp;{{selectedRequest.name}} </p>
                    </div>
                    <div class="col-0 col-md-1 zero collapse_title remove">
                        <!--   <p class="collapse_title_bold marginTop" *ngIf="selectedRequest" > Centro de costos :&nbsp;{{selectedRequest.projectName}} </p> -->
                    </div>
                    <div class="col-0 col-md-1 zero collapse_title remove">
                        <p class=" " style="font-size: .8em;margin-top:1.5em;" *ngIf="selectedRequest"> Folio
                            :&nbsp;{{selectedRequest.id}} </p>
                    </div>
                    <div class="col-0 col-md-1 zero collapse_title remove">
                        <!--   <p class="collapse_title_bold marginTop" *ngIf="selectedRequest" > Centro de costos :&nbsp;{{selectedRequest.projectName}} </p> -->
                    </div>
                </div>
                <div class="container-bg-blur zero">
                    <div class="row width">
                        <div class="container ">

                            <app-respondQuotation *ngIf="selectedRequest" [paranueva]="true" (fileProductUrl)="emitedProducFileURl($event)" [paranueva]="false" [request]="selectedRequest"
                                [paraenviada2]="paraenviada2" [paraenviada1]="paraenviada1" [porcotizar]="porcotizar"
                                [bloque]="bloque" [paraaceptadas]="paraaceptadas"></app-respondQuotation>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container" id="regresa">
            <div class="container-bg-blur">


                <section class="card-view" id="card" [ngClass]="cardView ? 'show' : 'hide'">
                    <!-- COLLAPSE 1 -->
                    <div class="card">
                        <div class="card-header" id="express">
                            <h5 class="mb-0">
                                <a class="collapse_title wine" data-toggle="collapse" href="#expressRequests"
                                    aria-expanded="true" aria-controls="expressRequests">
                                    <i id="1" class="fa fa-chevron-down pull-right"></i>
                                    <div class="collapse_title"> EXPRESS </div>
                                </a>
                            </h5>
                        </div>
                        <div id="expressRequests" class="collapse show" aria-labelledby="heading-example">
                            <div class="card-block" [ngClass]="{'overCard':expressRequests.length>6 ,'overCardSmall':expressRequests.length>1 ,'overCardMed':expressRequests.length>3}">
                                <div style="width:100%" class="row zero" *ngIf="expressRequests.length>0 ">
                                    <!-- <div style="width:100%" > -->
                                    <!--  cards -->
                                    <div class=" col-12 col-md-4 white zero" *ngFor="let express of expressRequests">

                                        <div class=" cards">
                                            <div class="container_logo">
                                                <!-- <div class=" logo-brand" [style.background-image]="'url(' + 'assets/images/fakeCompany.png' + ')'"> </div> -->
                                                <img *ngIf="express.company.profileUrl" class="logo-brand-img" [src]="express.company.profileUrl">
                                                <img *ngIf="!express.company.profileUrl" class="logo-brand-img" src="../../../assets/images/fakeCompany.png">
                                                <div class="text_logo"> {{express.company.name}} </div>
                                                <div  *ngIf="express.company" class="">
                                                    <rating *ngIf="!express.company.rating" class="remove" [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating>
                                                </div>
                                                <div  *ngIf="express.company"  class="">
                                                    <rating  *ngIf="express.company.rating" class="remove" [(ngModel)]="express.company.rating"[max]="max" [readonly]="isReadonly"></rating>
                                                </div>
                                            </div>
                                            <div class="container_text">
                                                <div class="ready titleQuotation">
                                                    <div class="product"> {{express.name}} </div>
                                                    <div class="free">
                                                        <i class="fa fa-circle"></i>
                                                    </div>
                                                </div>
                                                <div>Fecha límite para cotizar: {{express.limitQuotationDate | date:
                                                    'dd/MM/yyyy'}}
                                                </div>
                                                <div>Fecha de entrega de pedido: {{express.endDeliveryDate | date:
                                                    'dd/MM/yyyy'}}</div>
                                            </div>
                                            <button (click)="showRejectMenuExpress(express.id)"  type="button" class="button-gray">
                                                <div class=""> RECHAZAR </div>
                                            </button>
                                            <button (click)="verNew(express)" type="button" class="button-black button-wine">
                                                VER SOLICITUD
                                            </button>
                                        </div>
                                        <div class="row cover" id="rejectExpress{{express.id}}">
                                            <div class="card-rejected">
                                                <div class="accept accept-position">
                                                    <p>Seguro que deseas rechazar la solicitud de cotización de
                                                        <span class="name-rejected">{{express.name}} &nbsp;</span>?</p>
                                                    <button (click)="rejectRequest(express)" > Si, rechazar solicitud
                                                    </button>
                                                    <button (click)="cancelReject(express.id)"> No, volver al menú
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!--  cards -->
                                    <!--   </div>-->




                                </div>
                                <div *ngIf="expressRequests.length===0" class="col-md-12 bg_blanco">
                                    <h2 class="text-center"> No tienes cotizaciones Express</h2>

                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- COLLAPSE 2 -->
                    <div class="card">
                        <div class="card-header" id="nuevas">
                            <h5 class="mb-0">
                                <a class="collapse_title black" data-toggle="collapse" href="#normalRequests"
                                    aria-expanded="true" aria-controls="normalRequests">
                                    <i class="fa fa-chevron-down pull-right"></i>
                                    <div class="collapse_title"> NUEVAS </div>
                                </a>
                            </h5>
                        </div>
                        <div id="normalRequests" class="collapse show" aria-labelledby="heading-example">
                            <div class="card-block" [ngClass]="{'overCard':newRequests.length>6 ,
                                                                'overCardSmall':newRequests.length>1 ,
                                                                'overCardMed':newRequests.length>3}">
                                <div class="row">
                                    <!--  <div > -->
                                    <!--  cards -->
                                    <div class="col-12 col-md-4 white zero" *ngFor="let new of newRequests">
                                        <div class=" cards">
                                            <!-- Rechazar ó Aceptar solicitur -->
                                            <div class="row">
                                                <div class="container_logo">
                                                    <!-- <div class=" logo-brand" [style.background-image]="'url(' + 'assets/images/fakeCompany.png' + ')'"> </div> -->
                                                    <img *ngIf="new.company.profileUrl" class="logo-brand-img" [src]="new.company.profileUrl">
                                                    <img *ngIf="!new.company.profileUrl" class="logo-brand-img" src="../../../assets/images/fakeCompany.png">

                                                    <div class="text_logo"> {{new.company.name}} </div>
                                                    <div  *ngIf="new.company" class="">
                                                        <rating  *ngIf="!new.company.rating" class="remove" [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating>
                                                    </div>
                                                    <div  *ngIf="new.company"   class="">
                                                        <rating *ngIf="new.company.rating" class="remove" [(ngModel)]="new.company.rating"[max]="max" [readonly]="isReadonly"></rating>
                                                    </div>
                                                </div>
                                                <div class="container_text">
                                                    <div class="ready  titleQuotation">
                                                        <div class="product"> {{new.name}} </div>
                                                        <div class="free">
                                                            <i class="fa fa-circle"></i>
                                                        </div>
                                                    </div>
                                                    <!--<div>Cantidad a cotizar: {{nueva.cantidad}}</div>-->
                                                    <div  *ngIf="new.contestLabel==='request'" >Fecha límite para cotizar: {{new.limitQuotationDate | date:
                                                        'dd/MM/yyyy'}}
                                                    </div>
                                                    <div  *ngIf="new.contestLabel==='request'">Fecha de entrega de pedido: {{new.endDeliveryDate | date:
                                                        'dd/MM/yyyy'}}</div>
                                                        <div >{{filterSubcategory(new.requestSubcategoriesArray,new.subcategoryFilter)}}</div>
                                                </div>
                                                <div class="row" style="width:100%">
                                                    <button type="button" class="buttons-gray col-5">
                                                        <div class="" (click)="showRejectMenu(new.id)"> RECHAZAR </div>
                                                    </button>
                                                    <button (click)="verNew(new)" type="button" class="col-7 buttons-black">
                                                        VER SOLICITUD
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="row cover" id="reject{{new.id}}">
                                            <div class="card-rejected">
                                                <div class="accept accept-position">
                                                    <p>Seguro que deseas rechazar la solicitud de cotización de
                                                        <span class="name-rejected">{{new.name}} &nbsp;</span>?</p>
                                                    <button (click)="rejectRequest(new)"> Si, rechazar solicitud
                                                    </button>
                                                    <button (click)="cancelReject(new.id)"> No, volver al menú </button>
                                                </div>
                                            </div>
                                        </div>
                                        <!--   </div> -->
                                        <!--  cards -->
                                    </div>

                                    <div *ngIf="newRequests.length===0" class="col-md-12 bg_blanco">
                                        <h2 class="text-center"> No tienes cotizaciones Nuevas</h2>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="list-view" id="list" [ngClass]="listView ? 'show' : 'hide'">
                    <!-- COLLAPSE 1 -->
                    <div class="card">
                        <div class="card-header" id="express3">
                            <h5 class="mb-0">
                                <a class="collapse_title wine" data-toggle="collapse" href="#collapse-example3"
                                    aria-expanded="true" aria-controls="collapse-example">
                                    <i id="1" class="fa fa-chevron-down pull-right"></i>
                                    <div class="collapse_title"> EXPRESS </div>
                                </a>
                            </h5>
                        </div>
                        <div id="collapse-example3" class="collapse show" aria-labelledby="heading-example">
                            <div class="card-block">
                                <div class="row zero">
                                    <!--  cards -->
                                    <div class="col-12 col-md-12 white zero" *ngFor="let express of expressRequests">
                                        <div class="cards padding0-margin1" id="rechazada{{express.id}}">
                                            <!-- Rechazar ó Aceptar solicitur -->
                                            <div class="row zero width movil-height">
                                                <div class="col-3 col-md-1 zero container_text point-movil">
                                                    <div class="ready ">
                                                        <div class="free ">
                                                            <i class="fa fa-circle"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-3 col-md-1 zero container_logo log_movil_list logo_movil">
                                                    <img *ngIf="express.company.profileUrl" class="logo-brand-img"
                                                        [src]="express.company.profileUrl">
                                                    <img *ngIf="!express.company.profileUrl" class="logo-brand-img" src="../../../assets/images/fakeCompany.png">
                                                </div>
                                                <div class="col-3 col-md-1 zero padding1 container_logo next width_39  zero-movil">
                                                    <div class="text_logo  "> {{express.company.name}}</div>
                                                </div>
                                                <div class="col-4 col-md-2 zero padding1 container_logo next width_39 borders start-movil">
                                                  
                                                    <div  *ngIf="express.company"  class="text_logo borders">
                                                        <rating *ngIf="!express.company.rating" class="remove" [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating>
                                                    </div>
                                                    <div *ngIf="express.company"  class="text_logo borders">
                                                        <rating *ngIf="express.company.rating" class="remove" [(ngModel)]="express.company.rating"[max]="max" [readonly]="isReadonly"></rating>
                                                    </div>
                                                </div>
                                                <div class="col-4 col-md-2 zero padding1 container_text  next width_39 borders margin-left-24 martop-7 quotation-movil">
                                                    <div class="borders font0-8">
                                                        <p class=" only-movile">Cotizar</p>
                                                        <span class="strong">{{express.limitDeliveryDate | date:
                                                            'dd/MM/yyyy'}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-4 col-md-2 zero padding1 container_text  next width_39 borders margin-top-7 quote-movil">
                                                    <div class="borders font0-8">
                                                        <p class=" only-movile">Entregar</p>
                                                        <span class="strong">{{express.endDeliveryDate | date:
                                                            'dd/MM/yyyy'}}</span>
                                                    </div>
                                                </div>
                                                <!--   <div class="col-4 col-md-2 zero padding1 container_text  next width_39 borders margin-left-24 martop-34  quotation-movil quote-money">
                                                    <div class="borders font0-8 ">A cotizar
                                                        <span class="strong">{{new.}}</span>
                                                    </div>
                                                </div> -->
                                                <button (click)="verNew(express)" type="button" class="col-4 col-md-2 zero padding1 next width_39  margin-top-3 quote-movil">
                                                    VER SOLICITUD
                                                </button>
                                            </div>
                                        </div>

                                    </div>


                                    <!--  cards -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- COLLAPSE 2 -->
                    <div class="card">
                        <div class="card-header" id="nuevas2">
                            <h5 class="mb-0">
                                <a class="collapse_title black" data-toggle="collapse" href="#collapse-example4"
                                    aria-expanded="true" aria-controls="collapse-example1">
                                    <i class="fa fa-chevron-down pull-right"></i>
                                    <div class="collapse_title"> NUEVAS </div>
                                </a>
                            </h5>
                        </div>
                        <div id="collapse-example4" class="collapse show zero" aria-labelledby="heading-example">
                            <div class="card-block">
                                <div *ngIf="newRequests.length>0" class="row zero">
                                    <!--  cards -->
                                    <div class="col-12 col-md-12 white zero" *ngFor="let new of newRequests">
                                        <div class=" cards padding0-margin1">
                                            <!-- Rechazar ó Aceptar solicitur -->
                                            <div class="row zero width movil-height">
                                                <div class="col-3 col-md-1 zero container_text point-movil">
                                                    <div class="ready ">
                                                        <div class="free ">
                                                            <i class="fa fa-circle"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-3 col-md-1 zero container_logo log_movil_list logo_movil">
                                                    <img *ngIf="new.company.profileUrl" class="logo-brand-img" [src]="new.company.profileUrl">
                                                    <img *ngIf="!new.company.profileUrl" class="logo-brand-img" src="../../../assets/images/fakeCompany.png">
                                                </div>
                                                <div class="col-3 col-md-1 zero padding1 container_logo next width_39  zero-movil">
                                                    <div class="text_logo  ">{{new.company.name}} </div>
                                                </div>
                                                <div class="col-4 col-md-2 zero padding1 container_logo next width_39 borders start-movil">
                                                    <div *ngIf="!new.company.rating"  class="text_logo borders">
                                                        <rating class="remove" [(ngModel)]="rate" [max]="max" [readonly]="isReadonly"></rating>
                                                    </div>
                                                    <div *ngIf="new.company.rating" class="text_logo borders">
                                                        <rating class="remove" [(ngModel)]="new.company.rating"[max]="max" [readonly]="isReadonly"></rating>
                                                    </div>
                                                </div>
                                                <div class="col-4 col-md-2 zero padding1 container_text  next width_39 borders margin-left-24 martop-7 quotation-movil">
                                                    <div class="borders font0-8">
                                                        <p class=" only-movile">Cotizar</p>
                                                        <span class="strong">{{new.limitQuotationDate | date:
                                                            'dd/MM/yyyy'}} </span>
                                                    </div>
                                                </div>
                                                <div class="col-4 col-md-2 zero padding1 container_text  next width_39 borders margin-top-7 quote-movil">
                                                    <div class="borders font0-8">
                                                        <p class=" only-movile">Entregar</p>
                                                        <span class="strong">{{new.endDeliveryDate | date:
                                                            'dd/MM/yyyy'}} </span>
                                                    </div>
                                                </div>
                                                <!--    <div class="col-4 col-md-2 zero padding1 container_text  next width_39 borders margin-left-24 martop-34 quotation-movil quote-money">
                                                    <div class="borders font0-8 ">A cotizar
                                                        <span class="strong">{{nueva.cantidad}}</span>
                                                    </div>
                                                </div> -->
                                                <button (click)="verNew(new)" type="button" class="col-4 col-md-2 zero padding1 next width_39  margin-top-3 quote-movil">
                                                    VER SOLICITUD
                                                </button>
                                            </div>
                                        </div>
                                        <!-- fin  cards -->
                                    </div>
                                    <!--  cards -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- COLLAPSE fin -->
                </div>
            </div>
        </div>

      <app-footer></app-footer>
        <div class="padding-top1"></div>
    </div>
</div>
