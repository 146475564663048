/// <reference types="@types/googlemaps" />

import { Directive, ElementRef, OnInit, EventEmitter, Output } from '@angular/core';
//This directive will show the google places auto complete and emit the geopoint related to
//Gera
//import { } from 'googlemaps';

@Directive({
  selector: '[google]'
})
export class GooglePlaceDirective implements OnInit {
  private element: HTMLInputElement;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();

  constructor(private elRef: ElementRef) {
    //elRef will get a reference to the element where
    //the directive is placed
    this.element = elRef.nativeElement;
  }

  ngOnInit() {
    var options = {
      componentRestrictions: {country: "mx"}
     };
    const autocomplete = new google.maps.places.Autocomplete(this.element,options);
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      //Emit the new address object for the updated place
      // Get place info
      let place = autocomplete.getPlace();

      console.log(place.geometry.location.lat());
      console.log(place.geometry.location.lng());
      let geoPoint = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        place:place
      }

      this.onSelect.emit(geoPoint);
    });
  }

}