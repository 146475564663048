import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
declare var $: any;
import { ApiService } from '../../../services/apiService';
import swal from 'sweetalert2'
@Component({
  selector: 'app-viewCompletedQuotation',
  templateUrl: './viewCompletedQuotation.component.html',
  styleUrls: ['./viewCompletedQuotation.component.css']
})
export class ViewCompletedQuotationComponent implements OnInit {
  @Input() request: any;
  @Input() onlyViewForSales: boolean;
  @Input() completed: boolean;

  @Input() bloque: boolean;
  @Input() paraaceptadas: boolean;

  @Output() recQuotation: EventEmitter<any> = new EventEmitter();
  @Output() fileProductUrl: EventEmitter<any> = new EventEmitter();
  public selectedReason = '';
public status ;
  public selectedRequest: any;
  constructor() { }

  ngOnInit() {
    this.selectedRequest=this.request;
    this.status=this.request.status;
    console.log(this.request)
  }
  ixccoSurvey(){
    $('#ixccoEncuesta').modal('show');
  
  }
}
