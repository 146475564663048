<script type="text/javascript">
    $(function () {
        var pull = $("#pull");
        menu = $("nav ul");
        menuHeight = menu.height();
        $(pull).on("click", function (e) {
            e.preventDefault();
            menu.slideToggle();
        });
        $(window).resize(function () {
            var w = $(window).width();
            if (w > 700 && menu.is(":hidden")) {
                menu.removeAttr("style");
            }
        });
    });
</script>


<!--
=============================================================================================
            ZONA MODALES
=============================================================================================
-->
<!-- Modal  Confirm Report-->
<div class="modal fade " id="modalConfirmReport" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">

    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content martop30">
            <div class="modal-body width">
                <button type="button" class="close btnColose" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="row zero tabla width">
                    <div class="row width" style="height:12em;">
                        <div class="container-scroll top-ask">

                            <div class="row" style="padding:20px; ">
                                <div class=" col-12 col-md-12 text bold zero" style="text-align:center; ">
                                    <p *ngIf="companyToReportName" class="font-1 black">Reportar a :
                                        {{companyToReportName}}</p>
                                </div>
                                <div class=" col-12 col-md-12 text bold zero" style="text-align:center; ">
                                    <p class="font-1 black">Agregar Comentarios</p>
                                </div>
                                <textarea rows="3" cols="50" [(ngModel)]="reportComment" name="report" #report="ngModel"
                                    placeholder=""> &nbsp;</textarea>

                                <!--  <input type="text" class="width input2" style="border-left:0px;border-right:0px;"
                                    [(ngModel)]="reportComment" name="report" #report="ngModel" placeholder=""> -->

                            </div>

                        </div>
                    </div>
                    <div class="row zero width">
                        <div class="col-3 col-md-3 zero font-8">

                        </div>
                        <div class="col-6 col-md-6 zero font-8">
                            <button (click)="confirmReportCompany()" class="width zero center strong padding5"
                                style=" background-color: black; border:solid transparent; color:#fff;">
                                OK</button>
                        </div>
                        <div class="col-3 col-md-3 zero font-8">

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal REPORTAR EMPRESA -->
<div class="modal fade animated fadeInDown fondo_modal" id="modalReportCompany" data-backdrop="false" tabindex="-1"
    role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document" id="reporte">
        <div class="modal-content">
            <div class="modal-header zero">
                <h4 class="modal-title center" id="myModalLabel" style="width:100%">Reportar empresa</h4>
                <button type="button" class="close" id="reportar" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body zero">
                <div class="row zero">
                    <h6>Revisaremos detalladamente la empresa que reportas como posible fraudulenta</h6>
                </div>
                <div *ngIf="Companies" style="height:20em">
                    <div class="modal-scroll">
                        <div class="row zero" *ngFor="let company of Companies">
                            <div class="col-4 col-md-2 ">
                                <img *ngIf="company.profileUrl" class="logo-brand-img" [src]="company.profileUrl">
                                <img *ngIf="!company.profileUrl" class="logo-brand-img"
                                    src="../../../assets/images/fakeCompany.png">
                            </div>
                            <div class="col-8 col-md-2 zero">
                                <div class="font07 ptop-10">
                                    <strong *ngIf="company.name">{{company.name}}</strong>
                                </div>

                            </div>
                            <div class="col-8 col-md-2 zero">

                                <div class="font07 ptop-10">
                                    <strong *ngIf="company.ratingIxcco">{{company.ratingIxcco}}</strong> </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="row">
                                    <div class="col-12 col-md-6 zero">
                                        <div class="row zero">
                                            <div *ngIf="company.companyInfo" class="font07 ptop-10">
                                                {{company.companyInfo.city}}</div>

                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 ptop-10">
                                        <button class="btn_reportar font07 center"
                                            (click)="openConfirmReportCompanyModal(company)">Reportar</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 zero target" id="target{{company.id}}">
                                <button class="btn_reportar font07 center" style="padding: 0.34em!important;">
                                    ¡Gracias! Revisaremos tu reporte &nbsp;&nbsp;&nbsp;
                                    <i class="fa fa-check-circle-o check" aria-hidden="true"></i>
                                </button>
                            </div>
                            <hr class="hr_reporte">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal REPORTAR COTIZACION DUPLICADA -->
<div class="modal fade animated bounceInRight fondo_modal" id="myModal_cotizacion" data-backdrop="false" tabindex="-1"
    role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document" id="reportecotizacion">

        <div class="modal-content">
            <div class="modal-header zero">
                <h4 class="modal-title center" id="myModalLabel" style="width:100%">Reportar cotización duplicada</h4>
                <button type="button" style="right:1em" class="close" id="reportar" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body zero">
                <div class="row zero">
                    <h6>Revisaremos detalladamente la empresa que reportas como posible fraudulenta</h6>
                </div>
                <div style="height:20em">
                    <div class="modal-scroll">
                        <div class="row zero" *ngFor="let corizacion of reportarduplicada">
                            <div class="col-4 col-md-2 ">
                                <div class="log_reporte" [style.background-image]="'url(' + corizacion.logo + ')'">
                                </div>
                            </div>
                            <div class="col-8 col-md-4 zero">
                                <div class="font07 center">
                                    <strong>{{corizacion.nombre}}</strong>
                                </div>
                                <div class="font07 center">{{corizacion.producto}}
                                    <strong>{{corizacion.medida}}</strong>
                                </div>
                                <div class="font07 center">Folio:
                                    <strong>{{corizacion.folio}}</strong>
                                </div>
                            </div>
                            <div class="col-12 col-md-6" id="graciascotiza{{corizacion.id}}">
                                <div class="row">
                                    <div class="col-6 col-md-6 zero">
                                        <div class="font07 center">
                                            <br>
                                            <strong>{{corizacion.fecha}}</strong>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <button class="btn_reportar font07 center"
                                            (click)="cotizacion(corizacion.id)">Reportar</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 zero target" id="cotiza{{corizacion.id}}">
                                <button class="btn_reportar font07 center" style="padding: 0.34em!important;">
                                    ¡Gracias! Revisaremos tu reporte &nbsp;&nbsp;&nbsp;
                                    <i class="fa fa-check-circle-o check" aria-hidden="true"></i>
                                </button>
                            </div>
                            <hr class="hr_reporte">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Modal NOTIFICACIONES -->
<div class="modal right fade" id="myModalNofificacion" data-backdrop="false" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel2">
    <div class="modal-dialog" role="document">
        <div class="modal-content zero">
            <div class="container zero">
                <div class="row zero notificaciones">
                    <div class="col-1 col-md-1 zero title_modal">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class=" col-10 col-md-10 title_modal">
                        Notificaciones
                    </div>
                    <div class="col-1 col-md-1 title_modal">
                        <i class="fa fa-bell" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="modal-body cont_modal zero">
                <div class="container zero">
                    <div class="row zero bordo_contenido" *ngFor="let notificacion of notificaciones">
                        <div class="col-9 col-md-9">
                            <span class="padding1">
                                {{notificacion.pre}}
                                <strong>{{notificacion.numero}}</strong>
                                {{notificacion.pos}}
                            </span>
                        </div>
                        <div class="col-3 col-md-3 zero padding1">
                            <div class="{{notificacion.clase}}">
                                <strong class="ribbon-content">{{notificacion.bandera}}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- modal-content -->
    </div>
    <!-- modal-dialog -->
</div>

<header class="topbar">

    <div class="" style="margin-left: 0px !important; margin-right: 0px!important;">
        <div class="row zero">
            <div class=" col-12 col-md-10 zero">
                <div class="row marging-8">
                    <div *ngIf="isPurchases && isSales && isperfil ===false"
                        class="col-md-5 col-md-pull-7 tex_center is-movil">
                        <div class="row" *ngIf="(viewSales)">
                            <div class="col-2 col-md-2 cursor" (click)="toggleSales()">
                                <div class="title_font tex_center sale">
                                    VENTAS

                                </div>
                            </div>
                            <div class="col-6 col-md-6 cursor" (click)="toggleSales()" id="venta">
                                <div class="row">
                                    <div class="col-3 col-md-3 aligned-right" style=" margin-left: 0px; ">
                                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                    </div>
                                    <!--   <div class="col-9 col-md-9">
                                    </div> -->
                                </div>
                            </div>
                            <div class="col-6 col-md-6 target" id="compra">
                                <div class="row">
                                    <div class="col-1 col-md-1 aligned-right">
                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                    </div>
                                    <div class="col-9 col-md-9" style="padding-left: 0px;
                                    padding-right: 0px;">
                                        <a [routerLink]="['/compras']">
                                            <div class="tex_center sale " *ngIf="viewSales" (click)="setPurchase()">
                                                <div class="flag-green"> COMPRAS</div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="(!viewSales )">
                            <div class="col-2 col-md-2 cursor" (click)="toggleSales()">
                                <div class="title_font tex_center sale">
                                    COMPRAS

                                </div>
                            </div>
                            <div class="col-6 col-md-6 cursor" (click)="toggleSales()" id="venta">
                                <div class="row">
                                    <div class="col-3 col-md-3 aligned-right" style=" margin-left: 16px; ">
                                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                    </div>
                                    <!--   <div class="col-9 col-md-9">
                                            </div> -->
                                </div>
                            </div>
                            <div class="col-6 col-md-6 target" id="compra">
                                <div class="row">
                                    <div class="col-3 col-md-3 aligned-right" style=" margin-left: 16px; ">
                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                    </div>
                                    <div class="col-6 col-md-6" style="padding-left: 0px;
                                            padding-right: 0px;">
                                        <a [routerLink]="['/inicio']">
                                            <div class="tex_center sale " (click)="setSale()">
                                                <div class="flag-green" *ngIf="!viewSales"> VENTAS </div>
                                            </div>
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isPurchases===false && isSales===true && isperfil ===false"
                        class="col-md-5 col-md-pull-7 tex_center is-movil">
                        <div class="row">

                            <div class="col-2 col-md-2 cursor">
                                <div class="title_font tex_center sale">
                                    SOLO VENTAS

                                </div>
                            </div>
                            <div class="col-6 col-md-6 cursor" (click)="toggleSales()" id="venta">
                                <div class="row">
                                    <div class="col-3 col-md-3 aligned-right" style=" margin-left: 16px; ">
                                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                    </div>
                                    <!--   <div class="col-9 col-md-9">
                                        </div> -->
                                </div>
                            </div>

                        </div>

                    </div>

                    <div *ngIf="isPurchases===true && isSales===false  && isperfil ===false"
                        class="col-md-5 col-md-pull-7 tex_center is-movil">
                        <div class="row" *ngIf="(viewSales)">


                            <div class="col-6 col-md-6 target" id="compra">
                                <div class="row">
                                    <div class="col-1 col-md-1 aligned-right">
                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                    </div>
                                    <div class="col-9 col-md-9" style="padding-left: 0px;
                                                    padding-right: 0px;">
                                        <a></a>[routerLink]="['/compras']">
                                        <div class="tex_center sale " *ngIf="viewSales" (click)="setPurchase()">
                                            <div class="flag-green">SOLO COMPRAS </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div *ngIf="isPurchases && isSales && isperfil && (usuario.role==='Gerente'||'Director')"
                        class="col-md-5 col-md-pull-7 tex_center is-movil">
                        <div class="row" *ngIf="viewSales">

                            <div class="col-2 col-md-2 cursor" (click)="toggleAll()">
                                <div class="title_font tex_center sale">
                                    EMPRESA

                                </div>
                            </div>
                            <div class="col-6 col-md-6 cursor" (click)="toggleAll()" id="venta">
                                <div class="row">
                                    <div class="col-3 col-md-3 aligned-right" style=" margin-left: 16px; ">
                                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                    </div>

                                </div>
                            </div>
                            <div class="col-6 col-md-6 target" id="compra">
                                <div class="row">

                                    <div class="col-12 col-md-12" style="padding-left: 0px;
                                                padding-right: 0px;">
                                        <a [routerLink]="['/compras']">
                                            <div class=" sale " style="text-align: left" (click)="setPurchase()">
                                                <div class="flag-green"> COMPRAS</div>
                                            </div>

                                        </a>
                                        <a [routerLink]="['/inicio']">
                                            <div class=" sale " style="text-align: left" (click)="setSale()">
                                                <div class="flag-green"> VENTAS</div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row" *ngIf="!viewSales">

                            <div class="col-2 col-md-2 cursor" (click)="toggleAll()">
                                <div class="title_font tex_center sale">
                                    EMPRESA

                                </div>
                            </div>
                            <div class="col-6 col-md-6 cursor" (click)="toggleAll()" id="venta">
                                <div class="row">
                                    <div class="col-3 col-md-3 aligned-right" style=" margin-left: 16px; ">
                                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                    </div>

                                </div>
                            </div>
                            <div class="col-6 col-md-6 target" id="compra">
                                <div class="row">

                                    <div class="col-12 col-md-12" style="padding-left: 0px;
                                                padding-right: 0px;">
                                        <a [routerLink]="['/compras']">
                                            <div class=" sale " style="text-align: left" (click)="setPurchase()">
                                                <div class="flag-green"> COMPRAS</div>
                                            </div>

                                        </a>
                                        <a [routerLink]="['/inicio']">
                                            <div class=" sale " style="text-align: left" (click)="setSale()">
                                                <div class="flag-green"> VENTAS</div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>











                    <div class="col-4 col-md-7 zero logo-the-company">
                        <div class="row zero">
                            <div class="col-0 col-md-2 zero tex_center"></div>
                            <div class="col-12 col-md-4 tex_center logo_menu zero for-menu" style="background:url('assets/images/logo-inicio.png');
                            background-position: center; background-repeat: no-repeat; background-size: contain;">
                            </div>
                            <!-- <div class="col-12 col-md-4 tex_center logo_menu zero for-menu" style="background:url('assets/images/logo-inicio.png');
                                background-position: center; background-repeat: no-repeat; background-size: contain;">
                            </div> -->
                            <div class="col-0 col-md-5 zero tex_center">
                                <!--                                 <p class="powered">powered by IXCCO</p>
 -->
                            </div>
                        </div>
                    </div>
                    <!-- MOVILVENTAS -->
                    <div class="col-8 col-md-2" id="ventas-moviles">
                        <div class="row">
                            <div class="col-9 col-md-0 tex_center swiching zero mobile-menu" (click)="switchSales()">
                                <div class="zero title_font tex_center sale purchase sale purchase-sale">
                                    VENTAS
                                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                </div>
                            </div>
                            <!-- hamburguesa -->
                            <div class="col-3 col-md-0 title_font tex_center sale mobile-menu" (click)="menuSales()">
                                <i class="fa fa-bars margin-top-fond" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 col-md-2 cover" id="compras-moviles">
                        <!-- MOVIL COMPRAS -->
                        <div class="row">
                            <div class="col-9 col-md-0 tex_center swiching zero mobile-menu" (click)="switchBuy()">
                                <div class="zero title_font tex_center sale purchase sale purchase-sale">
                                    COMPRAS
                                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                </div>
                            </div>
                            <!-- hamburguesa -->
                            <div class="col-3 col-md-0 title_font tex_center sale mobile-menu" (click)="menuBuy()">
                                <i class="fa fa-bars margin-top-fond" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                    <!-- MOVIL VENTAS A COMPRAS -->
                    <div class="target height-100" id="sales-menu">
                        <div class="row width col-12 bg-position">
                            <div class="close-menu-movil" (click)="closeMenuMovil()"> <i class="fa fa-times-circle"
                                    aria-hidden="true"></i> </div>
                            <div class="col-12 the-novil-menu">
                                <a (click)="hideMenuSales()" [routerLink]="['/inicio']">
                                    <p>NUEVAS </p>
                                </a>
                            </div>
                            <div class="col-12 the-novil-menu">
                                <a (click)="hideMenuSales()" [routerLink]="['/porcotizar']">
                                    <p>POR COTIZAR</p>
                                </a>
                            </div>
                            <div class="col-12 the-novil-menu">
                                <a (click)="hideMenuSales()" [routerLink]="['/ventasenviadas']">
                                    <p>ENVIADAS</p>
                                </a>
                            </div>
                            <div class="col-12 the-novil-menu">
                                <a (click)="hideMenuSales()" [routerLink]="['/aceptada']">
                                    <p>ACEPTADAS</p>
                                </a>
                            </div>
                            <div class="col-12 the-novil-menu">
                                <div class="nav-item dropdown">
                                    <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <p> NOTIFICACIONES </p>
                                    </a>
                                    <div
                                        class="dropdown-menu menu-notifications animated fadeInDown zero position-fixed buy-notifications">
                                        <div class="container max zero">
                                            <div class="row max zero notificaciones">
                                                <div class="col-1 col-md-1 zero title_modal"></div>
                                                <div class=" col-10 col-md-10 title_modal" style="padding-top:0.9em">
                                                    Notificaciones
                                                </div>
                                                <div class="col-1 col-md-1 title_modal"></div>
                                            </div>
                                        </div>
                                        <div class="modal-body cont_modal zero">
                                            <div class="container max zero" style="height:75vh; padding-bottom:1em">
                                                <div class="row container-scroll top-scroll">
                                                    <div class="max zero bordo_contenido"
                                                        *ngFor="let notificacion of notifications">
                                                        <div class="col-12 col-md-12">
                                                            <span class="padding1">
                                                                {{notificacion.title}}&nbsp; -
                                                                <!--   <strong>{{notificacion.numero}}</strong> -->
                                                                {{notificacion.message}}
                                                            </span>
                                                        </div>
                                                        <!--   <div class="col-3 col-md-3 zero padding1">
                                                            <div class="{{notificacion.clase}}">
                                                                <strong class="ribbon-content">{{notificacion.bandera}}</strong>
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 the-novil-menu">
                                <div class="nav-item dropdown">
                                    <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <p> MI PERFIL </p>
                                    </a>
                                    <div
                                        class="dropdown-menu perfil-notifications animated fadeInDown zero position-fixed">
                                        <div class="modal-body cont_modal zero">
                                            <div class="container max zero" style="height:75vh; padding-bottom:1em">
                                                <div class="row container-scroll top-scroll">
                                                    <ul class="dropdown-user zero">
                                                        <div class="zero">
                                                            <div class="row zero perfil">
                                                                <div class=" col-10 col-md-10 title_modal padding-05">
                                                                    Mi Perfil
                                                                </div>
                                                                <div class="col-2 col-md-2 title_modal">
                                                                    <img *ngIf="userImage" [src]="userImage" alt="user"
                                                                        class="profile-pic" />
                                                                    <img *ngIf="!userImage"
                                                                        src="assets/images/users/1.jpg" alt="user"
                                                                        class="profile-pic" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <li *ngIf="usuario.role==='Director' " (click)="closeMenu()"
                                                            class="zero bordo_contenido">
                                                            <a class="zero" [routerLink]="['/empresa']">
                                                                <i class="ti-user"></i> &nbsp;&nbsp; Editar información
                                                                de mi empresa
                                                            </a>
                                                        </li>
                                                        <li *ngIf="usuario.role==='Gerente' " (click)="closeMenu()"
                                                            class="zero bordo_contenido">
                                                            <a class="zero" [routerLink]="['/empresa']">
                                                                <i class="ti-user"></i> &nbsp;&nbsp;Mi empresa
                                                            </a>
                                                        </li>
                                                        <li *ngIf="usuario.role==='Director' " (click)="closeMenu()"
                                                            class="zero bordo_contenido">
                                                            <a class="zero" [routerLink]="['/reportes']">
                                                                <i class="ti-wallet"></i> &nbsp;&nbsp;Reportes
                                                            </a>
                                                        </li>
                                                        <li (click)="closeMenu()" class="zero bordo_contenido">
                                                            <a class="zero" href="#">
                                                                <i class="ti-email"></i> &nbsp;&nbsp; Estado de cuenta
                                                            </a>
                                                        </li>
                                                        <li (click)="closeMenu()" class="zero bordo_contenido"
                                                            data-toggle="modal" data-target="#myModal_cotizacion">
                                                            <a class="zero" href="#">
                                                                <i class="ti-settings"></i> &nbsp;&nbsp; Reportar
                                                                solicitudes de cotizacion duplicadas
                                                            </a>
                                                        </li>
                                                        <li (click)="openReportCompanyModal()"
                                                            class="zero bordo_contenido">
                                                            <a class="zero">
                                                                <i class="ti-settings"></i> &nbsp;&nbsp; Reportar
                                                                empresa3
                                                            </a>
                                                        </li>
                                                        <li (click)="logout()" class="zero bordo_contenido">
                                                            <a class="zero" href="#">
                                                                <i class="fa fa-power-off"></i> &nbsp;&nbsp; Cerrar
                                                                sesión
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="target height-100" id="buy-menu">
                        <div class="close-menu-movil" (click)="closeMenuMovil()"> <i class="fa fa-times-circle"
                                aria-hidden="true"></i> </div>
                        <div class="row width col-12 bg-position">
                            <div class="col-12 the-novil-menu">
                                <a (click)="hideMenuBuy()" [routerLink]="['/enviadas']">
                                    <p>ENVIADAS</p>
                                </a>
                            </div>
                            <div class="col-12 the-novil-menu">
                                <a (click)="hideMenuBuy()" [routerLink]="['/recibidas']">
                                    <p>RECIBIDAS</p>
                                </a>
                            </div>
                            <div class="col-12 the-novil-menu">
                                <a (click)="hideMenuBuy()" [routerLink]="['/concretadas']">
                                    <p>CONCRETADAS</p>
                                </a>
                            </div>
                            <div class="col-12 the-novil-menu">
                                <div class="nav-item dropdown">
                                    <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <p> NOTIFICACIONES </p>
                                    </a>
                                    <div
                                        class="dropdown-menu menu-notifications animated fadeInDown zero position-fixed">
                                        <div class="container max zero">
                                            <div class="row max zero notificaciones">
                                                <div class="col-1 col-md-1 zero title_modal"></div>
                                                <div class=" col-10 col-md-10 title_modal" style="padding-top:0.9em">
                                                    Notificaciones
                                                </div>
                                                <div class="col-1 col-md-1 title_modal"></div>
                                            </div>
                                        </div>
                                        <div class="modal-body cont_modal zero">
                                            <div class="container max zero" style="height:75vh; padding-bottom:1em">
                                                <div class="row container-scroll top-scroll">
                                                    <div class="max zero bordo_contenido"
                                                        *ngFor="let notificacion of notifications">
                                                        <div class="col-12 col-md-12">
                                                            <span class="padding1">
                                                                {{notificacion.title}}&nbsp; -
                                                                <!--   <strong>{{notificacion.numero}}</strong> -->
                                                                {{notificacion.message}}
                                                            </span>
                                                        </div>
                                                        <!--   <div class="col-3 col-md-3 zero padding1">
                                                            <div class="{{notificacion.clase}}">
                                                                <strong class="ribbon-content">{{notificacion.bandera}}</strong>
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 the-novil-menu">
                                <div class="nav-item dropdown">
                                    <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <p> MI PERFIL </p>
                                    </a>
                                    <div
                                        class="dropdown-menu perfil-notifications animated fadeInDown zero position-fixed">
                                        <div class="modal-body cont_modal zero">
                                            <div class="container max zero" style="height:75vh; padding-bottom:1em">
                                                <div class="row container-scroll top-scroll">
                                                    <ul class="dropdown-user zero">
                                                        <div class="zero">
                                                            <div class="row zero perfil">
                                                                <div class=" col-10 col-md-10 title_modal padding-05">
                                                                    Mi Perfil
                                                                </div>
                                                                <div class="col-2 col-md-2 title_modal">
                                                                    <img *ngIf="userImage" [src]="userImage" alt="user"
                                                                        class="profile-pic" />
                                                                    <img *ngIf="!userImage"
                                                                        src="assets/images/users/1.jpg" alt="user"
                                                                        class="profile-pic" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <li (click)="closeMenu()" class="zero bordo_contenido">
                                                            <a class="zero" [routerLink]="['/empresa']">
                                                                <i class="ti-user"></i> &nbsp;&nbsp; Editar información
                                                                de mi empresa
                                                            </a>
                                                        </li>
                                                        <li (click)="closeMenu()" class="zero bordo_contenido">
                                                            <a class="zero" [routerLink]="['/reportes']">
                                                                <i class="ti-wallet"></i> &nbsp;&nbsp;Reportes
                                                            </a>
                                                        </li>
                                                        <li (click)="closeMenu()" class="zero bordo_contenido">
                                                            <a class="zero" href="#">
                                                                <i class="ti-email"></i> &nbsp;&nbsp; Estado de cuenta
                                                            </a>
                                                        </li>
                                                        <li (click)="closeMenu()" class="zero bordo_contenido"
                                                            data-toggle="modal" data-target="#myModal_cotizacion">
                                                            <a class="zero" href="#">
                                                                <i class="ti-settings"></i> &nbsp;&nbsp; Reportar
                                                                solicitudes de cotizacion duplicadas
                                                            </a>
                                                        </li>
                                                        <li (click)="openReportCompanyModal()"
                                                            class="zero bordo_contenido">
                                                            <a class="zero">
                                                                <i class="ti-settings"></i> &nbsp;&nbsp; Reportar
                                                                empresa1
                                                            </a>
                                                        </li>
                                                        <li (click)="logout()" class="zero bordo_contenido">
                                                            <a class="zero" href="#">
                                                                <i class="fa fa-power-off"></i> &nbsp;&nbsp; Cerrar
                                                                sesión
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
            <div class="col-0 col-md-2 zero profileAlertas">
                <!--= ICONOS / CAMPANA USER  ============================================================== -->

                <div style="position: absolute">
                    <p class="profileName"><span class="version">v2.0</span>&nbsp;&nbsp;{{userName}} </p>
                </div>
                <div class="row zero ">
                    <div class="col-4 col-md-4 zero tex_center margin-8">
                        <!--= NOTIFICACIONES ============================================================== -->
                        <div class="nav-item dropdown">
                            <button type="button" class="btn btn-demo" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                <i class="fa fa-bell-o" aria-hidden="true"></i>
                            </button>
                            <div class="dropdown-menu menu-notifications animated fadeInDown zero ">
                                <div class="container max zero">
                                    <div class="row max zero notificaciones">
                                        <div class="col-1 col-md-1 zero title_modal"></div>
                                        <div class=" col-10 col-md-10 title_modal">
                                            Notificaciones
                                        </div>
                                        <div class="col-1 col-md-1 title_modal"></div>
                                    </div>
                                </div>
                                <div class="modal-body cont_modal zero">
                                    <div class="container max zero">
                                        <div class="row max zero bordo_contenido"
                                            *ngFor="let notificacion of notifications">
                                            <div class="col-12 col-md-12">
                                                <span class="padding1">
                                                    {{notificacion.title}}&nbsp; -
                                                    {{notificacion.message}}
                                                </span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 col-md-4 zero tex_center margin-8">
                        <!--= PERFIL ============================================================== -->
                        <div class="nav-item dropdown">
                            <button type="button" class="btn btn-demo" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                <img *ngIf="userImage" [src]="userImage" alt="user" class="profile-pic" />
                                <img *ngIf="!userImage" src="assets/images/users/1.jpg" alt="user"
                                    class="profile-pic" />
                            </button>
                            <!-- Modal PERFIL -->
                            <div
                                class="dropdown-menu dropdown-fixed dropdown-menu-right animated fadeInDown zero the-profile">
                                <ul class="dropdown-user zero">
                                    <div class="zero">
                                        <div class="row zero perfil">
                                            <div class=" col-10 col-md-10 title_modal padding-05">
                                                Mi Perfil
                                            </div>
                                            <div class="col-2 col-md-2 title_modal">
                                                <img *ngIf="userImage" [src]="userImage" alt="user"
                                                    class="profile-pic" />
                                                <img *ngIf="!userImage" src="assets/images/users/1.jpg" alt="user"
                                                    class="profile-pic" />
                                            </div>
                                        </div>
                                    </div>
                                    <li class="zero bordo_contenido">
                                        <a class="zero" [routerLink]="['/empresa']">
                                            <i class="ti-user"></i> &nbsp;&nbsp; Editar información de mi empresa
                                        </a>
                                    </li>
                                    <li class="zero bordo_contenido">
                                        <a class="zero" [routerLink]="['/reportes']">
                                            <i class="ti-wallet"></i> &nbsp;&nbsp; Reportes
                                        </a>
                                    </li>
                                    <li class="zero bordo_contenido">
                                        <a class="zero"
                                            href="https://s3-us-west-2.amazonaws.com/uploads.ixcco.com/image.jpg"
                                            download>
                                            <i class="ti-email"></i> &nbsp;&nbsp; Estado de cuenta
                                        </a>
                                    </li>
                                    <li class="zero bordo_contenido" data-toggle="modal"
                                        data-target="#myModal_cotizacion">
                                        <a class="zero" href="#">
                                            <i class="ti-settings"></i> &nbsp;&nbsp; Reportar solicitudes de cotizacion
                                            duplicadas
                                        </a>
                                    </li>
                                    <li (click)="openReportCompanyModal()" class="pointer zero bordo_contenido">
                                        <a class="zero">
                                            <i class="ti-settings"></i> &nbsp;&nbsp; Reportar empresa
                                        </a>
                                    </li>
                                    <li (click)="logout()" class="zero bordo_contenido">
                                        <a class="zero" href="#">
                                            <i class="fa fa-power-off"></i> &nbsp;&nbsp; Cerrar sesión
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <!--= Menu de COMPRAS============================================================== -->
        <div class="row zero " *ngIf="(!viewSales && !isperfil)">
            <div class=" col-12 col-md-12 zero">
                <div class="row">
                    <div class="cont_menu">
                        <nav class="clearfix">
                            <ul class="clearfix menuList">
                                <li *ngFor="let menu of purchasesMenu"
                                    [ngClass]="{'active': menu.active===true, 'activia': menu.active===false}"
                                    (click)="setActivePurchases(menu)">
                                    <a *ngIf="menu.path" routerLink="{{menu.path}}">
                                        <p [ngClass]="{'active': menu.active, 'activia': menu.active===false}">
                                            {{menu.name}}</p>
                                    </a>
                                    <a *ngIf="!menu.path" target="_blank" style="color: #fff;"
                                        href="http://www.ixcco.com/blog">
                                        <p [ngClass]="{'active': menu.active, 'activia': menu.active===false}">
                                            Ver Perfiles </p>
                                    </a>
                                </li>


                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--= MENU de VENTAS==============================================================
    -->
    <div class="row zero margin-top-header-02" *ngIf="(viewSales && !isperfil)">
        <div class=" col-12 col-md-12 zero">
            <!--= MENU ============================================================== -->
            <div class="row">
                <div class="cont_menu">
                    <nav class=" clearfix">
                        <ul class="clearfix  menuList">
                            <li *ngFor="let menu of salesMenu"
                                [ngClass]="{'active': menu.active===true, 'activia': menu.active===false}"
                                (click)="setActiveSales(menu)">
                                <a *ngIf="menu.path" routerLink="{{menu.path}}">
                                    <p [ngClass]="{'active': menu.active, 'activia': menu.active===false}">
                                        {{menu.name}}</p>
                                </a>
                                <a *ngIf="!menu.path" target="_blank" style="color: #fff;"
                                    href="http://www.ixcco.com/blog">
                                    <p [ngClass]="{'active': menu.active, 'activia': menu.active===false}">
                                        Ver Perfiles </p>
                                </a>
                            </li>

                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</header>


<ngx-spinner bdOpacity = 0.9 bdColor =  "#333" size =  "default" color =  "#fff" type =  "square-loader"></ngx-spinner>























<!-- ==============================================================
        End Topbar header
        ==============================================================
        ============================================================== -->
