import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from '../../../../../node_modules/ngx-spinner';
import { ApiService } from '../../../services/apiService';
import swal from 'sweetalert2'
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { listLocales } from 'ngx-bootstrap/chronos';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { QueryFactory } from '../../../tableQueries/queryFactory';

declare var $: any;



@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.css'],
    providers: [ApiService]
})

export class ReportsComponent implements OnInit {
    public projects = [];
    public products = [];
    public purchasesProducts = [];
    public salesProducts = [];
    public loseQuotations = [];
    public projectId = "";
    //Concretadas
    public concretedSales = [];
    //En espera
    public quotedSales = [];
    //En contestadas
    public sentSales = [];
    public USDCURRENCY;

    public amounts = [0];
    public tokenId;
    public specifications = [];
    public startTime;
    public endTime = new Date();
    public selectedStartTime = new Date();
    public selectedEndTime = new Date();
    public filterStartDate: any;
    public filterEndDate: any;
    public productId;
    public specificationId;
    locale = 'en';
    locales = listLocales();
    myControl = new UntypedFormControl();
    filteredOptions: Observable<any[]>;
    displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];

    public selectedPurchase: any;
    public isPurchasesReport = false;
    public isSelectedPurchase = false;
    public viewContest = false;
    public contestImageUrl;
    public selectedRequest;
    public isSalesReport = false;
    public subtotal;
    public iva;
    public total;
    public quotedCompanies = [];
    // lineChart
    /*   public lineChartData: Array<any> = [
     { data: [65, 59, 80, 81, 56, 55, 40, 40, 40, 40, 50, 50], label: 'Contestadas' },
     { data: [28, 48, 40, 19, 86, 27, 90, 90, 90, 90, 95, 95], label: 'Recibidas' },
     { data: [18, 48, 77, 9, 100, 27, 40, 45, 50, 60, 60, 60], label: 'Ganadasº' }
     ]; */
    public lineChartData: Array<any> = [];
    public lineChartLabels: Array<any> = [];
    public showLineChart = false;
    public showDoughnutChartWon = false;
    public showDoughnutChartLose = false;

    //public lineChartLabels: Array<any> = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    public lineChartOptions: any = {
        responsive: true,
        scales: {
            yAxes: [{ id: 'y-axis-1', type: 'linear', position: 'left', ticks: { min: 0, max: 100 } }]
        }
    };

    public lineChartColors: Array<any> = [
        { // grey
            backgroundColor: 'rgba(77,83,96,0.1)',
            borderColor: '#4AA8FF',
            pointBackgroundColor: '#4AA8FF',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: '#4AA8FF'
        },
        { // dark grey
            backgroundColor: 'rgba(77,83,96,0.1)',
            borderColor: 'rgba(77,83,96,1)',
            pointBackgroundColor: 'rgba(77,83,96,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(77,83,96,1)'
        },
        { // grey
            backgroundColor: '#23E05C',
            borderColor: '##23E05C',
            pointBackgroundColor: '##23E05C',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: '##23E05C'
        }
    ];

    // Doughnut
    public doughnutChartLabels: string[] = ['Ganadas', 'Precio', 'Entrega', 'Credito'];
    public doughnutChartData: number[] = [350, 450, 100, 40, 30];
    public doughnutChartType: string = 'doughnut';
    public doughnutChartColorsWon = [{ backgroundColor: ["#3F7A7F", "#3FA07F", "#3FBE7F", "#3FBEB2", "#3F97B2"] }]
    public doughnutChartColorsLose = [{ backgroundColor: ["#FF0000", "#FF2163", "#FF689C", "#FF68BE", "#FFA2BE"] }]

    /*   public doughnutChartColors: any[] = [{
     backgroundColor: '#3F7A7F',
     borderColor: '#3F7A7F'
     }, { 
     backgroundColor: '#3FA07F',
     borderColor: '#3FA07F'
     }, { 
     backgroundColor: '#3FBE7F',
     borderColor: '#3FBE7F'
     }, { 
     backgroundColor: '#3FBEB2',
     borderColor: '#3FBEB2'
     }, { 
     backgroundColor: '#3F97B2',
     borderColor: '#3F97B2'
     }]; */
    public doughnutChartLabelsLost: string[] = ['Perdidas', 'Precio', 'Entrega', 'Credito'];
    public doughnutChartDataLost: number[] = [350, 450, 100, 40, 30];
    public doughnutChartTypeLost: string = 'doughnut';

    public lineChartLegend: boolean = true;
    public lineChartType: string = 'line';
    public selectedAmount = [];

    constructor(private localeService: BsLocaleService,    public queryFactory: QueryFactory,
        private _router: Router, private route: ActivatedRoute, private spinner: NgxSpinnerService, private _apiService: ApiService) {
    }

    getItems(type, where, cb?) {
        var params = {
            filter: {
                where: where
            },
            access_token: localStorage.getItem('$LoopBack$accessTokenId')
        };
        this._apiService.getDataObjectsFilter(type, params).subscribe(
            (result: any)=> {
                cb(result);

            },
            error => {
                console.log(error);

            }
        );

    }

    private _filter(value: any): string[] {
        console.log(value)
        const filterValue = value.toLowerCase();
        return this.products.filter(product => product.name.toLowerCase().indexOf(filterValue) === 0);
        //return this.products.filter(product => product.name.toLowerCase().includes(filterValue));
    }

    public randomize(): void {
        let _lineChartData: Array<any> = new Array(this.lineChartData.length);
        for (let i = 0; i < this.lineChartData.length; i++) {
            _lineChartData[i] = {
                data: new Array(this.lineChartData[i].data.length),
                label: this.lineChartData[i].label
            };
            for (let j = 0; j < this.lineChartData[i].data.length; j++) {
                _lineChartData[i].data[j] = Math.floor((Math.random() * 100) + 1);
            }
        }
        this.lineChartData = _lineChartData;
    }

    // events
    public chartClicked(e: any): void {
        console.log(e);
    }

    public chartHovered(e: any): void {
        console.log(e);
    }

    getProducts(filter?) {
        // Runs on every search
        let filters = {
            filter: {
                where: {}
            },
            access_token: localStorage.getItem('$LoopBack$accessTokenId')
        };
        this._apiService.getDataObjectsFilter('Products', filters).subscribe(
            (result: any)=> {
                console.log(result)
                this.products = result;
            }
        );
    }

    displayFn(product?: any): string | undefined {
        return product ? product.name : undefined;
    }

    selectedProduct(product) {

        console.log(product);
        this.productId = product.value.id;
        let whereSpec = {
            productId: product.value.id
        };
        this.getItems('Specifications', whereSpec, (result: any)=> {
            console.log(result, ' specs');
            this.specifications = result;
        });

    }

    selectedSpecification(product) {
        console.log(product);


    }

    getTotal(amount) {


        let plusIva = amount * 1.16;
        return plusIva

    }

    getIVA(plus) {

        let iva = plus * .16;
        return iva

    }

    onChangeDateTo(dateChanged) {
        console.log(dateChanged)
        if (this.startTime > dateChanged) {
            swal({
                title: 'Advertencia',
                text: 'La fecha final no puede ser menor a la fecha inicial',
                type: 'info',
                confirmButtonClass: 'btn ',

                confirmButtonColor: '#000'
            });
            this.endTime = this.selectedEndTime;

        } else {
            this.selectedEndTime = dateChanged;
            this.filterEndDate = dateChanged;

        }


    }

    onChangeDateFrom(dateChanged) {
        console.log(dateChanged)

        if (dateChanged > this.endTime) {

            swal({
                title: 'Advertencia',
                text: 'La fecha inicial no puede ser mayor a la fecha final',
                type: 'info',
                confirmButtonClass: 'btn ',

                confirmButtonColor: '#000'
            });

            this.startTime = this.selectedStartTime;


        } else {
            this.selectedStartTime = dateChanged;
            this.filterStartDate = dateChanged;

        }

    }

    generateReportPurchases() {
        if (this.isPurchasesReport) {
            this.purchasesProducts = [];
            this.subtotal = 0;
            this.amounts = [];
        }
        console.log('generate report purchases')
        this.getPurchases('Reports/getPurchasesData');
    }

    generateReportSales() {
        if (this.isSalesReport) {
            this.purchasesProducts = [];
        }

        //just in case
        this.getSales('Reports/getSalesData');

    }

    viewDetailPurchase(purchase) {
        console.log(purchase)
        if (this.viewContest) {
            this.viewContest = false;
        }

        this.isSelectedPurchase = true;
        this.selectedPurchase = purchase;
        this.tokenId = localStorage.getItem('$LoopBack$accessTokenId');

        let filter = {
            filter: {

                include: {
                    relation: 'requestProducts',
                    filter: { include: ['specification', 'unit', 'category', 'subCategory'] },
                    scope: {
                        include: ['subCategory', 'specification', 'unit', 'category']
                    }
                }
            },
            access_token: this.tokenId
        };
        this.getRequest('Requests', this.selectedPurchase.requestId, filter);
        window.scrollTo(1000, 1000);

    }

    verConcurso() {
        console.log(this.selectedPurchase)
        this.getContest('Reports/getContest', this.selectedRequest.id)
        //this.getGrupoCotizaciones('Requests/quotationsGroup', this.selectedPurchase.requestId)
    }

    volverConcurso() {
        this.viewContest = false;
        this.isSelectedPurchase = true;
        this.contestImageUrl = '';
    }

    getGrupoCotizaciones(type, id?) {
        let branchId = localStorage.getItem('userBranchId');
        let userId = localStorage.getItem('$LoopBack$currentUserId');

        let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
        let companyId = localStorage.getItem('$LoopBack$currentCompanyId');
        let params = {
            //branchId: branchId,
            requestId: id,
            companyId: companyId,

            access_token: tokenId
        };
        this._apiService.getDataObjects(type, branchId, params).subscribe(
            (result: any)=> {
                this.quotedCompanies = result.data.quotations;
                console.log(this.quotedCompanies);
                this.viewContest = true;
                this.isSelectedPurchase = false;


            },
            error => {
                let errorMessage = <any>error;
                if (errorMessage != null) {
                    console.log(errorMessage);

                }
            }
        );
    }
    getCurrency(type) {
        let filter = {
            filter: {
                where: {
                    active: true


                }
            }

        };
        this._apiService.getDataObjectsFilter(type, filter).subscribe(
            (result: any)=> {
                console.log('CURRENCIES');

                console.log(result);
                this.USDCURRENCY = Number(result[0].mxn)

            },
            error => {
                if (error != null) {
                    console.log(error);
                }
            }
        );
    }
    ngOnInit() {
        var asd = new Date();
        console.log(asd.toString())
        var asd1 = asd.setMonth(asd.getMonth() - 1);
        console.log(asd1.toString())

        var d = new Date();
        console.log(d.toLocaleDateString());
        d.setMonth(d.getMonth() - 1);
        console.log(d.toLocaleDateString());
        this.startTime = d;
        this.getCurrency('currencies');

        this.filteredOptions = this.myControl.valueChanges
            .pipe(
                startWith<string | any>(''),
                map(value => typeof value === 'string' ? value : value.name),
                map(name => name ? this._filter(name) : this.products.slice())
            );


        this.localeService.use('es');

        let where = {
            companyId: localStorage.getItem('$LoopBack$currentCompanyId')
        };
        //Centro de costos get
        this.getItems('Projects', where,
            (result: any)=> {
                this.projects = result;
                console.log(this.projects)
                if (result.length === 0) {
                    swal({
                        title: 'Alerta',
                        text: 'No hay Centro de Costo creados , Crear un nuevo Centro de Costo antes de continuar',
                        type: 'info',
                        confirmButtonClass: 'btn ',

                        confirmButtonColor: '#000'
                    }
                    )
                    this._router.navigate(['/empresa/', { isPerfil: true }]);

                }
                this.getProducts();
            }
        );


    }
    onChangeProject(value) {
        console.log(value);
        console.log(this.projectId)
        // this.projectId =value;

    }
    onChangeReport(value) {
        console.log(value);
        if (value === 'COMPRAS') {
            this.isPurchasesReport = true;
            this.isSalesReport = false;

        } else if (value === 'VENTAS') {

            this.isSalesReport = true;
            this.isPurchasesReport = false;
            this.isSelectedPurchase = false;

        }



        var d = new Date();
        console.log(d.toLocaleDateString());
        d.setMonth(d.getMonth() - 1);
        console.log(d.toLocaleDateString());
        this.startTime = d;
        this.endTime = new Date();
        this.specificationId = null;
        this.productId = null;
        this.myControl.setValue('');
        this.specifications = [];

    }
    resetFilters() {

        var d = new Date();
        console.log(d.toLocaleDateString());
        d.setMonth(d.getMonth() - 1);
        console.log(d.toLocaleDateString());
        this.startTime = d;

        this.endTime = new Date();
        this.specificationId = null;
        this.productId = null;
        this.myControl.setValue('');
        this.specifications = [];
    }
    getRequest(type: string, id: string, params: any) {
        // console.log(id)
        this._apiService.getDataById(type, id).subscribe(
            (result: any)=> {
                ;

                this.selectedRequest = result;
                // console.log(this.selectedRequest)


            },
            error => {
                if (error != null) {
                    console.log(error);
                }
            }

        );
    }
    getPurchases(type) {
        // this.spinner.show()


        console.log('Get purchases--->SUB' + this.subtotal)
        let branchId = localStorage.getItem('userBranchId');
        let userId = localStorage.getItem('$LoopBack$currentUserId');

        let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
        let companyId = localStorage.getItem('$LoopBack$currentCompanyId');

        let params = {
            dates: {
                startDate: this.selectedStartTime,
                endDate: this.selectedEndTime
            },
            projectId: this.projectId,
            productId: this.productId ? this.productId : null,
            specificationId: this.specificationId ? this.specificationId : null,
            companyId: companyId,
            access_token: tokenId
        };
        console.log(params)
        console.log(JSON.stringify(params))
        this._apiService.getDataObjects(type, branchId, params).subscribe(
            (result: any)=> {
                this.spinner.hide();
                console.log(result)
                this.purchasesProducts = result.data.info;
                this.purchasesProducts.forEach(product => {
                    if (product.purchaseOrder.currency === "USD") {
                        let amountCurrency = product.amount * this.USDCURRENCY;

                        this.amounts.push(amountCurrency * product.quantity)

                    } else {
                        this.amounts.push(product.amount * product.quantity)
                    }

                });
                if (this.purchasesProducts.length === 0) {

                    swal({
                        title: 'Informacion',
                        text: 'No se encontraron registros !',
                        type: 'info',
                        confirmButtonClass: 'btn ',

                        confirmButtonColor: '#000'
                    });

                }
                this.subtotal = this.amounts.reduce((a, b) => a + b, 0);
                console.log('After--->SUB' + this.subtotal)

                this.getIVA(this.subtotal)
                this.getTotal(this.subtotal)


            },
            error => {
                this.spinner.hide();

                let errorMessage = <any>error;
                if (errorMessage != null) {
                    console.log(errorMessage);

                }
            }
        );
    }

    getContest(type, reqId) {
        let branchId = localStorage.getItem('userBranchId');

        let tokenId = localStorage.getItem('$LoopBack$accessTokenId');

        let params = {

            requestId: reqId,

            access_token: tokenId
        };
        console.log(params)
        this._apiService.getDataObjects(type, branchId, params).subscribe(
            (result: any)=> {
                console.log(result);
                this.contestImageUrl = result.data.info.contestURL;
                if (!this.contestImageUrl) {
                    swal({
                        title: 'Informacion',
                        text: 'Este concurso no esta disponible!',
                        type: 'info',
                        confirmButtonClass: 'btn ',

                        confirmButtonColor: '#000'
                    });
                }
                this.viewContest = true;
                this.isSelectedPurchase = false;


            },
            error => {
                let errorMessage = <any>error;
                if (errorMessage != null) {
                    console.log(errorMessage);

                }
            }
        );
    }

    createLineChart() {
        this.showLineChart = false;

        /* 
         data chart example
         { data: [0, 0, 0, 0, 0, 0,0, 0, 0,0, 0, 0], label: 'Contestadas' },
         { data: [0, 0, 0, 0, 0, 0,0, 0, 0,0, 0, 0], label: 'Recibidas' },
         { data: [0, 0, 0, 0, 0, 0,0, 0, 0,0, 0, 0], label: 'Ganadas' }
         */
        var b;
        let temporalMonth;
        let months = [];
        let sentCount = [];
        let quotedCount = [];
        let concretedCount = [];
        this.lineChartLabels = [];

        this.sentSales.forEach((sale, index) => {
            let dateToCheck = new Date(sale.createdAt);
            let month = dateToCheck.getMonth();
            //console.log(month);
            switch (month) {
                case 0:
                    {
                        b = "Enero";
                        sentCount[b] ? sentCount[b]++ : sentCount[b] = 1;


                    }
                    break;
                case 1:
                    {
                        b = "Febrero";
                        sentCount[b] ? sentCount[b]++ : sentCount[b] = 1

                    }
                    break;
                case 2:
                    {
                        b = "Marzo";
                        sentCount[b] ? sentCount[b]++ : sentCount[b] = 1

                    }
                    break;
                case 3:
                    {
                        b = "Abril";
                        sentCount[b] ? sentCount[b]++ : sentCount[b] = 1


                    }
                    break;
                case 4:
                    {
                        b = "Mayo";
                        sentCount[b] ? sentCount[b]++ : sentCount[b] = 1

                    }

                    break;
                case 5:
                    {
                        b = "Junio";
                        sentCount[b] ? sentCount[b]++ : sentCount[b] = 1


                    }
                    break;
                case 6:
                    {
                        b = "Julio";
                        sentCount[b] ? sentCount[b]++ : sentCount[b] = 1


                    }
                    break;
                case 7:
                    {

                        b = "Agosto";
                        sentCount[b] ? sentCount[b]++ : sentCount[b] = 1

                    }
                    break;
                case 8:
                    {
                        b = "Septiembre";
                        sentCount[b] ? sentCount[b]++ : sentCount[b] = 1

                    }
                    break;
                case 9:
                    {
                        b = "Octubre";
                        sentCount[b] ? sentCount[b]++ : sentCount[b] = 1
                       

                    }
                    break;
                case 10:
                    {
                        b = "Noviembre";
                        sentCount[b] ? sentCount[b]++ : sentCount[b] = 1


                    }
                    break;
                case 11:
                    {
                        b = "Diciembre";
                        sentCount[b] ? sentCount[b]++ : sentCount[b] = 1;
                     


                    }
                    break;
            }
            if (temporalMonth === b) {
               
            } else {
                months.push(b);
                temporalMonth = b;

            }

        });
        console.log(months);
        this.lineChartLabels = months;
        this.lineChartLabels.forEach((month)=>{
        quotedCount[month]=0
        concretedCount[month]=0

        })

        this.quotedSales.forEach((sale) => {
            let dateToCheck = new Date(sale.createdAt);
            let month = dateToCheck.getMonth();
            console.log(month);
            switch (month) {
                case 0:
                    {
                        b = "Enero";
                        quotedCount[b] ? quotedCount[b]++ : quotedCount[b] = 1


                    }
                    break;
                case 1:
                    {
                        b = "Febrero";
                        quotedCount[b] ? quotedCount[b]++ : quotedCount[b] = 1

                    }
                    break;
                case 2:
                    {
                        b = "Marzo";
                        quotedCount[b] ? quotedCount[b]++ : quotedCount[b] = 1

                    }
                    break;
                case 3:
                    {
                        b = "Abril";
                        quotedCount[b] ? quotedCount[b]++ : quotedCount[b] = 1


                    }
                    break;
                case 4:
                    {
                        b = "Mayo";
                        quotedCount[b] ? quotedCount[b]++ : quotedCount[b] = 1


                        break;
                    }
                case 5:
                    {
                        b = "Junio";
                        quotedCount[b] ? quotedCount[b]++ : quotedCount[b] = 1


                    }
                    break;
                case 6:
                    {
                        b = "Julio";
                        quotedCount[b] ? quotedCount[b]++ : quotedCount[b] = 1


                    }
                    break;
                case 7:
                    {

                        b = "Agosto";
                        quotedCount[b] ? quotedCount[b]++ : quotedCount[b] = 1

                    }
                    break;
                case 8:
                    {
                        b = "Septiembre";
                        quotedCount[b] ? quotedCount[b]++ : quotedCount[b] = 1

                    }
                    break;
                case 9:
                    {
                        b = "Octubre";
                        quotedCount[b] ? quotedCount[b]++ : quotedCount[b] = 1


                    }
                    break;
                case 10:
                    {
                        b = "Noviembre";
                        quotedCount[b] ? quotedCount[b]++ : quotedCount[b] = 1


                    }
                    break;
                case 11:
                    {
                        b = "Diciembre";
                        quotedCount[b] ? quotedCount[b]++ : quotedCount[b] = 1

                    }
                    break;
            }
        });

        this.concretedSales.forEach((sale) => {
            let dateToCheck = new Date(sale.createdAt);
            let month = dateToCheck.getMonth();
            //console.log(month);
            switch (month) {
                case 0:
                    {
                        b = "Enero";
                        concretedCount[b] ? concretedCount[b]++ : concretedCount[b] = 1


                    }
                    break;
                case 1:
                    {
                        b = "Febrero";
                        concretedCount[b] ? concretedCount[b]++ : concretedCount[b] = 1

                    }
                    break;
                case 2:
                    {
                        b = "Marzo";
                        concretedCount[b] ? concretedCount[b]++ : concretedCount[b] = 1

                    }
                    break;
                case 3:
                    {
                        b = "Abril";
                        concretedCount[b] ? concretedCount[b]++ : concretedCount[b] = 1


                    }
                    break;
                case 4:
                    {
                        b = "Mayo";
                        concretedCount[b] ? concretedCount[b]++ : concretedCount[b] = 1


                        break;
                    }
                case 5:
                    {
                        b = "Junio";
                        concretedCount[b] ? concretedCount[b]++ : concretedCount[b] = 1


                    }
                    break;
                case 6:
                    {
                        b = "Julio";
                        concretedCount[b] ? concretedCount[b]++ : concretedCount[b] = 1


                    }
                    break;
                case 7:
                    {

                        b = "Agosto";
                        concretedCount[b] ? concretedCount[b]++ : concretedCount[b] = 1

                    }
                    break;
                case 8:
                    {
                        b = "Septiembre";
                        concretedCount[b] ? concretedCount[b]++ : concretedCount[b] = 1

                    }
                    break;
                case 9:
                    {
                        b = "Octubre";
                        concretedCount[b] ? concretedCount[b]++ : concretedCount[b] = 1


                    }
                    break;
                case 10:
                    {
                        b = "Noviembre";
                        concretedCount[b] ? concretedCount[b]++ : concretedCount[b] = 1


                    }
                    break;
                case 11:
                    {
                        b = "Diciembre";
                        concretedCount[b] ? concretedCount[b]++ : concretedCount[b] = 1

                    }
                    break;
            }
        });
        console.log(sentCount);
        console.log(quotedCount);
        console.log(concretedCount);

        let dataSent = [];
        let dataQuot = [];
        let dataConc = []

        months.forEach((month) => {
            dataSent.push(sentCount[month]);
            if (quotedCount[month]) {
                dataQuot.push(quotedCount[month]);

            }else{
                dataQuot.push(quotedCount[month]=0);
            }
            if (concretedCount[month]) {
                dataConc.push(concretedCount[month]);

            }else{
                dataConc.push(concretedCount[month]=0)
            }


        })
        this.lineChartData = [{ data: dataSent, label: 'Recibidas' }, {
            data: dataQuot,
            label: 'Contestadas'
        }, { data: dataConc, label: 'Ganadas' }];
        console.log(this.lineChartData);
        this.showLineChart = true;

    }

    createDoughnutChart() {


        let sentCountProduct = [];
        let quotedCountProduct = [];
        let concretedCountProduct = [];


        this.concretedSales.forEach((sale) => {
            let dateToCheck = new Date(sale.createdAt);


        });

        let b;

        let sentCount = [];
        let quotedCount = [];
        let concretedCount = [];


        this.sentSales.forEach((sale, index) => {
            let dateToCheck = new Date(sale.createdAt);
            let month = dateToCheck.getMonth();
            switch (month) {
                case 0:
                    {
                        b = "Enero";
                        sentCountProduct[b] ? sentCountProduct[b]++ : sentCountProduct[b] = 1;


                    }
                    break;
                case 1:
                    {
                        b = "Febrero";
                        sentCountProduct[b] ? sentCountProduct[b]++ : sentCountProduct[b] = 1

                    }
                    break;
                case 2:
                    {
                        b = "Marzo";
                        sentCountProduct[b] ? sentCountProduct[b]++ : sentCountProduct[b] = 1

                    }
                    break;
                case 3:
                    {
                        b = "Abril";
                        sentCountProduct[b] ? sentCountProduct[b]++ : sentCountProduct[b] = 1

                    }
                    break;
                case 4:
                    {
                        b = "Mayo";
                        sentCountProduct[b] ? sentCountProduct[b]++ : sentCountProduct[b] = 1

                    }

                    break;
                case 5:
                    {
                        b = "Junio";
                        sentCount[b] ? sentCount[b]++ : sentCount[b] = 1


                    }
                    break;
                case 6:
                    {
                        b = "Julio";
                        sentCountProduct[b] ? sentCountProduct[b]++ : sentCountProduct[b] = 1


                    }
                    break;
                case 7:
                    {

                        b = "Agosto";
                        sentCountProduct[b] ? sentCountProduct[b]++ : sentCountProduct[b] = 1

                    }
                    break;
                case 8:
                    {
                        b = "Septiembre";
                        sentCountProduct[b] ? sentCountProduct[b]++ : sentCountProduct[b] = 1

                    }
                    break;
                case 9:
                    {
                        b = "Octubre";
                        sentCountProduct[b] ? sentCountProduct[b]++ : sentCountProduct[b] = 1
                        quotedCountProduct[b] = 0
                        concretedCountProduct[b] = 0


                    }
                    break;
                case 10:
                    {
                        b = "Noviembre";
                        sentCountProduct[b] ? sentCountProduct[b]++ : sentCountProduct[b] = 1

                        quotedCountProduct[b] = 0
                        concretedCountProduct[b] = 0

                    }
                    break;
                case 11:
                    {
                        b = "Diciembre";
                        sentCountProduct[b] ? sentCountProduct[b]++ : sentCountProduct[b] = 1;
                        quotedCountProduct[b] ? quotedCountProduct[b] = 0 : quotedCountProduct[b] = 0
                        concretedCountProduct[b] ? concretedCountProduct[b] = 0 : concretedCountProduct[b] = 0


                    }
                    break;
            }


        });
        this.quotedSales.forEach((sale) => {
            let dateToCheck = new Date(sale.createdAt);
            let month = dateToCheck.getMonth();
            //console.log(month);
            switch (month) {
                case 0:
                    {
                        b = "Enero";
                        quotedCountProduct[b]++;


                    }
                    break;
                case 1:
                    {
                        b = "Febrero";
                        quotedCountProduct[b]++;

                    }
                    break;
                case 2:
                    {
                        b = "Marzo";
                        quotedCountProduct[b]++;

                    }
                    break;
                case 3:
                    {
                        b = "Abril";
                        quotedCountProduct[b]++;


                    }
                    break;
                case 4:
                    {
                        b = "Mayo";
                        quotedCountProduct[b]++;


                        break;
                    }
                case 5:
                    {
                        b = "Junio";
                        quotedCountProduct[b]++;


                    }
                    break;
                case 6:
                    {
                        b = "Julio";
                        quotedCountProduct[b]++;


                    }
                    break;
                case 7:
                    {

                        b = "Agosto";
                        quotedCountProduct[b]++;

                    }
                    break;
                case 8:
                    {
                        b = "Septiembre";
                        quotedCountProduct[b]++;

                    }
                    break;
                case 9:
                    {
                        b = "Octubre";
                        quotedCountProduct[b]++;


                    }
                    break;
                case 10:
                    {
                        b = "Noviembre";
                        quotedCountProduct[b]++;


                    }
                    break;
                case 11:
                    {
                        b = "Diciembre";
                        quotedCountProduct[b]++;

                    }
                    break;
            }
        });

        this.concretedSales.forEach((sale) => {
            let dateToCheck = new Date(sale.createdAt);
            let month = dateToCheck.getMonth();
            //console.log(month);
            switch (month) {
                case 0:
                    {
                        b = "Enero";
                        concretedCountProduct[b]++;


                    }
                    break;
                case 1:
                    {
                        b = "Febrero";
                        concretedCountProduct[b]++;

                    }
                    break;
                case 2:
                    {
                        b = "Marzo";
                        concretedCountProduct[b]++;

                    }
                    break;
                case 3:
                    {
                        b = "Abril";
                        concretedCountProduct[b]++;


                    }
                    break;
                case 4:
                    {
                        b = "Mayo";
                        concretedCountProduct[b]++;


                        break;
                    }
                case 5:
                    {
                        b = "Junio";
                        concretedCountProduct[b]++;


                    }
                    break;
                case 6:
                    {
                        b = "Julio";
                        concretedCountProduct[b]++;


                    }
                    break;
                case 7:
                    {

                        b = "Agosto";
                        concretedCountProduct[b]++;

                    }
                    break;
                case 8:
                    {
                        b = "Septiembre";
                        concretedCountProduct[b]++;

                    }
                    break;
                case 9:
                    {
                        b = "Octubre";
                        quotedCountProduct[b]++;


                    }
                    break;
                case 10:
                    {
                        b = "Noviembre";
                        concretedCountProduct[b]++;


                    }
                    break;
                case 11:
                    {
                        b = "Diciembre";
                        concretedCountProduct[b]++;

                    }
                    break;
            }
        });
        console.log(sentCount);
        console.log(quotedCount);
        console.log(concretedCount);

        let dataSent = [];
        let dataQuot = [];
        let dataConc = []


        //his.showDoughnutChart = true;

    }

    createWonDoughnutChart() {

        console.log(this.salesProducts)

        let sentCountProduct = [];
        let quotedCountProduct = [];
        let concretedCountProduct = [];


        let wonProducts = [];
        let wonProductsCredit = [];
        let wonProductsPrice = [];
        let wonProducsDelivery = [];

        let wonCount = 0;
        let wonCreditCount = 0;
        let wonPriceCount = 0;
        let wonDeliveryCount = 0;


        this.concretedSales.forEach((sale) => {
            let dateToCheck = new Date(sale.createdAt);


        });

        let b;

        let sentCount = [];
        let quotedCount = [];
        let concretedCount = [];

        this.salesProducts.forEach((sale) => {
            wonCount++;
            if (sale.sellProducts.length > 0) {
                sale.sellProducts.forEach(product => {


                    if (product.creditScore === 100) {
                        wonCreditCount++;
                    } else if (product.winPrice) {
                        wonPriceCount++;

                    } else if (product.deliveryScore === 100) {
                        wonDeliveryCount++;
                    }

                });


            }

        });

        console.log(wonCount);
        console.log(wonCreditCount);
        console.log(wonDeliveryCount);
        console.log(wonPriceCount);

        this.sentSales.forEach((sale, index) => {
            let dateToCheck = new Date(sale.createdAt);
            let month = dateToCheck.getMonth();
            switch (month) {
                case 0:
                    {
                        b = "Enero";
                        sentCountProduct[b] ? sentCountProduct[b]++ : sentCountProduct[b] = 1;


                    }
                    break;
                case 1:
                    {
                        b = "Febrero";
                        sentCountProduct[b] ? sentCountProduct[b]++ : sentCountProduct[b] = 1

                    }
                    break;
                case 2:
                    {
                        b = "Marzo";
                        sentCountProduct[b] ? sentCountProduct[b]++ : sentCountProduct[b] = 1

                    }
                    break;
                case 3:
                    {
                        b = "Abril";
                        sentCountProduct[b] ? sentCountProduct[b]++ : sentCountProduct[b] = 1

                    }
                    break;
                case 4:
                    {
                        b = "Mayo";
                        sentCountProduct[b] ? sentCountProduct[b]++ : sentCountProduct[b] = 1

                    }

                    break;
                case 5:
                    {
                        b = "Junio";
                        sentCount[b] ? sentCount[b]++ : sentCount[b] = 1


                    }
                    break;
                case 6:
                    {
                        b = "Julio";
                        sentCountProduct[b] ? sentCountProduct[b]++ : sentCountProduct[b] = 1


                    }
                    break;
                case 7:
                    {

                        b = "Agosto";
                        sentCountProduct[b] ? sentCountProduct[b]++ : sentCountProduct[b] = 1

                    }
                    break;
                case 8:
                    {
                        b = "Septiembre";
                        sentCountProduct[b] ? sentCountProduct[b]++ : sentCountProduct[b] = 1

                    }
                    break;
                case 9:
                    {
                        b = "Octubre";
                        sentCountProduct[b] ? sentCountProduct[b]++ : sentCountProduct[b] = 1
                        quotedCountProduct[b] = 0
                        concretedCountProduct[b] = 0


                    }
                    break;
                case 10:
                    {
                        b = "Noviembre";
                        sentCountProduct[b] ? sentCountProduct[b]++ : sentCountProduct[b] = 1

                        quotedCountProduct[b] = 0
                        concretedCountProduct[b] = 0

                    }
                    break;
                case 11:
                    {
                        b = "Diciembre";
                        sentCountProduct[b] ? sentCountProduct[b]++ : sentCountProduct[b] = 1;
                        quotedCountProduct[b] ? quotedCountProduct[b] = 0 : quotedCountProduct[b] = 0
                        concretedCountProduct[b] ? concretedCountProduct[b] = 0 : concretedCountProduct[b] = 0


                    }
                    break;
            }


        });
        this.quotedSales.forEach((sale) => {
            let dateToCheck = new Date(sale.createdAt);
            let month = dateToCheck.getMonth();
            //console.log(month);
            switch (month) {
                case 0:
                    {
                        b = "Enero";
                        quotedCountProduct[b]++;


                    }
                    break;
                case 1:
                    {
                        b = "Febrero";
                        quotedCountProduct[b]++;

                    }
                    break;
                case 2:
                    {
                        b = "Marzo";
                        quotedCountProduct[b]++;

                    }
                    break;
                case 3:
                    {
                        b = "Abril";
                        quotedCountProduct[b]++;


                    }
                    break;
                case 4:
                    {
                        b = "Mayo";
                        quotedCountProduct[b]++;


                        break;
                    }
                case 5:
                    {
                        b = "Junio";
                        quotedCountProduct[b]++;


                    }
                    break;
                case 6:
                    {
                        b = "Julio";
                        quotedCountProduct[b]++;


                    }
                    break;
                case 7:
                    {

                        b = "Agosto";
                        quotedCountProduct[b]++;

                    }
                    break;
                case 8:
                    {
                        b = "Septiembre";
                        quotedCountProduct[b]++;

                    }
                    break;
                case 9:
                    {
                        b = "Octubre";
                        quotedCountProduct[b]++;


                    }
                    break;
                case 10:
                    {
                        b = "Noviembre";
                        quotedCountProduct[b]++;


                    }
                    break;
                case 11:
                    {
                        b = "Diciembre";
                        quotedCountProduct[b]++;

                    }
                    break;
            }
        });

        this.concretedSales.forEach((sale) => {
            let dateToCheck = new Date(sale.createdAt);
            let month = dateToCheck.getMonth();
            //console.log(month);
            switch (month) {
                case 0:
                    {
                        b = "Enero";
                        concretedCountProduct[b]++;


                    }
                    break;
                case 1:
                    {
                        b = "Febrero";
                        concretedCountProduct[b]++;

                    }
                    break;
                case 2:
                    {
                        b = "Marzo";
                        concretedCountProduct[b]++;

                    }
                    break;
                case 3:
                    {
                        b = "Abril";
                        concretedCountProduct[b]++;


                    }
                    break;
                case 4:
                    {
                        b = "Mayo";
                        concretedCountProduct[b]++;


                        break;
                    }
                case 5:
                    {
                        b = "Junio";
                        concretedCountProduct[b]++;


                    }
                    break;
                case 6:
                    {
                        b = "Julio";
                        concretedCountProduct[b]++;


                    }
                    break;
                case 7:
                    {

                        b = "Agosto";
                        concretedCountProduct[b]++;

                    }
                    break;
                case 8:
                    {
                        b = "Septiembre";
                        concretedCountProduct[b]++;

                    }
                    break;
                case 9:
                    {
                        b = "Octubre";
                        quotedCountProduct[b]++;


                    }
                    break;
                case 10:
                    {
                        b = "Noviembre";
                        concretedCountProduct[b]++;


                    }
                    break;
                case 11:
                    {
                        b = "Diciembre";
                        concretedCountProduct[b]++;

                    }
                    break;
            }
        });


        this.doughnutChartData = [wonCount, wonCreditCount, wonDeliveryCount, wonPriceCount];
        this.showDoughnutChartWon = true;

    }

    createLoseDoughnutChart() {

        console.log(this.loseQuotations)

        let sentCountProduct = [];
        let quotedCountProduct = [];
        let concretedCountProduct = [];


        let loseProducts = [];
        let wonProductsCredit = [];
        let wonProductsPrice = [];
        let wonProducsDelivery = [];

        let loseCount = 0;
        let loseCreditCount = 0;
        let losePriceCount = 0;
        let loseDeliveryCount = 0;


        this.concretedSales.forEach((sale) => {
            let dateToCheck = new Date(sale.createdAt);


        });

        let b;
        this.loseQuotations.forEach((quotation) => {
            loseCount++;


            if (quotation[0].creditScore === 0) {
                loseCreditCount++;
            } else if (!quotation[0].winPrice) {
                losePriceCount++;

            } else if (quotation[0].deliveryScore === 0) {
                loseDeliveryCount++;
            }


        });
        console.log(loseCount);
        console.log(loseCreditCount);
        console.log(loseDeliveryCount);
        console.log(losePriceCount);


        this.doughnutChartDataLost = [loseCount, loseCreditCount, loseDeliveryCount, losePriceCount];
        this.showDoughnutChartLose = true;

    }

    getSales(type) {
        let branchId = localStorage.getItem('userBranchId');
        let userId = localStorage.getItem('$LoopBack$currentUserId');

        let tokenId = localStorage.getItem('$LoopBack$accessTokenId');
        let companyId = localStorage.getItem('$LoopBack$currentCompanyId');

        let params = {
            dates: {
                startDate: this.selectedStartTime,
                endDate: this.selectedEndTime
            },
            productId: this.productId ? this.productId : null,
            specificationId: this.specificationId ? this.specificationId : null,
            companyId: companyId,
            access_token: tokenId
        };

        console.log(JSON.stringify(params))
        this._apiService.getDataObjects(type, branchId, params).subscribe(
            (result: any)=> {
                this.salesProducts = result.data.info;
                this.loseQuotations = result.data.notSellProducts;

                this.sentSales = result.data.sentCount;
                this.concretedSales = result.data.concretedCount;
                this.quotedSales = result.data.quotedCount;
                console.log(result.data);

                this.salesProducts.forEach(product => {

                        let amountCurrency = product.amount * this.USDCURRENCY;

                        this.amounts.push(amountCurrency * product.quantity)

                        this.amounts.push(product.amount * product.quantity)
                    
                });
                if (this.salesProducts.length === 0 && this.sentSales.length === 0) {

                    swal({
                        title: 'Informacion',
                        text: 'No se encontraron registros !',
                        type: 'info',
                        confirmButtonClass: 'btn ',

                        confirmButtonColor: '#000'
                    });
                }
                this.lineChartData = null;
                this.lineChartLabels = null;
                this.lineChartLabels = [];
                setTimeout(() => {

                    this.createLineChart();

                }, 500)
                this.createWonDoughnutChart();
                this.createLoseDoughnutChart();


            },
            error => {
                let errorMessage = <any>error;
                if (errorMessage != null) {
                    console.log(errorMessage);

                }
            }
        );
    }
}
