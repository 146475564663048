import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../../services/apiService";
import { NgxSpinnerService } from "ngx-spinner";
import swal from "sweetalert2";
import { Router, NavigationEnd, Event } from "@angular/router";
declare var $: any;
import * as SendBird from "SendBird";
import * as html2canvas from "html2canvas";
//import * as XLSX from "xlsx";
import * as XLSX from "xlsx-js-style";

type AOA = Array<Array<any>>;
import { UUID } from "angular2-uuid";
import { FileUploader, FileItem } from "ng2-file-upload";
import { QueryFactory } from "../../../tableQueries/queryFactory";

declare var $: any;
@Component({
  selector: "app-received",
  templateUrl: "./received.component.html",
  styleUrls: ["./received.component.css"],
  providers: [ApiService],
})
export class ReceivedComponent implements OnInit {
  public received: Array<any> = [];
  public review: Array<any> = [];
  public allTheAnswersSurvey: Array<any> = [];
  public requestBranch: any;
  public qualitative: any;
  public aspects: any;
  public disableQualitative = false;
  public blockExport = false;
  public maxValue = 0;
  public minValue = 0;

  public technical: any;
  public isDirector: any;
  public today = new Date();
  public USDCURRENCY: number;
  public USD = false;
  public currency = "MXN";
  public errorMessage;
  public contentEditable: boolean = false;
  public indexActualProducto;
  public indexActualCompany;
  public chatComponent = false;
  public decisionCompra = false;
  public movilDecisionCompra = false;
  public tablesReceived = true;
  public quotedCompanies: any = [];
  public selectedRequest: any;
  public selectedAmount = [];
  public requestId;
  public orders = [];
  public ordersAll = [];
  public fakeQuoted: any = [];
  public mobile = false;
  public estimatedDeliveryDate;
  public quotation: any;
  public fileURL;
  public contestImageUrl;
  contestBlob: any;
  public printingCanvas = false;
  map: google.maps.Map;
  public uploader: FileUploader;
  public sortSelect = "";
  public sortOptions = ["Precio", "Credito", "Entrega"];
  public sortOptionsContest = ["Precio"];
  public asd;
  // Rating
  max: number = 5;
  rate: number = 4;
  public totalContest = 0;
  isReadonly: boolean = true;
  public previewOrder = false;
  public purchaseOrders: any;
  public prePurchaseOrders: any;
  public groupQuotations;
  public purchaseOrderIndex = 0;
  public ordersToSave = [];
  public userName = "";
  public userRequest = "";

  public userMail = "";

  public companyName = "";
  public companyAddress = "";
  public companyPhone;
  public extra: any;
  public sb: any; //SendBird
  public channel: any; //Channel (Chat) SendBird

  public URL;
  public URLBUCKET;

  subtotalBase = 0;

  constructor(
    private _apiService: ApiService,
    private queryFactory: QueryFactory,
    private spinner: NgxSpinnerService,
    private _router: Router
  ) {
    this.URL = this._apiService.URL;
    this.URLBUCKET = this._apiService.URLBUCKET;
    this.uploader = new FileUploader({ url: this.URL });
  }

  toggleEditable(event) {
    if (event.target.checked) {
      this.contentEditable = true;
    }
  }
  test() {}
  filterSubcategory(subcategories, id) {
    if (subcategories) {
      var result = subcategories.filter((obj) => {
        return obj.id === id;
      });
      if (result) {
        if (result[0]) {
          return result[0].name;
        } else {
          return "";
        }
      } else {
        return "";
      }
    } else {
      return "";
    }
  }
  getPercentContest(products, price, qty) {
    return ((price * qty * 100) / this.totalContest).toFixed(2) + "%";
  }
  printCanvasContest() {
    // Do some async stuff
    this.spinner.show();
    this.printingCanvas = true;
    setTimeout(() => {
      html2canvas(document.getElementById("canvasToPrint"), {
        allowTaint: true,
        useCORS: true,
      }).then((canvas) => {
        this.spinner.hide();
        console.log(canvas);
        console.log(canvas.toDataURL());
        /*  var doc = new jsPDF('p', 'mm');
         doc.addImage(canvas.toDataURL('image/png'), 'PNG', 10, 10);
         doc.save('sample-file.pdf'); */
        var tWindow = window.open("", "_blank");
        if (tWindow) {
          $(tWindow.document.body)
            .html(
              "<img id='Image' src=" +
                canvas.toDataURL("image/png") +
                " style='width:100%;'></img>"
            )
            .ready(() => {
              console.log("termino de imprimir" + this.printingCanvas);
              this.printingCanvas = false;

              tWindow.focus();
              // tWindow.print();
              setTimeout(function () {
                //this.printingCanvas = false;
                tWindow.print();
              }, 500);
            });
        } else {
          swal({
            title: "Gracias!",
            text: "Ocurrio un error, por favor intente nuevamente.",
            type: "error",
            confirmButtonClass: "btn ",

            confirmButtonColor: "#000",
          });
          this._router.navigate(["/recibidas"]);
        }

        canvas.toBlob((blob) => {}, "image/png");
      });
    }, 500);
  }
  printCanvasContestLic() {
    // Do some async stuff
    this.spinner.show();
    this.printingCanvas = true;
    setTimeout(() => {
      html2canvas(document.getElementById("canvasToPrintContest"), {
        allowTaint: true,
        useCORS: true,
      }).then((canvas) => {
        this.spinner.hide();
        console.log(canvas);
        console.log(canvas.toDataURL());
        /*  var doc = new jsPDF('p', 'mm');
         doc.addImage(canvas.toDataURL('image/png'), 'PNG', 10, 10);
         doc.save('sample-file.pdf'); */
        var tWindow = window.open("", "_blank");
        if (tWindow) {
          $(tWindow.document.body)
            .html(
              "<img id='Image' src=" +
                canvas.toDataURL("image/png") +
                " style='width:100%;'></img>"
            )
            .ready(() => {
              console.log("termino de imprimir" + this.printingCanvas);
              this.printingCanvas = false;

              tWindow.focus();
              // tWindow.print();
              setTimeout(function () {
                //this.printingCanvas = false;
                tWindow.print();
              }, 500);
            });
        } else {
          swal({
            title: "Gracias!",
            text: "Ocurrio un error, por favor intente nuevamente.",
            type: "error",
            confirmButtonClass: "btn ",

            confirmButtonColor: "#000",
          });
          this._router.navigate(["/recibidas"]);
        }

        canvas.toBlob((blob) => {}, "image/png");
      });
    }, 500);
  }
  getTotalsBase() {
    this.subtotalBase = 0;
    this.selectedRequest.requestProducts.forEach((element) => {
      this.subtotalBase += element.price * element.quantity;
    });
    console.log(this.subtotalBase);
  }
  getPercentage(basePrice, companyPrice) {
    let diff = companyPrice - basePrice;
    let percentage = (diff / basePrice) * 100;

    if (isNaN(percentage)) {
      return 0;
    } else if (!isFinite(percentage)) {
      return 0;
    } else {
      return percentage.toFixed(2);
    }
  }
  getPercentageResume(quote, quotations) {
    let amounts = quotations.map((a) => a.amount);
    this.maxValue = Math.max(...amounts);
    let percent = Number((quote.amount / this.maxValue) * 100);
    return percent;
  }
  getDifResume(quote, quotations) {
    let amounts = quotations.map((a) => a.amount * 1.16);

    this.minValue = Math.min(...amounts);

    this.maxValue = Math.max(...amounts);
    let dif = Number(quote.amount * 1.16 - this.minValue);
    return dif === 0 ? 0 : dif.toFixed(2);
  }
  getDifPercentage(quote, quotations) {
    let amounts = quotations.map((a) => a.amount * 1.16);
    this.minValue = Math.min(...amounts);
    let percentageAbove =
      ((quote.amount * 1.16 - this.minValue) / this.minValue) * 100;
    if (!isFinite(percentageAbove)) {
      return 0;
    } else {
      return percentageAbove.toFixed(2);
    }
  }
  createCanvasContest() {
    const promise = new Promise((resolve, reject) => {
      // Do some async stuff
      html2canvas(document.getElementById("canvasToPrint"), {
        allowTaint: true,
        useCORS: true,
      }).then((canvas) => {
        canvas.toBlob((blob) => {
          //this.uploadCanvasImage(blob);
          let file: any = blob;
          let fileItem = new FileItem(this.uploader, file, {});

          this.uploader.queue.push(fileItem);
          console.log(this.uploader.queue);
          this.uploader.queue[0].file.type = "image/jpeg";
          let uuid = UUID.UUID();

          this.uploader.queue[0].file.name = uuid + ".jpg";
          this.uploader.queue[0].upload();
          console.log(this.URLBUCKET + uuid + ".jpg");
          this.contestImageUrl = this.URLBUCKET + uuid + ".jpg";
          resolve(this.URLBUCKET + uuid + ".jpg");

          /*  console.log(blob);
           this.uploadCanvasImage(blob)
           window.open().document.write('<img src="' + canvas.toDataURL() + '" />'); */
        }, "image/png");
      });
    });
    return promise;
  }
  uploadCanvasImage(file: any) {
    console.log(file);
    let fileItem = new FileItem(this.uploader, file, {});

    this.uploader.queue.push(fileItem);
    //this.uploader.addToQueue(file);
    console.log(this.uploader.queue);
    this.uploader.queue[0].file.type = "image/jpeg";
    let uuid = UUID.UUID();

    this.uploader.queue[0].file.name = uuid + ".jpg";
    this.uploader.queue[0].upload();
    console.log(this.URLBUCKET + uuid + ".jpg");
    this.contestImageUrl = this.URLBUCKET + uuid + ".jpg";
  }
  ngOnInit() {
    this.userName = localStorage.getItem("userName");

    this.getCurrencies("currencies");
    let technical = localStorage.getItem("$LoopBack$Technical");
    console.log(technical);
    if (technical === "true") {
      this.technical = true;
      console.log(this.technical);
    }
    let role = localStorage.getItem("$LoopBack$currentUserRole");
    console.log(role);

    if (role === "Director") {
      this.isDirector = true;
      console.log(this.isDirector);
    }

    console.log("tecnical!!!");
    console.log(technical);
    $("#orderPdf").on("hidden.bs.modal", () => {
      this.purchaseOrders = null;
      this.resetDesicion();
      this.getComprasRecibidas("Requests/receivedRequests");
      this.getGrupoCotizaciones("Requests/quotationsGroup", this.requestId);

      this.spinner.show();
      setTimeout(() => {
        this.spinner.hide();
      }, 300);
    });

    if (window.screen.width < 500) {
      // 768px portrait
      this.mobile = true;
    }
    this._router.events.subscribe((value: Event) => {
      if (value instanceof NavigationEnd) {
        this.getComprasRecibidas("Requests/receivedRequests");
      }
    });
    this.getComprasRecibidas("Requests/receivedRequests");
    this.getCurrency("currencies");
  }
  populateExcelQuotation(quotation) {
    console.log("populateExcelQuotation");
    let headers = [
      "Codigo",
      "Concepto",
      "Unidad",
      "Cantidad",
      "Precio Unitario",
    ];
    var arraysExcel = [];
    //arraysExcel.push(headers);
    quotation.quotedProducts.forEach((productExcel) => {
      let objRow = {
        Codigo: productExcel.code,
        Concepto: productExcel.name,
        Unidad: productExcel.unitName,
        Cantidad: productExcel.quantity,
        PU: productExcel.amount,
      };
      arraysExcel.push(objRow);
    });
    setTimeout(() => {
      this.exportAsExcelFile(arraysExcel, headers);
    }, 500);
  }

  populateExcelQuotationRequest(quotation) {
    console.log("populateExcelQuotationRequest");
    let headers = [
      "Concepto",
      "Unidad",
      "Especificacion",
      "Cantidad",
      "Precio Unitario",
      "Marca",
    ];
    var arraysExcel = [];

    quotation.quotedProducts.forEach((productExcel) => {
      let objRow = {
        Concepto: productExcel.name,
        Unidad: productExcel.unitName,
        Especificacion: productExcel.specificationName,
        Cantidad: productExcel.quantity,
        PU: productExcel.amount,
        Marca: productExcel.brand,
      };
      arraysExcel.push(objRow);
    });
    setTimeout(() => {
      this.exportAsExcelFile(arraysExcel, headers);
    }, 500);
  }
  exportComparativa() {
    this.blockExport = true;
    console.log(this.quotedCompanies);

    let headers = [
      "Concepto",
      "Unidad",
      "Especificacion",
      "Cantidad",
      "P.U",
      "Importe",
      "Marca",
    ];
    this.quotedCompanies.forEach((element) => {
      headers.push(element.company.name);
      headers.push("Importe");
    });
    let today = new Date();
    var arraysExcel = [
      ["EVALUACIÓN CUANTITATIVA DE PROVEEDORES "],
      ["Nombre: " + this.selectedRequest.name],
      ["Proyecto: " + this.selectedRequest.projectName],
      ["Fecha " + today.toLocaleDateString()],
      ["Responsable: " + this.userRequest],
      headers,
    ];
    let objRow = [];

    this.quotedCompanies.forEach((companyQuotation, index) => {
      let total = 0;
      companyQuotation.quotedProducts.forEach((productExcel, idx) => {
        total += Number(productExcel.amount * productExcel.quantity);
        if (index === 0) {
          objRow = [
            productExcel.name,
            productExcel.unitName,
            productExcel.specificationName,
            productExcel.quantity,
            this.selectedRequest.requestProducts[idx].price,
            this.selectedRequest.requestProducts[idx].price *
              this.selectedRequest.requestProducts[idx].quantity,
            productExcel.brand ? productExcel.brand : "",
          ];
          arraysExcel.push(objRow);

        }
        // the number 6 is for 6 rows including headers and titles
        if (arraysExcel[idx + 6]) {        
          arraysExcel[idx + 6].push("$" + productExcel.amount);
          arraysExcel[idx + 6].push(
            "$" + productExcel.amount * productExcel.quantity
          );
        }
      });
      arraysExcel.push([]);
      arraysExcel.push([]);
      arraysExcel.push([]);
      arraysExcel.push([]);
      arraysExcel.push([]);
      if(index !== 0){
        index=index+index
      }
      arraysExcel[companyQuotation.quotedProducts.length + 7][0] =
        "Subtotal:  ";
      arraysExcel[companyQuotation.quotedProducts.length + 7][index + 8] =
        "$" + total;
      arraysExcel.push([]);
      let iva = Number(total) * 0.16;
      arraysExcel[companyQuotation.quotedProducts.length + 8][0] = "IVA:  ";
      arraysExcel[companyQuotation.quotedProducts.length + 8][index  + 8] =
        " $" + iva;
      arraysExcel.push([]);
      let suma = Number(Number(iva) + Number(total));
      arraysExcel[companyQuotation.quotedProducts.length + 9][0] = "TOTAL:  ";
      arraysExcel[companyQuotation.quotedProducts.length + 9][index  + 8] =
        " $" + suma;
    });

    setTimeout(() => {
      this.exportAsExcelFile(arraysExcel, headers);
    }, 500);
  }
  public exportAsExcelFile(json: any[], headers: any): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(json);
    for (var i in worksheet) {
      if (typeof worksheet[i] != "object") continue;
      let cell = XLSX.utils.decode_cell(i);

      worksheet[i].s = {
        // styling for all cells
        font: {
          name: "arial",
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
          wrapText: "1", // any truthy value here
        },
      };

      if (cell.r == 0) {
        // first row
        worksheet[i].s.fill = {
          // background color
          patternType: "solid",
          fgColor: { rgb: "b2b2b2" },
          bgColor: { rgb: "#de1623" },
        };
      }

      if (cell.r == 1) {
        // first row
        worksheet[i].s.fill = {
          // background color
          patternType: "solid",
          fgColor: { rgb: "b2b2b2" },
          bgColor: { rgb: "d1d2d0" },
        };
      }

      if (cell.r == 2) {
        // first row
        worksheet[i].s.fill = {
          // background color
          patternType: "solid",
          fgColor: { rgb: "b2b2b2" },
          bgColor: { rgb: "d1d2d0" },
        };
      }

      if (cell.r == 3) {
        // first row
        worksheet[i].s.fill = {
          // background color
          patternType: "solid",
          fgColor: { rgb: "b2b2b2" },
          bgColor: { rgb: "d1d2d0" },
        };
      }
    }
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const colWidths = [
      { wch: 50 },
      { wch: 50 },
      { wch: 50 },
      { wch: 50 },
      { wch: 50 },
      { wch: 50 },
      { wch: 50 },
      { wch: 50 },
      { wch: 50 },
      { wch: 50 },
      { wch: 50 },
      { wch: 50 },
      { wch: 50 },
      { wch: 50 },
      { wch: 50 },
    ];
    this.quotedCompanies.forEach((element) => {
      colWidths.push({ wch: 50 });
    });

    const colHeights = [
      { hpt: 50 }, // height for row 1
      { hpt: 50 },
      { hpt: 50 },
      { hpt: 50 },
      { hpt: 50 },
    ];

    worksheet["!cols"] = colWidths;
    worksheet["!rows"] = colHeights;

    XLSX.writeFile(workbook, "Comparativa.xlsx");
  }
  public exportAsExcelFileAnalysis(json: any[]): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(json);
    for (var i in worksheet) {
      console.log(worksheet[i]);
      if (typeof worksheet[i] != "object") continue;
      let cell = XLSX.utils.decode_cell(i);

      worksheet[i].s = {
        // styling for all cells
        font: {
          name: "arial",
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
          wrapText: "1", // any truthy value here
        },
      };

      if (cell.r == 0) {
        // first row
        worksheet[i].s.fill = {
          // background color
          patternType: "solid",
          fgColor: { rgb: "b2b2b2" },
          bgColor: { rgb: "#de1623" },
        };
      }

      if (cell.r == 1) {
        // first row
        worksheet[i].s.fill = {
          // background color
          patternType: "solid",
          fgColor: { rgb: "b2b2b2" },
          bgColor: { rgb: "d1d2d0" },
        };
      }

      if (cell.r == 2) {
        // first row
        worksheet[i].s.fill = {
          // background color
          patternType: "solid",
          fgColor: { rgb: "b2b2b2" },
          bgColor: { rgb: "d1d2d0" },
        };
      }

      if (cell.r == 3) {
        // first row
        worksheet[i].s.fill = {
          // background color
          patternType: "solid",
          fgColor: { rgb: "b2b2b2" },
          bgColor: { rgb: "d1d2d0" },
        };
      }
      if (cell.r == 4) {
        // first row
        worksheet[i].s.fill = {
          // background color
          patternType: "solid",
          fgColor: { rgb: "b2b2b2" },
          bgColor: { rgb: "d1d2d0" },
        };
      }
    }
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const colWidths = [
      { wch: 50 },
      { wch: 50 },
      { wch: 50 },
      { wch: 50 },
      { wch: 50 },
      { wch: 50 },
      { wch: 50 },
    ];
    this.quotedCompanies.forEach((element) => {
      colWidths.push({ wch: 50 });
    });

    const colHeights = [
      { hpt: 50 }, // height for row 1
      { hpt: 50 },
      { hpt: 50 },
      { hpt: 50 },
      { hpt: 50 },
    ];

    worksheet["!cols"] = colWidths;
    worksheet["!rows"] = colHeights;

    XLSX.writeFile(workbook, "Analisis_cualitativo.xlsx");
  }
  exportAnalysis() {
    console.log(this.qualitative);
    let headers = ["Aspectos a calificar", "Ponderación"];
    this.quotedCompanies.forEach((element) => {
      headers.push(element.company.name);
    });
    let today = new Date();
    var arraysExcel = [
      ["EVALUACIÓN CUALITATIVA DE PROVEEDORES "],
      ["Nombre: " + this.selectedRequest.name],
      ["Proyecto: " + this.selectedRequest.projectName],
      ["Fecha " + today.toLocaleDateString()],
      ["Responsable: " + this.userRequest],
      ["Calificador: " + this.userName],

      headers,
    ];

    this.aspects.forEach((element, index) => {
      arraysExcel.push([element.aspect, element.ponderation + "%"]);
      //row = [element.aspect, element.ponderation + "%"];
    });
    this.qualitative.forEach((companyQualy, idxCompany) => {
      let total = 0;
      let totalRep = 0;
      companyQualy.company.qualitative.forEach((qualyResults, idx) => {
        total += Number(qualyResults.calif);
        totalRep +=
          Number(qualyResults.calif * this.aspects[idx].ponderation) / 100;
        arraysExcel[idx + 7][idxCompany + 2] =
          qualyResults.calif +
          "   -   " +
          (qualyResults.calif * this.aspects[idx].ponderation) / 100;
      });
      arraysExcel.push([], [], []);

      arraysExcel[companyQualy.company.qualitative.length + 7][1] = "100% ";

      arraysExcel[companyQualy.company.qualitative.length + 7][idxCompany + 2] =
        "Suma: " + total + "   " + "Rep:" + totalRep;
    });

    setTimeout(() => {
      this.exportAsExcelFileAnalysis(arraysExcel);
    }, 500);
  }
  validateAnalysis(value: number) {
    console.log(value);
    if (value) {
      if (value > 5) {
        swal({
          title: "Alerta!",
          text: "La calificacion es entre 0-5.",
          type: "info",
          confirmButtonClass: "btn ",
          confirmButtonColor: "#000",
        });
        value = 0;
      }
    }
  }
  reDoContest(draft, label) {
    console.log(draft);

    this._router.navigate(["/compras"], {
      queryParams: { id: draft, editable: false, redo: true, contest: label },
    });
  }
  reDoRequest(draft, editable) {
    console.log(draft);
    if (editable) {
      this._router.navigate(["/compras"], {
        queryParams: { id: draft, editable: true, redo: true },
      });
    } else {
      this._router.navigate(["/compras"], {
        queryParams: { id: draft, editable: false, redo: true },
      });
    }
  }

  onChangeCurrencyView(value) {
    this.resetDesicion();
    setTimeout(() => {
      this.quotedCompanies.quotedProducts = this.fakeQuoted.quotedProducts;
      if (value === "MXN") {
        this.quotedCompanies.forEach((quotation, index) => {
          //quotation['fakeQuoted']= quotation.quotedProducts;
          // console.log(quotation.currency);
          if (this.quotedCompanies[index].currency === "USD") {
            this.USD = true;

            this.quotedCompanies[index].quotedProducts.forEach(
              (product, indexproduct) => {
                product.amount =
                  Number(product.amount) * Number(this.USDCURRENCY);
              }
            );
            this.quotedCompanies[index].currency = "MXN";
          }
        });
      } else if (value === "USD") {
        this.quotedCompanies.forEach((quotation, index) => {
          //quotation['fakeQuoted']= quotation.quotedProducts;
          // console.log(quotation.currency);
          if (this.quotedCompanies[index].currency === "MXN") {
            this.USD = true;

            this.quotedCompanies[index].quotedProducts.forEach(
              (product, indexproduct) => {
                product.amount =
                  Number(product.amount) / Number(this.USDCURRENCY);
              }
            );
            this.quotedCompanies[index].currency = "USD";
          }
        });
      } else if (value === "") {
        this.quotedCompanies = JSON.parse(this.asd);

        //this.quotedCompanies=this.fakeQuoted;
      }
    }, 300);
  }
  onChangeCurrencyViewDynamic(value) {
    this.quotedCompanies = this.fakeQuoted;
    if (value === "MXN") {
      this.quotedCompanies.forEach((quotation, index) => {
        if (this.quotedCompanies[index].currency === "USD") {
          this.USD = true;

          this.quotedCompanies[index].quotedProducts.forEach(
            (product, indexproduct) => {
              product.amount =
                Number(product.amount) * Number(this.USDCURRENCY);
            }
          );
          this.quotedCompanies[index].currency = "MXN";
        }
      });
    } else if (value === "USD") {
      this.quotedCompanies.forEach((quotation, index) => {
        if (this.quotedCompanies[index].currency === "MXN") {
          this.USD = true;

          this.quotedCompanies[index].quotedProducts.forEach(
            (product, indexproduct) => {
              product.amount =
                Number(product.amount) / Number(this.USDCURRENCY);
            }
          );
          this.quotedCompanies[index].currency = "USD";
        }
      });
    } else if (value === "") {
      this.quotedCompanies = JSON.parse(this.asd);
    }
  }
  getCurrency(type) {
    let where = {
      active: true,
    };

    const getDataQuery = this.queryFactory.generateGetQuery(
      type,
      where,
      100,
      0,
      null,
      []
    );

    this._apiService.getDataObjects(getDataQuery).subscribe(
      (result: any) => {
        console.log(result);
        this.USDCURRENCY = Number(result[0].mxn);
      },
      (error) => {
        if (error != null) {
          console.log(error);
        }
      }
    );
  }

  getComprasRecibidas(type, id?) {
    let branchId = localStorage.getItem("userBranchId");
    let userId = localStorage.getItem("$LoopBack$currentUserId");

    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let params = {
      userId: userId,

      access_token: tokenId,
    };
    this._apiService.getDataObjects(type, branchId, params).subscribe(
      (result: any) => {
        this.received = result.data.received;
        this.review = result.data.review;
      },
      (error) => {
        this.errorMessage = <any>error;
        if (this.errorMessage != null) {
          console.log(this.errorMessage);
        }
      }
    );
  }
  getGrupoCotizaciones(type, id?) {
    console.log("Get groupo cotizaciones");
    this.qualitative = [];
    this.aspects = [];
    this.disableQualitative = false;

    let branchId = localStorage.getItem("userBranchId");
    let userId = localStorage.getItem("$LoopBack$currentUserId");
    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let filter = {
      access_token: tokenId,
    };
    let companyId = localStorage.getItem("$LoopBack$currentCompanyId");
    let params = {
      //branchId: branchId,
      requestId: id,
      companyId: companyId,

      access_token: tokenId,
    };
    this._apiService.getDataObjects(type, branchId, params).subscribe(
      (result: any) => {
        console.log("result group quotation");
        console.log(result);
        //var total = 0;
        if (result.data.requestProducts) {
          for (var b = 0; b < result.data.requestProducts.length; b++) {
            if (result.data.requestProducts[b] != null) {
              this.totalContest +=
                Number(result.data.requestProducts[b].price) *
                result.data.requestProducts[b].quantity;
            }
          }
        }

        this.estimatedDeliveryDate = result.data.estimatedDeliveryDate;
        this.groupQuotations = result.data;
        console.log(this.groupQuotations);
        if (this.groupQuotations.contestLabel) {
          if (this.groupQuotations.contestLabel === "request") {
            $("#decisionCompra").show(400);
          } else {
            $("#decisionContest").show(400);
          }
        } else {
          this.groupQuotations["contestLabel"] = "request";
          $("#decisionCompra").show(400);
        }

        this.quotation = result.data.quotations;
        this.requestBranch = result.data.branchId;

        // CUALITATIVO ANALISIS
        this.quotedCompanies = result.data.quotations;
        if (result.data.qualitative) {
          console.log(result.data.qualitative);
          this.disableQualitative = true;
          this.qualitative = result.data.qualitative;
          this.aspects = result.data.aspects;
          this.qualitative.conclusion = result.data.qualitativeConclusions;
          console.log(this.qualitative);
        } else {
          this.qualitative = result.data.quotations;
          this.aspects = [
            {
              aspect: "",
              ponderation: 0,
            },
          ];
          this.qualitative.forEach((element, idx) => {
            element.company.qualitative = [
              {
                aspect: "",
                ponderation: 0,
                index: idx,
                calif: 0,
                repValue: 0,
              },
            ];
          });

          console.log(this.qualitative);
        }

        console.log("QUOTED COMPANIES");
        console.log(this.quotedCompanies);
        if (this.quotedCompanies) {
          this.quotedCompanies.forEach((quotation, index) => {
            if (this.quotedCompanies[index].currency === "USD") {
              this.USD = true;

              this.quotedCompanies[index]["originalCurrency"] = "USD";
              this.quotedCompanies[index].currency = "MXN";

              this.quotedCompanies[index].quotedProducts.forEach(
                (product, indexproduct) => {
                  product.amount =
                    Number(product.amount) * Number(this.USDCURRENCY);
                }
              );
            } else if (this.quotedCompanies[index].currency === "MXN") {
              this.quotedCompanies[index]["originalCurrency"] = "MXN";
            }
          });
        } else {
          console.log("NADIE HA COTIZADO");
        }

        if (result.data.quotations) {
          let asd = JSON.stringify(result.data.quotations);
          this.fakeQuoted = JSON.parse(asd);
        }

        console.log(this.fakeQuoted);
        console.log(this.quotedCompanies);
        //this.exportComparativa();

        setTimeout(() => {
          //this.onChangeSort('Precio')
        }, 100);
      },
      (error) => {
        this.quotedCompanies = [];
        this.errorMessage = <any>error;
        if (this.errorMessage != null) {
          console.log(this.errorMessage);
        }
      }
    );
  }
  saveAnalysis() {
    let sum = 0;
    this.aspects.forEach((element) => {
      sum += Number(element.ponderation);
    });
    if (sum > 100) {
      swal({
        title: "Alerta!",
        text: "La ponderación no puede ser mas de 100% .",
        type: "info",
        confirmButtonClass: "btn ",
        confirmButtonColor: "#000",
      });
    } else if (sum < 100) {
      swal({
        title: "Alerta!",
        text: "La ponderación no puede ser menor de 100% .",
        type: "info",
        confirmButtonClass: "btn ",
        confirmButtonColor: "#000",
      });
    } else {
      console.log(this.qualitative);
      let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
      let params = {
        access_token: tokenId,
      };
      console.log(this.requestId);

      this._apiService
        .editDataObject(
          "Requests",
          this.requestId,
          {
            qualitative: this.qualitative,
            aspects: this.aspects,
            qualitativeSaved: true,
            qualitativeConclusions: this.qualitative.conclusion,
          },
          params
        )
        .subscribe(
          (result: any) => {
            console.log(result);
            swal({
              title: "Exito!",
              text: "Se ha guardado el análisis cualitativo .",
              type: "success",
              confirmButtonClass: "btn ",
              confirmButtonColor: "#000",
            });
          },
          (error) => {
            if (error != null) {
            }
          }
        );
    }
  }
  getPonderation(): Number {
    let sum = 0;
    this.aspects.forEach((element) => {
      sum += Number(element.ponderation);
    });

    return sum;
  }
  getTotals(data, index): Number {
    let sum = 0;
    data.forEach((element) => {
      sum += Number(element.calif);
    });

    return sum;
  }
  getTotalsRep(data, index): Number {
    let sum = 0;
    data.forEach((element, index) => {
      sum += Number((element.calif * this.aspects[index].ponderation) / 100);
    });

    return sum;
  }
  getCurrencies(type) {
    let where = {
      active: true,
    };

    const getDataQuery = this.queryFactory.generateGetQuery(
      type,
      where,
      100,
      0,
      null,
      []
    );

    this._apiService.getDataObjects(getDataQuery).subscribe(
      (result: any) => {
        console.log(result);
        this.USDCURRENCY = Number(result[0].mxn);
      },
      (error) => {
        if (error != null) {
          console.log(error);
        }
      }
    );
  }
  addAspect() {
    this.aspects.push({
      aspect: "",
      ponderation: 0,
    });
    this.qualitative.forEach((element, idx) => {
      element.company.qualitative.push({
        aspect: "",
        ponderation: 0,
        index: idx,
        calif: 0,
        repValue: 0,
      });
    });
    console.log(this.qualitative);
  }
  removeAspect() {
    if (this.aspects.length > 1) {
      this.aspects.pop();
      console.log("inside lengh" + this.aspects.length);
    }
    this.qualitative.forEach((element, idx) => {
      if (element.company.qualitative.length > 1) {
        console.log("inside lengh" + element.company.qualitative.length);
        element.company.qualitative.pop();
      }
    });
    console.log(this.qualitative);
  }
  saveAnswer(questionId, answer?) {
    let userId = localStorage.getItem("$LoopBack$currentUserId");

    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let branchId = localStorage.getItem("userBranchId");

    let companyId = localStorage.getItem("$LoopBack$currentCompanyId");

    let params1 = {
      requestId: this.selectedRequest.id,
      access_token: tokenId,
    };
    console.log(answer);
    let params = {
      access_token: tokenId,
    };
    let answerObject = {
      answer: answer,
      questionId: questionId,
      companyId: companyId,
      branchId: branchId,
    };
    this._apiService.addDataObject("Answers", answer).subscribe(
      (result: any) => {
        console.log(result);
      },
      (error) => {
        if (error != null) {
          console.log(error);
          setTimeout(() => {
            //this.spinner.hide()
            console.log(error);
          }, 250);
        }
      }
    );
  }

  saveAllAnswers(answer?) {
    this.allTheAnswersSurvey.forEach((answer) => {
      this.saveAnswer(answer);
    });
    setTimeout(() => {
      //this.spinner.hide();

      swal({
        title: "Gracias!",
        text: "Encuesta contestada correctamente.",
        type: "success",
        confirmButtonClass: "btn ",

        confirmButtonColor: "#000",
      });
    }, 500);
  }

  radioChange(
    event,
    amount,
    indexProducto,
    qty,
    product,
    quotation,
    companyIndex
  ) {
    event.preventDefault();
    /*   console.log(event)
      console.log(quotation);
      console.log(indexProducto);
      console.log(companyIndex);
     
      console.log('RADIO CHANGE ORDERS')
      console.log(this.orders);
      console.log(this.quotedCompanies);
      console.log(this.fakeQuoted); */
    //quotedCompanies companies quotations
    console.log(product);
    if (
      indexProducto === this.indexActualProducto &&
      companyIndex != this.indexActualCompany
    ) {
      console.log("Mismo producto cambio company");
      this.fakeQuoted[this.indexActualCompany].quotedProducts.push(product);
      this.orders[this.indexActualCompany].orderedProducts.splice(
        indexProducto,
        1
      );
      this.orders.splice(this.indexActualCompany, 1);
    }
    this.indexActualProducto = indexProducto;
    this.indexActualCompany = companyIndex;
    product.amount = amount;
    this.orders[companyIndex] = quotation;

    if (this.fakeQuoted) {
      this.fakeQuoted[this.indexActualCompany].quotedProducts.splice(
        this.findWithAttr(product.id, product.unitId),
        1
      );
    }

    if (this.orders[companyIndex].orderedProducts) {
      this.orders[companyIndex].orderedProducts.push(product);
      this.orders[companyIndex].total = amount * qty;
      this.orders[companyIndex].branchId = quotation.branchId;
    } else {
      this.orders[companyIndex]["orderedProducts"] = [];
      this.orders[companyIndex].orderedProducts = [product];

      this.orders[companyIndex]["total"] = amount * qty;
      this.orders[companyIndex]["branchId"] = quotation.branchId;
    }

    console.log("RADIO CHANGE AFTER");
    console.log(this.orders);

    this.selectedAmount[indexProducto] = amount * qty;
  }
  findWithAttr(idProduct, idUnit) {
    console.log(idProduct);
    console.log(idUnit);

    for (var i = 0; i < this.fakeQuoted.length; i++) {
      if (
        this.fakeQuoted[i].id === idProduct &&
        this.fakeQuoted[i].unitId === idUnit
      ) {
        console.log(this.fakeQuoted[i].id);
        console.log(this.fakeQuoted[i].unitId);
        return i;
      }
    }
    return -1;
  }

  resetDesicion() {
    this.indexActualProducto = null;
    this.indexActualCompany = null;
    this.sortSelect = "";
    this.selectedRequest.requestProducts.forEach((element, index) => {
      this.selectedAmount[index] = 0;

      $('input[type="radio"]').prop("checked", false);
    });
    this.quotedCompanies.forEach((quotation, index) => {
      quotation["orderedProducts"] = [];
    });
    this.orders = [];
    /*  if (this.quotedCompanies['orderedProducts']) {
       this.quotedCompanies['orderedProducts'] = []
     } else {
       this.quotedCompanies['orderedProducts'] = []
 
     } */

    this.orders = [];
    console.log("ORDERS AFTER RESET");
    console.log(this.orders);
  }

  getTotal(array, iva) {
    if (iva && array.length > 0) {
      let plus = array.reduce((a, b) => a + b, 0);
      let plusIva = plus * 1.16;
      return plusIva;
    } else if (array.length > 0) {
      return array.reduce((a, b) => a + b, 0);
    } else {
      return 0;
    }
  }
  getIVA(array) {
    if (array.length > 0) {
      let plus = array.reduce((a, b) => a + b, 0);
      let iva = plus * 0.16;
      return iva;
    } else {
      return 0;
    }
  }

  fakePurchaseOrder() {
    console.log("FAKEORDERS");
    console.log(this.orders);
    let userId = localStorage.getItem("$LoopBack$currentUserId");
    let userRole = localStorage.getItem("$LoopBack$currentUserRole");

    let branchId;
    if (userRole === "Director" || userRole === "Gerente") {
      branchId = this.requestBranch;
    } else {
      branchId = localStorage.getItem("userBranchId");
    }
    let companyId = localStorage.getItem("$LoopBack$currentCompanyId");
    let purchaseOrders = [];
    this.orders.forEach((element, index) => {
      if (element.originalCurrency === "USD" && element.currency === "MXN") {
        element.quotedProducts.forEach((quoted) => {
          quoted.amount = Number(quoted.amount) / Number(this.USDCURRENCY);
        });
        this.orders[index].currency = "USD";
        element.currency = "USD";
      } else if (
        element.originalCurrency === "MXN" &&
        element.currency === "USD"
      ) {
        console.log("OriginalCurrency MXN");
        this.orders[index].quotedProducts.forEach((quoted, indexQ) => {
          this.orders[index].quotedProducts[indexQ].amount =
            Number(this.orders[index].quotedProducts[indexQ].amount) *
            Number(this.USDCURRENCY);
        });
        this.orders[index].currency = "MXN";
        element.currency = "MXN";
      } else {
      }
    });
    this.orders.forEach((order) => {
      console.log(order);
      if (order) {
        if (
          Object.values(order.orderedProducts).length ===
          order.orderedProducts.length
        ) {
          let asd = {
            estimatedDeliveryDate: order.estimatedDeliveryDate,
            sellerCompanyId: order.companyId,
            sellerUserId: order.sellerId,
            amount: order.total, //total
            sellerBranchId: order.branchId,
            sellProducts: order.orderedProducts,
            paymentOptions: order.paymentOptions,
            winCredit: order.winCredit,
            winPrice: order.quotedProducts[0].winPrice,
            winDelivery: order.winDelivery,
            creditCompareScore: order.creditCompareScore,
            creditScore: order.creditScore,
            deliveryScore: order.deliveryScore,
            quotationId: order.id,
            //new data
            buyerCompanyId: companyId,
            buyerUserId: userId,
            buyerBranchId: branchId,
            currency: order.currency,
          };
          purchaseOrders.push(asd);
        } else {
          if (order.orderedProducts.length === 1) {
          } else {
            let asd = {
              estimatedDeliveryDate: order.estimatedDeliveryDate,
              sellerCompanyId: order.companyId,
              sellerUserId: order.sellerId,
              amount: order.total, //total
              sellerBranchId: order.branchId,
              sellProducts: order.orderedProducts,
              paymentOptions: order.paymentOptions,
              winCredit: order.winCredit,
              winPrice: order.quotedProducts[0].winPrice,
              winDelivery: order.winDelivery,
              creditCompareScore: order.creditCompareScore,
              creditScore: order.creditScore,
              deliveryScore: order.deliveryScore,
              quotationId: order.id,
              //new data
              buyerCompanyId: companyId,
              buyerUserId: userId,
              buyerBranchId: branchId,
              currency: order.currency,
            };
            purchaseOrders.push(asd);
          }
        }
      }
    });

    this.purchaseOrders = purchaseOrders;
    console.log(purchaseOrders);
    console.log(this.quotation);
    this.generateFakePurchaseOrder(
      "PurchaseOrders/sendToBuyer",
      this.purchaseOrders
    );
  }
  openPreviewPurchaseOrder() {
    console.log("Opening Preview Order");
    console.log("Orders");
    console.log(this.orders);
    console.log("Orders Length");
    console.log(this.orders);

    if (this.orders.length === 0) {
      swal({
        title: "Alerta!",
        text: "No has seleccionado proveedores .",
        type: "info",
        confirmButtonClass: "btn ",
        confirmButtonColor: "#000",
      });
    } else {
      let userId = localStorage.getItem("$LoopBack$currentUserId");
      let userRole = localStorage.getItem("$LoopBack$currentUserRole");

      let branchId;
      if (userRole === "Director" || userRole === "Gerente") {
        branchId = this.requestBranch;
      } else {
        branchId = localStorage.getItem("userBranchId");
      }
      let companyId = localStorage.getItem("$LoopBack$currentCompanyId");

      let purchaseOrders = [];

      this.orders.forEach((element, index) => {
        if (element.originalCurrency === "USD" && element.currency === "MXN") {
          console.log("OriginalCurrency " + element.originalCurrency);
          console.log("Currency " + element.currency);
          this.orders[index].quotedProducts.forEach((quoted, indexQ) => {
            this.orders[index].quotedProducts[indexQ].amount =
              Number(this.orders[index].quotedProducts[indexQ].amount) /
              Number(this.USDCURRENCY);
          });
          this.orders[index].currency = "USD";
          element.currency = "USD";
        } else if (
          element.originalCurrency === "MXN" &&
          element.currency === "USD"
        ) {
          console.log("OriginalCurrency " + element.originalCurrency);
          console.log("Currency " + element.currency);

          this.orders[index].quotedProducts.forEach((quoted, indexQ) => {
            this.orders[index].quotedProducts[indexQ].amount =
              Number(this.orders[index].quotedProducts[indexQ].amount) *
              Number(this.USDCURRENCY);
          });
          this.orders[index].currency = "MXN";
          element.currency = "MXN";
        } else {
          console.log("OriginalCurrency " + element.originalCurrency);
          console.log("Currency " + element.currency);
        }
      });
      this.orders.forEach((order, index) => {
        console.log(order, "----> ORDENES DE COMPRA");
        if (order) {
          if (
            Object.values(order.orderedProducts).length ===
            order.orderedProducts.length
          ) {
            console.log("Equals");
            let asd = {
              estimatedDeliveryDate: order.estimatedDeliveryDate,
              sellerCompanyId: order.companyId,
              sellerUserId: order.sellerId,
              amount: order.total, //total
              sellerBranchId: order.branchId,
              sellProducts: order.orderedProducts,
              paymentOptions: order.paymentOptions,
              winCredit: order.winCredit,
              winDelivery: order.winDelivery,
              winPrice: order.quotedProducts[0].winPrice,
              creditCompareScore: order.creditCompareScore,
              creditScore: order.creditScore,
              deliveryScore: order.deliveryScore,
              quotationId: order.id,
              //new data
              buyerCompanyId: companyId,
              buyerUserId: userId,
              buyerBranchId: branchId,
              currency: order.currency,
            };
            purchaseOrders.push(asd);
          } else {
            if (order.orderedProducts.length === 1) {
            } else {
              console.log("else");

              let asd = {
                estimatedDeliveryDate: order.estimatedDeliveryDate,
                sellerCompanyId: order.companyId,
                sellerUserId: order.sellerId,
                amount: order.total, //total
                sellerBranchId: order.branchId,
                sellProducts: order.orderedProducts,
                paymentOptions: order.paymentOptions,
                winCredit: order.winCredit,
                winDelivery: order.winDelivery,
                winPrice: order.quotedProducts[0].winPrice,
                creditCompareScore: order.creditCompareScore,
                creditScore: order.creditScore,
                deliveryScore: order.deliveryScore,
                quotationId: order.id,
                //new data
                buyerCompanyId: companyId,
                buyerUserId: userId,
                buyerBranchId: branchId,
                currency: order.currency,
              };
              purchaseOrders.push(asd);
            }
          }
        }
      });

      this.purchaseOrders = purchaseOrders;
      console.log(purchaseOrders);

      console.log("ANTES DE MANDAR LOS PURCHASES ORDER");

      console.log(this.purchaseOrders);

      console.log(this.purchaseOrders);
      this.generatePreviewPurchaseOrder(
        "PurchaseOrders/concretedQuotation",
        this.purchaseOrders
      );

      //this.recQuotation.emit(quotation);
    }
  }

  nextPreviewPurchaseOrder() {
    var quotationId;
    if (this.prePurchaseOrders[this.purchaseOrderIndex]) {
      quotationId = this.prePurchaseOrders[this.purchaseOrderIndex].id;
      let order = this.prePurchaseOrders[this.purchaseOrderIndex];
      console.log(order);
      this.ordersToSave.push(order);
      order["buyed"] = true;
      this.updateQuotationStatus(this.quotation[0].id);

      this.sendPurchaseOrder("PurchaseOrders/acceptedQuotation", order);

      $("#orderPdf").modal("hide");

      this.purchaseOrderIndex++;
      this.spinner.show();
      setTimeout(() => {
        this.spinner.hide();
        if (!this.prePurchaseOrders[this.purchaseOrderIndex]) {
          $("#orderPdf").modal("hide");
          //this.updateQuotationStatus(this.quotation[0].id);

          this.spinner.hide();
          //Guardo el contest porque ya no quedan ordenes por revisar
          this.saveContest();
          //Guardo el contest porque ya no quedan ordenes por revisar

          //this.deleteQuoteProducts(this.quotation[0].id);
          console.log(this.quotedCompanies);
          this.quotedCompanies.forEach((order, index) => {
            this.deleteQuoteProducts(
              order.id,
              this.fakeQuoted[index].quotedProducts
            );
          });
          console.log(this.fakeQuoted);

          swal({
            title: "Ordenes de compra!",
            text: "Ordenes de compra Generadas .",
            type: "info",
            confirmButtonClass: "btn ",
            confirmButtonColor: "#000",
          });

          this._router.navigate(["/concretadas"]);
        } else {
          $("#orderPdf").modal("show");
        }
      }, 1000);
    } else {
      console.log("entro a else");
      $("#orderPdf").modal("hide");
      //this.spinner.hide();

      // this.deleteQuoteProducts(this.quotation[0].id);

      swal({
        title: "Ordenes de compra!",
        text: "Ordenes de compra Generadas .",
        type: "info",
        confirmButtonClass: "btn ",
        confirmButtonColor: "#000",
      });

      console.log(this.quotedCompanies);
      this.quotedCompanies.forEach((order, index) => {
        this.deleteQuoteProducts(
          order.id,
          this.fakeQuoted[index].quotedProducts
        );
      });
      console.log(this.fakeQuoted);

      this._router.navigate(["/concretadas"]);
    }
  }
  nextPreviewPurchaseOrderCancelled() {
    if (this.prePurchaseOrders[this.purchaseOrderIndex]) {
      let order = this.prePurchaseOrders[this.purchaseOrderIndex];
      this.cancelledPurchaseOrder("PurchaseOrders/cancelQuotation", order);

      $("#orderPdf").modal("hide");

      this.purchaseOrderIndex++;
      this.spinner.show();
      setTimeout(() => {
        this.spinner.hide();
        if (!this.prePurchaseOrders[this.purchaseOrderIndex]) {
          $("#orderPdf").modal("hide");
          this.spinner.hide();
          //Guardo el contest porque ya no quedan ordenes por revisar
          this.saveContest();

          swal({
            title: "Ordenes de compra!",
            text: "Decision finalizada .",
            type: "info",
            confirmButtonClass: "btn ",
            confirmButtonColor: "#000",
          });
          console.log(this.quotedCompanies);
          this.quotedCompanies.forEach((order, index) => {
            this.deleteQuoteProducts(
              order.id,
              this.fakeQuoted[index].quotedProducts
            );
          });
          console.log(this.fakeQuoted);

          this._router.navigate(["/concretadas"]);
        } else {
          $("#orderPdf").modal("show");
        }
      }, 1000);
    } else {
      console.log("entro a else");
      $("#orderPdf").modal("hide");
      this.spinner.hide();

      swal({
        title: "Ordenes de compra!",
        text: "Ordenes de compra Generadas .",
        type: "info",
        confirmButtonClass: "btn ",
        confirmButtonColor: "#000",
      });

      this._router.navigate(["/concretadas"]);
    }
  }
  cancelPreviewPurchaseOrder() {
    swal("Cancelado!", "No se generaron Ordenes de compra.", "error");
    $("#orderPdf").modal("hide");
  }

  setPurchaseOrder(quote) {
    //NOLOUSO
    console.log("SET");
    console.log("Orders");
    let purchaseOrders = [];
    this.orders.forEach((order) => {
      if (
        Object.values(order.orderedProducts).length ===
        order.orderedProducts.length
      ) {
        console.log("Perfectos sin Emptys");
        console.log(order);
        let asd = {
          sellerCompanyId: order.companyId,
          sellerUserId: order.sellerId,
          amount: order.total, //total
          sellerBranchId: order.branchId,
          sellProducts: order.orderedProducts,
          paymentOptions: order.paymentOptions,
        };
        purchaseOrders.push(asd);
      } else {
        if (order.orderedProducts.length === 1) {
          console.log("Puro Empty ");
          console.log(order);
        } else {
          console.log("Con algun Empty por ahi");
          console.log(order);

          let asd = {
            sellerCompanyId: order.companyId,
            sellerUserId: order.sellerId,
            amount: order.total, //total
            sellerBranchId: order.branchId,
            sellProducts: order.orderedProducts,
            paymentOptions: order.paymentOptions,
          };
          purchaseOrders.push(asd);
        }
      }
    });

    console.log(purchaseOrders);
  }

  connectSendBird(purchaseOrders?) {
    var companyId = localStorage.getItem("$LoopBack$currentCompanyId");

    console.log(purchaseOrders);
    this.sb = new SendBird({ appId: "14D7BB96-A65D-479E-80A9-573085F9358E" });
    let uuid = UUID.UUID();
    let userId = localStorage.getItem("$LoopBack$currentUserId");
    console.log("Chat Component Init");

    this.sb.connect(userId, (success, error) => {
      if (success) {
        console.log(success);
        var cover_url = "unknown_group.png";
        var dataFriend = {};
        var name = "";
        console.log("No tiene chat esta compra se va a crear");
        purchaseOrders.forEach((element, index) => {
          let userIds = [companyId, element.sellerUserId];
          console.log(userIds);
        });
      } else {
        console.log(error);
      }
    });
  }

  createChannel(userIds) {
    var cover_url = "unknown_group.png";
    var dataFriend = {};
    var name = "";

    console.log(userIds);
    this.sb.GroupChannel.createChannelWithUserIds(
      userIds,
      true,
      name,
      cover_url,
      JSON.stringify(dataFriend),
      "",
      (createdChannel, error) => {
        // console.log(createdChannel)
        if (error) {
          console.log(error, "error creating chat");
          return;
        } else {
          console.log("created--", "--");
          console.log(createdChannel.url);
          return true;

          // this.saveChat(orders, createdChannel.url)
        }
      }
    );
  }
  generateFakePurchaseOrder(type, orders, id?) {
    this.spinner.show();
    console.log("Generate");
    console.log(orders);
    console.log(this.selectedRequest.id);

    let userId = localStorage.getItem("$LoopBack$currentUserId");

    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let companyId = localStorage.getItem("$LoopBack$currentCompanyId");
    let params = {
      //branchId: branchId,
      requestId: this.selectedRequest.id,
      orders: orders,
      access_token: tokenId,
    };

    this._apiService.getDataObjects(type, "", params).subscribe(
      (result: any) => {
        console.log(result);
        this.spinner.hide();
        swal({
          title: "Concretada!",
          text: "Ordenes de compra Generadas .",
          type: "info",
          confirmButtonClass: "btn ",
          confirmButtonColor: "#000",
        });
        //Guardo el contest porque ya no quedan ordenes por revisar
        this.saveContest(orders);
        //Guardo el contest porque ya no quedan ordenes por revisar
        this._router.navigate(["/concretadas"]);
      },
      (error) => {
        console.log(error);
        this.errorMessage = <any>error;
        if (this.errorMessage != null) {
          console.log(this.errorMessage);
        }
      }
    );
  }

  confirmReceived(order) {
    this.editPurchaseOrder(order);
  }
  editRequest(request: any) {
    let id = request.id;
    console.log(id);
    let companyId = localStorage.getItem("$LoopBack$currentCompanyId");

    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let params = {
      access_token: tokenId,
    };
    console.log(params);
    this._apiService
      .editDataObject("Requests", id, { delivered: true }, params)
      .subscribe(
        (result: any) => {
          console.log(result);
        },
        (error) => {
          if (error != null) {
          }
        }
      );
  }

  editRequestLooserBranch(request: any) {
    let id = request.id;
    console.log(id);
    let companyId = localStorage.getItem("$LoopBack$currentCompanyId");

    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let params = {
      access_token: tokenId,
    };
    console.log(params);
    this._apiService
      .editDataObject("Requests", id, { delivered: true }, params)
      .subscribe(
        (result: any) => {
          console.log(result);
        },
        (error) => {
          if (error != null) {
          }
        }
      );
  }

  editPurchaseOrder(order: any) {
    this.spinner.show();
    let id = order.id;
    console.log(id);
    let companyId = localStorage.getItem("$LoopBack$currentCompanyId");

    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let params = {
      access_token: tokenId,
    };
    console.log(params);
    this._apiService
      .editDataObject("PurchaseOrders", id, { delivered: true }, params)
      .subscribe(
        (result: any) => {
          console.log(result);
          this.spinner.hide();

          swal({
            title: "Notificado!",
            text: "La empresa ha sido notificada del envio.",
            type: "success",
            confirmButtonClass: "btn ",

            confirmButtonColor: "#000",
          });
        },
        (error) => {
          if (error != null) {
          }
        }
      );
  }

  deleteQuoteProducts(id: any, newQuotedProducts?) {
    // this.spinner.show()
    console.log(newQuotedProducts);
    let companyId = localStorage.getItem("$LoopBack$currentCompanyId");

    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let params = {
      access_token: tokenId,
    };
    this._apiService
      .editDataObject(
        "Quotations",
        id,
        { quotedProducts: newQuotedProducts },
        params
      )
      .subscribe(
        (result: any) => {
          console.log(result);
          //   this.spinner.hide()
        },
        (error) => {
          if (error != null) {
            console.log(error);
          }
        }
      );
  }

  patchRequest(id: any) {
    // this.spinner.show()
    console.log(id);
    let companyId = localStorage.getItem("$LoopBack$currentCompanyId");

    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let params = {
      access_token: tokenId,
    };
    console.log(params);
    this._apiService.editDataObject("Requests", id, {}, params).subscribe(
      (result: any) => {
        console.log(result);
        // this.spinner.hide()
      },
      (error) => {
        if (error != null) {
        }
      }
    );
  }
  generatePreviewPurchaseOrder(type, orders, id?) {
    // this.spinner.show();
    console.log("Generate");
    console.log(orders);
    console.log(this.selectedRequest.id);

    let userId = localStorage.getItem("$LoopBack$currentUserId");

    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let companyId = localStorage.getItem("$LoopBack$currentCompanyId");
    let data = {
      requestId: this.selectedRequest.id,
      orders: orders,
      access_token: tokenId,
    };
    let params = {
      access_token: tokenId,
    };
    this._apiService.addDataObjectCustom(type, data, params).subscribe(
      (result: any) => {
        console.log(result);
        this.prePurchaseOrders = result.data.data;
        console.log("RETORNO DEL CONCRETEDb QUOTTION");

        console.log(this.prePurchaseOrders);
        $("#orderPdf").modal("show");

        this.previewOrder = true;
      },
      (error) => {
        console.log(error);
        this.errorMessage = <any>error;
        if (this.errorMessage != null) {
          console.log(this.errorMessage);
        }
      }
    );
  }

  sendPurchaseOrder(type, purchase) {
    // PurchaseOrder.acceptedQuotation = function (purchaseOrderId, requestId, approved, sellerUserId, sellerBranchId, cb)
    console.log(purchase);
    console.log();
    let userId = localStorage.getItem("$LoopBack$currentUserId");

    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let companyId = localStorage.getItem("$LoopBack$currentCompanyId");
    let params = {
      purchaseOrderId: purchase.id,
      requestId: purchase.requestId,
      sellerUserId: purchase.sellerUserId,
      sellerBranchId: purchase.sellerBranchId,
      approved: true,
      access_token: tokenId,
    };

    this._apiService.getDataObjects(type, "", params).subscribe(
      (result: any) => {
        console.log();
        console.log(result);
      },
      (error) => {
        this.errorMessage = <any>error;
        if (this.errorMessage != null) {
          console.log(this.errorMessage);
        }
      }
    );
  }
  cancelledPurchaseOrder(type, purchase) {
    // PurchaseOrder.acceptedQuotation = function (purchaseOrderId, requestId, approved, sellerUserId, sellerBranchId, cb)
    console.log(purchase);
    console.log();
    let userId = localStorage.getItem("$LoopBack$currentUserId");

    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let companyId = localStorage.getItem("$LoopBack$currentCompanyId");
    let params = {
      purchaseOrderId: purchase.id,
      requestId: purchase.requestId,
      sellerUserId: purchase.sellerUserId,
      sellerBranchId: purchase.sellerBranchId,
      approved: false,
      access_token: tokenId,
    };

    this._apiService.getDataObjects(type, "", params).subscribe(
      (result: any) => {
        console.log();
        console.log(result);
      },
      (error) => {
        this.errorMessage = <any>error;
        if (this.errorMessage != null) {
          console.log(this.errorMessage);
        }
      }
    );
  }
  cancelPurchaseOrder(type, purchase) {
    // PurchaseOrder.acceptedQuotation = function (purchaseOrderId, requestId, approved, sellerUserId, sellerBranchId, cb)
    console.log(purchase);
    console.log();
    let userId = localStorage.getItem("$LoopBack$currentUserId");

    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let companyId = localStorage.getItem("$LoopBack$currentCompanyId");
    let params = {
      purchaseOrderId: purchase.id,
      requestId: purchase.requestId,
      sellerUserId: purchase.sellerUserId,
      sellerBranchId: purchase.sellerBranchId,
      approved: false,
      access_token: tokenId,
    };

    this._apiService.getDataObjects(type, "", params).subscribe(
      (result: any) => {
        console.log();
        console.log(result);
      },
      (error) => {
        this.errorMessage = <any>error;
        if (this.errorMessage != null) {
          console.log(this.errorMessage);
        }
      }
    );
  }

  updateQuotationStatus(id: any) {
    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let params = {
      access_token: tokenId,
    };
    this._apiService
      .editDataObject("Quotations", id, { winner: true }, params)
      .subscribe(
        (result: any) => {
          /*   console.log(result);
          console.log('Update Winner') */
        },
        (error) => {
          if (error != null) {
            console.log(error);
          }
        }
      );
  }
  updateQuotationStatusFalse(id: any) {
    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let params = {
      access_token: tokenId,
    };
    this._apiService
      .editDataObject("Quotations", id, { winner: false }, params)
      .subscribe(
        (result: any) => {
          /*   console.log(result);
          console.log('Update False')
   */
        },
        (error) => {
          if (error != null) {
            console.log(error);
          }
        }
      );
  }
  saveContest(orders?) {
    console.log("SAVECONTEST");
    /*  {
        "requestId": "string",
        "orders": [
          {}
        ],
        "companyId": "string",
        "branchId": "string",
        "userId": "string",
      }*/
    this.spinner.show();
    this.createCanvasContest().then((url) => {
      console.log(url);
      let companyId = localStorage.getItem("$LoopBack$currentCompanyId");
      let userId = localStorage.getItem("$LoopBack$currentUserId");
      let userRole = localStorage.getItem("$LoopBack$currentUserRole");
      let branchId;
      if (userRole === "Director" || userRole === "Gerente") {
        if (this.selectedRequest.temporalBranch) {
          console.log(this.selectedRequest.temporalBranch, " temporal branch");

          branchId = this.selectedRequest.temporalBranch;
          console.log(this.selectedRequest);
        } else {
          console.log("no venia el temporal branch");
        }
      } else {
        branchId = localStorage.getItem("userBranchId");
      }
      let contest = {};
      if (orders) {
        contest = {
          requestId: this.selectedRequest.id,
          orders: orders,
          companyId: companyId,
          branchId: branchId,
          userId: userId,
          contestUrl: url,
        };
      } else {
        contest = {
          requestId: this.selectedRequest.id,
          orders: this.ordersToSave,
          companyId: companyId,
          branchId: branchId,
          userId: userId,
          contestUrl: url,
        };
      }

      console.log(contest);

      let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
      let params = {
        access_token: tokenId,
      };
      this._apiService.addDataObject("Contests", contest).subscribe(
        (result: any) => {
          console.log("Insert contest");

          setTimeout(() => {
            this.spinner.hide();
          }, 500);
        },
        (error) => {
          if (error != null) {
            console.log(error);
            setTimeout(() => {
              this.spinner.hide();
              swal("Error!", "Error al Guardar.", "error");
            }, 250);
          }
        }
      );
    });
  }

  saveChat(orders, chatId) {
    let companyId = localStorage.getItem("$LoopBack$currentCompanyId");

    let chat = {
      sellerId: orders[0].sellerCompanyId,
      buyerId: companyId,
      requestId: orders[0].requestId,
      active: true,
      channelUrl: chatId,
      purchaseOrderId: orders[0].id,
    };

    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let params = {
      access_token: tokenId,
    };
    console.log(chat, "CHAT A GUARDAR");
    this._apiService.addDataObject("Chats", chat).subscribe(
      (result: any) => {
        console.log(result);
        setTimeout(() => {}, 500);
      },
      (error) => {
        if (error != null) {
          setTimeout(() => {}, 250);

          console.log(error);
        }
      }
    );
  }
  openPurchaseDec(reqId, request) {
    console.log(request);
    this.selectedRequest = request;
    this.requestId = request.id;
    let tokenId = localStorage.getItem("$LoopBack$accessTokenId");
    let filter = {
      access_token: tokenId,
    };
    this.getUserName("AppUsers/", request.userId, filter);
    this.getTotalsBase();

    this.getCompanyName("Companies", this.selectedRequest.companyId, filter);

    this.getGrupoCotizaciones("Requests/quotationsGroup", reqId);

    $("#movilDecision").show(400);
    $("#tablesReceived").hide(10);
  }

  getCompanyName(type: string, id: string, params: any) {
    var name;
    this._apiService.getDataById(type, id, params).subscribe(
      (result: any) => {
        name = result.companyInfo.name;
        this.companyName = result.name;
        this.companyAddress = result.companyInfo.address;
        this.companyPhone = result.companyInfo.phone;
        this.extra = {
          companyName: this.companyName,
          fiscalName: result.companyInfo.fiscalName,
          companyAddress: this.companyAddress,
          email: result.companyInfo.email,
          companyPhone: this.companyPhone,
        };
      },
      (error) => {
        if (error != null) {
          console.log(error);
        }
      }
    );
    return name;
  }
  getUserName(type: string, id: string, params: any) {
    var name;
    this._apiService.getDataById(type, id, params).subscribe(
      (result: any) => {
        console.log("user NAME");
        name = result.personalInfo.name;
        this.userRequest = name;
      },
      (error) => {
        if (error != null) {
          console.log(error);
        }
      }
    );
    return name;
  }
  onChangeSort(value: any) {
    console.log(value);
    console.log(this.sortSelect);

    if (this.sortSelect === "Credito") {
      console.log(this.quotedCompanies);
      this.quotedCompanies.sort((a, b) => {
        return b.creditCompareScore - a.creditCompareScore;
      });
      this.fakeQuoted.sort((a, b) => {
        return b.creditCompareScore - a.creditCompareScore;
      });
      console.log(this.quotedCompanies);
    } else if (this.sortSelect === "Precio") {
      console.log(this.quotedCompanies);
      if (this.quotedCompanies[0].quotedProducts.length === 1) {
        this.quotedCompanies.sort((a, b) => {
          return a.quotedProducts[0].amount - b.quotedProducts[0].amount;
        });
        this.fakeQuoted.sort((a, b) => {
          return a.quotedProducts[0].amount - b.quotedProducts[0].amount;
        });
      } else {
        this.quotedCompanies.sort((a, b) => {
          return a.amount - b.amount;
        });
        this.fakeQuoted.sort((a, b) => {
          return a.amount - b.amount;
        });
        this.resetDesicion();
      }

      console.log(this.quotedCompanies);
    } else if (this.sortSelect === "Entrega") {
      console.log("ENTREGA");

      console.log(this.quotedCompanies);
      console.log(this.fakeQuoted);
      //console.log(this.orders)

      this.quotedCompanies.sort((a, b) => {
        let dateA = new Date(a.estimatedDeliveryDate);
        let dateB = new Date(b.estimatedDeliveryDate);

        return dateA.getTime() - dateB.getTime();
      });

      this.fakeQuoted.sort((a, b) => {
        let dateA = new Date(a.estimatedDeliveryDate);
        let dateB = new Date(b.estimatedDeliveryDate);

        return dateA.getTime() - dateB.getTime();
      });
    }
    console.log("AFTER");
    console.log(this.quotedCompanies);
    console.log(this.fakeQuoted);
    // console.log(this.orders)
  }
  decision() {
    $("#decidir").show(400);
    $("#tablesReceived").hide(10);
  }
  aprobacion() {
    $("#tablesReceived").hide(10);
  }
  goBackRecibidas() {
    if (this.quotedCompanies) {
      this.resetDesicion();
    }
    this.quotedCompanies = [];
    $("#tablesReceived").show(400);
    $("#decidir").hide(10);
    $("#decisionCompra").hide(10);
    $("#decisionContest").hide(10);

    $("#movilDecision").hide(10);
  }
  mensajes() {
    $("#mensajear").show(400);
    $("#decidir").hide(10);
    $("#tablesReceived").hide(10);
  }
}
